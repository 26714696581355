import React from 'react';
import { PieChartWidget } from 'common/components';

import {
  useAsepticChangeoverDetailsApi,
  stepCategoryConvertLabels
} from '../../hooks/useAsepticChangeoverDetailsApi';
import { ChangeoverStepCategoriesChartTooltip } from './ChangeoverStepCategoriesChart.elements';
import { useFilterSelected } from 'components';

const TooltipComponent = ({ label, value }: Record<string, unknown>): JSX.Element => {
  return (
    <ChangeoverStepCategoriesChartTooltip>
      <div className="pie-chart-tooltip__label">
        {stepCategoryConvertLabels[label as string] || label}
      </div>
      <div className="pie-chart-tooltip__value">
        {value} <span className="small-text">Hours</span>
      </div>
    </ChangeoverStepCategoriesChartTooltip>
  );
};

export const ChangeoverStepCategoriesChart = (): JSX.Element => {
  const { chartData, isLoading, hasMessage, hasError } = useAsepticChangeoverDetailsApi();

  const [selected, handle] = useFilterSelected();

  const handleClick = (slice: Record<string, unknown>) => {
    const isSelected = selected?.label?.includes(slice.label as string) ? true : false;
    return handle(isSelected ? 'toggle' : 'set', { label: slice.label as string });
  };

  // the function that helps determins if the slice is selected
  const checkIfSelected = (slice: Record<string, unknown>) => {
    const label = slice.label as string;
    const selectedVals = selected?.label || [];
    if (selectedVals.includes(label) || !selectedVals.length) return true;
    return false;
  };

  // the value of the preformatted middle text
  const currentSelected = selected?.label[0] || 'Mainten';
  const currentItem = chartData?.find((x) => x.label === currentSelected);

  // set WidgetUi settings - this is the settings for the widget
  const widgetUiSettings = {
    styleType: 'v1',
    isLoading,
    hasMessage,
    hasError,
    title: 'Changeover Step Categories',
    className: 'changeover-step-categories-widget',
    hasDateFooter: true
  };

  const pieChartSettings = {
    type: 'doughnut',
    // passing it the inner part of the tooltip.
    // the container stiles will already be applied
    TooltipComponent
  };

  const pieChartWidgetSettings = {
    widgetUiSettings,
    pieChartSettings,
    handleClick,
    checkIfSelected,
    data: chartData as Record<string, unknown>[],
    selected,
    // get center values based on selected or default
    centerCellValues: {
      title: currentItem?.label,
      value: currentItem?.value,
      label: 'Hours'
    },
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;

        return <>{stepCategoryConvertLabels[label] || label}</>;
      }
    }
  };

  return <PieChartWidget {...pieChartWidgetSettings} />;
};
