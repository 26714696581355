import React from 'react';

export const IcoCarouselView = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3773_13672)">
        <path
          d="M4.66699 3.99992C4.66699 3.82311 4.73723 3.65354 4.86225 3.52851C4.98728 3.40349 5.15685 3.33325 5.33366 3.33325H10.667C10.8438 3.33325 11.0134 3.40349 11.1384 3.52851C11.2634 3.65354 11.3337 3.82311 11.3337 3.99992V11.9999C11.3337 12.1767 11.2634 12.3463 11.1384 12.4713C11.0134 12.5963 10.8438 12.6666 10.667 12.6666H5.33366C5.15685 12.6666 4.98728 12.5963 4.86225 12.4713C4.73723 12.3463 4.66699 12.1767 4.66699 11.9999V3.99992Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6663 11.3334H13.9997C13.8229 11.3334 13.6533 11.2632 13.5283 11.1382C13.4032 11.0131 13.333 10.8436 13.333 10.6667V5.33341C13.333 5.1566 13.4032 4.98703 13.5283 4.86201C13.6533 4.73699 13.8229 4.66675 13.9997 4.66675H14.6663"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.33301 11.3334H1.99967C2.17649 11.3334 2.34605 11.2632 2.47108 11.1382C2.5961 11.0131 2.66634 10.8436 2.66634 10.6667V5.33341C2.66634 5.1566 2.5961 4.98703 2.47108 4.86201C2.34605 4.73699 2.17649 4.66675 1.99967 4.66675H1.33301"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3773_13672">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
