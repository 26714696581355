import styled from 'styled-components';
import theme from 'themes';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 2rem;
  border-top: 1px solid ${theme.colors.tableborder};
  .bookmarkIcon:hover {
    cursor: pointer;
  }
  .fileName:hover {
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 0.4rem;
    text-decoration-color: ${theme.colors.primaryBlue5};
  }
`;

const Content = styled.div`
  padding: 1rem 1.5rem;
`;
const ModalText = styled.div`
  padding: 1rem 0;
  margin-bottom: 1.5rem;
`;

export { ItemContainer, Content, ModalText };
