import React from 'react';
import { Checkbox, InformationTooltip, Switch } from 'components';
import { useTranslation } from 'react-i18next';
import { TAlertData } from 'types/machine-health/alerts';
import { THandleType } from '.';
import styled, { useTheme } from 'styled-components';
import theme from 'themes';
import IcoInfo2 from 'icons/IcoInfo2';

interface TAlertHeader {
  alertData: TAlertData;
  onHandleChange: (key: string, value: THandleType) => void;
  alertIncrementId: number | undefined;
  onHandleNeedAcknowledgment: (value: boolean) => void;
}

export const AlertStateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 45%;
  .label {
    color: ${theme.colors.text.lightBlack};
    font-weight: 600;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    flex-basis: 50%;
  }
  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

export const AlertIdWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  .label {
    color: ${theme.colors.text.lightBlack};
    font-weight: 600;
    font-size: 16px;
  }
  span {
    padding-left: 0.2rem;
    color: ${theme.colors.text.lightBlack};
    font-weight: 600;
    font-size: 16px;
  }
`;

const NeedAckWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 0.5rem;
`;

const AlertHeader = ({
  alertData,
  alertIncrementId,
  onHandleChange,
  onHandleNeedAcknowledgment
}: TAlertHeader): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const theme = useTheme();

  const formattedIncrementId = alertIncrementId?.toLocaleString('en-US', {
    minimumIntegerDigits: 4,
    useGrouping: false
  });

  return (
    <HeaderWrapper>
      <AlertStateWrapper>
        <p className="label">{t('alert_enable') as string}</p>
        <Switch
          checked={alertData?.state === 'ENABLED'}
          onChange={(e: boolean) => {
            onHandleChange('state', e);
          }}
          offColor={theme.colors.mediumGrey2}
        />
        {process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true' ? (
          <NeedAckWrapper>
            <Checkbox
              key={'alertheader'}
              id={`${'alertheader'}`}
              width={18}
              height={18}
              labelWeight={600}
              label={t('Needs Acknowledgement') as string}
              checked={alertData.needsAcknowledgment}
              onChange={(e) => {
                onHandleNeedAcknowledgment(e.target.checked);
              }}
            />
            <div
              style={{
                marginTop: '4px'
              }}
            >
              <InformationTooltip
                tooltipText={
                  t(
                    'if_this_is_checked_an_active_alert_will_be_retained_on_the_machine_dashboard_till_it_is_acknowledged_by_a_user'
                  ) as string
                }
                placement="top"
                icon={<IcoInfo2 color={theme.colors.primaryBlue5} height={18} width={18} />}
                width="20rem"
                labelStyle={{
                  fontSize: '14px',
                  lineHeight: 1.2,
                  color: theme.colors.text.lightBlack
                }}
              />
            </div>
          </NeedAckWrapper>
        ) : null}
      </AlertStateWrapper>

      {alertIncrementId ? (
        <AlertIdWrapper>
          <p className="label">Alert ID: </p>
          <span>{formattedIncrementId}</span>
        </AlertIdWrapper>
      ) : null}
    </HeaderWrapper>
  );
};

export default AlertHeader;
