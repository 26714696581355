import React, { useState } from 'react';
import Select from 'react-dropdown-select';

interface CategoryProps {
  selected: { type: string[]; scope: string };
  scope: string[];
  handle: (type: string, value: string[]) => void;
}

export const Categories = ({ scope, selected, handle }: CategoryProps): JSX.Element => {
  const [sel, setSel] = useState(selected.scope || 'Show All');

  return (
    <div className="container-select">
      <Select
        options={scope.map((el) => ({ value: el, label: el }))}
        values={[{ value: sel, label: sel }]}
        onChange={(value) => {
          setSel(value[0] && value[0].value ? value[0].value : '');
          value[0].value &&
            handle('scope', value[0].value === 'Show All' ? [''] : [value[0].value]);
        }}
        className="select-dropdown-items"
        placeholder="Select..."
      />
    </div>
  );
};
