import React from 'react';
import { usePermissions } from 'hooks';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import theme from 'themes';
import { PageHeader } from 'components';
import SwaggerIcon from 'icons/headerIcons/swagger.svg';
import { DataNotFound } from 'components/DataNotFound';
import { NoServiceSelectedWrapper, ServiceSelectorWrapper, SwaggerWrapper } from './index.element';

const DropdownOptions = [
  {
    label: 'FPS Service',
    value: 'https://app-omniblu-fps-service-dev-eastus.azurewebsites.net/docs'
  },
  {
    label: 'Machine Health Service',
    value: 'https://app-omniblu-machine-health-service-dev-eastus.azurewebsites.net/docs'
  },
  {
    label: 'Onboarding Service',
    value: 'https://app-omniblu-onboarding-service-dev-eastus.azurewebsites.net/docs'
  },
  {
    label: 'User Service',
    value: 'https://app-omniblu-user-service-dev-eastus.azurewebsites.net/docs'
  },
  {
    label: 'Common Service',
    value: 'https://app-omniblu-common-service-dev-eastus.azurewebsites.net/docs'
  },
  {
    label: 'Digitization Service',
    value: 'https://app-omniblu-digitization-service-dev-eastus.azurewebsites.net/docs'
  },
  {
    label: 'Auth Service',
    value: 'https://app-omniblu-auth-service-dev-eastus.azurewebsites.net/docs'
  }
];

const SwaggerPage = (): JSX.Element => {
  const history = useHistory();
  const { scopes } = usePermissions();
  const [selectedService, setSelectedService] = React.useState<{
    label: string;
    value: string;
  } | null>(null);

  const userHaveAlertManagementPermission = React.useMemo(() => {
    const swaggerScope = scopes.find((scope) => {
      return scope.name === 'Swagger Access';
    });
    return swaggerScope?.read || swaggerScope?.write || false;
  }, [scopes]);

  React.useLayoutEffect(() => {
    if (!userHaveAlertManagementPermission || process.env.REACT_APP_ENABLE_SWAGGER === 'false')
      return history.push('/access-denied');
  }, [userHaveAlertManagementPermission]);

  return (
    <>
      <PageHeader heading="Swagger" icon={SwaggerIcon} />
      <SwaggerWrapper>
        <ServiceSelectorWrapper>
          <p>Select your service : </p>
          <div>
            <Select
              options={DropdownOptions}
              value={selectedService}
              onChange={(selectedOption) => {
                setSelectedService(selectedOption);
              }}
              placeholder="Please select service"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: theme.colors.white,
                  width: '14rem'
                })
              }}
            />
          </div>
        </ServiceSelectorWrapper>
        {selectedService === null ? (
          <NoServiceSelectedWrapper>
            <DataNotFound textMsg={'No service to show, Please select your service' || ''} />
          </NoServiceSelectedWrapper>
        ) : (
          <iframe
            src={selectedService === null ? '' : selectedService.value}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Swagger UI"
            loading="lazy"
          />
        )}
      </SwaggerWrapper>
    </>
  );
};

export default SwaggerPage;
