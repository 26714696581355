import React from 'react';
import styled from 'styled-components';
import theme from 'themes';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoUpload = ({
  width,
  color = theme.colors.cyan
}: {
  width?: string;
  color?: string;
}): JSX.Element => {
  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.584 13.7026C6.00374 13.571 5.44645 13.3533 4.93066 13.0566"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25067 2C10.5761 2.30271 11.7595 3.04646 12.6071 4.10947C13.4547 5.17248 13.9163 6.49177 13.9163 7.85133C13.9163 9.2109 13.4547 10.5302 12.6071 11.5932C11.7595 12.6562 10.5761 13.4 9.25067 13.7027"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.96998 11.2467C2.60631 10.7183 2.33011 10.1349 2.15198 9.51868"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.85129C2.10667 6.21796 2.312 5.61796 2.6 5.06796L2.71267 4.86462"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.52197 2.904C5.14574 2.4746 5.84552 2.16781 6.58397 2"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.40002L8 5.40002L10 7.40002"
        stroke={color}
        strokeWidth="0.888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.40002V10.2"
        stroke={color}
        strokeWidth="0.888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
