import ButtonComponent from 'components/Button';
import React from 'react';
import {
  BottomWrapper,
  ButtonWrapper,
  Heading,
  HeadingWrapper,
  InputWrapper,
  NewTagWrapper,
  Section,
  SectionOne,
  SectionTwo,
  StyledTextarea,
  TextAreaWrapper
} from './index.elements';
import { Input, InputLabel, Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { useGetMachineDataAnalysisTagsQuery } from 'api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import MentionTextArea from '../../../components/MentionTextArea';
import { formatDecimalValue } from 'helpers';

type Props = {
  machineId: string | undefined;
};

const CreateNewTag = ({ machineId }: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const {
    data: tagValues,
    isFetching: isFetchingTagValues,
    isError: isErrorTagValues
  } = useGetMachineDataAnalysisTagsQuery(
    machineId
      ? {
          machineId: machineId
        }
      : skipToken
  );

  if (isFetchingTagValues) {
    return <Loader />;
  }

  const TagDropdownOptions =
    tagValues &&
    !isErrorTagValues &&
    tagValues
      .filter((tag) => {
        return tag.value !== null;
      })
      .map((tag) => ({
        id: `${formatDecimalValue({ value: Number(tag.value) })}`,
        display: tag.tagId
      }));

  return (
    <NewTagWrapper>
      <HeadingWrapper>
        <Heading>{t('calculated_tag')}</Heading>
      </HeadingWrapper>
      <Section>
        <SectionOne>
          <InputWrapper>
            <Input
              label={t('tag_name') as string}
              mandatory
              variant="white"
              placeholder={t('provide_a_name_to_your_tag') as string}
            />
          </InputWrapper>
          <TextAreaWrapper>
            <InputLabel>{t('tag_description')}</InputLabel>
            <StyledTextarea
              placeholder={t('description') as string}
              rows={5}
              style={{
                resize: 'none'
              }}
            />
          </TextAreaWrapper>
        </SectionOne>
        <SectionTwo>
          <div>
            {TagDropdownOptions && TagDropdownOptions.length > 0 && (
              <MentionTextArea
                expressionOptions={TagDropdownOptions ? TagDropdownOptions : []}
                label={t('expression')}
                tagName={t('tag_name')}
                placeholder={t('expression_builder_placeholder')}
              />
            )}
          </div>
          <BottomWrapper>
            <ButtonWrapper>
              <ButtonComponent borderRadius="48px">{t('cancel')}</ButtonComponent>
              <ButtonComponent borderRadius="48px" variant="primary">
                {t('save')}
              </ButtonComponent>
            </ButtonWrapper>
          </BottomWrapper>
        </SectionTwo>
      </Section>
    </NewTagWrapper>
  );
};

export default CreateNewTag;
