import React from 'react';

import { ProcessedProductPercentageKPIProgressTile } from './ProcessedProductPercentageKpiProgressTile';
import { ThroughputPieceCountKPIProgressTile } from './ThroughputPieceCountKPIProgressTile';
import { YieldKPIProgressTile } from './YieldKPIProgressTile';
import { ThroughputRateKPIProgressTile } from './ThroughputRateKPIProgressTile';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

export const ProgressTilesRow = (): JSX.Element => {
  return (
    <>
      <YieldKPIProgressTile />
      <ThroughputRateKPIProgressTile />
      <ThroughputPieceCountKPIProgressTile />
      <ProcessedProductPercentageKPIProgressTile />
    </>
  );
};
