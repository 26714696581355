import React, { ReactElement, useReducer, useState } from 'react';
// 3rd party libs
import TabNav from 'components/TabNavTwo';
import {
  UserManagementTable,
  UserManagementModal,
  GroupModal
} from 'pages/UserManagementTwo/components';

// Types
import { Id } from 'types';
import { UserManagementTableType, UserTypes } from 'types/user-management';
import styled from 'styled-components';
import GroupManagementTable from './components/GroupManagementTable/Index';
import { Button, PageHeader, TooltipWrapper } from 'components';
import GroupIcon from 'icons/headerIcons/GroupIcon.svg';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  width: 100%;
  padding: 1.5rem 2rem 0 2rem;
  margin-top: -3.5rem;
  .userTable{
    tr th {
      &:first-child{
        max-width: 1rem;
      }
    };
    tr td {
      &:first-child {
        max-width: 1rem;
      };
    };
  };
  };
  .group-table{
  .customcss{
    padding: 0
  }
  }
`;
const ImportButtonWrapper = styled.div`
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #da702912;
  background-color: #da702912;
  padding: 0 1rem;
`;

const ImportStatusMessage = styled.div`
  color: #323130;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const ImportButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.875rem;
  position: relative;
  z-index: 100 !important;
`;
const ImportButtonSupport = styled.div`
  color: #323130;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
// Types
export interface FlyoutState {
  isOpen: boolean;
  flyoutType: UserManagementTableType;
  flyoutId?: Id;
}

export type TableActionType = 'addUser' | 'editUser' | 'edit' | 'import' | 'delete' | 'exit';
export interface TableAction {
  flyoutType: UserManagementTableType;
  flyoutItemId?: Id;
  selectedUser: UserTypes;
  action?: TableActionType;
}

export type FlyoutAction =
  | { type: 'close' }
  | { type: 'open'; flyoutType: UserManagementTableType; flyoutItemId?: Id };

const initialFlyoutState = {
  isOpen: false,
  flyoutType: UserManagementTableType.GROUP,
  flyoutItemId: null
};

const reducer = (state: FlyoutState, action: FlyoutAction) => {
  switch (action.type) {
    case 'close':
      return { ...state, isOpen: false };
    case 'open':
      return {
        ...state,
        isOpen: true,
        flyoutType: action.flyoutType,
        flyoutId: action.flyoutItemId
      };
    default:
      return state;
  }
};
const UserManagementTwo = (): ReactElement => {
  const [currentTab, setCurrentTab] = useState<string>('0');
  const [selectedItem, setSelectedItem] = useState<UserTypes>();
  const [showImportUserBar, setShowImportUserBar] = useState<boolean>(false);
  const [disableImportUserButton, setDisableImportUserButton] = useState<boolean>(true);
  const [flyoutState, dispatch] = useReducer(reducer, initialFlyoutState);
  const [importValidatedUsers, setImportValidatedusers] = useState<number>(0);
  const [isActiveUserImportMode, setIsActiveUserImportMode] = useState<boolean>(false);
  const [moveToGroupOnImportMode, setMoveToGroupOnImportMode] = useState<boolean>(false);
  const { t } = useTranslation(['common']);

  const handleMoveToSiblingTabRequest = (option: string): void => {
    if (option === 'cancel') {
      setMoveToGroupOnImportMode(false);
    } else {
      if (moveToGroupOnImportMode) {
        setCurrentTab('0');
        setMoveToGroupOnImportMode(false);
        setIsActiveUserImportMode(false);
      }
    }
  };
  return (
    <>
      {showImportUserBar && (
        <ImportButtonWrapper>
          <ImportStatusMessage>Currently on edit mode, you can edit data.</ImportStatusMessage>
          <ImportButtonBox>
            <ImportButtonSupport>Done editing?</ImportButtonSupport>
            <TooltipWrapper
              Tooltip={'Add all fields to import'}
              textColor="#000"
              backGroundColor="#ffffff"
              left
            >
              <Button
                width="74px"
                height="32px"
                borderRadius="48px"
                disabled={disableImportUserButton}
                onClick={() => {
                  setImportValidatedusers((prev) => prev + 1);
                }}
              >
                Import
              </Button>
            </TooltipWrapper>
          </ImportButtonBox>
        </ImportButtonWrapper>
      )}
      <PageHeader mb="0rem" heading={t('card_group_user_management')} icon={GroupIcon} />

      {/* mock for now based on mockups, to adjust later according to path and Ids*/}
      <TabNav
        items={[
          {
            label: 'Groups',
            onClick: () => {
              if (isActiveUserImportMode) {
                setMoveToGroupOnImportMode(true);
              } else {
                setCurrentTab('0');
              }
            },
            active: currentTab === '0',
            isTabEnabled: true
          },
          {
            label: 'Users',
            onClick: () => {
              setCurrentTab('1');
            },
            active: currentTab === '1',
            isTabEnabled: true
          }
        ]}
      />
      <Container>
        {currentTab === '0' ? (
          <GroupManagementTable
            onClickButton={(flyoutType: UserManagementTableType, flyoutItemId?: Id) => {
              dispatch({ type: 'open', flyoutType, flyoutItemId });
            }}
          />
        ) : (
          <UserManagementTable
            onClickButton={(
              flyoutType: UserManagementTableType,
              flyoutItemId?: Id,
              selectedUser?: UserTypes
            ) => {
              setSelectedItem(selectedUser);
              dispatch({ type: 'open', flyoutType, flyoutItemId });
            }}
            setShowImportUserBar={setShowImportUserBar}
            setDisableImportUserButton={setDisableImportUserButton}
            importValidatedUsers={importValidatedUsers}
            setIsActiveUserImportMode={setIsActiveUserImportMode}
            moveToGroupOnImportMode={moveToGroupOnImportMode}
            setMoveToGroupOnImportMode={handleMoveToSiblingTabRequest}
          />
        )}

        {flyoutState.flyoutType === UserManagementTableType.GROUP ? (
          <GroupModal
            open={flyoutState.isOpen}
            setIsOpenModal={() => {
              dispatch({ type: 'close' });
            }}
            tableItemId={flyoutState.flyoutId}
          />
        ) : (
          <UserManagementModal
            tableType={flyoutState.flyoutType}
            open={flyoutState.isOpen}
            setIsOpenModal={() => dispatch({ type: 'close' })}
            tableItemId={flyoutState.flyoutId}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </Container>
    </>
  );
};

export default UserManagementTwo;
