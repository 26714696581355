import React from 'react';
import { DotsChartWrapper } from './DotsChartWrapper';
import { uniqueId } from 'lodash';
import { DimensionsContainer, WidgetUi } from 'components';
import { IntensifiersStrokeCountsList } from '../IntensifiersStrokeCountsList/IntensifiersStrokeCountsList';
import { INTENSIFIERS } from './utils/index';
import { LabelWrapper } from './LabelWrapper';
import { MiddleProp } from './types';
import { isEqual } from 'lodash';
import { SelectedItemsProvider } from 'pages/FleetMachineDetail/hooks/useZoomHistoryhook';
import { ZoomContainer } from 'pages/FleetMachineDetail/MachineHealth/components/ZoomDotsChart/Zoom';

export const SkidStrokeCountsCharts = ({ data, isLoading, hasError }: MiddleProp): JSX.Element => {
  const { skids, skidAlarms, skidAlarmsByKeyTimestamp } = data;

  // Based on the data (There could be anywhere from 1 to 4 skids)
  // We loop through the data and display chart widget for each Skid
  return skids?.map((el, i) => {
    const rightsideSettings = {
      data: el,
      data2: skidAlarmsByKeyTimestamp[i],
      tooltipData: skidAlarmsByKeyTimestamp,
      index: i
    };

    const widgetSettings = {
      className: 'intensifier-stroke-count-widgetui',
      isLoading,
      hasError,
      Main: (
        <>
          <IntensifiersStrokeCountsList
            data={skidAlarms[i]}
            skidNum={i + 1}
            headers={INTENSIFIERS}
          />

          <DimensionsContainer
            Component={({ width: innerWidth }) => {
              return <RightSide width={innerWidth} {...rightsideSettings} />;
            }}
          />
        </>
      )
    };
    return <WidgetUi key={uniqueId()} {...widgetSettings} />;
  });
};

const customComparator = (prevProps: MiddleProp, nextProps: MiddleProp) => {
  const isTheSame = isEqual(prevProps.data.skids, nextProps.data.skids);
  return isTheSame;
};

export const MemoSkidStrokeCountsCharts = React.memo(SkidStrokeCountsCharts, customComparator);

interface Props {
  data?: Record<string, unknown>;
  data2?: Record<string, unknown>[];
  chartDomainRangeX?: Date[];
  tooltipData: undefined;
  width?: number;
  index: number;
}

const RightSide = ({ data, data2, tooltipData, width, index }: Props): JSX.Element => {
  const chartSettings = {
    data,
    data2,
    tooltipData,
    width,
    index
  };

  return (
    <div style={{ position: 'absolute' }}>
      <LabelWrapper />
      <SelectedItemsProvider>
        <ChartWrapper {...chartSettings} />
      </SelectedItemsProvider>
    </div>
  );
};

const ChartWrapper = ({ data, data2, tooltipData, width, index }: Props): JSX.Element => {
  const dataSet2 = data2 as unknown as Record<string, unknown>[];

  return (
    <>
      <ZoomContainer />
      <DotsChartWrapper
        {...{
          data,
          data2: dataSet2,
          width,
          height: 350,
          tooltipData,
          index
        }}
      />
    </>
  );
};
