import React, { ReactElement, useState } from 'react';
import { Button, Flyout, FlyoutHeader, Loader, Modal, Typography } from 'components';
import theme from 'themes';
import {
  Container,
  UploadPromptMessage,
  ImageStorageMessage,
  ButtonBox,
  StyledTitle,
  StyledButtonRow,
  StyledContentContainer,
  StyledModalHeaderContainer
} from './MachineImageUploaderStyledComponents';

import AlertIcon from 'components/WarningPrompt/AlertIcon';
import { ModalSize } from 'types';
import { MachineImageSelector } from './MachineImageSelector';
import {
  useGetMachineAssetsByIdQuery,
  useAddMachineAssetsByIdMutation,
  useUpdateMachineAssetsByIdMutation
} from 'api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface MachineImageUploaderProps {
  isOpen: boolean;
  setShowMachineImageUploader: React.Dispatch<React.SetStateAction<boolean>>;
  machineId?: string;
}
// const modalTitle = 'Do you want to remove the current file?';
//const modalPrompText =
//  'Existing machine image will be removed. A new machine image can then be uploaded.';
const MachineImageUploader = ({
  setShowMachineImageUploader,
  isOpen,
  machineId
}: MachineImageUploaderProps): ReactElement => {
  const [showRemoveImagePrompt, setShowRemoveImagePrompt] = useState<boolean>(false);
  const [uploadedMachineImage, setUploadedMachineImage] = useState<File | null>(null);
  const closeFlyoutHandler = (): void => {
    setShowMachineImageUploader(false);
    setUploadedMachineImage(null);
  };
  const {
    data: assets,
    isFetching: isLoadingAssets,
    refetch: refetchMachineAsset
  } = useGetMachineAssetsByIdQuery(machineId as string);
  const [tryLoadAssets] = useAddMachineAssetsByIdMutation();
  const [tryUpdateAsset] = useUpdateMachineAssetsByIdMutation();
  const onCloseImageRemovePrompt = () => {
    setShowRemoveImagePrompt(false);
  };
  const { t } = useTranslation(['common']);
  const loadSelectedMachineAssets = async () => {
    const files: File[] = [];
    files.push(uploadedMachineImage as File);
    await tryLoadAssets({ assets: files, id: machineId as string })
      .unwrap()
      .then(() => {
        toast.success(`Machine assets uploaded successfully`);
        setUploadedMachineImage(null);
        refetchMachineAsset();
      })
      .catch((error) => {
        toast.error(`Failed to load assets: ${error.data}`);
        setUploadedMachineImage(null);
      });
  };
  const removeMachineAsset = async () => {
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */

    // file uploaded from system doesn't have id and in that case no need to call the api.
    if (!assets![0]?.id) {
      setUploadedMachineImage(null);
      return;
    }
    await tryUpdateAsset({ assetId: assets![0]?.id, id: machineId as string, removeImage: true })
      .unwrap()
      .then(() => {
        toast.success(`Machine asset removed successfully`);
        setUploadedMachineImage(null);
        refetchMachineAsset();
      })
      .catch((error) => {
        toast.error(`Failed to remove machine asset: ${error.data}`);
      });
  };

  let hasImage = false;
  if (assets !== undefined && assets.length > 0) {
    hasImage = assets[0].url.length > 0;
  } else if (uploadedMachineImage !== null) {
    hasImage = uploadedMachineImage.name.length > 0;
  }
  return (
    <div>
      <Modal
        title={
          <StyledModalHeaderContainer>
            <AlertIcon />
            <StyledTitle>{t('remove_file')}</StyledTitle>
          </StyledModalHeaderContainer>
        }
        allowContentScroll
        onClose={onCloseImageRemovePrompt}
        showCloseButton={false}
        size={ModalSize.XSMALL}
        visible={showRemoveImagePrompt}
      >
        <StyledContentContainer>
          <Typography color={theme.colors.mediumGrey3} variant="stepheading">
            {t('remove_image_prompt')}
          </Typography>
          <StyledButtonRow>
            <Button
              onClick={() => {
                setShowRemoveImagePrompt(false);
              }}
              width="auto"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeMachineAsset();
                setShowRemoveImagePrompt(false);
                setUploadedMachineImage(null);
              }}
              variant="danger"
              width="auto"
            >
              Remove
            </Button>
          </StyledButtonRow>
        </StyledContentContainer>
      </Modal>
      <Flyout width="28.125rem" visible={isOpen} onClose={closeFlyoutHandler}>
        <FlyoutHeader
          heading={'Upload Image'}
          onClose={closeFlyoutHandler}
          bgColor={theme.colors.lightGrey2}
        />
        {isLoadingAssets ? (
          <Loader />
        ) : (
          <Container tabIndex={0}>
            <UploadPromptMessage>{t('upload_image_prompt')}</UploadPromptMessage>
            <MachineImageSelector
              hasMachineImage={hasImage}
              setShowRemoveImagePrompt={setShowRemoveImagePrompt}
              acceptedTypes={{
                'application/image': ['.jpeg', '.webp', '.png', '.jpg']
              }}
              file={uploadedMachineImage}
              url={assets && assets.length > 0 ? assets[0].url : null}
              onFileChange={setUploadedMachineImage}
            />
            {hasImage ? (
              <ImageStorageMessage>(1 {t('file_in_storage')})</ImageStorageMessage>
            ) : (
              <ImageStorageMessage>(0 {t('file_in_storage')})</ImageStorageMessage>
            )}
          </Container>
        )}
        <ButtonBox>
          <Button
            width="6.688rem"
            height="2rem"
            borderRadius="3rem"
            onClick={closeFlyoutHandler}
            disabled={showRemoveImagePrompt}
          >
            Cancel
          </Button>
          <Button
            width="6.688rem"
            height="2rem"
            borderRadius="3rem"
            disabled={showRemoveImagePrompt || uploadedMachineImage?.name === undefined}
            onClick={() => {
              loadSelectedMachineAssets();
            }}
          >
            Apply
          </Button>
        </ButtonBox>
      </Flyout>
    </div>
  );
};

export default MachineImageUploader;
