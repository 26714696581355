/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flyout, FlyoutHeader, Typography } from 'components';
import { IcoChevRight } from 'icons';
import { getDocIcons } from 'icons/docsIcons';
import React, { useMemo, useReducer, useState } from 'react';
import styled from 'styled-components';
import FileUploadArea, { isFile } from './FileUploadArea';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {
  useCreateAzureBlobMutation,
  useDeleteMachineDocumentMutation,
  useGetDocumentCategoryQuery,
  useGetMachineDocumentQuery,
  useLazyGetMachineDocumentQuery
} from 'api';
import { useTranslation } from 'react-i18next';
import { DocsCategory } from 'types';
import theme from 'themes';
import CustomLoader from 'components/CustomLoader';
import uploadFileToBlob from './azureBlob';
import { AzureBlobParams, CustomFile, Documents, FileType } from 'types/machine-management';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ToastMsg } from 'common/components/Toast/Toast';

const DocumentCategoryContainer = styled.div``;
const DocumentList = styled.ul`
  padding: 0px 1rem;
  list-style: none;
  margin: 0px;
`;
const DocFileContainer = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0px;
`;
const DocItem = styled.li`
  border-bottom: 1px solid #eee;
  padding: 6px 0px;
`;
const SubmenuItem = styled.li``;
const DocCategory = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.2rem;
  background-color: transparent;
  border: none;
  box-shadow: unset;
  border-radius: 0px;
  cursor: pointer;
  & > svg {
    margin: 0;
    width: 15px;
    height: 13px;
    transform: ${({ isActive }) => (isActive ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: all 0.3s ease-in-out;
  }

  & span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${theme.colors.primaryBlue5};
    font-size: 15px;
    font-weight: 400;
  }
`;
export const DocFlyoutBtnContainer = styled.div`
  display: flex;
  margin-top: auto;
  padding: 0 0 2rem;
  gap: 0.5rem;
  margin-left: auto;
  &.btn-danger:hover {
    background-color: ${theme.colors.text.error};
  }
`;
const SubHeadText = styled.div`
  margin: 0 1.75rem 1rem;
  border-bottom: 1px solid ${theme.colors.tableborder};
`;
type Props = {
  open: boolean;
  onCloseFlyout: () => void;
  machineId: string;
};

const initialState = {
  operation_and_maintenance_manual: [],
  parts_and_service_manual: [],
  spare_parts_diagram: [],
  electrical_schematics: [],
  equipment_installation: [],
  supplementary_material: [],
  global_machine_document: [],
  pneumatic_schematics: [],
  misc: []
};
type ActionType = 'add_file' | 'delete_file' | 'set_file' | 'set_status' | 'set_url';
type FileAction = {
  type: ActionType;
  payload: {
    file?: any;
    signedUrl?: string;
    progress?: number;
    category: string;
    fileName?: string;
    id?: string;
  };
};

const reducer = (state: any, action: FileAction) => {
  const {
    type,
    payload: { signedUrl, file, progress, category, id } = {
      signedUrl: '',
      file: null,
      category: '',
      progress: 0,
      id: ''
    }
  } = action;
  const prevFile = state[category] || [];
  const data: FileType[] = state[category];
  let updateData;
  if (type === 'set_url' || type === 'set_status') {
    updateData = data.map((item) =>
      action.payload?.fileName === item?.file?.name ? { ...item, signedUrl, progress, id } : item
    );
  }

  switch (action.type) {
    case 'add_file':
      return { ...state, [category]: [...prevFile, file && file] };
    case 'delete_file':
      updateData = data.filter((d) =>
        file?.id ? d.file?.id !== file?.id : d?.file?.name !== file?.name
      );
      return { ...state, [category]: updateData };
    case 'set_file':
      return { ...state, [category]: file };
    case 'set_status':
    case 'set_url':
      return { ...state, [category]: updateData };
    default:
      return state;
  }
};
const categoryIds = new Set<string>();
const UploadMachinePdf = ({ open, onCloseFlyout, machineId }: Props): JSX.Element => {
  const [categoryId, setCategoryId] = useState('');
  const [isFetchingDoc, setIsFetchingDoc] = useState(false);
  const {
    data: documentsList,
    isLoading: isFileLoading,
    isFetching
  } = useGetMachineDocumentQuery(
    categoryId
      ? {
          machine_id: machineId,
          category_id: categoryId
        }
      : skipToken
  );

  const [getMachineDoc] = useLazyGetMachineDocumentQuery();

  const [deleteMachineDoc] = useDeleteMachineDocumentMutation();
  const [fileStore, dispatch] = useReducer(reducer, initialState);
  const [uploadDisable, setUploadDisable] = useState(true);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const { t } = useTranslation(['fpns']);
  const { data: docsCategory = [], isLoading } = useGetDocumentCategoryQuery({ type: 'on' });
  const [createBlobStorage] = useCreateAzureBlobMutation();

  useMemo(() => {
    const id = documentsList?.[0]?.documentCategory;
    const category = docsCategory.find((doc: DocsCategory) => doc.id === id);
    const file = documentsList?.map((doc: Documents) => {
      const file = {
        file: { name: doc.userFileName, id: doc.id, isPublished: doc.isPublished },
        signedUrl: doc.signedUrl
      };
      return file;
    });
    dispatch({ type: 'set_file', payload: { category: category?.name || '', file: file } });
    setIsFetchingDoc(false);
  }, [documentsList]);

  const DocsNavItems = useMemo(() => {
    return docsCategory.filter((doc: DocsCategory) => !doc.isOnlyForMember);
  }, [docsCategory, isFetching]);

  const [openedMenu, setOpenedMenu] = useState<string[]>([]);

  const handleOpenMenu = (menu: string) => {
    setCategoryId(menu);
    const isOpen = openedMenu.includes(menu);
    if (isOpen) {
      const removedData = openedMenu.filter((i) => i !== menu);
      setOpenedMenu(removedData);
    } else {
      setOpenedMenu([...openedMenu, menu]);
      setIsFetchingDoc(true);
    }
  };

  async function createBlob(files: File[], category: DocsCategory) {
    const filesParams = files.map((file) => {
      const params: AzureBlobParams = {
        user_file_name: file.name,
        document_category: category.id,
        is_global: category.isGlobal,
        machine_id: machineId
      };
      return params;
    });
    setUploadDisable(true);
    const resp = (await createBlobStorage(filesParams)) as { data?: FileType[] };
    resp?.data?.length &&
      resp.data.map((item) =>
        dispatch({
          type: 'set_url',
          payload: {
            category: category.name,
            signedUrl: item.signedUrl,
            fileName: item.userFileName,
            id: item.id
          }
        })
      );
    setUploadDisable(false);
  }
  const handleFileChange = (files: File[], category: DocsCategory) => {
    categoryIds.add(category.id);
    setUploadDisable(false);
    files.map((file) =>
      dispatch({ type: 'add_file', payload: { category: category.name, file: { file } } })
    );
    createBlob(files, category);
  };

  const handleFileDelete = async (
    file:
      | (File & {
          id?: string;
          file?: CustomFile;
        })
      | FileType,
    category: DocsCategory
  ) => {
    if (file) deleteMachineDoc({ document_id: file?.file?.id || file.id });
    dispatch({ type: 'delete_file', payload: { category: category.name, file: file.file } });
  };

  const handleUpload = async () => {
    setIsFileUploading(true);
    setUploadDisable(true);
    try {
      const uploadPromises: Promise<void>[] = [];
      for (const key in fileStore) {
        if (fileStore[key]?.length) {
          const files = fileStore[key] as FileType[];
          const userUploadedFile = files.filter((file) => isFile(file.file));

          userUploadedFile.forEach((file: FileType) => {
            const uploadPromise = uploadFileToBlob(file.file, file.signedUrl, (progress) => {
              const { loaded, total = 1 } = progress;
              const uploadStatus = Math.ceil((loaded / total) * 100);
              dispatch({
                type: 'set_status',
                payload: { category: key, fileName: file.file.name, progress: uploadStatus }
              });
            });
            uploadPromises.push(uploadPromise);
          });
        }
      }

      const resp = await Promise.all(uploadPromises);
      if (resp.length) {
        setIsFileUploading(false);
        refetchMachineDocument();
        ToastMsg({
          heading: 'Success',
          message: 'Document(s) Uploaded Successfully!',
          type: 'success'
        });
      }
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  function refetchMachineDocument() {
    const categIds = [...categoryIds];
    categIds.forEach(async (id) => {
      const fileList = await getMachineDoc({
        category_id: id,
        machine_id: machineId
      });
      const category = docsCategory.find((doc: DocsCategory) => doc.id === id);
      const file = fileList?.data?.map((doc: Documents) => {
        const file = {
          file: { name: doc.userFileName, id: doc.id, isPublished: doc.isPublished },
          signedUrl: doc.signedUrl
        };
        return file;
      });
      dispatch({ type: 'set_file', payload: { category: category?.name || '', file: file } });
      setIsFetchingDoc(false);
    });
  }

  return (
    <Flyout width="28.125rem" visible={open} onClose={onCloseFlyout}>
      <FlyoutHeader
        heading={t('upload_machine_documents') || ''}
        onClose={onCloseFlyout}
        marginLeft="0.625rem"
      />
      <SubHeadText>
        <Typography>Upload Machine Documents to be displayed in OmniBlu Library</Typography>
      </SubHeadText>
      <DocumentCategoryContainer>
        <DocumentList>
          {isLoading ? <CustomLoader size="3.4rem" /> : ''}
          {DocsNavItems.map((nav: DocsCategory) => {
            const Icon = getDocIcons(nav.name);
            const isActive = openedMenu.includes(nav.id);
            const files = fileStore?.[nav.name];
            return (
              <DocItem key={nav.id}>
                <DocCategory isActive={isActive} onClick={() => handleOpenMenu(nav.id)}>
                  <IcoChevRight color={theme.colors.primaryBlue5} />
                  <span>
                    <Icon />
                    {t(nav.name)}
                  </span>
                </DocCategory>
                {isActive ? (
                  <>
                    <DocFileContainer>
                      <SubmenuItem>
                        <FileUploadArea
                          acceptedTypes={{
                            'application/vnd.ms-excel': ['.xls', '.xlsx', '.xlsm'],
                            'application/pdf': ['.pdf'],
                            'application/image': ['.png', '.jpg'],
                            'application/zip': ['.zip'],
                            'application/doc': ['.doc']
                          }}
                          filesWithUrl={files}
                          onFileChange={(file) => {
                            file && files.length <= 100 && handleFileChange(file, nav);
                          }}
                          icon={faFileExcel}
                          handleFileDelete={(file) => handleFileDelete(file, nav)}
                          isLoading={!!(isFileLoading || (isFetchingDoc && categoryId === nav.id))}
                          maxFileSize={104857600}
                          isFileUploading={isFileUploading}
                        />
                      </SubmenuItem>
                    </DocFileContainer>
                  </>
                ) : (
                  ''
                )}
              </DocItem>
            );
          })}
        </DocumentList>
      </DocumentCategoryContainer>
      <DocFlyoutBtnContainer>
        <Button
          variant="link"
          onClick={onCloseFlyout}
          borderRadius="3rem"
          borderColor={theme.colors.primaryBlue5}
          width="8rem"
        >
          {t('close', { ns: 'common' })}
        </Button>
        <Button
          variant="hover-blue"
          borderRadius="3rem"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.lightGrey3}
          width="8rem"
          onClick={handleUpload}
          disabled={uploadDisable}
        >
          {t('upload', { ns: 'common' })}
        </Button>
      </DocFlyoutBtnContainer>
    </Flyout>
  );
};

export default UploadMachinePdf;
