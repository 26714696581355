import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const ChartSvg = styled.svg<StyledUiContainerProps>`
  text {
    font-size: 11px;
  }
  .axis {
    .domain {
      opacity: 0;
    }
    .ticks .tick {
      line {
        opacity: 0;
      }
      text {
      }
    }
  }
`;
