import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: 'none'
}))``;

export const IcoExit = ({
  width = 20,
  color,
  srLabel
}: {
  width?: number;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg {...{ width, color }} aria-label={srLabel || `edit`} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.33333 10.6667L4.66667 8.00002M4.66667 8.00002L7.33333 5.33335M4.66667 8.00002L14 8.00002M10.6667 10.6667V11.3334C10.6667 12.4379 9.77124 13.3334 8.66667 13.3334H4C2.89543 13.3334 2 12.4379 2 11.3334V4.66669C2 3.56212 2.89543 2.66669 4 2.66669H8.66667C9.77124 2.66669 10.6667 3.56212 10.6667 4.66669V5.33335"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </div>
  );
};
