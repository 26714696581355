import { styledTheme } from 'common/theme';
import { radius } from 'common/theme/standard.theme';
import styled from 'styled-components';
import { colors, themeColors } from 'themes';

export const IntensifierAlarmsTableContainer = styled.div`
  width: 100%;

  .sub-text {
    padding: 0 0.25rem;
  }

  tr td {
    .svg-inline--fa {
      cursor: pointer;
    }

    &:first-child {
      width: 10%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 40%;
    }

    &:nth-child(5) {
      width: 30%;

      span {
        display: block;
        width: 100%;
      }
    }
  }

  table {
    th {
      & > div,
      .select-none {
        margin: 0;
      }
    }
  }

  td {
    text-align: left;
  }

  tr td span {
    margin: 0;
  }

  th {
    text-align: left;
    text-transform: capitalize;
  }

  td,
  th {
    padding: 0.6em 1em 0.6em 0;
  }

  tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  thead th {
    padding: 0.5rem 0.625rem;
  }

  .auto {
    width: max-content;
  }

  .full {
    width: auto;
    min-width: max-content;
  }

  .sub-text {
    color: ${styledTheme.colors.dark};
  }

  .intensifiers-alarms-table {
    div:nth-child(2) {
      max-height: 400px !important;
    }
  }
`;

export const FilterscContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .container-select {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    align-items: baseline;

    .react-dropdown-select {
      border: 0;
      color: ${colors.text.lightBlack};
      min-height: 33px !important;

      &[aria-expanded='true'] {
        border-bottom: 2px solid ${styledTheme.colors.border.blue};
      }
    }

    .react-dropdown-select-dropdown {
      left: -64px;
      z-index: 99;
      top: 35px !important;
    }

    .react-dropdown-select.select-dropdown-items {
      &:focus-within {
        box-shadow: none;
      }
    }

    .react-dropdown-select-dropdown {
      width: 230px;
      border-radius: ${radius.xs};
    }

    .react-dropdown-select-content {
      width: 135px;
      justify-content: center;
    }

    .react-dropdown-select-content.react-dropdown-select-type-single {
      padding: 0;
    }

    .react-dropdown-select-item {
      padding: 0.75rem 1rem 0.75rem 3.5rem;
    }

    .react-dropdown-select-item-selected {
      padding: 0.75rem 1rem 0.75rem 3.5rem;
      position: relative;
      background: ${themeColors.lightGrey10};
      color: ${colors.text.lightBlack};

      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url('/assets/imgs/IconCheckMark.svg');
        background-size: 20px 20px;
        left: 21px;
      }
    }

    .react-dropdown-select-item {
      &:hover:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url('/assets/imgs/IconCheckMark.svg');
        background-size: 20px 20px;
        left: 21px;
      }
    }
  }
`;
