import { ChartDataItemProps } from '../';

export type ConvertToStackedChartDataReturnProps = ChartDataItemProps;

// this takes an array of objects who's keys are the category and values are the bar values.
export const convertKeyValuesToChartDataArray = (
  data: Record<string, unknown>[],
  categoryKey: string,
  options?: {
    /** if the category id is different than the object key */
    groupKey?: string;
    valueKey?: string;
    colors?: Record<string, string>;
    /** list of keys to skip while converting to chart data */
    ignoreKeys?: string[];
    /** map keys renames the value of the key to a mapped valued of this object.  the key of the mapped keys should match with the key of the
     * item in the data array.  the value of the mapped key should be the new key name.  this is useful for renaming values
     * */
    mapKeys?: Record<string, string>;
  }
): {
  category: string;
  group?: string;
  value: number;
  color?: string;
}[] =>
  data.reduce(
    (
      acc: {
        category: string;
        group?: string;
        value: number;
        color?: string;
      }[],
      item
    ) => {
      Object.entries(item).forEach(([key, value]) => {
        // make sure this key is not in the ignore list and is not the category key
        if (!options?.ignoreKeys?.includes(key) && key !== categoryKey) {
          const group = options?.mapKeys?.[key] || key;
          acc.push({
            category: item?.[categoryKey] as string,
            value: value as number,
            group,
            color: options?.colors?.[group] || undefined
          });
        }
      });
      return acc;
    },
    []
  );

export const convertToStackedBarChartData = (
  data: Record<string, unknown>[],
  categoryKey: string,
  groupKey: string,
  options?: {
    colors?: Record<string, string>;
  }
): ChartDataItemProps[] => {
  let categories: Record<string, boolean> = {};
  let groups: Record<string, boolean> = {};

  const counter = data.reduce((acc: Record<string, Record<string, number>>, item) => {
    const categoryId = item?.[categoryKey] as string;
    const groupId = item?.[groupKey] as string;

    // add to categories list
    if (!categories?.[categoryId]) categories = { ...categories, [categoryId]: true };
    // add to groups list
    if (!groups?.[groupId]) groups = { ...groups, [groupId]: true };

    if (!acc?.[categoryId]) acc = { ...acc, [categoryId]: { [groupId]: 0 } };
    if (!acc[categoryId][groupId]) acc[categoryId] = { ...acc[categoryId], [groupId]: 0 };

    acc[categoryId][groupId] += 1;

    return acc;
  }, {});

  const chartData = Object.entries(counter).reduce(
    (
      acc: {
        category: string;
        group: string;
        value: number;
        color: string;
      }[],
      [category, group]
    ) => {
      Object.entries(group).forEach(([groupKey, value]) => {
        acc.push({
          category,
          group: groupKey,
          value,
          color: options?.colors?.[groupKey as string]
            ? `${options.colors[groupKey as string]}`
            : 'pink'
        });
      });

      return acc;
    },
    []
  );
  return chartData;
};
