import styled from 'styled-components';

export const AlarmsViewContainer = styled.div`
  padding: 1em;
  grid-gap: 1em;
  display: grid;
  grid-template-rows: auto 1fr;

  .alarm-pie-chart {
    width: 50%;

    .pie-chart-widget-main {
      &:nth-child(2) {
        padding-top: 2.656rem;
      }
    }
  }

  .alarms-view__date-picker {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 1925px) {
    .bar-styles {
      width: 1.25rem;
    }
    .tick__inner {
      font-size: 0.7em;
    }
  }
  @media only screen and (max-width: 1600px) {
    .bar-styles {
      width: 1rem;
    }

    .tick__inner {
      font-size: 0.6em;
    }
  }

  @media only screen and (max-width: 1400px) {
    .charts-and-filters-page__charts-area {
      flex-direction: column;
    }
    .alarm-pie-chart {
      width: 100% !important;
    }
  }
`;

export const ChartTooltip = styled.div`
  min-width: max-content;
  flex-direction: column;
  gap: 1em;

  .tooltip__label {
    font-weight: 500;
  }

  .small-text {
    font-size: 0.9em;
  }

  .bar-chart-tooltip__value {
    font-weight: 200;
    font-size: 1em;
  }
`;
