import React, { FC, ReactElement, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DigitalEdgeType } from 'types';
import {
  ReviewMachineMtlDsdm,
  ReviewMachineMtlKdm,
  ReviewMachineMtlMqtt,
  UpdateRowValueFunc
} from 'types/machine-management';
import DeviceAccordion from './DeviceAccordion';
import ReviewMachineMtlAttrsTable from './ReviewMachineMtlAttrsTable';
import LeftChevron from 'components/HorizontalScrollButtons/LeftScrollButton';
import RightChevron from 'components/HorizontalScrollButtons/RightScrollButton';
import { debounce } from 'lodash';
import { formatDecimalValue } from 'helpers';

//styling
const DeviceAccordionWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  order: 1;
  align-self: stretch;
  gap: 1.5rem;
`;

interface ReviewMachineMtlTableProps {
  reviewMachineMtlData:
    | ReviewMachineMtlKdm[]
    | ReviewMachineMtlDsdm[]
    | ReviewMachineMtlMqtt[]
    | undefined;
  digitalEdgeType: DigitalEdgeType;
  updateReviewMachineMtlRow: UpdateRowValueFunc;
  updateReviewMachineMtlAttrsRow: UpdateRowValueFunc;
}

const ReviewMachineMtlTable: FC<ReviewMachineMtlTableProps> = ({
  reviewMachineMtlData,
  digitalEdgeType,
  updateReviewMachineMtlRow,
  updateReviewMachineMtlAttrsRow
}: ReviewMachineMtlTableProps): ReactElement => {
  const { t } = useTranslation(['mh', 'common']);
  const [showColumnOptions, setShowColumnOptions] = useState<boolean>(false);
  const triggershowColumnOptions = (): void => {
    setShowColumnOptions(true);
  };

  const [reviewTabId, setReviewTabId] = useState<string>();
  const [horizontalScrollRate, setHorizontalScrollRate] = useState<number>();
  const [tableHasScroll, setTableHasScroll] = useState(false);

  const [hideIcons, setHideIcons] = useState({
    leftIcon: true,
    rightIcon: false
  });

  const eTab = document.querySelector(`div#${reviewTabId} div div table`);

  useMemo(() => {
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLElement;

      if (target) {
        const clientWidth = target.clientWidth;
        const totalWidth = target.scrollWidth;
        const scrollLeft = target.scrollLeft;

        formatDecimalValue({
          value: clientWidth + scrollLeft,
          roundOffDigit: 0
        });
        console.log(
          'formatDecimalValue',
          formatDecimalValue({
            value: clientWidth + scrollLeft,
            roundOffDigit: 0
          }),
          formatDecimalValue({
            value: totalWidth,
            roundOffDigit: 0
          })
        );

        setHideIcons((prevState) => ({
          ...prevState,
          rightIcon:
            formatDecimalValue({
              value: clientWidth + scrollLeft,
              roundOffDigit: 0
            }) >=
            formatDecimalValue({
              value: totalWidth - 5,
              roundOffDigit: 0
            }),
          leftIcon: scrollLeft === 0
        }));
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    eTab?.parentElement?.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      eTab?.parentElement?.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [eTab]);

  useLayoutEffect(() => {
    setReviewTabId('reviewTagsPageTab');
    setHorizontalScrollRate(400);
  });

  useEffect(() => {
    setTableHasScroll(
      (eTab?.parentElement?.scrollWidth as number) > (eTab?.parentElement?.clientWidth as number)
    );
  }, [reviewTabId, eTab?.parentElement?.clientWidth]);

  const handleScrollRightReviewTable = () => {
    if (reviewTabId) {
      if (eTab?.parentElement) {
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        eTab.parentElement.scrollLeft += horizontalScrollRate!;
      }
    }
  };

  const handleScrollLeftReviewTable = () => {
    if (reviewTabId) {
      if (eTab?.parentElement) {
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        eTab.parentElement.scrollLeft -= horizontalScrollRate!;
      }
    }
  };

  return (
    <>
      <DeviceAccordionWrapper>
        {reviewMachineMtlData &&
          reviewMachineMtlData?.map((mtlData, index) => {
            return (
              <DeviceAccordion
                key={mtlData.machineTagListId}
                data={mtlData}
                digitalEdgeType={digitalEdgeType}
                onRowUpdate={updateReviewMachineMtlRow}
                name={t('rm_mtl_device', { item: index + 1 }) as string}
                triggershowColumnOptions={triggershowColumnOptions}
              >
                {tableHasScroll && (
                  <>
                    {!hideIcons.leftIcon && (
                      <LeftChevron handleScroll={handleScrollLeftReviewTable} />
                    )}
                    {!hideIcons.rightIcon && (
                      <RightChevron handleScroll={handleScrollRightReviewTable} />
                    )}
                  </>
                )}
                <ReviewMachineMtlAttrsTable
                  reviewMachineMtlData={mtlData}
                  digitalEdgeType={digitalEdgeType}
                  onRowUpdate={updateReviewMachineMtlAttrsRow}
                  showColumnOptions={showColumnOptions}
                  setShowcolumOptions={setShowColumnOptions}
                ></ReviewMachineMtlAttrsTable>
              </DeviceAccordion>
            );
          })}
      </DeviceAccordionWrapper>
    </>
  );
};

export default ReviewMachineMtlTable;
