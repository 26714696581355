import React from 'react';
import { faTriangleExclamation, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Loader, Typography } from 'components';
import { default as theme } from 'themes';
import {
  useGetDiagramNoErpQuery,
  useGetDiagramNoImageQuery,
  useGetDiagramZeroPriceQuery
} from 'api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DataQualityInImage } from 'types/machine-management';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { JBTRoutes } from 'constants/routes';
import { useUpdateMachineOnboardingStatusMutation } from 'api';
import { useWizard } from 'react-use-wizard';
import DownloadIcon from '../../../img/DownloadIcon.svg';
import RefreshIcon from '../../../img/refreshIcon.svg';
import {
  Container,
  Section,
  Title,
  Message,
  Row,
  TopButtonsContainer,
  ButtonsContainer,
  HeaderButtonContainer,
  HeaderButtonBox,
  ButtonWrapper,
  ButtonIcon,
  ButtonText
} from './DataQualityStyledComponents';

const dataText = {
  table1: {
    h2: 'Parts in diagram but not in ERP',
    message:
      'Compare part numbers ingested into OmniBlu with part numbers in ERP. Update part #’s number as appropriate to match ERP on previous screen.'
  },
  table2: {
    h2: 'Parts in diagram with “Contact JBT for Price”',
    message:
      'Check ERP flags to ensure applicable parts are active in customer portal and price is visible.'
  },
  table3: {
    h2: 'Parts in diagram without photos',
    message: 'Upload photos for photos missing parts. Ensure photo names match ERP part number.'
  }
};

export const DataQualityCheck = ({ machineId }: { machineId: string }): JSX.Element => {
  const history = useHistory();
  const { previousStep } = useWizard();
  const goToPillars = (machineId: string) => {
    history.push(JBTRoutes.onboardingPage.replace(':machineId', machineId));
  };
  const [updateMachineOnboardingStatus] = useUpdateMachineOnboardingStatusMutation();
  const {
    data: noErpData,
    isFetching: noErpFetching,
    refetch: noErpRefetch
  } = useGetDiagramNoErpQuery(
    machineId === ''
      ? skipToken
      : {
          machineId: machineId
        }
  );

  const {
    data: zeroPriceData,
    isFetching: zeroPriceFetching,
    refetch: zeroPriceRefetch
  } = useGetDiagramZeroPriceQuery(
    machineId === ''
      ? skipToken
      : {
          machineId: machineId
        }
  );

  const {
    data: noImageData,
    isFetching: noImageFetching,
    refetch: noImageRefetch
  } = useGetDiagramNoImageQuery(
    machineId === ''
      ? skipToken
      : {
          machineId: machineId
        }
  );

  function downloadQualityCheckCSV() {
    function SKUOrEmpty(list: DataQualityInImage[], index: number): string {
      if (index >= list.length) {
        return '';
      }
      return list[index].sku || '';
    }

    if (
      !noErpFetching &&
      !zeroPriceFetching &&
      !noImageFetching &&
      noErpData &&
      zeroPriceData &&
      noImageData
    ) {
      const numEntries = Math.max(noErpData.length, zeroPriceData.length, noImageData.length);
      const csv: BlobPart[] = [];
      csv.push(
        'Parts in diagram but not in ERP,Parts in diagram with Contact JBT for Price,Parts in diagram without photos,\n'
      );
      csv.push(
        ` ${SKUOrEmpty(noErpData, 0)}, ${SKUOrEmpty(zeroPriceData, 0)}, ${SKUOrEmpty(
          noImageData,
          0
        )}\n`
      );
      for (let i = 1; i < numEntries; i++) {
        csv.push(
          ` ${SKUOrEmpty(noErpData, i)}, ${SKUOrEmpty(zeroPriceData, i)}, ${SKUOrEmpty(
            noImageData,
            i
          )}\n`
        );
      }

      saveAs(new Blob(csv, { type: 'text/csv;charset=utf-8', endings: 'native' }), `qa_check.csv`);
    } else {
      toast.error(`Please wait for all data to finish loading`);
    }
  }

  return (
    <div>
      {noErpFetching || zeroPriceFetching || noImageFetching ? (
        <Loader margin="auto" />
      ) : (
        <HeaderButtonContainer>
          <HeaderButtonBox>
            <ButtonWrapper
              onClick={() => {
                noErpRefetch();
                zeroPriceRefetch();
                noImageRefetch();
              }}
            >
              <ButtonIcon src={RefreshIcon}></ButtonIcon>
              <ButtonText>Refresh</ButtonText>
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                downloadQualityCheckCSV();
              }}
            >
              <ButtonIcon src={DownloadIcon}></ButtonIcon>
              <ButtonText>Download</ButtonText>
            </ButtonWrapper>
          </HeaderButtonBox>
        </HeaderButtonContainer>
      )}
      <Container>
        {noErpFetching ? (
          <Loader margin="auto" />
        ) : (
          noErpData && (
            <TableSection dataText={dataText.table1} warnings={noErpData.length} data={noErpData} />
          )
        )}
        {zeroPriceFetching ? (
          <Loader margin="auto" />
        ) : (
          zeroPriceData && (
            <TableSection
              dataText={dataText.table2}
              warnings={zeroPriceData.length}
              data={zeroPriceData}
            />
          )
        )}
        {noImageFetching ? (
          <Loader margin="auto" />
        ) : (
          noImageData && (
            <TableSection
              dataText={dataText.table3}
              warnings={noImageData.length}
              data={noImageData}
            />
          )
        )}
      </Container>
      {noErpFetching || zeroPriceFetching || noImageFetching ? (
        <Loader margin="auto" />
      ) : (
        <>
          <ButtonsContainer></ButtonsContainer>
          <TopButtonsContainer>
            <Button
              onClick={() => {
                goToPillars(machineId);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={false}
              variant="thin"
              onClick={() => {
                previousStep();
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                await updateMachineOnboardingStatus({
                  machineId: machineId,
                  diagramsStatus: 'Done'
                })
                  .unwrap()
                  .then(() => {
                    toast.success(`Status of diagram onboarding updated successfully`);
                    goToPillars(machineId);
                  })
                  .catch((error) => {
                    toast.error(
                      `Failed to update the diagram status for the current machine${
                        error?.data?.detail ? `: ${error.data.detail}` : ''
                      }`
                    );
                    goToPillars(machineId);
                  });
              }}
            >
              Submit
            </Button>
          </TopButtonsContainer>
        </>
      )}
    </div>
  );
};

export const TableSection = ({
  dataText,
  warnings,
  data
}: {
  dataText: { h2: string; message: string };
  warnings: number;
  data: DataQualityInImage[];
}): JSX.Element => {
  return (
    <Section>
      <Title>
        <Typography variant="h2">{dataText.h2}</Typography>
        <Typography variant="modelheading">
          <FontAwesomeIcon icon={faTriangleExclamation} color={theme.colors.negativeRed} />
          {warnings} Issues
        </Typography>
      </Title>
      <Message>
        <FontAwesomeIcon icon={faCircleQuestion} />
        <Typography variant="body2">{dataText.message}</Typography>
      </Message>
      {data.map((dataQualityInImage, i) => {
        return (
          <Row key={i}>
            <div>
              <Typography variant="body2">
                {dataQualityInImage.partDescription || dataQualityInImage.description}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">{dataQualityInImage.sku}</Typography>
            </div>
          </Row>
        );
      })}
    </Section>
  );
};
