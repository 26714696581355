import { WidgetUiContainerOnlyStyles } from 'common/ui/WidgetUi/WidgetUi.elements';
import styled from 'styled-components';

// Styling
export const ViewContainer = styled.div`
  gap: 1em;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .machine-overview-widget {
    grid-column: span 2;
    min-height: 300px;
    .widget-ui__main {
      min-height: 300px;
    }

    img.full {
      width: 100%;
      height: auto;
      max-height: 220px;
    }
  }

  .current-issues-widget {
    grid-column: span 2;
  }

  .progress-tiles-widget {
    grid-column: span 4;
  }

  .kpi-progress-tile {
    grid-column: span 1;
    ${WidgetUiContainerOnlyStyles};
  }

  .machine-modes-graph-widget {
    grid-column: span 4;
  }
`;
