import React from 'react';

import { LargeTextTileWidget } from 'common/components';
import { useBeltSpeed } from './hooks/useBeltSpeed';

export const BeltSpeedWidget = (): JSX.Element => {
  const { beltSpeed } = useBeltSpeed();

  const rounded = Math.round(beltSpeed || 0);

  return (
    <LargeTextTileWidget
      className="belt-speed-widget"
      value={{ value: rounded || '-', label: 'Avg/Last 1min' }}
      title="Belt Speed"
    />
  );
};
