import React from 'react';
import theme from 'themes';

import { Typography } from 'components';
import { useTranslation } from 'react-i18next';
import Asterisk from './Asterisk ';
import { Dropdown } from './Dropdown';
import { AlertEnumTypes, TAlertData } from 'types/machine-health/alerts';
import { ValidationErrors } from '../CreateAlert';
import { ErrorBorderWrapper, InputWrapper, RequiredMessageWrapper } from './index.elements';
import { IcoError } from 'icons/IcoError';

interface IFrequencyDropdown {
  alertFrequencyUnits: AlertEnumTypes['alertFrequencyUnits'];
  alertData: TAlertData;
  onDropdownChange: (key: string, value: string | number | boolean, place?: string) => void;
  onBlurHandle: (key: string) => void;
  validationState: ValidationErrors;
  inputValidationsErrors: boolean;
}

export const FrequencyDropdown = ({
  alertFrequencyUnits,
  alertData,
  onDropdownChange,
  onBlurHandle,
  inputValidationsErrors
}: IFrequencyDropdown): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const onDropdownValueChange = (val: string) => {
    onDropdownChange('frequencyUnits', val);
  };

  const onFrequencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0) {
      onDropdownChange('frequencyValue', e.target.value);
    }
  };

  return (
    <div className="frequency_container">
      <div className="dropdown-container--label label">
        <Typography variant="inputlabel">
          <p className="label label-fields">
            {t('alert_frequency')} <Asterisk />
          </p>
        </Typography>
      </div>

      <InputWrapper className="inputHoverSec">
        <input
          type="number"
          name="frequency"
          min={0}
          onChange={onFrequencyChange}
          value={alertData.frequencyValue}
          onBlur={() => onBlurHandle('frequency')}
          placeholder="Value"
          style={{
            border: inputValidationsErrors
              ? alertData?.frequencyValue === undefined || alertData.frequencyValue < 1
                ? `0.0625rem solid ${theme.colors.text.error}`
                : '1px solid #e5e9ed'
              : '1px solid #e5e9ed',
            borderRadius: '0.375rem 0rem 0rem 0.438rem'
          }}
        />
        <ErrorBorderWrapper
          className="customSelectHover"
          style={{
            width: '8.2rem'
          }}
          error={inputValidationsErrors && alertData.frequencyUnits === undefined}
          hideLeftBorder={
            inputValidationsErrors
              ? alertData?.frequencyValue === undefined || alertData?.frequencyValue < 1
              : false
          }
        >
          <Dropdown
            vals={alertFrequencyUnits}
            onDropdownChange={onDropdownValueChange}
            value={alertData.frequencyUnits}
          />
        </ErrorBorderWrapper>
      </InputWrapper>
      <>
        {inputValidationsErrors ? (
          alertData?.frequencyValue === undefined ||
          alertData.frequencyUnits === undefined ||
          (alertData.frequencyValue as number) < 1 ? (
            <RequiredMessageWrapper className="label">
              <IcoError />{' '}
              <span>
                {alertData?.frequencyValue === undefined ||
                (alertData?.frequencyValue as number) < 1
                  ? Number(alertData?.frequencyValue as number) === 0
                    ? (alertData?.frequencyValue as unknown as string) === ''
                      ? t('please-add-frequency')
                      : t('frequency-cannot-be-0')
                    : t('please-add-frequency')
                  : t('please-add-frequency-unit')}
              </span>
            </RequiredMessageWrapper>
          ) : null
        ) : null}
      </>
    </div>
  );
};
