import * as d3 from 'd3';
import { styledTheme } from 'common/theme';
import Icon9 from 'icons/redPin.svg';
import { formatXaxisValues } from './utils';
import { SelectedItemsPropsHistory } from 'pages/FleetMachineDetail/hooks/useZoomHistoryhook';

type SvgInHtml = HTMLElement & SVGElement;

interface TooltipProps {
  width: number;
  height: number;
}

interface MarginsProps {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface AxisSettingsProps {
  xAxisKey: string;
  yAxisKey: string;
  yAxisKey2?: string;
  yAxisKey3?: string;
  yAxisKey4?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisDomainPadding: number;
  yDomainKeysToValuesToIgnore: string[];
}

interface ChartProps {
  data: Record<string, unknown>[];
  dataTooltipMessage: Record<string, unknown>[];
  data2: Record<string, unknown>[];
  height: number;
  width: number;
  axisSettings: AxisSettingsProps;
  margins: MarginsProps;
  domainX: Date[];
  domainY: number[];
  chartClass: string;
  dataColor1: string;
  dataColor2: string;
  dataColor3: string;
  dataColor4: string;
  verticalLineMessage: string;
  tooltipSettings: TooltipProps;
}

class VerticalLine {
  constructor({
    svg,
    data,
    xAxis,
    yAxis,
    xAxisKey,
    yAxisKey,
    chartHeight,
    margins,
    icon,
    displayErrorMsg,
    color
  }) {
    const { top, left, bottom, right } = margins;

    this.svg = svg;
    this.data = data;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.xAxisKey = xAxisKey;
    this.yAxisKey = yAxisKey;

    this.chartHeight = chartHeight;

    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;

    this.icon = icon;
    this.displayErrorMsg = displayErrorMsg;

    this.color = color;
  }

  appendVerticalLines(data, xKey) {
    this.svg
      .append('g')
      .selectAll('.vertical-indicator')
      .data(data)
      .join('line')
      .attr('transform', (d) => `translate(${this.xAxis(d[xKey])},0)`)
      .attr('class', 'vertical-indicator')
      .attr('y1', this.chartHeight - this.bottom)
      .attr('y2', 0)
      .attr('stroke', this.color)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '5,5');
  }

  appendVerticalLineErrorMessage(data, xKey, svg) {
    // Draw Error message
    svg
      .selectAll('foreignObject')
      .data(data)
      .join('foreignObject')
      .attr('x', (d) => this.xAxis(d[xKey]) - 300 / 2)
      .attr('y', 23)
      .attr('class', (_, i) => `message message--${i}`)
      .attr('width', 300)
      .attr('height', 105)
      .join('xhtml:div')
      .style('position', 'absolute')
      .html((d) => this.displayErrorMsg(d))
      .lower();
  }

  appendVerticalLineIcon(data, xKey) {
    // Draw Icon
    this.svg
      .append('g')
      .selectAll('.vertical-indicator-icon')
      .data(data)
      .join('foreignObject')
      .attr('x', (d) => this.xAxis(d[xKey]) - 17 / 2)
      .attr('y', 0)
      .attr('class', 'vertical-indicator-icon')
      .attr('width', 17)
      .attr('height', 23)
      .join('xhtml:div')
      .style('position', 'absolute')
      .html((d, i) => {
        return `<div class="vertical-indicator--mark ${i}">
                  <img src='${this.icon}' alt="Error icon" />
                </div>`;
      });
  }
}

class Tooltip {
  constructor({
    svg,
    data,
    xAxis,
    yAxis,
    xAxisKey,
    yAxisKey,
    yAxisKey2,
    displayTooltipMsg,
    width,
    height
  }) {
    this.svg = svg;
    this.data = data;

    this.xAxis = xAxis;
    this.yAxis = yAxis;

    this.xAxisKey = xAxisKey;
    this.yAxisKey = yAxisKey;
    // When object has 2 data points we want to display and we need a key to display the value
    this.yAxisKey2 = yAxisKey2;
    this.displayTooltipMsg = displayTooltipMsg; //function that returns a string - an error message that will be shown when an Icon is clicked

    this.width = width;
    this.height = height;

    // Create the tooltip container.
    this.tooltip = this.svg.append('g');
  }

  hideTooltip = () => {
    this.tooltip.selectAll('.tooltip--point').remove();
    this.tooltip.selectAll('.tooltip-container').remove();
  };

  showTooltip = (e, data, yKey) => {
    this.tooltip
      .selectAll('foreignObject')
      .data([,]) // eslint-disable-line no-sparse-arrays
      .join('foreignObject')
      .attr('x', this.xAxis(data[this.xAxisKey]) - this.width / 2)
      .attr('y', this.yAxis(data[yKey]) - this.height)
      .attr('class', 'tooltip-container')
      .attr('width', this.width)
      .attr('height', this.height)
      .join('xhtml:div')
      .style('position', 'absolute')
      .html(() => this.displayTooltipMsg(data));
  };
}

export default class DotsChart {
  constructor(
    element: HTMLElement,
    {
      data,
      dataTooltipMessage,
      data2, //alerts data
      height,
      width,
      axisSettings,
      margins,
      domainX,
      domainY,
      chartClass,
      dataColor1,
      dataColor2,
      dataColor3,
      dataColor4,
      verticalLineMessage,
      tooltipSettings
    }: ChartProps,
    updateZoomHistory?: (value: SelectedItemsPropsHistory) => void
  ) {
    const {
      xAxisKey,
      yAxisKey,
      yAxisKey2,
      yAxisKey3,
      yAxisKey4,
      xAxisLabel,
      yAxisLabel,
      yAxisDomainPadding,
      yDomainKeysToValuesToIgnore
    } = axisSettings;
    const { top, right, bottom, left } = margins;
    const { width: tooltipWidth, height: tooltipHeight } = tooltipSettings;
    this.tooltipWidth = tooltipWidth;
    this.tooltipHeight = tooltipHeight;

    this.element = element;
    this.data = data;

    this.data2 = data2 ? data2 : undefined;

    this.verticalLineMessage = verticalLineMessage;

    this.dataTooltipMessage = dataTooltipMessage;

    this.top = top;
    this.left = left;
    this.right = right;
    this.bottom = bottom;

    this.domainX = domainX;
    this.domainY = domainY;

    this.chartClass = chartClass;
    this.chartWidth = width;
    this.chartHeight = height;

    this.xAxisKey = xAxisKey;
    this.yAxisKey = yAxisKey;
    this.xAxisLabel = xAxisLabel;
    this.yAxisLabel = yAxisLabel;

    this.yAxisDomainPadding = yAxisDomainPadding;

    this.yDomainKeysToValuesToIgnore = yDomainKeysToValuesToIgnore;

    // When object has 2 data points we want to display and we need a key to display the value
    this.yAxisKey2 = yAxisKey2;
    this.yAxisKey3 = yAxisKey3;
    this.yAxisKey4 = yAxisKey4;

    // When object has 2 data points we want to display and need to assign different colors to them
    this.dataColor1 = dataColor1;
    this.dataColor2 = dataColor2;
    this.dataColor3 = dataColor3;
    this.dataColor4 = dataColor4;

    //zoom related
    this.idleTimeout = null;
    this.updateZoomHistory = updateZoomHistory;
    this.scatter = undefined;
    this.brush = undefined;

    this.x = undefined;
    this.y = undefined;

    // Delete svg if already exists
    d3.select(this.element).selectAll('svg').remove();

    this.svg = this.generateSVG(this.element, 'live-chart');

    chartClass && this.svg.attr('class', chartClass);

    // Create group for X axis
    this.xAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(0,${this.chartHeight - this.bottom})`);

    // Create group for Y axis
    this.yAxisGroup = this.svg.append('g').attr('transform', `translate(${this.left},0)`);

    this.scatter = this.generateZoomContainer(
      this.svg,
      this.chartWidth,
      this.chartHeight,
      this.left,
      this.right,
      this.top
    );

    this.brush = this.brushAction();

    this.brush.on('end', (e) => this.updateChartOnBrushEnd(e));
  }

  generateSVG(element: SvgInHtml, className: string): SvgInHtml {
    return d3
      .select(element)
      .append('svg')
      .classed('svg-content-responsive', true)
      .attr('width', this.chartWidth)
      .attr('height', this.chartHeight)
      .append('g')
      .attr('class', `${className}`);
  }

  generateZoomContainer(
    svg: SVGElement,
    width: number,
    height: number,
    left: number,
    right: number,
    top: number
  ): SvgInHtml {
    // Add a clipPath: everything out of this area won't be drawn.
    const clip = svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width - right - left)
      .attr('height', height - top)
      .attr('x', left)
      .attr('y', 0);

    // to make TS happy
    const showMSG = false;
    if (showMSG) console.log(clip);

    const scatter = svg.append('g').attr('clip-path', 'url(#clip)').attr('class', 'zoom-container');

    return scatter;
  }

  xGroup(x: SVGElement, xRange: Date[]): SVGElement {
    // Call X axis
    const xAxisCall = d3
      .axisBottom(x)
      .ticks(6)
      //.tickSizeOuter(0)
      .tickFormat((d) => formatXaxisValues(d, xRange));

    return this.xAxisGroup
      .call(xAxisCall)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('y1', 0)
          .attr('y2', -this.chartHeight + this.bottom + this.top)
          .transition()
          .duration(500)
          .attr('stroke-opacity', 0.1)
      )
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', styledTheme.colors.gray)
          .attr('transform', `translate(0, 10)`)
      )
      .call((g) => g.selectAll('.tick line:first-child').remove());
  }

  createYGroup(y: SVGElement): SVGElement {
    const yAxisCall = d3.axisLeft(y).ticks(5);
    this.yAxisGroup
      .call(yAxisCall)
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', this.chartWidth - this.left - this.right)
          .transition()
          .duration(500)
          .attr('stroke-opacity', 0.1)
      )
      .attr('fill', styledTheme.colors.gray)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .append('text')
          .attr('x', -(this.chartHeight / 2) + 10)
          .attr('y', -this.left / 2)
          .attr('fill', styledTheme.colors.gray)
          .attr('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('transform', 'rotate(-90)')
          .text(this.yAxisLabel)
      )
      .call((g) => g.selectAll('.tick text').attr('fill', styledTheme.colors.gray))
      .call((g) => g.selectAll('.tick line').attr('stroke', styledTheme.colors.gray))
      .call((g) => g.selectAll('.tick line:first-child').remove());

    return this.yAxisGroup;
  }

  updateYGroup(y: SVGElement): SVGElement {
    const yAxisCall = d3.axisLeft(y).ticks(5);
    this.yAxisGroup
      .call(yAxisCall)
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', this.chartWidth - this.left - this.right)
          .transition()
          .duration(500)
          .attr('stroke-opacity', 0.1)
      )
      .attr('fill', styledTheme.colors.gray)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick text').attr('fill', styledTheme.colors.gray))
      .call((g) => g.selectAll('.tick line').attr('stroke', styledTheme.colors.gray))
      .call((g) => g.selectAll('.tick line:first-child').remove());

    return this.yAxisGroup;
  }

  brushAction(): void {
    // Add brushing
    const brush = d3.brush().extent([
      //brush area
      [this.left, this.bottom],
      [this.chartWidth - this.right, this.chartHeight - this.top]
    ]);
    return brush;
  }

  idled(): void {
    return (this.idleTimeout = null);
  }

  removeOldData(): void {
    //Remove old data
    this.svg
      .selectAll('circle')
      .data(this.data)
      .exit()
      .transition()
      .duration(500)
      .attr('opacity', 0)
      .remove();
    this.svg
      .selectAll('line')
      .data(this.data)
      .exit()
      .transition()
      .duration(500)
      .attr('opacity', 0)
      .remove();
    this.svg.selectAll('.tooltip--point').remove();
    this.svg
      .selectAll('.vertical-indicator')
      .exit()
      .transition()
      .duration(100)
      .attr('opacity', 0)
      .remove();
  }

  appendDots(
    element: SVGElement,
    data: Record<string, unknown>[],
    xKey: string,
    yKey: string,
    color: string
  ): void {
    element
      .append('g')
      .attr('fill', 'none')
      .selectAll('circle')
      .data(data)
      .join('circle')
      .attr('transform', (d) => `translate(${this.x(d[xKey])},${this.y(d[yKey])})`)
      .attr('class', 'data' + yKey)
      .transition()
      .duration(500)
      .attr('r', 2)
      .attr('fill', () => color);
  }

  updateDots(element: SVGElement, xKey: string, yKey: string): void {
    const key = 'data' + yKey;

    element
      .selectAll(`.${key}`)
      .transition()
      .duration(500)
      .attr('transform', (d) => {
        return `translate(${this.x(d[xKey])},${this.y(d[yKey])})`;
      });
  }

  update(
    data: Record<string, unknown>[],
    data2: Record<string, unknown>[],
    domain: { chartDomainRangeX: Date[]; chartDomainRangeY?: number[] },
    updateZoomHistory: (value: SelectedItemsPropsHistory) => void
  ): void {
    this.data = data;
    this.data2 = data2 ? data2 : undefined;
    const keysToFilter = this.yDomainKeysToValuesToIgnore;

    this.updateZoomHistory = updateZoomHistory;
    const { chartDomainRangeX } = domain;
    const { chartDomainRangeY } = domain;
    this.chartDomainRangeX = chartDomainRangeX;

    // Create X axis
    this.x = d3
      .scaleTime()
      .domain(this.chartDomainRangeX)
      .range([this.left, this.chartWidth - this.right])
      .nice();

    const yDomain = d3.extent(this.data, (d) => {
      const filteredObject = Object.keys(d)
        .filter((objKey) => keysToFilter?.indexOf(objKey) == -1)
        .reduce((newObj, key) => {
          newObj[key] = d[key];
          return newObj;
        }, {});

      const maxNumber = Math.max(
        ...Object.values(filteredObject).reduce((arr, val) => {
          if (typeof val == 'number') {
            arr.push(parseInt(val));
            return arr;
          }
          return arr;
        }, [])
      );

      return maxNumber;
    });

    // This is a chart specific calculation. We don't want any negative values on Y axis, that's why we check what the minimum is
    // and if it is less than a hundred we 'hardocde' 0 as min Y axis value
    const calcMinY = yDomain[0] < 100 ? 0 : yDomain[0] - this.yAxisDomainPadding;

    const range = chartDomainRangeY
      ? chartDomainRangeY
      : this.yAxisDomainPadding
      ? [calcMinY, yDomain[1] + this.yAxisDomainPadding]
      : [yDomain];

    this.y = d3
      .scaleLinear()
      .domain(range)
      .range([this.chartHeight - this.top, this.bottom]) // Map higher values to lower screen coordinates
      .nice();

    // Style axises
    this.xGroup(this.x, this.chartDomainRangeX);
    this.createYGroup(this.y);

    //Add label to Y axis
    const yLabel = d3.selectAll('.dots-chart-yaxis--label');
    yLabel && this.yAxisLabel && yLabel.text(this.yAxisLabel);

    //Add label to X axis
    const xLabel = d3.selectAll('.dots-chart-xaxis--label');
    xLabel && this.xAxisLabel && xLabel.text(this.xAxisLabel);

    // Remove any old data
    this.removeOldData();

    // Add Vertical Lines
    const verticalLineSettings = {
      svg: this.scatter,
      data: this.data,
      xAxis: this.x,
      yAxis: this.y,
      xAxisKey: this.xAxisKey,
      yAxisKey: this.yAxisKey,
      chartHeight: this.chartHeight,
      margins: {
        top: this.top,
        right: this.right,
        bottom: this.bottom,
        left: this.left
      },
      icon: Icon9,
      displayErrorMsg: this.verticalLineMessage,
      color: styledTheme.colors.status.error.base
    };

    const verticalError = new VerticalLine(verticalLineSettings);
    data2 && verticalError.appendVerticalLineErrorMessage(data2, this.xAxisKey, this.svg);

    // Add the brushing
    this.scatter.append('g').attr('class', 'brush').call(this.brush);

    data2 &&
      verticalError.appendVerticalLines(data2, this.xAxisKey, styledTheme.colors.status.error.base);
    data2 && verticalError.appendVerticalLineIcon(data2, this.xAxisKey);

    // Append new Data
    data &&
      this.yAxisKey4 &&
      this.appendDots(this.scatter, data, this.xAxisKey, this.yAxisKey4, this.dataColor4);
    data &&
      this.yAxisKey3 &&
      this.appendDots(this.scatter, data, this.xAxisKey, this.yAxisKey3, this.dataColor3);
    data &&
      this.yAxisKey2 &&
      this.appendDots(this.scatter, data, this.xAxisKey, this.yAxisKey2, this.dataColor2);
    data && this.appendDots(this.scatter, data, this.xAxisKey, this.yAxisKey, this.dataColor1);

    // Tooltips
    const tooltipSettings = {
      svg: this.svg,
      data: this.data,
      xAxis: this.x,
      yAxis: this.y,
      xAxisKey: this.xAxisKey,
      yAxisKey: this.yAxisKey,
      yAxisKey2: undefined,
      displayTooltipMsg: this.dataTooltipMessage,
      width: this.tooltipWidth,
      height: this.tooltipHeight
    };

    const tooltip = new Tooltip(tooltipSettings);

    const circles1 = this.svg.selectAll(`.data${this.yAxisKey}`);
    const circles2 = this.svg.selectAll(`.data${this.yAxisKey2}`);
    const circles3 = this.svg.selectAll(`.data${this.yAxisKey3}`);
    const circles4 = this.svg.selectAll(`.data${this.yAxisKey4}`);

    // Show tooltip
    circles1.on('mousemove', (event, d) => {
      tooltip.showTooltip(event, d, this.yAxisKey);
    });

    // Hide tooltip
    circles1.on('mouseleave', () => {
      tooltip.hideTooltip();
    });

    // Show tooltip
    circles2.on('mousemove', (event, d) => {
      tooltip.showTooltip(event, d, this.yAxisKey2);
    });

    // Hide tooltip
    circles2.on('mouseleave', () => {
      tooltip.hideTooltip();
    });

    // Show tooltip
    circles3.on('mousemove', (event, d) => {
      tooltip.showTooltip(event, d, this.yAxisKey3);
    });

    // Hide tooltip
    circles3.on('mouseleave', () => {
      tooltip.hideTooltip();
    });

    // Show tooltip
    circles4.on('mousemove', (event, d) => {
      tooltip.showTooltip(event, d, this.yAxisKey4);
    });

    // Hide tooltip
    circles4.on('mouseleave', () => {
      tooltip.hideTooltip();
    });

    // Show/hide labels on click
    data2 &&
      d3.selectAll('.vertical-indicator-icon').on('click', (e) => {
        const parent = e.target.parentNode;
        const index = parent.className.split(' ')[1];
        const message = d3.select(`.message--${index}`);
        const msg = message.select('.message--inner');

        if (msg.attr('class').indexOf('visible') > -1) {
          msg.classed('visible', false);
          d3.selectAll('.message-group-wrapper').lower().lower().lower();
          message.lower();
        } else {
          msg.classed('visible', true);
          d3.selectAll('.message-group-wrapper').raise().raise().raise();
          message.raise();
        }

        e.preventDefault();
      });
  }

  updateChartOnBrushEnd(event: Event): void {
    const extent = event.selection;

    // If no selection, back to initial coordinate. Otherwise, update X axis domain
    if (!extent) {
      return;
    } else {
      const x1 = this.x.invert(extent[0][0]);
      const x2 = this.x.invert(extent[1][0]);
      const y1 = this.y.invert(extent[0][1]);
      const y2 = this.y.invert(extent[1][1]);

      this.updateZoomHistory.updateZoomHistory([
        [x1, x2],
        [y1, y2]
      ]);

      this.x.domain([x1, x2]);
      this.y.domain([y2, y1]);

      //Style axises
      this.xGroup(this.x, [x1, x2]);
      this.updateYGroup(this.y);

      // This remove the grey brush area as soon as the selection has been done
      this.scatter.select('.brush').call(this.brush.move, null);
    }

    // Update Data
    this.xAxisKey && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey);
    this.yAxisKey2 && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey2);
    this.yAxisKey3 && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey3);
    this.yAxisKey4 && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey4);

    // Update Alarm Data
    this.updateAlarmPositionOnZoom(this.x);
  }

  updateOnUndo(domain: { chartDomainRangeX: Date[]; chartDomainRangeY?: number[] }): void {
    if (!domain) return;

    const keysToFilter = this.yDomainKeysToValuesToIgnore;

    const { chartDomainRangeX, chartDomainRangeY } = domain;

    const x1 = chartDomainRangeX[0];
    const x2 = chartDomainRangeX[1];

    if (chartDomainRangeY) {
      const y1 = chartDomainRangeY[0];
      const y2 = chartDomainRangeY[1];
      this.y.domain([y2, y1]);
    } else {
      const yDomain = d3.extent(this.data, (d) => {
        const filteredObject = Object.keys(d)
          .filter((objKey) => keysToFilter.indexOf(objKey) == -1)
          .reduce((newObj, key) => {
            newObj[key] = d[key];
            return newObj;
          }, {});

        const maxNumber = Math.max(
          ...Object.values(filteredObject).reduce((arr, val) => {
            if (typeof val == 'number') {
              arr.push(parseInt(val));
              return arr;
            }
            return arr;
          }, [])
        );

        return maxNumber;
      });

      // This is a chart specific calculation. We don't want any negative values on Y axis, that's why we check what the minimum is
      // and if it is less than a hundred we 'hardocde' 0 as min Y axis value
      const calcMinY = yDomain[0] < 100 ? 0 : yDomain[0] - this.yAxisDomainPadding;

      const range = chartDomainRangeY
        ? chartDomainRangeY
        : this.yAxisDomainPadding
        ? [calcMinY, yDomain[1] + this.yAxisDomainPadding]
        : [yDomain];

      this.y.domain(range);
    }

    this.x
      .domain([x1, x2])
      .range([this.left, this.chartWidth - this.right])
      .nice();

    //Style axises
    this.xGroup(this.x, [x1, x2]);
    this.updateYGroup(this.y);

    // Update Data
    this.xAxisKey && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey);
    this.yAxisKey2 && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey2);
    this.yAxisKey3 && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey3);
    this.yAxisKey4 && this.updateDots(this.scatter, this.xAxisKey, this.yAxisKey4);

    this.updateAlarmPositionOnZoom(this.x);
  }

  updateAlarmPositionOnZoom(x: SVGElement): void {
    this.scatter
      .selectAll('.vertical-indicator')
      .transition()
      .duration(500)
      .attr('transform', (d) => `translate(${x(d[this.xAxisKey])},0)`);

    this.svg
      .selectAll('.message')
      .transition()
      .duration(500)
      .attr('x', (d) => x(d[this.xAxisKey]) - 300 / 2)
      .attr('y', 23);

    this.scatter
      .selectAll('.vertical-indicator-icon')
      .transition()
      .duration(500)
      .attr('x', (d) => x(d[this.xAxisKey]) - 17 / 2)
      .attr('y', 0);
  }
}
