import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoUntrash = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4790_10935)">
        <path
          d="M5.33333 2.66656H12.6667C13.0203 2.66656 13.3594 2.80704 13.6095 3.05709C13.8595 3.30714 14 3.64628 14 3.9999C14 4.35352 13.8595 4.69266 13.6095 4.94271C13.3594 5.19276 13.0203 5.33323 12.6667 5.33323H8M5.33333 5.33323H3.33333C3.0285 5.33341 2.73281 5.22913 2.49552 5.03777C2.25824 4.8464 2.09369 4.57951 2.0293 4.28155C1.9649 3.9836 2.00455 3.67257 2.14164 3.4003C2.27872 3.12803 2.50496 2.91095 2.78267 2.78523"
          stroke={'#0076CC' || color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33331 5.33319V11.9999C3.33331 12.3535 3.47379 12.6926 3.72384 12.9427C3.97389 13.1927 4.31302 13.3332 4.66665 13.3332H11.3333C11.5902 13.3332 11.8417 13.2589 12.0574 13.1194C12.2732 12.9798 12.444 12.7809 12.5493 12.5465M12.6666 9.99986V5.33319"
          stroke={'#0076CC' || color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66669 7.99988H8.00002"
          stroke={'#0076CC' || color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 1.99988L14 13.9999"
          stroke={'#0076CC' || color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4790_10935">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
