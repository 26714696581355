import styled from 'styled-components';
import theme from 'common/theme';

export const SplitListContainer = styled.dl.attrs(
  ({ className, scrollable }: { className?: string; scrollable?: boolean }) => ({
    className: `split-list ${className || ''}${scrollable ? `is-scrollable` : ``}`.trim()
  })
)<{ className?: string; scrollable?: boolean }>`
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr auto;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : undefined)};

  dd,
  dt {
    display: flex;
    align-self: start;
    padding: 0.5em 0;
    margin: 0;
    border-top: solid 1px ${theme.colors.border.lightest};

    &:nth-child(1),
    &:nth-child(2) {
      border-color: transparent;
    }
  }

  dt {
    width: 100%;
  }
  dd {
    font-weight: 800;
    justify-content: flex-end;
    color: ${theme.colors.mainLight};
  }
`;
