import React from 'react';
import { IntensifiersStrokeCountsListContainer } from './IntensifiersStrokeCountsList.elements';
import { StyledUiContainerProps } from 'components';
import { CollapseContainerWrapper } from './CollapseContainerWrapper';

export interface IntensifiersStrokeCountsListProps extends StyledUiContainerProps {
  data: Record<string, unknown>[];
  skidNum?: number;
  headers?: Record<string, string>[];
}

export const IntensifiersStrokeCountsList = ({
  data,
  skidNum,
  className,
  headers
}: IntensifiersStrokeCountsListProps): JSX.Element => {
  // set default skid number
  skidNum = skidNum || 1;
  // adjust classnames
  className = className
    ? `${className} intensifiers-stroke-counts-list`
    : `intensifiers-stroke-counts-list`;
  className += `intensifiers-stroke-counts-list--skid-${skidNum}`;

  const list = headers?.map(({ displayValue, lookupValue, color }, i) => {
    const intensifierTitle = displayValue;
    const intensifierData = (data && data[lookupValue]) || [];
    const intensifierColor = color;
    const settings = {
      title: intensifierTitle,
      data: intensifierData,
      color: intensifierColor
    };
    return <CollapseContainerWrapper key={i} {...settings} />;
  });

  return (
    <IntensifiersStrokeCountsListContainer {...{ className }}>
      <div className="list-area">
        <h2>Intensifier Stroke Counts Skid {skidNum}</h2>
        <div className="intensifiers-stroke-counts-list__scroll-area">{list}</div>
      </div>
    </IntensifiersStrokeCountsListContainer>
  );
};
