import styled from 'styled-components';

export const CarouselContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;

  .fleet-chevron-left {
    position: static;
    left: 1.125rem;
  }

  .fleet-chevron-right {
    position: static;
    right: 1.125rem;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: 1100px) {
    gap: 0.1rem;
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const Dot = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#0076CC' : '#D9D9D9')};
  margin: 0 0.313rem;
`;
