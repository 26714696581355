import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, defaultPatchQuery } from 'api';
import { addArgsToPath, defaultTransformResponse } from 'helpers';
import { RecipeUpdateItem } from './types';

const TAGS = ['RecipiesConfigs'];

export const prosealApi = createApi({
  reducerPath: 'prosealApi', //Key in the Redux store where the API slice's reducer state will be stored//prevents conflicts with other slices or reducers
  baseQuery: baseQueryWithReauth, // Replace '/api' with your actual base URL
  tagTypes: TAGS, //Tags are used to group endpoints together in the generated hooks
  endpoints: (builder) => ({
    getDowntimeData: builder.query({
      query: ({ machineId, ...rest }) => {
        return `/mh/v1/proseal/machines/${machineId ?? ''}/downtime${addArgsToPath(rest)}`;
      }
    }),
    getRecipes: builder.query({
      query: ({ machineId }) => {
        return `/mh/v1/machines/${machineId ?? ''}/proseal-recipes`;
      },
      transformResponse: defaultTransformResponse
    }),
    updateRecipe: builder.mutation<void, RecipeUpdateItem>({
      query: (formData) => {
        const { recipeId, targetPacksPerMinute, color } = formData;
        return defaultPatchQuery(
          { targetPacksPerMinute, color },
          `/mh/v1/proseal-recipe/${recipeId}`
        );
      },
      transformResponse: defaultTransformResponse
    })
  })
});

export const { useGetDowntimeDataQuery, useGetRecipesQuery, useUpdateRecipeMutation } = prosealApi;
