import React from 'react';
import { TileWidgetCellValueProps, TileWidget } from 'components';
import { useTranslation } from 'react-i18next';
import { useInfeedData } from './hooks/useProductTypeWidget';
import useMachineHealthByBuKpi from 'hooks/useMachineHealthByBuKpi';
import { find, last, round } from 'lodash';
import { useParams } from 'react-router-dom';
import { DSIKPIType, MachineHealthInterval, BusinessUnit, DSIKpiId } from 'types/dsi';

const InfeedTile = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { cellValues, isLoading, hasError, hasMessage } = useInfeedData() as {
    isLoading?: boolean;
    hasError?: string;
    hasMessage?: string;
    cellValues?: TileWidgetCellValueProps[];
  };

  console.log({ cellValues });
  return (
    <TileWidget
      ga="c-2"
      title={t('infeed_piece_size') as string}
      {...{
        isLoading,
        hasError,
        hasMessage,
        cellValues
      }}
    />
  );
};

export const ProductTiles = (): JSX.Element => {
  return (
    <div className="product-tiles">
      <InfeedTile />
    </div>
  );
};

export const ProductionValuesV1 = (): JSX.Element => {
  const { machineId } = useParams<{ machineId: string }>();
  const { t } = useTranslation(['mh']);

  const { machineHealth: currentKpiResult, isLoading: isLoading } = useMachineHealthByBuKpi(
    machineId,
    DSIKPIType.DsiCurrentKpi,
    MachineHealthInterval.LastHour,
    true,
    BusinessUnit.DSI
  );

  const { machineHealth: infeedPieceSizeResult, isLoading: isLoadingInfeed } =
    useMachineHealthByBuKpi(
      machineId,
      DSIKPIType.InfeedPieceSize,
      MachineHealthInterval.LastHour,
      true,
      BusinessUnit.DSI
    );

  const currentPsu = find(currentKpiResult, (o) => o.id === DSIKpiId.CurrentPSU);
  const appName = find(currentKpiResult, (o) => o.id === DSIKpiId.App);
  const infeedPieceSizeItem = find(infeedPieceSizeResult, (o) => o.id === DSIKpiId.InfeedPiece);
  const infeedPieceSizeLastValue = infeedPieceSizeItem?.values && last(infeedPieceSizeItem.values);
  const infeedPieceSizeValueModified =
    infeedPieceSizeLastValue?.value && round(infeedPieceSizeLastValue.value, 0).toLocaleString();

  const productLeftCellValues = [
    {
      value: `${infeedPieceSizeValueModified ?? '-'}${infeedPieceSizeValueModified ? 'g' : ''}`,
      title: t('avg_last_1min') as string
    }
  ];

  const productRightCellValues =
    appName &&
    (appName.values.map(({ value }, i) => ({
      value,
      title: `${t('asu', { item: i + 1 }) as string}`
    })) as TileWidgetCellValueProps[]);

  return (
    <>
      <div>
        <TileWidget
          ga="c-1"
          isLoading={isLoadingInfeed ? true : false}
          title={t('infeed_piece_size') as string}
          cellValues={productLeftCellValues}
        />
      </div>
      <div>
        <TileWidget
          ga="c-2"
          isLoading={isLoading}
          title={`${currentPsu?.value?.value || ``}`}
          subTitle={t('current_psu') as string}
          cellValues={productRightCellValues}
        />
      </div>
    </>
  );
};
