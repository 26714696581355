import React from 'react';

export const Idle = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6349_196573)">
        <path
          d="M5.6001 16.7607H22.4001V5.64575H5.6001V25.4057"
          stroke="#8A8886"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_6349_196573)">
          <path
            d="M17.6123 11.6148C17.6986 10.8571 17.547 10.0913 17.1786 9.42354C16.8102 8.75579 16.2431 8.21921 15.5561 7.88818C14.869 7.55715 14.096 7.44806 13.3442 7.57605C12.5923 7.70403 11.899 8.06274 11.3601 8.60246C10.8213 9.14217 10.4637 9.83615 10.337 10.5882C10.2103 11.3402 10.3206 12.1131 10.6528 12.7996C10.9849 13.4861 11.5224 14.0523 12.1908 14.4196C12.8591 14.7869 13.6252 14.9372 14.3828 14.8497"
            stroke="#8A8886"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9609 9.15747V11.1992L14.7776 12.0158"
            stroke="#8A8886"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.0027 13.241V15.2827"
            stroke="#8A8886"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.636 13.241V15.2827"
            stroke="#8A8886"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6349_196573">
          <rect width="28" height="28.2284" fill="white" />
        </clipPath>
        <clipPath id="clip1_6349_196573">
          <rect width="9.8" height="9.80008" fill="white" transform="translate(9.06104 6.29956)" />
        </clipPath>
      </defs>
    </svg>
  );
};
