import React from 'react';
import { ViewContainer } from './View.elements';
import { MachineOverviewWidget } from './MachineOverviewWidget';
import { CurrentIssuesWidget } from '../_components/CurrentIssuesWidget/CurrentIssuesWidget';
import { ProgressTilesRow } from './ProgressTileWidget/ProgressTilesRow';
import { MachineModes } from './MachineModesWidgetV2';

export const OverviewView = (): JSX.Element => {
  return (
    <ViewContainer className="view-container view-container--overview">
      <MachineOverviewWidget />
      <CurrentIssuesWidget />
      <ProgressTilesRow />
      <MachineModes />
    </ViewContainer>
  );
};
