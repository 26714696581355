import React, { useMemo } from 'react';
import { ActiveIssuesWidget } from 'common/components/WidgetUi/ActiveIssuesWidget/ActiveIssuesWidget';
import { useGetMachineDataScienceAlertsQuery } from 'api';
import { useRouter } from 'common/hooks/useRouter';
import { useDateRange } from 'components';
import { processApiData } from 'common/helpers/processApiData';
import { AlertType, AlertStatus, AlertCriticality } from 'types/machine-health/alerts';

export const demoData = [
  {
    description: 'consectetur adipiscing elit',
    detailedInstructions: 'lorem ipsum dolor sit amet, consectetur adipiscing elit',
    createdAt: '2021-10-11T11:16:25+00:00'
  },
  {
    description: 'consectetur adipiscing elit',
    detailedInstructions: 'lorem ipsum dolor sit amet, consectetur adipiscing elit',
    createdAt: '2021-10-11T12:19:25+00:00'
  },
  {
    description: 'consectetur adipiscing elit',
    detailedInstructions: 'lorem ipsum dolor sit amet, consectetur adipiscing elit',
    createdAt: '2021-10-11T13:16:25+00:00'
  },
  {
    description: 'consectetur adipiscing elit',
    detailedInstructions: 'lorem ipsum dolor sit amet, consectetur adipiscing elit',
    createdAt: '2021-10-11T14:16:25+00:00'
  }
];

export const demoChartData = demoData.map((item, id) => ({
  CustomRow: () => <CustomRow key={id} {...item} />,
  timestamp: item?.createdAt as string
}));

const CustomRow = (item?: Record<string, unknown>) => (
  <>
    {item?.description && (
      <div className="description active-issues__row">
        <strong className="description">{item?.description as string}</strong>
      </div>
    )}

    {item?.detailedInstructions && (
      <div className="instructions active-issues__row">{item?.detailedInstructions as string}</div>
    )}

    {item?.subcomponent && (
      <div className="instructions active-issues__row sub-component">
        {`Subcomponent: ${item?.subcomponent as string}`}
      </div>
    )}
  </>
);

export const CurrentIssuesWidget = (): JSX.Element => {
  const { machineId } = useRouter('/fleet/page/machineId') as { machineId: string };

  const { isoDateRange, timeZone } = useDateRange();

  // the below returns a obj that "SHOULD" (i can't verify until we see an alarm)
  // id: string;
  // description: string;
  // alertType: AlertType;
  // createdAt: string;
  // status: AlertStatus;
  // I DONT THINK THINK THIS IS GOING TO WORK WITHOUT A NEW API
  const { data, isLoading, error } = useGetMachineDataScienceAlertsQuery({
    machineId,
    startDatetime: isoDateRange.startTime,
    endDatetime: isoDateRange.endTime,
    alertType: 'maintenance'
  }) as {
    data?: {
      description?: string;
      alertType?: AlertType;
      createdAt?: string;
      status?: AlertStatus;
      subcomponent?: string;
      criticality?: AlertCriticality;
      detailedInstructions?: string;
    }[];
    isLoading?: boolean;
    error?: Record<string, unknown>;
  };

  // get the data ready for the widget
  const processedData = useMemo(() => {
    if (data)
      return processApiData(data, {
        startTimeKey: 'createdAt',
        timeZone,
        formatTimestamps: 'P, p'
      })
        ?.sort((a, b) => String(b?.createdAt).localeCompare(String(a?.createdAt)))
        ?.map((item) => ({
          CustomRow: () => <CustomRow {...item} />,
          // we'll send the formatted time stamp here
          timestamp: item?.createdAtFormatted as string
        }));
  }, [data]);

  let hasError = undefined;
  let hasMessage = undefined;
  let noData = false;

  if (error) hasError = 'error getting alerts';
  if (!isLoading && !hasError && !data) hasError = 'error getting data';
  if (!hasMessage && !hasError && !isLoading) {
    if (!data) hasError = 'no data';
    else if (data.length === 0) {
      hasMessage = 'No current issues';
      noData = true;
    }
  }

  const widgetSettings = {
    title: 'current issues',
    className: 'current-issues-widget',
    hasError,
    hasMessage,
    isLoading,
    noData,
    tableData: processedData
  };

  return <ActiveIssuesWidget {...widgetSettings} />;
};
