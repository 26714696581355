import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntensifierAlarmsTableContainer } from './IntensifierAlarmsWidget.elements';
import NewBaseTable, { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import { TableColumnConfigs } from 'types';
import { CellContext } from '@tanstack/react-table';
import { ProgressCell } from './ProgressCell';

const colors: Record<string, unknown> = {
  Critical: 'rgba(182, 44, 16, 1)',
  Product: 'rgba(0, 118, 204, 1)',
  Warning: 'rgba(226, 178, 8, 1)'
};

interface TableProps {
  tableData?: Record<string, unknown>[];
  filter: { type: string[]; scope: string };
  columnMapping: Record<string, string>;
  total_alarm_count?: number;
  total_cycles?: number;
}

export const Table = ({
  tableData,
  filter,
  columnMapping,
  total_alarm_count,
  total_cycles
}: TableProps): JSX.Element => {
  const { t } = useTranslation(['mh']);

  // Filter tabledata
  const checkAlarms = (type: string, val: string) => {
    if (!val) return false;
    switch (type) {
      case 'type':
        if (filter.type.length === 0 || filter.type?.includes(val)) return true;
        return false;
      case 'scope':
        if (filter.scope.length === 0 || filter.scope === val) return true;
        return false;
    }
  };

  const isFiltered = (
    row: Record<string, string>,
    filter: { type: string[]; scope: string }
  ): boolean => {
    const selAlarm = row && checkAlarms('type', row[columnMapping['category']]);
    const selSelect = row && checkAlarms('scope', row[columnMapping['scope']]);

    if ((filter.type.length == 0 || filter.type.length === 3) && filter.scope === '') {
      return true;
    } else if ((filter.type.length != 0 || filter.type.length < 3) && filter.scope === '') {
      if (!selAlarm) return false;
      return true;
    } else if ((filter.type.length != 0 || filter.type.length < 3) && filter.scope != '') {
      if (selAlarm && selSelect) {
        return true;
      }
    } else if ((filter.type.length == 0 || filter.type.length === 3) && filter.scope != '') {
      if (!selSelect) return false;
      return true;
    } else {
      return false;
    }

    return false;
  };

  const mapRow = (row: Record<string, unknown>): Record<string, unknown> => {
    const mappedRow = {
      ...row,
      alarm_category: row[columnMapping['category']],
      alarm_description: row[columnMapping['alarm_description']],
      scope: row[columnMapping['scope']] ? row[columnMapping['scope']] : 'Skid 1',
      alarm_id: row[columnMapping['alarm_id']]
    };

    return mappedRow;
  };

  const columns: ColumnConfig[] = [
    {
      id: 'alarm_category',
      header: 'Category',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'scope',
      header: 'Scope',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'alarm_id',
      header: 'Alarm ID',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'alarm_description',
      header: 'Alarm Description',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'occurences',
      header: (
        <>
          {t('alarm occurrences')}
          <span className="sub-text">
            [{total_alarm_count} Alarms in {total_cycles} Cycles]
          </span>
        </>
      ),
      isEnableSorting: true,
      isSelected: true,
      renderer: (row: CellContext<TableColumnConfigs, string>) => {
        const alarmRatioCell = {
          value: row.row.original.occurences_ratio as number,
          label: `${row.row.original.occurences}`,
          color: colors[row.row.original.alarm_category as string] as string,
          labelPosition: 'outside'
        };

        return <ProgressCell {...alarmRatioCell} />;
      }
    }
  ];

  const defaultSortStates = {
    id: 'occurences',
    desc: true
  };

  return (
    <IntensifierAlarmsTableContainer>
      <NewBaseTable
        newTableData={
          tableData?.reduce((filtered: Record<string, unknown>[], row) => {
            // @ts-expect-error: error
            if (isFiltered(row, filter)) {
              const mappedObject = mapRow(row);
              filtered.push(mappedObject);
            }
            return filtered;
          }, []) as unknown as TableColumnConfigs[]
        }
        columnConfigs={columns}
        sortState={defaultSortStates}
        isShowColumnOptions={false}
        disableVirtualize={true}
        customClassName="intensifiers-alarms-table"
        hideTotalFetchCount={true}
      />
    </IntensifierAlarmsTableContainer>
  );
};
