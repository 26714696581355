import React, { useState, useRef, RefObject, useEffect } from 'react';
import { DateRangeProps } from '..';
import useClickOutside from 'hooks/useClickOutside';
import { getUserTimeZone, isUSTimeZone } from 'helpers';
import { DateButtonWithDropdownProps } from '../DateRange/DateButtonWithDropdown/DateButtonWithDropdown.types';
import { DateButtonWithDropdownContainer } from '../DateRange/DateButtonWithDropdown/DateButtonWithDropdown.elements';
import { DisplayDateRangeButton } from './DisplayDateRangeButton';
import { DisplayDateRangePicker } from './DisplayDateRangePicker';

const RefUser = (ref: RefObject<HTMLDivElement>, cb: (x?: boolean) => void) =>
  useClickOutside(ref, () => cb(false));

export const DisplayDateButtonWithDropdown = ({
  className,
  ga,
  dateRange,
  setDateRange,
  alignDropdown,
  iconOnly,
  hideIcon,
  placeholder,
  hasGoBackDateLimit,
  hideTimeInputs,
  hideDateRangeSelect,
  hideTimeRangeSelect,
  hideCalendar,
  dateFormat,
  hasFutureDates,
  timeZone
}: DateButtonWithDropdownProps): JSX.Element => {
  /** setting wrapping div css class */
  className = className ? `${className} ui-date-button-w-dropdown` : `ui-date-button-w-dropdown`;

  if (iconOnly) className += ` icon-only`;
  if (hideIcon) className += ` icon-hidden`;
  /** show/hide calendar dropdown */
  const [showCalendar, toggleCalendar] = useState(false);
  const [key, setKey] = useState(0);

  /** add custom class if calendar is open for extra styling */
  className = showCalendar ? `${className} is-open` : className;

  /** change formatting of button if the time select options are hidden */
  if (hideTimeRangeSelect) dateFormat = dateFormat || 'date-only';

  //this is to force DisplayDateRangeButton to re-mount when date range changes
  useEffect(() => {
    setKey((c) => c + 1);
  }, [dateRange]);

  const buttonSettings = {
    handleClick: () => toggleCalendar(!showCalendar),
    iconOnly,
    placeholder,
    dateFormat,
    dateRange,
    isUSTimeZone: isUSTimeZone(getUserTimeZone()),
    timeZone
  };

  const rangePickerSettings = {
    handleSubmit: (range: DateRangeProps) => {
      setDateRange(range);
      toggleCalendar(false);
    },
    handleCancel: () => toggleCalendar(false),
    dateRange,
    hasGoBackDateLimit,
    hideTimeInputs,
    hideDateRangeSelect,
    hideTimeRangeSelect,
    hideCalendar,
    hasFutureDates,
    timezone: timeZone
  };

  const ref = useRef<HTMLDivElement>(null);

  if (ref) RefUser(ref, () => toggleCalendar(false));

  return (
    <DateButtonWithDropdownContainer {...{ className, ga, alignDropdown }} ref={ref}>
      <DisplayDateRangeButton key={key} {...buttonSettings} />
      {showCalendar && <DisplayDateRangePicker {...rangePickerSettings} />}
    </DateButtonWithDropdownContainer>
  );
};
