import styled from 'styled-components';
import { themeColors } from 'themes';

export const ExpressionWrapper = styled.div``;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: -0.75rem;
`;

export const OperationIcons = styled.span`
  cursor: pointer;
`;

export const TagNameWrapper = styled.div<{ isDisabled?: boolean }>`
  background: ${({ isDisabled }) => (isDisabled ? '#f6f7f7' : themeColors.white)};
  padding: 0.4rem 0.8rem;
  border: 0.063rem solid ${themeColors.lightGrey6};
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  border-bottom: 0.125rem dashed ${themeColors.lightGrey6} !important;
  height: 2rem;
  display: flex;
  justify-content: space-between;
`;

export const TagNameDiv = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${themeColors.mediumGrey3};
`;

export const OperationIconsWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  margin: -0.125rem 0rem;
`;
export const MentionWrapper = styled.div`
  .mentions--multiLine .mentions__control {
    font-family: roboto;
    font-size: 0.875rem;
    border-top: 0px;
    border-radius: 0.375rem;
    min-height: 8.125rem !important;
    background-color: ${themeColors.white};

    > textarea:disabled {
      background-color: #f6f7f7 !important;
    }
  }

  .mentions--multiLine .mentions__highlighter {
    border: 0.063rem solid transparent;
    border-top: 0px;
    padding: 0.563rem;
    min-height: 3.938rem;
    border-radius: 1.25rem;
    font-size: 0.875rem;
  }

  .mentions--multiLine .mentions__input {
    border: 0.063rem solid ${themeColors.lightGrey6};
    padding: 0.563rem;
    outline: 0;
  }

  .mentions__suggestions__list {
    background-color: ${themeColors.white};
    border: 0.063rem solid ${themeColors.lightGrey6};
    font-size: 9pt;
    height: auto;
    max-height: 15rem;
    width: 15rem;
    overflow: auto;
    border-radius: 0.313rem;
  }

  .mentions__suggestions__item {
    padding: 0.5rem 0.938rem;
    border-bottom: 0.063rem solid ${themeColors.lightGrey6};
    font-weight: 400;
  }

  .mentions__suggestions__item--focused {
    background-color: ${themeColors.primaryBlue5};
    color: ${themeColors.white};
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 0.063rem 0.063rem 0.063rem ${themeColors.white},
      0.063rem -0.063rem 0.063rem ${themeColors.white},
      -0.063rem 0.063rem 0.063rem ${themeColors.white},
      -0.063rem -0.063rem 0.063rem ${themeColors.white};
    text-decoration: underline;
    pointer-events: none;
    border-top: 0px;
  }

  .mentions__input {
    color: ${themeColors.lightGrey8};
    font-size: 0.875rem !important;
    min-height: 6.25rem !important;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top: 0px !important;
    font-family: Roboto !important;
    padding: 0.5rem 0.8rem !important;
  }

  .mentions-disabled {
    color: ${themeColors.lightGrey8};
    font-size: 0.875rem !important;
    min-height: 6.25rem !important;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top: 0px !important;
    padding: 0.5rem 0.8rem !important;
    background: grey !important;
  }

  .mentions__input::placeholder {
    color: ${themeColors.mediumGrey3};
    font-size: 0.875rem !important;
    font-family: Roboto !important;
  }
`;

export const TagDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
