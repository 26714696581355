import React from 'react';
import { Idle } from '../../../../icons/idle';
import { Sterilization } from '../../../../icons/Sterilization';
import { Cleaning } from '../../../../icons/Cleaning';
import { Maint } from '../../../../icons/maint';
import { WaitTime } from '../../../../icons/waittime';
import { Production } from 'icons/productionState';
import { NoProduct } from '../../../../icons/no_product';

export const calculateTimeDifference = (start: string, end: string): string => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculate the time difference in milliseconds
  const timeDifference = (endDate as unknown as number) - (startDate as unknown as number);

  // Convert the time difference to hours and minutes
  const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1 minute = 60000 milliseconds

  // Format the result
  const formattedTime = `${hours}h ${minutes}min`;

  return formattedTime;
};

export const ICONMAP = {
  0: <Idle />,
  4: <Sterilization />,
  3: <Cleaning />,
  'WAIT TIME': <WaitTime />,
  WAITTIME: <WaitTime />,
  1: <Maint />,
  2: <Production />,
  Offline: <WaitTime />,
  'Not Running': <Idle />,
  Running: <Production />,
  'No Product': <NoProduct />
};
