import React from 'react';
import { KPIProgressTile } from 'common/components/KPIProgressTile';
import { useKPIApi } from '../hooks/useKPIApi';

export const ProcessedProductPercentageKPIProgressTile = (): JSX.Element => {
  const { data, progress, ...rest } = useKPIApi('processed-product-percentage');

  const progressTileSettings = {
    ...rest,
    progress: progress as number,
    title: 'processed product percentage',
    className: 'processed-product-percentage',
    labelKey: 'name',
    kpiRows: data,
    format: {
      infoTooltip: () => 'Progress bar reflects the 30-minute performance compared to the target.'
    }
  };

  return <KPIProgressTile {...progressTileSettings} />;
};
