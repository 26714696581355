import styled from 'styled-components';

export const ChangeOverStepsOverTimeCotainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;

  .change-over-step-durations-bar-chart-widget,
  .widget-ui {
    flex-grow: 1;
    min-height: 300px;
  }

  .pie-chart-container,
  .pie-chart-container .dimensions-container {
    width: 180px;
    height: 180px;
  }
`;
