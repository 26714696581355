// 3rd party libs
import React from 'react';
import styled from 'styled-components';
import { usePressurizeCycleDataById } from '../hooks';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { NewBaseTable, WidgetUi, useDateRange } from 'components';
import { CellContext } from '@tanstack/react-table';
import { IcoCheckIn } from 'icons/IcoCheckIn';
import { IcoError } from 'icons/IcoError';
import { TableColumnConfigs } from 'types';
export interface PressurizedPerformancePanelProps {
  machineId?: string;
}

const CycleTableContainer = styled.div`
  header {
    display: flex;
    gap: inherit;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.3em;
    padding: 0.25rem;
  }
  .customcss {
    padding: 0rem 0.5rem;
  }
`;

export const PressurizationPerformanceTable = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const { isLoading, tableData } = usePressurizeCycleDataById();
  const { timeZone } = useDateRange();

  const columns = [
    {
      id: 'batchSuccessful',
      header: '',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        cellValue.getValue() ? <IcoCheckIn /> : <IcoError />
    },
    {
      id: 'startTime',
      header: 'start Time',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        formatInTimeZone(parseISO(cellValue.getValue()), timeZone, `P' - 'p`)
    },
    {
      id: 'lotId',
      header: 'lot Id',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
    },
    {
      id: 'batchNumber',
      header: 'batch Number',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => cellValue.getValue()
    },

    {
      id: 'pressurizeTime',
      header: 'Actual Pressurization Time',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        `${Math.floor(Number(cellValue.renderValue()))} sec`
    },
    {
      id: 'idealPressurizeTime',
      header: 'Target Pressurization Time',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        `${Math.floor(Number(cellValue.renderValue()))} sec`
    },
    {
      id: 'deltaPressurizeTime',
      header: 'Pressurization Time Result',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        `${Math.floor(Number(cellValue.renderValue()))} sec`
    },
    {
      id: 'cycleTime',
      header: 'cycle Time',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        `${cellValue.renderValue()} sec`
    }
  ];

  const defaultSortStates = {
    id: 'startTime',
    desc: true
  };
  const widgetSettings = {
    isLoading: isLoading,
    Main: (
      <CycleTableContainer className="widget-ui-main">
        <header>{t('production_cycles')}</header>
        {tableData && (
          <NewBaseTable
            newTableData={tableData}
            columnConfigs={columns}
            sortState={defaultSortStates}
            isShowColumnOptions={false}
            disableVirtualize={false}
          />
        )}
      </CycleTableContainer>
    )
  };

  return (
    <>
      <WidgetUi {...widgetSettings} />
    </>
  );
};
