import React from 'react';
import { IntensifiersStrokeCountsListContainer } from '../IntensifiersStrokeCountsList/IntensifiersStrokeCountsList.elements';
import { CollapseContainerWrapper } from '../IntensifiersStrokeCountsList/CollapseContainerWrapper';
import { ClockIcon } from 'icons/ClockIcon';
import { Speedometericon } from 'icons/SpeedometerIcon';

interface Props {
  data: Record<string, unknown>[];
  headers: string[];
  className: string;
}

export const PressurizationTimeList = ({ data, headers, className }: Props): JSX.Element => {
  if (!data || !headers) return <></>;

  const list = headers?.map((header, i) => {
    header = header as unknown as string;
    const settings = {
      title: header as unknown as string,
      data: data[header.trim()],
      ItemIcon: <ClockIcon />,
      TitleIcon: <Speedometericon />
    };
    return <CollapseContainerWrapper key={i} {...settings} />;
  });

  return (
    <IntensifiersStrokeCountsListContainer {...{ className }}>
      <div className="list-area">
        <h2>Pressurization Delta</h2>
        <div className="intensifiers-stroke-counts-list__scroll-area">{list}</div>
      </div>
    </IntensifiersStrokeCountsListContainer>
  );
};
