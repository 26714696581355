import { BarChartWidget } from 'common/components';
import { formatZonedTimestamp } from 'common/helpers/dateAndTimeHelpersV2';
import { colors as alarmByTypeColors } from 'common/pages/fleetV2/settings/protein';
import { useFilterSelected } from 'components';
import {
  convertToChartData,
  convertToStackedChartData
} from 'components/StyledUi/StyledChartsV2/helpers';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartTooltip } from './Alarms.elements';
import { StyledChartLegendProps } from 'components/StyledUi/StyledChartsV2/ChartLegends';
import { utcToZonedTime } from 'date-fns-tz';
import { parse } from 'date-fns';
import { useTimeZone } from 'providers';
import { handleSliceClick } from './AlarmByTypePieChart';

interface UseStyledChartProps {
  title?: string;
  hasLegend?: boolean;
  data?: Record<string, unknown>[];
  isLoading?: boolean;
  hasMessage?: string;
  hasError?: string;
  hideLegend?: boolean;
  groupKey?: string;
  filteredByKeys?: string[];
  type?: string;
  categoryKey?: string;
  dateKey?: string;
  legendSettings?: StyledChartLegendProps;
  colors?: Record<string, unknown>;
  customLegends?: Record<string, unknown>[];
}

const handleBarClick = (
  datum: Record<string, unknown>,
  groupKey: string,
  categoryKey: string,
  handleSelect: (x: string, sel?: Record<string, string | string[]>) => void,
  options?: {
    selected?: Record<string, string[]>;
    filteredByKeys?: string[];
  }
): void => {
  // for ts
  const selectedCat = datum[categoryKey] as string;

  const newSelected = {
    [categoryKey]: selectedCat,
    ...options?.selected
  };
  if (newSelected.searchTerm) delete newSelected.searchTerm;
  if (options?.selected) {
    return handleSelect('toggle');
  } else {
    return handleSelect('set', newSelected);
  }
};

const AlarmByTypeBarChart = ({
  hideLegend,
  data,
  isLoading,
  hasError,
  title,
  filteredByKeys,
  customLegends,
  ...barChartProps
}: UseStyledChartProps): JSX.Element => {
  const { groupKey, type, colors } = barChartProps;
  const { timeZone } = useTimeZone();
  const { t } = useTranslation(['mh']);

  const legendItems = Object.keys(colors || {})
    .map((item) => {
      if (item && item.length) {
        return {
          label: t(item),
          color: alarmByTypeColors[item?.toLowerCase()] || '#000000'
        };
      }
    })
    .filter(Boolean);

  const checkIfSelected = (slice: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (slice.label as string) || (slice.group as string);
    const selectedVals = t(selected?.type) || selected?.activerecipe || [];
    if (selectedVals.length && selectedVals.includes(group)) return true;
    return false;
  };

  if (!groupKey) return <>error loading chart, missing groupKey</>;
  // checking for stacked bars or grouped bars
  const isGrouped = type !== 'bar';

  // lines up with bottom axis as well, typically date.  use a different chart if you're doing grouped % values
  const categoryKey = barChartProps.categoryKey || barChartProps.dateKey;

  // get selected items from the FilterSelected provider/context
  const [selected, handleSelect] = useFilterSelected();

  const chartData = useMemo(() => {
    if (!data) return undefined;
    if (isGrouped) {
      return convertToStackedChartData(
        data as Record<string, unknown>[],
        groupKey,
        String(categoryKey)
      );
    } else return convertToChartData(data as Record<string, unknown>[], groupKey);
  }, [data]);

  const barChartData = [];
  for (const key in chartData) {
    chartData?.[key].forEach((element) => {
      barChartData.push(element);
    });
  }
  const newBarChartData = barChartData.map((item, i) => ({
    id: i,
    name: item?.type || '',
    group: item?.type || '',
    color: alarmByTypeColors?.[item?.type.toLowerCase() as string],
    category: item?.date || '',
    count: item?.count || 0,
    code: item?.code || '',
    description: item?.description || '',
    startTimestamp: item?.startTimestamp || '',
    endTimestamp: item?.endTimestamp || '',
    duration: item?.duration || 0,
    location: item?.location || ''
  }));

  const uniqueGroupedData = new Set(newBarChartData.map((item) => item?.category));

  const handleClick = (datum: Record<string, unknown>) => {
    if (datum && datum?.label) {
      const newDatum = {
        xName: datum?.label,
        _y: datum?.value,
        y: datum?.value,
        x: datum?.label,
        type: datum?.label,
        groupKey: groupKey,
        code: datum?.code,
        description: datum?.description,
        count: datum?.count
      };
      if (selected) {
        handleSelect('clear');
      } else {
        handleSliceClick(newDatum, groupKey, handleSelect, { selected, filteredByKeys });
      }
    } else {
      handleSelect('clear');
      const filteredList =
        newBarChartData && newBarChartData.filter((item) => t(item?.group) === t(datum?.group));
      if (selected) {
        const newDatum = filteredList &&
          filteredList.length && {
            xName: filteredList[0]?.category || '',
            _y: filteredList[0]?.count || 0,
            y: filteredList[0]?.count || 0,
            x: filteredList[0]?.category || '',
            type: filteredList[0]?.group || '',
            groupKey: groupKey,
            code: filteredList[0]?.code || '',
            description: filteredList[0]?.description || '',
            count: filteredList[0]?.count || 0,
            date: filteredList[0]?.category || '',
            categoryKey: 'date',
            startTimestamp: filteredList[0]?.startTimestamp || '',
            endTimestamp: filteredList[0]?.endTimestamp || '',
            location: filteredList[0]?.location || '',
            duration: filteredList[0]?.duration || 0
          };
        newDatum &&
          handleBarClick(newDatum, groupKey, 'type', handleSelect, { selected, filteredByKeys });
      } else {
        if (filteredList && filteredList.length) {
          const newDatum = {
            xName: filteredList[0]?.category || '',
            _y: filteredList[0]?.count || 0,
            y: filteredList[0]?.count || 0,
            x: filteredList[0]?.category || '',
            type: filteredList[0]?.group || '',
            groupKey: groupKey,
            code: filteredList[0]?.code || '',
            description: filteredList[0]?.description || '',
            count: filteredList[0]?.count || 0,
            date: filteredList[0]?.category || '',
            categoryKey: 'date',
            startTimestamp: filteredList[0]?.startTimestamp || '',
            endTimestamp: filteredList[0]?.endTimestamp || '',
            location: filteredList[0]?.location || '',
            duration: filteredList[0]?.duration || 0
          };
          handleBarClick(newDatum, groupKey, 'type', handleSelect, { selected });
        } else {
          handleBarClick({}, groupKey, 'type', handleSelect, { selected, filteredByKeys });
        }
      }
    }
  };

  const TooltipComponent = (props: Record<string, unknown>): JSX.Element => {
    const { t } = useTranslation(['mh']);
    return (
      <ChartTooltip>
        <div className="bar-chart-tooltip__value">{t(String(props.category))}</div>
        <div className="tooltip__label">{`${props.group}: ${props.count}`}</div>
      </ChartTooltip>
    );
  };

  const widgetSettings = {
    className: 'alarm-bar-chart',
    title: title,
    isLoading,
    hasError: hasError ? 'Error fetching changeover details' : '',
    hasMessage: newBarChartData && newBarChartData.length ? undefined : 'no data',
    hideLegend,
    legendItems: customLegends ? customLegends : legendItems.length && legendItems,
    selected,
    handleClick
  };

  const chartSettings = {
    colors: customLegends
      ? Object.fromEntries(customLegends.map((item) => [item.label, item.color]))
      : alarmByTypeColors,
    chartData: newBarChartData,
    categories: [...uniqueGroupedData].sort(),
    chartKeys: {
      value: 'count'
    },
    handleClick,
    checkIfSelected,
    margins: {
      left: 50
    },
    format: {
      bottomTicks: (date?: string | number) => {
        try {
          return formatZonedTimestamp(date as string, 'IST', 'M/d');
        } catch (error) {
          const parsedDate = parse(date as string, 'yyyy-dd-MM', new Date());
          // Convert the parsed date to the specified timezone
          const zonedDate = utcToZonedTime(parsedDate, timeZone as string);
          try {
            return formatZonedTimestamp(zonedDate.toISOString(), 'UTC', 'M/d');
          } catch (error) {
            return '';
          }
        }
      }
    },
    Tooltip: TooltipComponent
  };

  return <BarChartWidget {...widgetSettings} {...chartSettings} />;
};

export default AlarmByTypeBarChart;
