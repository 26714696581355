import React, { useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import { Button, Typography } from 'components';
import theme from 'themes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-left: auto;
`;

type Props = {
  handleCopyText?: () => void;
  isLoading?: boolean;
  icon?: JSX.Element;
  isOnlyIcon?: boolean;
};
const CopyTextComponent = ({ handleCopyText, isLoading, icon, isOnlyIcon }: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [toolTipVisible, setTooltipVisible] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setTooltipVisible(false), 800);
    }
  }, [toolTipVisible, isLoading]);
  return (
    <Container>
      <Tooltip
        placement="bottom"
        trigger={['focus']}
        mouseLeaveDelay={0.5}
        visible={toolTipVisible}
        overlay={<span>{isLoading ? 'Copying...' : 'Copied!'}</span>}
      >
        <Button
          onClick={() => {
            handleCopyText?.();
            setTooltipVisible(true);
          }}
          variant="text"
          type="button"
        >
          {icon}
          {!isOnlyIcon ? (
            <Typography mb={0} size={'.85rem'} color={theme.colors.primaryBlue5}>
              {t('copy_link')}
            </Typography>
          ) : (
            ''
          )}
        </Button>
      </Tooltip>
    </Container>
  );
};

export default CopyTextComponent;
