import React from 'react';

export interface Props {
  color?: string;
}

export const ClockIcon = ({ color }: Props): JSX.Element => {
  color = color || '#004577';

  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 3V5L6.5 6.5M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5Z"
        stroke={`${color}`}
        strokeWidth="0.825"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
