import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Loader,
  Modal,
  ModalButtonsContainer,
  OptionColumnCheckboxWrapper,
  OptionalColumnContainer,
  OptionalColumnName,
  OptionsModalContainer,
  SelectionHeader,
  StyledColumnRefContainer,
  StyledDragRefContainer,
  Typography
} from 'components';
import React, { FC } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import theme from 'themes';
import { ModalSize } from 'types';

interface Props {
  machineList: { id: string; name: string }[];
  setMachineList: (x: { id: string; name: string }[]) => void;
  isChangeItemOrderPopupOpen: boolean;
  onCancelReOrder: () => void;
  onSaveReOrder: () => void;
  isLoading: boolean;
}

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: { id: string; name: string }[]
): { id: string; name: string }[] => {
  const draggedIndex = columnOrder.findIndex((column) => column.id === draggedColumnId);
  const targetIndex = columnOrder.findIndex((column) => column.id === targetColumnId);

  if (draggedIndex === -1 || targetIndex === -1) {
    // Handle invalid IDs, you might want to throw an error or return the original array
    return [...columnOrder];
  }

  const reorderedColumns = [...columnOrder];
  const [draggedColumn] = reorderedColumns.splice(draggedIndex, 1);
  reorderedColumns.splice(targetIndex, 0, draggedColumn);

  return reorderedColumns;
};

const DraggableColumnHeader: FC<{
  machineId: string;
  machineName: string;
  idArray: { id: string; name: string }[] | undefined;
  setIdArray: (list: { id: string; name: string }[]) => void;
}> = ({ machineId, machineName, idArray, setIdArray }) => {
  const [{ isOver }, dropRef] = useDrop({
    accept: 'ITEM',
    drop: (draggedColumn: { machineId: string }) => {
      const newColumnOrder = idArray && reorderColumn(draggedColumn.machineId, machineId, idArray);
      setIdArray(newColumnOrder as { id: string; name: string }[]);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: { machineId },
    type: 'ITEM'
  });

  return (
    <StyledColumnRefContainer
      ref={dropRef}
      style={{
        opacity: isDragging ? 0 : 1
      }}
      key={machineId}
    >
      <div ref={previewRef}>
        <StyledDragRefContainer ref={dragRef}>
          <OptionColumnCheckboxWrapper
            key={machineId}
            style={{
              backgroundColor: isOver ? 'lightgrey' : 'white'
            }}
          >
            <div style={{ height: '2.5rem' }}></div>
            <OptionalColumnName>{machineName}</OptionalColumnName>
            <FontAwesomeIcon
              icon={faGripVertical}
              color={theme.colors.darkBlue}
              style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}
              size="xs"
            />
          </OptionColumnCheckboxWrapper>
        </StyledDragRefContainer>
      </div>
    </StyledColumnRefContainer>
  );
};

const ChangeItemOrderPopUp = ({
  machineList,
  setMachineList,
  isChangeItemOrderPopupOpen,
  onCancelReOrder,
  onSaveReOrder,
  isLoading
}: Props): JSX.Element => {
  return (
    <Modal
      onClose={onCancelReOrder}
      size={ModalSize.XXSMALL_AUTO_HEIGHT}
      title="Change Machine Layout"
      visible={isChangeItemOrderPopupOpen as boolean}
      widthOverride="25rem"
    >
      {isLoading ? (
        <Loader size={50} />
      ) : (
        <OptionsModalContainer>
          <SelectionHeader>
            <Typography variant="body2" color={theme.colors.darkGrey}>
              Simply drag and drop the tiles on the order that you want to see them, you can change
              the structure and the order by simply just drag and drop the Machine name.
            </Typography>
          </SelectionHeader>
          <OptionalColumnContainer>
            {machineList?.map((el: { id: string; name: string }, i: number) => {
              return (
                <DraggableColumnHeader
                  machineId={el.id}
                  machineName={el.name}
                  idArray={machineList}
                  setIdArray={setMachineList}
                  key={i}
                />
              );
            })}
          </OptionalColumnContainer>
        </OptionsModalContainer>
      )}

      <ModalButtonsContainer>
        <Button onClick={onCancelReOrder} bgColor={theme.colors.primaryBlue4}>
          Cancel
        </Button>
        <Button onClick={onSaveReOrder} variant="primary" width="5.25rem">
          Save
        </Button>
      </ModalButtonsContainer>
    </Modal>
  );
};

export default ChangeItemOrderPopUp;
