import React from 'react';

export const IcoArrowThreeArrowsUp = ({ color }: { color?: string }): JSX.Element => {
  color = color || 'white';

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className="icon icon-arrow-three-arrows-up"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2864 21.0147V7.01465"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.28638 15.0146L12.2864 12.0146L15.2864 15.0146"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2864 10.0146L18.2864 7.01465L21.2864 10.0146"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.28638 21.0146H21.2864"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2864 21.0146V12.0146"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.28638 6.01465L6.28638 3.01465L9.28638 6.01465"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.28638 21.0146V3.01465"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
