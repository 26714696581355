import styled from 'styled-components';

export const TooltipIconContainer = styled.span`
  position: relative;
  height: max-content;
  width: max-content;
  cursor: help;
  .icon {
    line-height: 0;
  }
  .tooltip-wrapper {
    .tooltip {
      transform: translateY(-50%);
    }
  }
`;
