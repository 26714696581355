import React from 'react';
import { KPIWidget } from 'common/components';
import { StyledUiContainerProps } from 'components';
import { useMachinePressurization } from 'pages/FleetMachineDetail/hooks';
import { convertSecondsToTime } from 'common/helpers/dateAndTimeHelpersV2';

export const DeltaPressurizationTimeKPIWidget = ({
  gridArea,
  className
}: StyledUiContainerProps): JSX.Element => {
  className = className || 'delta-pressurization-time-kpi-widget';

  const { isLoading, hasError, data, hasMessage } = useMachinePressurization();

  const sumCurrent = (data?.sumCurrentPressurizeTime || 0) as number;
  const sumTarget = (data?.sumTargetIdealPressurizeTime || 0) as number;
  // this makes sure it's a whole number
  // needs to be updated to support negative time
  const differenceInSums = (sumCurrent - sumTarget) * 1;

  return (
    <KPIWidget
      {...{
        isLoading,
        hasError,
        hasMessage,
        gridArea,
        className,
        title: 'delta pressurization time',
        rightCell: {
          title: 'Current',
          value: convertSecondsToTime(sumCurrent)
        },
        leftCell: {
          title: 'Target',
          value: convertSecondsToTime(sumTarget)
        },
        subCells: [
          {
            title: 'Result',
            value: convertSecondsToTime(differenceInSums)
          }
        ],
        hasDateFooter: true
      }}
    />
  );
};
