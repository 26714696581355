import BaseSelect from 'components/BaseSelect/BaseSelect';
import React, { useContext, useMemo } from 'react';
import { OperatorSelectorProps } from 'react-querybuilder';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components';
import { useGetAlertEnumsQuery, useGetMachineDataAnalysisTagsQuery } from 'api';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ValidationContext } from '../AlertQueryContext';

const CustomOperatorSelector = (props: OperatorSelectorProps): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const inputValidation = useContext(ValidationContext);

  const { data: EnumsTypes, isFetching: isAlertTypeFetching } = useGetAlertEnumsQuery();
  const { machineId } = useFleetMachineAccountData();

  const { data: alertStatementTagQuery, isFetching } = useGetMachineDataAnalysisTagsQuery(
    machineId
      ? {
          machineId: machineId,
          numbersOnly: 'false'
        }
      : skipToken
  );

  const stringOperator = ['EQUAL', 'NOT_EQUAL', 'CONTAINS'];

  const selectedFieldType = useMemo(() => {
    if (alertStatementTagQuery && alertStatementTagQuery.length > 0) {
      return typeof alertStatementTagQuery.find((tag) => tag.tagId === props.rule.field)?.value;
    }
    return [];
  }, [props.rule.field]);

  const operatorsList = useMemo(() => {
    if (!isAlertTypeFetching) {
      if (!EnumsTypes || !EnumsTypes.logicStatementComparisonOperator) {
        // Handle the case where EnumsTypes or its property is undefined
        return [{ label: 'No Logic Statement Operator', value: 'no_logic_statement_operator' }];
      }

      if (EnumsTypes && EnumsTypes.logicStatementComparisonOperator.length > 0) {
        const OperatorList = EnumsTypes.logicStatementComparisonOperator.filter((op) => {
          if (selectedFieldType === 'string') {
            return stringOperator.includes(op);
          }

          if (selectedFieldType === 'number') {
            return op !== 'CONTAINS';
          }
          return op;
        }, []);

        return OperatorList.map((item: string) => ({
          label: modifyLabel(item),
          value: item
        }));
      } else {
        return [{ label: 'No Logic Statement Operator', value: 'no_logic_statement_operator' }];
      }
    }

    return [];
  }, [EnumsTypes, isAlertTypeFetching, props.rule.field]);

  if (isAlertTypeFetching) {
    return <Loader />;
  }

  // to modify the label for Operator Dropdown
  function modifyLabel(label: string) {
    if (label == 'EQUAL')
      return `${label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())} (=)`;
    if (label == 'NOT_EQUAL')
      return `${label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())} (!=)`;
    if (label == 'GREATER_THAN')
      return `${label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())} (>)`;
    if (label == 'GREATER_THAN_OR_EQUAL')
      return `${label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())} (>=)`;
    if (label == 'LESS_THAN')
      return `${label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())} (<)`;
    if (label == 'LESS_THAN_OR_EQUAL')
      return `${label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())} (<=)`;
    else
      return label
        .replaceAll('_', ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
  }

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <div style={{ width: '100%' }}>
          <BaseSelect
            variant={alertStatementTagQuery?.length === 0 ? 'disabled' : 'white'}
            handleChange={(e) => {
              props.handleOnChange(e ? e.target.value : null);
            }}
            labelField="id"
            options={operatorsList ? operatorsList : []}
            placeholder={t('select_an_operator') as string}
            searchable={false}
            searchBy="label" // label is assigned as friendlyName value
            value={props?.value === '=' ? '' : (props?.value as string)}
            valueField="id"
            width="100%"
            isShowErrorMessage={
              (inputValidation && props?.value === '=') || props?.value?.length === 0
            }
            borderVariant={
              (inputValidation && props?.value === '=') || props?.value?.length === 0
                ? 'error'
                : 'none'
            }
            tooltipText="Operator Is Required"
          />
        </div>
      )}
    </>
  );
};

export default CustomOperatorSelector;
