import React from 'react';
import DocViewer, { DocViewerRenderers, IHeaderOverride } from 'react-doc-viewer';
import styled from 'styled-components';

type DocUrl = {
  uri: string;
};
type Props = {
  urlList: DocUrl[];
  height?: string;
  headerComponent?: IHeaderOverride;
};
const StyledDocViewer = styled(DocViewer)<{ height?: string }>`
  height: ${({ height }) => height || '65vh'};
`;

function DefaultHeader() {
  return <></>;
}
const FileViewer = ({ urlList, height, headerComponent }: Props): JSX.Element => {
  return (
    <StyledDocViewer
      config={{
        header: {
          overrideComponent: headerComponent || DefaultHeader
        }
      }}
      height={height}
      documents={urlList}
      pluginRenderers={DocViewerRenderers}
    ></StyledDocViewer>
  );
};

export default FileViewer;
