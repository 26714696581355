import { useDSICalibrationHealthQuery, useDSIMachineOverviewQuery } from 'api';
import { useLiveTimeFrame } from 'common/hooks';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { useMachine } from 'hooks';
import { useTimeZone } from 'providers';
import { ReactNode } from 'react';
//import { useWidgetDateRange } from 'common/ui/WidgetUi/providers/useWidgetDateRange';

/**
 * API INFO
 * 
    machine_id: 56f7660f-487b-4f77-bee8-0b3aa88c5f84
    kpi_data_interval: LAST_DAY - Available values : LAST_8_HOURS, LAST_7_DAYS, LAST_3_DAYS, LAST_15_MINUTES, LAST_30_MINUTES, LAST_HOUR, LAST_3_HOURS, LAST_SHIFT, LAST_DAY, LAST_WEEK, LAST_MONTH, CURRENT_DAY
    utc_start_datetime:
    utc_end_datetime:

    path: /v1/dsi/machine-health/56f7660f-487b-4f77-bee8-0b3aa88c5f84/machine-overview?kpi_data_interval=LAST_DAY

 */

export const useMachineOverviewWidgetCalibrationData = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
} => {
  const { machineId } = useMachineInfo();
  const api = useDSICalibrationHealthQuery({ machineId: machineId as string });

  return api;
};

export const useMachineOverviewWidget = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
} => {
  const { id } = useMachine()?.machine || {};

  const { timeZone } = useTimeZone();

  const { startTime, endTime } = useLiveTimeFrame(30000, { startOf: 'day' }, timeZone);

  console.log({ timeZone, startTime, endTime });
  const { data, isLoading, error } = useDSIMachineOverviewQuery({
    machineId: id,
    utc_start_datetime: startTime,
    utc_end_datetime: endTime
  });

  // this is to trigger a refresh, console.log is just here to pass lint checks
  if (!startTime && !endTime) console.log({ startTime, endTime });

  if (data) {
    const dataTS = data as {
      psu_name?: {
        value?: {
          value?: string;
          timestamp?: string;
        };
      };
      app_name?: {
        values?: {
          value?: string;
          timestamp?: string;
          piece_counts?: number;
        }[];
      };
      product_type?: {
        values?: {
          value?: string;
          timestamp?: string;
        }[];
      };
      state_name?: {
        value?: {
          value?: string;
          timestamp?: string;
        };
      };
      cumulative_goods_details?: {
        output_piece_counts?: Record<string, number>;
        output_weight_pounds?: Record<string, number>;
      };
    };

    const { cumulative_goods_details, psu_name, app_name, product_type } = dataTS;

    const { output_piece_counts, output_weight_pounds } = cumulative_goods_details || {};

    const totalWeightOutput = {
      nugget: 0,
      filet: 0,
      total: 0,
      unit: 'lbs'
    };

    const totalCountOutput = {
      nugget: 0,
      filet: 0,
      total: 0,
      unit: 'pieces'
    };

    if (output_piece_counts) {
      const { Nugget, ...rest } = output_piece_counts;
      totalCountOutput.nugget = Math.round(Nugget || 0);
      totalCountOutput.filet = Math.round(
        Object.values(rest).reduce((acc, val) => acc + val, 0) || 0
      );
      totalCountOutput.total = totalCountOutput.nugget + totalCountOutput.filet;
    }

    if (output_weight_pounds) {
      const { Nugget, ...rest } = output_weight_pounds;
      totalWeightOutput.nugget = Math.round(Nugget || 0);
      totalWeightOutput.filet = Math.round(Object.values(rest).reduce((acc, val) => acc + val, 0));
      totalWeightOutput.total = totalWeightOutput.nugget + totalWeightOutput.filet;
    }

    const currentProductName = psu_name?.value?.value;
    const currentProductType = product_type?.values?.[0]?.value;
    const currentRecipes = app_name?.values;

    const currentTotalOutput = { weight: totalWeightOutput, pieces: totalCountOutput };

    const machineState = {
      sate: dataTS?.state_name?.value?.value,
      lastChecked: dataTS?.state_name?.value?.timestamp
    };

    return {
      currentProductName,
      currentProductType,
      currentRecipes,
      currentTotalOutput,
      machineState
    };
  }

  return {
    isLoading,
    hasError: error ? true : false,
    hasMessage: false
  };
};
