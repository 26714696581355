import { useAlarmsForIntensifierApi } from '../MachineHealth/api';
import { useAlarmsForIntensifierApiASD } from '../MachineHealth/api/useAlarmsForIntensifiersASD';

export const avureCustomerTimeZones: Record<string, string> = {
  PASCAL: 'Europe/Amsterdam',
  'DAILY DOSE LTD': 'Europe/London'
};

export const avureCustomerMachineTypes: Record<string, string> = {
  default: 'KMT',
  PASCAL: 'KMT',
  'PASCAL PROCESSING BV': 'KMT',
  'DAILY DOSE LTD': 'ASD'
};

export const avureCustomerSkids: Record<string, number[]> = {
  PASCAL: [1],
  'DAILY DOSE LTD': [1]
};

export const avureGetAlarmsHook: Record<string, unknown> = {
  KMT: useAlarmsForIntensifierApi,
  ASD: useAlarmsForIntensifierApiASD
};

const ASDmapping = {
  category: 'pump',
  scope: 'alarm_category',
  alarm_id: 'alarm_id',
  alarm_description: 'alarm_description'
};

const KMTmapping = {
  category: 'alarm_category',
  scope: 'scope',
  alarm_id: 'alarm_id',
  alarm_description: 'alarm_description'
};

const KMTlegendProps = {
  items: [
    {
      label: 'A-Alarm (Critical)',
      id: 'Critical'
    },
    {
      label: 'B-Alarm (Product)',
      id: 'Product'
    },
    {
      label: 'C-Alarm (Warning)',
      id: 'Warning'
    }
  ],
  colors: {
    Product: 'rgba(0, 118, 204, 1)',
    Warning: 'rgba(226, 178, 8, 1)',
    Critical: 'rgba(182, 44, 16, 1)'
  }
};

const ASDlegendProps = {
  items: [
    {
      label: 'ASD 1-1',
      id: 'ASD 1-1'
    },
    {
      label: 'ASD 1-2',
      id: 'ASD 1-2'
    }
  ],
  colors: {
    'ASD 1-1': '#3A4BC6',
    'ASD 1-2': '#118DFF'
  }
};

export const alarmsColumnMapping = {
  KMT: KMTmapping,
  ASD: ASDmapping
} as {
  KMT: {
    [key: string]: string;
  };
  ASD: {
    [key: string]: string;
  };
};

export const alarmsLegendsMapping = {
  KMT: KMTlegendProps,
  ASD: ASDlegendProps
};
