import { Button, Checkbox, Modal, RadioButton, Typography } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSize } from 'types';
import {
  ButtonContainer,
  ModalContent,
  ModalTitle,
  PublishOption
} from './reviewAndPublish.elements';
import theme from 'themes';
import { useSendReviewAndPublishMachineDataMutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import CustomLoader from 'components/CustomLoader';
import { Flex } from 'common/components/common-styled';

type MachineStatus = {
  diagramsStatus: string;
  maintenanceScheduleStatus: string;
  machineDocumentsStatus: string;
  bomStatus: string;
};
type Props = {
  showSelectionModal: boolean;
  closeModal: () => void;
  status?: MachineStatus;
  machineId?: string;
  goBack?: (machineId: string) => void;
};
const PublishOptionModal = ({
  showSelectionModal,
  closeModal,
  status,
  machineId,
  goBack
}: Props): JSX.Element => {
  const { t } = useTranslation(['fpns', 'common']);
  const [sendReviewAndPublish, { isLoading }] = useSendReviewAndPublishMachineDataMutation();
  const [selectedOption, setSelectedOption] = useState({
    diagramStatus: false,
    maintenanceSchedule: false,
    machineDocuments: false,
    bomStatus: false
  });
  const handleOption = (option: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectedOption({ ...selectedOption, [option]: checked });
  };
  const handleRadioOption = (option: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectedOption({
      ...selectedOption,
      bomStatus: false,
      diagramStatus: false,
      [option]: checked
    });
  };
  const checkDisable = () => {
    const isSelected = Object.values(selectedOption).some((option) => option === true);
    return !isSelected;
  };

  const handlePublish = async () => {
    const { diagramStatus, machineDocuments, maintenanceSchedule, bomStatus } = selectedOption;
    try {
      if (diagramStatus || maintenanceSchedule || machineDocuments || bomStatus) {
        sendReviewAndPublish({
          status_diagram: diagramStatus,
          status_maintenance_schedule: maintenanceSchedule,
          submit_machine_pdf: machineDocuments,
          submit_bom_list: bomStatus,
          machine_id: machineId || ''
        })
          .unwrap()
          .then(() => {
            closeModal();
            ToastMsg({
              heading: 'Publish Successful',
              message: '',
              type: 'success',
              onClose: () => goBack?.(machineId || '')
            });
            closeModal();
          })
          .catch((error) => {
            ToastMsg({
              type: 'error',
              message: `Failed to publish${error?.data?.detail ? `: ${error.data.detail}` : ''}`,
              heading: 'Publish Not Successful'
            });
          });
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };
  return (
    <Modal
      visible={showSelectionModal}
      onClose={closeModal}
      title={
        <ModalTitle>
          <Typography size={'1.25rem'} weight="semi-bold">
            {t('publish_machine')}?
          </Typography>
          <Typography>{t('select_publish_document')}.</Typography>
        </ModalTitle>
      }
      size={ModalSize.SMALL_AUTO_HEIGHT}
      maxWidth="40rem"
    >
      <ModalContent>
        <Flex width="100%">
          <PublishOption disabled={status?.diagramsStatus !== 'Done'}>
            <RadioButton
              checked={selectedOption.diagramStatus}
              disabled={status?.diagramsStatus !== 'Done'}
              onChange={(e) => handleRadioOption('diagramStatus', e)}
            />

            <Typography mb={0}>{t('diagram')}</Typography>
          </PublishOption>
          <PublishOption disabled={status?.bomStatus !== 'Done'}>
            <RadioButton
              checked={selectedOption.bomStatus}
              disabled={status?.bomStatus !== 'Done'}
              onChange={(e) => handleRadioOption('bomStatus', e)}
            />
            <Typography mb={0}>{t('BOM Only')}</Typography>
          </PublishOption>
        </Flex>
        <PublishOption disabled={status?.maintenanceScheduleStatus !== 'Done'}>
          <Checkbox
            width={20}
            height={20}
            margin="0"
            checked={selectedOption.maintenanceSchedule}
            onChange={(e) => handleOption('maintenanceSchedule', e)}
            disabled={status?.maintenanceScheduleStatus !== 'Done'}
          />
          <Typography mb={0}>{t('maintenance_schedule')}</Typography>
        </PublishOption>
        <PublishOption disabled={status?.machineDocumentsStatus !== 'Done'}>
          <Checkbox
            width={20}
            height={20}
            margin="0"
            checked={selectedOption.machineDocuments}
            onChange={(e) => handleOption('machineDocuments', e)}
            disabled={status?.machineDocumentsStatus !== 'Done'}
          />
          <Typography mb={0}>{t('machine_documents')}</Typography>
        </PublishOption>
      </ModalContent>
      <ButtonContainer padding="3rem 1.25rem">
        <Button
          variant="link"
          borderColor={theme.colors.primaryBlue5}
          borderRadius="3rem"
          width="8rem"
          onClick={closeModal}
        >
          {t('back')}
        </Button>
        <Button
          variant="hover-blue"
          bgColor={theme.colors.primaryBlue5}
          borderRadius="3rem"
          width="8rem"
          onClick={handlePublish}
          disabled={checkDisable() || isLoading}
        >
          {t('publish')}
          {isLoading && <CustomLoader size="1.3rem" thickness=".3rem" />}
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default PublishOptionModal;
