// 3rd party
import React from 'react';

import { format, parseISO } from 'date-fns';

// Hooks
import { MachineAlarmsProvider, useMachineAlarmsContext } from '../../hooks/useMachineAlarms';

import { DSIAlarmsPageContainer } from 'pages/DSI/components/DSIAlarmsPageContainer';

//import { FilteredChartsAndData } from 'components/StyledUi/StyledCharts/FilteredChartsAndData/FilteredChartsAndData';

import { DateButtonWithDropdown, useDateRange } from 'components';

// Types
import { DSIAlarm } from 'types/machine-health/alarms';

import { UiChartsAndFiltersPage } from 'components/StyledUi/StyledChartsV2/UIChartsAndFiltersPage/UiChartsAndFiltersPage';
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'helpers';
import { useTimeZone } from 'providers';

export interface DSIAlarmTableRow extends DSIAlarm {
  key: string;
  startDate: string;
}

const TableCell = styled.div<{ width?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

const alarmsOverTimeChart = {
  groupKey: 'logType',
  categoryKey: 'date',
  type: 'stacked-bar-over-time',
  format: {
    tooltip: (datum?: Record<string, unknown>) => {
      const [yy, m, d] = String(datum?.date).split('-');
      const date = `${Number(m)}/${d}/${yy}`;
      return [date, `${datum?.y} Alarms`];
    },
    axisX: (label: string): string => `${label}`,
    axisY: (label: string): string => {
      label = String(label).slice(5);
      const [m, d] = label.split('-');
      return `${Number(m)}/${d}`;
    }
  },
  widgetUiSettings: {
    title: 'Alarms by Date'
  }
};

const Provided = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const apiCall = useMachineAlarmsContext();
  const { dateRange, isoDateRange, setDateRange, timeZone } = useDateRange();
  const timeZoneData = useTimeZone();

  const start = format(parseISO(isoDateRange.startTime), 'P');
  const end = format(parseISO(isoDateRange.endTime), 'P');

  const sortState = {
    id: 'timestamp',
    desc: true
  };

  const alarmsColumnsConfig: ColumnConfig[] = [
    {
      header: `${t('date')}`,
      id: 'timestamp',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => {
        const timestamp = getValue();
        const formattedTimestamp = `${formatDate(
          String(timestamp),
          'long',
          timeZoneData.timeZone
        )} - ${formatDate(String(timestamp), 'hours-minutes-seconds', timeZoneData.timeZone)}`;
        return (
          <TableCell title={formattedTimestamp} width={180}>
            {formattedTimestamp}
          </TableCell>
        );
      }
    },
    {
      header: `${t('senderId')}`,
      id: 'senderId',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={150}>
          {getValue()}
        </TableCell>
      )
    },
    {
      header: `${t('message')}`,
      id: 'message',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => (
        <TableCell title={getValue()} width={800}>
          {getValue()}
        </TableCell>
      )
    }
  ];

  const alarmsTable = {
    sortState: sortState,
    columnsConfig: alarmsColumnsConfig
  };

  const csvSettings = {
    ...apiCall,
    fileName: `machine-alarms--${start}-to-${end}.csv`
  };

  const chartSettings = {
    charts: [alarmsOverTimeChart],
    tables: [alarmsTable],
    csvSettings,
    ...apiCall
  };

  return (
    <DSIAlarmsPageContainer className="dsi-alarms">
      <header className="dsi-alarms__header">
        <DateButtonWithDropdown
          {...{ dateRange, setDateRange, hasGoBackDateLimit: 30, timeZone }}
        />
      </header>
      <UiChartsAndFiltersPage {...chartSettings} usesFilteredData />
    </DSIAlarmsPageContainer>
  );
};

const Alarms = (): JSX.Element => {
  return (
    <MachineAlarmsProvider>
      <Provided />
    </MachineAlarmsProvider>
  );
};

export default Alarms;
