import React from 'react';
import { SvgIconProps } from 'types/maintenance';

const UploadIcon = ({ size, color }: SvgIconProps): JSX.Element => {
  return (
    <svg
      width={size || '16'}
      height={size || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66675 10.6666L2.66675 11.3333C2.66675 12.4378 3.56218 13.3333 4.66675 13.3333L11.3334 13.3333C12.438 13.3333 13.3334 12.4378 13.3334 11.3333L13.3334 10.6666M10.6667 5.33325L8.00008 2.66658M8.00008 2.66658L5.33341 5.33325M8.00008 2.66658L8.00008 10.6666"
        stroke={color || '#303E47'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
