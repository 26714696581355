// 3rd party libs
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { KPICard, Loader } from 'components';
import { useContainerSize } from 'hooks';
import Typography from 'components/Typography/Typography';

// Theme
import theme from 'themes';

// Hooks
import { useColorMap } from 'hooks';

// Types
import { useGetAsepticMachineHealthAlarmByLaneQuery } from 'api';

import AlarmsStackedBarChartWidget from '../AlarmsStackedBarChartWidget';

const ChartContainer = styled.div`
height: 15.938rem;
width= 100%;
display: flex;
gap: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const headerRenderer = (heading?: string, address?: string) => {
  return (
    <HeaderContainer>
      <Typography mb={0} size="1rem" weight="bold">
        {heading}
      </Typography>
      <Typography mb={0} size="0.875rem" weight="normal">
        {address}
      </Typography>
    </HeaderContainer>
  );
};
export const LaneAlarmsWidget = (): JSX.Element => {
  const { machineId } = useParams<{ machineId: string }>();
  const { t } = useTranslation(['mh']);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 3);
  const formattedDate = currentDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');

  const {
    data: alarmsData,
    isFetching,
    error: sessionsError
  } = useGetAsepticMachineHealthAlarmByLaneQuery({
    machineId,
    start_datetime: formattedDate
  });

  // On a first render we show load icon, but hide when page stays open and keeps recieveing new data
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [alarmsData, isFetching, sessionsError]);

  // Stacked Bar Chart
  const stackedBarChartData = useMemo(() => alarmsData, [alarmsData]);
  const { containerRef: graphContainerRef } = useContainerSize();
  const { containerRef: headerRef } = useContainerSize();
  const getColorById = useColorMap(theme.colors.alarmIdColors);
  const onDayFilterBarClick = useCallback((date: string) => ({ type: 'FILTER_BY_DATE', date }), []);

  return (
    <KPICard component={headerRenderer('Lane alarms', 'Last 3 days')} ref={headerRef}>
      <ChartContainer ref={graphContainerRef}>
        {sessionsError && (
          <Typography color="negativeRed" style={{ marginLeft: '2rem', marginTop: '1.5rem' }}>
            {t('failed_to_load_lane_alarms_data')}
          </Typography>
        )}
        {!sessionsError &&
          ((isFirstRender.current ? isFetching : false) ? (
            <Loader margin="auto" />
          ) : (
            stackedBarChartData && (
              <AlarmsStackedBarChartWidget
                barValueType="alarmCount"
                onBarClick={onDayFilterBarClick}
                data={stackedBarChartData}
                timeZone={'UTC'}
                getColorById={getColorById}
              />
            )
          ))}
      </ChartContainer>
    </KPICard>
  );
};

export default LaneAlarmsWidget;
