import React, { useContext, useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetMachineDataAnalysisTagsQuery } from 'api';
import BaseSelect from 'components/BaseSelect/BaseSelect';
import Loader from 'components/Loader';
import {
  CustomContentRendererValueEditor,
  CustomDropdownRendererAlertValueEditor
} from 'components/machine-health/configurator/WidgetTable/Dropdown';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { ValueEditorProps } from 'react-querybuilder';
import { useParams } from 'react-router-dom';
import { AlertsTableDropdownItem, TagsDropdownItems } from 'types/machine-health/widget-table';
import { ValidationContext } from '../AlertQueryContext';
import { useTranslation } from 'react-i18next';

export type TDropdownValue = {
  label?: string | null;
  id: string | null;
  isCustomThresholdValue: boolean;
  name?: string | null;
  tagId?: string | null;
  value?: string | number | null;
  values: [{ value?: number | null | string }];
};

type TParams = {
  alertId: string;
  machineId: string;
};

const CustomValueEditor = (props: ValueEditorProps): JSX.Element => {
  const inputValidation = useContext(ValidationContext);
  const { t } = useTranslation(['mh']);
  const [dropdownValue, setDropdownValue] = useState<TDropdownValue | null>(null);
  const [dropdownValueContains, setDropdownValueContains] = useState<TDropdownValue | null>(null);
  const [dropdownValueBetween1, setDropdownValueBetween1] = useState<TDropdownValue | null>(null);
  const [dropdownValueBetween2, setDropdownValueBetween2] = useState<TDropdownValue | null>(null);
  const { machineId } = useFleetMachineAccountData();
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const params: TParams = useParams();
  const MACHINE_ID = machineId;
  const { data: alertStatementTagQuery, isFetching } = useGetMachineDataAnalysisTagsQuery(
    MACHINE_ID
      ? {
          machineId: MACHINE_ID,
          numbersOnly: 'false'
        }
      : skipToken
  );

  const selectedFieldType = useMemo(() => {
    if (alertStatementTagQuery && alertStatementTagQuery.length > 0) {
      return typeof alertStatementTagQuery.find((tag) => tag.tagId === props.rule.field)?.value;
    }
  }, [alertStatementTagQuery, props.rule.field]);

  useMemo(() => {
    if (alertStatementTagQuery && alertStatementTagQuery.length > 0) {
      if (props.rule.operator === 'CONTAINS') {
        const field = alertStatementTagQuery.find(
          (tag) => tag.tagId === props.rule.value.split('/')[1]
        );
        if (field) {
          setDropdownValueContains({
            ...dropdownValueContains,
            label: field.tagId as string,
            id: field.name ? field.name : field.tagId,
            name: field.name as string,
            isCustomThresholdValue: false,
            tagId: field.tagId as string,
            values: [
              {
                value: field.value
              }
            ]
          });
        } else {
          params.alertId
            ? setDropdownValueContains({
                ...dropdownValueContains,
                label: null,
                id: props.rule.value.split('/')[0] as string,
                name: null,
                isCustomThresholdValue: true,
                tagId: null,
                values: [
                  {
                    value: props.rule.value.split('/')[0]
                  }
                ]
              })
            : setDropdownValueContains(null);
        }
      } else if (props.rule.operator === 'BETWEEN' || props.rule.operator === 'NOT_BETWEEN') {
        const valueOne = props?.value?.split(',')[0];
        const valueTwo = props?.value?.split(',')[1];
        const BetweenFieldTwo = alertStatementTagQuery.find(
          (tag) => tag.tagId === valueTwo.split('/')[1]
        );
        const BetweenFieldOne = alertStatementTagQuery.find(
          (tag) => tag.tagId === valueOne.split('/')[1]
        );
        if (BetweenFieldOne) {
          setDropdownValueBetween1({
            ...dropdownValueBetween1,
            label: BetweenFieldOne.tagId as string,
            id: BetweenFieldOne.name ? BetweenFieldOne.name : BetweenFieldOne.tagId,
            name: BetweenFieldOne.name ? BetweenFieldOne.name : (BetweenFieldOne.tagId as string),
            isCustomThresholdValue: false,
            tagId: BetweenFieldOne.tagId as string,
            values: [
              {
                value: BetweenFieldOne.value
              }
            ]
          });
        } else {
          params.alertId
            ? setDropdownValueBetween1({
                ...dropdownValueBetween1,
                label: null,
                id: valueOne.split('/')[0] as string,
                name: null,
                isCustomThresholdValue: true,
                tagId: null,
                values: [
                  {
                    value: valueOne.split('/')[0]
                  }
                ]
              })
            : setDropdownValueBetween1(null);
        }

        if (BetweenFieldTwo) {
          setDropdownValueBetween2({
            ...dropdownValueBetween2,
            label: BetweenFieldTwo.tagId as string,
            id: BetweenFieldTwo.name ? BetweenFieldTwo.name : BetweenFieldTwo.tagId,
            name: BetweenFieldTwo.name ? BetweenFieldTwo.name : (BetweenFieldTwo.tagId as string),
            isCustomThresholdValue: false,
            tagId: BetweenFieldTwo.tagId as string,
            values: [
              {
                value: BetweenFieldTwo.value
              }
            ]
          });
        } else {
          params.alertId
            ? setDropdownValueBetween2({
                ...dropdownValueBetween2,
                label: null,
                id: valueTwo.split('/')[0] as string,
                name: null,
                isCustomThresholdValue: true,
                tagId: null,
                values: [
                  {
                    value: valueTwo.split('/')[0]
                  }
                ]
              })
            : setDropdownValueBetween2(null);
        }
      } else {
        const field = alertStatementTagQuery.find(
          (tag) => tag.tagId === props.rule.value.split('/')[1]
        );

        if (field) {
          setDropdownValue({
            ...dropdownValue,
            label: field.tagId as string,
            id: field.name ? field.name : field.tagId,
            isCustomThresholdValue: false,
            name: field.name ? field.name : (field.tagId as string),
            tagId: field.tagId as string,
            value: field.value,
            values: [
              {
                value: field.value
              }
            ]
          });
        } else {
          params.alertId
            ? setDropdownValue({
                ...dropdownValue,
                label: null,
                id: props.rule.value.split('/')[0] as string,
                isCustomThresholdValue: true,
                name: null,
                tagId: null,
                value: props.rule.value.split('/')[0],
                values: [
                  {
                    value: props.rule.value.split('/')[0]
                  }
                ]
              })
            : setDropdownValue(null);
        }
      }
    }
  }, [alertStatementTagQuery, isFetching]);

  const newList = useMemo(() => {
    return alertStatementTagQuery && alertStatementTagQuery.length !== 0
      ? alertStatementTagQuery
          .filter((tag) => {
            if (selectedFieldType !== 'undefined') {
              return tag?.value !== null && typeof tag?.value === selectedFieldType;
            }
            return tag.value !== null;
          })
          .map((item) => ({
            label: item.tagId,
            value: item.value,
            id: item.name ? item.name : item.tagId,
            friendlyName: item.name ? item.name : item.tagId,
            name: item.name ? item.name : item.tagId,
            tagId: item.tagId,
            values: [{ value: item.value }]
          }))
      : [];
  }, [alertStatementTagQuery, props.operator]);

  useEffect(() => {
    if (dropdownValue && dropdownValue?.id?.length !== 0 && isUpdateAlert) {
      setDropdownValue(null);
    }
    if (dropdownValueBetween1 && dropdownValueBetween1?.id?.length !== 0 && isUpdateAlert) {
      if (props.operator !== String('BETWEEN') || props.operator !== String('NOT_BETWEEN')) {
        setDropdownValueBetween1(null);
        setDropdownValueBetween2(null);
      }
    }
    if (dropdownValueContains && dropdownValueContains?.id?.length !== 0 && isUpdateAlert) {
      setDropdownValueContains(null);
    }

    setIsUpdateAlert(true);
  }, [props.operator]);

  const checkingUpperShouldBeGreater = useMemo(() => {
    if (
      dropdownValueBetween2 &&
      dropdownValueBetween2?.id !== null &&
      dropdownValueBetween1 &&
      dropdownValueBetween1?.id !== null
    ) {
      return dropdownValueBetween2?.id > dropdownValueBetween1?.id;
    }
  }, [dropdownValueBetween2, dropdownValueBetween1]);

  if (isFetching) {
    return <Loader />;
  } else {
    // condition for between operator
    if (props.operator === 'BETWEEN' || props.operator === 'NOT_BETWEEN') {
      return (
        <>
          <section style={{ margin: '0 -1rem' }}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABoAQMAAACXLQHQAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAXEgAAFxIBZ5/SUgAAAAZQTFRFAAAAe7fiGEOFVQAAAAJ0Uk5TAP9bkSK1AAAAKklEQVR4nGNgQALs/////4CDAVczyhhkDGAU/cfBGCQuHGUQZuDOemAAAARnJjjOODZ3AAAAAElFTkSuQmCC"
              alt=""
              style={{ width: '2.5rem' }}
            />
          </section>
          <div style={{ width: '100%' }}>
            <BaseSelect
              contentRenderer={({ id }: Record<string, unknown>) => (
                <CustomContentRendererValueEditor
                  item={id as unknown as TagsDropdownItems}
                  label={newList.length === 0 ? 'No Data' : 'choose_a_uppper_tag_or_threshold'}
                  enableTooltip
                />
              )}
              width="100%"
              dropdownRenderer={(props, state, methods) => (
                <CustomDropdownRendererAlertValueEditor
                  props={props}
                  state={state}
                  methods={methods}
                  thresholdType={selectedFieldType}
                />
              )}
              disabledLabel={'tag_already_assigned'}
              handleChangeSearch={(
                value: (AlertsTableDropdownItem | string | Record<string, unknown> | undefined)[]
              ) => {
                // We need to account for the case when value = undefined ...
                // ... which can happen when the user clears the search field

                // const updateValue = value[0] as unknown as AlertsTableDropdownItem | undefined;
                // return updateValue && setDropdownValueBetween1(updateValue);

                // if (updateValue && (updateValue?.tagId as string) && updateValue.id !== null) {
                //   return setDropdownValueBetween1({
                //     ...updateValue,
                //     isCustomThresholdValue: false
                //   });
                // } else {
                //   if (updateValue && updateValue.id !== null) {
                //     return setDropdownValueBetween1({
                //       ...updateValue,
                //       isCustomThresholdValue: true
                //     });
                //   }
                // }

                const betweenValue2Tag =
                  dropdownValueBetween2 && dropdownValueBetween2.tagId === null
                    ? undefined
                    : dropdownValueBetween2 && dropdownValueBetween2.tagId;

                const updateValue = value[0] as TDropdownValue;
                if (updateValue && (updateValue?.tagId as string) && updateValue.id !== null) {
                  const betweenValue1 = updateValue
                    ? updateValue.values && updateValue.values[0].value + '/' + updateValue.tagId
                    : '0';
                  const betweenValue2 = dropdownValueBetween2
                    ? dropdownValueBetween2.values &&
                      dropdownValueBetween2.values[0].value + '/' + betweenValue2Tag
                    : '0';
                  const finalValue = betweenValue1 + ',' + betweenValue2;
                  props.handleOnChange(finalValue);
                  return setDropdownValueBetween1({
                    ...updateValue,
                    isCustomThresholdValue: false
                  });
                } else {
                  if (updateValue && updateValue.id !== null) {
                    const betweenValue1 = updateValue
                      ? updateValue.values && updateValue.values[0].value + '/' + updateValue.tagId
                      : '0';
                    const betweenValue2 = dropdownValueBetween2
                      ? dropdownValueBetween2.values &&
                        dropdownValueBetween2.values[0].value + '/' + betweenValue2Tag
                      : '0';
                    const finalValue = betweenValue1 + ',' + betweenValue2;
                    props.handleOnChange(finalValue);
                    return setDropdownValueBetween1({
                      ...updateValue,
                      isCustomThresholdValue: true
                    });
                  }
                }
              }}
              labelField="id"
              options={newList && (newList as { id: string; label: string }[])}
              placeholder={'select_tag'}
              searchable
              searchBy="label" // label is assigned as friendlyName value
              value={{
                label: dropdownValueBetween1 ? dropdownValueBetween1 : null,
                id: dropdownValueBetween1 ? dropdownValueBetween1 : null,
                name: dropdownValueBetween1 ? dropdownValueBetween1 : null
              }}
              valueField="id"
              borderVariant={inputValidation && dropdownValueBetween1 === null ? 'error' : 'none'}
              isShowErrorMessage={inputValidation && dropdownValueBetween1 === null}
              variant={newList.length === 0 ? 'disabled' : 'gray'}
              margin={0.7}
            />
            <section style={{ width: '100%', height: '1rem' }}></section>
            <BaseSelect
              contentRenderer={({ id }: Record<string, unknown>) => (
                <CustomContentRendererValueEditor
                  item={id as unknown as TagsDropdownItems}
                  label={newList.length === 0 ? 'No Data' : 'choose_a_lower_tag_or_threshold'}
                  enableTooltip
                />
              )}
              width="100%"
              dropdownRenderer={(props, state, methods) => (
                <CustomDropdownRendererAlertValueEditor
                  props={props}
                  state={state}
                  methods={methods}
                  thresholdType={selectedFieldType}
                />
              )}
              disabledLabel={'tag_already_assigned'}
              handleChangeSearch={(
                value: (AlertsTableDropdownItem | string | Record<string, unknown> | undefined)[]
              ) => {
                // We need to account for the case when value = undefined ...
                // ... which can happen when the user clears the search field
                const updateValue = value[0] as TDropdownValue;
                // const betweenValue1 = dropdownValueBetween1
                //   ? dropdownValueBetween1.values &&
                //     dropdownValueBetween1.values[0].value + '/' + dropdownValueBetween1.tagId
                //   : '0';
                // const betweenValue2 = updateValue
                //   ? updateValue.values && updateValue.values[0].value + '/' + updateValue.tagId
                //   : '0';
                // const finalValue = betweenValue1 + ',' + betweenValue2;
                // props.handleOnChange(finalValue);
                // return updateValue && setDropdownValueBetween2(updateValue);
                const dropdownValueBetween1Tag =
                  dropdownValueBetween1 && dropdownValueBetween1.tagId === null
                    ? undefined
                    : dropdownValueBetween1 && dropdownValueBetween1.tagId;

                if (updateValue && (updateValue?.tagId as string) && updateValue.id !== null) {
                  const betweenValue1 = dropdownValueBetween1
                    ? dropdownValueBetween1.values &&
                      dropdownValueBetween1.values[0].value + '/' + dropdownValueBetween1Tag
                    : '0';
                  const betweenValue2 = updateValue
                    ? updateValue.values && updateValue.values[0].value + '/' + updateValue.tagId
                    : '0';
                  const finalValue = betweenValue1 + ',' + betweenValue2;
                  props.handleOnChange(finalValue);
                  return setDropdownValueBetween2({
                    ...updateValue,
                    isCustomThresholdValue: false
                  });
                } else {
                  if (updateValue && updateValue.id !== null) {
                    const betweenValue1 = dropdownValueBetween1
                      ? dropdownValueBetween1.values &&
                        dropdownValueBetween1.values[0].value + '/' + dropdownValueBetween1Tag
                      : '0';
                    const betweenValue2 = updateValue
                      ? updateValue.values && updateValue.values[0].value + '/' + updateValue.tagId
                      : '0';
                    const finalValue = betweenValue1 + ',' + betweenValue2;
                    props.handleOnChange(finalValue);
                    return setDropdownValueBetween2({
                      ...updateValue,
                      isCustomThresholdValue: true
                    });
                  }
                }
              }}
              labelField="id"
              options={newList && (newList as { id: string; label: string }[])}
              placeholder={'select_tag'}
              searchable
              searchBy="label" // label is assigned as friendlyName value
              value={{
                label: dropdownValueBetween2 ? dropdownValueBetween2 : null,
                id: dropdownValueBetween2 ? dropdownValueBetween2 : null,
                name: dropdownValueBetween2 ? dropdownValueBetween2 : null
              }}
              borderVariant={
                inputValidation && (dropdownValueBetween2 === null || checkingUpperShouldBeGreater)
                  ? 'error'
                  : 'none'
              }
              isShowErrorMessage={
                inputValidation && (dropdownValueBetween2 === null || checkingUpperShouldBeGreater)
              }
              customErrorMessage={
                checkingUpperShouldBeGreater
                  ? (t('Lower threshold cannot be greater than Upper threshold') as string)
                  : undefined
              }
              variant={newList.length === 0 ? 'disabled' : 'gray'}
              valueField="id"
              margin={0.7}
            />
          </div>
        </>
      );
    }

    // condition for contains operator
    else if (props.operator === 'CONTAINS') {
      return (
        <>
          <BaseSelect
            contentRenderer={({ id }: Record<string, unknown>) => (
              <CustomContentRendererValueEditor
                item={id as unknown as TagsDropdownItems}
                label={newList.length === 0 ? 'No Data' : 'choose_a_tag_or_threshold'}
                enableTooltip
              />
            )}
            width="100%"
            dropdownRenderer={(props, state, methods) => (
              <CustomDropdownRendererAlertValueEditor
                props={props}
                state={state}
                methods={methods}
                thresholdType={selectedFieldType}
                isContainsDropdown={true}
              />
            )}
            disabledLabel={'tag_already_assigned'}
            handleChangeSearch={(
              value: (AlertsTableDropdownItem | string | Record<string, unknown> | undefined)[]
            ) => {
              // We need to account for the case when value = undefined ...
              // ... which can happen when the user clears the search field
              const updateValue = value[0] as TDropdownValue;
              if (updateValue && (updateValue?.tagId as string) && updateValue.id !== null) {
                props.handleOnChange(updateValue.values[0].value + '/' + updateValue.tagId);
                return setDropdownValueContains({ ...updateValue, isCustomThresholdValue: false });
              } else {
                if (updateValue && updateValue.id !== null) {
                  props.handleOnChange(updateValue.values[0].value + '/' + updateValue.tagId);
                  return setDropdownValueContains({ ...updateValue, isCustomThresholdValue: true });
                }
              }
            }}
            labelField="id"
            options={newList && (newList as { id: string; label: string }[])}
            placeholder={'select_tag'}
            searchable
            searchBy="label" // label is assigned as friendlyName value
            value={{
              label: dropdownValueContains ? dropdownValueContains : null,
              id: dropdownValueContains ? dropdownValueContains : null,
              name: dropdownValueContains ? dropdownValueContains : null
            }}
            borderVariant={inputValidation && dropdownValueContains === null ? 'error' : 'none'}
            isShowErrorMessage={inputValidation && dropdownValueContains === null}
            variant={newList.length === 0 ? 'disabled' : 'gray'}
            valueField="id"
          />
        </>
      );
    }

    return (
      <>
        <BaseSelect
          contentRenderer={({ id }: Record<string, unknown>) => (
            <CustomContentRendererValueEditor
              item={id as unknown as TagsDropdownItems}
              label={newList.length === 0 ? 'No Data' : 'choose_a_tag_or_threshold'}
              enableTooltip
            />
          )}
          width="100%"
          dropdownRenderer={(props, state, methods) => (
            <CustomDropdownRendererAlertValueEditor
              props={props}
              state={state}
              methods={methods}
              thresholdType={selectedFieldType}
            />
          )}
          disabledLabel={'tag_already_assigned'}
          handleChangeSearch={(
            value: (TDropdownValue | string | Record<string, unknown> | undefined)[]
          ) => {
            // We need to account for the case when value = undefined ...
            // ... which can happen when the user clears the search field
            // const updateValue = value[0] as TDropdownValue;
            // props.handleOnChange(updateValue.values[0].value + '/' + updateValue.tagId);
            // return updateValue && setDropdownValue(updateValue);
            const updateValue = value[0] as TDropdownValue;
            if (updateValue && (updateValue?.tagId as string) && updateValue.id !== null) {
              props.handleOnChange(
                updateValue &&
                  updateValue.values &&
                  updateValue.values[0].value + '/' + updateValue.tagId
              );
              return setDropdownValue({ ...updateValue, isCustomThresholdValue: false });
            } else {
              if (updateValue && updateValue.id !== null) {
                props.handleOnChange(
                  updateValue &&
                    updateValue.values &&
                    updateValue.values[0].value + '/' + updateValue.tagId
                );
                return setDropdownValue({ ...updateValue, isCustomThresholdValue: true });
              }
            }
          }}
          labelField="id"
          options={newList && (newList as { id: string; label: string }[])}
          placeholder={'select_tag'}
          searchable
          searchBy="label" // label is assigned as friendlyName value
          value={{
            label: dropdownValue ? dropdownValue : null,
            id: dropdownValue ? dropdownValue : null,
            name: dropdownValue ? dropdownValue : null
          }}
          valueField="id"
          isShowErrorMessage={
            inputValidation && (dropdownValue === null || dropdownValue.id?.length === 0)
          }
          borderVariant={
            inputValidation && (dropdownValue === null || dropdownValue.id?.length === 0)
              ? 'error'
              : 'none'
          }
          variant={newList.length === 0 ? 'disabled' : 'gray'}
        />
      </>
    );
  }
};

export default CustomValueEditor;
