// 3rd party libs
import React, { useMemo, useState } from 'react';

// Components
import { NewBaseTable } from 'components';

// Types
import { SortState } from 'types';

// Custom hooks
import { useSort } from 'hooks';

// Helpers
import { formatDuration } from 'helpers';

// Types
import { CleaningStepGroup } from 'types/protein';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CleaningStepsContainer } from 'components/CleaningStepsTable/index.elements';
import ChevronDown from '../../icons/ChevronDown.svg';
import Chevron from '../../icons/chevron.svg';

// Remove unusable props
type CleaningSteps = Omit<CleaningStepGroup, 'duration' | 'steps' | 'percent'>;

interface CleaningStepGroupWithKey extends CleaningSteps {
  key: string;
  parent?: boolean;
  children: CleaningSteps[];
}

interface Props {
  data: CleaningStepGroup[];
  selectedSteps?: string[];
}

// Initial state for sorting
const defaultSortState: Record<string, SortState> = {
  name: SortState.unsorted,
  averageDuration: SortState.unsorted
};

// Group CleaningStepGroup[] by parent name, and add a key to each row/subRow
const groupByParentNameWithKeys = (data: CleaningStepGroup[]): CleaningStepGroupWithKey[] => {
  const rows: CleaningStepGroupWithKey[] = [];
  // Group steps by their 'parentName'
  const group = _(data)
    .groupBy((step) => step.parentName)
    .map((value, key) => ({
      parent: key,
      steps: value
    }))
    .value();

  // Populate parent row
  group.forEach((d) => {
    rows.push({
      id: d.parent,
      key: d.parent,
      name: d.parent,
      parentName: d.parent,
      parent: true,
      // Aggregate subRows data (cleaning steps)
      averageDuration: d.steps.map((step) => step.averageDuration).reduce((a, b) => a + b, 0),
      maxDuration: Math.max(...d.steps.map((step) => step.maxDuration)),
      minDuration: Math.min(...d.steps.map((step) => step.minDuration)),
      children: d.steps.map((step) => {
        return { ...step, key: `${d.parent}-${step.id}` };
      })
    });
  });

  return rows;
};

// Generate the configurations for each column of this table
const generateColumnConfigs = (t: TFunction<'mh'[], undefined>) => {
  return [
    {
      header: t('steps') as string,
      id: 'name',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ row, getValue }) => {
        return (
          <div className={row.getCanExpand() ? 'parent-cell' : 'child-cell'}>
            {row.getCanExpand() ? (
              <button
                className="button-expand"
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: 'pointer' }
                }}
              >
                {row.getIsExpanded() ? (
                  <img src={ChevronDown} alt="chevron-down" />
                ) : (
                  <img src={Chevron} alt="chevron-right" />
                )}
              </button>
            ) : (
              ''
            )}{' '}
            {row.getCanExpand() ? getValue() : `${getValue()} (${row.original.id})`}
          </div>
        );
      }
    },
    {
      header: t('average_over_time') as string,
      id: 'averageDuration',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ getValue }) => formatDuration(Number(getValue()), 'hours:mins:secs')
    },

    {
      header: t('min') as string,
      id: 'minDuration',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ row, getValue }) =>
        row.getCanExpand() ? '-' : formatDuration(Number(getValue()), 'hours:mins:secs')
    },

    {
      header: t('max') as string,
      id: 'maxDuration',
      isEnableSorting: true,
      isSelected: true,
      renderer: ({ row, getValue }) =>
        row.getCanExpand() ? '-' : formatDuration(Number(getValue()), 'hours:mins:secs')
    }
  ];
};

const filter = (data: CleaningStepGroup[], selectedSteps: string[] | undefined) => {
  return selectedSteps?.length ? data.filter(({ id }) => selectedSteps.includes(id)) : data;
};

const CleaningStepsDurationsTable = ({ data, selectedSteps }: Props): JSX.Element => {
  const [sortState] = useState<Record<string, SortState>>(defaultSortState);
  const { t } = useTranslation(['mh']);

  const dataWithKeys = useMemo(
    () => groupByParentNameWithKeys(filter(data, selectedSteps)),
    [data, selectedSteps]
  );

  const sortedData = useSort<CleaningStepGroupWithKey>(sortState, dataWithKeys);

  const newSortedData = sortedData.map((item) => {
    return {
      subRows: item.children,
      ...item
    };
  });

  return (
    <CleaningStepsContainer className="cleaning-steps-table-container">
      <NewBaseTable
        columnConfigs={generateColumnConfigs(t)}
        sortState={{ id: 'id', desc: true }}
        newTableData={newSortedData}
        tdMarginLeft="0.625rem"
        textAlign="left"
        padding="0 0 0 0"
        isShowGlobalSearch={false}
        customClassName="cleaning-steps-table"
        maxTableHeight={37.8}
        hideTotalFetchCount={true}
      />
    </CleaningStepsContainer>
  );
};

export default CleaningStepsDurationsTable;
