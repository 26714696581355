import React, { ReactElement } from 'react';
import {
  CardContainer,
  Dashboard,
  EmptyData,
  FilterInfo,
  Heading,
  LoaderContainer,
  Results
} from '../documentation.elements';
import { Typography } from 'components';
import { useGetBookmarkDocumentQuery } from 'api';
import DocumentCardComponent from 'common/components/DocumentCardComponent';
import CustomLoader from 'components/CustomLoader';
import { IcoNoFiles } from 'icons/IcoNoFiles';
import { useTranslation } from 'react-i18next';

const BookmarkPage = (): ReactElement => {
  const { t } = useTranslation(['fpns']);

  const { data: bookmarkedDocuments, isLoading } = useGetBookmarkDocumentQuery();

  return (
    <>
      <Heading>
        <Typography weight="semi-bold" size={'1.25rem'}>
          Bookmarks
        </Typography>
      </Heading>

      <FilterInfo>
        {bookmarkedDocuments?.length ? (
          <Results>
            {t('Showings')} <span>01~{`${bookmarkedDocuments?.length}`.padStart(2, '0')}</span>{' '}
            {t('of_all')} <span>{`${bookmarkedDocuments?.length}`.padStart(2, '0')}</span>{' '}
            {t('bookmark')}
          </Results>
        ) : (
          ''
        )}
      </FilterInfo>
      {isLoading ? (
        <LoaderContainer>
          <CustomLoader size="3rem" />
        </LoaderContainer>
      ) : bookmarkedDocuments?.length ? (
        <Dashboard>
          <CardContainer reducedHeight={'25rem'}>
            {bookmarkedDocuments?.map((doc) => {
              return (
                <DocumentCardComponent
                  totalBookmark={bookmarkedDocuments.length}
                  documentDetail={{ ...doc.documentDetails, hasBookmark: true }}
                />
              );
            })}
          </CardContainer>
        </Dashboard>
      ) : (
        <EmptyData>
          <IcoNoFiles className="emptyIcon" />
          <Typography weight="semi-bold">{t('no_file_to_show')} </Typography>
        </EmptyData>
      )}
    </>
  );
};

export default BookmarkPage;
