import React from 'react';
import { DotsChartLabelGenerator } from './DotsChartLabelGenerator';
import { COLORMAP } from 'pages/FleetMachineDetail/MachineHealth/components/PressurizationDelta/types/constants';

export const LabelWrapper = (): JSX.Element => {
  const settings = [
    {
      name: 'Ideal Pressurization Time',
      keyName: 'idealPressurizeTime' //key nam is used to look up color value
    },
    {
      name: 'Actual Pressurization Time',
      keyName: 'pressurizeTime' //key nam is used to look up color value
    }
  ] as Record<string, unknown>[];

  const generatorSettings = {
    settings,
    colorMap: COLORMAP,
    className: 'pressurization-delta-labels'
  };

  return <DotsChartLabelGenerator {...generatorSettings} />;
};
