import styled from 'styled-components';
import { CommonProps, FlexTypes } from './style.types';

const OverflowEllipsis = styled.div<CommonProps>`
  width: ${({ width }) => width || '10rem'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative; /* Required for positioning the tooltip */

  &:hover::before {
    display: block;
  }

  &::before {
    content: attr(data-title); /* Use data-title instead of title */
    display: none;
    position: absolute;
    top: -250%; /* Position below the element */
    left: 50%;
    transform: translateX(-50%);
    background: #333; /* Tooltip background color */
    color: #fff; /* Tooltip text color */
    padding: 0.5rem; /* Tooltip padding */
    border-radius: 0.25rem; /* Tooltip border radius */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Tooltip shadow */
    white-space: nowrap;
    z-index: 1000000; /* Ensure the tooltip is on top of other elements */
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: calc(100% + 0.25rem); /* Small gap between element and tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 0.5rem;
    border-style: solid;
    border-color: #333 transparent transparent transparent; /* Triangle arrow */
    z-index: 1000000;
  }
`;
const Flex = styled.div<FlexTypes>`
  display: flex;
  width: ${({ width }) => width || 'auto'};
  gap: ${({ gap }) => gap || '1.5rem'};
  flex-wrap: wrap;
`;

const ContentAlign = styled.div<{ justifyContent?: string; alignItems?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'start'};
  align-items: ${({ alignItems }) => alignItems || 'start'};
`;
const CenterAlign = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export { OverflowEllipsis, Flex, ContentAlign, CenterAlign };
