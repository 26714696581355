import React, { FC, SVGProps } from 'react';

export const IcoNoFiles: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="196"
    height="198"
    viewBox="0 0 196 198"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Scene">
      <g id="hole" opacity="0.7" filter="url(#filter0_d_8693_26436)">
        <ellipse cx="113.114" cy="159.36" rx="62.2641" ry="20.0355" fill="black" />
      </g>
      <g id="fly">
        <path
          id="path"
          opacity="0.5"
          d="M105.004 161.983C63.9932 165.795 65.3123 135.425 65.3123 135.425C70.8326 116.468 86.4845 114.016 91.4516 105.415C100.956 88.9575 92.3011 68.4164 77.0169 66.0754C71.2477 65.1917 59.5179 80.966 82.4664 94.0163C90.551 98.6139 100.992 96.7261 110.261 83.2584C124.431 62.6685 95.8109 36.3651 95.507 35.001"
          stroke="#B4B4B4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="4 10"
        />
        <g id="bee">
          <ellipse
            id="Oval"
            opacity="0.4"
            cx="4.42963"
            cy="5.83105"
            rx="4.42963"
            ry="5.83105"
            transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 100.851 22.1973)"
            fill="#D1D6DB"
          />
          <ellipse
            id="Oval Copy"
            opacity="0.4"
            cx="4.42963"
            cy="5.83105"
            rx="4.42963"
            ry="5.83105"
            transform="matrix(0.809017 0.587785 0.587785 -0.809017 77.3916 38.9121)"
            fill="#D1D6DB"
          />
          <g id="Rectangle 4">
            <path
              id="Mask"
              d="M93.0645 32.203C92.5046 28.6682 89.1853 26.2566 85.6506 26.8164L84.4938 26.9996L84.6754 28.146C85.2352 31.6808 88.5545 34.0924 92.0893 33.5326L93.246 33.3493L93.0645 32.203Z"
              fill="white"
            />
            <mask
              id="mask0_8693_26436"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="84"
              y="26"
              width="10"
              height="8"
            >
              <path
                id="Mask_2"
                d="M93.0645 32.203C92.5046 28.6682 89.1853 26.2566 85.6506 26.8164L84.4938 26.9996L84.6754 28.146C85.2352 31.6808 88.5545 34.0924 92.0893 33.5326L93.246 33.3493L93.0645 32.203Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_8693_26436)">
              <rect
                id="Rectangle 4_2"
                width="1.20808"
                height="8.84711"
                transform="matrix(-0.809017 -0.587785 -0.587785 0.809017 93.707 27.6914)"
                fill="#0076CC"
              />
              <path
                id="Rectangle 4 Copy"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M91.0048 26.0841L90.0274 25.3739L84.8285 32.5311L85.8059 33.2414L91.0048 26.0841Z"
                fill="#0076CC"
              />
            </g>
          </g>
          <path
            id="Path 7 Copy"
            opacity="0.5"
            d="M80.1367 25.7522L77.1246 25.521"
            stroke="white"
            strokeWidth="0.72"
          />
          <path
            id="Path 7"
            opacity="0.5"
            d="M81.1417 24.3709L79.5697 21.674"
            stroke="white"
            strokeWidth="0.72"
          />
          <ellipse
            id="Oval 4"
            cx="3.22155"
            cy="3.21713"
            rx="3.22155"
            ry="3.21713"
            transform="matrix(-0.987688 0.156434 0.156434 0.987688 85.8535 22.7129)"
            fill="white"
          />
        </g>
      </g>
      <g id="tube">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.643 106.669C105.643 99.0278 111.837 92.833 119.479 92.833C127.121 92.833 133.316 99.0278 133.316 106.669V178.416C133.316 178.416 126.101 179.334 121.139 179.466C116.178 179.598 105.643 179.369 105.643 179.369V106.669Z"
          fill="url(#paint0_linear_8693_26436)"
        />
        <path
          id="Rectangle 2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.652 99C105.652 94.5817 109.233 91 113.652 91H151V119H125.186H105.652V99Z"
          fill="url(#paint1_linear_8693_26436)"
        />
        <path
          id="Oval_2"
          d="M157 105C157 112.732 154.09 119 150.5 119C144.5 119 144 114 144 105C144 97.268 146.91 91 150.5 91C154.09 91 157 97.268 157 105Z"
          fill="#004577"
        />
      </g>
      <g id="lines" opacity="0.5">
        <path
          id="Line"
          d="M168.177 93.6206L173.526 90.7344C174.087 90.4318 174.293 89.7377 173.987 89.1841C173.681 88.6305 172.978 88.427 172.417 88.7295L167.069 91.6157C166.508 91.9183 166.302 92.6124 166.608 93.166C166.914 93.7196 167.617 93.9232 168.177 93.6206Z"
          fill="url(#paint2_linear_8693_26436)"
        />
        <path
          id="Line Copy"
          d="M163.041 87.356L166.753 82.5439C167.142 82.0394 167.048 81.3214 166.543 80.9402C166.038 80.5589 165.314 80.6588 164.925 81.1633L161.212 85.9754C160.823 86.4799 160.917 87.1979 161.422 87.5792C161.927 87.9604 162.651 87.8605 163.041 87.356Z"
          fill="url(#paint3_linear_8693_26436)"
        />
        <path
          id="Line Copy 2"
          d="M169.377 100.728L175.452 100.903C176.089 100.921 176.615 100.423 176.626 99.7909C176.638 99.1584 176.131 98.6308 175.494 98.6125L169.419 98.4378C168.782 98.4195 168.257 98.9174 168.245 99.5499C168.233 100.182 168.74 100.71 169.377 100.728Z"
          fill="url(#paint4_linear_8693_26436)"
        />
      </g>
      <g id="yellow note">
        <g id="Path 70">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M159.881 134.799L172.377 146.132L195.194 142.079L180.923 130.883L159.881 134.799Z"
            fill="url(#paint5_linear_8693_26436)"
            fillOpacity="0.3"
            style={{ mixBlendMode: 'multiply' }}
          />
        </g>
        <g id="yellow">
          <path
            id="Path 12"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.55 124.985C183.55 124.985 184.649 126.854 185.331 128.515C186.278 130.822 186.005 133.586 185.885 133.625C185.234 133.841 182.631 130.889 182.47 129.014C182.325 127.329 183.55 124.985 183.55 124.985Z"
            fill="#FFB883"
          />
          <path
            id="Path 9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152.509 134.675C152.509 134.675 165.426 130.142 168.937 119.157C170.925 119.384 174.521 120.729 183.353 124.823C184.587 125.395 182.481 128.992 182.481 128.992C182.481 128.992 186.706 131.966 185.897 133.417C181.224 141.804 177.13 145.102 172.094 145.817C164.053 144.721 152.509 134.675 152.509 134.675Z"
            fill="url(#paint6_linear_8693_26436)"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_8693_26436"
        x="36.8496"
        y="129.324"
        width="152.528"
        height="68.0713"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8693_26436" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8693_26436"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8693_26436"
        x1="105.643"
        y1="136.173"
        x2="133.316"
        y2="136.173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CFFF" />
        <stop offset="1" stopColor="#0076CC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8693_26436"
        x1="105.651"
        y1="105"
        x2="151"
        y2="105"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CFFF" />
        <stop offset="1" stopColor="#0076CC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8693_26436"
        x1="174.108"
        y1="88.5693"
        x2="166.398"
        y2="88.7039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.01" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8693_26436"
        x1="166.404"
        y1="80.3291"
        x2="159.415"
        y2="83.5883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.01" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8693_26436"
        x1="177.039"
        y1="99.3193"
        x2="170.294"
        y2="95.5805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.01" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_8693_26436"
        x1="189.521"
        y1="140.602"
        x2="186.097"
        y2="134.711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_8693_26436"
        x1="161.444"
        y1="118.319"
        x2="158.564"
        y2="136.555"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF1BC" />
        <stop offset="1" stopColor="#FFDF87" />
      </linearGradient>
    </defs>
  </svg>
);
