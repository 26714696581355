import { Button, Modal, Typography } from 'components';
import React, { SetStateAction, useEffect, useState } from 'react';
import {
  ErrorMsg,
  FileArea,
  FileNameContainer,
  FilePreviewContent,
  FooterButton,
  PreviewFileContainer,
  PreviewHeader
} from './File.element';
import theme from 'themes';
import { CustomFile, FileType } from 'types/machine-management';
import downloadPdf from 'img/pdf-download-icon.png';
import CustomLoader from 'components/CustomLoader';
import { useGetMachineDocFileQuery } from 'api';
import { getFileIcon } from './FileItem';
import { useTranslation } from 'react-i18next';
import FileViewer from 'common/components/FileViewer';
import axios from 'axios';
import { skipToken } from '@reduxjs/toolkit/dist/query';

export interface FileObj extends Partial<File> {
  id?: string;
  signedUrl?: string;
}

interface Props {
  file?: CustomFile | FileObj;
  isPreviewLoading?: boolean;
  setSelectedFilePreview?: React.Dispatch<SetStateAction<CustomFile | FileObj | undefined>>;
  type?: 'fps' | 'on' | 'dg'; //type is used to get the file from different repo of BE service.
}
const FilePreviewModal = ({
  file: selectedFilePreview,
  setSelectedFilePreview,
  isPreviewLoading,
  type = 'on'
}: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [errorCode, setErrorCode] = useState(0);

  const { data: filePreview, isLoading } = useGetMachineDocFileQuery(
    selectedFilePreview?.id
      ? {
          document_id: selectedFilePreview.id,
          type
        }
      : skipToken
  );

  const [filePreviewData, setFilePreviewData] = useState<FileType | FileObj>();
  useEffect(() => {
    setFilePreviewData(filePreview || selectedFilePreview);
  }, [isLoading, isPreviewLoading]);

  async function checkFileStatus(uri: string) {
    try {
      await axios.get(uri);
    } catch (err) {
      const resp = err as { status: number };
      setErrorCode(resp.status);
    }
  }
  useEffect(() => {
    if (filePreviewData?.signedUrl) {
      checkFileStatus(filePreviewData?.signedUrl);
    }
  }, [filePreviewData]);

  return (
    <Modal
      visible={!!selectedFilePreview}
      onClose={() => setSelectedFilePreview?.(undefined)}
      size={'small_auto_height'}
      widthOverride="50vw"
    >
      <PreviewFileContainer>
        <PreviewHeader>
          <FileArea>
            {/* <img width={32} src={PdfIcon} /> */}
            {getFileIcon(selectedFilePreview?.name || '')}
            {!(isLoading || isPreviewLoading) ? (
              <FileNameContainer>
                <Typography size={'1.3rem'} weight="medium" mb={'.4rem'}>
                  {selectedFilePreview?.name}
                </Typography>
                <Typography size={'.8rem'}>
                  <a
                    download
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    href={filePreviewData?.signedUrl}
                  >
                    {t('download_document')}
                  </a>
                </Typography>
              </FileNameContainer>
            ) : (
              ''
            )}
          </FileArea>
        </PreviewHeader>
        <FilePreviewContent>
          {isLoading || isPreviewLoading ? (
            <CustomLoader size="3rem" />
          ) : filePreviewData?.signedUrl && selectedFilePreview && !errorCode ? (
            <FileViewer urlList={[{ uri: filePreviewData.signedUrl }]} />
          ) : (
            <ErrorMsg>
              <Typography color={theme.colors.text.error}>{t('file_not_loaded')}</Typography>
            </ErrorMsg>
          )}
        </FilePreviewContent>
        <FooterButton>
          <Button
            width="8rem"
            variant="text"
            color={theme.colors.primaryBlue5}
            style={{ gap: '.3rem' }}
          >
            <img src={downloadPdf} />{' '}
            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              download
              href={filePreviewData?.signedUrl}
            >
              {t('download_document')}
            </a>
          </Button>
          <Button
            width="8rem"
            variant="hover-blue"
            borderRadius="3rem"
            onClick={() => setSelectedFilePreview?.(undefined)}
          >
            {t('close')}
          </Button>
        </FooterButton>
      </PreviewFileContainer>
    </Modal>
  );
};

export default FilePreviewModal;
