import React, { useContext } from 'react';
import { ConnectedScatterChart } from 'common/components/Charts/LineCharts/ConnectedScatterChart/ConnectedScatterChart';
import { SkidChartTooltipContainer, SkidChartTooltipItem } from './SkidUtilizationChart.elements';
import { WidgetUi } from 'common/components';
import { UseSkidUtilizationKpiReturnProps } from '../../api';
import { SelectedSkidContext } from '../../IntensifierPerformance/IntensifierViewAlarmsAndSkids';
import { intensifierColors } from '../../IntensifierPerformance/IntensifierPerformanceView';

import { useTranslation } from 'react-i18next';
import { useDateRange } from 'components';
import { usePressurizeCycleDataById } from 'pages/FleetMachineDetail/hooks';
import { formatInTimeZone } from 'date-fns-tz';
import { format, parseISO } from 'date-fns';

export interface SkidUtilizationChartProps extends UseSkidUtilizationKpiReturnProps {
  skidNumber: number;
  dateRange?: string[];
  bottomTickCount?: number;
  [key: string]: unknown;
}

const TooltipComponent = ({
  skidNumber,
  day_machine_local_time,
  ...props
}: Record<string, unknown>) => {
  if (!skidNumber) return <>Error loading skid</>;
  if (!day_machine_local_time) return <>Error loading date</>;

  const { t } = useTranslation(['mh']);
  const { batchStatusByDate } = usePressurizeCycleDataById();

  const colors = intensifierColors;
  const date = day_machine_local_time as string;

  const passedCycles = batchStatusByDate?.[date]?.passed;
  const failedCycles = batchStatusByDate?.[date]?.failed;

  let Cycles = (
    <>
      <div className="tooltip-cycle pass">
        <span className="icon">
          {
            //<IcoCheckMark />
            <>ok</>
          }
        </span>
        <b>{passedCycles} cycles</b> (Successful batches)
      </div>
      <div className="tooltip-cycle fail">
        <span>
          {
            // <IconX />
            <>no</>
          }
        </span>
        <b>{failedCycles} cycles</b> (Failed batches)
      </div>
    </>
  );

  if (!passedCycles || !failedCycles) Cycles = <>Error loading cycle data</>;
  if (!date) console.log('this to pass ts error', Cycles);

  const intensifier = t('intensifier');

  return (
    <SkidChartTooltipContainer className="tooltip">
      <div className="tooltip__date underlined">{format(parseISO(date), 'MMM d, y')}</div>
      {
        //turn off cycles for now <div className="tooltip__cycles">{Cycles}</div>
      }
      <SkidChartTooltipItem
        color={colors?.[`intensifier_utilization_${skidNumber}_1`]}
        className={`intensifier_utilization_${skidNumber}_1`}
      >
        {intensifier} {skidNumber}.1 -{' '}
        <b>{Math.ceil(props?.[`intensifier_utilization_${skidNumber}_1`] as number)}%</b>
      </SkidChartTooltipItem>
      <SkidChartTooltipItem
        color={colors?.[`intensifier_utilization_${skidNumber}_2`]}
        className={`intensifier_utilization_${skidNumber}_2`}
      >
        {intensifier} {skidNumber}.2 -{' '}
        <b>{Math.ceil(props?.[`intensifier_utilization_${skidNumber}_2`] as number)}%</b>
      </SkidChartTooltipItem>
      <SkidChartTooltipItem
        color={colors?.[`intensifier_utilization_${skidNumber}_3`]}
        className={`intensifier_utilization_${skidNumber}_3`}
      >
        {intensifier} {skidNumber}.3 -{' '}
        <b>{Math.ceil(props?.[`intensifier_utilization_${skidNumber}_3`] as number)}%</b>
      </SkidChartTooltipItem>
      <SkidChartTooltipItem
        color={colors?.[`intensifier_utilization_${skidNumber}_4`]}
        className={`intensifier_utilization_${skidNumber}_4`}
      >
        {intensifier} {skidNumber}.4 -{' '}
        <b>{Math.ceil(props?.[`intensifier_utilization_${skidNumber}_4`] as number)}%</b>
      </SkidChartTooltipItem>
    </SkidChartTooltipContainer>
  );
};

export const SkidUtilizationChart = ({
  skidNumber,
  dateRange,
  data,
  isLoading,
  hasMessage,
  hasError,
  bottomTickCount,
  ...rest
}: SkidUtilizationChartProps): JSX.Element => {
  const { selected, setSelected } = useContext(SelectedSkidContext);

  const handle = (type: string, options?: Record<string, unknown>) => {
    switch (type) {
      case 'select': {
        if (options?.selected) return setSelected?.(options?.selected as string[]);
        return false;
      }
    }
  };

  const { timeZone } = useDateRange();

  const chartSettings = {
    selected,
    handle,
    className: 'widget-ui__main',
    labelLeft: 'Utilization Percentage',
    groupKey: 'day_machine_local_time',
    // the [startDate, endData] in yyyy-mm-dd format
    dateRange,
    // this generates a line for each key
    valueKeys: [
      `intensifier_utilization_${skidNumber}_1`,
      `intensifier_utilization_${skidNumber}_2`,
      `intensifier_utilization_${skidNumber}_3`,
      `intensifier_utilization_${skidNumber}_4`
    ],
    height: 300,
    // tese are the colors for the legend items
    legendItems: {
      items: [
        { id: `intensifier_utilization_${skidNumber}_1`, label: `Intensifier L 1` },
        { id: `intensifier_utilization_${skidNumber}_2`, label: `Intensifier L 2` },
        { id: `intensifier_utilization_${skidNumber}_3`, label: `Intensifier R 1` },
        { id: `intensifier_utilization_${skidNumber}_4`, label: `Intensifier R 2` }
      ]
    },
    dateRangeDomain: dateRange,
    bottomTickCount,
    colors: intensifierColors,
    isOutlined: true,
    TooltipComponent: (props: Record<string, unknown>) => (
      <TooltipComponent {...props} skidNumber={skidNumber} />
    ),
    data: data as Record<string, unknown>[],
    formatLeftAxis: (x: unknown) => `${x}%`,
    formatBottomAxis: (x: string) => formatInTimeZone(x, timeZone, 'MMM/d')
  };

  const { t } = useTranslation(['mh']);

  const widgetSettings = {
    title: `${t('skid')} ${skidNumber} ${t('utilization')}`,
    className: `skid-chart`,
    isLoading,
    hasError,
    hasMessage,
    hasDateFooter: true
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={data && <ConnectedScatterChart {...chartSettings} {...rest} />}
    />
  );
};
