import React, { useState } from 'react';
import { ActionWithRulesProps } from 'react-querybuilder';
import { useTranslation } from 'react-i18next';
import { DeleteGroupButton } from './index.elements';
import WarningPrompt from 'components/WarningPrompt';

interface CustomRemoveRuleBtnProps extends ActionWithRulesProps {
  getGroupsLength: () => number;
}

const CustomRemoveGroupBtn = (props: CustomRemoveRuleBtnProps): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const [isViewConfirmCancel, setIsViewConfirmCancel] = useState(false);
  const [handleData, setHandleData] = useState<React.MouseEvent<
    HTMLButtonElement,
    MouseEvent
  > | null>(null);
  const onHandleCancel = () => {
    props.handleOnClick(handleData as React.MouseEvent<HTMLButtonElement>);
  };
  return (
    <>
      <WarningPrompt
        helperText={'Deleting this will also delete any underlying children. Are you sure?'}
        isConfirmPrompt
        isVisible={isViewConfirmCancel}
        onCancelCallback={() => setIsViewConfirmCancel(false)}
        onConfirmCallback={onHandleCancel}
        title={'Delete Group'}
      />
      <DeleteGroupButton
        onClick={(e) => {
          setIsViewConfirmCancel(true);
          return setHandleData(e);
        }}
      >
        {t('delete_group') as string}
      </DeleteGroupButton>
    </>
  );
};

export default CustomRemoveGroupBtn;
