// Calendar date range controls initial time frame of the chart
// Date range start and end date are used to set local state "dates"
// If we are looking at end date that is current, then we trigger interval that updtes locat state "dates".
// How often it updates it depends on API interval
// If the difference between current time and end date is greater than 5 minutes, we clear the interval
// 5 minute - is a grace period where we still count end date as current

import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  //dateToString,
  useDateRangeV2
} from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { useMachineInfo } from 'common/pages/fleetV2/providers';

interface Props {
  children?: ReactNode | ReactNode[];
  API_INTERVAL: number;
  version?: string;
}

interface APITimerProps {
  start_time?: string;
  end_time?: string;
}

const APITimerContext = createContext<APITimerProps>({
  start_time: undefined,
  end_time: undefined
});

interface APIIntervalProps {
  start_time?: string;
  end_time?: string;
}

type IntervalID = ReturnType<typeof setInterval>;

export const useAPIIntervalProvider = (): APIIntervalProps => useContext(APITimerContext);

export const APITimerProvider = ({ children, API_INTERVAL }: Props): JSX.Element => {
  const { startMoment: startDate, endMoment: endDate } = useDateRangeV2().machineDateRange;
  const { setDateRange } = useDateRangeV2();
  const { timeZone } = useMachineInfo();
  const timezone = timeZone || 'UTC';

  // // New end date because we cannot really use 'right now' end date.
  // // There is a delay between when event occured and when it got into Comsos,
  // // so we are artifically changing end date to be 1 minute behind to account for that delay.
  // const newEndDate = formatInTimeZone(
  //   new Date(new Date(endDate).getTime() - 90000),
  //   'UTC',
  //   "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
  // );

  const [dates, setDates] = useState({
    startDate: startDate,
    endDate: endDate
  });

  //console.log('dates', dates.startDate, dates.endDate);

  const intervalRef = useRef<IntervalID>();

  const checkAndUpdateDates = () => {
    //const now = utcToZonedTime(new Date().toISOString(), timezone);
    setDates((prevDates) => {
      //const prevZoned = utcToZonedTime(prevDates.endDate, timezone);
      const newStartDate = prevDates.endDate;
      const newEndDate = formatInTimeZone(
        new Date(new Date(prevDates.endDate).getTime() + API_INTERVAL),
        'UTC',
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      // const { startTime: start, endTime: end } = dateToString(
      //   newStartDate,
      //   newEndDate,
      //   undefined,
      //   undefined,
      //   true
      // );

      //console.log('interval', newStartDate, newEndDate);
      return { startDate: newStartDate, endDate: newEndDate };

      //const difference = Math.abs(now.valueOf() - prevZoned.getTime()) > 5 * 60 * 1000;
      // if (!difference) {
      //   // const newStartDate2 = prevDates.endDate;
      //   // const newEndDate2 = formatInTimeZone(
      //   //   new Date(new Date(prevDates.endDate).getTime() + API_INTERVAL),
      //   //   'UTC',
      //   //   "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      //   // );

      //   // return { startDate: newStartDate2, endDate: newEndDate2 };

      //   const newEndDate = now;
      //   const newStartDate = utcToZonedTime(
      //     new Date(new Date().getTime() - API_INTERVAL).toISOString(),
      //     timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
      //   );
      //   const { startTime: start, endTime: end } = dateToString(
      //     newStartDate,
      //     newEndDate,
      //     undefined,
      //     undefined,
      //     true
      //   );
      //   return { startDate: start, endDate: end };

      // } else {
      //   clearInterval(intervalRef.current);
      //   return prevDates;
      // }
    });
  };

  useEffect(() => {
    setDates({ startDate: startDate, endDate: endDate });
  }, [setDateRange]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    const startDateObj = startDate;
    const endDateObj = endDate;
    setDates({ startDate: startDateObj, endDate: endDateObj });

    const now = utcToZonedTime(new Date().toISOString(), timezone);
    const end = utcToZonedTime(endDateObj, timezone);
    const difference = Math.abs(now.valueOf() - end.getTime()) > 5 * 60 * 1000;
    if (!difference) {
      intervalRef.current = setInterval(checkAndUpdateDates, API_INTERVAL);
    }
    return () => clearInterval(intervalRef.current);
  }, [startDate, endDate]);

  const values = {
    start_time: dates.startDate,
    end_time: dates.endDate
  };

  return <APITimerContext.Provider value={values}>{children}</APITimerContext.Provider>;
};
