import styled from 'styled-components';
import theme from 'themes';

export const Container = styled.div`
  margin: 2rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
`;

export const Section = styled.div`
  max-height: 30rem;
  border: 0.125rem solid ${(props) => props.theme.colors.lightGrey4};
  border-radius: 0.625rem;
  width: 33%;
  overflow-y: auto;
`;

export const Title = styled.div`
  padding: 2rem 1rem 2rem 1rem;
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.lightGrey4};
  background-color: ${(props) => props.theme.colors.lightGrey1};
  text-align: center;

  h2 {
    margin: 0;
  }

  p {
    margin-top: 0.875rem;
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const Message = styled.div`
  padding: 0.625rem 1rem 0.625rem 1rem;
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.lightGrey4};
  display: flex;

  svg {
    margin-top: 0.1rem;
    margin-right: 0.4rem;
  }

  p {
    margin: 0;
  }
`;

export const Row = styled.div`
  padding: 0.625rem 1rem 0.625rem 1rem;
  border-bottom: 0.125rem solid ${(props) => props.theme.colors.lightGrey4};
  display: flex;

  :last-of-type {
    border: none;
  }

  p {
    margin: 0;
  }

  div {
    :first-of-type {
      width: 60%;
    }

    :last-of-type {
      width: 40%;
    }
  }
`;

export const TopButtonsContainer = styled.div`
  width: 100%;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  justify-content: flex-end;
  padding-right: 1rem;
  button {
    width: 5.375rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 20%;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin: 1rem;
    max-width: 20%;
    svg {
      margin-right: 0.4rem;
    }
  }
`;
export const HeaderButtonContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 2.3rem;
  width: 95%;
  padding-bottom: 0.5rem;
  border-bottom: 0.063rem solid ${theme.colors.lightGrey7};
`;
export const HeaderButtonBox = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-left: 1rem;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  cursor: pointer;
`;
export const ButtonIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;
export const ButtonText = styled.div`
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1.125rem;
`;
