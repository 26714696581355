import React from 'react';
import { MachineIssuesProvider } from 'hooks/useMachineAlarmsAlerts';
import { MachineType } from 'types/machine-health';
import { AlertStatus } from 'types/machine-health/alerts';
import { PressurizationDeltaProvider } from 'hooks/usePressurizationDeltaProvider';
import { ProvidedPage } from './ProvidedPage';

export const PressurizationDeltaWidget = (): JSX.Element => {
  const machineIssuesProviderSettings = {
    machineType: MachineType.Avure,
    alertStatus: AlertStatus.NotComplete,
    isAlarms: false,
    isAlerts: true,
    isMachineAlerts: false
  };

  return (
    <PressurizationDeltaProvider>
      <MachineIssuesProvider {...machineIssuesProviderSettings}>
        <ProvidedPage />
      </MachineIssuesProvider>
    </PressurizationDeltaProvider>
  );
};
