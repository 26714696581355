import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: 'none'
}))``;

export const IcoBracketRightIcon = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg
        {...{ width, color }}
        className="icon--pencil icon"
        focusable="true"
        aria-label={srLabel || `edit`}
      >
        <path
          d="M8 2C3.2 2 2 3.2 2 8C2 12.8 3.2 14 8 14C12.8 14 14 12.8 14 8C14 3.2 12.8 2 8 2Z"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip0_3182_62905)">
          <path
            d="M7.72986 4.83496C8.44947 5.66899 8.84534 6.73365 8.84534 7.83496C8.84534 8.93627 8.44947 10.0009 7.72986 10.835"
            stroke={color ? color : '#0076CC'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3182_62905">
            <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 14 2)" />
          </clipPath>
        </defs>
      </Svg>
    </div>
  );
};
