import React from 'react';

interface IconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const ColumnIcon = ({
  color = '#0076CC',
  width = 20,
  height = 20
}: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1750_23093)">
        <path d="M2.66669 4H6.33335" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M2.66669 6.66669H6.33335"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66669 9.33331H6.33335"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.66669 12H6.33335" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.66669 4H13.3334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9.66669 6.66669H13.3334"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.66669 9.33331H13.3334"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.66669 12H13.3334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1750_23093">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
