import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoLink = ({
  width = '24',
  color
}: {
  width?: string;
  color?: string;
}): JSX.Element => {
  return (
    <Svg width={width} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7210_4230)">
        <path
          d="M6 10.5L10 6.5"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33301 4.49997L7.64167 4.14264C7.95121 3.83302 8.31872 3.58741 8.72319 3.41983C9.12767 3.25226 9.56119 3.16602 9.99901 3.16602C10.4368 3.16602 10.8703 3.25226 11.2748 3.41983C11.6793 3.58741 12.0468 3.83302 12.3563 4.14264C12.6665 4.45191 12.9125 4.81935 13.0804 5.22389C13.2483 5.62843 13.3346 6.06212 13.3346 6.50011C13.3345 6.9381 13.248 7.37176 13.08 7.77626C12.9121 8.18075 12.6659 8.54812 12.3557 8.8573"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.40144 12.8557C7.76877 13.4808 6.9152 13.8314 6.02578 13.8314C5.13636 13.8314 4.28278 13.4808 3.65011 12.8557C3.33835 12.5474 3.09084 12.1804 2.92192 11.7758C2.753 11.3712 2.66602 10.9371 2.66602 10.4987C2.66602 10.0602 2.753 9.62616 2.92192 9.22158C3.09084 8.81699 3.33835 8.44994 3.65011 8.14167L3.99944 7.83301"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.666 13.167H14.666"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.666 11.167V15.167"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7210_4230">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};
