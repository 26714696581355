import { ReactNode, useMemo } from 'react';

import {
  useGetAlertsByMachineIdQuery,
  useGetMachineDataScienceAlertsQuery,
  useGetMachineOverviewAlarmsQuery
} from 'api';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'components/machine-health/IssuesTable';

// Types
import { Alert, AlertStatus, MachineAlert } from 'types/machine-health/alerts';
import { Alarm } from 'types/machine-health/alarms';
import { getDurationBetweenTimestamps } from 'helpers';
import { ActiveIssuesTableDataItemProps } from 'common/ui/ActiveIssuesTable/ActiveIssuesTable';

interface ActiveIssuesTableProps {
  machineId: string;
}

enum RowType {
  Alarm,
  Alert,
  MachineAlert
}

export const useLineActiveIssues = ({
  machineId
}: ActiveIssuesTableProps): {
  activeIssues?: ActiveIssuesTableDataItemProps[];
  isLoading?: boolean;
  hasError?: ReactNode;
} => {
  const { t } = useTranslation(['mh', 'common']);

  const {
    data: alarmsData,
    isLoading: alarmsLoading,
    error: alarmsError
  } = useGetMachineOverviewAlarmsQuery({ machineId });
  const {
    data: alertsData,
    isLoading: alertsLoading,
    error: alertsError
  } = useGetMachineDataScienceAlertsQuery({
    machineId,
    status: AlertStatus.NotComplete
  });
  const {
    data: machineAlertsData,
    error: machineAlertsError,
    isLoading: machineAlertsLoading
  } = useGetAlertsByMachineIdQuery({
    machineId,
    active: true
  });

  const excludeHiddenAlarms = alarmsData?.filter(
    (alarm) => alarm?.location?.trim().toLocaleLowerCase() !== 'hidden'
  );

  const activeIssues: TableRow[] = useMemo(() => {
    // Convert Alarms to TableRows to be rendered by the table
    const alarmRows: TableRow[] | undefined =
      excludeHiddenAlarms?.map((alarm: Alarm, index) => {
        const startDateTime = new Date(alarm.startTimestamp);
        return {
          key: `${alarm.startTimestamp}-${alarm.endTimestamp}-${alarm.code}-${index}`,
          duration: getDurationBetweenTimestamps(alarm.startTimestamp, alarm.endTimestamp),
          startDateTime,
          rowType: RowType.Alarm,
          description: alarm.description,
          alarmCode: alarm.code
        };
      }) || [];

    // Convert Alerts to TableRows to be rendered by the table
    const alertRows: TableRow[] | undefined =
      alertsData?.map((alert: Alert) => {
        const startDateTime = new Date(alert.createdAt);
        return {
          key: alert.id,
          startDateTime,
          rowType: RowType.Alert,
          alertType: alert.alertType,
          description: alert.description,
          alertStatus: alert.status,
          alertId: alert.id
        };
      }) || [];

    // Converts MachineAlerts to TableRows to be rendered by the table
    const machineAlertRows: TableRow[] | undefined =
      machineAlertsData?.map((alert: MachineAlert) => {
        const startDateTime = alert.startTimestamp ? new Date(alert.startTimestamp) : new Date();
        const alarmCode = alert.triggerRule && t(alert.triggerRule);
        return {
          key: alert.id,
          startDateTime,
          rowType: RowType.MachineAlert,
          alertType: alert.triggerRule,
          description: alert.description,
          alarmCode,
          severity: alert.severity, // handle color
          importance: alert.importance // tooltip
        };
      }) || [];

    const combinedRows: TableRow[] = [...alarmRows, ...alertRows, ...machineAlertRows];

    // Then order rows by startDateTime
    const sortedRows = combinedRows.sort((a, b) => {
      // sort in descending order (from newest to oldest)
      return b.startDateTime.getTime() - a.startDateTime.getTime();
    });

    return sortedRows;
  }, [alarmsData, alertsData, machineAlertsData]);

  if (activeIssues) return { activeIssues };

  return {
    isLoading: alarmsLoading || alertsLoading || machineAlertsLoading,
    hasError: alarmsError || alertsError || machineAlertsError
  };
};
