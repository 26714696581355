import { styledTheme } from 'components';
import styled from 'styled-components';
import theme, { colors, themeColors } from 'themes';

// Styled components
export const AlertHeading = styled.h2`
  margin: 0 0 1rem 1rem;
`;

export const FormParent = styled.section`
  //padding: 1rem;
  padding-bottom: 2rem;
  //margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    padding-bottom: 6rem;
  }
  .dropdown-header-row {
    .header--label {
      color: #000000;
      font-size: 0.875rem;
      text-transform: none;
      padding: 1rem 0.25rem;
    }
    .header--value {
      display: none;
    }
`;

export const FormContainer = styled.section`
  background-color: ${styledTheme.color.bg.negLightest};
  padding-top: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const SecondFormContainer = styled.section`
  background-color: ${styledTheme.color.bg.negLightest};
  padding-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const FormItem = styled.section`
  position: relative;
  flex: 1 0 21%;
  padding: 0.75rem;
  .label {
    color: ${theme.colors.text.lightBlack};
    font-weight: 700;
    font-size: 0.75rem;

    small {
      color: red;
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    flex-basis: 50%;
  }
  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

export const LabelContainer = styled.section`
  display: flex;
`;

export const FormSecondRowItem = styled.section`
  position: relative;
  width: 25%;
  padding: 12px;
  .label {
    color: ${theme.colors.text.lightBlack};
    font-weight: 700;
    font-size: 0.75rem;

    small {
      color: red;
      font-weight: 700;
    }
  }

  .label-fields {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
  }

  @media (max-width: 768px) {
    flex-basis: 50%;
  }
  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

export const FormSecondRowItemDiv = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    font-weight: bold;
  }
`;

export const TooltipDiv = styled.div`
  height: 0.625rem;
  padding-top: 0.6875rem;
`;

export const StyledInputField = styled.input`
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border: 0.063rem solid ${themeColors.lightGrey6};
  border-radius: 0.25rem;
  font-size: 100%;
  width: 100%;
`;

export const StyledTextarea = styled.textarea<{ error?: boolean }>`
  width: 100%;
  padding: 1rem;
  border: 0.0625rem solid ${({ error }) => (error ? '#B62C10' : theme.colors.lightGrey6)};
  box-shadow: ${theme.colors.borders.border02.shadow};
  border-radius: 0.375rem;
  :focus {
    outline: none;
    border-color: ${theme.colors.primaryBlue5};
  }
  :hover {
    outline: none;
    border-color: ${theme.colors.primaryBlue5};
  }

  ::placeholder {
    font-size: 14px;
    color: ${theme.colors.lightGrey8};
  }
`;

export const Footer = styled.div<{ flexDirection?: string; bgColor?: string }>`
  background-color: transparent;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1.3125rem 1.625rem 2.5rem 2.375rem;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  display: flex;
  flex-direction: revert;
  justify-content: end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  & button {
    padding: 0.75rem 1rem;
    margin-left: 1rem;
  }
`;

export const PageContainer = styled.div`
  height: 100%;
  > * {
    flex: 1;
  }
`;

export const HeaderContainer = styled.header`
  padding-bottom: 0.5rem;
  div a h3 {
    color: ${themeColors.lightGrey8};
    font-family: 'Roboto';
  }
  div h2 {
    font-family: 'Roboto';
    font-weight: 400;
    color: ${theme.colors.text.lightBlack};
  }
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  & button {
    padding: 0.75rem 1rem;
    margin-left: 1rem;
  }
`;

export const masterTagLists = [
  { value: 'PNA_Freezer_GCM_10_MTL', label: 'PNA Freezer GCM 10 MTL', isFixed: true },
  { value: 'PNA_Freezer_GCM_20_MTL', label: 'PNA Freezer GCM 20 MTL', isFixed: true },
  { value: 'PNA_Freezer_GCM_30_MTL', label: 'PNA Freezer GCM 30 MTL', isFixed: true }
];

export const Nav = styled.div`
  display: block;
  text-align: left;
  border: 0;
  width: 100%;
  padding-top: 1rem;
  border-bottom: 1px solid ${theme.colors.lightGrey7};
  a {
    display: block;
    margin: 0 1rem;
    line-height: inherit;
    cursor: pointer;
    text-align: center;
  }
  ul {
    list-style: none;
    z-index: 999;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const NavMenu = styled.ul`
  line-height: 2.813rem;
  display: flex;
  align-items: start;
  font-size: 0.8125rem;
  .nav__menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0rem 0.2rem 0rem 0rem;
    cursor: pointer;
    border-radius: 0;

    span {
      font-size: 14px;
      &:hover {
        color: ${theme.colors.primaryBlue5};
      }
    }

    span:hover svg {
      color: ${theme.colors.primaryBlue5};
    }
  }
  .icon {
    padding-left: 1rem;
  }
`;

export const Tab = styled.span<{ $isSelected?: boolean }>`
  color: ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8)};
  border: 0;
  font-size: 0.885rem;

  font-weight: ${(props) => (props.$isSelected ? '600' : '400')};
  border-bottom: 2px solid ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : 'none')};
  margin-bottom: 4px;
  padding-bottom: 0;
  padding-right: 0.5rem;
  display: flex;
  span {
    padding-left: 10px;
  }
`;

export const SubTab = styled.span<{ $isSelected?: boolean }>`
  color: ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8)};
  border: 0;
  font-size: 0.885rem;
  font-weight: ${(props) => (props.$isSelected ? '600' : '400')};
  display: flex;
`;

export const SelectedSubTab = styled.span<{ $isSelected?: boolean }>`
  color: ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : themeColors.lightGrey8)};
  border: 0;
  font-size: 0.885rem;
  min-width: 9rem;
  margin: 0px 10px;
  padding-left: 5px;
  border-bottom: 2px solid ${(props) => (props.$isSelected ? themeColors.primaryBlue5 : 'none')};
  font-weight: ${(props) => (props.$isSelected ? '600' : '400')};
`;

export const NavSubMenu = styled.ul`
  flex-direction: column;
  list-style: none;
  position: absolute;
  top: 3rem;
  left: 0.15rem;
  background: #ffffff;
  padding: 0;
  box-shadow: 0.063rem 0.063rem 0.625rem 0.063rem #00000040;
  box-shadow: 0rem 0.438rem 0.563rem 0rem #0000001a;
  border-radius: 0.313rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  min-width: 10rem;
`;

type WarningIconContainerProps = {
  right?: string;
  left?: string;
  top?: string;
  bottom?: string;
};

export const WarningIconContainer = styled.div<WarningIconContainerProps>`
  cursor: pointer;
  position: absolute;
  width: min-content;
  right: ${({ right }) => {
    return right || '1.5rem';
  }};
  left: ${({ left }) => {
    return left;
  }};
  top: ${({ top }) => {
    return top || '3.8rem';
  }};
`;

export const Container = styled.div<{ error?: boolean }>`
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  width: 99%;
  ${({ error }) => {
    return error
      ? `
    border: 0.063rem solid ${theme.colors.text.error};
    border-radius: 0.625rem;
    `
      : '';
  }}
`;

export const AlertLogicTitle = styled.h2`
  margin: 1rem 0.2rem;
  color: ${colors.text.lightBlack};
  font-size: 16px;
  font-weight: 500;
`;

export const HideToggleButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: #ab091e;
`;

export const AlertLogicHeader = styled.div`
  display: flex;
  align-items: center;

  .required-wrapper {
    padding-top: 5px;
    margin-left: 5px;
    display: flex;

    .required-message {
      color: ${themeColors.warning};
      font-weight: 500;
    }
  }
`;

export const RequiredTextMessageWrapper = styled.div`
  font-weight: 600;
  margin: auto 0;
  #astrick-icon {
    color: ${theme.colors.text.error};
  }
`;
