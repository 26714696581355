import React, { ReactNode } from 'react';
import { WidgetUi, WidgetUiProps } from 'common/ui';
import TagTable from 'components/StyledUi/LineCardWidget/TagTable';
import { LineOverviewWidgetContainer } from './LineOverviewWidget.elements';
import { ActiveIssuesWidget } from '../ActiveIssuesWidget';
import { ActiveIssuesTableDataItemProps } from 'common/ui/ActiveIssuesTable/ActiveIssuesTable';

export interface TagTableProps {
  tableData?: TagProps[];
}

export interface TagProps {
  name: string | undefined;
  unit?: string;
  value: string | number | null;
}

export interface LineOverviewWidgetProps extends WidgetUiProps {
  tagTableData?: TagProps[];
  tagData?: Record<string, unknown>[];
  activeIssuesData?: ActiveIssuesTableDataItemProps[] | undefined;
  activeIssuesDataStatus?: {
    title?: string | ReactNode;
    isLoading?: boolean;
    hasError?: ReactNode;
    hasMessage?: ReactNode;
  };
  tagTableDataStatus?: {
    isLoading?: boolean;
    hasError?: ReactNode;
    hasMessage?: ReactNode;
  };
}

const ActiveIssuesRow = ({ activeIssuesData, activeIssuesDataStatus }: LineOverviewWidgetProps) => {
  return (
    <ActiveIssuesWidget
      title={activeIssuesDataStatus?.title}
      tableData={activeIssuesData}
      {...activeIssuesDataStatus}
    />
  );
};

const TagTableRow = ({ tagTableData, tagTableDataStatus }: LineOverviewWidgetProps) => {
  return tagTableDataStatus?.hasMessage ? (
    (tagTableDataStatus.hasMessage as JSX.Element)
  ) : (
    <TagTable tableData={tagTableData || []} />
  );
};

export const LineOverviewWidget = ({
  title,
  tagTableData,
  tagTableDataStatus,
  activeIssuesData,
  activeIssuesDataStatus,
  ...rest
}: LineOverviewWidgetProps): JSX.Element => {
  const widgetSettings = {
    title,
    ...rest
  };

  return (
    <WidgetUi {...widgetSettings}>
      <LineOverviewWidgetContainer>
        <TagTableRow {...{ tagTableData, tagTableDataStatus }} />
        <ActiveIssuesRow {...{ activeIssuesData, activeIssuesDataStatus }} />
      </LineOverviewWidgetContainer>
    </WidgetUi>
  );
};
