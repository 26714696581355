import { styledTheme } from 'common/theme';
import styled from 'styled-components';

export const IssuesContainer = styled.div`
  padding: 0 5% 5% 5%;
  height: auto;
  overflow-y: scroll;

  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-width: 0 1px 1px 1px;
  border-color: ${styledTheme.colors.border.main};
  border-style: solid;
`;

export const IssuesRow = styled.div`
  font-size: 0.75rem;
  border-bottom: 1px solid ${styledTheme.colors.border.light};
  padding: 0 0 2%;

  p {
    display: inline;
    padding: 0;
    margin: 0;
    line-height: 1.4;
  }

  &:first-child {
    padding-top: 3%;
  }

  &:last-child {
    border-bottom: none;
  }

  .issues_row--1 {
    display: flex;
    align-items: center;
    padding-top: 2%;
    font-size: 0.87rem;
    font-weight: normal;
    color: ${styledTheme.colors.black};

    img {
      width: 20px;
      max-width: 20px;
    }
  }

  .issues_row--2 {
    padding-top: 1%;
    padding-bottom: 1%;
    color: ${styledTheme.colors.black};
  }

  .issues_row--strong {
    font-weight: 700;
    padding-right: 0.5rem;
    color: ${styledTheme.colors.black};
  }
`;
