export const createBars = (
  data: Record<string, unknown>[],
  isChild: boolean
): Record<string, unknown>[] => {
  return data.reduce((prev, current) => {
    // Combine the parent property with the child state code to make it a unique state
    const uniqueStateCode = `${current.data_type}-${current.mode_descr}`;

    prev.push({
      ...current,
      state: isChild ? uniqueStateCode : current.data_type,
      class: isChild ? 'bar-child' : 'bar-parent',
      height: isChild ? '20' : '40'
    });

    return prev;
  }, []);
};
