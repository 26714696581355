import React from 'react';
import type { FC, SVGProps } from 'react';

const IcoPdf: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="30"
    {...props}
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16651 1.84433C2.69657 1.84433 1.5 3.04176 1.5 4.51165V24.9959C1.5 26.4658 2.69657 27.6632 4.16651 27.6632H19.8302C21.3001 27.6632 22.4967 26.4658 22.4967 24.9959V8.15214C22.4967 8.15214 22.5386 7.65892 22.3245 7.17897C22.1238 6.72908 21.7967 6.41596 21.7967 6.41596C21.7959 6.41485 21.7951 6.41375 21.7943 6.41265L17.9081 2.6008C17.9059 2.59862 17.9038 2.59647 17.9016 2.59434C17.9016 2.59434 17.5698 2.2784 17.078 2.06734C16.4988 1.81883 15.9019 1.84507 15.9019 1.84507L15.9108 1.84424L4.16651 1.84433Z"
      fill="#FF2116"
    />
    <path
      d="M4.1668 2.7207H15.9111C15.9141 2.72073 15.917 2.72073 15.92 2.7207C15.92 2.7207 16.3896 2.72525 16.7331 2.87266C17.0637 3.01453 17.2982 3.22929 17.2997 3.23073C17.2997 3.23073 17.3014 3.23239 17.3014 3.23239L21.1779 7.03534C21.1779 7.03534 21.4114 7.28192 21.5246 7.53567C21.616 7.74048 21.6216 8.11521 21.6216 8.11521C21.6212 8.12139 21.6209 8.12758 21.6208 8.13378V24.9961C21.6208 25.9959 20.8302 26.7873 19.8304 26.7873H4.1668C3.167 26.7873 2.37646 25.996 2.37646 24.9961V4.51188C2.37646 3.51203 3.167 2.7207 4.1668 2.7207Z"
      fill="#F5F5F5"
    />
    <path
      d="M6.74254 17.2167C6.13971 16.6139 6.79193 15.7855 8.56198 14.9059L9.67572 14.3524L10.1096 13.4031C10.3483 12.8809 10.7044 12.029 10.9011 11.5098L11.2587 10.5659L11.0123 9.8674C10.7093 9.00849 10.6012 7.71776 10.7935 7.25364C11.0539 6.62498 11.9068 6.68949 12.2445 7.36337C12.5082 7.88968 12.4813 8.8428 12.1686 10.0448L11.9123 11.0303L12.1381 11.4135C12.2623 11.6243 12.6249 12.1248 12.944 12.5257L13.5438 13.2717L14.2901 13.1743C16.661 12.8649 17.473 13.3908 17.473 14.1447C17.473 15.0962 15.6114 15.1747 14.0481 14.0767C13.6963 13.8297 13.4548 13.5842 13.4548 13.5842C13.4548 13.5842 12.4754 13.7836 11.9932 13.9136C11.4955 14.0477 11.2472 14.1318 10.5181 14.3779C10.5181 14.3779 10.2623 14.7493 10.0956 15.0194C9.47541 16.0242 8.75129 16.8568 8.23416 17.1599C7.65516 17.4992 7.04816 17.5224 6.74254 17.2167ZM7.6886 16.8788C8.02747 16.6693 8.71333 15.8581 9.18811 15.1053L9.38033 14.8004L8.50508 15.2405C7.15325 15.9202 6.53482 16.5608 6.8564 16.9483C7.03705 17.1659 7.25316 17.1479 7.6886 16.8788ZM16.4695 14.414C16.8009 14.1819 16.7528 13.7141 16.3781 13.5254C16.0865 13.3786 15.8514 13.3485 15.0939 13.3597C14.6284 13.3914 13.8798 13.4852 13.7529 13.5138C13.7529 13.5138 14.1641 13.798 14.3467 13.9025C14.5898 14.0413 15.1804 14.299 15.6116 14.431C16.037 14.5611 16.283 14.5474 16.4695 14.414ZM12.9388 12.9466C12.7382 12.7357 12.3971 12.2956 12.1808 11.9686C11.898 11.5976 11.7561 11.3359 11.7561 11.3359C11.7561 11.3359 11.5494 12.0009 11.3797 12.4011L10.8505 13.7091L10.6971 14.0058C10.6971 14.0058 11.5128 13.7384 11.9278 13.63C12.3674 13.5152 13.2595 13.3397 13.2595 13.3397L12.9388 12.9466ZM11.801 8.38371C11.8522 7.95435 11.8739 7.52563 11.7359 7.30943C11.3533 6.89114 10.8915 7.23996 10.9697 8.23443C10.996 8.56898 11.0791 9.14088 11.1902 9.49337L11.3923 10.1343L11.5344 9.65156C11.6126 9.38608 11.7326 8.81554 11.801 8.38371Z"
      fill="#FF2116"
    />
    <path
      d="M7.76758 20.0093H8.7463C9.05903 20.0093 9.31288 20.0392 9.50786 20.099C9.70283 20.157 9.86691 20.2873 10.0001 20.49C10.1333 20.6907 10.1999 20.933 10.1999 21.2168C10.1999 21.4774 10.1459 21.7013 10.0378 21.8885C9.92965 22.0758 9.78391 22.2109 9.60052 22.2939C9.41905 22.3769 9.13914 22.4184 8.76078 22.4184H8.42199V23.9618H7.76758V20.0093ZM8.42199 20.516V21.8943H8.7463C9.03587 21.8943 9.23566 21.8403 9.3457 21.7322C9.45766 21.6241 9.51365 21.4484 9.51365 21.2052C9.51365 21.0237 9.47697 20.877 9.40361 20.765C9.33026 20.6511 9.24918 20.5816 9.16038 20.5566C9.07351 20.5295 8.93548 20.516 8.7463 20.516L8.42199 20.516ZM10.7009 20.0093H11.5898C12.0203 20.0093 12.3639 20.0855 12.6207 20.238C12.8793 20.3905 13.0743 20.6174 13.2056 20.9185C13.3388 21.2196 13.4054 21.5536 13.4054 21.9204C13.4054 22.3065 13.3455 22.6511 13.2258 22.9541C13.1081 23.2553 12.9266 23.4985 12.6815 23.6838C12.4382 23.8692 12.0908 23.9618 11.639 23.9618H10.7009V20.0093ZM11.3553 20.5334V23.4377H11.6275C12.0078 23.4377 12.2838 23.3064 12.4556 23.0439C12.6274 22.7794 12.7133 22.4271 12.7133 21.987C12.7133 21.0179 12.3514 20.5334 11.6275 20.5334H11.3553ZM14.0337 20.0093H16.2286V20.5334H14.6881V21.7148H15.9217V22.2389H14.6881V23.9618H14.0337V20.0093Z"
      fill="#2C2C2C"
    />
  </svg>
);

const IcoZip: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8393_20441)">
      <path
        d="M21.8906 19.5656H20.7281L20.7375 20.7281V23.0625H18.4031H5.59688H3.28125C3.28125 23.0625 3.27188 20.5623 3.27188 19.5656H2.1C1.45312 19.5656 0.9375 19.0406 0.9375 18.4031V11.4188C0.9375 10.7719 1.4625 10.2563 2.1 10.2563H3.2625V3.2625L3.28125 0.9375L5.59688 0.9375H14.9062H16.0687L20.7281 5.59688V6.75937V10.2563H21.8906C22.5375 10.2563 23.0531 10.7813 23.0531 11.4188V18.4031C23.0625 19.05 22.5375 19.5656 21.8906 19.5656ZM4.43437 20.7281C4.43437 21.054 4.44721 21.8906 4.44721 21.8906C4.44721 21.8906 5.28048 21.8906 5.59688 21.8906C5.91327 21.8906 18.4031 21.8906 18.4031 21.8906H19.5469L19.5656 20.7281V19.5656H4.43437C4.43437 19.5656 4.43437 20.4023 4.43437 20.7281ZM12.975 18.4031V11.4656H11.2313V18.4031H12.975ZM3.60938 11.4656V12.7594H6.91875V12.7969L3.2625 17.55V18.3937H8.82187V17.1H5.59688V17.0625L9.16875 12.375V11.4656H3.60938ZM19.5656 6.75937H17.2313C15.9563 6.75937 14.9062 5.71875 14.9062 4.43437V2.1H5.59688H4.43437V3.2625V10.2469H19.575L19.5656 6.75937ZM19.9125 11.9719C19.3969 11.6062 18.6375 11.4188 17.5687 11.4188C16.5094 11.4188 15.7594 11.4844 15.225 11.5594V18.4031H16.9406V15.9188C17.1 15.9375 17.3062 15.9469 17.5312 15.9469C18.5531 15.9469 19.4344 15.7219 20.025 15.2156C20.4844 14.8219 20.7281 14.25 20.7281 13.5656C20.7281 12.9 20.4 12.3187 19.9125 11.9719ZM17.5219 14.7469C17.2687 14.7469 17.0906 14.7375 16.9406 14.7094V12.6656C17.0625 12.6375 17.3062 12.6 17.6625 12.6C18.525 12.6 19.0125 12.9844 19.0125 13.6219C19.0125 14.325 18.4406 14.7469 17.5219 14.7469Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_8393_20441">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IcoExcel: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8393_20427)">
      <path
        d="M15.0699 11.442L5.58154 9.76758V22.1399C5.58154 22.7049 6.03958 23.1629 6.60461 23.1629H22.9771C23.5421 23.1629 24.0001 22.7049 24.0001 22.1399V17.5815L15.0699 11.442Z"
        fill="#185C37"
      />
      <path
        d="M15.0699 0.837402H6.60461C6.03958 0.837402 5.58154 1.29544 5.58154 1.86047V6.4188L15.0699 12.0002L20.0932 13.6746L24.0001 12.0002V6.4188L15.0699 0.837402Z"
        fill="#21A366"
      />
      <path d="M5.58154 6.41895H15.0699V12.0003H5.58154V6.41895Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.3724 5.30273H5.58154V19.2562H12.3724C12.9367 19.2544 13.3937 18.7974 13.3955 18.2332V6.3258C13.3937 5.76154 12.9367 5.30457 12.3724 5.30273Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8143 5.86084H5.58154V19.8143H11.8143C12.3785 19.8125 12.8355 19.3555 12.8374 18.7913V6.88391C12.8355 6.31965 12.3785 5.86267 11.8143 5.86084Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8143 5.86084H5.58154V18.698H11.8143C12.3785 18.6962 12.8355 18.2392 12.8374 17.675V6.88391C12.8355 6.31965 12.3785 5.86267 11.8143 5.86084Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.2562 5.86084H5.58154V18.698H11.2562C11.8204 18.6962 12.2774 18.2392 12.2792 17.675V6.88391C12.2774 6.31965 11.8204 5.86267 11.2562 5.86084Z"
        fill="black"
      />
      <path
        d="M1.02307 5.86084H11.256C11.821 5.86084 12.2791 6.31888 12.2791 6.88391V17.1168C12.2791 17.6819 11.821 18.1399 11.256 18.1399H1.02307C0.458041 18.1399 0 17.6819 0 17.1168V6.88391C0 6.31888 0.458041 5.86084 1.02307 5.86084Z"
        fill="url(#paint0_linear_8393_20427)"
      />
      <path
        d="M3.16846 15.3257L5.32064 11.9908L3.34874 8.67432H4.93497L6.01106 10.7952C6.11041 10.9967 6.1785 11.1463 6.21534 11.2451H6.22929C6.29999 11.0844 6.37441 10.9283 6.45255 10.7768L7.60287 8.67544H9.05906L7.03691 11.9724L9.11042 15.3257H7.56102L6.31804 12.9977C6.25949 12.8986 6.20981 12.7946 6.16958 12.6868H6.15117C6.11475 12.7924 6.06644 12.8935 6.00716 12.9882L4.72735 15.3257H3.16846Z"
        fill="white"
      />
      <path
        d="M22.977 0.837402H15.0698V6.4188H24.0001V1.86047C24.0001 1.29544 23.542 0.837402 22.977 0.837402Z"
        fill="#33C481"
      />
      <path d="M15.0698 12H24.0001V17.5814H15.0698V12Z" fill="#107C41" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8393_20427"
        x1="2.13312"
        y1="5.06143"
        x2="10.1459"
        y2="18.9393"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18884F" />
        <stop offset="0.5" stopColor="#117E43" />
        <stop offset="1" stopColor="#0B6631" />
      </linearGradient>
      <clipPath id="clip0_8393_20427">
        <rect width="24" height="22.3256" fill="white" transform="translate(0 0.837402)" />
      </clipPath>
    </defs>
  </svg>
);

export { IcoPdf, IcoZip, IcoExcel };
