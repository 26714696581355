import { Button, Modal, Typography } from 'components';
import React from 'react';
import { Content, ModalText } from './bookmarkDocItem.element';
import { BtnContainer } from 'pages/Documentation/documentation.elements';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  showModal: boolean;
  closeModal: () => void;
};
const BookmarkLimitModal = ({ showModal, closeModal }: ModalProps): JSX.Element => {
  const { t } = useTranslation('fpns');
  return (
    <Modal
      visible={showModal}
      onClose={closeModal}
      size={'small_auto_height'}
      widthOverride="28rem"
    >
      <Content>
        <Typography weight="semi-bold" size={'1.25rem'} mb="0">
          {t('bookmark_limit')}
        </Typography>
        <ModalText>{t(`bookmark_limit_message`)}</ModalText>
        <BtnContainer justifyContent="end">
          <Button width="8rem" borderRadius="3rem" variant="hover-blue" onClick={closeModal}>
            Ok
          </Button>
        </BtnContainer>
      </Content>
    </Modal>
  );
};

export default BookmarkLimitModal;
