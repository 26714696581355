import React from 'react';

export const IcoTemplate = ({
  width = '16',
  color
}: {
  width?: string;
  color?: string;
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Template" clipPath="url(#clip0_8238_49478)">
        <path
          id="Vector"
          d="M2.66602 3.33366C2.66602 3.15685 2.73625 2.98728 2.86128 2.86225C2.9863 2.73723 3.15587 2.66699 3.33268 2.66699H12.666C12.8428 2.66699 13.0124 2.73723 13.1374 2.86225C13.2624 2.98728 13.3327 3.15685 13.3327 3.33366V4.66699C13.3327 4.8438 13.2624 5.01337 13.1374 5.1384C13.0124 5.26342 12.8428 5.33366 12.666 5.33366H3.33268C3.15587 5.33366 2.9863 5.26342 2.86128 5.1384C2.73625 5.01337 2.66602 4.8438 2.66602 4.66699V3.33366Z"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.66602 8.66667C2.66602 8.48986 2.73625 8.32029 2.86128 8.19526C2.9863 8.07024 3.15587 8 3.33268 8H5.99935C6.17616 8 6.34573 8.07024 6.47075 8.19526C6.59578 8.32029 6.66602 8.48986 6.66602 8.66667V12.6667C6.66602 12.8435 6.59578 13.013 6.47075 13.1381C6.34573 13.2631 6.17616 13.3333 5.99935 13.3333H3.33268C3.15587 13.3333 2.9863 13.2631 2.86128 13.1381C2.73625 13.013 2.66602 12.8435 2.66602 12.6667V8.66667Z"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M9.33398 8H13.334"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M9.33398 10.667H13.334"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M9.33398 13.333H13.334"
          stroke={color || '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8238_49478">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
