import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import Button from 'components/Button';
import Card from 'components/Card';
import Typography from 'components/Typography/Typography';
import theme from 'themes';
import { MaintenanceEvent, MaintenanceEventStatus, MaintenanceTaskType } from 'types/maintenance';
import { CTAsStretch, Table } from '.';
import { formatDate, markTaskCompleteOrIncompleteByType } from 'helpers';
import { useUpdateMaintenanceEventsMutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import { CardFooter } from '../MaintenanceEventDetailCard';
import { IcoBadgCheck } from 'icons/IcoBadgCheck';
import { IcoCheck } from 'icons/IcoCheck';
import { IcoClose } from 'icons/IcoClose';
import styled from 'styled-components';
import { IcoChevronDown } from 'icons/IcoChevronDown';

interface CloseOutSurveyCardProps {
  machineDescription?: string;
  event: MaintenanceEvent;
  surveyClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  refresh?: CallableFunction;
}

export const BtnText = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
export const DownArrow = styled.div`
  margin-left: auto;
  margin-top: 1rem;
`;

function getSurveyTitle(
  event: MaintenanceEvent,
  editClicked: boolean,
  t: TFunction<'fpns'[], undefined>
): string {
  if (event.status == MaintenanceEventStatus.NotComplete || editClicked) {
    return t('mark_complete', { ns: 'fpns' });
  } else if (event.status == MaintenanceEventStatus.Completed && event.completedAsPlanned) {
    return t('completed_as_planned', { ns: 'fpns' });
  }
  return t('not_completed_as_planned', { ns: 'fpns' });
}

function getSurveyHeaderColor(event: MaintenanceEvent) {
  if (event.status == MaintenanceEventStatus.Completed && event.completedAsPlanned) {
    return theme.colors.white;
  } else if (event.status == MaintenanceEventStatus.Completed && !event.completedAsPlanned) {
    return theme.colors.white;
  }
  return theme.colors.white;
}

const CloseOutSurveyCard = ({
  machineDescription,
  event,
  surveyClickHandler
}: CloseOutSurveyCardProps): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [editClicked, setEditClicked] = useState<boolean>(false);
  const pmSurveyTitle = getSurveyTitle(event, editClicked, t);
  const [headerColor, setHeaderColor] = useState<string>(getSurveyHeaderColor(event));
  const [updateMaintenanceEvent] = useUpdateMaintenanceEventsMutation();

  return (
    <Card borderColor={theme.colors.tableborder}>
      <Card.Header bgColor={headerColor} padding="0 1rem" icon={<IcoBadgCheck />}>
        <Typography variant="h3" mb={0} size="0.8125rem" weight="medium">
          {pmSurveyTitle}?
        </Typography>
        <DownArrow>
          <IcoChevronDown width="24" color={theme.colors.primaryBlue5} />
        </DownArrow>
      </Card.Header>
      <Card.Body pl={'3rem'} pt={0}>
        {event.status === MaintenanceEventStatus.Completed && event.completion && (
          <Table col={2} mb="1rem">
            <tbody>
              <tr>
                <td>
                  <Typography as="span" size=".95rem" weight="semi-bold">
                    Completed on:
                  </Typography>
                </td>
                <td>
                  <Typography as="span" size=".95rem" weight="semi-bold">
                    {event.completion
                      ? formatDate(event.completion, 'short')
                      : t('scheduled_date_unavailable')}
                  </Typography>
                </td>
              </tr>
              {event.closedRunMetric && (
                <tr>
                  <td>
                    <Typography as="span" size=".95rem" weight="semi-bold">
                      {t('run_metric')}:
                    </Typography>
                  </td>
                  <td>
                    <Typography as="span" size=".95rem" weight="semi-bold">
                      {event.closedRunMetric}
                    </Typography>
                  </td>
                </tr>
              )}
              {event.status == MaintenanceEventStatus.Completed &&
                event.comments &&
                event.comments.length > 0 && (
                  <tr>
                    <td>
                      <Typography as="span" size=".95rem" weight="semi-bold">
                        {t('comments')}:
                      </Typography>
                    </td>
                    <td>
                      <Typography as="span" size=".95rem" weight="semi-bold">
                        {event.comments ? event.comments : t('comments_unavailable')}
                      </Typography>
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        )}
        {event.status == MaintenanceEventStatus.NotComplete && (
          <Typography as="span" weight="semi-bold" size="1rem">
            {t('is_completed_as_planned')}
          </Typography>
        )}
      </Card.Body>
      <CardFooter>
        {(event.status == MaintenanceEventStatus.NotComplete || editClicked) && (
          <>
            <CTAsStretch>
              <Button variant="text" onClick={surveyClickHandler} size="small">
                <BtnText>
                  {IcoClose(theme.colors.darkGrey)}
                  <Typography mb={0} size={'1rem'} color={theme.colors.darkGrey}>
                    {t('no', { ns: 'common' })}
                  </Typography>{' '}
                </BtnText>
              </Button>
              <Button
                variant="text"
                onClick={surveyClickHandler}
                bgColor={theme.colors.onTrackGreen3}
                size="small"
                color={theme.colors.primaryBlue5}
              >
                <BtnText>
                  <IcoCheck />
                  <Typography mb={0} color={theme.colors.primaryBlue5} size={'1rem'}>
                    {t('yes', { ns: 'common' })}
                  </Typography>
                </BtnText>
              </Button>
            </CTAsStretch>
          </>
        )}
        {event.status == MaintenanceEventStatus.Completed && !editClicked && (
          <CTAsStretch>
            <Button
              borderRadius="3rem"
              variant="hover-blue"
              bgColor={theme.colors.primaryBlue5}
              color={theme.colors.lightGrey3}
              onClick={() => {
                setEditClicked(true);
                setHeaderColor(theme.colors.white);
                updateMaintenanceEvent([
                  {
                    ...markTaskCompleteOrIncompleteByType(
                      MaintenanceTaskType.Complete,
                      event,
                      false
                    ),
                    status: MaintenanceEventStatus.NotComplete,
                    completedAsPlanned: undefined,
                    followUpNeeded: undefined,
                    completion: undefined,
                    completionCycleCount: undefined
                  }
                ]).then(() => {
                  ToastMsg({
                    message: t('mark_incomplete', {
                      item: event.description,
                      machine_name: machineDescription
                    }),
                    heading: t('incomplete') as string,
                    theme: 'colored',
                    type: 'warning'
                  });
                });
              }}
              size="small"
            >
              {t('mark_as_incomplete')}
            </Button>
          </CTAsStretch>
        )}
      </CardFooter>
    </Card>
  );
};

export default CloseOutSurveyCard;
