import { useDateRange } from 'components';
import React, { ReactNode, createContext, useContext, useState } from 'react';

export type SelectedItemsPropsSelectedX = Date[];
export type SelectedItemsPropsSelectedY = number[] | undefined;
export type SelectedItemsPropsHandle = (type: string, x?: number[]) => void;
export type SelectedItemsPropsHistory = [
  SelectedItemsPropsSelectedX,
  SelectedItemsPropsSelectedY | undefined
];

export interface SelectedItemsProps {
  history?: SelectedItemsPropsHistory;
  setState: (value: SelectedItemsPropsHistory) => void;
  resetState: () => void;
  index?: number;
  lastIndex?: number;
  goBack: () => void;
}

export const SelectedItemsProvider = ({ children }: Props): JSX.Element => {
  const { startTime: startDatetime, endTime: endDatetime } = useDateRange().dateRange;

  const maxHistory = 5;

  const initY = undefined as SelectedItemsPropsSelectedY;
  const initX = [startDatetime, endDatetime] as SelectedItemsPropsSelectedX;

  const initVal = [initX, initY] as unknown as SelectedItemsPropsHistory[];

  const [history, setHistory] = useState<SelectedItemsPropsHistory[]>([initVal]);
  const [index, setIndex] = useState(0);

  const setState = (value: SelectedItemsPropsHistory) => {
    const copy = history;

    if (copy.length > maxHistory) {
      copy.shift(); //remove first old element
    }
    copy.push(value);

    setHistory(copy);
    setIndex(copy.length - 1);
  };

  // Allows you to go back (undo) N steps
  const goBack = () => {
    setIndex(Math.max(0, index - 1));
  };

  // Clear all state history
  const resetState = () => {
    setIndex(0);
    setHistory([initVal]);
  };

  const values = {
    history,
    setState,
    resetState,
    index,
    lastIndex: history.length - 1,
    goBack
  };

  return <SelectedItemsContext.Provider value={values}>{children}</SelectedItemsContext.Provider>;
};

const SelectedItemsContext = createContext({
  resetState: () => console.log(),
  goBack: () => console.log(),
  setState: (value: SelectedItemsPropsHistory) => console.log(value)
});

export const useSelectedItems = (): SelectedItemsProps =>
  useContext<SelectedItemsProps>(SelectedItemsContext);

interface Props {
  children?: ReactNode | ReactNode[];
}
