import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: 'none'
}))``;

export const IcoBracketLeftIcon = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg
        {...{ width, color }}
        className="icon--pencil icon"
        focusable="true"
        aria-label={srLabel || `edit`}
      >
        <path
          d="M8 2C12.8 2 14 3.2 14 8C14 12.8 12.8 14 8 14C3.2 14 2 12.8 2 8C2 3.2 3.2 2 8 2Z"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip0_3182_62898)">
          <path
            d="M8.27014 4.83496C7.55053 5.66899 7.15466 6.73365 7.15466 7.83496C7.15466 8.93627 7.55053 10.0009 8.27014 10.835"
            stroke={color ? color : '#0076CC'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3182_62898">
            <rect width="12" height="12" fill="white" transform="translate(2 2)" />
          </clipPath>
        </defs>
      </Svg>
    </div>
  );
};
