import React, { useEffect, useRef, useState } from 'react';
import DotsChart from '../../../../../../common/components/Charts/DotsChart/DotsChart';
import { DoctsChartContainer } from 'pages/FleetMachineDetail/MachineHealth/components/SkidStrokeCountsChart/DotsChart.elements';
import { COLORMAP } from '../../../../../../pages/FleetMachineDetail/MachineHealth/components/PressurizationDelta/types/constants';
import { PressureDeltaData } from '../types';
import { useSelectedItems } from '../../../../hooks/useZoomHistoryhook';
import { generateCheckmark } from 'icons/checkmark';
import { styledTheme } from 'common/theme';
import { format } from 'date-fns';

interface Props {
  data: PressureDeltaData[];
  data2: Record<string, unknown>[];
  tooltipData: Record<string, unknown>;
  height: number;
  width?: number;
}

export const DotsChartWrapper = ({ data, data2, height, width }: Props): JSX.Element => {
  const chartArea = useRef<HTMLDivElement | undefined>(undefined);

  const [chart, setChart] = useState<DotsChart>();
  const [chartWidth, setChartWidth] = useState<number | undefined>(undefined);

  const { history, index, setState } = useSelectedItems();

  const tooltipMessage = (data: {
    batchNumber: string;
    lotId: string;
    batchSuccessful: boolean;
    idealPressurizeTime: string;
    pressurizeTime: string;
    startTime: string;
  }): string => {
    const { batchNumber, lotId, batchSuccessful, idealPressurizeTime, pressurizeTime, startTime } =
      data;

    const content = `<div class='tooltip--line-item'><span class='color-indicator' style='background-color: ${COLORMAP['idealPressurizeTime']}'></span>Ideal Pressurization Time - <span class='tooltip--value'>${idealPressurizeTime}s</span></div>
        <div class='tooltip--line-item'><span class='color-indicator' style='background-color: ${COLORMAP['pressurizeTime']}'></span>Actual Pressurization Time - <span class='tooltip--value'>${pressurizeTime}s</span></div>`;

    const checkmark = batchSuccessful
      ? generateCheckmark({ color: styledTheme.colors.status.success.base })
      : generateCheckmark({ color: styledTheme.colors.status.error.base });

    return `<div class="tooltip--inner">
                    <div class='tooltip--header'>
                      <div>${checkmark}</div>
                      ${format(new Date(startTime), 'MMMM d, y')}
                    </div>
                    <div class='tooltip--subheader'><small>LotID:</small> ${lotId} <small>Batchnumber: </small> ${batchNumber}</div>
                    ${content}
              </div>`;
  };

  const errorMessage = (data: {
    machineTime: string;
    description: string;
    detailedInstructions: string;
  }): string => {
    const { machineTime, description, detailedInstructions } = data;
    const item = `
        <div class='tooltip--header'>${machineTime}</div>
        <div class='error--line-item'>
          <span class='tooltip--value'><p><b>${description}</b> ${detailedInstructions}</p></span>
        </div>    
      `;
    return `<div class='message--inner'>${item}</div>`;
  };

  const settings = {
    data: data,
    dataTooltipMessage: tooltipMessage,
    data2: data2,
    height: height,
    width: width,
    axisSettings: {
      xAxisKey: 'timestamp',
      yAxisKey: 'pressurizeTime',
      yAxisKey2: 'idealPressurizeTime',
      xAxisLabel: 'Time',
      yAxisLabel: 'Pressurisation Time',
      yAxisDomainPadding: 200,
      yDomainKeysToValuesToIgnore: ['batchNumber', 'cycleTime']
    },
    margins: {
      top: 30,
      right: 70,
      bottom: 30,
      left: 85
    },
    chartClass: 'pressurization-chart',
    dataColor1: COLORMAP['pressurizeTime'],
    dataColor2: COLORMAP['idealPressurizeTime'],
    verticalLineMessage: errorMessage,
    tooltipSettings: {
      width: 220,
      height: 95
    }
  };

  const updateHistory = {
    updateZoomHistory: setState
  };

  useEffect(() => {
    if (history === undefined || index === undefined) return;
    const domain = {
      chartDomainRangeX: history && history[index] && history[index][0],
      chartDomainRangeY: history && history[index] && history[index][1]
    };

    if (!chart) {
      chartArea.current &&
        setChart(new DotsChart(chartArea.current, { ...settings }, { ...updateHistory }));
    } else {
      chart.update(data, data2, { ...domain }, { ...updateHistory });
    }
  }, [chart, chartWidth]);

  useEffect(() => {
    if (history === undefined || index === undefined) return;
    const domain = {
      chartDomainRangeX: history && history[index] && history[index][0],
      chartDomainRangeY: history && history[index] && history[index][1]
    };

    chart && chart.updateOnUndo(domain);
  }, [index]);

  useEffect(() => {
    width && setChartWidth(width);
  }, [width]);

  useEffect(() => {
    chartArea.current &&
      setChart(new DotsChart(chartArea.current, { ...settings }, { ...updateHistory }));
  }, [chartWidth]);

  return (
    <DoctsChartContainer>
      <div className={`chart-area dots-chart-${index}`} ref={chartArea}></div>
    </DoctsChartContainer>
  );
};
