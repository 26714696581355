import React, { ReactElement } from 'react';
import theme from 'themes';
import LeftChevron from 'components/HorizontalScrollButtons/LeftScrollButton';
import RightChevron from 'components/HorizontalScrollButtons/RightScrollButton';
import {
  CardContainer,
  CarouselContainer,
  CarouselWrapper,
  Dot,
  DotsContainer
} from './MultiCardCarousel.elements';

interface MultiCardCarouselProps {
  items?: ReactElement[];
  itemDisplay?: number;
}

const MultiCardCarousel = ({
  items = [],
  itemDisplay = 4
}: MultiCardCarouselProps): ReactElement => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleNext = () => {
    if (items.length > itemDisplay) {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + itemDisplay;
        return nextIndex < items.length ? nextIndex : prevIndex;
      });
    }
  };

  const handlePrev = () => {
    if (items.length > itemDisplay) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - itemDisplay, 0));
    }
  };

  const numDots = Math.ceil(items.length / itemDisplay);
  const activeDotIndex = Math.floor(currentIndex / itemDisplay);

  return (
    <CarouselContainer>
      <CarouselWrapper>
        {items.length > itemDisplay && (
          <LeftChevron
            color={theme.colors.primaryBlue5}
            className="fleet-chevron-left"
            handleScroll={handlePrev}
          />
        )}
        <CardContainer>
          {items?.slice(currentIndex, currentIndex + 4).map((item) => item)}
        </CardContainer>
        {items.length > itemDisplay && (
          <RightChevron
            color={theme.colors.primaryBlue5}
            className="fleet-chevron-right"
            handleScroll={handleNext}
          />
        )}
      </CarouselWrapper>
      <DotsContainer>
        {Array.from({ length: numDots }).map((_, index) => (
          <Dot key={index} active={index === activeDotIndex} />
        ))}
      </DotsContainer>
    </CarouselContainer>
  );
};

export default MultiCardCarousel;
