// 3rd party libs
import React, { SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import { useParams } from 'react-router';
import theme from 'themes';
import { MachineProgressType } from 'types/machine-management';

// Constants
import { JBTRoutes } from 'constants/routes';

// Components
import { Typography, Button, Breadcrumbs } from 'components';

// API
import { useGetOnboardingMachineByIdQuery } from 'api';
import {
  ButtonContainer,
  CardContainer,
  CardWrapper,
  Icon,
  PageContainer,
  Status
} from './reviewAndPublish.elements';
import PublishOptionModal from './PublishOptionModal';
import { IcoLightning } from 'icons/IcoLightning';
import CustomLoader from 'components/CustomLoader';
import { IcoCheckWithCircle } from 'icons/IcoCheck';
import { IcoUpload } from 'icons/IcoUpload';
import { IconPublished } from 'icons/IconPublished';
import { PublishStatusBg } from 'img/PublishStatusBg';
import {
  IcoDashboardDiagram,
  IcoDocument,
  IcoMaintenanceSchedule,
  IcoPartBom
} from 'icons/IcoDashboardDiagram';
import { Circle } from 'components/MachineManagementTable';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type StatusItem = {
  status: MachineProgressType | undefined;
  setterFn: React.Dispatch<SetStateAction<string>>;
};
const publishItem = [
  { name: 'Diagrams', icon: <IcoDashboardDiagram /> },
  { name: 'BOM Only', icon: <IcoPartBom /> },
  { name: 'Maintenance Schedule', icon: <IcoMaintenanceSchedule /> },
  { name: 'Machine Documents', icon: <IcoDocument /> }
];
const getBgColor = (status: string) => {
  switch (status) {
    case 'None':
      return theme.colors.mediumGrey3;
    case 'Uploading':
      return theme.colors.cyan;
    case 'Done':
    case 'Published':
      return theme.colors.green3;
    case 'InProgress':
      return theme.colors.warning;
    default:
      return theme.colors.warning;
  }
};
const makeIndicator = (data: string, theme: DefaultTheme) => {
  switch (data) {
    case MachineProgressType.NONE:
      return (
        <>
          <Circle />
          <Typography
            mb={0}
            color={theme.colors.mediumGrey3}
            size={'.85rem'}
            weight="medium"
            font="Roboto"
          >
            Not Started
          </Typography>
        </>
      );
    case MachineProgressType.UPLOADING:
      return (
        <>
          <IcoUpload width="20" />
          <Typography
            mb={0}
            color={theme.colors.cyan}
            size={'.85rem'}
            weight="medium"
            font="Roboto"
          >
            Uploading
          </Typography>
        </>
      );
    case MachineProgressType.INPROGRESS:
      return (
        <>
          <IcoLightning width="20" />
          <Typography
            mb={0}
            color={theme.colors.warning}
            size={'.85rem'}
            weight="medium"
            font="Roboto"
          >
            In Progress
          </Typography>
        </>
      );
    case MachineProgressType.DONE:
      return (
        <>
          {IcoCheckWithCircle({ color: theme.colors.green2 })}
          <Typography
            mb={0}
            color={theme.colors.green2}
            size={'.85rem'}
            weight="medium"
            font="Roboto"
          >
            Done
          </Typography>
        </>
      );
    case MachineProgressType.PUBLISHED:
      return (
        <>
          {IconPublished({ color: theme.colors.green2 })}
          <Typography
            mb={0}
            color={theme.colors.green2}
            size={'.85rem'}
            weight="medium"
            font="Roboto"
          >
            Published
          </Typography>
        </>
      );
    default:
      return (
        <>
          <Circle />
          <Typography
            mb={0}
            color={theme.colors.mediumGrey3}
            size={'.85rem'}
            weight="medium"
            font="Roboto"
          >
            Not Started
          </Typography>
        </>
      );
  }
};

const getTextContent = (type: string, t: TFunction<['fpns'], undefined>) => {
  switch (type) {
    case 'Diagrams':
      return t('publish_document_item', { item: 'Part Shop' });
    case 'Maintenance Schedule':
      return t('publish_document_item', { item: 'Maintenance Manager' });
    case 'Machine Documents':
      return t('publish_document_item', { item: 'Documentation Center' });
    case 'BOM Only':
      return t('publish_document_item', { item: 'Part Shop' });
    default:
      return '';
  }
};

export function setStatus(
  statusType: MachineProgressType,
  setterFunction: React.Dispatch<SetStateAction<string>>
): void {
  switch (statusType) {
    case MachineProgressType.DONE:
      setterFunction(MachineProgressType.DONE);
      break;
    case MachineProgressType.INPROGRESS:
      setterFunction(MachineProgressType.INPROGRESS);
      break;
    case MachineProgressType.NONE:
      setterFunction(MachineProgressType.NONE);
      break;
    case MachineProgressType.PUBLISHED:
      setterFunction(MachineProgressType.PUBLISHED);
      break;
    default:
      break;
  }
}

const ReviewAndPublishPage = (): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const { machineId } = useParams<{ machineId: string }>();
  const { data: machine, isLoading: machineIsLoading } = useGetOnboardingMachineByIdQuery({
    machineId
  });
  //onboardingPage
  // To Go Back:
  const goToBack = (machineId: string) => {
    window.location.replace(JBTRoutes.onboardingPage.replace(':machineId', machineId));
  };

  const [diagramsStatus, setDiagramsStatus] = useState<string>('Not Started');
  const [maintenanceScheduleStatus, setMaintenanceScheduleStatus] = useState<string>('Not Started');
  const [machineDocumentsStatus, setMachineDocumentsStatus] = useState<string>('Not Started');
  const [bomStatus, setBomStatus] = useState<string>('Not Started');
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  //Related to Review & Publish Button enable-disable
  const [currentReviewButtonStatus, setReviewButtonStatus] = useState<boolean>(true);

  useEffect(() => {
    if (
      diagramsStatus == MachineProgressType.DONE ||
      maintenanceScheduleStatus == MachineProgressType.DONE ||
      machineDocumentsStatus == MachineProgressType.DONE ||
      bomStatus == MachineProgressType.DONE
    ) {
      setReviewButtonStatus(false);
    } else {
      setReviewButtonStatus(true);
    }
  });

  useEffect(() => {
    function setStatus(
      statusType: MachineProgressType,
      setterFunction: React.Dispatch<SetStateAction<string>>
    ) {
      switch (statusType) {
        case MachineProgressType.DONE:
          setterFunction(MachineProgressType.DONE);
          break;
        case MachineProgressType.INPROGRESS:
          setterFunction(MachineProgressType.INPROGRESS);
          break;
        case MachineProgressType.NONE:
          setterFunction(MachineProgressType.NONE);
          break;
        case MachineProgressType.PUBLISHED:
          setterFunction(MachineProgressType.PUBLISHED);
          break;
        default:
          break;
      }
    }

    if (machine) {
      const status_diagrams = machine.diagrams;
      const bom_status = machine.bomList;
      const status_maintenance_schedule = machine.maintenanceSchedule;
      const status_machine_document = machine.documentLibrary;
      const statusList: StatusItem[] = [
        { status: status_diagrams, setterFn: setDiagramsStatus },
        { status: bom_status, setterFn: setBomStatus },
        { status: status_maintenance_schedule, setterFn: setMaintenanceScheduleStatus },
        { status: status_machine_document, setterFn: setMachineDocumentsStatus }
      ];

      statusList.forEach((statusItem) => {
        if (statusItem.status) setStatus(statusItem.status, statusItem.setterFn);
      });
    }
  }, [machine]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Machine Management', link: JBTRoutes.machineManagement },
          {
            label: machine?.description || 'Retrieving machine...',
            link: JBTRoutes.onboardingPage.replace(':machineId', machineId)
          },
          { label: 'Publish Machine' }
        ]}
      />
      <PageContainer>
        <Typography size={'1.25rem'} weight="semi-bold">
          {t('machine_onboarding_summary')}
        </Typography>
        <Typography
          size={'.85rem'}
          font="Roboto"
        >{`Please review the information that has been onboarded for this machine. If you would like to make an update "Go Back" to the previous screen. Pressing "Publish" will make the information loaded here viewable to the customer.`}</Typography>

        <CardContainer>
          {publishItem.map((item) => {
            const status: string =
              {
                Diagrams: diagramsStatus,
                'Maintenance Schedule': maintenanceScheduleStatus,
                'Machine Documents': machineDocumentsStatus,
                'BOM Only': bomStatus
              }[item.name] || '';
            return (
              <CardWrapper bg={PublishStatusBg(getBgColor(status))}>
                <Icon>{item.icon}</Icon>

                <Typography size={'1.25rem'} weight="semi-bold">
                  {item.name}
                </Typography>
                <Typography size={'.95rem'} font="Roboto" color={theme.colors.lightGrey8}>
                  {getTextContent(item.name, t)}
                </Typography>

                <Status>
                  {machineIsLoading ? <CustomLoader size="3rem" /> : makeIndicator(status, theme)}
                </Status>
              </CardWrapper>
            );
          })}
        </CardContainer>

        <ButtonContainer position="fixed">
          <Button
            variant="link"
            borderColor={theme.colors.primaryBlue5}
            borderRadius="3rem"
            width="8rem"
            onClick={() => {
              goToBack(machineId);
            }}
          >
            {t('back')}
          </Button>
          <Button
            variant="hover-blue"
            bgColor={theme.colors.primaryBlue5}
            borderRadius="3rem"
            width="8rem"
            onClick={() => setShowSelectionModal(true)}
            disabled={currentReviewButtonStatus}
          >
            {t('publish')}
          </Button>
        </ButtonContainer>
        {showSelectionModal && (
          <PublishOptionModal
            closeModal={() => setShowSelectionModal(false)}
            showSelectionModal={showSelectionModal}
            machineId={machineId}
            status={{
              diagramsStatus,
              maintenanceScheduleStatus,
              machineDocumentsStatus,
              bomStatus
            }}
            goBack={goToBack}
          />
        )}
      </PageContainer>
    </>
  );
};

export default ReviewAndPublishPage;
