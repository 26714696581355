import React from 'react';
import GearIcon from '../../../icons/nothing_to_show.png';

export const NoData = (): JSX.Element => {
  return (
    <div className="container_no_data">
      <img src={GearIcon} alt="No data" />
    </div>
  );
};
