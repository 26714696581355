import React, { useEffect, useMemo, useState } from 'react';
import { BuiltInSortingFn, CellContext } from '@tanstack/react-table';
import { NewBaseTable, Switch, WarningPrompt } from 'components';
import { IcoTrash } from 'icons/IcoTrash';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { BaseType } from 'types';
import { useTheme } from 'styled-components';
import { capitalizeFirst } from 'helpers';
import { useChangeAlertStateByIdMutation, useDeleteAlertMutation, useGetAlertsQuery } from 'api';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Dropdown } from './Dropdown';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ToastMsg } from 'common/components/Toast/Toast';
import { AlertsTableWrapper, LoaderWrapper, LoadingWrapper } from './AlertsTable.element';
import CustomLoader from 'components/CustomLoader';

interface ITrigger extends BaseType {
  type?: string;
  value?: number;
  units?: string;
}

interface TableColumnConfigs extends BaseType {
  id?: string;
  machineId?: string;
  name?: string;
  state?: boolean;
  priority?: string;
  message?: string;
  description?: string;
  frequencyValue?: number;
  frequencyUnits?: string;
  slidingWindowValue?: number;
  slidingWindowUnits?: string;
  trigger?: ITrigger;
  triggerUn?: string;
}

const AlertsTable = (): JSX.Element => {
  const { machineId } = useFleetMachineAccountData();
  const { t } = useTranslation(['mh']);
  const theme = useTheme();
  const [isViewConfirmDelete, setIsViewConfirmDelete] = useState(false);
  const [selectedDeleteId, isSelectedDeleteId] = useState<string | undefined>(undefined);
  const [deleteAlert] = useDeleteAlertMutation();
  const [changeAlertStateById] = useChangeAlertStateByIdMutation();
  const [isLoading, setIsLoading] = useState<{
    alertIds: string[];
    isLoading: boolean;
  }>({
    alertIds: [],
    isLoading: false
  });

  const onAlertToggleChanged = async (checked: boolean, alertId: string) => {
    setIsLoading({
      alertIds: [...isLoading.alertIds, alertId],
      isLoading: true
    });
    try {
      const response = await changeAlertStateById({
        alertId: alertId,
        newState: checked ? 'ENABLED' : 'DISABLED'
      }).unwrap();
      refetch();

      ToastMsg({
        message: `"${response.name}" is ${capitalizeFirst(
          response.state?.toLowerCase()
        )} Successfully`,
        heading: 'Alert Updated',
        position: 'top-right',
        type: 'success'
      });
    } catch (e) {
      setIsLoading({
        alertIds: [],
        isLoading: false
      });
      ToastMsg({
        message: 'Failed to update',
        heading: 'Alert Update',
        position: 'top-right',
        type: 'error'
      });
      console.error(e);
    }
  };

  const {
    data: TableData,
    refetch,
    isLoading: isAlertsLoading,
    isFetching: isAlertsFetching,
    isError
  } = useGetAlertsQuery(
    machineId
      ? {
          machineId: machineId
        }
      : skipToken
  );

  const onHandleDelete = async () => {
    if (typeof selectedDeleteId === 'undefined') {
      return toast.warn(t('alert_not_found'));
    }
    try {
      setIsViewConfirmDelete(false);
      await deleteAlert({ alertId: selectedDeleteId }).unwrap();
      ToastMsg({
        message: t('success_alert_delete'),
        type: 'success',
        position: 'top-right'
      });
    } catch (error) {
      ToastMsg({
        message: t('error_alert_deleted'),
        position: 'top-right',
        type: 'error'
      });
      console.log(error);
      setIsViewConfirmDelete(false);
    }
  };

  const columns = [
    {
      id: 'state',
      header: t('state'),
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem'
            }}
          >
            {isLoading.alertIds.includes(`${cellValue.row.original.id}`) ? (
              isLoading.isLoading ? (
                <LoaderWrapper>
                  <CustomLoader size={'1.5rem'} thickness="0.3rem" />
                </LoaderWrapper>
              ) : (
                <Switch
                  defaultChecked={cellValue.getValue() === 'ENABLED'}
                  checked={cellValue.getValue() === 'ENABLED'}
                  onChange={(checked) =>
                    onAlertToggleChanged(checked, cellValue.row.original.id as string)
                  }
                  offColor={theme.colors.mediumGrey2}
                />
              )
            ) : (
              <Switch
                defaultChecked={cellValue.getValue() === 'ENABLED'}
                checked={cellValue.getValue() === 'ENABLED'}
                onChange={(checked) =>
                  onAlertToggleChanged(checked, cellValue.row.original.id as string)
                }
                offColor={theme.colors.mediumGrey2}
              />
            )}

            <span>{capitalizeFirst(cellValue.getValue().toLowerCase())}</span>
          </div>
        );
      },
      isAlwaysVisible: true,
      disableGlobalSearch: true
    },
    {
      id: 'name',
      header: t('alert_name2'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => (
        <NavLink
          to={`${cellValue.row.original.id}/update-alert`}
          style={{ color: theme.colors.primaryBlue5, textDecoration: 'none' }}
        >
          {cellValue.getValue()}
        </NavLink>
      ),
      customSort: 'text' as BuiltInSortingFn,
      isAlwaysVisible: true
    },
    {
      id: 'priority',
      header: t('priority'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        capitalizeFirst(cellValue.getValue().toLocaleLowerCase()),
      isAlwaysVisible: true,
      disableGlobalSearch: true
    },
    {
      id: 'frequencyValue',
      header: t('alert_frequency'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const units = capitalizeFirst(
          cellValue.row.original.frequencyUnits?.toLocaleLowerCase() as string
        );
        return cellValue.row.original.frequencyUnits && `${cellValue.getValue()} ${units}`;
      },
      isAlwaysVisible: true,
      disableGlobalSearch: true
    },
    {
      id: 'slidingWindowValue',
      header: t('alert_sliding_window'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        const units = capitalizeFirst(
          cellValue.row.original.slidingWindowUnits?.toLocaleLowerCase() as string
        );
        const value = cellValue.getValue() + ' ' + units;
        return value;
      },
      isAlwaysVisible: true,
      disableGlobalSearch: true
    },
    {
      id: 'trigger',
      header: 'trigger',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return capitalizeFirst(cellValue.getValue().toLocaleLowerCase());
      },
      isAlwaysVisible: true,
      customSort: 'alphanumeric' as BuiltInSortingFn,
      disableGlobalSearch: true
    },
    {
      id: 'action',
      header: 'action',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <div
            onClick={() => {
              setIsViewConfirmDelete(true);
              isSelectedDeleteId(cellValue.row.original.id);
            }}
            style={{
              cursor: 'pointer'
            }}
          >
            <IcoTrash width="30" />
          </div>
        );
      },
      isAlwaysVisible: true,
      disableGlobalSearch: true
    }
  ];

  const convertingPayload = useMemo(() => {
    if (TableData && TableData?.length !== 0)
      return TableData?.map((data) => {
        return {
          ...data,
          trigger: `${data.trigger.type}  - ${data.trigger.value}`
        };
      });

    return [];
  }, [TableData]);

  const filteredColumnWithIsSelected = columns.filter((column) => column.isSelected !== false);

  const defaultSortState = {
    id: 'name',
    desc: false
  };

  useEffect(() => {
    if (!isAlertsFetching) {
      setIsLoading({ alertIds: [], isLoading: true });
    }
  }, [isAlertsFetching]);

  if (isAlertsLoading) {
    return (
      <LoadingWrapper>
        <CustomLoader size={'4rem'} thickness="0.3rem" />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <div>
        <div>
          {TableData && !isError && (
            <AlertsTableWrapper>
              <NewBaseTable
                newTableData={convertingPayload}
                columnConfigs={filteredColumnWithIsSelected}
                sortState={defaultSortState}
                customClassName="threshold-alerts-table"
                isShowColumnOptions
                isShowGlobalSearch
                textAlign="left"
                tdMarginLeft="0.625rem"
                shadow
                tableHeadBtn={<Dropdown />}
              />
            </AlertsTableWrapper>
          )}
        </div>
        <WarningPrompt
          helperText={t('alert_delete_confirm_message')}
          isConfirmPrompt
          isVisible={isViewConfirmDelete}
          onCancelCallback={() => setIsViewConfirmDelete(false)}
          onConfirmCallback={() => onHandleDelete()}
          title={t('delete_alert_confirm_title')}
        />
      </div>
    </>
  );
};

export default AlertsTable;
