import styled from 'styled-components';

// Styling
export const ViewContainer = styled.div`
  gap: 1em;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-height: 100vh;

  .product-row {
    grid-column: span 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: inherit;
  }

  .charts-over-time-row {
    margin-top: 1em;
  }

  .machine-overview-widget {
    grid-column: span 2;

    img.full {
      width: 100%;
      height: auto;
      max-height: 220px;
    }
  }

  .belt-speed-widget,
  .loading-widget {
    grid-column: 1 / span 1;
  }

  .belt-speed-widget {
    grid-row: 2;
  }

  .loading-widget {
    grid-row: 3;
  }

  .progress-tiles-widget {
    grid-column: 2 / span 3;
    grid-row: 2 / span 2;
  }

  .charts-over-time-row {
    grid-column: span 4;
  }

  .machine-modes-graph-widget {
    grid-column: span 4;
  }
`;
