// 3rd party libraries
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'api';

import { API_UNUSED_DATA_CACHE_SECONDS } from 'constants/api';

import { UseAlarmsForIntensifierApiDataProps } from './useAlarmsForIntensifierApi';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';

type BaseType = Record<string, unknown>;

interface BaseRequestParams {
  machineId: string;
  startTime: string;
  endTime: string;
}

export interface ApiReturnProps extends BaseType {
  isLoading?: boolean;
  hasError?: string;
  error?: Record<string, unknown>;
  hasMessage?: string;
  data?: Record<string, unknown>[];
}

// Base query
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState }) => {
    // TODO: why can't we use a selector here?
    const token = (getState() as RootState).tokens?.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const iopsApi = createApi({
  reducerPath: 'iopsApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: API_UNUSED_DATA_CACHE_SECONDS,
  tagTypes: [],
  endpoints: (builder) => ({
    // used for alarms for intensifiers table in the intensifier performance tab
    getAlarmsForIntensifiers: builder.query<UseAlarmsForIntensifierApiDataProps, BaseRequestParams>(
      {
        query: ({ startTime, endTime, machineId }) =>
          `/mh/v1/avure/machine-health/${machineId}/alarms-for-intensifiers?start_datetime=${startTime}&end_datetime=${endTime}`
      }
    ),
    // used for alarms for intensifiers type ASD table in the intensifier performance tab
    getAlarmsForIntensifiersAsd: builder.query<BaseType[], BaseRequestParams>({
      query: ({ startTime, endTime, machineId }) =>
        `/mh/v1/avure/machine-health/${machineId}/alarms-for-asd?start_datetime=${startTime}&end_datetime=${endTime}`
    }),
    // used for skid utilization line charts in the intensifier performance tab
    getSkidUtilizationKpi: builder.query<BaseType[], BaseRequestParams>({
      query: ({ machineId, startTime, endTime }) =>
        `/mh/v1/avure/machine-health/${machineId}/skid-utilization-kpi?start_datetime=${startTime} 00%3A00%3A00&end_datetime=${endTime} 00%3A00%3A00`
    })
  })
});

export const {
  useGetAlarmsForIntensifiersQuery,
  useGetSkidUtilizationKpiQuery,
  useGetAlarmsForIntensifiersAsdQuery
} = iopsApi;
