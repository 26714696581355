import React from 'react';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { getData, parseAlarmData } from './utils';
import { MemoSkidStrokeCountsCharts } from './SkidStrokeCountsChart';
import { MiddleProp } from './types';

export const ProvidedPage = (): JSX.Element => {
  const { timeZone } = useFleetMachineAccountData();

  if (!timeZone) return <></>;

  const { allData, isLoading, hasError } = getData();

  if (isLoading) return <>Loading...</>;
  if (!allData) return <></>;

  const { skidsData, alertData } = allData;
  const { skids } = skidsData;

  //skidAlarms need this for right side of the widget with a list of alarms broken down by a skid and an intensifier
  //skidAlarmsByKeyTimestamp  broken down by skid, each skid contains an array of alarms
  const { skidAlarms, skidAlarmsByKeyTimestamp } = parseAlarmData(alertData, timeZone);

  const data = { skids, skidAlarms, skidAlarmsByKeyTimestamp };

  return <Middle data={data} isLoading={isLoading} hasError={hasError} />;
};

// Middle grabs the data and passes it to memoized function
export const Middle = ({ data, isLoading, hasError }: MiddleProp): JSX.Element => {
  return <MemoSkidStrokeCountsCharts data={data} isLoading={isLoading} hasError={hasError} />;
};
