import React from 'react';
import type { FC, SVGProps } from 'react';

const IcoGroupIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_5459_367)">
      <path
        d="M2 5.33333C2 5.68696 2.14048 6.02609 2.39052 6.27614C2.64057 6.52619 2.97971 6.66667 3.33333 6.66667C3.68696 6.66667 4.02609 6.52619 4.27614 6.27614C4.52619 6.02609 4.66667 5.68696 4.66667 5.33333C4.66667 4.97971 4.52619 4.64057 4.27614 4.39052C4.02609 4.14048 3.68696 4 3.33333 4C2.97971 4 2.64057 4.14048 2.39052 4.39052C2.14048 4.64057 2 4.97971 2 5.33333Z"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 6.66699V8.66699C3.33301 8.8438 3.40325 9.01337 3.52827 9.1384C3.65329 9.26342 3.82286 9.33366 3.99967 9.33366H5.99967"
        stroke="#0076CC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 9.33301H8.66667" stroke="#0076CC" strokeLinecap="round" strokeLinejoin="round" />
      <mask id="path-4-inside-1_5459_367" fill="white">
        <rect x="10" y="7.2998" width="4" height="4" rx="0.5" />
      </mask>
      <rect
        x="10"
        y="7.2998"
        width="4"
        height="4"
        rx="0.5"
        stroke="#0076CC"
        strokeWidth="2"
        mask="url(#path-4-inside-1_5459_367)"
      />
    </g>
    <defs>
      <clipPath id="clip0_5459_367">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IcoGroupIcon;
