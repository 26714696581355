import React from 'react';
import { PressurizationDeltaChart } from 'pages/FleetMachineDetail/MachineHealth/components/PressurizationDelta/PressurizationDeltaChart';
import { PressureDeltaData, WidgetWrapperProps } from './types';
import { WidgetUi } from 'components';
import { PressurizationContainer } from './elements';
import { isEqual } from 'lodash';

export const Chart = ({ data, isLoading, hasError }: WidgetWrapperProps): JSX.Element => {
  const widgetSettings = {
    className: 'pressurization-delta-widgetui',
    isLoading,
    hasError: hasError ? 'Error loading data' : undefined,
    Main: <PressurizationDeltaChart data={data} />
  };

  return (
    <PressurizationContainer gridArea={'chart'}>
      <WidgetUi {...widgetSettings} />
    </PressurizationContainer>
  );
};

const customComparator = (
  prevProps: { data: { chartData: PressureDeltaData[] } },
  nextProps: { data: { chartData: PressureDeltaData[] } }
) => {
  const isTrue = isEqual(prevProps.data.chartData, nextProps.data.chartData);
  return isTrue;
};

export const MemoizedChart = React.memo(Chart, customComparator);
