import PermissionWrapper from 'components/PermissionWrapper';
import { IcoPlus } from 'icons/IconPlus';
import { IconTextBtn } from 'components/RibbonComponent';
import React from 'react';
import { UserScopes } from 'types';
import { PermissionScopeName } from 'types/user-management';
type Props = {
  handleServiceModal?: (value: boolean) => void;
};
const CreateServiceBtn = ({ handleServiceModal }: Props): JSX.Element => {
  return (
    <PermissionWrapper page={PermissionScopeName.MAINTENANCE_MANAGER} scope={UserScopes.Write}>
      <IconTextBtn onClick={() => handleServiceModal && handleServiceModal(true)}>
        <span style={{ marginTop: '.8rem' }}>
          <IcoPlus width="24" />
        </span>
        Create Service
      </IconTextBtn>
    </PermissionWrapper>
  );
};

export default CreateServiceBtn;
