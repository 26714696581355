import { useArchiveMachineMutation } from 'api';
import { ToastMsg } from 'common/components/Toast/Toast';
import { Button, Checkbox } from 'components';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'themes';
const options = [
  { label: 'Diagrams', selected: false, key: 'publish_upload_diagram' },
  { label: 'Maintenance Schedule', selected: false, key: 'publish_status_maintenance' },
  { label: 'Gateway ID', selected: false, key: 'publish_status_gateway' },
  { label: 'Tag List', selected: false, key: 'publish_status_tags' }
];
const Container = styled.div`
  padding: 0 3rem;
  > p {
    margin-bottom: 0;
  }
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 50%;
`;
const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 75%;
`;
const BtnContainer = styled.div`
  display: flex;
  margin: 2rem 0;
  gap: 1rem;
  justify-content: flex-end;
  & button {
    width: 25%;
  }
`;
type Props = {
  handleClose: () => void;
  machineId: string;
  isOnboardingPage?: boolean;
};
const UnArchiveModal = ({ handleClose, machineId, isOnboardingPage }: Props): JSX.Element => {
  const history = useHistory();
  const [statusList, setStatusList] = useState(options);

  const [unArchive] = useArchiveMachineMutation();

  const handleCheckBox = (e: React.ChangeEvent) => {
    const { id } = e.target;
    const updatedOptions = statusList.map((option) =>
      `checkbox-${option.label}` === id ? { ...option, selected: !option.selected } : option
    );
    setStatusList(updatedOptions);
  };
  console.log(isOnboardingPage);

  const handleUnArchive = (type?: string) => {
    let status = {
      publish_upload_diagram: false,
      publish_status_maintenance: false,
      publish_status_gateway: false,
      publish_status_tags: false
    };
    if (type === 'not-restore') {
      status;
    } else {
      status = statusList.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.selected }), {
        publish_upload_diagram: false,
        publish_status_maintenance: false,
        publish_status_gateway: false,
        publish_status_tags: false
      });
    }
    unArchive({
      machine_id: machineId,
      machine_archive: true,
      status: status
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((res: any) => {
      handleClose();
      if (res?.error) {
        return ToastMsg({
          heading: 'Failed',
          message: 'Machine is not Unarchive, Please try again.',
          type: 'error'
        });
      } else {
        if (isOnboardingPage) {
          history.push({ pathname: '', search: `isArchived=false` });
        }
        return ToastMsg({
          heading: 'Successful',
          message: 'Machine Unarchive successfully'
        });
      }
    });
  };

  return (
    <Container>
      <p>Do you want to restore all the previous data associated with the machine?</p>
      <p>Select the pillars you wish to restore the data:</p>
      <OptionContainer>
        {statusList.map((status) => {
          return (
            <Option>
              <Checkbox onChange={handleCheckBox} id={status.label} checked={status.selected} />{' '}
              {status.label}
            </Option>
          );
        })}
      </OptionContainer>

      <BtnContainer>
        <Button
          borderRadius="3rem"
          onClick={handleClose}
          borderColor={theme.colors.primaryBlue5}
          bgColor="transparent"
          variant="link"
        >
          Cancel
        </Button>
        <Button
          borderRadius="3rem"
          onClick={() => handleUnArchive('not-restore')}
          borderColor={theme.colors.primaryBlue5}
          bgColor="transparent"
          variant="link"
        >
          Do not Restore
        </Button>
        <Button
          borderRadius="3rem"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.white}
          disabled={!statusList.find((item) => item.selected)}
          variant="hover-blue"
          onClick={() => handleUnArchive()}
        >
          Restore
        </Button>
      </BtnContainer>
    </Container>
  );
};

export default UnArchiveModal;
