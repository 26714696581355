// 3rd party libs
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { UserFormElement, UserManagementTableType, UserTypes } from 'types/user-management';
import styled, { useTheme } from 'styled-components';

// Components

import { Button, Flyout, FlyoutHeader, Modal, Typography } from 'components';
import AlertIcon from 'components/WarningPrompt/AlertIcon';
import UserForm, {
  FormSection,
  InputContainer,
  StyledInputLabel
} from 'pages/UserManagementTwo/components/User/AddEditUser/UserForm';
import AsyncSelect from 'react-select/async';

// Types
import { Id, ModalSize } from 'types';
import {
  useCreateUserMutation,
  useGetUserManagementV2Query,
  useLazyGetUserManagementV2Query
} from 'api';
import { PAGE_LENGTH } from 'constants/search';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import { ActionMeta, MultiValue } from 'react-select';
import { toSnakeCase } from 'helpers';
import { ToastMsg } from 'common/components/Toast/Toast';
import { GroupHeaderPropt } from '../../Group/AddEditGroup/GroupInfo';
import {
  ClearValues,
  DropdownIndicator,
  Option
} from 'pages/ProteinMachine/MachineConfig/Common/Alerts/DropdownWithCheckboxes';
import { useTranslation } from 'react-i18next';
import Collapse from 'rc-collapse';
import {
  expandIcon,
  MenuPanel
} from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';
import { v4 as uuidv4 } from 'uuid';
import { AddPlusIcon } from 'icons/AddPlusIcon';
import { SectionRule } from '../../Group/AddEditGroup/GroupInfo';
import { IcoTrash } from 'icons/IcoTrash';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
`;
const UserFormContainer = styled.div`
  max-height: 45rem;
  overflow-y: scroll;
  padding: 1rem;
  height: 100%;
  position: relative;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 94%;
`;
const BtnContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
const StyledModalHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.25rem;
  padding: 1.25rem 1.5rem;
  width: 100%;
`;
const StyledButtonRow = styled.div`
  align-item: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding-top: 1.25rem;
`;
const StyledTitle = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0rem;
  line-height: 1.3125rem;
`;
const StyledContentContainer = styled.div`
  padding: 0 1.5rem 1.25rem;
  width: 100%;
`;
const AddAnotheuserContainer = styled.div`
  display: flex;
  margin-top: 1.438rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
`;
const AddAnotheuser = styled.div`
  color: ${({ theme }) => theme.colors.text.lightBlack};
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.75rem;
`;
const HeaderWrapper = styled.div`
  display: flex;
  margin-left: 1.25rem;
`;
const ChildHeader = styled.div`
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.lightBlack};
`;
const HeaderIcon = styled.div`
  margin-left: auto;
  cursor: pointer;
`;
// Types
interface Props {
  tableType: UserManagementTableType;
  tableItemId?: Id;
  open: boolean;
  setIsOpenModal: (isOpenModal?: boolean) => void;
  selectedItem?: UserTypes;
  setSelectedItem?: React.Dispatch<React.SetStateAction<UserTypes | undefined>>;
}
const defaultUserField = {
  email: '',
  firstName: '',
  lastName: '',
  textAlert: false,
  emailAlert: false,
  phone: '',
  groups: []
};
const UserManagementModal = ({
  tableType,
  tableItemId,
  open,
  setIsOpenModal,
  selectedItem = defaultUserField
}: Props): ReactElement => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const [isViewConfirmCancel, setIsViewConfirmCancel] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const selectedGroups =
    selectedItem?.groups?.map((group) => ({ value: group.id, label: group.name || '' })) || [];

  const [multiValue, setMultiValue] = useState<DropDownItem[]>(selectedGroups);

  const [createUser] = useCreateUserMutation();

  const [getGroupList] = useLazyGetUserManagementV2Query();
  const { data: groups } = useGetUserManagementV2Query({
    type: 'group',
    limit: 50,
    offset: 0
  });
  const getUserPanelKeys = (): string[] => {
    const keys: string[] = [];
    newUsers.forEach((panel) => {
      keys.push(panel.id as string);
    });
    return keys;
  };
  //const [userFormField, setUserFormField] = useState<UserTypes>(selectedItem);
  const [newUsers, setNewUsers] = useState<UserFormElement[]>([
    { user: defaultUserField, validationState: false, id: uuidv4() }
  ]);
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<{ id: string }[]>([]);
  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>(getUserPanelKeys());
  const groupList = useMemo(
    () => groups?.items.map((group) => ({ label: group.name, value: group.id })),
    [groups]
  );

  useEffect(() => {
    validateNoneActiveForms();
  }, [newUsers]);

  const handleInputOnChange = (e: MultiValue<DropDownItem>, option: ActionMeta<DropDownItem>) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    setMultiValue([...e]);
    const selectedGroupsIds = e.map((group) => ({ id: String(group.value) }));
    setSelectedGroupsIds([...selectedGroupsIds]);
    //setUserFormField({ ...userFormField, groups: selectedGroups });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setNewUsers([{ user: defaultUserField, validationState: false, id: uuidv4() }]);
    setIsValid(false);
  };
  const loadOptions = async (inputValue: string) => {
    const groups = await getGroupList({
      type: 'group',
      nameSearchQuery: inputValue.trim(),
      limit: PAGE_LENGTH.SMALL,
      offset: 0
    });
    const groupList = groups.data?.items.map((group) => ({ label: group.name, value: group.id }));
    return Promise.resolve(groupList || []);
  };
  const massageUserData = (): UserTypes[] => {
    const data: UserTypes[] = [];
    for (const user of newUsers) {
      const u = toSnakeCase(user.user);
      u.groups = selectedGroupsIds;
      data.push(u);
    }
    return data;
  };
  const handleUserSave = () => {
    const params = massageUserData();
    createUser(params)
      .unwrap()
      .then(() => {
        ToastMsg({
          heading: 'Success',
          message: tableItemId ? 'Data updated successfully' : 'A user has been created.',
          type: 'success',
          position: 'top-center'
        });
        setIsOpenModal();
        setIsValid(false);
        setNewUsers([{ user: defaultUserField, validationState: false, id: uuidv4() }]);
      });
  };

  const handleFormChange = (data: UserTypes, isValidForm?: boolean, id?: string) => {
    newUsers.forEach((form, index) => {
      if (id && form.id === id) {
        newUsers[index] = { ...form, user: data, validationState: isValidForm as boolean };
      }
    });
    setNewUsers([...newUsers]);
  };
  const validateNoneActiveForms = (): void => {
    const hasInvalidForm = newUsers.some((user) => !user.validationState);
    setIsValid(!hasInvalidForm);
  };
  const onCloseVerifyData = () => {
    setNewUsers([{ user: defaultUserField, validationState: false, id: uuidv4() }]);
    setIsOpenModal(false);
  };

  const flyoutTitle = useMemo(() => {
    const tableTypeName = tableType === UserManagementTableType.USER ? 'user' : 'Group';
    return tableItemId ? `Edit ${tableTypeName}` : `Add ${tableTypeName}`;
  }, [tableType, tableItemId]);

  const helperText = `Are you sure you want to go back to users? You have unsaved changes.`;
  const title = 'Unsaved Changes';
  const addAnotherUser = (): void => {
    setNewUsers((prev) => [...prev, { user: defaultUserField, validationState: {}, id: uuidv4() }]);
  };
  const deleteNewUser = (index: number): void => {
    newUsers.splice(index, 1);
    setNewUsers([...newUsers]);
  };
  return (
    <>
      <Modal
        title={
          <StyledModalHeaderContainer>
            <AlertIcon />
            <StyledTitle>{title}</StyledTitle>
          </StyledModalHeaderContainer>
        }
        allowContentScroll
        onClose={onCloseVerifyData}
        showCloseButton={false}
        size={ModalSize.XSMALL}
        visible={isViewConfirmCancel}
      >
        <StyledContentContainer>
          <Typography color={theme.colors.mediumGrey3} variant="stepheading">
            {helperText}
          </Typography>
          <StyledButtonRow>
            <Button
              onClick={() => {
                setIsViewConfirmCancel(false);
              }}
              width="auto"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsOpenModal(false);
                setIsViewConfirmCancel(false);
              }}
              variant="danger"
              width="auto"
            >
              Yes
            </Button>
          </StyledButtonRow>
        </StyledContentContainer>
      </Modal>
      <Flyout width="28.125rem" visible={open} onClose={onCloseVerifyData}>
        <FlyoutHeader
          heading={flyoutTitle}
          onClose={onCloseVerifyData}
          bgColor={theme.colors.white}
        />
        <Container tabIndex={0}>
          <UserFormContainer>
            <GroupHeaderPropt>{t('by_adding_a_new_user')}</GroupHeaderPropt>
            {newUsers && newUsers.length > 0 && (
              <>
                <Collapse
                  activeKey={activePanelKeys}
                  onChange={(key: React.Key | React.Key[]) => {
                    setActivePanelKeys(Array.isArray(key) ? key : [key]);
                  }}
                  destroyInactivePanel={true}
                  expandIcon={expandIcon}
                  accordion={true}
                >
                  {newUsers.map((panel, index) => {
                    const header = (
                      <HeaderWrapper>
                        <ChildHeader>User &nbsp; {index + 1}</ChildHeader>
                        {newUsers.length > 1 && (
                          <HeaderIcon
                            onClick={() => {
                              deleteNewUser(index);
                            }}
                            title="Delete User"
                          >
                            <IcoTrash width={'2rem'} />
                          </HeaderIcon>
                        )}
                      </HeaderWrapper>
                    );
                    return (
                      <MenuPanel
                        showArrow={true}
                        collapsible={'header'}
                        key={panel.id}
                        header={header}
                        mb={0}
                        forceRender={true}
                      >
                        {
                          <UserForm
                            isUserPage
                            handleFormChange={handleFormChange}
                            userData={newUsers[index]}
                          />
                        }
                      </MenuPanel>
                    );
                  })}
                </Collapse>
              </>
            )}
            <FormSection>
              <AddAnotheuserContainer onClick={addAnotherUser}>
                <AddPlusIcon /> <AddAnotheuser>Add another User</AddAnotheuser>
              </AddAnotheuserContainer>
              <SectionRule></SectionRule>
            </FormSection>
            <FormSection margin={'1.5rem 0 0'}>
              <InputContainer>
                <StyledInputLabel mandatory={true}>Group Selection</StyledInputLabel>
                <AsyncSelect
                  className="groupSelect"
                  placeholder="Select Group(s)"
                  value={multiValue}
                  onChange={(e, opt) => {
                    handleInputOnChange(e, opt);
                  }}
                  isMulti
                  isSearchable
                  loadOptions={loadOptions}
                  defaultOptions={groupList}
                  components={{
                    Option,
                    DropdownIndicator
                  }}
                  styles={{
                    clearIndicator: ClearValues,
                    multiValue: (styles) => {
                      return {
                        ...styles,
                        backgroundColor: 'white',
                        borderRadius: '1.25rem',
                        border: '0.063rem solid  #0076CC',
                        padding: '0.25rem 0.5rem 0.25rem 0.5rem;'
                      };
                    },
                    multiValueLabel: (styles) => ({
                      ...styles,
                      fontWeight: '400'
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      ':hover': {
                        cursor: 'pointer'
                      }
                    })
                  }}
                />
              </InputContainer>
            </FormSection>
          </UserFormContainer>
          <Footer>
            <BtnContainer>
              <Button
                variant="link"
                onClick={onClose}
                borderRadius="3rem"
                borderColor={theme.colors.primaryBlue5}
                width="6.688rem"
              >
                Cancel
              </Button>

              <Button
                disabled={!(isValid && multiValue.length)}
                variant="hover-blue"
                borderRadius="3rem"
                bgColor={theme.colors.primaryBlue5}
                color={theme.colors.lightGrey3}
                onClick={handleUserSave}
                width="6.688rem"
              >
                Save
              </Button>
            </BtnContainer>
          </Footer>
        </Container>
      </Flyout>
    </>
  );
};

export default UserManagementModal;
