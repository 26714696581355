/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosProgressEvent } from 'axios';
import { CustomFile } from 'types/machine-management';

/**
 * Uploads a file to Azure Blob Storage using a pre-signed URL.
 *
 * @param {File} file - The file to upload.
 * @param {string} presignedUrl - The pre-signed URL for uploading.
 * @param {function} onUploadProgress - The callback function to track upload progress.
 * @returns {Promise} - A promise that resolves when the upload is complete.
 */
const uploadFileToBlob = async (
  file: CustomFile,
  presignedUrl: string,
  onUploadProgress: (data: AxiosProgressEvent) => void
): Promise<any> => {
  try {
    if (!presignedUrl) {
      throw new Error('Presigned URL is undefined');
    }
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-ms-blob-type': 'BlockBlob'
      },
      onUploadProgress
    };

    await axios.put(presignedUrl, file, options);
  } catch (error) {
    console.error('Error uploading file', error);
    throw error;
  }
};

export default uploadFileToBlob;
