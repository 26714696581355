import React from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';
import theme from 'themes';

type OptionType = { value: string; label: string };
type DropdownProps = {
  variant?: string;
  options?: OptionType[];
  defaultValue?: OptionType;
  handleChange: (selectedOption: OptionType) => void;
};
const Dropdown = (props: DropdownProps): JSX.Element => {
  const { options, defaultValue, handleChange } = props;

  const style: StylesConfig<unknown, boolean> = {
    indicatorsContainer: (base) => ({
      ...base,
      display: 'flex',
      margin: '.1rem',
      div: {
        padding: 0
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    placeholder: (base) => ({
      ...base
    }),
    option: (base) => ({
      ...base,
      margin: '0'
    }),
    control: (base) => ({
      ...base,
      borderColor: 'transparent',
      boxShadow: 'transparent',
      borderBottom: `1px solid ${theme.colors.lightBlack}`,
      borderRadius: 0,
      fontSize: '14px',
      minHeight: 'auto',
      ':active': {
        borderColor: 'transparent',
        borderBottom: `1px solid ${'#00000080'}`
      },
      ':hover': {
        borderColor: 'transparent',
        borderBottom: `1px solid ${'#00000080'}`
      }
    }),
    multiValue: (base) => ({
      ...base
    }),
    menu: (base) => ({
      ...base
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      ':hover': {
        cursor: 'pointer'
      }
    })
  };
  return (
    <div>
      <Select
        options={options}
        // value={"userRole"}
        onChange={(selectedOption) => {
          handleChange(selectedOption);
        }}
        isSearchable={false}
        // components={{IndicatorsContainer}}
        styles={style}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
};

export default Dropdown;
