import React, { useMemo } from 'react';
import { DateRangeProviderV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { useParams } from 'react-router-dom';
import { MachineAccountInfoQueryParams } from 'types/protein';
import { MachineModesWidget } from 'common/pages/fleetV2/machine/dsi/views/MachineHealth/components/MachineStateWidget/MachineModesWidget';
import { MachineModesAPIProvider } from 'common/pages/fleetV2/machine/dsi/views/MachineHealth/components/MachineStateWidget/provider/useMachineModesProvider';
import { DataManagement } from 'common/pages/fleetV2/machine/dsi/views/MachineHealth/components/MachineStateWidget/provider/useDataManagement';
import { useTimeZone } from 'providers/timeZoneProvider';
import { APITimerProvider } from 'common/pages/fleetV2/hooks/useAPITimer';
import { API_INTERVAL } from 'common/pages/fleetV2/machine/dsi/views/MachineHealth/components/MachineStateWidget/utils';

export const MachineModes = (): JSX.Element => {
  //let's wrap DSI in MachineInfoProvider, so we can stop using params?
  const { machineId } = useParams<MachineAccountInfoQueryParams>();
  const { timeZone } = useTimeZone();

  //Note version="v1" is refering to v1 original page structure/set up.
  //This param tells us where to pull timezone value from. It comes from diff providers based on the version

  const widgetContent = useMemo(() => {
    return (
      <DateRangeProviderV2 timeZone={timeZone || 'UTC'} subtractDaysCount={3}>
        <APITimerProvider version="v1" API_INTERVAL={API_INTERVAL}>
          <MachineModesAPIProvider timeZone={timeZone || 'UTC'} machineId={machineId}>
            <DataManagement version="v1">
              <MachineModesWidget version="v1" />
            </DataManagement>
          </MachineModesAPIProvider>
        </APITimerProvider>
      </DateRangeProviderV2>
    );
  }, [machineId, timeZone]);

  if (!timeZone) return <></>;

  return <>{widgetContent}</>;
};
