// 3rd party
import React, { createContext, ReactNode, useContext } from 'react';
import { processApiData } from 'common/helpers/processApiData';

//import { useGetAsepticMachineHealthChangeoverDetailsQuery } from 'api';
//import { useDateRange } from 'components';
//import { useParams } from 'react-router';

//import moment from 'moment';
import { calculateDataKeyTotals } from 'common/helpers/dataCounters';
import { useBuSettings } from 'common/pages/fleetV2/providers';

export interface AsepticChangeoverDetailsApiChartDataItemProps {
  label?: string;
  value?: number;
  color?: string;
  description?: string;
  [key: string]: unknown;
}

export interface AsepticChangeoverDetailsApiReturnProps {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  data?: Record<string, unknown>[];
  chartData?: AsepticChangeoverDetailsApiChartDataItemProps[];
  [key: string]: unknown;
}

const AsepticChangeoverDetailsApiContext = createContext<AsepticChangeoverDetailsApiReturnProps>({
  isLoading: true
});

// todo: add these to style guide
// these match up with the labels in the api data return, if the labels change
// these need to
export const stepCategoryColors: Record<string, string> = {
  Idle: '#7D96C3',
  Cleaning: '#694085',
  Steril: '#7EBCC3',
  Mainten: '#2C476F',
  'Wait time': 'gray'
};

export const stepCategoryConvertLabels: Record<string, string> = {
  Steril: 'Sterilization',
  Mainten: 'Maintenance',
  'Wait time': 'Wait Time'
};

export const useAsepticChangeoverDetailsApi = (): AsepticChangeoverDetailsApiReturnProps => {
  return useContext(AsepticChangeoverDetailsApiContext);
};

/*
const GetData = (): {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  data?: Record<string, unknown>[];
} => {
  const { startTime, endTime } = useDateRange().utcTZConvertedISO;
  
  const { machineId } = useParams<{ machineId?: string }>();

  if (!machineId) return { hasError: 'missing machine id' };

  const { data, isLoading, error } = useGetAsepticMachineHealthChangeoverDetailsQuery({
    machineId,
    start_datetime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
    end_datetime: moment(endTime).format('YYYY-MM-DD HH:mm:ss')
  });

  const dataCache = useMemo(
    () => ({ data, isLoading, hasError: error ? 'error getting data' : undefined }),
    [data, isLoading, error]
  );

  console.log({ data })

  return dataCache;
};
*/

// This provider is used on Production tab of machine health for DSI
export const AsepticChangeoverDetailsApiProvider = ({
  children,
  data,
  isLoading,
  hasError,
  hasMessage
}: {
  children?: ReactNode | ReactNode[];
  data?: Record<string, unknown>[];
  hasError?: string;
  isLoading?: boolean;
  hasMessage?: string;
}): JSX.Element => {
  //const { data, isLoading, hasError } = GetData();

  const { colors } = useBuSettings();
  const processedData = processApiData(data, {
    startTimeKey: 'startTime',
    endTimeKey: 'endTime',
    addDurationAsKey: 'duration'
  });

  // loop through api return and calculate totals for chart
  const categoryTotals = calculateDataKeyTotals(processedData || [], 'name', {
    valueKey: 'duration'
  });

  // convert data to chart data using demoColors above.  the key will match up with the label
  const chartData = Object.entries(categoryTotals).map(([label, item]) => {
    // for ts
    const tsitem = item as Record<string, unknown>;
    return {
      // the label will also be used as an id
      label,
      // the value is what determines the size of the slice
      value: Math.round((Number(tsitem.total) / 3600) * 2) / 2,
      // we'll send the color in the chart data
      color: colors?.[label.toLowerCase()],
      // send any other data there might be
      ...tsitem
    };
  });

  const value = {
    hasMessage,
    data,
    isLoading,
    hasError,
    chartData: chartData as AsepticChangeoverDetailsApiChartDataItemProps[],
    colors
  };

  return (
    <AsepticChangeoverDetailsApiContext.Provider value={value}>
      {children}
    </AsepticChangeoverDetailsApiContext.Provider>
  );
};
