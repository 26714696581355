import React, { ReactElement } from 'react';
import { generateIcon } from 'common/pages/fleetV2/machine/aseptic/views/MachineHealth/components/SingleSessionSteps/utils';
import { IcoClose } from 'icons/IcoClose';
import { CardContainer, CloseButton, Link } from './index.elements';
import theme, { themeColors } from 'themes';
import { useDateRange } from 'components';
import {
  useDeleteFavMachineMutation,
  useGetAccountInfoQuery,
  useGetMachineStatesCategoriesQuery
} from 'api';
import {
  StateProtein,
  mappedStateDSI,
  mappedStateProtein,
  mappedStatuses
} from 'components/MachineStatus/utils/BUsMachineStatusMapping';
import { DataRenderer } from 'components/machine-health';
import { MachineConnectionIcon } from 'components/MachineStatus/MachineStatus';
import { formatDate } from 'helpers';
import { useTranslation } from 'react-i18next';
import { differenceInMinutes } from 'date-fns';
import { MachineStateStatusIcon } from 'components/MachineStatus/MachineState';
import { ToastMsg } from 'common/components/Toast/Toast';
import { useHistory } from 'react-router-dom';
import { CloseButtonContainer } from './index.elements';
import { CardBodyContainer } from './index.elements';
import { CardFooterContainer } from './index.elements';

export const FavoriteCard = ({
  machineId,
  machineName,
  customerName
}: {
  machineId: string;
  machineName: string;
  customerName: string;
}): ReactElement => {
  const { endTime } = useDateRange().utcTZConvertedISO;
  const baseClass = 'machine_status';
  const history = useHistory();
  const connected = <p className={baseClass + ` connected`}>Connected</p>;
  const disconnected = <p className={baseClass + ` disconnected`}>Offline</p>;
  const { data: states, isLoading } = useGetMachineStatesCategoriesQuery(
    {
      machineId,
      startDatetime: endTime
    },
    { pollingInterval: 30000 }
  );
  const { data: accountInfo, isLoading: accountInfoIsLoading } = useGetAccountInfoQuery({
    machineId
  });

  const machineStatus =
    (states && states?.[states?.length - 1]?.name) ||
    (accountInfo && accountInfo.connectionStatus.watchdog);

  const lastConnected = accountInfo?.connectionStatus?.lastKnownConnected;
  const lastConnectedTime = lastConnected
    ? differenceInMinutes(new Date(), new Date(lastConnected))
    : null;

  const displayStatus =
    machineStatus &&
    mappedStatuses?.[machineStatus] &&
    lastConnectedTime !== null &&
    lastConnectedTime < 6
      ? mappedStatuses[machineStatus]
      : 'Offline';

  const machineState = states && states[states?.length - 1]?.name;

  const baseClassMachineState = 'state';

  const { t } = useTranslation(['mh']);
  const mappedProteinState: StateProtein = mappedStateProtein(t);

  const mappedState =
    (machineState && mappedStateDSI[machineState]) ||
    (machineState && mappedProteinState[machineState]);

  const state =
    machineState && mappedState && lastConnectedTime !== null && lastConnectedTime < 6
      ? mappedState
      : 'Unknown';

  const [deleteFavoriteMachines, deleteFavoritesMachineStatus] = useDeleteFavMachineMutation();

  const removeFavoriteOnClick = () => {
    deleteFavoriteMachines({ machineId })
      .unwrap()
      .then(() => {
        ToastMsg({
          message: 'Successfully removed as Favorite',
          theme: 'colored',
          type: 'success'
        });
      })
      .catch(() => {
        ToastMsg({
          message: 'Failed to remove as Favorite',
          theme: 'colored',
          type: 'warning'
        });
      });
  };

  const generateLastConnectedStatus = () => {
    if (displayStatus === 'Offline' && accountInfo) {
      if (accountInfo.connectionStatus && !accountInfo.connectionStatus.lastKnownConnected) {
        return `Not Available`;
      } else if (accountInfo.connectionStatus && accountInfo.connectionStatus.lastKnownConnected) {
        return `Offline since ${formatDate(
          accountInfo.connectionStatus.lastKnownConnected,
          'day-month-ordinal-time',
          undefined,
          undefined,
          true
        )}`;
      }
    } else {
      return `On track`;
    }
  };

  return (
    <CardContainer className="favorite-container">
      <CloseButtonContainer>
        <CloseButton
          disabled={deleteFavoritesMachineStatus.isLoading}
          onClick={removeFavoriteOnClick}
        >
          {IcoClose(theme.colors.primaryBlue5)}
        </CloseButton>
      </CloseButtonContainer>
      <DataRenderer isLoading={deleteFavoritesMachineStatus.isLoading}>
        <CardBodyContainer>
          <p
            style={{
              fontWeight: '600',
              fontSize: '0.875rem',
              margin: '0.5rem',
              textAlign: 'center'
            }}
          >
            {customerName}
          </p>
          <div style={{ display: 'flex', gap: '0.2rem' }}>
            <Link
              onClick={() => history.push(`/fleet/machine/${machineId}/machine-health/overview`)}
            >
              {machineName}
            </Link>
            {generateIcon('blue arrow')}
          </div>
          <DataRenderer isLoading={accountInfoIsLoading}>
            <p
              style={{
                fontWeight: '600',
                fontSize: '0.75rem',
                color: themeColors.lightGrey8,
                margin: '0.5rem'
              }}
            >
              {generateLastConnectedStatus()}
            </p>
          </DataRenderer>
        </CardBodyContainer>
        <CardFooterContainer>
          <div style={{ margin: 'auto 0', display: 'flex' }}>
            <DataRenderer isLoading={isLoading && accountInfoIsLoading}>
              <span className={baseClassMachineState + ` ${state.toLowerCase()}`}>{state}</span>
              <MachineStateStatusIcon state={state} />
            </DataRenderer>
          </div>
          <div style={{ display: 'flex', gap: '0.2rem' }}>
            <DataRenderer isLoading={isLoading && accountInfoIsLoading}>
              {displayStatus === 'Connected' ? connected : disconnected}
              <MachineConnectionIcon {...{ displayStatus }} />
            </DataRenderer>
          </div>
        </CardFooterContainer>
      </DataRenderer>
    </CardContainer>
  );
};

export default FavoriteCard;
