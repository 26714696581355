import { Button } from 'components';
import { BookmarkIcon } from 'icons/docsIcons';
import {
  BtnContainer,
  Card,
  CardContent,
  FileName
} from 'pages/Documentation/documentation.elements';
import React, { useEffect, useState } from 'react';
import { Documents } from 'types/machine-management';
import FilePreviewModal, {
  FileObj
} from 'pages/MachineManagement/UploadMachinePdf/FilePreviewModal';
import CopyTextComponent from '../CopyLink';
import {
  useAddBookmarkDocumentMutation,
  useLazyGetFileLinkQuery,
  useRemoveBookmarkDocumentMutation
} from 'api';
import { getFileIcon } from 'pages/MachineManagement/UploadMachinePdf/FileItem';
import CustomLoader from 'components/CustomLoader';
import { ToastMsg } from '../Toast/Toast';
import { IcoLink } from 'icons/IcoLink';
import { v4 as uuidv4 } from 'uuid';
import theme from 'themes';
import { ToolTipWrapper } from 'components/machine-health/configurator/WidgetTable/Dropdown';
import BookmarkLimitModal from '../BookmarkDocItem/BookmarkLimitModal';

type Props = {
  documentDetail: Documents;
  totalBookmark: number;
};
const DocumentCardComponent = ({ documentDetail, totalBookmark }: Props): JSX.Element => {
  const { userFileName, id, hasBookmark, documentCategory } = documentDetail;

  const [toolTipVisible, setTooltipVisible] = useState<string>('');
  const [selectedFilePreview, setSelectedFilePreview] = useState<FileObj | undefined>();

  const [isBookmark, setIsBookmark] = useState(hasBookmark);
  const [showLimitModal, setShowLimitModal] = useState(false);

  const [removeBookmark, { isLoading: removeBookmarkLoading }] =
    useRemoveBookmarkDocumentMutation();
  const [addBookmark, { isLoading: addBookmarkLoading }] = useAddBookmarkDocumentMutation();
  const [getFileLink, { isLoading, isFetching }] = useLazyGetFileLinkQuery();

  useEffect(() => {
    setTimeout(() => {
      setTooltipVisible('');
    }, 1500);
  }, [toolTipVisible]);

  const handlePreview = () => {
    setSelectedFilePreview({ id, name: userFileName });
  };

  const handleCopyText = async () => {
    try {
      const resp = await getFileLink({ document_id: id });
      const copyLink =
        window.location.origin +
        `/documentation/category?categoryId=${documentCategory}&sharedId=${resp?.data?.shareDocumentId}`;
      if (document.hasFocus()) {
        navigator.clipboard.writeText(copyLink || '');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddBookmark = async (doc_id: string) => {
    if (totalBookmark >= 10) {
      return setShowLimitModal(true);
    }
    const id = uuidv4();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp = (await addBookmark({ document_id: doc_id, id })) as any;
    if (resp.data) {
      setIsBookmark(true);
      ToastMsg({
        heading: 'Successful',
        message: 'Bookmark saved successfully',
        type: 'success',
        position: 'top-right'
      });
    } else {
      ToastMsg({
        heading: 'Error',
        message: resp.error.data.detail,
        type: 'error',
        position: 'top-right'
      });
    }
  };

  const handleRemoveBookmark = async (doc_id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = (await removeBookmark(doc_id)) as any;
    if (res.error) {
      ToastMsg({
        heading: 'Error',
        message: res.error.data.detail,
        type: 'error',
        position: 'top-right'
      });
    } else {
      setIsBookmark(false);
      ToastMsg({
        heading: 'Successful',
        message: 'Document is removed from bookmark',
        type: 'success',
        position: 'top-right'
      });
    }
  };

  return (
    <>
      <Card padding=".5rem" gridRows="auto 1fr auto">
        <BtnContainer>
          <Button marginLeft="0" variant="text">
            {removeBookmarkLoading || addBookmarkLoading ? (
              <CustomLoader thickness=".2rem" size="1.25rem" />
            ) : (
              <BookmarkIcon
                fill={hasBookmark || isBookmark ? theme.colors.primaryBlue5 : 'none'}
                onClick={
                  hasBookmark || isBookmark
                    ? () => handleRemoveBookmark(id || '')
                    : () => handleAddBookmark(id || '')
                }
                width="22"
              />
            )}
          </Button>
        </BtnContainer>
        <CardContent onClick={handlePreview}>
          <div>{getFileIcon(userFileName || '')}</div>
          <ToolTipWrapper offset="center" tooltipMessage={userFileName as string}>
            <FileName>
              {userFileName}
              {/* <div>{`${userFileName?.substring(0, 15)}...`}</div> */}
            </FileName>
          </ToolTipWrapper>
        </CardContent>
        <BtnContainer justifyContent="end">
          <CopyTextComponent
            isLoading={isLoading || isFetching}
            handleCopyText={handleCopyText}
            icon={<IcoLink width="20" />}
          />
        </BtnContainer>
      </Card>
      {selectedFilePreview && (
        <FilePreviewModal
          type="fps"
          file={selectedFilePreview}
          setSelectedFilePreview={setSelectedFilePreview}
        />
      )}
      {showLimitModal && (
        <BookmarkLimitModal
          showModal={showLimitModal}
          closeModal={() => setShowLimitModal(false)}
        />
      )}
    </>
  );
};

export default DocumentCardComponent;
