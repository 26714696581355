import React, { useState } from 'react';
import {
  alarmsColumnMapping,
  avureGetAlarmsHook
} from 'pages/FleetMachineDetail/default_settings/avure_customer_default_settings';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { WidgetUi } from 'common/components';
import { FilterscContainer } from './IntensifierAlarmsWidget.elements';
import { AlarmsLegend } from './IntensifiersAlarmsWidgetLegend';
import { Categories } from './Categories';
import { Table } from './Table';
import { isEqual } from 'lodash';

export interface ReturnedData {
  tableData?: Record<string, unknown>[];
  isLoading?: boolean;
  hasMessage?: string;
  hasError?: string;
  total_cycles?: number;
  total_alarm_count?: number;
}

export const ProvidedData = (): JSX.Element => {
  const { machineType } = useFleetMachineAccountData();
  if (!machineType) return <></>;

  //Makes an api call to correct endpoint depending on machine type
  const returnValue = avureGetAlarmsHook[machineType] as unknown as () => ReturnedData;
  const { tableData, isLoading, hasMessage, hasError, total_cycles, total_alarm_count } =
    returnValue();

  const tableSettings = {
    machineType,
    tableData,
    isLoading,
    hasMessage,
    hasError,
    total_cycles,
    total_alarm_count
  };

  return <AlarmsTable {...tableSettings} />;
};

interface IntensifierAlarmsWidgetProps extends ReturnedData {
  machineType: string;
}

export const IntensifierAlarmsWidget = ({
  machineType,
  tableData,
  isLoading,
  hasMessage,
  hasError,
  total_cycles,
  total_alarm_count
}: IntensifierAlarmsWidgetProps): JSX.Element => {
  //@ts-expect-error: error
  const columnMapping = alarmsColumnMapping[machineType];

  const allCat = tableData?.map((item) => item[columnMapping.scope]) || [];

  const cat = ['Show All', ...[...new Set(allCat)].filter((item) => item !== undefined)] || [];

  const [filter, setFilter] = useState<{ type: string[]; scope: string }>({
    type: [],
    scope: ''
  });

  // Update filter
  const handleAlarmFilter = (type: string, value: string[]) => {
    switch (type) {
      case 'type':
        setFilter?.({ ...filter, type: value });
        return;
      case 'scope':
        setFilter?.({ ...filter, scope: value.length ? value[0] : '' });
        return;
    }
  };

  const widgetSettings = {
    title: <>intensifier alarms </>,
    className: 'intensifier-alarms-widget',
    isLoading,
    hasMessage,
    hasError
  };

  const tableSettings = {
    tableData,
    filter,
    columnMapping,
    machineType,
    total_alarm_count,
    total_cycles
  };

  return (
    <WidgetUi {...widgetSettings}>
      <FilterscContainer className="row_filters">
        <AlarmsLegend {...{ selected: filter, handle: handleAlarmFilter }} />
        {cat.length > 1 && (
          <Categories {...{ scope: cat as [], selected: filter, handle: handleAlarmFilter }} />
        )}
      </FilterscContainer>
      <Table {...tableSettings} />
    </WidgetUi>
  );
};

const customComparator = (
  prevProps: IntensifierAlarmsWidgetProps,
  nextProps: IntensifierAlarmsWidgetProps
) => {
  const isTheSame = isEqual(prevProps.tableData, nextProps.tableData);
  return isTheSame;
};

export const AlarmsTable = React.memo(IntensifierAlarmsWidget, customComparator);
