import React from 'react';
import { useFleetMachineAccountData, MachinePressurizationProvider } from '../hooks';
import { FleetMachinePageView } from '../components';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PressurizationPerformanceView } from '../MachineHealth/PressurizedPerformance/PressurizedPerformanceView';

import { IntensifierPerformanceView } from '../MachineHealth/IntensifierPerformance/IntensifierPerformanceView';
import SubComponentPanel from './SubComponent';
import { AlarmsView } from '../MachineHealth/Alarms/AlarmsView';

export const MachineHealthView = (): JSX.Element => {
  const { machineId, curCustomer } = useFleetMachineAccountData();

  if (!machineId) return <></>;

  const match = useRouteMatch();

  return (
    <MachinePressurizationProvider>
      <FleetMachinePageView pageViewSlug="machine-health">
        <Switch>
          <Route exact path={`${match.path}/`}>
            {curCustomer?.isPascal ? (
              <Redirect to={`${match.url}/alarms`} />
            ) : (
              <Redirect to={`${match.url}/sub-components`} />
            )}
          </Route>
          <Route path={`${match.path}/alarms`}>
            <AlarmsView />
          </Route>
          <Route path={`${match.path}/sub-components`}>
            <SubComponentPanel />
          </Route>
          <Route path={`${match.path}/pressurize-performance`}>
            <PressurizationPerformanceView />
          </Route>
          <Route path={`${match.path}/intensifier-performance`}>
            <IntensifierPerformanceView />
          </Route>
        </Switch>
      </FleetMachinePageView>
    </MachinePressurizationProvider>
  );
};
