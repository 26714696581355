import React from 'react';

export const IcoGridView = ({
  color,
  width = '16'
}: {
  color?: string;
  width?: string;
}): JSX.Element => {
  color = color || '#0076CC';
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3773_13666)">
        <path
          d="M2.66602 3.33366C2.66602 3.15685 2.73625 2.98728 2.86128 2.86225C2.9863 2.73723 3.15587 2.66699 3.33268 2.66699H5.99935C6.17616 2.66699 6.34573 2.73723 6.47075 2.86225C6.59578 2.98728 6.66602 3.15685 6.66602 3.33366V6.00033C6.66602 6.17714 6.59578 6.34671 6.47075 6.47173C6.34573 6.59675 6.17616 6.66699 5.99935 6.66699H3.33268C3.15587 6.66699 2.9863 6.59675 2.86128 6.47173C2.73625 6.34671 2.66602 6.17714 2.66602 6.00033V3.33366Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33398 3.33366C9.33398 3.15685 9.40422 2.98728 9.52925 2.86225C9.65427 2.73723 9.82384 2.66699 10.0007 2.66699H12.6673C12.8441 2.66699 13.0137 2.73723 13.1387 2.86225C13.2637 2.98728 13.334 3.15685 13.334 3.33366V6.00033C13.334 6.17714 13.2637 6.34671 13.1387 6.47173C13.0137 6.59675 12.8441 6.66699 12.6673 6.66699H10.0007C9.82384 6.66699 9.65427 6.59675 9.52925 6.47173C9.40422 6.34671 9.33398 6.17714 9.33398 6.00033V3.33366Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66602 9.99967C2.66602 9.82286 2.73625 9.65329 2.86128 9.52827C2.9863 9.40325 3.15587 9.33301 3.33268 9.33301H5.99935C6.17616 9.33301 6.34573 9.40325 6.47075 9.52827C6.59578 9.65329 6.66602 9.82286 6.66602 9.99967V12.6663C6.66602 12.8432 6.59578 13.0127 6.47075 13.1377C6.34573 13.2628 6.17616 13.333 5.99935 13.333H3.33268C3.15587 13.333 2.9863 13.2628 2.86128 13.1377C2.73625 13.0127 2.66602 12.8432 2.66602 12.6663V9.99967Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33398 9.99967C9.33398 9.82286 9.40422 9.65329 9.52925 9.52827C9.65427 9.40325 9.82384 9.33301 10.0007 9.33301H12.6673C12.8441 9.33301 13.0137 9.40325 13.1387 9.52827C13.2637 9.65329 13.334 9.82286 13.334 9.99967V12.6663C13.334 12.8432 13.2637 13.0127 13.1387 13.1377C13.0137 13.2628 12.8441 13.333 12.6673 13.333H10.0007C9.82384 13.333 9.65427 13.2628 9.52925 13.1377C9.40422 13.0127 9.33398 12.8432 9.33398 12.6663V9.99967Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3773_13666">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
