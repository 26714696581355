// 3rd party libs
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { VictoryLabelProps } from 'victory';
import { useTranslation } from 'react-i18next';

// Components
import { ActionButton, GraphLegend, Loader, StateOverTimeChart, Typography } from 'components';
import { BarPeriod, Row, SpanPeriod } from 'components/StateOverTimeChart';

// Types
import { CleaningState, CleaningStepWithKPI } from 'types/protein';
import { ToolTipProps } from 'types/graph';
import { ZoomObjectTuples } from 'types';

// Helpers
import { formatTooltipDateMessage } from 'helpers';

// Providers
import { useTimeZone } from 'providers';
import { GoBackIcon } from 'icons/IconGoBack';
import { IcoChevron } from 'icons/IcoChevron';
interface Props {
  stepsData: CleaningStepWithKPI[];
  waitingStatesData?: CleaningState[];
  getColorById: (id: string) => string;
  dataIsGrouped?: boolean;
  selectedSteps?: string[];
  onSelectSteps?: (steps: string[]) => void;
  isLoading?: boolean;
  isFetching?: boolean;
}

interface IdNameMap {
  [id: string]: string;
}

const WAIT_TIME_COLOR = 'rgba(255, 175, 252, 0.2)';

// How many legends to show when not all legends are visible
const NUM_LEGENDS_CONTRACTED = 5;

const Container = styled.div`
  display: block;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey1};
  position: relative;
`;

const Legends = styled.div`
  flex: 0 0 11.5625rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 0.0625rem solid ${({ theme }) => theme.colors.lightGrey2};
`;

const LegendRow = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.938rem;
`;

const LabelComponent = styled.div`
  display: flex;
  gap: 0.2rem;
  & > div > span {
    font-size: 0.75rem;
  }
`;

const ToolTipTitle = styled((props) => <Typography {...props} />)`
  text-overflow: ellipsis;
  max-width: 11.5rem;
  white-space: nowrap;
  overflow: hidden;
`;

const KeyInline = styled.div`
  display: flex;
  gap: 0.5rem;
}`;

const DataZoom = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text.lightBlack};
  border-radius: 0.5rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  vertical-align: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.mediumBlue4};
  }
`;

export const ZoomContainer = styled.div`
  display: flex;
`;

export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;
const ArrowMenu = styled.div`
  cursor: pointer;
  svg > path {
    fill: #303e47;
  }
  position: relative;

  &:hover .dropdown-menu {
    display: block;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey1};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 10;
  max-height: 8.375rem;
  overflow-y: auto;
  display: none;
  min-width: max-content;
`;

const DropdownItem = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey1};
  }
`;

// Custom tooltip renderer
const CustomTooltip = (props: VictoryLabelProps) => {
  const { x, y, datum } = props;
  const { toolTipData, color } = datum as { toolTipData: ToolTipProps; color: string };
  const intialX = (x || 0) - 95;
  const initialY = (y || 0) - 40;
  const { timeZone } = useTimeZone();
  return (
    <g>
      <foreignObject x={intialX} y={initialY} width="200" height={'100%'}>
        <LabelComponent>
          <FontAwesomeIcon icon={faSquare} color={color} />
          <div>
            <ToolTipTitle mb={0} size="0.75rem" weight="bold">
              {toolTipData.title}
            </ToolTipTitle>
            <span>Start time</span>
            <Typography mb={0} size="0.75rem" weight="bold">
              {formatTooltipDateMessage(toolTipData.startTime, timeZone)}
            </Typography>
            <span>End time</span>
            <Typography mb="0.3rem" size="0.75rem" weight="bold">
              {formatTooltipDateMessage(toolTipData.endTime, timeZone)}
            </Typography>
          </div>
        </LabelComponent>
      </foreignObject>
    </g>
  );
};

const CleaningStepsChart = ({
  stepsData,
  waitingStatesData,
  getColorById,
  dataIsGrouped,
  selectedSteps,
  onSelectSteps,
  isLoading,
  isFetching
}: Props): JSX.Element => {
  const { t, i18n } = useTranslation(['mh']);
  const [expanded, setExpanded] = useState(false);
  const [waitTimeVisible, setWaitTimeVisible] = useState(true);

  // Not zoomed by default
  const [zoomedDomain, setZoomedDomain] = useState<ZoomObjectTuples | undefined>();
  // True - when an area is being selecting
  const [zooming, setZooming] = useState(false);
  // State to store the history of zoomed domains
  const [zoomedDomainHistory, setZoomedDomainHistory] = useState<ZoomObjectTuples[]>([]);
  // Color map to map step categories to specific color
  const stepCategoryColorMap = useTheme().colors.cleaningStepColors as { [key: string]: string };

  // Find a list of all unique step ids, and map them to their names
  const stepNameMap: IdNameMap = useMemo(() => {
    const map: IdNameMap = {};
    stepsData.forEach(({ id, name }) => (map[id] = name));
    return map;
  }, [stepsData]);

  // Create bar objects from the data
  const bars: BarPeriod[] = useMemo(() => {
    if (dataIsGrouped) {
      /**
       * Parsing step data that is grouped into categories.
       * Create a new row for each top level step category.
       * Each row then plots the substeps within that top level step category along it.
       * They take the color of the top level step category, not the sub step
       */
      return stepsData.reduce((acc, step) => {
        // Do not add this bar if it should be hidden (i.e. not selected in the legend)
        if (!selectedSteps?.length || selectedSteps.includes(step.id)) {
          const substepBars = step?.subSteps?.map((subStep) => {
            const startTime = new Date(subStep.startTime);
            const endTime = new Date(subStep.endTime);
            return {
              state: `${step.id}`,
              color: stepCategoryColorMap[step.id] || getColorById(step.id),
              startTime,
              endTime,
              toolTipData: {
                title: subStep.name,
                startTime,
                endTime
              }
            } as BarPeriod;
          });
          return [...acc, ...(substepBars ?? [])];
        } else {
          return acc;
        }
      }, [] as BarPeriod[]);
    } else {
      /**
       * Parsing step data is that is not grouped into categories.
       * Map over all the steps and create a bar for each one.
       */
      return stepsData.reduce((acc, step) => {
        // Do not add this bar if it should be hidden (i.e. not selected in the legend)
        if (!selectedSteps?.length || selectedSteps.includes(step.id)) {
          const startTime = new Date(step.startTime);
          const endTime = new Date(step.endTime);
          acc.push({
            state: `${step.id}`,
            color: getColorById(step.id),
            startTime,
            endTime,
            toolTipData: {
              title: step.name,
              startTime,
              endTime
            }
          });
        }
        return acc;
      }, [] as BarPeriod[]);
    }
  }, [stepsData, dataIsGrouped, selectedSteps]);

  // Define rows for the StateOverTimeChart
  const rows: Row[] = useMemo(() => {
    // Define the first row explicitly here, which displays _all_ the steps, with a custom state of "all"
    // This is a duplication of all the steps combined
    const rows: Row[] = [
      {
        state: 'all',
        label: t('Cleaning Steps'),
        bars: bars.map((bar) => ({ ...bar, state: 'all' })),
        isButton: true,
        isExpanded: expanded,
        key: 'cleaning-step-chart-all'
      }
    ];

    // Then dynamically add a row for each step id
    Object.keys(stepNameMap).forEach((stepId) => {
      rows.push({
        bars: bars.filter((bar) => bar.state === stepId),
        label: t(stepNameMap[stepId]),
        state: stepId,
        key: stepId
      });
    });

    return rows;
  }, [bars, expanded, stepNameMap, i18n.language]);

  // Create the span periods from the waiting time data
  const waitingSpanPeriods: SpanPeriod[] | undefined = useMemo(
    () =>
      waitingStatesData?.map((waitingState) => {
        const startTime = new Date(waitingState.startTimestamp);
        const endTime = new Date(waitingState.endTimestamp);

        return {
          // 'all' as we want the waiting bar to be plotted along with all the other steps (combined in the first row)
          id: 'all',
          startTime,
          endTime,
          color: WAIT_TIME_COLOR,
          toolTipData: {
            title: t('Wait time'),
            startTime,
            endTime
          }
        };
      }),
    [waitingStatesData]
  );

  // Only show all rows only when exapnded
  const filteredRows = expanded ? rows : rows.filter((row) => row.state === 'all');

  // Show a limited amount of legends, with a "show all" button if necessary
  const allLegendsData = Object.keys(stepNameMap);

  const legendsData = allLegendsData.slice(0, NUM_LEGENDS_CONTRACTED);

  const showLegendsButtonVisible = allLegendsData.length > NUM_LEGENDS_CONTRACTED;

  // ***Function to handle undoing the zoom
  const handleUndo = () => {
    // Check if there's a previous zoomed domain in history
    if (zoomedDomainHistory.length > 0) {
      // Get the last zoomed domain from history
      const previousZoomedDomain = zoomedDomainHistory.slice(0, -1);
      setZoomedDomain(previousZoomedDomain[previousZoomedDomain.length - 1]);
      // Update the zooming state to indicate zooming
      setZooming(true);
      setZoomedDomainHistory(previousZoomedDomain);
    }
  };

  const memoizedSetZoomHistory = useCallback((newZoomedDomain) => {
    setZoomedDomainHistory((prevZoomHistory) => [...prevZoomHistory, newZoomedDomain]);
  }, []);

  useEffect(() => {
    // Check if zoomedDomain is defined and not equal to the last zoomedDomain
    if (zoomedDomain && zoomedDomain !== zoomedDomainHistory[zoomedDomainHistory.length - 1]) {
      // Update zoom history using memoized function
      memoizedSetZoomHistory(zoomedDomain);
    }
  }, [zoomedDomain, memoizedSetZoomHistory, zoomedDomainHistory]);

  return (
    <Container>
      <Legends>
        <div>
          <Typography weight="semi-bold" size="1rem">
            {t('Cleaning Session Steps')}
          </Typography>
        </div>
        <KeyInline>
          {legendsData.map((stepId) => (
            <LegendRow key={stepId}>
              <GraphLegend
                id={stepId}
                active={selectedSteps?.includes(stepId) || !selectedSteps?.length}
                label={`${t(stepNameMap[stepId])} (${stepId})`}
                color={
                  dataIsGrouped
                    ? stepCategoryColorMap[stepId] || getColorById(stepId)
                    : getColorById(stepId)
                }
                onClick={() => onSelectSteps?.([stepId])}
              />
            </LegendRow>
          ))}

          {waitingStatesData && (
            <LegendRow key="waiting">
              <GraphLegend
                id="waiting"
                active={waitTimeVisible}
                label={t('Wait time') as string}
                color={WAIT_TIME_COLOR}
                onClick={() => setWaitTimeVisible(!waitTimeVisible)}
              />
            </LegendRow>
          )}
          {showLegendsButtonVisible && (
            <LegendRow>
              <ArrowMenu>
                ... <IcoChevron />
                <DropdownMenu className="dropdown-menu">
                  {allLegendsData.slice(NUM_LEGENDS_CONTRACTED).map((stepId) => (
                    <DropdownItem key={stepId}>
                      <GraphLegend
                        id={stepId}
                        active={selectedSteps?.includes(stepId) || !selectedSteps?.length}
                        label={`${t(stepNameMap[stepId])} (${stepId})`}
                        color={
                          dataIsGrouped
                            ? stepCategoryColorMap[stepId] || getColorById(stepId)
                            : getColorById(stepId)
                        }
                        onClick={() => onSelectSteps?.([stepId])}
                      />
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ArrowMenu>
            </LegendRow>
          )}
        </KeyInline>
        <ZoomContainer>
          {zoomedDomain?.x && (
            <>
              <DataZoom onClick={handleUndo}>
                {t('undo')}
                <IconStyle>
                  <GoBackIcon />
                </IconStyle>
              </DataZoom>
              <ActionButton onClick={() => setZoomedDomain(undefined)} hideArrow>
                {t('reset_zoom')}
              </ActionButton>
            </>
          )}
        </ZoomContainer>
      </Legends>

      {!isLoading && !isFetching ? (
        <StateOverTimeChart
          padding={{ left: 150 }}
          rows={filteredRows}
          spanPeriods={waitTimeVisible ? waitingSpanPeriods : undefined}
          onLabelClick={() => setExpanded(!expanded)}
          customTooltip={<CustomTooltip />}
          hasZoom={true}
          zooming={zooming}
          brush={{
            onBrushDomainChange: () => !zooming && setZooming(true),
            onBrushDomainChangeEnd: (d) => {
              setZoomedDomain(d);
              setZooming(false);
            },
            resetZoom: () => setZoomedDomain(undefined),
            undoZoom: () => setZoomedDomain(undefined),
            zoomedDomain
          }}
        />
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default CleaningStepsChart;
