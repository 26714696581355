//3rd party
import React, { useState, Fragment, useMemo } from 'react';
import Select, { ActionMeta, MultiValue, components } from 'react-select';
import {
  DropDownItem,
  DropdownProps,
  GroupedOption
} from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import {
  InsertButton,
  LabelWrapper,
  MenuListOptionWrapper,
  RequiredMessageWrapper
} from './index.elements';
import { StylesConfig } from 'react-select';
import theme from 'themes';
import { IcoError } from 'icons/IcoError';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="option">
        <MenuListOptionWrapper>
          <input
            className="option--checkbox"
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
          />
          <LabelWrapper>
            <label
              className="option--label"
              style={{
                cursor: props.isDisabled ? 'not-allowed' : 'default'
              }}
            >
              {props.label}
            </label>
          </LabelWrapper>
        </MenuListOptionWrapper>
      </components.Option>
    </div>
  );
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const Menu = (props: any) => {
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>
          <InsertButton>+ Insert Tag(s)</InsertButton>
        </div>
      </components.Menu>
    </Fragment>
  );
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function MultiValueRemove(props: any) {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
}

export const DropdownSubOptions = ({
  disabled,
  optionsWithHeaders,
  value,
  options,
  handleMultiSelect,
  ariaLabel,
  placeholder,
  isCustomAddButton,
  hasError,
  inputValidationsErrors
}: DropdownProps): JSX.Element => {
  const [multiValue, setMultiValue] = useState<DropDownItem[]>(value);
  const getArrangedData = (data: DropDownItem[]) => {
    for (let i = 0; i < data.length; i++) {
      const current = data?.[i];
      if (current?.isFixed) {
        const temp = current;
        data[i] = data[0];
        data[0] = temp;
      }
    }
    return data;
  };

  const DropdownOptions = useMemo(() => {
    return getArrangedData(options);
  }, [options]);

  const handleInputOnChange = (e: MultiValue<DropDownItem>, option: ActionMeta<DropDownItem>) => {
    if (option.removedValue && option.removedValue.isFixed) return;

    if (e.length > 2) {
      const [first, ...rest] = e;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...all] = rest;
      setMultiValue([first, ...all]);
      handleMultiSelect([first, ...all]);
    } else {
      setMultiValue([...e]);
      handleMultiSelect([...e]);
    }
  };

  useMemo(() => {
    setMultiValue(value);
  }, [value]);

  const formatGroupLabel = (optionsWithHeaders: GroupedOption) => (
    <div className="dropdown-header-row">
      <span className="header--label">{optionsWithHeaders.label}</span>
      <span className="header--value">{optionsWithHeaders.options.length}</span>
    </div>
  );
  const colorStyles: StylesConfig<DropDownItem, true, GroupedOption> = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? 'hsl(0, 0%, 95%)' : 'white',
      borderColor: inputValidationsErrors
        ? hasError
          ? theme.colors.text.error
          : theme.colors.lightGrey6
        : theme.colors.lightGrey6,
      outline: 'none',
      ':hover': {
        outline: 'none',
        borderColor: `${theme.colors.primaryBlue5}`
      }
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: theme.colors.whiteLight,
        cursor: isDisabled ? 'not-allowed' : 'default',
        color: isDisabled ? theme.colors.mediumGrey4 : theme.colors.text.lightBlack
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 110
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        color: theme.colors.text.lightBlack,
        marginTop: '-0.25rem'
      };
    }
  };

  return (
    <div>
      <Select
        isDisabled={disabled ? true : false}
        className={disabled ? 'dropdown_checkboxes select--disabled' : 'dropdown_checkboxes'}
        hideSelectedOptions={false}
        aria-label={ariaLabel}
        isClearable={false}
        closeMenuOnSelect={false}
        maxMenuHeight={200}
        styles={colorStyles}
        components={
          isCustomAddButton
            ? {
                Option,
                MultiValueRemove,
                IndicatorSeparator: () => null,
                Menu
              }
            : {
                Option,
                MultiValueRemove,
                IndicatorSeparator: () => null
              }
        }
        value={multiValue}
        isMulti
        options={optionsWithHeaders ? optionsWithHeaders : DropdownOptions}
        onChange={(e, options) => handleInputOnChange(e, options)}
        placeholder={placeholder}
        formatGroupLabel={formatGroupLabel}
      />
      {inputValidationsErrors ? (
        hasError ? (
          <RequiredMessageWrapper className="label">
            <IcoError />
            <span>Please select one more destination</span>
          </RequiredMessageWrapper>
        ) : null
      ) : null}
    </div>
  );
};
