import styled, { DefaultTheme } from 'styled-components';
import theme from 'themes';
interface DropAreaProps {
  isDragAccept: boolean;
  theme: DefaultTheme;
}

const FileInfo = styled.div`
  width: 100%;
`;

const FileArea = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.625rem;
  padding: 0.5rem 1rem;
  text-align: left;
  flex-direction: row;
  column-gap: 0.625rem;
  align-items: start;
`;

const ProgressBar = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '0%'};
  height: 3px;
  background: #2fdb1c;
  border-radius: 50px;
  margin-top: 10px;
`;
const ProgressBarContainer = styled.div`
  position: relative;
  background-color: ${theme.colors.black}20;
`;

const FileNameContainer = styled.div`
  flex: 1;
  .fileName {
    display: flex;
    gap: 0.3rem;
    justify-content: space-between;
  }
`;
const RemoveIcon = styled.div`
  cursor: pointer;
  align-self: center;
`;
const FileSize = styled.div`
  white-space: nowrap;
`;
const FileContainer = styled.div<{ fileContainerheight?: string; hasBorder?: boolean }>`
  max-height: ${({ fileContainerheight }) => fileContainerheight || '20rem'};
  overflow: auto;
  width: 100%;
  border: ${({ hasBorder }) => (hasBorder ? `1px dashed ${theme.colors.mediumGrey2}` : undefined)};
  border-radius: 0.5rem;
  padding-bottom: 0.625rem;
  .progress-text {
    padding: 1.5rem 1rem 0.5rem;
    text-align: left;
  }
`;
const FileStatus = styled.div`
  margin-top: 0.2rem;
  gap: 1.3rem;
  display: flex;
  align-items: center;
  .status {
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
  }
`;
const Dot = styled.div`
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: ${theme.colors.black};
`;

const DropArea = styled.div<DropAreaProps>`
  width: 100%;
  border: dashed;
  border-width: 0.0625rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: 1px dashed #ddd;
  margin: 1rem 0;

  background-color: ${(props) => (props.isDragAccept ? 'rgba(200, 120, 204, 0.1)' : 'transparent')};
  border-color: ${(props) =>
    props.isDragAccept ? 'rgba(200, 120, 204, 0.5)' : props.theme.colors.mediumGrey2};
`;

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const InlineLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.mediumBlue};
  cursor: pointer;
`;

const ProcessingContainer = styled.div`
  width: 100%;
  height: auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  div {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
    margin-bottom: 0.75rem;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const ProcessingLabel = styled.div`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin-bottom: 0;
`;
const PreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FooterButton = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  padding: 1rem;
  gap: 1.3rem;
`;
const PreviewFileContainer = styled.div`
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  .link {
    text-decoration: none;
    opacity: 0.6;
    color: ${theme.colors.primaryBlue5};
    cursor: pointer;
  }
`;
const FilePreviewContent = styled.div`
  flex: 1;
`;
const ErrorMsg = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
`;
const FileName = styled.div`
  span {
    font-weight: 600;
  }
`;
export {
  FileArea,
  FileInfo,
  FileNameContainer,
  ProgressBar,
  ProgressBarContainer,
  RemoveIcon,
  FileContainer,
  FileStatus,
  FileSize,
  Dot,
  DropArea,
  VContainer,
  ProcessingContainer,
  InlineLink,
  LoaderContainer,
  ProcessingLabel,
  PreviewHeader,
  PreviewFileContainer,
  FooterButton,
  FilePreviewContent,
  ErrorMsg,
  FileName
};
