// 3rd party libs
import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { DataRenderer } from 'components/machine-health';
import { JBTRoutes } from 'constants/routes';
import { mapBusinessUnit } from 'helpers/machine';
import { useMachine } from 'hooks';
import {
  Aseptic,
  //DSI,
  FleetMachineDetail,
  //ProteinMachine,
  Proseal
} from 'pages';
//import { FleetPage } from 'common/pages/fleetV2/FleetPage';
import { Redirect, useLocation } from 'react-router-dom';
//import { MachineBusinessUnit } from 'types';
import { TimeZoneProvider } from 'providers/timeZoneProvider';
import { useFleetNavigation, FleetNavigationEntityType } from 'providers';
import { NAV_ITEMS_TO_ID } from 'constants/nav';
import { useRouterWithHandler } from 'common/hooks/useRouter';
import { fleetPageRoute } from 'common/pages/fleetV2/settings/fleetPageRoutes';

const ProteinMachine = loadable(() => import('pages/ProteinMachine'));
const DSI = loadable(() => import('pages/DSI'));

function ProteinMachineViewComponent() {
  return <ProteinMachine />;
}

function DSIMachineViewComponent() {
  return <DSI />;
}

// NEEDS TO BE UPDATED AT SOME POINT
// const pages: Record<MachineBusinessUnit, JSX.Element> = {
//   pemea: ProteinMachineViewComponent(),
//   pna: ProteinMachineViewComponent(),
//   avure: <FleetMachineDetail />,
//   aseptic: <Aseptic />,
//   dsi: <DSI />,
//   proseal: <Proseal />
// };

/**
 *
 * Redirect to the appropriate page, based on machine business unit
 */

// const redirect = (key: MachineBusinessUnit | undefined) => {
//   return key ? pages[key] : <Redirect to={JBTRoutes.fleet} />;
// };

export const Machine = (): JSX.Element => {
  return (
    <TimeZoneProvider>
      <Provided />
    </TimeZoneProvider>
  );
};

const Provided = (): JSX.Element => {
  const { state } = useLocation();
  const { businessUnit } = (state as Record<string, number>) || { businessUnit: 0 };
  const [{ version, id }] = useRouterWithHandler(fleetPageRoute);

  const { machine, isLoading, error } = useMachine();

  const navContext = useFleetNavigation();
  /** adding comments to trigger refresh */
  useEffect(() => {
    machine?.id &&
      navContext.updateEntityIfNeeded({ type: FleetNavigationEntityType.MACHINE, id: machine.id });
    navContext.updateNavIdIfNeeded(NAV_ITEMS_TO_ID.fleet);
  }, [machine?.id, navContext]);

  const bu = mapBusinessUnit(machine?.businessUnit || businessUnit);

  if (isLoading)
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}></DataRenderer>
    );

  if (machine?.id && bu && (version === 'v2' || (bu === 'aseptic' && id !== 'reports'))) {
    return <Redirect to={`/fleet/machine/v2/${machine?.id}/machine-health/overview`} />;
  }

  if (bu === 'pemea')
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}>
        {ProteinMachineViewComponent()}
      </DataRenderer>
    );
  if (bu === 'pna')
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}>
        {ProteinMachineViewComponent()}
      </DataRenderer>
    );
  if (bu === 'avure')
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}>
        <FleetMachineDetail />
      </DataRenderer>
    );
  if (bu === 'aseptic')
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}>
        <Aseptic />
      </DataRenderer>
    );
  if (bu === 'proseal')
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}>
        <Proseal />
      </DataRenderer>
    );
  if (bu === 'dsi')
    return (
      <DataRenderer isLoading={isLoading} error={error && 'Failed to load machine'}>
        {DSIMachineViewComponent()}
      </DataRenderer>
    );

  if (bu === 'undefined') return <Redirect to={JBTRoutes.fleet} />;

  return <div>404</div>;
};

export default Machine;
