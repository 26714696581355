import React from 'react';
import type { FC, SVGProps } from 'react';
import theme from 'themes';
const primaryBlue = theme.colors.primaryBlue5;
const IcoDashboardDiagram: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Template">
      <g id="Icon">
        <path
          d="M10 12.5C10 11.1193 11.1193 10 12.5 10H47.5C48.8807 10 50 11.1193 50 12.5V17.5C50 18.8807 48.8807 20 47.5 20H12.5C11.1193 20 10 18.8807 10 17.5V12.5Z"
          stroke={props.color || primaryBlue}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 32.5C10 31.1193 11.1193 30 12.5 30H27.5C28.8807 30 30 31.1193 30 32.5V47.5C30 48.8807 28.8807 50 27.5 50H12.5C11.1193 50 10 48.8807 10 47.5V32.5Z"
          stroke={props.color || primaryBlue}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40 32.5C40 31.1193 41.1193 30 42.5 30H47.5C48.8807 30 50 31.1193 50 32.5V47.5C50 48.8807 48.8807 50 47.5 50H42.5C41.1193 50 40 48.8807 40 47.5V32.5Z"
          stroke={props.color || primaryBlue}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

const IcoPartBom: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="53"
    height="48"
    {...props}
    viewBox="0 0 53 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 62816">
      <path
        id="Vector"
        d="M13.4277 5.26687C13.4277 4.13523 13.8773 3.04993 14.6775 2.24974C15.4777 1.44954 16.563 1 17.6946 1H43.2958C44.4275 1 45.5128 1.44954 46.313 2.24974C47.1132 3.04993 47.5627 4.13523 47.5627 5.26687V31.6155"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M51.563 46.6556V35.135V31.7215H48.2295H44.896H37.5356H22.8149L17.588 25.3212H11.721C10.5894 25.3212 7.4541 25.3212 7.4541 25.3212C7.4541 24.4678 7.45417 22.186 7.45417 21.0543V9.96048C7.45417 8.82883 7.90371 7.74353 8.7039 6.94334C9.5041 6.14315 10.5894 5.6936 11.721 5.6936H37.3223C38.4539 5.6936 39.5392 6.14315 40.3394 6.94334C41.1396 7.74353 41.5891 8.82883 41.5891 9.96048V30.8681"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M16.4141 25.3212L1.05334 25.3212L1 27.988L1 30.6548L1.00001 35.9884L1.00002 46.6555L7.77368 46.6555L10.6538 46.6555L16.4141 46.6555L27.9347 46.6555L50.9757 46.6555"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="BOM">
        <path
          id="Vector_4"
          d="M30.8006 34.825H32.189L34.5108 41.2186L36.8267 34.825H38.221L35.065 43.3187H33.9508L30.8006 34.825ZM30.1006 34.825H31.4598L31.7048 40.6994V43.3187H30.1006V34.825ZM37.5559 34.825H38.921V43.3187H37.3168V40.6994L37.5559 34.825Z"
          fill={props.color || primaryBlue}
        />
        <path
          id="Vector_5"
          d="M28.7419 38.856V39.2935C28.7419 39.9352 28.6563 40.5127 28.4852 41.0261C28.3141 41.5355 28.071 41.9692 27.756 42.327C27.441 42.6847 27.0657 42.9589 26.6301 43.1495C26.1945 43.3401 25.7103 43.4353 25.1775 43.4353C24.6525 43.4353 24.1703 43.3401 23.7308 43.1495C23.2952 42.9589 22.918 42.6847 22.5991 42.327C22.2802 41.9692 22.0332 41.5355 21.8582 41.0261C21.6832 40.5127 21.5957 39.9352 21.5957 39.2935V38.856C21.5957 38.2104 21.6832 37.6328 21.8582 37.1234C22.0332 36.6139 22.2782 36.1803 22.5933 35.8225C22.9083 35.4608 23.2836 35.1847 23.7191 34.9941C24.1586 34.8035 24.6408 34.7083 25.1659 34.7083C25.6987 34.7083 26.1829 34.8035 26.6184 34.9941C27.054 35.1847 27.4293 35.4608 27.7443 35.8225C28.0632 36.1803 28.3082 36.6139 28.4794 37.1234C28.6544 37.6328 28.7419 38.2104 28.7419 38.856ZM27.1201 39.2935V38.8443C27.1201 38.3815 27.0774 37.9751 26.9918 37.6251C26.9062 37.2712 26.7798 36.9736 26.6126 36.7325C26.4454 36.4914 26.2393 36.3106 25.9942 36.19C25.7492 36.0655 25.4731 36.0033 25.1659 36.0033C24.8547 36.0033 24.5786 36.0655 24.3375 36.19C24.1003 36.3106 23.898 36.4914 23.7308 36.7325C23.5636 36.9736 23.4352 37.2712 23.3458 37.6251C23.2602 37.9751 23.2174 38.3815 23.2174 38.8443V39.2935C23.2174 39.7524 23.2602 40.1588 23.3458 40.5127C23.4352 40.8666 23.5636 41.1661 23.7308 41.4111C23.9019 41.6522 24.108 41.835 24.3492 41.9594C24.5903 42.0839 24.8664 42.1461 25.1775 42.1461C25.4887 42.1461 25.7648 42.0839 26.0059 41.9594C26.247 41.835 26.4493 41.6522 26.6126 41.4111C26.7798 41.1661 26.9062 40.8666 26.9918 40.5127C27.0774 40.1588 27.1201 39.7524 27.1201 39.2935Z"
          fill={props.color || primaryBlue}
        />
        <path
          id="Vector_6"
          d="M17.5354 39.5327H15.3653L15.3537 38.401H17.2146C17.5335 38.401 17.7999 38.3562 18.0138 38.2668C18.2277 38.1773 18.3891 38.0471 18.498 37.8759C18.6108 37.7048 18.6672 37.4967 18.6672 37.2517C18.6672 36.9795 18.6146 36.7578 18.5096 36.5867C18.4085 36.4156 18.2491 36.2911 18.0313 36.2134C17.8135 36.1317 17.5374 36.0908 17.2029 36.0908H15.8845V43.3187H14.2803V34.825H17.2029C17.6852 34.825 18.1149 34.8716 18.4921 34.965C18.8733 35.0544 19.1961 35.1944 19.4605 35.385C19.725 35.5755 19.9253 35.8147 20.0614 36.1025C20.2014 36.3903 20.2714 36.7325 20.2714 37.1292C20.2714 37.4792 20.1897 37.802 20.0264 38.0976C19.8669 38.3893 19.62 38.6265 19.2855 38.8093C18.9549 38.9921 18.5349 39.0971 18.0255 39.1243L17.5354 39.5327ZM17.4654 43.3187H14.8928L15.5637 42.0586H17.4654C17.7843 42.0586 18.0468 42.0061 18.253 41.9011C18.463 41.7961 18.6185 41.6522 18.7197 41.4694C18.8247 41.2828 18.8772 41.0689 18.8772 40.8277C18.8772 40.5633 18.8305 40.3338 18.7372 40.1394C18.6477 39.9449 18.5038 39.7952 18.3055 39.6902C18.111 39.5852 17.8543 39.5327 17.5354 39.5327H15.867L15.8787 38.401H18.0021L18.3696 38.8385C18.8597 38.8424 19.2583 38.9396 19.5655 39.1302C19.8767 39.3207 20.1061 39.5657 20.2539 39.8652C20.4017 40.1647 20.4756 40.4874 20.4756 40.8336C20.4756 41.378 20.357 41.835 20.1197 42.2045C19.8864 42.5739 19.5441 42.852 19.093 43.0387C18.6458 43.2254 18.1032 43.3187 17.4654 43.3187Z"
          fill={props.color || primaryBlue}
        />
      </g>
      <circle
        id="Ellipse 5490"
        cx="5.10676"
        cy="29.8013"
        r="1.06672"
        fill={props.color || primaryBlue}
      />
      <circle
        id="Ellipse 5491"
        cx="9.37336"
        cy="29.8013"
        r="1.06672"
        fill={props.color || primaryBlue}
      />
      <circle
        id="Ellipse 5492"
        cx="13.6409"
        cy="29.8013"
        r="1.06672"
        fill={props.color || primaryBlue}
      />
    </g>
  </svg>
);

const IcoMaintenanceSchedule: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clipPath="url(#clip0_5901_215462)">
      <path
        id="Vector"
        d="M28.75 52.5H15C13.6739 52.5 12.4021 51.9732 11.4645 51.0355C10.5268 50.0979 10 48.8261 10 47.5V17.5C10 16.1739 10.5268 14.9021 11.4645 13.9645C12.4021 13.0268 13.6739 12.5 15 12.5H45C46.3261 12.5 47.5979 13.0268 48.5355 13.9645C49.4732 14.9021 50 16.1739 50 17.5V32.5"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M40 7.5V17.5"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M20 7.5V17.5"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M10 27.5H50"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M37.5 47.5L42.5 52.5L52.5 42.5"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5901_215462">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const IcoDocument: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Document text">
      <path
        id="Icon"
        d="M22.5 30H37.5M22.5 40H37.5M42.5 52.5H17.5C14.7386 52.5 12.5 50.2614 12.5 47.5V12.5C12.5 9.73858 14.7386 7.5 17.5 7.5H31.4645C32.1275 7.5 32.7634 7.76339 33.2322 8.23223L46.7678 21.7678C47.2366 22.2366 47.5 22.8725 47.5 23.5355V47.5C47.5 50.2614 45.2614 52.5 42.5 52.5Z"
        stroke={props.color || primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export { IcoDashboardDiagram, IcoMaintenanceSchedule, IcoDocument, IcoPartBom };
