import React, { useCallback } from 'react';
import {
  UploaderWrapper,
  ImageDragInBox,
  ImageInStorage,
  DragPromptWrapper,
  ClickOrDragText,
  FileTypeAndSizeText,
  FileImage,
  ImageButton,
  ImageWrapper,
  MachineImage,
  MachineImageBox
} from './MachineImageUploaderStyledComponents';
import UploadIcon from '../../../img/Upload Icon.svg';
import { Button } from 'components';
import { Accept, useDropzone } from 'react-dropzone';

interface MachineImageSelectorProps {
  acceptedTypes: Accept;
  file: File | null;
  url: string | null;
  onFileChange: (file: File | null) => void;
  hasMachineImage?: boolean;
  setShowRemoveImagePrompt?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MachineImageSelector = ({
  hasMachineImage,
  setShowRemoveImagePrompt,
  acceptedTypes,
  file,
  url,
  onFileChange
}: MachineImageSelectorProps): JSX.Element => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileChange(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    onDrop: onDrop,
    noClick: true,
    accept: acceptedTypes,
    multiple: false,
    maxFiles: 1
  });
  return (
    <UploaderWrapper>
      {hasMachineImage ? (
        <ImageWrapper>
          <ImageButton>
            <Button
              color="red"
              borderColor="red"
              height="0.793"
              width="1.519rem"
              borderRadius="50%"
              onClick={() => {
                /* eslint-disable  @typescript-eslint/no-non-null-assertion */
                setShowRemoveImagePrompt!(true);
              }}
            >
              X
            </Button>
          </ImageButton>
          <ImageInStorage>
            <MachineImageBox>
              <MachineImage
                src={url ? (url as string) : file ? URL.createObjectURL(file as File) : ''}
              />
            </MachineImageBox>
          </ImageInStorage>
        </ImageWrapper>
      ) : (
        <ImageDragInBox {...getRootProps()} isDragAccept={isDragAccept}>
          <input {...getInputProps()} />
          <DragPromptWrapper onClick={open}>
            <FileImage src={UploadIcon} />
            <ClickOrDragText>
              <span style={{ color: 'blue' }}>Click</span> or drag file here
            </ClickOrDragText>
            <FileTypeAndSizeText>.jpg, .png, File size (500 kB max)</FileTypeAndSizeText>
          </DragPromptWrapper>
        </ImageDragInBox>
      )}
    </UploaderWrapper>
  );
};
