import { styledTheme } from 'common/theme';
import styled from 'styled-components';

interface PressurizationContainerProp {
  gridArea: string;
}

export const PressurizationContainer = styled.div<PressurizationContainerProp>`
  grid-area: ${({ gridArea }) => gridArea};
  border-radius: 0.625rem;
  border: 1px solid ${styledTheme.colors.border.main};
  padding: 0.65rem;

  .pressurization-delta-labels {
    margin-left: 1.5rem;
  }

  .pressurization-delta-widgetui {
    display: flex;
    height: 460px;

    h2 {
      font-size: 1.3em;
    }
  }

  .intensifiers-stroke-counts-list-item__alert {
    margin: 0.65rem 0;

    .intensifiers-stroke-counts-list-item__date {
      font-size: clamp(10px, 1.5vw, 13px);

      svg {
        width: 13px;
        height: 13px;
      }
    }

    .intensifiers-stroke-counts-list-item__message {
      font-size: clamp(10px, 1.5vw, 13px);
    }
  }

  h3 {
    font-size: clamp(12px, 2vw, 14px);
    grid-template-columns: 25px 1fr auto;

    span {
      padding-right: 0.25rem;
      margin-right: 0.5rem;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .collapse-area {
    margin-left: 1.5em;
  }
`;
