import React from 'react';

import { LargeTextTileWidget } from 'common/components';
import { useLoadingWidget } from './hooks/useLoadingWidget';

export const LoadingWidget = (): JSX.Element => {
  const { loadingEfficiency } = useLoadingWidget();

  const gap = loadingEfficiency ? Math.round(loadingEfficiency) : '-';

  const values = [
    {
      title: 'Efficiency',
      value: 0,
      label: 'Avg/Last 1min'
    },
    {
      title: 'Gap',
      value: gap,
      label: 'Avg/Last 1min',
      unit: 'mm'
    }
  ];

  return <LargeTextTileWidget className="loading-widget" values={values} title="Loading" />;
};
