import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`
}))``;

export const IcoPhoto = ({ width, color }: { width?: string; color?: string }): JSX.Element => {
  return (
    <Svg {...{ width, color }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66666 10.6668L5.72385 7.60962C6.24455 7.08892 7.08877 7.08892 7.60947 7.60962L10.6667 10.6668M9.33332 9.33348L10.3905 8.27628C10.9112 7.75559 11.7554 7.75559 12.2761 8.27628L13.3333 9.33348M9.33332 5.33348H9.33999M3.99999 13.3335H12C12.7364 13.3335 13.3333 12.7365 13.3333 12.0001V4.00014C13.3333 3.26376 12.7364 2.66681 12 2.66681H3.99999C3.26361 2.66681 2.66666 3.26376 2.66666 4.00014V12.0001C2.66666 12.7365 3.26361 13.3335 3.99999 13.3335Z"
        stroke={color || '#0076CC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
