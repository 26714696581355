import { Button, Flyout, FlyoutHeader, Typography } from 'components';
import React, { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'themes';
import FileUploadArea, { isFile } from '../UploadMachinePdf/FileUploadArea';
import { AzureBlobParams, FileType } from 'types/machine-management';
import { v4 as uuidv4 } from 'uuid';
import {
  useDeletePartsBomDocumentMutation,
  useGetPartsBomDocumentQuery,
  useGetPartsBomTemplateQuery,
  useUploadPartsBomDocumentMutation
} from 'api';
import uploadFileToBlob from '../UploadMachinePdf/azureBlob';
import { ToastMsg } from 'common/components/Toast/Toast';
import { DocFlyoutBtnContainer } from '../UploadMachinePdf';
import { IcoDownload } from 'icons/IcoDownload';
import DropdownOption, { OptionType } from 'common/components/DropdownOption';
import { IcoTemplate } from 'icons/IcoTemplate';

type Props = {
  open: boolean;
  onCloseFlyout: () => void;
  machineId: string;
};
const SubHeadText = styled.div`
  margin: 0 1.75rem;
  text-align: justify;
  border-bottom: 1px solid ${theme.colors.tableborder};
`;
const FileUploadConainer = styled.div`
  margin: 0 1.75rem;
`;
const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: end;
  padding: 0.5rem 0;
`;
const initialState = {
  files: []
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  const {
    type,
    payload: { signedUrl, progress, id }
  } = action;
  const { payload } = action;

  let updateData;
  const prevFiles = state.files;
  if (type === 'set_url' || type === 'set_status') {
    updateData = state.files.map((item: FileType) =>
      action.payload?.fileName === item?.file?.name ? { ...item, signedUrl, progress, id } : item
    );
  }
  switch (type) {
    case 'add_file':
      return { ...state, files: [...prevFiles, ...payload] };
    case 'delete_file':
      updateData = state.files.filter((d: FileType) =>
        d?.file?.id ? d?.file?.id !== payload?.id : d?.id !== payload.id
      );
      return { ...state, files: updateData };
    case 'set_file':
      return { ...state, files: payload };
    case 'set_status':
    case 'set_url':
      return { ...state, files: updateData };
    default:
      return state;
  }
};
const UploadPartsBOM = ({ open, onCloseFlyout, machineId }: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [fileStore, dispatch] = useReducer(reducer, initialState);
  const { files } = fileStore;
  const [uploadPartsBOM] = useUploadPartsBomDocumentMutation();
  const [deletePartsBomDoc] = useDeletePartsBomDocumentMutation();
  const { data: bomTemplate } = useGetPartsBomTemplateQuery();
  const {
    data: bomDocument,
    isLoading: isBomDocumentLoading,
    refetch: getBomDocument
  } = useGetPartsBomDocumentQuery({ machineId });

  const [uploadDisable, setUploadDisable] = useState(true);
  const [templateList, setTemplateList] = useState<OptionType[]>([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  useMemo(() => {
    if (bomDocument) {
      const files = bomDocument?.map((file) => ({ file: { ...file, name: file.userFileName } }));
      dispatch({ type: 'set_file', payload: files });
    }
  }, [bomDocument]);

  useMemo(() => {
    if (!bomTemplate) return;
    const templates = bomTemplate?.map((template: string) => {
      const label = template.includes('bom_template_excel')
        ? t('download_xls_template')
        : t('download_pdf_template');
      return { label, value: template, icon: <IcoDownload width="1.25rem" /> };
    });
    setTemplateList(templates);
  }, [bomTemplate]);

  const handleFileChange = (userfiles: File[]) => {
    const f = userfiles.map((file) => {
      return { file: file, name: file.name, id: uuidv4() };
    });
    dispatch({ type: 'add_file', payload: f });
    createBlob(userfiles);
  };

  const createBlob = async (files: File[]) => {
    const filesParams = files.map((file) => {
      const params: AzureBlobParams = {
        user_file_name: file.name,
        machine_id: machineId
      };
      return params;
    });
    setUploadDisable(true);
    const resp = (await uploadPartsBOM(filesParams)) as { data?: FileType[] };
    resp?.data?.length &&
      resp.data.map((item) =>
        dispatch({
          type: 'set_url',
          payload: {
            signedUrl: item.signedUrl,
            fileName: item.userFileName,
            id: item.id
          }
        })
      );
    setUploadDisable(false);
  };
  const handleListClick = (option?: OptionType) => {
    const url = option?.value || '';
    const anchorTag = document.createElement('a');
    anchorTag.href = url;
    anchorTag.target = '_blank';
    anchorTag.download = 'true';
    anchorTag.click();
  };
  const handleFileDelete = async (file: FileType) => {
    const id = file.file.id || file.id;
    if (file && !isFile(file.file)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resp = (await deletePartsBomDoc({ document_id: id })) as any;
      if (resp?.data?.message) {
        dispatch({ type: 'delete_file', payload: { id, name: file.file.name, isDeleting: true } });
        ToastMsg({
          heading: 'Success',
          message: 'Document deleted Successfully!',
          type: 'success'
        });
      } else {
        ToastMsg({
          heading: 'Error',
          message: resp.error?.data?.detail || 'Not able to delete the document.',
          type: 'error'
        });
      }
      getBomDocument();
    } else {
      dispatch({ type: 'delete_file', payload: { id, name: file.file.name, isDeleting: true } });
    }
  };

  const handleUpload = async () => {
    const uploadPromises: Promise<void>[] = [];
    setUploadDisable(true);
    setIsFileUploading(true);
    fileStore.files.forEach((file: FileType) => {
      if (file.signedUrl) {
        const uploadPromise = uploadFileToBlob(file.file, file.signedUrl, (progress) => {
          const { loaded, total = 1 } = progress;
          const uploadStatus = Math.floor((loaded / total) * 100);
          dispatch({
            type: 'set_status',
            payload: { id: file.id, fileName: file.file.name, progress: uploadStatus }
          });
        });
        uploadPromises.push(uploadPromise);
      }
    });

    const resp = await Promise.all(uploadPromises);
    if (resp.length) {
      setIsFileUploading(false);
      getBomDocument();
      ToastMsg({
        heading: 'Success',
        message: 'Document(s) Uploaded Successfully!',
        type: 'success'
      });
    }
  };
  return (
    <Flyout width="28.125rem" visible={open} onClose={onCloseFlyout}>
      <FlyoutHeader
        heading={t('upload_parts_bom') || ''}
        onClose={onCloseFlyout}
        marginLeft="0.625rem"
      />
      <SubHeadText>
        <Typography>{t('upload_bom_flyout_message')}</Typography>
        <DropdownContainer>
          <IcoTemplate />
          <DropdownOption
            options={templateList}
            handleOptionSelect={handleListClick}
            label={
              <Typography mb={0} size={'1rem'} color={theme.colors.primaryBlue5}>
                {t('view_bom_templates')}
              </Typography>
            }
            iconColor={theme.colors.primaryBlue5}
            popupTop="1.75rem"
          />
        </DropdownContainer>
      </SubHeadText>
      <FileUploadConainer>
        <FileUploadArea
          acceptedTypes={{
            'application/vnd.ms-excel': ['.xls', '.xlsx', '.xlsm'],
            'application/pdf': ['.pdf'],
            'application/zip': ['.zip']
          }}
          acceptedFileText={'.xls, .zip, .pdf, File size(100 MB per file max)'}
          onFileChange={(file) => {
            file && files.length <= 100 && handleFileChange(file);
          }}
          filesWithUrl={files}
          handleFileDelete={(file) => handleFileDelete(file as FileType)}
          maxFileSize={104857600}
          isLoading={isBomDocumentLoading}
          styles={{
            fileContainerHeight: '30rem',
            isBorder: true
          }}
          repoType="dg"
          isFileUploading={isFileUploading}
        />
      </FileUploadConainer>
      <DocFlyoutBtnContainer>
        <Button
          variant="link"
          onClick={onCloseFlyout}
          borderRadius="3rem"
          borderColor={theme.colors.primaryBlue5}
          width="8rem"
        >
          {t('close', { ns: 'common' })}
        </Button>
        <Button
          variant="hover-blue"
          borderRadius="3rem"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.lightGrey3}
          width="8rem"
          onClick={handleUpload}
          disabled={uploadDisable}
        >
          {t('upload', { ns: 'common' })}
        </Button>
      </DocFlyoutBtnContainer>
    </Flyout>
  );
};

export default UploadPartsBOM;
