import React, { useState } from 'react';
import { AlertSetupHeading, StepContainer } from './index.elements';
import { NotificationsToggle } from './ToggleSwitch';
import TriggerAlert from './TriggerAlert';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SlidingWindow } from './SlidingWindow';
import { FrequencyDropdown } from './FrequencyDropdown';
import Reminder from './Reminder';
import { AlertEnumTypes, TAlertData, TReminder, TTrigger } from 'types/machine-health/alerts';
import { ValidationErrors } from '../CreateAlert';
import CustomLoader from 'components/CustomLoader';

type TAlertSetup = {
  AlertUnitTypes: AlertEnumTypes;
  isAlertTypeFetching: boolean;
  alertData: TAlertData;
  onHandleChange: (key: string, value: string | number | boolean) => void;
  setIsReminderEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isReminderEnabled: boolean;
  onReminderHandleChange: (key: string, value: string | number) => void;
  reminderValues: TReminder;
  onTriggerHandleChange: (key: string, value: string | number) => void;
  triggerValues: TTrigger;
  onBlurHandle: (key: string) => void;
  validationState: ValidationErrors;
  inputValidationsErrors: boolean;
};

const AlertSetup = ({
  AlertUnitTypes,
  isAlertTypeFetching,
  alertData,
  onHandleChange,
  setIsReminderEnabled,
  isReminderEnabled,
  onReminderHandleChange,
  reminderValues,
  onTriggerHandleChange,
  triggerValues,
  onBlurHandle,
  validationState,
  inputValidationsErrors
}: TAlertSetup): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const [isOpen, setIsOpen] = useState(true);
  const alertFrequencyUnits = AlertUnitTypes.alertFrequencyUnits;
  const alertReminderUnits = AlertUnitTypes.reminderUnits;
  const alertTriggerTypes = AlertUnitTypes.triggerType;
  return (
    <StepContainer>
      <div
        className="header"
        style={{
          paddingLeft: '5px'
        }}
      >
        <button type="button" className="chevron" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} color={'#303E47'} />
        </button>
        <AlertSetupHeading>{t('alert_set_up')}</AlertSetupHeading>
      </div>
      {isAlertTypeFetching ? (
        <CustomLoader size={'3rem'} thickness="0.2rem" />
      ) : (
        <div className={`wrapper ${isOpen ? 'open' : 'closed'}`}>
          <div className="row">
            <div className="column">
              <FrequencyDropdown
                alertFrequencyUnits={alertFrequencyUnits}
                alertData={alertData}
                onDropdownChange={onHandleChange}
                onBlurHandle={onBlurHandle}
                validationState={validationState}
                inputValidationsErrors={inputValidationsErrors}
              />
              {process.env.REACT_APP_ENABLE_CALCULATED_TAGS_TAB === 'true' ? (
                <div
                  style={{
                    marginTop: '2rem'
                  }}
                >
                  <Reminder
                    alertReminderUnits={alertReminderUnits}
                    reminderValues={reminderValues}
                    onDropdownChange={onReminderHandleChange}
                    isReminderEnabled={isReminderEnabled}
                    setIsReminderEnabled={setIsReminderEnabled}
                  />
                </div>
              ) : null}
            </div>
            <div className="column">
              <SlidingWindow
                alertFrequencyUnits={alertFrequencyUnits}
                alertData={alertData}
                onDropdownChange={onHandleChange}
                onBlurHandle={onBlurHandle}
                validationState={validationState}
                inputValidationsErrors={inputValidationsErrors}
              />
            </div>
            <div>
              <TriggerAlert
                label={t('alert_trigger_based_on') as string}
                alertFrequencyUnits={alertFrequencyUnits}
                alertTriggerTypes={alertTriggerTypes}
                onDropdownChange={onTriggerHandleChange}
                triggerValues={triggerValues}
                inputValidationsErrors={inputValidationsErrors}
              />
            </div>
            <div className="column fixed-width">
              {process.env.REACT_APP_ENABLE_CALCULATED_TAGS_TAB === 'true' ? (
                <NotificationsToggle />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </StepContainer>
  );
};

export default AlertSetup;
