import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChevronRightContainer } from './ChevronContainer';
import theme from 'themes';
import { ChevronBox } from './ChevronContainer';

interface ScrollProps {
  handleScroll: () => void;
  color?: string;
  className?: string;
}
const RightChevron = ({ handleScroll, color, className }: ScrollProps): JSX.Element => {
  return (
    <ChevronRightContainer onClick={handleScroll} className={className}>
      <ChevronBox>
        <FontAwesomeIcon icon={faChevronRight} color={color || theme.colors.darkGrey} />
      </ChevronBox>
    </ChevronRightContainer>
  );
};

export default RightChevron;
