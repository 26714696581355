import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { utcToZonedTime } from 'date-fns-tz';

import { useGetMachineStatesByBuV2Query } from 'api';
import { API_INTERVAL } from '../utils';
import { colors } from 'common/pages/fleetV2/settings/dsi';
import { AsepticMachineHealthKpiItem } from 'types/machine-health';
import { useAPIIntervalProvider } from 'common/pages/fleetV2/hooks/useAPITimer';

const MachineModesAPIProviderContext = createContext<MachineModesProps>({
  isLoading: true
});

interface MachineModesProps {
  isLoading: boolean;
  data?: Record<string, unknown>[];
  hasError?: string;
  hasMessage?: string;
  end_time?: string;
}

interface MachineModeItem extends AsepticMachineHealthKpiItem {
  recipe?: string;
  runLoginId?: string;
}

export const useMachineModesAPIProvider = (): MachineModesProps =>
  useContext(MachineModesAPIProviderContext);

interface Props {
  children?: ReactNode | ReactNode[];
  timeZone: string;
  machineId: string;
}

//const UPDATE_CACHE_INTERVAL = 3 * 60 * 1000; // This is an interval for cache update if we are recieveing SAME state. This is no t api frequency.

export const MachineModesAPIProvider = ({ children, timeZone, machineId }: Props): JSX.Element => {
  const { start_time, end_time } = useAPIIntervalProvider();
  const [cached, setCachedData] = useState(undefined);
  const lastItemEndDate = useRef<string | undefined>(start_time);

  // I need to compare if end date is current of in the past
  const now = utcToZonedTime(new Date().toISOString(), timeZone);
  const prevEndTime = utcToZonedTime(end_time, timeZone);
  //if in the past, then we use start date from date range for API call
  const newStartDate =
    Math.abs(now.valueOf() - prevEndTime.getTime()) > 5 * 60 * 1000
      ? start_time
      : lastItemEndDate.current;

  const {
    data: machineHealth,
    isLoading,
    error
  } = useGetMachineStatesByBuV2Query({
    machineId,
    businessUnit: 'dsi',
    //here, I need to compare if end date is current of in the past, if in the past, then we use start date from date range for API call: TODO
    startTimestamp: newStartDate, // Use last item end date as a start date for next api call
    endTimestamp: end_time
  });

  useEffect(() => {
    if (!machineHealth || machineHealth?.length === 0) return;
    if (!start_time || !end_time) return;

    // Here we compare data with already exisiting and:
    // 1. add new data to array, or
    // 2. update end date of already exisitng item in array, meaning this state is still running
    if (cached) {
      if (machineHealth.length > 1) {
        const newCache = machineHealth;
        lastItemEndDate.current = newCache[newCache.length - 1].end_timestamp; //Api is sorted in asd order, so the last item is the most recent
        return newCache && setCachedData(newCache);
      } else {
        // If we select one day time frame and 1 state lasts the whole day, we will only recieve 1 item in array back from api.
        // if returned item start and end date is equal to start and end time from provider then assign new cache
        const mhItem = machineHealth[0]; //Selecting first item, because there is only item returned from api
        const mhItemStartTime = utcToZonedTime(mhItem.start_timestamp, 'UTC');
        const mhItemEndTime = utcToZonedTime(mhItem.end_timestamp, 'UTC');
        const dateRangeStartTime = utcToZonedTime(start_time, 'UTC');
        const dateRangeEndTime = utcToZonedTime(end_time, 'UTC');

        if (
          mhItemStartTime.getTime() === dateRangeStartTime.getTime() &&
          mhItemEndTime.getTime() === dateRangeEndTime.getTime() &&
          dateRangeEndTime.getTime() - dateRangeStartTime.getTime() > API_INTERVAL
        ) {
          const newCache = machineHealth;
          //Api is sorted in asd order, so the last item is the most recent
          lastItemEndDate.current = newCache[newCache.length - 1].end_timestamp;
          return newCache && setCachedData(newCache);
        }

        //Api is sorted in asd order, so the last item is the most recent
        const exisitingItem = cached[cached?.length - 1];
        //New item from API
        const newItem = machineHealth[machineHealth.length - 1] as unknown as MachineModeItem;
        if (
          exisitingItem.state_name === newItem.state_name &&
          exisitingItem.state_code === newItem.state_code
        ) {
          //If same state, update end timestamp
          lastItemEndDate.current = newItem.end_timestamp;
          const updated = JSON.parse(JSON.stringify(cached));
          //Api is sorted in asd order, so the last item is the most recent
          updated[updated.length - 1].end_timestamp = newItem.end_timestamp;
          setCachedData(updated);
        } else {
          //If state is different, then add state to the cache
          const updated = JSON.parse(JSON.stringify(cached));
          updated.push(machineHealth);
          setCachedData(updated);
        }
      }
    } else {
      const newCache = machineHealth;
      lastItemEndDate.current = newCache[newCache.length - 1].end_timestamp; //Api is sorted in asd order, so the last item is the most recent
      return newCache && setCachedData(newCache);
    }
  }, [machineHealth]);

  const cachedData = useMemo(() => {
    return cached?.map((item) => {
      const zonedStart = utcToZonedTime(new Date(item.start_timestamp as string), timeZone);
      const zonedEnd = utcToZonedTime(new Date(item.end_timestamp as string), timeZone);

      return {
        ...item,
        start_time: utcToZonedTime(new Date(item.start_timestamp as string), timeZone),
        end_time: utcToZonedTime(new Date(item.end_timestamp as string), timeZone),
        color: colors[item.state_name?.toLowerCase() as unknown as string],
        colorHover: colors[item.state_name?.toLowerCase() as unknown as string],
        duration: zonedEnd.getTime() - zonedStart.getTime(), // duration is in MILLISECONDS
        data_type: 'status',
        mode_descr: item.state_name,
        mode_number: item.state_code
      };
    }) as unknown as MachineStatesExtended[];
  }, [cached]);

  const hasError = error ? 'Error getting Machine Modes data' : undefined;
  let hasMessage = undefined;

  if (hasError) console.warn('Error getting Machine Modes data');

  if ((!isLoading && !hasError) || machineHealth?.length === 0) {
    hasMessage = 'no data';
  }

  return (
    <MachineModesAPIProviderContext.Provider
      value={{
        data: cachedData,
        end_time,
        hasError,
        hasMessage,
        isLoading
      }}
    >
      {children}
    </MachineModesAPIProviderContext.Provider>
  );
};
