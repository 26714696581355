import React from 'react';
import { styledTheme } from 'components';

export interface Props {
  color?: string;
}

export const IconInfo = ({ color }: Props): JSX.Element => {
  color = color || styledTheme.color.secondary;

  return (
    <svg
      width="14"
      height="14"
      className="icon icon-info"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 9.66667H7V7H6.33333M7 4.33333H7.00667M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
        stroke={`${color}`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
