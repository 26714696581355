import React, { useEffect, useState } from 'react';
import QueryBuilder, { findPath, formatQuery, getParentPath } from 'react-querybuilder';
import CustomRemoveGroupBtn from './customComponents/CustomRemoveGroupBtn';
import CustomRemoveRuleBtn from './customComponents/CustomRemoveRuleBtn';
import CustomAddGroupBtn from './customComponents/CustomAddGroupBtn';
import CustomAddRuleBtn from './customComponents/CustomAddRuleBtn';
import CustomValueEditor from './customComponents/CustomValueEditor';
import CustomFieldEditor from './customComponents/CustomFieldEditor';
import CustomOperatorSelector from './customComponents/CustomOperatorSelector';
import CustomCombinatorSelector from './customComponents/CustomCombinatorSelector';
import 'react-querybuilder/dist/query-builder.css';
import './style.css';
import { QueryBuilderRule, RuleGroup } from 'types/machine-health/alerts';
import { useGetMachineDataAnalysisTagsQuery } from 'api';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ValidationContext } from './AlertQueryContext';
import CustomLoader from 'components/CustomLoader';

interface QueryBuilderProp {
  onDataChange: (data: string, parentId?: string, id?: string) => void;
  queryData: LogicGroup2 | ConvertedRuleGroup;
  inputValidationsErrors: boolean;
}

// initial default query
const defaultQuery: ConvertedRuleGroup = {
  id: 'root',
  combinator: 'AND',
  rules: []
};

export interface LogicStatement {
  tagName: string;
  comparisonOperator: string;
  logicGroupId?: string;
  id?: string;
  logicStatementValues: {
    value: string | null;
    tagName: string | null;
    id?: string;
    logicStatementId?: string;
    businessUnitId: string | null;
  }[];
}

export interface LogicGroup2 {
  id?: string;
  alertId?: string | null;
  parentLogicGroupId?: string | undefined;
  logicOperator: string;
  name: string;
  position: number;
  logicGroups: LogicGroup2[];
  logicStatements: LogicStatement[];
}
interface ConvertedRule {
  field: string;
  operator: string;
  value: string;
  logicGroupId?: string;
  id?: string;
  statementValuesIds?: {
    logicStatementId?: string;
    id?: string;
  }[];
}

export interface ConvertedRuleGroup {
  id: string | undefined;
  combinator: string;
  rules: (ConvertedRule | ConvertedRuleGroup)[];
  parent_logic_group_id?: string | undefined;
}

function convertLogicGroup(logicGroup: LogicGroup2): ConvertedRuleGroup {
  const newGroup: ConvertedRuleGroup = {
    id: 'root',
    combinator: logicGroup.logicOperator,
    rules: [],
    parent_logic_group_id: ''
  };

  logicGroup &&
    logicGroup?.logicStatements &&
    logicGroup?.logicStatements?.forEach((statement) => {
      const rule: ConvertedRule = {
        field: statement.tagName,
        operator: statement.comparisonOperator,
        logicGroupId: statement.logicGroupId,
        id: statement.id,
        value: statement.logicStatementValues
          .map((value) =>
            value.value ? value.value + '/' + undefined : undefined + '/' + value.tagName
          )
          .join(','),
        statementValuesIds: statement.logicStatementValues.map((value) => {
          return {
            logicStatementId: value.logicStatementId,
            id: value.id
          };
        })
      };

      newGroup.rules.push(rule);
    });

  logicGroup &&
    logicGroup?.logicGroups &&
    logicGroup?.logicGroups?.forEach((childGroup) => {
      const childRuleGroup = convertLogicGroup(childGroup);
      newGroup.parent_logic_group_id = childGroup.parentLogicGroupId;
      newGroup.id = childGroup.id;
      newGroup.rules.push({
        id: childGroup.id,
        combinator: childGroup.logicOperator,
        rules: childRuleGroup.rules
      });
    });

  return newGroup as ConvertedRuleGroup;
}

function convertJsonToNewFormat(jsonObj: LogicGroup2 | ConvertedRuleGroup): ConvertedRuleGroup {
  if (jsonObj && jsonObj?.id !== 'root') {
    return convertLogicGroup(jsonObj as LogicGroup2);
  } else {
    return defaultQuery as ConvertedRuleGroup;
  }
}

export const AlertQueryBuilder = ({
  onDataChange,
  queryData,
  inputValidationsErrors
}: QueryBuilderProp): JSX.Element => {
  const [query, setQuery] = useState<ConvertedRuleGroup>(convertJsonToNewFormat(queryData));
  const { machineId } = useFleetMachineAccountData();
  function hasSingleFieldObject(data: QueryBuilderRule): boolean {
    function flatten(arr: RuleGroup[]): RuleGroup[] {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return arr.reduce((acc: any, item) => {
        if (Array.isArray(item)) {
          flatten(item).forEach((nestedItem) => acc.push(nestedItem));
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
    }

    const flatData = flatten(data.rules); // Flatten if necessary

    // eslint-disable-next-line no-prototype-builtins
    const fieldObjects = flatData.filter((obj) => obj.hasOwnProperty('field'));
    return fieldObjects.length === 1;
  }

  const getRulesLength = (index: number[]) => {
    const parentPath = getParentPath(index);
    const parentGroup = findPath(parentPath, query);

    return hasSingleFieldObject(parentGroup as QueryBuilderRule) || false;
  };

  const getGroupsLength = () => {
    return JSON.parse(formatQuery(query, 'json')).rules.length;
  };

  const {
    data: alertStatementTagQuery,
    isFetching,
    isError
  } = useGetMachineDataAnalysisTagsQuery(
    machineId
      ? {
          machineId,
          numbersOnly: 'false'
        }
      : skipToken
  );

  useEffect(() => {
    onDataChange(
      formatQuery(query, 'json'),
      convertJsonToNewFormat(queryData).parent_logic_group_id,
      convertJsonToNewFormat(queryData).id
    );
  }, [query]);

  return (
    <>
      {isFetching ? (
        <CustomLoader size={'3rem'} thickness="0.2rem" />
      ) : alertStatementTagQuery && !isError && alertStatementTagQuery.length === 0 ? null : (
        <ValidationContext.Provider value={inputValidationsErrors}>
          <div
            style={{
              marginLeft: '-11px'
            }}
          >
            <QueryBuilder
              onQueryChange={(q) => {
                setQuery(q);
              }}
              query={query && query}
              controlElements={{
                valueEditor: CustomValueEditor,
                fieldSelector: CustomFieldEditor,
                combinatorSelector: CustomCombinatorSelector,
                removeRuleAction: (props) => (
                  <CustomRemoveRuleBtn getRulesLength={getRulesLength} {...props} />
                ),
                removeGroupAction: (props) => (
                  <CustomRemoveGroupBtn getGroupsLength={getGroupsLength} {...props} />
                ),
                addGroupAction: CustomAddGroupBtn,
                addRuleAction: CustomAddRuleBtn,
                operatorSelector: CustomOperatorSelector
              }}
              addRuleToNewGroups
              resetOnOperatorChange
              resetOnFieldChange
              controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
            />
          </div>
        </ValidationContext.Provider>
      )}
    </>
  );
};

export default AlertQueryBuilder;
