import BaseSelect from 'components/BaseSelect/BaseSelect';
import {
  CustomContentRenderer,
  CustomDropdownRendererAlertFieldSelector
} from 'components/machine-health/configurator/WidgetTable/Dropdown';
import React, { useContext, useMemo, useState } from 'react';
import { FieldSelectorProps } from 'react-querybuilder';
import { AlertsTableDropdownItem, TagsDropdownItems } from 'types/machine-health/widget-table';
import { useTranslation } from 'react-i18next';
import { useGetMachineDataAnalysisTagsQuery } from 'api';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Loader from 'components/Loader';
import { ValidationContext } from '../AlertQueryContext';
type TDropdown = {
  label: string;
  id: string;
  name: string;
  tagId: string;
  values: [{ value?: number | null | string }];
};

const CustomFieldEditor = (props: FieldSelectorProps): JSX.Element => {
  const [dropdownValue, setDropdownValue] = useState<TDropdown | null>(null);
  const { t } = useTranslation(['mh']);
  const inputValidation = useContext(ValidationContext);
  const { machineId } = useFleetMachineAccountData();

  const { data: alertStatementTagQuery, isFetching } = useGetMachineDataAnalysisTagsQuery(
    machineId
      ? {
          machineId: machineId,
          numbersOnly: 'false'
        }
      : skipToken
  );

  const MasterTagDropdownList = useMemo(() => {
    if (alertStatementTagQuery && alertStatementTagQuery.length > 0) {
      const field = alertStatementTagQuery.find((tag) => tag.tagId === props.rule.field);
      if (field) {
        setDropdownValue({
          ...dropdownValue,
          label: field.tagId as string,
          id: field.name ? field.name : field.tagId,
          name: field.name ? field.name : (field.tagId as string),
          tagId: field.tagId as string,
          values: [
            {
              value: field.value
            }
          ]
        });
      }
      return alertStatementTagQuery
        .filter((tag) => {
          return tag.value !== null;
        })
        .map((item) => ({
          label: item.tagId,
          id: item.name ? item.name : item.tagId,
          name: item.name ? item.name : item.tagId,
          tagId: item.tagId,
          values: [{ value: item.value }]
        }));
    }
    return [];
  }, []);

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <BaseSelect
          contentRenderer={({ id }: Record<string, unknown>) => (
            <CustomContentRenderer
              item={id as unknown as TagsDropdownItems}
              label={MasterTagDropdownList.length === 0 ? 'No Data' : 'select_a_tag'}
              toolTipText={'Tag Is Required'}
              removeTooltipIcon
              enableTooltip
            />
          )}
          dropdownRenderer={(props, state, methods) => (
            <CustomDropdownRendererAlertFieldSelector
              placeHolder={t('search_for_tag') as string}
              props={props}
              state={state}
              methods={methods}
              secondDropdown={true}
              header
            />
          )}
          disabledLabel={'tag_already_assigned'}
          handleChangeSearch={(
            value: (AlertsTableDropdownItem | string | Record<string, unknown> | undefined)[]
          ) => {
            // We need to account for the case when value = undefined ...
            // ... which can happen when the user clears the search field
            const updateValue = value[0] as TDropdown;
            props.handleOnChange(updateValue && updateValue.tagId);
            return updateValue && setDropdownValue(updateValue);
          }}
          labelField="id"
          options={MasterTagDropdownList as { id: string; label: string }[]}
          placeholder={'select_tag'}
          searchable
          searchBy="label" // label is assigned as friendlyName value
          value={{
            label: dropdownValue,
            id: dropdownValue,
            name: dropdownValue
          }}
          width="100%"
          valueField="id"
          isShowErrorMessage={inputValidation && dropdownValue === null}
          variant={MasterTagDropdownList.length === 0 ? 'disabled' : 'gray'}
          borderVariant={inputValidation && dropdownValue === null ? 'error' : 'none'}
        />
      )}
    </>
  );
};

export default CustomFieldEditor;
