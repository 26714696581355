import React from 'react';
import { useSelectedItems } from 'pages/FleetMachineDetail/hooks/useZoomHistoryhook';
import { ZoomActions } from './elements';
import { IconInfo } from 'icons/IconInfo';
import { IconReset } from 'icons/IconReset';
import { GoBackIcon } from 'icons/IconGoBack';

export const ZoomContainer = (): JSX.Element => {
  const { history, goBack, resetState } = useSelectedItems();

  const showButtons = history && history?.length > 1;

  return (
    <ZoomActions className="container-zoom">
      <p className="zoom">
        Data Zoom{' '}
        <div className="tooltip-container">
          <IconInfo />
          <span className="tooltip">Chart has zoom functionality</span>
        </div>
      </p>

      {showButtons && (
        <button
          className="button undo"
          onClick={() => (history && history?.length <= 1 ? resetState() : goBack())}
          disabled={history && history?.length > 1 ? false : true}
          type="button"
        >
          <GoBackIcon /> Undo
        </button>
      )}

      {showButtons && (
        <button className="button reset" onClick={() => resetState()} type="button">
          <IconReset /> Reset
        </button>
      )}
    </ZoomActions>
  );
};
