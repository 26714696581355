import React from 'react';
import { MachineIssuesProvider } from 'hooks/useMachineAlarmsAlerts';
import { MachineType } from 'types/machine-health';
import { AlertStatus } from 'types/machine-health/alerts';
import styled from 'styled-components';
import { IntensifiersProvider } from 'hooks/useIntensifiersCount';
import { ProvidedPage } from './ProvidedPage';

export const StrokeCountContainer = styled.div`
  display: grid;
  grid-gap: inherit;
  grid-auto-flow: row;
  grid-gap: inherit;
  padding: 1rem;
  border-color: #c2c2c6;
  border-width: 1px;
  border-radius: 0.625rem;
  border-style: solid;

  .widget-ui.intensifier-stroke-count-widgetui {
    display: flex;
    min-height: 430px;
    z-index: 99;

    .list-area {
      min-width: 270px;
    }

    h2 {
      font-size: 1.25em;
    }

    .intensifiers-stroke-counts-list-item {
      font-size: 1em;

      h3 {
        margin-bottom: 0.5rem;
      }
    }

    .intensifiers-stroke-counts-list-item__alert {
      .intensifiers-stroke-counts-list-item__date {
        font-size: 0.75rem;
      }

      .intensifiers-stroke-counts-list-item__message {
        font-size: 0.75rem;
        line-height: 1.6;
      }
    }

    .y_axis_label {
      font-size: 14px;
    }
  }

  .dots-chart-labels {
    max-width: 50%;
    margin-left: 1rem;
  }
`;

export const StrokeCountAreaChart = (): JSX.Element => {
  const machineIssuesProviderSettings = {
    machineType: MachineType.Avure,
    alertStatus: AlertStatus.NotComplete,
    isAlarms: false,
    isAlerts: true,
    isMachineAlerts: false
  };

  return (
    <MachineIssuesProvider {...machineIssuesProviderSettings}>
      <IntensifiersProvider>
        <StrokeCountContainer>
          <ProvidedPage />
        </StrokeCountContainer>
      </IntensifiersProvider>
    </MachineIssuesProvider>
  );
};
