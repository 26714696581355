// 3rd party
import React, { ReactElement, useMemo } from 'react';
import styled, { useTheme, DefaultTheme } from 'styled-components';
import { JBTRoutes } from 'constants/routes';
import { useHistory } from 'react-router';
import Tooltip from 'rc-tooltip';

// Components
import { Button, NewBaseTable } from 'components';

// Types
import { SortState, TableColumnConfigs } from 'types';
import { MachineProgressType, OnboardingMachine, MasterTagList } from 'types/machine-management';
import { useSort } from 'hooks';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoIcon } from 'icons/InfoIcon';
import { TooltipInformationContainer } from 'components/Input';
import { CellContext } from '@tanstack/react-table';
import { Checkmark } from 'icons/checkmark';
import { IcoUpload } from 'icons/IcoUpload';
import { IcoLightning } from 'icons/IcoLightning';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { IcoUntrash } from 'icons/IcoUntrash';
import { IconPublished } from 'icons/IconPublished';

const Root = styled.div`
  width: 100%;
  height: auto;
`;

const ActionsButton = styled(Button)`
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  padding: 0.25rem 0.375rem;
  width: 1%;
  svg {
    margin-left: 0;
  }
`;

const StyledIndicator = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  gap: 0.3rem;
  margin: 0;
  white-space: nowrap;
`;
export const Circle = styled.div`
  width: 0.85rem;
  height: 0.85rem;
  border-radius: 50%;
  border: 1.75px solid ${({ theme }) => theme.colors.mediumGrey4};
`;
const Icon = styled.span`
  vertical-align: sub;
`;

const TagListDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const UnArchiveBtn = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const MachineManagementTableWrapper = styled.div`
  width: 100%;

  .sub-text {
    padding: 0 0.25rem;
  }

  tr td {
    &:first-child {
      width: 23%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 10%;
    }

    &:nth-child(5) {
      width: 10%;
    }
  }

  table {
    th {
      & > div,
      .select-none {
        margin: 0;
      }
    }
  }

  td {
    text-align: left;
  }

  tr td span {
    margin: 0;
  }

  th {
    text-align: left;
    text-transform: capitalize;
  }

  td,
  th {
    padding: 0.6em 1em 0.6em 0;
  }

  tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  thead th {
    padding: 0.5rem 0.625rem;
  }
`;

const makeIndicator = (
  onboardingMachine: OnboardingMachine,
  data: string,
  theme: DefaultTheme,
  goTo: (onboardingMachine: OnboardingMachine) => void
) => {
  switch (data) {
    case MachineProgressType.ERROR:
      return (
        <StyledIndicator onClick={() => goTo(onboardingMachine)} color={theme.colors.text.error}>
          <span style={{ marginRight: '5px' }}>Error</span>
          <Tooltip
            overlay={
              <TooltipInformationContainer>
                <p style={{ fontSize: '.9rem' }}>There was an error when uploading the file</p>
              </TooltipInformationContainer>
            }
            placement={'top'}
            overlayClassName="information-tooltip"
          >
            <Icon>{InfoIcon(theme.colors.text.error)}</Icon>
          </Tooltip>
        </StyledIndicator>
      );
    case MachineProgressType.NONE:
      return (
        <StyledIndicator onClick={() => goTo(onboardingMachine)} color={theme.colors.mediumGrey4}>
          <Circle /> Not Started
        </StyledIndicator>
      );
    case MachineProgressType.UPLOADING:
      return (
        <StyledIndicator onClick={() => goTo(onboardingMachine)} color={theme.colors.cyan}>
          <IcoUpload width="16" /> Uploading
        </StyledIndicator>
      );
    case MachineProgressType.INPROGRESS:
      return (
        <StyledIndicator onClick={() => goTo(onboardingMachine)} color={theme.colors.atRiskYellow}>
          <IcoLightning width="16" /> In Progress
        </StyledIndicator>
      );
    case MachineProgressType.DONE:
      return (
        <StyledIndicator onClick={() => goTo(onboardingMachine)} color={theme.colors.onTrackGreen}>
          {Checkmark({ color: theme.colors.onTrackGreen })} Done
        </StyledIndicator>
      );
    case MachineProgressType.PUBLISHED:
      return (
        <StyledIndicator onClick={() => goTo(onboardingMachine)} color={theme.colors.onTrackGreen}>
          {IconPublished({ color: theme.colors.onTrackGreen })} Published
        </StyledIndicator>
      );
    default:
      return <div>{data}</div>;
  }
};

// Generate the configurations for each column of this table
const generateColumn = ({
  goTo,
  theme,
  onOpenActions,
  t,
  tableName,
  handleClickAction
}: {
  goTo: (onboardingMachine: OnboardingMachine) => void;
  onOpenActions?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mtlId: string,
    record?: OnboardingMachine
  ) => void;
  theme: DefaultTheme;
  t: TFunction<'mh'[], undefined>;
  tableName?: string;
  handleClickAction?: (event: string, mId: string) => void;
}) => {
  return [
    {
      header: t('customer/location'),
      id: 'customer',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const data = cellData.row.original as OnboardingMachine;
        return (
          <div style={{ cursor: 'pointer' }} onClick={() => goTo(data)}>
            {cellData.getValue()}
          </div>
        );
      }
    },
    {
      header: t('machine'),
      id: 'description',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const data = cellData.row.original as OnboardingMachine;
        return (
          <div style={{ cursor: 'pointer' }} onClick={() => goTo(data)}>
            {data.description}
          </div>
        );
      }
    },
    {
      header: t('diagram'),
      id: 'diagrams',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const data = cellData.row.original as OnboardingMachine;
        return makeIndicator(data, data.diagrams || '', theme, goTo);
      }
    },
    {
      header: t('maintenance_schedule'),
      id: 'maintenanceSchedule',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const data = cellData.row.original as OnboardingMachine;
        return makeIndicator(data, data.maintenanceSchedule || '', theme, goTo);
      }
    },
    {
      header: t('gateway_id'),
      id: 'provisionGateway',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const data = cellData.row.original as OnboardingMachine;
        return makeIndicator(data, data.provisionGateway || '', theme, goTo);
      }
    },
    {
      header: t('tag_list'),
      id: 'tagList',
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const data = cellData.row.original as OnboardingMachine;
        return (
          <TagListDiv>
            <span>{makeIndicator(data, data.tagList || '', theme, goTo)}</span>
          </TagListDiv>
        );
      }
    },
    {
      header: '',
      id: 'ellipsesMenu',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellData: CellContext<TableColumnConfigs, string>) => {
        const record = cellData.row.original as MasterTagList;
        return tableName === 'archive' ? (
          <UnArchiveBtn
            onClick={() => {
              handleClickAction && handleClickAction('unarchive', record.id);
            }}
          >
            {' '}
            <IcoUntrash width="18" /> Unarchive
          </UnArchiveBtn>
        ) : (
          <ActionsButton
            onClick={(event) => {
              if (onOpenActions)
                onOpenActions(event, (record as MasterTagList).id, record as OnboardingMachine);
            }}
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </ActionsButton>
        );
      }
    }
  ];
};

export type SortableColumn =
  | 'customer'
  | 'description'
  | 'diagrams'
  | 'maintenanceSchedule'
  | 'provisionGateway'
  | 'tagList'; //  | 'kpiThreshold'

export interface MachineManagementSortState {
  column: SortableColumn;
  state: SortState;
}

interface MachineManagementTableProps {
  data: OnboardingMachine[];
  isDataLoading?: boolean;
  headerBgColor?: string;
  onOpenActions?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, mtlId: string) => void;
  tableName?: string;
  handleClickAction?: (event: string, mId: string) => void;
}
const defaultSortState: Record<string, SortState> = {
  customer: SortState.ascending,
  description: SortState.unsorted,
  diagrams: SortState.unsorted,
  maintenanceSchedule: SortState.unsorted,
  provisionGateway: SortState.unsorted,
  tagList: SortState.unsorted
};

const MachineManagementTable = ({
  data,
  onOpenActions,
  tableName,
  handleClickAction
}: MachineManagementTableProps): ReactElement => {
  const sortableData: OnboardingMachine[] = useMemo(() => {
    return data.map((row) => {
      return {
        ...row
      } as OnboardingMachine;
    });
  }, [data]);
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation(['mh']);

  const goTo = (onboardingMachine: OnboardingMachine) => {
    history.push({
      pathname: JBTRoutes.onboardingPage.replace(':machineId', onboardingMachine.id),
      state: {
        machineId: onboardingMachine.id,
        description: onboardingMachine.description,
        data: onboardingMachine
      },
      search: `isArchived=${onboardingMachine.isArchived}`
    });
  };
  // const [sortState, setSortState] = useState<Record<string, SortState>>(defaultSortState);
  const sortedData = useSort<OnboardingMachine>(defaultSortState, sortableData);

  return (
    <Root>
      <MachineManagementTableWrapper>
        <NewBaseTable
          columnConfigs={generateColumn({
            goTo,
            theme,
            onOpenActions,
            t,
            tableName,
            handleClickAction
          })}
          sortState={{ id: 'id', desc: true }}
          newTableData={sortedData}
          tdMarginLeft="0.625rem"
          textAlign="left"
          padding=".8rem .5rem"
          isShowGlobalSearch
          customClassName="machine-management-table"
          maxTableHeight={37.8}
        />
      </MachineManagementTableWrapper>
    </Root>
  );
};

export default MachineManagementTable;
