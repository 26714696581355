import styled from 'styled-components';
import theme from 'themes';

// Styling
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.lightGrey1};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100dvh - 60px);
  align-items: center;
`;

const CardContainer = styled.div`
  width: 49.5rem;
  height: fit-content;
  border-radius: 0.625rem;
  text-align: center;
`;

const InnerContent = styled.div`
  padding: 1.75rem;
`;

const ImgBox = styled.img``;
const AccessContentDiv = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ padding }) => padding || '0'};
`;

export { Container, CardContainer, InnerContent, ImgBox, AccessContentDiv };
