import styled from 'styled-components';

export const LineViewWidgetHeader = styled.h2`
  color: #323130 !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  font-size: 1rem !important;
  line-height: 1.172rem !important;
  padding-bottom: 1.25rem !important;
  display: inline !important;
`;

export const LineViewWidgetSubHeader = styled.h2`
  color: #666666 !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  font-size: 1rem !important;
  line-height: 1.172rem !important;
  padding-bottom: 1.25rem !important;
  display: inline !important;
`;

export const LineViewWidgetNoData = styled.div`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  p {
    padding: 1.293rem !important;
    margin: 0 !important;
    font-weight: 600 !important;
    color: #828485 !important;
    font-size: 1rem !important;
  }
`;
