import styled from 'styled-components';
import { styledTheme } from '../';
import theme from 'themes';

interface UiGridTableProps {
  /** used to set grid-template-columns: '1fr auto auto auto' */
  colSizes: string;
}

export const ParentContainer = styled.div`
  min-height: 12rem;
  max-height: 12rem;
  margin-bottom: 1.75rem;
  overflow-y: auto;
`;

export const ParentTagTableRow = styled.tr`
  td:nth-child(2) {
    min-width: fit-content;
  }
  line-height: 1.125rem;
`;
export const UiGridTable = styled.table<UiGridTableProps>`
  width: 100%;

  .ui-grid-header,
  .ui-cell {
    padding-right: 0.7em;
    color: ${theme.colors.lightGrey8};
    font-size: 0.875rem;
  }

  .ui-col-title {
    font-weight: 500;
    font-size: 1.1em;
  }

  .ui-label {
    text-transform: capitalize;
    word-break: break-word;
    font-weight: 600;
    line-height: 1.026rem;
    color: ${theme.colors.lightGrey8};
  }

  .ui-no-data {
    border-width: 1px;
    border-style: dashed;
    text-align: center;

    border-radius: ${styledTheme.radius.sm};
    border-color: ${styledTheme.color.status.error.base};
    color: ${styledTheme.color.status.error.base};
    background-color: ${styledTheme.color.status.error.light};
    padding: ${styledTheme.space.base};
  }
`;
