import React, { useEffect, useRef, useState } from 'react';
import { IcoCalendar } from 'icons/IcoCalendar';
import { DateRangeProps } from '..';
import { format } from 'date-fns';
import { DateRangeButtonProps } from '../DateRange/DateRangeButton/DateRangeButton.types';
import { DateRangeButtonContainer } from '../DateRangeV1/DateRangeButton/DateRangeButton.elements';
import { utcToZonedTime } from 'date-fns-tz';
import { getTzOffset } from '../DateRange/hooks/useDateRangeV2';

const TIMER_INTERVAL = 1000;

interface FormatDateRangeProps extends DateRangeProps {
  dateFormat?: string;
  isUSTimeZone?: boolean;
  timeZone?: string;
}

type IntervalID = ReturnType<typeof setInterval>;

const formatDateRange = ({
  startTime,
  endTime,
  dateFormat,
  isUSTimeZone
}: FormatDateRangeProps) => {
  let displayFormat = 'MM/dd/yyyy HH:mm';
  if (isUSTimeZone) displayFormat = 'MM/dd/yyyy h:mm a';
  if (dateFormat === 'date-only') displayFormat = 'MM/dd/yyyy';

  return [format(startTime, displayFormat), format(endTime, displayFormat)];
};

export const DisplayDateRangeButton = ({
  dateFormat,
  dateRange,
  handleClick,
  iconOnly,
  className,
  ga,
  align,
  placeholder,
  isUSTimeZone,
  timeZone
}: DateRangeButtonProps): JSX.Element => {
  className = className ? `ui-display-date-range-btn ${className}` : `ui-display-date-range-btn`;

  const timezone = timeZone || 'UTC';
  const offsetString = getTzOffset(timezone);
  const offsetStringUser = getTzOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

  // Ref for interval, so we don't create new interval each time
  const intervalRef = useRef<IntervalID>();

  const [dates, setDates] = useState({
    startDate: dateRange?.startTime,
    endDate: dateRange?.endTime
  });

  const checkAndUpdateDates = () => {
    setDates((prevDates) => {
      const start = new Date(prevDates.startDate.getTime() + TIMER_INTERVAL);
      const end = new Date(prevDates.endDate.getTime() + TIMER_INTERVAL);
      return { startDate: start, endDate: end };

      // const machineTimeEnd = new Date(
      //   format(prevDates.endDate, 'yyyy-MM-dd HH:mm:ss') + offsetString
      // );
      // const machineTimeEnd2 = utcToZonedTime(machineTimeEnd, 'UTC');

      // const nowLocal = new Date(format(new Date(), 'yyyy-MM-dd HH:mm:ss') + offsetStringUser);
      // const nowUTC = utcToZonedTime(nowLocal, 'UTC');

      // const difference = Math.abs(nowUTC.getTime() - machineTimeEnd2.getTime()) > 3 * 60 * 1000;

      // if (!difference) {
      //   const start = new Date(prevDates.startDate.getTime() + TIMER_INTERVAL);
      //   const end = new Date(prevDates.endDate.getTime() + TIMER_INTERVAL);
      //   return { startDate: start, endDate: end };
      // } else {
      //   clearInterval(intervalRef.current);
      //   return prevDates;
      // }
    });
  };

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    const startDateObj = dateRange?.startTime;
    const endDateObj = dateRange?.endTime;
    setDates({ startDate: startDateObj, endDate: endDateObj });

    const machineTimeEnd = new Date(format(endDateObj, 'yyyy-MM-dd HH:mm:ss') + offsetString);
    const machineTimeEnd2 = utcToZonedTime(machineTimeEnd, 'UTC');

    const nowLocal = new Date(format(new Date(), 'yyyy-MM-dd HH:mm:ss') + offsetStringUser);
    const nowUTC = utcToZonedTime(nowLocal, 'UTC');

    const difference = Math.abs(nowUTC.getTime() - machineTimeEnd2.getTime()) > 3 * 60 * 1000; //5 min is
    if (!difference) {
      intervalRef.current = setInterval(checkAndUpdateDates, TIMER_INTERVAL);
    }
    return () => clearInterval(intervalRef.current);
  }, [dateRange]);

  const settings = { className, ga, align, iconOnly };

  // generate formated dates for display, first check if they're needed
  const [start, end] = formatDateRange({
    startTime: dates?.startDate,
    endTime: dates?.endDate,
    dateFormat,
    isUSTimeZone,
    timeZone
  });

  const Dates = !iconOnly && (
    <>
      <div className="date-col pad-l">{start}</div>
      <div className="spacer-col">-</div>
      <div className="date-col">{end}</div>
    </>
  );

  return (
    <DateRangeButtonContainer onClick={() => handleClick && handleClick()} {...settings}>
      <IcoCalendar />
      {!placeholder ? Dates : ''}
    </DateRangeButtonContainer>
  );
};
