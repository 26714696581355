import React from 'react';
import styled from 'styled-components';
import adaLogo from '../../../img/adaLogo.svg';
import jbtLogo from '../../../img/JBT logo.svg';
import jbtLogoCompact from '../../../img/OmniBlu-Logo-Compact.svg';
import speaker from '../../../img/speaker.svg';
import phone from '../../../img/PhoneIcon.svg';
import emailIcon from '../../../img/emailIcon.svg';
import ButtonComponent from 'components/Button';
import { useHistory } from 'react-router-dom';

const PageWrapper = styled.div`
  padding: 5rem;
  line-height: 25px;
  h2,
  h3,
  a {
    color: ${(props) => `${props.theme.colors.primaryBlue5}`};
  }
  img {
    vertical-align: middle;
  }
  .text-center {
    margin: 0px auto;
    display: block;
  }
  .d-flex {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding: 30px;
  }
  button {
    width: auto;
    margin: 0px auto;
  }
  ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    line-height: 30px;
    li a {
      color: ${(props) => `${props.theme.colors.primaryBlue5}`};
    }
    img {
      margin-right: 12px;
      color: ${(props) => `${props.theme.colors.primaryBlue5}`};
    }
  }
`;

const AccessibilityPolicy = (): React.ReactElement => {
  const history = useHistory();

  return (
    <>
      <PageWrapper>
        <img src={adaLogo} className="text-center" />
        <div className="d-flex">
          <img src={jbtLogo} /> <img src={jbtLogoCompact} />
        </div>

        <h2>JBT and OmniBlu are committed to accessibility</h2>
        <p>
          JBT and OmniBlu strives to maintain a website that is both accessible to all visitors and
          compliant with the Web Content Accessibility Guidelines (WCAG) put forth by the World Wide
          Web Consortium (W3C). We recognize that accessibility and usability are not always
          possible in every area of the website, or for those visitors using assistive technologies
          and devices. OmniBlu conducts accessibility reviews of its website, tools and remediates
          issues uncovered by these audits. Please be aware that our efforts are ongoing.
        </p>
        <h3>
          <img src={speaker} /> Have feedback or need help?
        </h3>
        <p>
          We welcome your feedback on the accessibility of OmniBlu. If you have specific questions
          or feedback about this site’s accessibility or need assistance using specific features,
          please contact us via telephone at:
        </p>
        <ul>
          <li>
            <img src={phone} />
            <a href="tel:+8005555555">(800)-555-5555</a>
          </li>
          <li>
            <img src={emailIcon} />
            <a href="mailto:accessibility@jbtc.com">accessibility@jbtc.com</a>
          </li>
        </ul>
        <p>
          If you have found an inaccessible area on the site, please specify the web page or element
          and provide any other relevant information to help us locate the problem.
        </p>
        <div>
          In the event a page cannot be made accessible, we will work to make a text version of the
          content available. Please contact us at the information above to request a specific
          electronic format. Please provide us with your contact information, the format you
          require, the web page address and the location of the content. We welcome your questions
          about this accessibility statement and comments on how to improve our website’s
          accessibility.
        </div>
        <p>
          To learn more visit the following{' '}
          <a target="_blank" href="https://adasitecompliance.com/" rel="noreferrer">
            Compliance and Accessibility reporting provide by ADA site compliance.
          </a>
        </p>
        <ButtonComponent borderRadius="48px" variant="primary" onClick={() => history.goBack()}>
          Go Back
        </ButtonComponent>
      </PageWrapper>
    </>
  );
};

export default AccessibilityPolicy;
