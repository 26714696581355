import React, { useState } from 'react';
import {
  Dot,
  FileArea,
  FileInfo,
  FileName,
  FileNameContainer,
  FileSize,
  FileStatus,
  ProgressBar,
  ProgressBarContainer,
  RemoveIcon
} from './File.element';
import { Typography } from 'components';
import { IcoClose2 } from 'icons/IcoClose';
import { isFile } from './FileUploadArea';
import theme from 'themes';
import { Checkmark } from 'icons/checkmark';
import { CustomFile } from 'types/machine-management';
import { IcoExcel, IcoPdf, IcoZip } from 'icons/docsIcons/IcoFileExtension';
import { IcoPhoto } from 'icons/IcoPhoto';
import { FileIcon } from 'icons/docsIcons';
import CustomLoader from 'components/CustomLoader';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IcoCross } from 'icons/IcoCross';
import { useTranslation } from 'react-i18next';

type Props = {
  file: CustomFile;
  signedUrl?: string;
  handleFileDelete?: () => void;
  progress?: number;
  handleFilePreview?: () => void;
};

const bytesToMB = (bytes: number) => {
  if (bytes >= 1024 * 1024) {
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
  } else {
    const kb = bytes / 1024;
    return `${kb.toFixed(2)} KB`;
  }
};
type FileTypeMap = {
  [x: string]: string | JSX.Element;
};
export const getFileIcon = (fileName: string): JSX.Element | string => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase() || '';

  const fileTypeMap: FileTypeMap = {
    jpg: <IcoPhoto width="30" />,
    jpeg: <IcoPhoto width="30" />,
    png: <IcoPhoto width="30" />,
    pdf: <IcoPdf />,
    xls: <IcoExcel />,
    xlsx: <IcoExcel />,
    csv: <IcoExcel />,
    zip: <IcoZip />
    // Add more mappings as needed
  };

  return fileTypeMap[extension] || <FileIcon width="26" />;
};
const FileItem = ({ file, handleFileDelete, progress, handleFilePreview }: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [activeId, setActiveId] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  return (
    <FileInfo>
      <FileArea>
        {getFileIcon(file?.name || '')}
        <FileNameContainer>
          <Typography className="fileName" as="span" variant="stepheading">
            {file?.name}
            <FileSize>{`${file?.size ? bytesToMB(file.size) : ''}`}</FileSize>
          </Typography>
          {isFile(file) ? (
            <ProgressBarContainer>
              <ProgressBar width={`${progress}%`} className="progressBar"></ProgressBar>
            </ProgressBarContainer>
          ) : (
            <FileStatus>
              <Typography className="status" weight="medium" color={theme.colors.green3} mb={0}>
                {Checkmark({ color: theme.colors.green3 })}{' '}
                {file?.isPublished ? 'Published' : 'Done'}
              </Typography>
              <Dot></Dot>
              <Typography
                onClick={handleFilePreview}
                className="status"
                color={theme.colors.primaryBlue5}
                mb={0}
              >
                Preview
              </Typography>
            </FileStatus>
          )}
        </FileNameContainer>
        <Typography variant="stepheading"></Typography>
        <RemoveIcon
          onClick={() => {
            setShowConfirmationModal(true);
          }}
        >
          {activeId === file.id ? (
            <CustomLoader thickness=".2rem" size="1.3rem" />
          ) : (
            <IcoClose2 color="red" />
          )}
        </RemoveIcon>
      </FileArea>
      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          closeModal={() => {
            setActiveId('');
            setShowConfirmationModal(false);
          }}
          title={t('delete_file') || ''}
          headerIcon={IcoCross()}
          handleConfirmation={() => {
            setActiveId(file.id || '');
            setShowConfirmationModal(false);
            handleFileDelete?.();
          }}
        >
          {file?.isPublished ? (
            t(`delete_publish_file_msg`) || ''
          ) : (
            <FileName>
              {t('are_you_sure_to_delete')} <span>&ldquo;{file.name}&ldquo;</span>&nbsp;
              {t('file')}. {t('action_cannot_be_undone')}
            </FileName>
          )}
        </ConfirmationModal>
      )}
    </FileInfo>
  );
};

export default FileItem;
