import React, { ReactElement } from 'react';
import {
  Card,
  CardContainer,
  CardContent,
  Dashboard,
  DeleteIcon,
  Filter,
  Heading,
  LoaderContainer,
  Results
} from '../documentation.elements';
import { Typography } from 'components';
import { getDocIcons } from 'icons/docsIcons';
import { DocsCategory } from 'types';
import CustomLoader from 'components/CustomLoader';
import { useTranslation } from 'react-i18next';
import { ChipItem, ChipList } from 'components/CartFulfillmentDetails/Chip';
import { IcoClose } from 'icons/IcoClose';

type Props = {
  DocsNavItems: DocsCategory[];
  isLoading: boolean;
  machineId?: string;
  handleClick: (category: DocsCategory) => void;
  handleRemove: () => void;
  machineDescription?: string;
  machineDescLoading?: boolean;
};
const LibraryPage = ({
  DocsNavItems,
  isLoading,
  machineId,
  handleClick,
  handleRemove,
  machineDescription,
  machineDescLoading
}: Props): ReactElement => {
  const { t } = useTranslation(['fpns']);

  return (
    <>
      <Heading>
        <Typography size={'1.5rem'} weight="semi-bold">
          {t('library')}
        </Typography>
        {!machineId && (
          <Typography style={{ lineHeight: '1.5rem' }}>{t('library_heading_text')}</Typography>
        )}
      </Heading>
      {machineId && (
        <Filter>
          {machineDescLoading ? (
            <CustomLoader size="2rem" thickness=".3rem" />
          ) : (
            <ChipList width="auto">
              <ChipItem width="auto">{machineDescription}</ChipItem>
              <DeleteIcon onClick={() => handleRemove()}>{IcoClose()}</DeleteIcon>
            </ChipList>
          )}
        </Filter>
      )}
      <Results>
        {t('showings')}{' '}
        <span>
          {DocsNavItems.length ? '01' : '00'}~{DocsNavItems.length}
        </span>{' '}
        {t('of_all')} <span>{DocsNavItems.length}</span> {t('categories')}
      </Results>
      <Dashboard>
        <CardContainer>
          {isLoading ? (
            <LoaderContainer>
              <CustomLoader size="3rem" />
            </LoaderContainer>
          ) : (
            DocsNavItems.map((item) => {
              const Icon = getDocIcons(item.name);
              return (
                <Card onClick={() => handleClick(item)} key={item.id} gridRows={'1fr'}>
                  <CardContent display={'flex'}>
                    <Icon width="40" />
                    <Typography
                      mb={0}
                      size={'1rem'}
                      style={{ textAlign: 'center' }}
                      weight="semi-bold"
                    >
                      {t(item.name)}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })
          )}
        </CardContainer>
      </Dashboard>
    </>
  );
};

export default LibraryPage;
