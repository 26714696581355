import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import AlertQueryBuilder, { ConvertedRuleGroup, LogicGroup2 } from 'components/AlertQueryBuilder';
import { Banner, InformationTooltip, Loader } from 'components';
import { useGetMachineByIdQuery } from 'api';
import { AlertLogicHeader, AlertLogicTitle, Container, HideToggleButton } from './index.elements';
import {
  LogicGroup,
  QueryBuilderRule,
  RuleGroup,
  queryBuilderDesireRule
} from 'types/machine-health/alerts';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import theme from 'themes';
import IcoInfo2 from 'icons/IcoInfo2';

import './AlertLogic.css';
export interface AlertLogicProps {
  onQueryDataChange: (data: queryBuilderDesireRule) => void;
  queryData?: LogicGroup2;
  inputValidationsErrors: boolean;
}

const defaultQuery: ConvertedRuleGroup = {
  id: 'root',
  combinator: 'AND',
  rules: [
    {
      id: 'ruleGroup',
      combinator: 'AND',
      rules: [{ field: '', operator: '=', value: '' }]
    }
  ]
};

const AlertLogic: React.FC<AlertLogicProps> = ({
  onQueryDataChange,
  queryData,
  inputValidationsErrors
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation(['mh']);
  const { machineId } = useFleetMachineAccountData();
  const params: {
    alertId: string;
    machineId: string;
  } = useParams();
  const MACHINE_ID = machineId;
  const { data: currentMachine, isFetching } = useGetMachineByIdQuery(
    MACHINE_ID ? MACHINE_ID : skipToken
  );
  const [isAlertLogicValidation, setIsAlertLogicValidation] = useState(false);

  const handleQueryChange = (data: string) => {
    onQueryDataChange(convertJsonToDesiredFormat(JSON.parse(data)));
  };

  function convertRule(rule: RuleGroup, position: number, parent_logic_group_id: string) {
    const LogicGroup: LogicGroup = {
      logic_operator: rule.combinator.toUpperCase(),
      name: `Logic Group ${position}`,
      position,
      logic_groups: [],
      id: parent_logic_group_id,
      logic_statements: []
    };

    if (rule.rules) {
      rule.rules.forEach((sub_rule, index) => {
        if (sub_rule.rules) {
          LogicGroup.logic_groups.push(convertRule(sub_rule as RuleGroup, index, sub_rule.id));
        } else {
          const ruleValueInString = String(sub_rule.value);
          if (sub_rule.operator === 'BETWEEN' || sub_rule.operator === 'NOT_BETWEEN') {
            const logic_statement_values: {
              value?: string | number;
              value_type?: string;
              business_unit_id?: number | undefined;
              tag_name?: string;
              relativity?: string;
              id?: string;
              logic_statement_id?: string;
            }[] = [];
            const parts = ruleValueInString && ruleValueInString.split(',');
            const [thresholdValue1, tag_id1] = parts && parts[0]?.split('/');
            const [thresholdValue2, tag_id2] = parts && parts[1]?.split('/');
            if (tag_id1 !== 'undefined') {
              logic_statement_values.push({
                tag_name: tag_id1,
                business_unit_id: currentMachine && (currentMachine.businessUnit as number),
                relativity: thresholdValue1 > thresholdValue2 ? 'UPPER' : 'LOWER',
                id: sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.id,
                logic_statement_id:
                  sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.logicStatementId
              });
            } else {
              if (!isNaN(parseFloat(thresholdValue1))) {
                logic_statement_values.push({
                  value: thresholdValue1,
                  value_type: 'FLOAT',
                  relativity: thresholdValue1 > thresholdValue2 ? 'UPPER' : 'LOWER',
                  id: sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.id,
                  logic_statement_id:
                    sub_rule?.statementValuesIds &&
                    sub_rule?.statementValuesIds[0]?.logicStatementId
                });
              }
            }

            if (tag_id2 !== 'undefined') {
              logic_statement_values.push({
                tag_name: tag_id2,
                business_unit_id: currentMachine && (currentMachine.businessUnit as number),
                relativity: thresholdValue2 > thresholdValue1 ? 'UPPER' : 'LOWER',
                id: sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[1]?.id,
                logic_statement_id:
                  sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[1]?.logicStatementId
              });
            } else {
              if (!isNaN(parseFloat(thresholdValue2))) {
                logic_statement_values.push({
                  value: thresholdValue2,
                  value_type: 'FLOAT',
                  relativity: thresholdValue2 > thresholdValue1 ? 'UPPER' : 'LOWER',
                  id: sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[1]?.id,
                  logic_statement_id:
                    sub_rule?.statementValuesIds &&
                    sub_rule?.statementValuesIds[1]?.logicStatementId
                });
              }
            }

            const logicStatement = {
              tag_name: sub_rule.field,
              business_unit_id: currentMachine && (currentMachine.businessUnit as number), // You can set the business_unit_id based on your requirements
              comparison_operator: sub_rule.operator,
              position: index,
              id: sub_rule.id,
              logic_group_id: sub_rule.logicGroupId,
              logic_statement_values: logic_statement_values
            };
            LogicGroup.logic_statements.push(logicStatement);
          } else {
            const logic_statement_values: {
              value?: string | number;
              id?: string | undefined;
              logic_group_id?: string | undefined;
              value_type?: string;
              business_unit_id?: number | undefined;
              tag_name?: string;
              relativity?: string;
              logic_statement_id?: string;
            }[] = [];

            const value_with_tag = ruleValueInString && ruleValueInString.split('/');
            const [threshold_value, tag_id_value] = value_with_tag;

            if (tag_id_value !== 'undefined') {
              logic_statement_values.push({
                tag_name: tag_id_value,
                business_unit_id: currentMachine && (currentMachine.businessUnit as number),
                id: sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.id,
                logic_statement_id:
                  sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.logicStatementId
              });
            } else {
              logic_statement_values.push({
                value: threshold_value,
                value_type: sub_rule.operator === 'CONTAINS' ? 'STRING' : 'FLOAT',
                id: sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.id,
                logic_statement_id:
                  sub_rule?.statementValuesIds && sub_rule?.statementValuesIds[0]?.logicStatementId
              });
            }

            const logicStatement = {
              tag_name: sub_rule.field,
              business_unit_id: currentMachine && (currentMachine.businessUnit as number),
              comparison_operator: sub_rule.operator,
              position: index,
              id: sub_rule.id,
              logic_group_id: sub_rule.logicGroupId,
              logic_statement_values: logic_statement_values
            };
            LogicGroup.logic_statements.push(logicStatement);
          }
        }
      });
    }

    return LogicGroup;
  }

  function convertRuleGroup(ruleGroup: QueryBuilderRule): queryBuilderDesireRule {
    const logicRoot: queryBuilderDesireRule = {
      logic_operator: ruleGroup.combinator,
      name: 'Root Logic Group',
      position: 0,
      id: queryData?.id ? queryData?.id : undefined,
      logic_groups: [],
      logic_statements: []
    };

    ruleGroup.rules.forEach((rule: RuleGroup, index: number) => {
      if (rule.rules) {
        logicRoot.logic_groups.push(convertRule(rule, index, rule.id));
      } else {
        const ruleValueInString = String(rule.value);
        if (rule.operator === 'BETWEEN' || rule.operator === 'NOT_BETWEEN') {
          const logic_statement_values: {
            value?: string | number;
            value_type?: string;
            business_unit_id?: number | undefined;
            tag_name?: string;
            relativity?: string;
            id?: string;
            logic_statement_id?: string;
          }[] = [];
          const parts = ruleValueInString && ruleValueInString.split(',');
          const [thresholdValue1String, tag_id1] = parts && parts[0]?.split('/');
          const [thresholdValue2String, tag_id2] = parts && parts[1]?.split('/');

          let thresholdValue1;
          let thresholdValue2;

          if (!isNaN(thresholdValue1String as unknown as number)) {
            thresholdValue1 = Number(thresholdValue1String);
          } else {
            thresholdValue1 = thresholdValue1String;
          }

          if (!isNaN(thresholdValue2String as unknown as number)) {
            thresholdValue2 = Number(thresholdValue2String);
          } else {
            thresholdValue2 = thresholdValue2String;
          }

          if (tag_id1 !== 'undefined') {
            logic_statement_values.push({
              tag_name: tag_id1,
              business_unit_id: currentMachine && (currentMachine.businessUnit as number),
              relativity: thresholdValue1 > thresholdValue2 ? 'UPPER' : 'LOWER',
              id: rule?.statementValuesIds && rule?.statementValuesIds[0]?.id,
              logic_statement_id:
                rule?.statementValuesIds && rule?.statementValuesIds[0]?.logicStatementId
            });
          } else {
            if (!isNaN(parseFloat(thresholdValue1 as string))) {
              logic_statement_values.push({
                value: thresholdValue1,
                value_type: 'FLOAT',
                relativity: thresholdValue1 > thresholdValue2 ? 'UPPER' : 'LOWER',
                id: rule?.statementValuesIds && rule?.statementValuesIds[0]?.id,
                logic_statement_id:
                  rule?.statementValuesIds && rule?.statementValuesIds[0]?.logicStatementId
              });
            }
          }

          if (tag_id2 !== 'undefined') {
            logic_statement_values.push({
              tag_name: tag_id2,
              business_unit_id: currentMachine && (currentMachine.businessUnit as number),
              relativity: thresholdValue2 > thresholdValue1 ? 'UPPER' : 'LOWER',
              id: rule?.statementValuesIds && rule?.statementValuesIds[1]?.id,
              logic_statement_id:
                rule?.statementValuesIds && rule?.statementValuesIds[1]?.logicStatementId
            });
          } else {
            if (!isNaN(parseFloat(thresholdValue2 as string))) {
              logic_statement_values.push({
                value: thresholdValue2,
                value_type: 'FLOAT',
                relativity: thresholdValue2 > thresholdValue1 ? 'UPPER' : 'LOWER',
                id: rule?.statementValuesIds && rule?.statementValuesIds[1]?.id,
                logic_statement_id:
                  rule?.statementValuesIds && rule?.statementValuesIds[1]?.logicStatementId
              });
            }
          }

          const logicStatement = {
            tag_name: rule.field,
            business_unit_id: currentMachine && (currentMachine.businessUnit as number), // You can set the business_unit_id based on your requirements
            comparison_operator: rule.operator,
            position: index,
            id: rule.id,
            logic_group_id: rule.logicGroupId,
            logic_statement_values: logic_statement_values
          };

          logicRoot.logic_statements.push(logicStatement);
        } else {
          const logic_statement_values: {
            value?: string | number;
            id?: string | undefined;
            logic_group_id?: string | undefined;
            value_type?: string;
            business_unit_id?: number | undefined;
            tag_name?: string;
            relativity?: string;
            logic_statement_id?: string;
          }[] = [];

          const value_with_tag = ruleValueInString && ruleValueInString.split('/');
          const [threshold_value_string, tag_id_value] = value_with_tag;
          let threshold_value;
          if (!isNaN(threshold_value_string as unknown as number)) {
            threshold_value = Number(threshold_value_string);
          } else {
            threshold_value = threshold_value_string;
          }

          if (tag_id_value !== 'undefined') {
            logic_statement_values.push({
              tag_name: tag_id_value,
              business_unit_id: currentMachine && (currentMachine.businessUnit as number),
              id: rule?.statementValuesIds && rule?.statementValuesIds[0]?.id,
              logic_statement_id:
                rule?.statementValuesIds && rule?.statementValuesIds[0]?.logicStatementId
            });
          } else {
            logic_statement_values.push({
              value: threshold_value,
              value_type: rule.operator === 'CONTAINS' ? 'STRING' : 'FLOAT',
              id: rule?.statementValuesIds && rule?.statementValuesIds[0]?.id,
              logic_statement_id:
                rule?.statementValuesIds && rule?.statementValuesIds[0]?.logicStatementId
            });
          }

          const logicStatement = {
            tag_name: rule.field,
            business_unit_id: currentMachine && (currentMachine.businessUnit as number),
            comparison_operator: rule.operator,
            position: index,
            id: rule.id,
            logic_group_id: rule.logicGroupId,
            logic_statement_values: logic_statement_values
          };

          logicRoot.logic_statements.push(logicStatement);
        }
      }
    });
    return logicRoot;
  }

  const convertJsonToDesiredFormat = (jsonData: QueryBuilderRule): queryBuilderDesireRule => {
    const transformedData = convertRuleGroup(jsonData);
    if (
      transformedData.logic_groups.length === 0 &&
      transformedData.logic_statements.length === 0
    ) {
      setIsAlertLogicValidation(true);
    } else {
      setIsAlertLogicValidation(false);
    }

    return transformedData;
  };

  if (isFetching || (!queryData && params.alertId)) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        {inputValidationsErrors && isAlertLogicValidation ? (
          <Banner
            type="warning"
            closable={false}
            message={t('there-must-be-at-least-1-rule-or-group-to-be-able-to-save-the-alert')}
          />
        ) : null}
        <AlertLogicHeader>
          <HideToggleButton onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon
              icon={isOpen ? faChevronDown : faChevronRight}
              color={'#303E47'}
              height={19}
              width={16}
            />
          </HideToggleButton>
          <AlertLogicTitle>{t('alert_logic') as string}</AlertLogicTitle>{' '}
          <div className="required-wrapper">
            <InformationTooltip
              tooltipText={t('alert_logic_tooltip_message') as string}
              placement="top"
              icon={<IcoInfo2 color={theme.colors.primaryBlue5} height={18} width={18} />}
              width="25rem"
              labelStyle={{
                fontSize: '14px',
                lineHeight: 1.2,
                color: theme.colors.text.lightBlack
              }}
            />
          </div>
        </AlertLogicHeader>
        {isOpen ? (
          queryData ? (
            <AlertQueryBuilder
              onDataChange={handleQueryChange}
              queryData={params.alertId ? queryData : defaultQuery}
              inputValidationsErrors={inputValidationsErrors}
            />
          ) : (
            <AlertQueryBuilder
              onDataChange={handleQueryChange}
              queryData={defaultQuery}
              inputValidationsErrors={inputValidationsErrors}
            />
          )
        ) : null}
      </Container>
    </>
  );
};

export default AlertLogic;
