// 3rd party libs
import { Button, Input, Typography } from 'components';
import React, { ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';

// Components
import { ChangeEvent } from 'types';
import { MaintenanceEvent, MaintenanceEventTableRow, MaintenanceTaskType } from 'types/maintenance';
import { ButtonContainer, RightBtnContainer } from '..';
import {
  formatDuration,
  isAlphaNumeric,
  isValidEmail,
  markTaskCompleteOrIncompleteByType,
  toISO8601
} from 'helpers';
import { useUpdateMaintenanceEventsMutation } from 'api';
import { AnalyticsCategories, MaintenanceAnalyticEventActions } from 'constants/analytics';
import { generateAnalyticsEvent } from 'helpers/analytics';
import { IcoCheck } from 'icons/IcoCheck';
import { ToastMsg } from 'common/components/Toast/Toast';

interface AssignAndScheduleEventProps {
  maintenanceEvent: MaintenanceEventTableRow;
  onSubmitClick: () => void;
  machineDescription?: string | undefined;
}

// Styling
const Container = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
  position: relative;
`;
export const ErrorMessage = styled.div`
  font-size: 0.75rem;
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.errorRed};
`;

const AssignAndScheduleEvent = ({
  maintenanceEvent,
  onSubmitClick,
  machineDescription
}: AssignAndScheduleEventProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation(['fpns']);
  const [localMaintenanceEvent, setLocalMaintenanceEvent] =
    useState<MaintenanceEvent>(maintenanceEvent);
  const [isValidAssigned, setIsValidAssigned] = useState<boolean>(true);
  const [updateMaintenanceEvent] = useUpdateMaintenanceEventsMutation();
  const copyPMInfo = (pm: MaintenanceEvent, propName: string, value: string | Date) => {
    setLocalMaintenanceEvent({ ...pm, [propName]: value } as MaintenanceEvent);
  };
  type propNames = 'owner' | 'scheduledDate';
  const handleChange = (value: string | Date, propName: propNames) => {
    copyPMInfo(localMaintenanceEvent, propName, value);
  };

  const handleDayChange = (day: Date) => {
    copyPMInfo(localMaintenanceEvent, 'scheduled', toISO8601(day));
  };
  const today = new Date().setHours(0, 0, 0, 0);
  const isValidDate = new Date(localMaintenanceEvent.scheduled || '') >= new Date(today);

  const isValid = (): boolean => {
    if (maintenanceEvent === undefined) {
      return false;
    }
    const valid = Boolean(
      localMaintenanceEvent.owner && localMaintenanceEvent.scheduled && isValidDate
    );
    return valid;
  };
  const isValidAssingedTo = (value: string): boolean => {
    return (isAlphaNumeric(value) || isValidEmail(value)) && value.length <= 75;
  };
  return (
    <Container>
      <Typography as="h4" mb={'1rem'} size="1.3rem" weight="bold">
        {t('assign_and_schedule')}
      </Typography>
      <Typography size="1rem" weight="semi-bold">
        {t('estimated_labor')} &nbsp;:&nbsp;&nbsp;
        {localMaintenanceEvent?.estimatedCompletionTime
          ? `${formatDuration(
              localMaintenanceEvent.estimatedCompletionTime * 1000,
              'hours:mins:secs'
            )} ` + t('mins')
          : t('unavailable')}
      </Typography>
      <div>
        <Typography mb={'.5rem'} weight="semi-bold" size="1rem">
          {t('assigned_to')}:
        </Typography>
        <Input
          type="string"
          id="assignedTo"
          variant="white-dark"
          onChange={(event: ChangeEvent) => {
            if (event.target.value !== ' ' && isValidAssingedTo(event.target.value)) {
              setIsValidAssigned(true);
              handleChange(event.target.value as string, 'owner');
            } else {
              setIsValidAssigned(false);
            }
          }}
          placeholder="Enter Alphanumeric values or a valid email"
        />{' '}
        {!isValidAssigned && (
          <ErrorMessage>{'Enter Alphanumeric values or a valid email'}</ErrorMessage>
        )}
        <br /> <br />
        <Typography mb={'.5rem'} weight="semi-bold" size="1rem">
          {t('scheduled_completion_date')}:
        </Typography>
        <Input
          type="date"
          max="9999-12-31"
          onChange={(e: ChangeEvent) => {
            handleDayChange(e.target.value as unknown as Date);
          }}
          min={new Date().toISOString().split('T')[0]}
        />
        {!isValidDate && <ErrorMessage>{`Date must be today's or later`}</ErrorMessage>}
      </div>
      <RightBtnContainer margin="1rem 0">
        <ButtonContainer>
          <Button
            variant={'text'}
            color={isValid() ? theme.colors.primaryBlue5 : theme.colors.lightGrey9}
            onClick={() => {
              // todo: error handling
              const updatedEvent = markTaskCompleteOrIncompleteByType(
                MaintenanceTaskType.AssignAndSchedule,
                localMaintenanceEvent
              );

              updateMaintenanceEvent([updatedEvent]).then(() => {
                ToastMsg({
                  message: t('maintenance_assigned', { item: machineDescription }),
                  heading: t('assigned') as string,
                  type: 'info'
                });
                generateAnalyticsEvent({
                  category: AnalyticsCategories.MAINTENANCE,
                  action: MaintenanceAnalyticEventActions.MAINTENANCE_EVENT_ASSIGNED,
                  label: maintenanceEvent?.id
                });
              });
              onSubmitClick();
            }}
            disabled={!isValid()}
          >
            <IcoCheck color={isValid() ? theme.colors.primaryBlue5 : theme.colors.lightGrey9} />
            {t('save', { ns: 'common' })}
          </Button>
        </ButtonContainer>
      </RightBtnContainer>
    </Container>
  );
};

export default AssignAndScheduleEvent;
