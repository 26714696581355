import React from 'react';

export const WaitTime = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8632_19649)">
        <path
          d="M5.6001 16.7604H22.4001V5.64551H5.6001V25.4053"
          stroke="#B62C10"
          strokeWidth="0.840002"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_8632_19649)">
          <path
            d="M17.6361 11.1999C17.6361 10.4731 17.4206 9.76254 17.0168 9.15819C16.613 8.55384 16.039 8.0828 15.3675 7.80465C14.696 7.52649 13.9571 7.45372 13.2442 7.59552C12.5313 7.73732 11.8765 8.08733 11.3625 8.60129C10.8486 9.11525 10.4985 9.77008 10.3567 10.483C10.2149 11.1958 10.2877 11.9348 10.5659 12.6063C10.844 13.2778 11.3151 13.8518 11.9194 14.2556C12.5238 14.6594 13.2343 14.8749 13.9611 14.8749"
            stroke="#B62C10"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9609 9.15869V11.2004L14.3693 11.6087"
            stroke="#B62C10"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.5945 12.8335H18.0445V15.2835H15.5945V12.8335Z"
            stroke="#B62C10"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8632_19649">
          <rect width="28" height="28.2283" fill="white" />
        </clipPath>
        <clipPath id="clip1_8632_19649">
          <rect width="9.8" height="9.80004" fill="white" transform="translate(9.06104 6.2998)" />
        </clipPath>
      </defs>
    </svg>
  );
};
