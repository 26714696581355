import React, { useEffect, useRef, useState } from 'react';
import { MachineOverviewWidget } from 'components/StyledUi/widgets/MachineOverviewWidget';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetMachineWidgetOverviewDataQuery } from 'api';
import moment from 'moment-timezone';
import { toISO8601 } from 'helpers';
// these will be needed when pascal is added to BE
//import { useGetMachineAssetsQuery } from 'api';
//import { ResourceType } from 'types';

import MachineImage from '../../assets/avure-temp-machine-image.jpg';
import { DotsLoadingIcon } from './LoadingIcon';
import { utcToZonedTime } from 'date-fns-tz';
import { parseISO, format } from 'date-fns';

interface DateTimeRange {
  startTime: string;
  endTime: string;
}
export const FleetMachineOverviewWidget = (): JSX.Element => {
  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
    startTime: '',
    endTime: ''
  });
  const { t } = useTranslation(['mh']);
  const { machineId } = useParams<{ machineId: string }>();
  const { accountData, isLoading: isLoadingAccountData } = useFleetMachineAccountData();

  useEffect(() => {
    const defStart = toISO8601(moment().subtract(1, 'hour').toDate());
    const defEnd = toISO8601(moment().toDate());
    const startDateTime = format(
      utcToZonedTime(parseISO(defStart), defStart.substring(19)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const endDateTime = format(
      utcToZonedTime(parseISO(defEnd), defEnd.substring(19)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    setDateTimeRange({ ...dateTimeRange, startTime: startDateTime, endTime: endDateTime });
  }, [dateTimeRange.startTime]);

  const { data, isLoading } = useGetMachineWidgetOverviewDataQuery({
    machineId: machineId as string,
    startDatetime: dateTimeRange.startTime,
    endDatetime: dateTimeRange.endTime
  });
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [isLoading, isLoadingAccountData]);

  const imagePath = MachineImage;

  let totalBatches = <p>{t('no_cycles_last_hour')}</p>;
  let totalAverageWaitTime = <p>{t('no_cycles_last_hour')}</p>;

  if (data && data?.length > 0) {
    if (data[0].processedCycles || data[0].reProcessedCycles) {
      totalBatches = (
        <p>
          <span>
            {data[0].processedCycles}/{data[0].reProcessedCycles + data[0].processedCycles}
          </span>{' '}
          in the last hour
        </p>
      );
    }
    if (data[0]?.waitingTime as number) {
      totalAverageWaitTime = (
        <p>
          <span>{Math.floor(data[0].waitingTime / 60)}</span> min in the last hour
        </p>
      );
    }
  }

  return (
    <MachineOverviewWidget
      {...{
        title: accountData?.description,
        isLoading: isFirstRender.current ? isLoading || isLoadingAccountData : false,
        imagePath,
        tooltipText: 'Edit',
        contentRows: [
          {
            label: t('processed_cycles') as string,
            value: isLoading ? <DotsLoadingIcon /> : totalBatches
          },
          {
            label: t('waiting_time') as string,
            value: isLoading ? <DotsLoadingIcon /> : totalAverageWaitTime
          }
        ]
        // handleHeaderIconClick: () => console.log('modal handle would be here')
      }}
    />
  );
};
