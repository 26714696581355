import React from 'react';
import { CardContainer, EmptyData, Heading } from '../documentation.elements';
import { Typography } from 'components';
import DocumentCardComponent from 'common/components/DocumentCardComponent';
import { useTranslation } from 'react-i18next';
import { useSearchDocumentQuery } from 'api';
import { IcoNoFiles } from 'icons/IcoNoFiles';
import { CenterAlign } from 'common/components/common-styled';
type Props = {
  totalBookmark: number;
};
const SearchPage = ({ totalBookmark }: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const searchQuery = new URLSearchParams(location.search);
  const docSearchQuery = searchQuery.get('query');

  const { data } = useSearchDocumentQuery({ query: docSearchQuery || '' });

  return (
    <>
      <Heading>
        <Typography weight="semi-bold" size={'1.25rem'}>
          {t('search_results')}
        </Typography>
      </Heading>

      <CardContainer reducedHeight="25rem">
        {data?.documentSearchDetails.length ? (
          data?.documentSearchDetails?.map((doc) => {
            return <DocumentCardComponent totalBookmark={totalBookmark} documentDetail={doc} />;
          })
        ) : (
          <CenterAlign>
            <EmptyData>
              <IcoNoFiles className="emptyIcon" />
              <Typography weight="semi-bold">{t('no_file_to_show')} </Typography>
            </EmptyData>
          </CenterAlign>
        )}
      </CardContainer>
    </>
  );
};

export default SearchPage;
