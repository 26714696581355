import React from 'react';
import styled from 'styled-components';

// Example of props:
// const settings = [
//     {
//         name: 'Ideal Pressurization Time',
//         keyName: 'idealPressurizeTime'       //key name is used to look up color value
//     },
//     {
//         name: 'Actual Pressurization Time',
//         keyName: 'pressurizeTime'            //key name is used to look up color value
//     }
// ]

// const colorMap = {
//     'pressurization': '#ffffff'
// }

const LabelList = styled.ul`
  padding: 0;
  margin: 0.65rem 0;
  list-style: none;
  display: flex;
`;

const LabelItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0 1%;

  .label-color {
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 8px;
  }

  .label-name {
    padding: 0 0.45rem;
    margin: 0;
  }
`;

interface SettingsProps {
  name: string;
  keyName: string;
}

interface Props {
  settings: SettingsProps[];
  colorMap: Record<string, unknown>[];
  className?: string;
}

export const DotsChartLabelGenerator = ({ settings, colorMap, className }: Props): JSX.Element => {
  className = className ? className + ' dots-chart-labels' : 'dots-chart-labels';

  const items = settings.map(({ name, keyName }, i) => {
    return (
      <LabelItem key={i} className="label-item">
        <span className="label-color" style={{ background: `${colorMap[keyName]}` }}></span>
        <p className="label-name">{name}</p>
      </LabelItem>
    );
  });

  return <LabelList className={className}>{items}</LabelList>;
};
