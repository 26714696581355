// colors will be an object who's keys are the formatted string values of the group/category/state names as they appear in the interface.

import theme from 'common/theme';

export const colors: Record<string, string> = {
  'no product': theme.colors.warning,
  offline: theme.colors.offline,
  'not running': theme.colors.notRunning,
  running: theme.colors.running,
  portions: theme.colors.orange1,
  nuggets: theme.colors.jbtBlue,
  'cut weight scan': theme.colors.Telemetry1800,
  'product output': theme.colors.jbtBlue
};
