import { format } from 'date-fns';
import { secondsToHms } from 'common/pages/fleetV2/machine/aseptic/views/MachineHealth/components/ActualDurationWidget/utils';
import { colors } from 'common/pages/fleetV2/settings/dsi';
import { getUserTimeZone, isUSTimeZone } from 'helpers';

export const API_INTERVAL = 1 * 60 * 1000; // 1 minute interval

export const legendSettings = [
  {
    label: 'No Product',
    color: colors['no product'],
    descr: 'No Product'
  },
  {
    label: 'Offline',
    color: colors['offline'],
    descr: 'Offline'
  },
  {
    label: 'Not Running',
    color: colors['not running'],
    descr: 'Not Running'
  },
  {
    label: 'Running',
    color: colors['running'],
    descr: 'Running'
  }
];

export const displayTooltip = (tooltipData: Record<string, unknown>): string => {
  const data = tooltipData.data;

  const { start_time, end_time, mode_descr, duration } = data as {
    start_time: Date;
    end_time: Date;
    mode_descr: string;
    duration: number;
  };

  const isUSA = isUSTimeZone(getUserTimeZone()); //Affects time formatting AM/PM vs 14:00

  const startDate = format(start_time, 'MMM do, y');
  const endDate = format(end_time, 'MMM do, y');

  const formattedDate =
    startDate === endDate
      ? startDate
      : `${startDate.split(',')[0]} - ${endDate.split(',')[0]}, ${startDate.split(',')[1]}`;

  const startTime = format(start_time, isUSA ? 'hh:mm a' : 'HH:mm');
  const endTime = format(end_time, isUSA ? 'hh:mm a' : 'HH:mm');

  return `<div class="tooltip--inner">
              <div class='tooltip--header'>${formattedDate}</div>
              <div class='tooltip--subheader'>
                <div class='tooltip--subheader time'>Time - &nbsp;<b>${startTime}-${endTime}</b></div>
                <div class='tooltip--subheader duration'>Duration - &nbsp;<b>${secondsToHms(
                  duration / 1000,
                  false
                )}</b></div>
              </div>
              <div class='tooltip--line-item'>
                <span class='color-indicator' style='background-color: ${
                  colors[mode_descr.toLowerCase()]
                }'></span>
                ${mode_descr}
              </div>
            </div>`;
};
