import React, { ReactNode } from 'react';
import { ProgressBar, ProgressBarProps } from 'common/ui';
import { SplitListContainer } from 'common/ui/Lists';
import { useTranslation } from 'react-i18next';
import { KPIProgressTileContainer } from './KPIProgressTile.elements';
import { DataLoader } from '../DataLoader/DataLoader';

export interface KPIProgressTileRowProps {
  /** left col string or jsx element */
  left?: ReactNode;
  /** left col string or jsx element */
  label?: string;
  /** appears next to number value */
  unit?: ReactNode;
  /** right col string or jsx element */
  value?: ReactNode;
  /** right col string or jsx element */
  right?: ReactNode;
  /** full width row with added class */
  headline?: ReactNode;
  /** index of the row */
  rowIndex?: number;
  /** fallback */
  [key: string]: unknown;
  valueKey?: string;
  labelKey?: string;
  unitKey?: string;
}

export interface KPIProgressTileBaseProps {
  kpiRows?: KPIProgressTileRowProps[];
  labelKey?: string;
  valueKey?: string;
  unitKey?: string;
}

export interface KPIProgressTileProps extends KPIProgressTileBaseProps, ProgressBarProps {
  className?: string;
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  labelKey?: string;
  valueKey?: string;
  unitKey?: string;
}

/** need to setup for headlines, this is a simple application for right now */
const Item = ({
  left,
  right,
  headline,
  rowIndex,
  labelKey,
  valueKey,
  unitKey,
  ...rest
}: KPIProgressTileRowProps): JSX.Element => {
  const className = rowIndex ? `split-list-row--${rowIndex}` : undefined;

  const { t } = useTranslation(['mh']);

  labelKey = labelKey || 'label';
  valueKey = valueKey || 'value';
  unitKey = unitKey || 'unit';

  let label = rest?.[labelKey as string];
  let unit = rest?.[unitKey as string];
  let value = rest?.[valueKey as string];

  if (unit !== '%') unit = ` ${unit}`;
  if (typeof value === 'number') value = value.toLocaleString();

  left = left || label;
  right = right || value;

  if (right === 0 || right === undefined) right = '0';

  if (!left && !right && !headline) return <></>;
  if (typeof label === 'string') label = t(label) as string;
  if (typeof headline === 'string') headline = t(headline) as string;

  return (
    <>
      {headline && <dt className={`split-list-headline ${className}`}>{headline}</dt>}
      {left && <dt className={className}>{left}</dt>}
      {right && (
        <dd className={className}>
          {String(right)}
          {unit && (
            <span className="kpi-unit">
              {unit !== '%' ? <>&nbsp;</> : ``}
              {unit}
            </span>
          )}
        </dd>
      )}
    </>
  );
};

interface Props extends KPIProgressTileProps {
  children?: ReactNode;
}

export const KPIProgressTile = ({
  kpiRows,
  className,
  isLoading,
  hasError,
  hasMessage,
  labelKey,
  valueKey,
  children,
  ...progressBarProps
}: Props): JSX.Element => {
  className = `kpi-progress-tile ${className}`.trim();

  const loaded = !isLoading && !hasError && !hasMessage;

  return (
    <KPIProgressTileContainer className={className}>
      <DataLoader {...{ isLoading, hasError, hasMessage }}>
        {loaded && (
          <>
            <ProgressBar {...progressBarProps} />
            {kpiRows && (
              <div className="kpi-area">
                <SplitListContainer className="split-list">
                  {kpiRows.map((row, i) => {
                    return <Item key={`splitListRow${i}`} {...{ labelKey, valueKey, ...row }} />;
                  })}
                </SplitListContainer>
              </div>
            )}
          </>
        )}
      </DataLoader>
      {children}
    </KPIProgressTileContainer>
  );
};
