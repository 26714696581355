import { colors } from 'common/pages/fleetV2/machine/aseptic/settings/colors';
import styled, { css } from 'styled-components';

function createCSS() {
  let styles = '';
  for (let i = 0; i < 100; i += 1) {
    styles += `
            &.percentage-${i}:after {
                width: calc(${i} * 1%);
            }
           `;
  }
  return css`
    ${styles}
  `;
}

export const BarContainer = styled.div`
  img {
    width: 25px;
    height: 25px;
  }

  &.bar-indicator--bars {
    display: flex;
    font-size: 0.85rem;
    width: 100%;
    //padding-left: 0.35rem;

    .bar-indicator--icon {
      align-self: flex-start;
      padding: 0.25rem;
      padding-left: 0;
    }

    .bar-inner {
      padding-left: 0.25rem;
      width: 100%;
    }

    .bar-indicator-wrap {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      height: 24px;
    }

    .bar-indicator--name {
      color: ${colors.actualDurationTitle};
      font-family: Roboto;
      font-weight: 600;
      margin: 0.25rem;
      margin-left: 0;

      small {
        color: ${colors.actualDurationSubText};
        font-weight: 600;
        font-size: 0.85rem;
      }
    }

    .bar-indicator--extra-data {
      margin: 0;
      color: ${colors.actualDurationExtraText};
      font-weight: 600;
      font-size: 0.75rem;
    }

    dd .text {
      color: #000000;
    }

    dl {
      display: flex;
      flex-direction: column;
      //width: 100%;
      height: 34px;
      margin: 0;
      margin-top: -9px;
    }

    dt {
      display: block;
      align-self: flex-start;
      text-align: center;
      width: 100%;
    }
  }
`;

export const ActualDuration = styled.div`
  &.machine-states-duration,
  &.lane-states-duration {
    grid-area: machine-states-duration;
    padding-right: 1.5rem;
    border-right: 1px solid #ededed;
    box-sizing: border-box;

    h3 {
      font-size: 1.1em;
      font-weight: 500;
    }
  }
`;

interface Props {
  color: string;
}

export const DL = styled.dl<Props>`
  .percentage: after {
    background-color: ${({ color }) => color};
  }
`;

export const DD = styled.dd`
  &.percentage {
    height: 12px;
    margin: 0;
    margin-bottom: 0.25rem;

    &:after,
    &:before {
      border-radius: 3px;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
      transition: width 0.5s linear;
    }

    &:before {
      content: '';
      top: 12px;
      display: block;
      background-color: #f2f3f4;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
    }

    .text {
      position: relative;
      font-weight: 500;
      padding-right: 0.35rem;
      width: 100%;
      display: block;
      text-align: right;
      z-index: 9;
    }
  }

  ${createCSS()};
`;
