import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

//Components
import { RadioButton, Typography } from 'components';
import { Dropdown } from './Dropdown';
import {
  CheckboxWrapper,
  ErrorBorderWrapper,
  InputWrapper,
  RequiredMessageWrapper,
  ToggleInput
} from './index.elements';
import { AlertEnumTypes, TTrigger } from 'types/machine-health/alerts';
import Asterisk from './Asterisk ';
import theme, { themeColors } from 'themes';
import { IcoError } from 'icons/IcoError';

interface TriggerAlertProps {
  classname?: string;
  label?: string;
  handleUpdate?: (val: string) => void;
  alertFrequencyUnits: AlertEnumTypes['alertFrequencyUnits'];
  alertTriggerTypes: AlertEnumTypes['triggerType'];
  onDropdownChange: (key: string, value: string | number) => void;
  triggerValues: TTrigger;
  inputValidationsErrors: boolean;
}

const TriggerAlert = ({
  classname,
  label,
  alertFrequencyUnits,
  alertTriggerTypes,
  onDropdownChange,
  triggerValues,
  inputValidationsErrors
}: TriggerAlertProps): JSX.Element => {
  const { t } = useTranslation(['mh']);
  const [isError, setIsError] = useState<{
    status: boolean;
    message: string;
    selectedTriggerType: string | undefined;
  }>({
    status: false,
    message: '',
    selectedTriggerType: triggerValues.type
  });

  const onDropdownValueChange = (val: string) => {
    onDropdownChange('units', val);
  };

  const onBlurHandle = () => {
    if (Number(triggerValues.value) === 0 || !triggerValues.value) {
      (triggerValues.value as unknown as string) === '' || !triggerValues.value
        ? isError.selectedTriggerType === 'MATCHES'
          ? (isError.message = 'Matches is Required')
          : (isError.message = 'Duration is Required')
        : isError.selectedTriggerType === 'MATCHES'
        ? (isError.message = 'Matches cannot be 0')
        : (isError.message = 'Duration cannot be 0');
      setIsError({ ...isError, status: true });
    } else {
      setIsError({ ...isError, status: false });
    }
  };

  const radios =
    alertTriggerTypes &&
    alertTriggerTypes.length > 0 &&
    alertTriggerTypes.map((item, index) => {
      return (
        <RadioButton
          key={`triggerFormat${index}`}
          id={`triggerFormat${index}`}
          checked={triggerValues.type === item ? true : false}
          label={t(`${item?.toLowerCase()}`) as string}
          labelWeight={400}
          onChange={() => {
            setIsError({ ...isError, status: false, message: '', selectedTriggerType: item });
            return item && onDropdownChange('type', item);
          }}
        />
      );
    });
  const triggerMatchesErrors = inputValidationsErrors
    ? (triggerValues.type === 'MATCHES' && triggerValues?.value === undefined) ||
      (triggerValues?.value as number) < 1
    : false;
  return (
    <div className={`radioInput-container ${classname}`}>
      <div className="radioInput-container--label label">
        <ToggleInput toggleSize={16}>
          <div className="row">
            <CheckboxWrapper>
              <Typography variant="inputlabel">
                <p className="label-fields">
                  {t(`${label}`)} <Asterisk />
                </p>
              </Typography>
            </CheckboxWrapper>
          </div>
        </ToggleInput>
      </div>
      <>
        <div className="radioInput-container--wrapper">
          <div className="radioInput-container--wrapper--input">{radios}</div>
        </div>
        <div className="radioInput-container--wrapper range">
          {triggerValues.type === 'MATCHES' ? (
            <div className="customMatchesBox">
              <InputWrapper
                style={{
                  borderRadius: '0.375rem'
                }}
                error={triggerMatchesErrors}
                className={`${
                  triggerValues.type === 'MATCHES' ? 'matches active cstmMatchInput' : 'matches'
                }`}
              >
                <input
                  type="number"
                  value={triggerValues.value}
                  name="Matches"
                  min={1}
                  placeholder="Value"
                  style={{
                    borderRadius: '0.375rem'
                  }}
                  onBlur={onBlurHandle}
                  className={`${triggerValues.type === 'MATCHES' ? 'matches active' : 'matches'}`}
                  onChange={(e) => onDropdownChange('value', e.target.value)}
                />
              </InputWrapper>
              <>
                {inputValidationsErrors ? (
                  triggerValues.value === undefined || (triggerValues.value as number) < 1 ? (
                    <RequiredMessageWrapper className="label">
                      <IcoError />
                      <span>
                        {triggerValues.value === undefined || (triggerValues.value as number) < 1
                          ? Number(triggerValues.value as number) === 0
                            ? (triggerValues?.value as unknown as string) === ''
                              ? t('please-add-matches')
                              : t('matches-cannot-be-0')
                            : t('please-add-matches')
                          : null}
                      </span>
                    </RequiredMessageWrapper>
                  ) : null
                ) : null}
              </>
            </div>
          ) : null}

          {triggerValues.type === 'DURATION' ? (
            <div>
              <InputWrapper
                className={`${
                  triggerValues.type === 'DURATION' ? 'duration active inputHoverSec' : 'duration'
                }`}
              >
                <input
                  type="number"
                  value={triggerValues.value}
                  min="1"
                  max="1000"
                  placeholder="Value"
                  onBlur={onBlurHandle}
                  name="Duration"
                  className={`${
                    triggerValues.type === 'DURATION' ? 'duration--input active' : 'duration--input'
                  }`}
                  onChange={(e) => onDropdownChange('value', e.target.value)}
                  style={{
                    border: inputValidationsErrors
                      ? triggerValues?.value === undefined || triggerValues?.value < 1
                        ? `0.063rem solid ${theme.colors.text.error}`
                        : `0.063rem solid ${themeColors.lightGrey3}`
                      : `0.063rem solid ${themeColors.lightGrey3}`,
                    borderRadius: '0.375rem 0rem 0rem 0.438rem'
                  }}
                />

                <ErrorBorderWrapper
                  className="customSelectHover"
                  error={inputValidationsErrors && triggerValues.units === undefined}
                  hideLeftBorder={
                    inputValidationsErrors
                      ? triggerValues.value === undefined || triggerValues.value < 1
                      : false
                  }
                >
                  <Dropdown
                    vals={alertFrequencyUnits}
                    onDropdownChange={onDropdownValueChange}
                    value={triggerValues.units}
                  />
                </ErrorBorderWrapper>
              </InputWrapper>
              {inputValidationsErrors ? (
                triggerValues.value === undefined ||
                (triggerValues.value as number) < 1 ||
                triggerValues.units === undefined ? (
                  <RequiredMessageWrapper className="label">
                    <IcoError />
                    <span>
                      {triggerValues.value === undefined || (triggerValues.value as number) < 1
                        ? Number(triggerValues.value as number) === 0
                          ? (triggerValues?.value as unknown as string) === ''
                            ? t('please-add-duration')
                            : t('duration-cannot-be-0')
                          : t('please-add-duration')
                        : t('please-add-duration-unit')}
                    </span>
                  </RequiredMessageWrapper>
                ) : null
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    </div>
  );
};

export default TriggerAlert;
