import { styledTheme } from 'common/theme';
import styled from 'styled-components';

export const ZoomActions = styled.div`
  &.container-zoom {
    display: flex;
    align-items: center;
    margin: 0 3%;

    .tooltip-container {
      cursor: pointer;

      &:hover > .tooltip {
        display: block;
      }

      .tooltip {
        display: none;
        position: absolute;
        top: 0;
        bottom: 20px;
        padding: 0.5rem;
        height: 32px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
        transform: translateX(-46%);

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: calc(50% - 3px);
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: #ffffff transparent transparent transparent;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -11px;
          left: calc(50% - 3px);
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: #d3d3d3 transparent transparent transparent;
        }
      }
    }

    .button.zoom {
      color: ${styledTheme.colors.black};
    }

    .zoom-container .brush {
      overflow: hidden;
    }
  }

  p.zoom {
    display: flex;
    align-items: center;
    padding: 0.4rem;
    margin: 0;
    font-size: 0.87rem;
    height: 30px;

    svg {
      margin-left: 0.2rem;
    }
  }

  button {
    display: flex;
    align-items: center;
    font-size: 0.87rem;
    padding: 0.35rem;
    background-color: #f1f7ff;
    border-color: #0a70ff;
    border: 1px solid;
    border-radius: 0.3rem;
    cursor: pointer;
    border: 0;
    background: none;
    height: 30px;

    svg {
      margin: 0 5px;
      opacity: 1;
    }

    &:disabled svg {
      opacity: 0.3;
    }

    &.button.undo {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
