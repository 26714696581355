import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  fill: 'none'
}))``;

export const IcoLightning = ({ width, color }: { width?: string; color?: string }): JSX.Element => {
  return (
    <Svg {...{ width, color }} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4055_9072)">
        <path
          d="M6.66667 13.8514C6.08641 13.7198 5.52912 13.5021 5.01334 13.2054"
          stroke={color || '#BA4E00'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33333 2.1488C10.6588 2.45152 11.8422 3.19526 12.6898 4.25827C13.5374 5.32128 13.999 6.64057 13.999 8.00014C13.999 9.3597 13.5374 10.679 12.6898 11.742C11.8422 12.805 10.6588 13.5488 9.33333 13.8515"
          stroke={color || '#BA4E00'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.05266 11.3955C2.689 10.8671 2.4128 10.2837 2.23466 9.66748"
          stroke={color || '#BA4E00'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.08267 7.00009C2.18934 6.36676 2.39467 5.76676 2.68267 5.21676L2.79534 5.01343"
          stroke={color || '#BA4E00'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.60466 3.0528C5.22843 2.6234 5.92821 2.31661 6.66666 2.1488"
          stroke={color || '#BA4E00'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00001 6.00012L6.66667 8.00012H9.33334L8.00001 10.0001"
          stroke={color || '#BA4E00'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4055_9072">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
