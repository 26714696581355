// utils/svgToDataUrl.ts

import { svgToDataUrl } from 'helpers';

export const PublishStatusBg = (color = '#29a429'): string => {
  const svgString = `
    <svg width="${326}" height="${82}" viewBox="0 0 326 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.06" d="M316.005 81.8506C314.048 80.6609 312.091 79.4967 310.156 78.3241C263.849 50.8513 208.124 36.6858 152.859 38.2834C100.473 39.8214 50.0442 55.8734 6.56934 81.8336H316.005V81.8506Z" fill="${color}" />
        <path opacity="0.06" d="M514.519 59.5548C463.278 27.5551 403.058 -6.55446 334.218 1.59888C285.641 7.34824 253.675 39.0538 210.934 54.4947C155.413 74.5334 111.895 48.9387 62.2101 31.7242C-4.83403 8.52501 -87.6515 14.8375 -147.423 47.6947C-165.92 57.8737 -183.608 70.6921 -202.016 81.5941H514.519V59.5548Z" fill="${color}" />
        <path opacity="0.06" d="M698.714 38.9682C693.33 38.3686 687.995 38.2757 683.185 38.6135C664.988 39.8381 647.739 46.0621 630.963 52.5141C614.187 58.9661 597.336 65.7644 579.314 68.3317C519.191 76.8781 469.462 38.5206 413.925 26.157C363.598 14.9589 310.279 24.6369 261.423 40.1252C211.694 55.8752 160.344 77.9929 108.646 68.5766C72.253 61.9473 41.7924 40.4883 8.16614 26.3851C-51.4339 1.35386 -114.723 1.91124 -176.417 14.0215V80.9233H698.714V38.9682Z" fill="${color}" />
    </svg>`;
  return svgToDataUrl(svgString);
};
