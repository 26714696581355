import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: 'none'
}))``;

export const IcoDivide = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg
        {...{ width, color }}
        className="icon--pencil icon"
        focusable="true"
        aria-label={srLabel || `edit`}
      >
        <path
          d="M8 2C12.8 2 14 3.2 14 8C14 12.8 12.8 14 8 14C3.2 14 2 12.8 2 8C2 3.2 3.2 2 8 2Z"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip0_2266_61735)">
          <path
            d="M8.15039 6.5C8.28846 6.5 8.40039 6.38807 8.40039 6.25C8.40039 6.11193 8.28846 6 8.15039 6C8.01232 6 7.90039 6.11193 7.90039 6.25C7.90039 6.38807 8.01232 6.5 8.15039 6.5Z"
            fill={color ? color : '#0076CC'}
            stroke={color ? color : '#0076CC'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.15039 10C8.28846 10 8.40039 9.88807 8.40039 9.75C8.40039 9.61193 8.28846 9.5 8.15039 9.5C8.01232 9.5 7.90039 9.61193 7.90039 9.75C7.90039 9.88807 8.01232 10 8.15039 10Z"
            fill={color ? color : '#0076CC'}
            stroke={color ? color : '#0076CC'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 8L10 8"
            stroke={color ? color : '#0076CC'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2266_61735">
            <rect width="12" height="12" fill="white" transform="translate(2 2)" />
          </clipPath>
        </defs>
      </Svg>
    </div>
  );
};
