import { useLocation } from 'react-router';
import { useHistory } from 'react-router';

export type UserRouterHandlerProps = [
  string,
  {
    path?: string;
    route?: string;
  }
];

export const UseHandlePageRouting = (
  type: string,
  paths: string,
  options?: {
    path?: string;
    route?: string;
    pathKey?: string;
  }
): void => {
  const { pathname } = useLocation();

  const slugsArr = pathname.split('/').filter((slug) => slug !== '');
  const pathsArr = paths.split('/').filter((slug) => slug !== '');

  const pathIndexs: Record<string, number> = pathsArr.reduce(
    (acc, path, index) => (acc = { ...acc, [path]: index }),
    {}
  );

  switch (type) {
    case 'updatePath': {
      if (!options?.path || !pathIndexs?.[options.path])
        return console.error('No path provided in handlePageRouting');
      const newSlugsArr = [...slugsArr];
      const slugIndex = pathIndexs[options.path];
      newSlugsArr[slugIndex] = options.route || '';
      return console.log(newSlugsArr);
    }
    default:
      return console.log('No type provided in handlePageRouting');
  }
};

const convertPathToSlugsObj = (paths: string): [Record<string, string>, string] => {
  const { pathname } = useLocation();

  const urlSlugs = pathname.split('/').filter((slug) => slug !== '');
  const basePaths = paths.split('/').filter((slug) => slug !== '');

  const pathIndex: Record<number, string> = basePaths.reduce(
    (acc, path, index) => (acc = { ...acc, [index]: path }),
    {}
  );

  return [
    // retorn key value object where the key is the path and the value is the slug
    urlSlugs.reduce((acc, slug, index) => {
      const pathKey = pathIndex[index];
      return (acc = { ...acc, [pathKey]: slug });
    }, {}),
    pathname
  ];
};

// netter handlers
export const useRouterWithHandlerV2 = (
  paths: string,
  options?: {
    path?: string;
  }
): [Record<string, unknown>, (type: string, options?: Record<string, unknown>) => void] => {
  if (options) console.log('options not used in useRouter hook');

  const [slugs, currentUrl] = convertPathToSlugsObj(paths);

  const history = useHistory();
  const { pathname } = useLocation();

  const handlePageRouting = (
    type: string,
    paths: string,
    options?: Record<string, unknown>
  ): void => {
    const slugsArr: string[] = pathname.split('/').filter((slug) => slug !== '');
    const pathsArr = paths.split('/').filter((slug) => slug !== '');

    const pathIndexs: Record<string, number> = pathsArr.reduce(
      (acc, path, index) => (acc = { ...acc, [path]: index }),
      {}
    );

    switch (type) {
      // updatePath updates only a single path directory in the URL
      case 'update': {
        if (!options) return console.log('missing key val [path]: slug (value)');

        const [path, slug] = Object.entries(options)[0];

        const newSlugsArr = [...slugsArr];
        const slugIndex = pathIndexs?.[path] as number;

        newSlugsArr[slugIndex] = slug as string;
        const newPath = `/${newSlugsArr.join('/')}`;

        return history.push(newPath);
      }
      // this just sets the whole url to the incoming { path: string } option
      case 'setPath': {
        if (!options?.path) return console.log('missing key val [path]: slug (value)');
        const path = options?.path;
        return history.push(path as string);
      }

      default:
        return console.log('No type provided in handlePageRouting');
    }
  };

  const handle = (type: string, options?: Record<string, unknown>) => {
    handlePageRouting(type, paths, options);
  };

  return [{ ...slugs, currentUrl }, handle];
};

export type UseRouterWithHandlerReturnProps = [
  Record<string, unknown>,
  (type: string, options?: Record<string, unknown>) => string
];

export const useRouterWithHandler = (
  paths: string,
  options?: {
    path?: string;
  }
): [Record<string, unknown>, (type: string, options?: Record<string, unknown>) => string] => {
  if (options) console.log('options not used in useRouter hook');

  const [slugs, currentUrl] = convertPathToSlugsObj(paths);

  const history = useHistory();
  const { pathname } = useLocation();

  const handlePageRouting = (
    type: string,
    paths: string,
    options?: Record<string, unknown>
  ): string => {
    const pathUpdater = (options: Record<string, unknown>): string => {
      const slugsArr: string[] = pathname.split('/').filter((slug) => slug !== '');
      const pathsArr = paths.split('/').filter((slug) => slug !== '');

      const pathIndexs: Record<string, number> = pathsArr.reduce(
        (acc, path, index) => (acc = { ...acc, [path]: index }),
        {}
      );

      const [path, slug] = Object.entries(options)[0];
      const newSlugsArr = [...slugsArr];
      const slugIndex = pathIndexs?.[path] as number;

      if (slugsArr[slugIndex] !== slug) {
        newSlugsArr[slugIndex] = slug as string;
      }

      return `/${newSlugsArr.join('/')}`;
    };

    if (!options) {
      console.log('missing key val [path]: slug (value)');
      return '';
    }

    switch (type) {
      case 'update':
      case 'updatePath': {
        if (!options?.path) history.push(pathUpdater(options));
        return '';
      }
      // this just sets the whole url to the incoming { path: string } option
      case 'setPath': {
        if (options?.path) history.push(options?.path as string);
        return '';
      }
      // this will return what the url would look like with update values (used for a links mainly)
      case 'getUpdatedPath': {
        return pathUpdater(options);
      }

      default:
        console.log('No type provided in handlePageRouting');
        return '';
    }
  };

  const handle = (type: string, options?: Record<string, unknown>): string => {
    return handlePageRouting(type, paths, options);
  };

  return [{ ...slugs, currentUrl }, handle];
};

export const useRouter = (
  paths: string,
  options?: Record<string, unknown>
): Record<string, string> => {
  if (options) console.log('options not used in useRouter hook');
  const [slugs, currentUrl] = convertPathToSlugsObj(paths);
  return { ...slugs, currentUrl };
};
