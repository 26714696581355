import styled from 'styled-components';

export const LoaderContainer = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: ${({ padding }) => padding || 'auto'};
`;
export const LoaderElement = styled.div<{ size?: string; color?: string; thickness?: string }>`
  width: ${({ size }) => size || '5rem'};
  height: ${({ size }) => size || '5rem'};
  border: ${({ thickness }) => thickness || '0.3rem'} solid ${({ color }) => color + '40'};
  border-radius: 50%;
  border-top: ${({ thickness }) => thickness || '0.3rem'} solid ${({ color }) => color};
  animation: spin 1.5s linear infinite;
  position: relative;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
