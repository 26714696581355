import React, { ReactNode } from 'react';
import { AsepticChangeoverDetailsApiProvider } from '../hooks/useAsepticChangeoverDetailsApi';
import { ChangeoverStepCategoriesChart } from '../components/ChangeoverStepCategoriesChart/ChangeoverStepCategoriesChart';
import { DateRangeProvider } from 'components';
import { useTimeZone } from 'providers';
import { FilterSelectedProvider } from 'components';

export interface ChangeoverViewProps {
  children?: React.ReactNode | ReactNode[];
}

const Provided = () => {
  return <ChangeoverStepCategoriesChart />;
};

// we're providing data down to the charts here
export const ChangeoverView = ({
  data,
  isLoading,
  hasError
}: {
  data: Record<string, unknown>[];
  isLoading?: boolean;
  hasError?: string;
}): JSX.Element => {
  const { timeZone } = useTimeZone();
  return (
    <DateRangeProvider {...{ timeZone, subtractDaysCount: 30 }}>
      <FilterSelectedProvider>
        <AsepticChangeoverDetailsApiProvider {...{ data, isLoading, hasError }}>
          <Provided />
        </AsepticChangeoverDetailsApiProvider>
      </FilterSelectedProvider>
    </DateRangeProvider>
  );
};
