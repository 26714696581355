import { Button, Modal, Typography } from 'components';
import { IcoClose } from 'icons/IcoClose';

import { CloseIcon } from 'pages/UserManagementTwo/components/User/UpdateUser';
import React from 'react';
import styled from 'styled-components';
import theme from 'themes';
import { ModalSize } from 'types';
const ModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5rem;
  width: 100%;
  gap: 1rem;
`;
const ModalContent = styled.div<{ flexDirection?: string }>`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  padding: 0 1.5rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-left: 2.5rem;
  flex: 1;
`;
const ModalCloseButton = styled.div`
  margin-left: auto;
`;
const StyledButtonRow = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 1.5rem;
  width: 65%;
  margin-left: auto;
  .btn-danger:hover {
    background-color: ${theme.colors.text.error + '10'};
  }
`;
type Props = {
  title?: JSX.Element | string;
  closeModal: () => void;
  showModal: boolean;
  handleConfirmation?: () => void;
  children?: JSX.Element | string;
  headerIcon?: JSX.Element | string;
  primaryBtnText?: string;
  secondaryBtnText?: string;
};
const ConfirmationModal = ({
  title,
  closeModal,
  showModal,
  handleConfirmation,
  children,
  headerIcon,
  secondaryBtnText = 'Cancel',
  primaryBtnText = 'Confirm'
}: Props): JSX.Element => {
  return (
    <Modal
      title={
        <ModalHeaderWrapper>
          {headerIcon}
          <Typography weight="bold" size={'1.25rem'} mb={0}>
            {title}
          </Typography>
          <ModalCloseButton>
            <CloseIcon onClick={closeModal}>{IcoClose()}</CloseIcon>
          </ModalCloseButton>
        </ModalHeaderWrapper>
      }
      allowContentScroll
      onClose={closeModal}
      showCloseButton={false}
      size={ModalSize.SMALL_AUTO_HEIGHT}
      widthOverride="30rem"
      visible={showModal}
    >
      <ModalContent>{children}</ModalContent>
      <StyledButtonRow>
        <Button
          onClick={closeModal}
          variant="link"
          borderRadius="3rem"
          borderColor={theme.colors.text.error}
          color={theme.colors.text.error}
          className="btn-danger"
        >
          {secondaryBtnText}
        </Button>
        <Button
          onClick={handleConfirmation}
          borderColor={theme.colors.text.error}
          variant="constant"
          bgColor={theme.colors.text.error}
          borderRadius="3rem"
          color={theme.colors.white}
        >
          {primaryBtnText}
        </Button>
      </StyledButtonRow>
    </Modal>
  );
};

export default ConfirmationModal;
