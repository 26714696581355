import React, { useEffect, useRef, ElementType } from 'react';
import * as d3 from 'd3';
import { StyledUiContainerProps } from 'components';

export interface ChartAxisWrapperProps extends StyledUiContainerProps {
  formatBottomAxis?: (x?: unknown) => string;
  formatLeftAxis?: (x?: unknown) => string;
  width?: number;
  height?: number;
  leftDomain?: number[];
  bottomDomain?: number[];
  dateRangeDomain?: string[];
  leftTicks?: number;
  bottomTickCount?: number;
  /** this is used to cal */
  children?: React.ReactNode;
  Chart?: (x?: Record<string, unknown>) => JSX.Element;
  axisSize?: number;
  dateRanges?: string[];
  axisMargins?: {
    t?: number;
    r?: number;
    b?: number;
    l?: number;
  };
  SVGComponent?: ElementType;
}

export const ChartAxisWrapper = ({
  formatBottomAxis,
  formatLeftAxis,
  width,
  height,
  dateRangeDomain,
  bottomTickCount,
  //dateRanges,
  Chart,
  axisMargins,
  className,
  SVGComponent
}: //categoryKey,
//groupKey
ChartAxisWrapperProps): JSX.Element => {
  const marginTop = axisMargins?.t || 40;
  const marginRight = axisMargins?.r || 40;
  const marginBottom = axisMargins?.b || 40;
  const marginLeft = axisMargins?.l || 40;

  // set some defaults
  width = width || 700;
  height = height || 300;
  // deconstruct dateRange to get iso timstamps
  const [startIso, endIso] = dateRangeDomain || ['2023-09-07', '2023-09-11'];
  // convert to date objects for D3
  const start = new Date(startIso);
  const end = new Date(endIso);

  // Layout. The div size is set by the given props.
  // The bounds (=area inside the axis) is calculated by substracting the margins
  const axisLeftRef = useRef(null);
  const axisBottomRef = useRef(null);
  const gridLinesRef = useRef(null);

  const dataAreaWidth = width - marginRight - marginLeft;
  const dataAreaHeight = height - marginTop - marginBottom;

  // Y / left axis
  const yScale = d3.scaleLinear().domain([0, 100]).range([dataAreaHeight, 0]);

  // X / bottom axis
  const xTimeScale = d3
    .scaleUtc()
    .domain([start, end])
    .range([0, dataAreaWidth as number]);
  const xCatScale = d3
    .scaleBand()
    .domain([`1`, `2`])
    .range([0, dataAreaWidth as number])
    .padding(0.2);

  useEffect(() => {
    // select the SVG Elements to popuplate
    const axisLeftEl = d3.select(axisLeftRef.current);
    const axisBottomEl = d3.select(axisBottomRef.current);
    const gridLinesEl = d3.select(gridLinesRef.current);

    // we're going to use d3 to add ths axis items
    const yAxisGenerator = d3.axisLeft(yScale).ticks(5);
    const xAxisGenerator = d3
      // .axisBottom(xCatScale || xTimeScale)
      // .ticks(dateRanges || bottomTickCount);
      .axisBottom(xTimeScale)
      .ticks(bottomTickCount);

    // clear the other elements to prevent double rendering
    axisLeftEl.selectAll('*').remove();
    axisBottomEl.selectAll('*').remove();
    gridLinesEl.selectAll('*').remove();

    // left
    if (formatLeftAxis)
      axisLeftEl
        .append('g')
        .attr('class', 'ticks')
        .call(
          yAxisGenerator
            .tickSize(15)
            .tickFormat((label: d3.NumberValue): string => formatLeftAxis(label))
        );
    else
      axisLeftEl
        .append('g')
        .attr('class', 'ticks')
        .call(
          yAxisGenerator.tickSize(15).tickFormat((label: d3.NumberValue): string => String(label))
        );

    // bottom
    if (formatBottomAxis)
      axisBottomEl
        .append('g')
        .attr('class', 'ticks')
        .call(
          xAxisGenerator
            .tickSize(20)
            .tickFormat((label: string | number): string => formatBottomAxis(label))
        );
    else axisBottomEl.append('g').attr('class', 'ticks').call(xAxisGenerator.tickSize(20));

    // grid lines
    gridLinesEl
      .append('g')
      .attr('class', 'lines horz')
      .call(xAxisGenerator.tickSize(dataAreaHeight).tickFormat(() => ''));

    gridLinesEl
      .append('g')
      .attr('class', 'lines vert')
      .attr('transform', `translate(${dataAreaWidth},0)`)
      .call(yAxisGenerator.tickSize(dataAreaWidth).tickFormat(() => ''));
  }, [xTimeScale, xCatScale, yScale, dataAreaHeight, dataAreaWidth]);

  // group defs for pos and populat
  const axisLeftGroup = {
    className: 'chart axis left',
    ref: axisLeftRef,
    width: marginLeft,
    height: dataAreaHeight,
    transform: `translate(${marginLeft}, ${marginTop})`
  };

  const axisBottomGroup = {
    className: 'chart axis bottom',
    ref: axisBottomRef,
    height: marginBottom,
    width: dataAreaWidth,
    transform: `translate(${marginLeft},${dataAreaHeight + marginTop})`
  };

  const gridLinesGroup = {
    className: 'chart grid-lines',
    ref: gridLinesRef,
    height: dataAreaHeight,
    width: dataAreaWidth,
    opacity: 0.1,
    transform: `translate(${marginLeft},${marginTop})`
  };

  const svgSettings = {
    className: className ? `d3-chart-svg ${className}` : `d3-chart-svg`,
    width,
    height
  };

  // check for wrapping svg
  const Wrapper = ({ children }: { children: React.ReactNode }) =>
    SVGComponent ? (
      <SVGComponent {...svgSettings}>{children}</SVGComponent>
    ) : (
      <svg {...svgSettings}>{children}</svg>
    );

  return (
    <Wrapper>
      <g {...gridLinesGroup} />
      <g {...axisLeftGroup} />
      <g {...axisBottomGroup} />
      {Chart && <Chart {...{ width: dataAreaWidth, height: dataAreaHeight, axisMargins }} />}
    </Wrapper>
  );
};
