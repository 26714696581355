import { useGetAlarmsForIntensifiersAsdQuery } from './machineHealthApis';
import { parseISO, format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useFleetMachineAccountData } from '../../hooks/useFleetMachineAcountData';
import { UseAlarmsForIntensifierApiProps } from './useAlarmsForIntensifierApi';
import { useDateRange } from 'components';
import { calculateDataKeyTotals } from 'common/helpers/dataCounters';
import { ReturnedData } from '../components/IntensifierAlarmsWidget/IntensifierAlarmsWidget';

export const useAlarmsForIntensifierApiASD = (
  props?: UseAlarmsForIntensifierApiProps
): ReturnedData => {
  const { isoDateRange } = useDateRange();

  let startTime = props?.startTime;
  let endTime = props?.endTime;
  let machineId = props?.machineId;

  if (!startTime || !endTime || !machineId) {
    startTime = isoDateRange.startTime;
    endTime = isoDateRange.endTime;
    machineId =
      useFleetMachineAccountData().machineId || useParams<{ machineId: string }>().machineId;
  }

  if (!machineId) return {};

  // Format it in the desired format
  const formattedTimestamp = format(parseISO(startTime), 'yyyy-MM-dd HH:mm:ss.SSS');
  const formattedEndstamp = format(parseISO(endTime), 'yyyy-MM-dd HH:mm:ss.SSS');

  const apiCall = useGetAlarmsForIntensifiersAsdQuery({
    machineId,
    startTime: formattedTimestamp,
    endTime: formattedEndstamp
  });

  let total_cycles = 0;
  let total_alarm_count = 0;
  let tableData = undefined;
  let alarmCount = 0;

  // process api call and return tableData
  if (apiCall?.data) {
    // break up api call and set types
    const { cycles, alarms_details } = apiCall.data as unknown as {
      cycles: number;
      alarms_details: Record<string, unknown>[];
    };

    // to avoid errors
    if (alarms_details && cycles) {
      total_alarm_count = alarms_details.length;
      total_cycles = cycles;
      let groupedAlarms: Record<string, Record<string, unknown>> = {};
      // get the alarm totals return in the following format:
      const alarmTotals = calculateDataKeyTotals(alarms_details, 'alarm_id');
      // get the total number of alarms for this period
      alarmCount = alarms_details.length;
      // loop through the alarm details to build tableData
      alarms_details?.forEach((alarm) => {
        // this is all for typescript
        const id = alarm['alarm_id'] as string;
        const typedValObj = alarmTotals[id] as { count: number };
        // the number of times this alarm has occured
        const count = typedValObj.count;

        if (!groupedAlarms[id]) groupedAlarms = { ...groupedAlarms, [id]: {} };

        groupedAlarms[id] = {
          ...groupedAlarms[id],
          ...alarm,
          // the ratio of this alarm to the total number of alarms
          occurences_ratio: (count / alarmCount) * 100,
          occurences: count,
          total_alarm_count: alarms_details.length,
          // the ratio of this alarm to total cycles
          occurences_percentage: (count / cycles) * 100,
          alarm_category: alarm?.alarm_category.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
        };
      });
      // return the tableData as array
      tableData = Object.values(groupedAlarms) as unknown as Record<string, unknown>[];
      //sort by occurences count
      tableData = tableData.sort((a, b) => (a.occurences < b.occurences ? 1 : -1));
    }
  }

  return { tableData, total_alarm_count, total_cycles };
};
