import React from 'react';
import { IssuesContainer, IssuesRow } from './Issues.elements';

interface IssuesProps {
  data: Record<string, unknown>[];
}

export const Issues = ({ data }: IssuesProps): JSX.Element => {
  const rows = data?.map(({ alertIcon, strong, text, timestamp, actionText, action, id }) => {
    return (
      <IssuesRow key={id} className="issues_row">
        <div className="issues_row--1">
          {alertIcon && <img src={alertIcon} alt="alert" />} {timestamp && timestamp}
        </div>
        <div className="issues_row--2">
          <p className="issues_row--strong">{strong && strong}</p>
          <p className="issues_row--text">{text && text}</p>
        </div>
        {action && actionText && <div className="issues_row--3">{actionText}</div>}
      </IssuesRow>
    );
  });

  return <IssuesContainer className="issues_container">{rows}</IssuesContainer>;
};
