// 3rd party libs
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'themes';
import { lowerCase, sortBy } from 'lodash';

// Utils
import {
  addIdProp,
  addKeyProp,
  updateMultiSelect,
  updateSelect,
  updateValidatedUser,
  transformUserColumnOptions
} from 'pages/UserManagementTwo/components/utils';
import { isValidPhoneNumber } from 'libphonenumber-js';
// Types
import {
  BulkUser,
  Group,
  User,
  UserGroup,
  UserManagementTableType,
  UserTypes,
  ValidatedUsersResponse
} from 'types/user-management';

// API
import {
  useGetUserListQuery,
  useGetUserManagementV2Query,
  useUpdateUserStatusMutation,
  useUploadValidateUsersDataMutation,
  useValidateUserEmailAvailableMutation,
  useGetUserManagementGroupByIdV2Query,
  useImportValidatedBulkUsersMutation
} from 'api';
import { usePaginatedQueryOffset } from 'hooks';
import { PAGE_LENGTH } from 'constants/search';
import { useTranslation } from 'react-i18next';
import UserTableButtonWrapper from './UserTableButtonWrapper';
import { TableAction } from 'pages/UserManagementTwo';
import { useDispatch } from 'react-redux';
import { tanStackTableActions } from 'actions/tanStackTable';
import DeleteUser from '../User/DeleteUser';
import ImportUsers from '../User/ImportUsers/Index';
import { ToastMsg } from 'common/components/Toast/Toast';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import { DisplayImportedUsers } from './DisplayImportedUsers';
import { DisplayExistingUsers } from './DisplayExistingUsers';
import { MultiValue } from 'react-select';
import UpdateUser, { CloseIcon, Footer } from '../User/UpdateUser';
import { IcoClose } from 'icons/IcoClose';
import {
  AlertBody,
  StyledButtonRow,
  StyledTitle
} from 'pages/MachineManagement/MachineManagementPopup/MachineImageUploaderStyledComponents';
import { Button, Modal } from 'components';
import { ModalContent } from 'pages/MachineManagement/ReviewAndPublishPage/reviewAndPublish.elements';
import { ModalSize } from 'types';
import IcoWarningNew from 'icons/IcoWarningNew';

interface Props {
  onClickButton: (tableType: UserManagementTableType, id?: string, selectUser?: UserTypes) => void;
  group_id?: string;
  isGroupPage?: boolean;
  setShowImportUserBar?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableImportUserButton?: React.Dispatch<React.SetStateAction<boolean>>;
  importValidatedUsers?: number;
  setIsActiveUserImportMode?: React.Dispatch<React.SetStateAction<boolean>>;
  moveToGroupOnImportMode?: boolean;
  setMoveToGroupOnImportMode?: (option: string) => void;
}

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.lightGrey1};
  border: 0.0625rem solid ${theme.colors.lightGrey3};
  border-top: none;
`;

const ButtonContainer = styled.div`
  position: relative;
  top: 2rem;
`;
const StyledModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5rem;
  width: 100%;
  align-items: flex-start;
`;
const ModalIconWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
`;
const ModalCloseButton = styled.div`
  margin-left: auto;
`;
const defaultUserSortState = {
  id: 'firstName',
  desc: false
};

const UserManagementTable = ({
  onClickButton,
  group_id,
  /* isGroupPage, */
  setShowImportUserBar,
  setDisableImportUserButton,
  importValidatedUsers,
  setIsActiveUserImportMode,
  moveToGroupOnImportMode,
  setMoveToGroupOnImportMode
}: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [groupSearchValue, setGroupSearchValue] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState(group_id);
  const [selectedTableItems, setSelectedTableItems] = useState<UserTypes[]>([]);
  const [userSearchValue, setUserSearchValue] = useState('');
  const [newValidatedUsers, setNewValidatedUsers] = useState<ValidatedUsersResponse[]>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [resetControlCheck, setResetControlCheck] = useState(false);

  // this search state used in group detail page
  // const [searchValue, setSearchValue] = useState('');

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showImportUsersModal, setShowImportUsersModal] = useState<boolean>(false);
  const [isBulkImportMode, setIsBulkImportMode] = useState<boolean>(false);
  const [showExitImportModal, setShowExitImportModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [groupId, setGroupId] = useState('');
  const { onPageChange, pageNumber } = usePaginatedQueryOffset();
  const [pageNumberState, setPageNumber] = useState(pageNumber);
  const { data: result, isFetching: isLoading } = useGetUserListQuery({
    nameSearchQuery: userSearchValue,
    group_id: selectedGroupId,
    limit: PAGE_LENGTH.SMALL,
    offset: pageNumberState
  });

  const { data: groupDetail } = useGetUserManagementGroupByIdV2Query(group_id || '');

  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [uploadUsersFile] = useUploadValidateUsersDataMutation();
  const [importValidateUsers] = useImportValidatedBulkUsersMutation();
  const { data: groupData } = useGetUserManagementV2Query({
    type: lowerCase(UserManagementTableType.GROUP),
    nameSearchQuery: groupSearchValue.trim(),
    groupId,
    limit: PAGE_LENGTH.SMALL,
    offset: pageNumberState
  });
  const [validateUserEmail] = useValidateUserEmailAvailableMutation();
  const { data: groups } = useGetUserManagementV2Query({
    type: 'group',
    limit: 50,
    offset: 0
  });
  useEffect(() => {
    //validate whole data!
    if (newValidatedUsers) {
      enableOrDisableImportButton();
    }
  }, [newValidatedUsers]);
  useEffect(() => {
    if (newValidatedUsers) {
      processAndImportValidatedUsers();
    }
  }, [importValidatedUsers]);
  const groupList = useMemo(() => {
    if (!groups) return [];
    return groups?.items.map((group) => ({ label: group.name, value: group.id }));
  }, [groups]);

  const tableData = useMemo(() => {
    if (result) {
      return result && result.items
        ? sortBy(result.items, [
            (p: User | Group) => {
              return p.firstName;
            }
          ])
        : [];
    } else {
      return [];
    }
  }, [result, groupDetail]);
  const tableValidationData = useMemo(() => {
    if (!newValidatedUsers) return [];
    else {
      return (
        groupList &&
        newValidatedUsers &&
        newValidatedUsers.map((usr: ValidatedUsersResponse) => {
          return {
            ...usr,
            groups: groupList,
            role: [
              { value: '1', label: 'Admin' },
              { value: '2', label: 'Member' }
            ],
            alertNotifications: [
              { value: '1', label: 'Email' },
              { value: '2', label: 'Text' }
            ]
          };
        })
      );
    }
  }, [newValidatedUsers]);

  const updateSearchValue = (updatedSearchValue: string) => {
    setGroupSearchValue(updatedSearchValue);
    // Make sure to reset the page count
    onPageChange(0);
  };

  const searchInputValue = (inputValue?: string, searchType?: string) => {
    if (searchType === 'global') {
      setUserSearchValue(inputValue || '');
    } else {
      updateSearchValue(inputValue || '');
    }
  };

  useMemo(() => {
    if (group_id) {
      const searchOptions = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
        { label: 'Member', value: 'member' },
        { label: 'Admin', value: 'admin' }
      ];
      dispatch(tanStackTableActions.addFilterOptions(searchOptions));
    } else {
      const groupOptions = groupData?.items.map((group: UserGroup) => ({
        value: group.id,
        label: group.name
      }));
      dispatch(tanStackTableActions.addFilterOptions(groupOptions || []));
    }
  }, [groupData, group_id]);

  // To set the page number to 0 and clear search when tab changes.
  useEffect(() => {
    setPageNumber(0);
    setGroupId('');
  }, []);

  useEffect(() => {
    setPageNumber(pageNumber);
  }, [pageNumber]);

  const handleGroupSearch = (val?: string) => {
    if (val === 'All') {
      setSelectedGroupId('');
    } else setSelectedGroupId(val || '');
  };

  const handleClickRow = (action?: string, data?: UserTypes) => {
    const isAlreadySelected = selectedTableItems?.find((user) => user?.id === data?.id);

    if (action === 'selectUser' && isAlreadySelected) {
      const newUser = selectedTableItems?.filter((user) => user?.id !== data?.id);
      setSelectedTableItems(newUser);
    } else if (action === 'selectUser' && data) {
      setSelectedTableItems([...selectedTableItems, data]);
    } else if (action === 'changeStatus' && data) {
      const params = { id: data.id, isActive: data.isActive };
      updateUserStatus([params]);
    } else if (action === 'selectAll') {
      setSelectedTableItems(tableData);
    } else if (action === 'deselectAll') {
      setSelectedTableItems([]);
    }
  };
  const handleSelect = (field: string, value: DropDownItem | null, id: string): void => {
    setNewValidatedUsers([
      ...(updateSelect(
        newValidatedUsers,
        field,
        value as DropDownItem,
        id
      ) as ValidatedUsersResponse[])
    ]);
  };
  const handleMultiSelect = (field: string, items: MultiValue<DropDownItem>, id: string): void => {
    setNewValidatedUsers([
      ...(updateMultiSelect(newValidatedUsers, field, items, id) as ValidatedUsersResponse[])
    ]);
  };
  const validateOnBlur = (field: string, value: string, id: string): void => {
    let msg = '';
    switch (field) {
      case 'first_name':
      case 'last_name':
        msg = validateName(value);
        updateBulkValidationError(msg, field, id);
        break;
      case 'phone':
        msg = validatePhone(value);
        updateBulkValidationError(msg, field, id);
        break;
      case 'email':
        msg = validateEmail(value);
        updateBulkValidationError(msg, field, id);
        break;
    }
  };
  const enableOrDisableImportButton = (): void => {
    let hasError = false;
    for (const user of newValidatedUsers as ValidatedUsersResponse[]) {
      if (user.errorMessages) {
        for (const error of user.errorMessages) {
          if (error.errors[0].length > 0) {
            setDisableImportUserButton?.(true);
            hasError = true;
            break;
          }
        }
        if (hasError) {
          break;
        }
      }
      if (
        !hasError &&
        (!user.selectedGroups || !user.selectedNotifications || !user.selectedRole)
      ) {
        setDisableImportUserButton?.(true);
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      setDisableImportUserButton?.(false);
    }
  };
  const updateFieldOnChange = (field: string, value: string, id: string): void => {
    setNewValidatedUsers([
      ...(updateValidatedUser(newValidatedUsers, field, value, id) as ValidatedUsersResponse[])
    ]);
  };
  const handleCheckClickOnBulkMode = (action?: string, data?: UserTypes): void => {
    const isAlreadySelected = selectedTableItems?.find((user) => user?.id === data?.id);
    if (isAlreadySelected) {
      const keepSelected = selectedTableItems?.filter((user) => user?.id !== data?.id);
      setSelectedTableItems(keepSelected);
    } else if (selectedTableItems && data) {
      setSelectedTableItems([...selectedTableItems, data]);
    }
  };
  //  Add, edit, delete action
  const handleTableAction = ({ flyoutType, flyoutItemId, action, selectedUser }: TableAction) => {
    if (action === 'addUser' || action === 'editUser') {
      onClickButton(flyoutType, flyoutItemId, selectedUser);
    } else if (action === 'delete') {
      setShowDeleteConfirmation(true);
      return;
    } else if (action === 'import') {
      setShowImportUsersModal(true);
    } else if (action === 'edit') {
      setShowEditModal(true);
      return;
    } else if (action === 'exit') {
      setShowExitImportModal(true);
      return;
    } else {
      return;
    }
  };

  // const handleTableSearch = (val: string) => {
  //   setSearchValue(val || '');
  // };

  const validatedUsersWithKeys = addKeyProp(
    tableValidationData,
    UserManagementTableType.USER
  ) as BulkUser[];
  const processUploadedFile = (file: File | null): void => {
    uploadUsersFile({
      file: file as File
    })
      .unwrap()
      .then((res) => {
        ToastMsg({
          message: t('User file upload successful'),
          heading: t('complete') as string,
          position: 'top-center',
          type: 'success'
        });
        setNewValidatedUsers(addIdProp(res) as ValidatedUsersResponse[]);
        setIsBulkImportMode(true);
        setShowImportUsersModal(false);
        setShowImportUserBar?.(true);
        setIsActiveUserImportMode?.(true);
        setSelectedTableItems([]);
      })
      .catch((error) => {
        ToastMsg({
          message: 'Failed to upload file',
          heading: 'Error',
          position: 'top-center',
          type: 'error'
        });
        console.error(error?.data?.detail || error);
      });
  };
  const processAndImportValidatedUsers = (): void => {
    importValidateUsers(processImportPayload())
      .unwrap()
      .then(() => {
        setIsBulkImportMode(false);
        setIsActiveUserImportMode?.(false);
        setDisableImportUserButton?.(true);
        setShowImportUserBar?.(false);
        ToastMsg({
          message: t('User data has been successfully imported'),
          heading: t('complete') as string,
          position: 'top-center',
          type: 'success'
        });
      })
      .catch((error) => {
        ToastMsg({
          message: 'Failed to import user data',
          heading: 'Error',
          position: 'top-center',
          type: 'error'
        });
        console.error(error?.data?.detail || error);
      });
  };
  const processImportPayload = (): UserTypes[] => {
    if (!newValidatedUsers) return [];
    const data: UserTypes[] = [];
    const val: UserTypes = { groups: [] };
    newValidatedUsers.forEach((user) => {
      const tempGroup: UserGroup[] = [];
      val.email = user.email;
      val.firstName = user.firstName;
      val.lastName = user.lastName;
      val.phone = user.phone;
      val.createdAt = new Date().getDate().toLocaleString() as string;
      val.updatedAt = new Date().getDate().toLocaleString() as string;
      val.isActive = true;
      val.ssoAuthentication = true;
      for (const alert of user.selectedNotifications as DropDownItem[]) {
        if (alert.value === '1') {
          val.emailAlert = true;
        } else {
          val.textAlert = true;
        }
      }
      for (const group of user.selectedGroups as DropDownItem[]) {
        let gdata: UserGroup = { name: '', isAdmin: false, id: '' };
        gdata = {
          name: group.label,
          id: group.value as string,
          isAdmin: user.selectedRole && user.selectedRole.value === '1' ? true : false
        };
        tempGroup.push(gdata);
      }
      val.groups = tempGroup;
      data.push(JSON.parse(JSON.stringify(val)));
    });
    return data;
  };
  const validateEmail = (email: string): string => {
    const emailRegex =
      /^(?=[^@]*[A-Za-z])[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/i;

    const emailRegexEmojiRes = /^(?!.*[\uD800-\uDFFF]).*$/;
    if (email === '') {
      return `Email cannot be empty`;
    } else if (!emailRegex.test(email as string) || !emailRegexEmojiRes.test(email as string)) {
      return `Email not valid`;
    } else {
      // Check if email is available/taken by another user
      try {
        validateUserEmail({ email: email as string })
          .unwrap()
          .then(() => {
            return '';
          });
      } catch (error) {
        return `Email already in use`;
      }
    }

    return '';
  };
  const validatePhone = (phone: string): string => {
    if (phone === '') {
      return `Phone cannot be empty`;
    } else if (!isValidPhoneNumber(phone)) {
      return `Invalid phone`;
    }
    return '';
  };
  const validateName = (name: string): string => {
    if (name.length >= 35) return 'Name should be less that 35 letters';
    const forbid = ['$', '#', '@', '%', '^', '(', ')', '*', '!', '-', '_', '='];
    for (const char of forbid) {
      if (name.includes(char)) return 'Invalid name';
    }
    return '';
  };
  const updateBulkValidationError = (msg: string, field: string, id: string): void => {
    const temRes = JSON.parse(JSON.stringify(newValidatedUsers)) as ValidatedUsersResponse[];
    let isExistingValidation = false;
    temRes.forEach((res) => {
      if (res.id === id && res.errorMessages !== null) {
        for (const error of res.errorMessages) {
          if (error.columnName === field) {
            error.errors[0] = msg;
            isExistingValidation = true;
            break;
          }
        }
        if (!isExistingValidation) {
          res.errorMessages.push({ columnName: field, errors: [msg] });
        }
      } else if (res.id === id && res.errorMessages === null && msg.length > 0) {
        res.errorMessages = [{ columnName: field, errors: [msg] }];
      }
    });
    setNewValidatedUsers([...temRes]);
  };
  const handleDeleteActionOnImport = (): void => {
    setShowDeleteConfirmation(false);
    const tempUsers: ValidatedUsersResponse[] = JSON.parse(JSON.stringify(newValidatedUsers));
    const selectedIds = selectedTableItems.map((i) => i.id);
    for (let i = 0; i < tempUsers.length; i++) {
      if (selectedIds.includes(tempUsers[i].id)) {
        tempUsers.splice(i, 1);
        i--; //reset loop counter to match new array after slice
      }
    }
    setNewValidatedUsers([...tempUsers]);
    setSelectedTableItems([]);
  };
  const dataWithKeys = transformUserColumnOptions(tableData, UserManagementTableType.USER);

  const closeBackModal = (): void => {
    setShowExitImportModal(false);
    setMoveToGroupOnImportMode?.('cancel');
  };
  const exitImportUserPage = (): void => {
    setShowExitImportModal(false);
    setIsBulkImportMode(false);
    setIsActiveUserImportMode?.(false);
    setMoveToGroupOnImportMode?.('continue');
    setShowImportUserBar?.(false);
  };
  const afterDeleteHouseKeeping = (): void => {
    setShowDeleteConfirmation(false);
    setSelectedTableItems([]);
    setResetControlCheck(true);
  };
  return (
    <>
      <ButtonContainer>
        <UserTableButtonWrapper
          isItemSelected={!!selectedTableItems.length}
          handleFlyout={handleTableAction}
          isImportMode={isBulkImportMode}
        />
      </ButtonContainer>
      {isBulkImportMode ? (
        <DisplayImportedUsers
          isLoading={isLoading}
          tableValidationData={validatedUsersWithKeys || []}
          handleSelect={handleSelect}
          handleMultiSelect={handleMultiSelect}
          validateOnBlur={validateOnBlur}
          t={t}
          updateFieldOnChange={updateFieldOnChange}
          handleCheckClickOnBulkMode={handleCheckClickOnBulkMode}
          selectedTableItems={selectedTableItems}
          defaultUserSortState={defaultUserSortState}
        />
      ) : (
        <DisplayExistingUsers
          isLoading={isLoading}
          dataWithKeys={dataWithKeys}
          handleTableAction={handleTableAction}
          t={t}
          handleClickRow={handleClickRow}
          selectedTableItems={selectedTableItems}
          defaultUserSortState={defaultUserSortState}
          searchInputValue={searchInputValue}
          handleGroupSearch={handleGroupSearch}
          result={result}
          onPageChange={onPageChange}
          pageLenght={PAGE_LENGTH.SMALL}
          pageNumberState={pageNumberState}
          pageNumber={pageNumber}
          resetControlCheck={resetControlCheck}
          setResetControlCheck={setResetControlCheck}
        />
      )}

      {showImportUsersModal && (
        <ImportUsers
          onFileChange={processUploadedFile}
          setShowImportUsersModal={setShowImportUsersModal}
        />
      )}
      {showDeleteConfirmation && selectedTableItems?.length && (
        <DeleteUser
          showConfirmation={showDeleteConfirmation}
          closeModal={() => {
            setShowDeleteConfirmation(false);
          }}
          houseKeepOnDelete={afterDeleteHouseKeeping}
          data={selectedTableItems}
          groupId={group_id}
          handleDeleteActionOnImport={handleDeleteActionOnImport}
          isImportMode={isBulkImportMode}
        />
      )}

      {showEditModal && selectedTableItems.length ? (
        <UpdateUser
          showEditModal={showEditModal}
          closeModal={() => {
            setShowEditModal(false);
          }}
          data={selectedTableItems}
          groupDetail={groupDetail}
        />
      ) : (
        ''
      )}
      <Modal
        title={
          <StyledModalHeaderWrapper>
            <ModalIconWrapper>
              <IcoWarningNew />
              <StyledTitle>Warning</StyledTitle>
            </ModalIconWrapper>
            <ModalCloseButton>
              <CloseIcon onClick={closeBackModal}>{IcoClose()}</CloseIcon>
            </ModalCloseButton>
          </StyledModalHeaderWrapper>
        }
        allowContentScroll
        onClose={closeBackModal}
        showCloseButton={false}
        size={ModalSize.XSMALL}
        visible={showExitImportModal || (moveToGroupOnImportMode as boolean)}
      >
        <ModalContent>
          <AlertBody>
            Are you sure you want to navigate away from this page? All the changes will be lost.
          </AlertBody>

          <Footer>
            <StyledButtonRow>
              <Button
                onClick={closeBackModal}
                variant="link"
                borderRadius="3rem"
                borderColor={theme.colors.text.error}
                color={theme.colors.text.error}
              >
                Cancel
              </Button>
              <Button
                onClick={exitImportUserPage}
                borderColor={theme.colors.text.error}
                variant="constant"
                bgColor={theme.colors.text.error}
                borderRadius="3rem"
                color={theme.colors.white}
              >
                Continue
              </Button>
            </StyledButtonRow>
          </Footer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserManagementTable;
