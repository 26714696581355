import { useGetMachineHealthByBuKpiQuery } from 'api';
import { useLiveTimeFrame } from 'common/ui';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { MachineAccountInfoQueryParams } from 'types/protein';
import { ChartOverTimeWidgetProps } from '../ChartOverTimeWidget';
import { useInterval } from 'pages/DSI/MachineHealth/_hooks/useInterval';

const showLiveLogs = false;

export const useChartsOverTimeWidget = ({
  kpiType,
  dataType
}: ChartOverTimeWidgetProps): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  [key: string]: unknown;
} => {
  const { interval } = useInterval();
  const { machineId } = useParams<MachineAccountInfoQueryParams>();

  const { data, error, isLoading } = useGetMachineHealthByBuKpiQuery({
    machineId,
    kpiDataInterval: interval,
    widgetType: kpiType || 'dsi_current_kpi',
    limit: 100000,
    includeHistoricData: true,
    businessUnit: 'dsi',
    psuName: dataType,
    totalValuesDesired: 2000
  });

  // this hook automaically refreshes this api call every 60 seconds
  const live = useLiveTimeFrame(60000, { seconds: 60 });

  if (showLiveLogs) console.log({ live });

  const productType = data?.[0];

  return {
    isLoading: isLoading && true,
    hasError: error && true,
    data: productType?.values as Record<string, unknown>[]
  };
};
