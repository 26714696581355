import React from 'react';

export const Sterilization = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5185_70580)">
        <path
          d="M5.59998 16.7604H22.4V5.64551H5.59998V25.4053"
          stroke="#009EB3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_5185_70580)">
          <path
            d="M17.636 11.1999C17.636 10.4727 17.4203 9.76186 17.0161 9.15734C16.6119 8.55281 16.0375 8.08178 15.3655 7.80386C14.6935 7.52595 13.9541 7.45365 13.241 7.59612C12.5279 7.73858 11.8731 8.08941 11.3595 8.60418C10.8459 9.11896 10.4965 9.77455 10.3556 10.488C10.2148 11.2014 10.2887 11.9406 10.5681 12.6119C10.8475 13.2833 11.3198 13.8567 11.9252 14.2595C12.5307 14.6624 13.242 14.8765 13.9692 14.8749"
            stroke="#009EB3"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9611 9.1582V11.1999L14.3694 11.6082"
            stroke="#009EB3"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.0444 12.8332C18.0444 14.4665 17.0235 15.2832 16.6152 15.2832C16.2069 15.2832 15.186 14.4665 15.186 12.8332C15.5944 12.8332 16.2069 12.629 16.6152 12.2207C17.0235 12.629 17.636 12.8332 18.0444 12.8332Z"
            stroke="#009EB3"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5185_70580">
          <rect width="28" height="28.2283" fill="white" />
        </clipPath>
        <clipPath id="clip1_5185_70580">
          <rect width="9.8" height="9.80004" fill="white" transform="translate(9.06104 6.2998)" />
        </clipPath>
      </defs>
    </svg>
  );
};
