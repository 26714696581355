import React from 'react';
import { ActionWithRulesAndAddersProps } from 'react-querybuilder';
import { useTranslation } from 'react-i18next';
import { AddButton } from './index.elements';
import { IcoPlus } from 'icons/IconPlus';

const CustomAddRuleBtn = (props: ActionWithRulesAndAddersProps): JSX.Element => {
  const { t } = useTranslation(['mh']);
  return (
    <>
      <AddButton onClick={(e) => props.handleOnClick(e)}>
        <IcoPlus color="#0076cc" />
        <span
          style={{
            marginLeft: '-4px'
          }}
        >
          {t('add_rule') as string}
        </span>
      </AddButton>
    </>
  );
};

export default CustomAddRuleBtn;
