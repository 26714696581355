import React, { ReactNode, useMemo } from 'react';
import {
  MachineOverviewWidgetContainer,
  WigetTileCellContainer
} from './MachineOverviewWidget.elements';
import { WidgetUiProps } from 'common/ui';
import { WidgetUi } from 'common/components/WidgetUi/WidgetUi';
import { useTranslation } from 'react-i18next';

export interface MachineOverviewWidgetTileItemProps {
  title?: ReactNode;
  label?: ReactNode;
  value?: ReactNode;
  subValue?: ReactNode;
  color?: string;
  id?: string;
  Icon?: JSX.Element;
  isHidden?: boolean;
  isLast?: boolean;
}

export interface MachineOverviewWidgetProps extends WidgetUiProps {
  MachingeImage?: ReactNode;
  tiles?: MachineOverviewWidgetTileItemProps[];
  image?: ReactNode;
  isRunning?: boolean;
}

const WidgetTile = ({
  title,
  label,
  value,
  color,
  id,
  Icon,
  isHidden,
  isLast,
  subValue
}: MachineOverviewWidgetTileItemProps): JSX.Element => {
  if (isHidden) return <></>;

  const { t } = useTranslation(['mh']);
  return (
    <div className="widget-tile machine-overview-widget__tile">
      <div className="widget-tile__inner">
        <WigetTileCellContainer
          className={`widget-tile--${id} widget-tile__cell widget-tile__cell--icon`}
          {...{ color }}
        >
          <span>{Icon}</span>
        </WigetTileCellContainer>
        <WigetTileCellContainer
          className={`widget-tile--${id} widget-tile__cell widget-tile__cell--title${
            isLast ? ' is-last' : ``
          }`}
        >
          {t((title as string).toLowerCase())}
        </WigetTileCellContainer>
        <WigetTileCellContainer
          className={`widget-tile--${id} widget-tile__cell widget-tile__cell--value${
            isLast ? ' is-last' : ``
          }`}
          {...{ color }}
        >
          {label && <div className="widget-tile__label">{t((label as string).toLowerCase())}</div>}
          <div className="widget-tile__value">{value}</div>
          {subValue && <div className="widget-tile__sub-value">{subValue}</div>}
        </WigetTileCellContainer>
      </div>
    </div>
  );
};

interface Props extends MachineOverviewWidgetProps {
  children?: ReactNode;
}

const Img = ({ image }: Props) => {
  const MachineImage = useMemo(
    () =>
      !image ? (
        <div className="has-error">{t('image not available')}</div>
      ) : typeof image === 'string' ? (
        <img src={image} alt="machine" className="machine-image" />
      ) : (
        Image
      ),
    []
  );

  return <>{MachineImage}</>;
};

export const MachineOverviewWidget = ({
  className,
  title,
  children,
  tiles,
  image,
  ...rest
}: Props): JSX.Element => {
  const widgetSettings = {
    title,
    className: className ? `machine-overview-widget ${className}` : `machine-overview-widget`,
    ...rest
    // turn off until available hasButtons: { headerRight: ['settings'] }
  };

  // this is used to remove borders from the last tile, it was easer than doing it via css
  const len = tiles?.length || 0;

  return (
    <WidgetUi
      {...widgetSettings}
      // turn off until available hasButtons: { headerRight: ['settings'] }
      Main={
        <MachineOverviewWidgetContainer className="widget-ui__main ">
          <div className="machine-overview-widget__image">
            <Img image={image} />
          </div>
          <div className="machine-overview-widget__tiles">
            {tiles?.map((props, i) => {
              if (!props.id) return <>error: missing id</>;
              props.isLast = i === len - 1;
              return <WidgetTile key={props?.id as string} id={props?.id as string} {...props} />;
            })}
          </div>
          {children}
        </MachineOverviewWidgetContainer>
      }
    />
  );
};
