import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useGetAccountInfoQuery } from 'api';
import { AccountInfo, PowerBiItem } from 'types/protein';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reportActions } from 'actions';
import { useTimeZone } from 'providers';
import {
  avureCustomerTimeZones,
  avureCustomerMachineTypes,
  avureCustomerSkids
} from '../default_settings/avure_customer_default_settings';

const FleetMachineAccountDataContext = createContext<FleetMachineAccountDataContextReturnProps>({
  accountData: undefined,
  reportsIds: {},
  powerBiList: []
});

export const useFleetMachineAccountData = (): FleetMachineAccountDataContextReturnProps => {
  return useContext(FleetMachineAccountDataContext);
};

interface Props {
  children?: ReactNode | ReactNode[];
}

export interface FleetMachineAccountDataContextReturnProps {
  accountData?: AccountInfo;
  isLoading?: boolean;
  hasError?: string;
  curCustomer?: Record<string, boolean> | undefined;
  machineId?: string;
  reportsIds?: {
    machineId?: string;
    workspaceId?: string;
    reportId?: string;
  };
  powerBiList?: PowerBiItem[];
  timeZone?: string;
  hasSkids?: number[];
  machineType?: 'ASD' | 'MKT' | string;
}

export const FleetMachineAccountDataProvider = ({ children }: Props): JSX.Element => {
  const { machineId } = useParams<{ machineId: string }>();
  const { data: accountData, isLoading, isError } = useGetAccountInfoQuery({ machineId });
  const { timeZone } = useTimeZone();

  // get the company name string from the accountData
  const companyName: string | undefined = (accountData?.companyName as string) || undefined;

  const dispatch = useDispatch();

  const value: FleetMachineAccountDataContextReturnProps = {
    accountData,
    isLoading,
    hasError: isError ? 'error getting account info' : undefined,
    curCustomer: companyName?.includes('PASCAL')
      ? { isPascal: true }
      : { [`is${companyName}`]: true },
    machineId,
    reportsIds: {
      reportId: accountData?.reportId,
      workspaceId: accountData?.workspaceId
    },
    powerBiList: accountData?.powerBiList,
    // set check for timeZone or load from hard coded list
    timeZone: avureCustomerTimeZones[companyName as string] || timeZone,
    // set check for skids or load from hard coded list
    hasSkids: avureCustomerSkids[companyName as string] || undefined,
    // get the machine type from deafult hard coded settings for now
    machineType: avureCustomerMachineTypes[companyName || 'undefined']
  };

  useEffect(() => {
    if (accountData?.reportId && accountData?.workspaceId) {
      dispatch({ type: reportActions.SHOW_REPORT, machineId });
    } else {
      dispatch({ type: reportActions.HIDE_REPORT, machineId });
    }
  }, [accountData]);

  return (
    <FleetMachineAccountDataContext.Provider {...{ value }}>
      {children}
    </FleetMachineAccountDataContext.Provider>
  );
};
