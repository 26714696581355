import React from 'react';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { StyledUiContainerProps } from 'components/StyledUi/StyledUiGlobal.types';
import { endOfDay, addDays, startOfDay, sub, subDays } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { utcToZonedTime } from 'date-fns-tz';
import { DateRangeProps } from '../DateRange/DateRange.types';

function isMidnight(date: Date): boolean {
  // Get a moment object from the date
  const m = moment(date);
  // Check if the hour, minute, and second are all 0
  return m.hour() === 0 && m.minute() === 0 && m.second() === 0;
}

// add custom classes to calendar for styling takeover
const customClasses = {
  dateRangePickerWrapper: 'ui-date-range',
  dateRangeWrapper: 'ui-date-range__calendar-wrapper',
  dateDisplayWrapper: 'ui-date-range__inputs',
  monthAndYearWrapper: 'ui-date-range__date-stepper',
  months: 'ui-date-range__calendar ui-calendar',
  month: 'ui-calendar__month',
  weekDays: 'ui-calendar__week-days',
  days: 'ui-calendar__days'
};

interface CalendarProps extends StyledUiContainerProps {
  dateRange: DateRangeProps;
  setDateRange: (range: DateRangeProps) => void;
  hasGoBackDateLimit?: number;
  hasFutureDates?: boolean;
  timezone?: string;
}

export const DisplayCalendar = ({
  dateRange,
  setDateRange,
  hasGoBackDateLimit,
  className,
  hasFutureDates,
  timezone
}: CalendarProps): JSX.Element => {
  // data does not go back more than 30 days so 30 is set as the default
  hasGoBackDateLimit = hasGoBackDateLimit || 30;

  const minDate = sub(new Date(), { days: hasGoBackDateLimit });

  const handleChange = ({ startTime, endTime }: DateRangeProps) => {
    // gets current date and time
    //const now = moment().toDate(); //should it account for Machine time?
    const startday = startOfDay(startTime);
    // checks to see if endTime is today to make sure the time doesn't go into the future
    //const endsToday = moment(now).isSame(endTime, 'day');
    // if today is selected, make sure that it doesn't go into the future
    // endTime = endsToday ? now : endOfDay(endTime);

    const now2 = utcToZonedTime(
      new Date().toISOString(),
      timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const endsToday = moment(now2).isSame(endTime, 'day');

    endTime = endsToday ? now2 : startOfDay(addDays(endTime, 1)); //changing the way dates are displayed when selecting a range, now it will show 00:00 - 00:00 instead of 00:00 - 23:59
    startTime = startday ? startday : startTime;
    // set the new date range
    setDateRange({ startTime, endTime });
  };

  // START:
  // Below block is for visual manipulation of dates
  // Currently (as of 05/08/2024) we have a calendar and below it, time picker.
  // Desired behavior: select only ONE day on the calendar, but have time below, display May5th 00:00 - May6th 00:00, NOT May 5th 00:00 - May 5th 11:59
  // Below is manipulation where we format the date to be 00:00 while visially selecting only 1 day on the calendar

  // displayNow in machine time zone time
  const displayNowMachineZone = utcToZonedTime(
    new Date().toISOString(),
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // 1. date range end time in machine time zone
  // 2. compare if displayNow is the same as date range end time, returns boolean
  const isEndDateMidnight = isMidnight(dateRange.endTime);
  const isTheSameEndDAy = moment(displayNowMachineZone).isSame(dateRange.endTime, 'day');
  // Based on previos boolean value, return end date for calendar
  const displayCalendarEnd = isTheSameEndDAy
    ? isEndDateMidnight
      ? endOfDay(subDays(dateRange.endTime, 1))
      : displayNowMachineZone
    : endOfDay(subDays(dateRange.endTime, 1));

  const ranges = [{ startDate: dateRange.startTime, endDate: displayCalendarEnd }];
  //END

  //const ranges = [{ startDate: dateRange.startTime, endDate: dateRange.endTime }];
  //const maxDate = hasFutureDates ? undefined : new Date(); //should be in machine timezone
  const maxDate = hasFutureDates
    ? undefined
    : utcToZonedTime(
        new Date().toISOString(),
        timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      );

  return (
    <DateRange
      className={className}
      classNames={customClasses}
      editableDateInputs={true}
      onChange={({ range1 }) => {
        handleChange({ startTime: range1.startDate as Date, endTime: range1.endDate as Date });
      }}
      minDate={minDate}
      moveRangeOnFirstSelection={false}
      ranges={ranges}
      calendarFocus="backwards"
      direction="horizontal"
      maxDate={maxDate}
      retainEndDateOnFirstSelection={false}
    />
  );
};
