import styled from 'styled-components';

export const PressurizationPerformanceViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .pressurization-performance-view__top-row {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 1em;

    .widget-ui {
      &.color-coded-widget .widget-ui-main {
        padding: 0;
      }

      &.color-coded-widget {
        height: 400px;

        .widget-ui-main {
          overflow: scroll;
        }
      }
    }
  }

  .horizontal-widget {
    height: 300px;
  }
`;
