// 3rd party libs
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import NewBaseTable from 'components/NewBaseTable/NewBaseTable';

// Types
import { SortState } from 'types';
import { LineRouteQueryParams, MachineLineStatus, MachineUtilization } from 'types/protein';

// Hooks
import { useSort } from 'hooks';

// Utils
import { addKeyProp, lineColumnConfig, LineTableWrapper } from './utils';
import { DataRenderer } from 'components/machine-health';

// Api
import { useGetLineMachinesStatusQuery } from 'api';

export const initSortState: Record<string, SortState> = {
  machine: SortState.unsorted
};

interface Props {
  utilizationByMachine: { id: string; utilization: MachineUtilization }[];
}

const LineTable = ({ utilizationByMachine }: Props): JSX.Element => {
  const { lineId } = useParams<LineRouteQueryParams>();
  const history = useHistory();
  const { t } = useTranslation(['mh']);

  /**
   * Query line machines
   */
  const {
    data: machines,
    isLoading,
    error
  } = useGetLineMachinesStatusQuery({ lineId }, { skip: !lineId, pollingInterval: 30000 });

  const machinesWithUtilization = useMemo(
    () =>
      machines?.map((machine) => {
        const machineUtilization = utilizationByMachine.find(
          (utilization) => utilization.id === machine.id
        );
        return {
          ...machine,
          ...machineUtilization?.utilization
        };
      }),
    [machines, utilizationByMachine]
  );

  const sortedData = useSort<MachineLineStatus>(initSortState, machinesWithUtilization);
  const [searchValue, setSearchValue] = useState('');

  const goto = (path: string) => {
    history.push(path);
  };

  const updateSearchValue = (updatedSearchValue?: string) => {
    setSearchValue(updatedSearchValue || searchValue);
  };

  const defaultGroupSortState = {
    id: '',
    desc: true
  };

  return (
    <>
      <DataRenderer
        width={'100%'}
        isLoading={isLoading}
        error={error && 'Failed to load machine list'}
      >
        <LineTableWrapper>
          <NewBaseTable
            newTableData={isLoading ? [] : addKeyProp(sortedData)}
            columnConfigs={lineColumnConfig(goto, t)}
            textAlign="left"
            sortState={defaultGroupSortState}
            isShowColumnOptions={true}
            isDataLoading={isLoading}
            isShowGlobalSearch={true}
            searchPlaceholder={t('search') as string}
            searchInputValue={updateSearchValue}
            customClassName="line-table"
          />
        </LineTableWrapper>
      </DataRenderer>
    </>
  );
};

export default LineTable;
