// 3rd party libs
import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import { useDateRange } from 'components';
import { useGetPressurizationDeltaQuery } from 'api';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { PressureDeltaData } from 'pages/FleetMachineDetail/MachineHealth/components/PressurizationDelta/types';

export interface PressurizationDeltaProps {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  data?: PressureDeltaData[];
}

const PressurizationDelta = createContext<PressurizationDeltaProps>({
  isLoading: true
});

export const usePressurizationDelta = (): PressurizationDeltaProps =>
  useContext(PressurizationDelta);

export const PressurizationDeltaProvider = ({
  children
}: {
  children?: ReactNode;
}): JSX.Element => {
  const { startTime, endTime } = useDateRange().utcTZConvertedISO;
  const { machineId } = useFleetMachineAccountData();

  if (!machineId) return <></>;

  const { data, isLoading, isError } = useGetPressurizationDeltaQuery({
    machineId: machineId,
    startDatetime: startTime,
    endDatetime: endTime
  });

  const cachedData = useMemo(() => data, [data]);

  const hasError = isError ? `error getting pressurization data` : undefined;

  const value = {
    data: cachedData,
    isLoading,
    hasError
  };

  return <PressurizationDelta.Provider value={value}>{children}</PressurizationDelta.Provider>;
};
