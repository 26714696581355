import { PieChartWidget, chartValueRGBA } from 'common/components';
import { colors as alarmColors } from 'common/pages/fleetV2/settings/protein';
import { filterSelectedData, useDateRange, useFilterSelected } from 'components';
import { convertToChartData } from 'components/StyledUi/StyledChartsV2/helpers';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartTooltip } from './Alarms.elements';
import { StyledChartLegendProps } from 'components/StyledUi/StyledChartsV2/ChartLegends';
import { timeDisplayFormatter } from 'components/StyledUi/DashboardWidget/timeDisplayFormatter';

interface UseStyledChartProps {
  title?: string;
  hasLegend?: boolean;
  data?: Record<string, unknown>[];
  isLoading?: boolean;
  hasMessage?: string;
  hasError?: string;
  hideLegend?: boolean;
  groupKey?: string;
  filteredByKeys?: string[];
  legendSettings?: StyledChartLegendProps;
  tooltip?: (record: Record<string, unknown>) => React.ReactNode;
  centerSubText?: (text: string) => string;
  customLegends?:
    | {
        label: string;
        color: string;
      }[]
    | undefined;
  unFilteredData?: Record<string, unknown>[];
}

const useChartData = (
  data: Record<string, unknown>[] | undefined,
  groupKey: string,
  options?: {
    selected?: Record<string, string[]>;
    filteredByKeys?: string[];
  }
) => {
  const { selected, filteredByKeys } = options || {};

  const dataCache = useMemo(
    () =>
      data
        ? convertToChartData(data as Record<string, unknown>[], groupKey, {
            calculatePercents: true
          })
        : [],
    [data]
  );

  const chartData: Record<string, unknown>[] | undefined = useMemo(() => {
    // make sure the data is here, check for filtered and if items are selected
    // this is done because we want to filter the data and re-calculate the percents
    // based on the parent group
    if (dataCache?.length && filteredByKeys?.length && selected) {
      const filteredData = filterSelectedData({
        data: dataCache,
        selected: filteredByKeys.reduce((acc, key) => {
          if (selected?.[key]) acc = { ...acc, [key]: selected[key] };
          return acc;
        }, {})
      });

      if (!filteredData) return undefined;

      return convertToChartData(filteredData, groupKey, { calculatePercents: true });
    }
    return dataCache;
  }, [dataCache, filteredByKeys, selected]);

  return chartData;
};

export const handleSliceClick = (
  datum: Record<string, unknown>,
  groupKey: string,
  handleSelect: (x: string, sel?: Record<string, string | string[]>) => void,
  options?: {
    selected?: Record<string, string[]>;
    filteredByKeys?: string[];
    usesFilteredData?: boolean;
  }
): void => {
  const { selected, filteredByKeys } = options || {};

  // define new object to pass to handleSelect
  let selectObj: Record<string, string | string[]> = {
    // check for x, if x isn't there, check for groupKey (legend passed object k as groupKey)
    [groupKey]: (datum.x as string) || (datum[groupKey] as string),
    ...selected
  };

  // this chart is filtered by another chart so we need to make sure those keys remain selected
  if (filteredByKeys) {
    const parentGroupKey = filteredByKeys?.[0];
    // add the parent group key to the select object to make sure
    // the parent filter is selected
    selectObj = { ...selectObj, [parentGroupKey]: datum?.[parentGroupKey] as string };
  }

  // nothing is selected, so select this group and item
  if (!selected) {
    return handleSelect('set', selectObj);
  }

  // get the number of selected groups to see if other groups are selected
  const selectedKeys = Object.keys(selected);

  // this group is the only group selected, toggling the item selection
  if (selectedKeys.length < 2 && !filteredByKeys) {
    return handleSelect('toggle', selectObj);
  }

  // there are other items in other groups selected
  if (selectedKeys.length > 1 && !filteredByKeys) {
    return handleSelect('toggle', selectObj);
  }

  // this chart is not filtered by keys, and there are other groups selected
  // so we're going to set the selection to clear the other selected groups and items
  return handleSelect('toggle', selectObj);
};

const AlarmByTypePieChart = ({
  data,
  isLoading,
  hasError,
  title,
  groupKey,
  filteredByKeys,
  legendSettings,
  tooltip,
  centerSubText,
  customLegends,
  unFilteredData
}: UseStyledChartProps): JSX.Element => {
  if (!groupKey) return <>error loading chart, missing groupKey</>;

  // get selected items from the FilterSelected provider/context
  const [selected, handleSelect] = useFilterSelected();
  const { dateRange } = useDateRange();

  const chartData = useChartData(data, groupKey, { selected, filteredByKeys });
  const unFilteredChartData = useChartData(unFilteredData, groupKey, { selected, filteredByKeys });

  const legendItems =
    legendSettings?.items &&
    legendSettings?.items.map((item) => ({
      color:
        groupKey === 'type'
          ? alarmColors?.[item?.label.toLowerCase() as string] || '#000000'
          : chartValueRGBA(item?.label),
      ...item
    }));

  const pieChartData = chartData?.map((item) => ({
    label: item?.x || '',
    group: item?.x || '',
    value: item?.y || 0,
    description: item?.description || '',
    code: item?.code || '',
    count: item?.count || 0,
    color:
      groupKey === 'type' ? alarmColors?.[item?.x.toLowerCase() as string] : chartValueRGBA(item?.x)
  }));

  const handleClick = (datum: Record<string, unknown>) => {
    if (datum && datum?.label) {
      const newDatum = {
        xName: datum?.label,
        _y: datum?.value,
        y: datum?.value,
        x: datum?.label,
        type: datum?.label,
        groupKey: groupKey,
        code: datum?.code,
        description: datum?.description,
        count: datum?.count
      };
      if (selected) {
        handleSelect('clear');
      } else {
        handleSliceClick(newDatum, groupKey, handleSelect, { selected, filteredByKeys });
      }
    } else {
      handleSelect('clear');
      const filteredList =
        pieChartData && pieChartData.filter((item) => item?.group === datum?.group);
      if (selected) {
        const newDatum = filteredList &&
          filteredList.length && {
            xName: filteredList[0]?.label,
            _y: filteredList[0]?.value,
            y: filteredList[0]?.value,
            x: filteredList[0]?.label,
            type: filteredList[0]?.label,
            groupKey: groupKey,
            code: filteredList[0]?.code,
            description: filteredList[0]?.description,
            count: filteredList[0]?.count
          };
        newDatum &&
          handleSliceClick(newDatum, groupKey, handleSelect, { selected, filteredByKeys });
      } else {
        if (filteredList && filteredList.length) {
          const newDatum = {
            xName: filteredList[0]?.label,
            _y: filteredList[0]?.value,
            y: filteredList[0]?.value,
            x: filteredList[0]?.label,
            type: filteredList[0]?.label,
            groupKey: groupKey,
            code: filteredList[0]?.code,
            description: filteredList[0]?.description,
            count: filteredList[0]?.count
          };
          newDatum &&
            handleSliceClick(newDatum, groupKey, handleSelect, { selected, filteredByKeys });
        } else {
          handleSliceClick({}, groupKey, handleSelect, { selected, filteredByKeys });
        }
      }
    }
  };

  const checkIfSelected = (slice: Record<string, unknown>) => {
    if (!selected) return true;
    const group = (slice.label as string) || (slice.group as string);
    const selectedVals = selected?.type || selected?.activerecipe || selected?.code || [];
    if (selectedVals.length && selectedVals.includes(group)) return true;
    return false;
  };

  const filterByKeyValue = (
    data: Record<string, unknown>[],
    filterCriteria: { code?: string[]; type?: string[] }
  ) => {
    // Check if data or filterCriteria is undefined or null
    if (!Array.isArray(data) || !filterCriteria) return null;

    if (filterCriteria?.code && groupKey === 'type') return null;
    if (filterCriteria?.type && groupKey === 'code') return null;

    const [key, values] = Object.entries(filterCriteria)[0] || [];
    if (!key || !values) return data[0]; // Return the original data if no valid filter criteria are provided

    return data.find((item) => values?.includes(item[key]));
  };

  const centerCellValues =
    selected && filterByKeyValue(unFilteredChartData, selected)
      ? {
          value: `${filterByKeyValue(unFilteredChartData, selected)?.y?.toFixed(0)}%` as string,
          label: centerSubText
            ? centerSubText(pieChartData?.[0]?.label?.slice(0, 14))
            : groupKey === 'type'
            ? `${pieChartData?.[0]?.count} ${pieChartData?.[0]?.label?.slice(0, 14)}s`
            : `Code: ${pieChartData?.[0]?.code?.slice(0, 15)}`
        }
      : !isLoading && !hasError && pieChartData
      ? {
          value: `${pieChartData?.[0]?.value?.toFixed(0)}%` as string,
          label: centerSubText
            ? centerSubText(pieChartData?.[0]?.label?.slice(0, 14))
            : groupKey === 'type'
            ? `${pieChartData?.[0]?.count} ${pieChartData?.[0]?.label?.slice(0, 14)}s`
            : `Code: ${pieChartData?.[0]?.code?.slice(0, 15)}`
        }
      : undefined;

  const TooltipComponent = (record: Record<string, unknown>): JSX.Element => {
    const { t } = useTranslation(['mh']);
    return (
      <ChartTooltip>
        {tooltip ? (
          tooltip(record)
        ) : groupKey === 'type' ? (
          <>
            <div className="tooltip__label">{`${t(String(record.label))}: ${record.count}`}</div>
            <div className="pie-chart-tooltip__value">
              {Math.round(record.value as number)} <span className="small-text">%</span>
            </div>
          </>
        ) : (
          <>
            <div className="tooltip__label">{`Code: ${t(String(record.label))}`}</div>
            <div className="pie-chart-tooltip__value">{`${t(String(record.description))}`}</div>
            <div className="pie-chart-tooltip__value">
              {Math.round(record.value as number)} <span className="small-text">%</span>
            </div>
          </>
        )}
      </ChartTooltip>
    );
  };

  const getDateRange = () => {
    if (selected?.date?.[0]) {
      timeDisplayFormatter({ ...dateRange, useForDisplay: true });
      const selectedDate = `${selected?.date[0]}`.split('-');
      return `${selectedDate?.[1]}/${selectedDate?.[2]}/${selectedDate?.[0]}`;
    } else {
      return `${timeDisplayFormatter({ ...dateRange, useForDisplay: true })}`;
    }
  };

  const widgetUiSettings = {
    isLoading,
    hasMessage: chartData && chartData.length ? undefined : 'no data',
    hasError: hasError ? 'Error fetching alarm details' : '',
    className: 'alarm-pie-chart',
    title: title,
    hasDateFooter: getDateRange(),
    handleClick,
    hideLegend: legendItems?.length ? false : true,
    legendItems: legendItems?.length ? legendItems : customLegends
  };

  const pieChartWidgetSettings = {
    ...widgetUiSettings,
    pieChartSettings: {
      type: 'doughnut',
      TooltipComponent
    },
    handleClick,
    checkIfSelected,
    selected,
    data: pieChartData,
    centerCellValues,
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const { t } = useTranslation(['mh']);
        const label = props?.label as string;
        return <>{(t(label) as string) || ''}</>;
      }
    }
  };

  return <PieChartWidget {...pieChartWidgetSettings} />;
};

export default AlarmByTypePieChart;
