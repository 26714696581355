import React from 'react';
import { IcoClockWithSquare } from 'icons/IcoClock';
import { IcoHand } from 'icons/IcoHand';
import { IcoTriangleWithQuestionMark } from 'icons/IcoTriangle';
import { IcoExternalError } from 'icons/IcoExternalError';
import { colors } from './colors';
import { IcoProcessError } from 'icons/IcoProcessError';
import { IcoCheckWithCircle } from 'icons/IcoCheck';

export const Icons: Record<string, JSX.Element> = {
  undefined: <IcoTriangleWithQuestionMark color={colors?.['undefined']} />,
  'stopped by operator': <IcoHand color={colors?.['stopped by operator']} />,
  'machine running': <IcoCheckWithCircle size={1.5} color={colors?.['machine running']} />,
  'machine error': <IcoTriangleWithQuestionMark color={colors?.['machine error']} />,
  'external error': <IcoExternalError color={colors?.['external error']} />,
  'process error': <IcoProcessError color={colors?.['process error']} />,
  offline: <IcoClockWithSquare color={colors?.['offline']} />
};
