// 3rd party libs
import React from 'react';
import styled from 'styled-components';
import { TFunction } from 'i18next';

// Components
import { ColumnConfig } from 'components/NewBaseTable/NewBaseTable';

// Types
import { MachineLineStatus } from 'types/protein';

// Routes
import { JBTRoutes } from 'constants/routes';

//Styling
import theme from 'themes';

const TableCell = styled.div<{ display?: string; overflow?: string; color?: string }>``;

export const LineTableWrapper = styled.div`
  border: 1px solid ${theme.colors.tableborder};
  border-radius: 0.5rem;
  th {
    .disabled-sort {
      white-space: wrap !important;
    }
  }

   tr th,
   tr td  {

    &:first-child {
      width: 20%;
      .cell{
      margin-left:0.5rem;
      color: ${theme.colors.primaryBlue5};
      cursor: pointer;
      }
    }

    &:nth-child(2) {
        width: 11%;
    }

    &:nth-child(3) {
        width: 11%;
    }

    &:nth-child(4) {
        width: 11%;
    }

    &:nth-child(5) {
        width: 11%;
    }

    &:nth-child(6) {
        width: 11%;
    }
    &:nth-child(7) {
        width: 11%;
    }
    &:nth-child(8) {
      width: 14%;
    }

  }
 
  }
`;

export const LoaderWrapper = styled.div`
  div {
    margin: 0;
  }
  }
`;

export const lineColumnConfig = (
  goto: (path: string) => void,
  t: TFunction<'mh'[], undefined>
): ColumnConfig[] => [
  {
    header: `${t('machines')}`,
    id: 'description',
    isEnableSorting: true,
    isSelected: true,
    renderer: ({ row, getValue }) => {
      const machineId = row.original.id as string;
      return (
        <TableCell
          color="blue"
          onClick={() => goto(JBTRoutes.machine.replace(':machineId', machineId))}
        >
          {/* <ActionButton
            hideArrow={true}
            style={{ justifyContent: 'center' }}
            onClick={() => goto(JBTRoutes.machine.replace(':machineId', machineId))}
          > */}
          {getValue()}
          {/* </ActionButton> */}
        </TableCell>
      );
    }
  },
  {
    header: `${t('issues_current')}`,
    id: 'numCurrentAlarms',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue()}</TableCell>
  },
  {
    header: `${t('issues_past_24h')}`,
    id: 'numAlarmsOverPeriod',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue()}</TableCell>
  },
  {
    header: `${t('running_past_24h')}`,
    id: 'running',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue() || 0}%</TableCell>
  },
  {
    header: `${t('cleaning_past_24h')}`,
    id: 'cleaning',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue() || 0}%</TableCell>
  },
  {
    header: `${t('idle_past_24h')}`,
    id: 'idle',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue() || 0}%</TableCell>
  },
  {
    header: `${t('paused_past_24h')}`,
    id: 'stopped',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue() || 0}%</TableCell>
  },
  {
    header: `${t('stop_by_alarm_past_24h')}`,
    id: 'stopAlarm',
    isEnableSorting: false,
    isSelected: true,
    renderer: ({ getValue }) => <TableCell title={getValue()}>{getValue() || 0}%</TableCell>
  }
];

export const addKeyProp = (data: MachineLineStatus[]): MachineLineStatus[] =>
  data.map((d, i) => {
    return { ...d, key: `line-${i}` };
  });
