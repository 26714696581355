import React from 'react';

export const Maint = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8632_19551)">
        <path
          d="M5.59998 16.7609H22.4V5.646H5.59998V25.4058"
          stroke="#DA7029"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_8632_19551)">
          <path
            d="M11.8222 10.3441H13.1055V9.06079L11.6083 7.56358C12.0872 7.33483 12.6253 7.2602 13.1484 7.34995C13.6716 7.43971 14.154 7.68944 14.5293 8.06475C14.9046 8.44006 15.1544 8.92249 15.2441 9.44562C15.3339 9.96875 15.2592 10.5068 15.0305 10.9858L17.5972 13.5524C17.7673 13.7226 17.8629 13.9534 17.8629 14.1941C17.8629 14.4348 17.7673 14.6656 17.5972 14.8358C17.427 15.0059 17.1962 15.1016 16.9555 15.1016C16.7148 15.1016 16.484 15.0059 16.3138 14.8358L13.7472 12.2691C13.2682 12.4979 12.7301 12.5725 12.207 12.4827C11.6839 12.393 11.2014 12.1433 10.8261 11.7679C10.4508 11.3926 10.2011 10.9102 10.1113 10.3871C10.0216 9.86394 10.0962 9.32585 10.3249 8.8469L11.8222 10.3441Z"
            stroke="#E66C37"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8632_19551">
          <rect width="28" height="28.2283" fill="white" />
        </clipPath>
        <clipPath id="clip1_8632_19551">
          <rect
            width="10.2667"
            height="10.2666"
            fill="white"
            transform="translate(8.82776 6.06641)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
