import React from 'react';

export const ResetIcon = (): JSX.Element => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.28711 15C9.08919 15 9.85742 14.8776 10.5918 14.6328C11.3314 14.3828 12.0033 14.0339 12.6074 13.5859C13.2168 13.1328 13.7454 12.5911 14.1934 11.9609C14.6465 11.3255 14.9902 10.6224 15.2246 9.85156L16.1777 10.1484C15.9173 11.0182 15.5293 11.8125 15.0137 12.5312C14.498 13.2448 13.8913 13.8594 13.1934 14.375C12.5007 14.8906 11.735 15.2917 10.8965 15.5781C10.0632 15.8594 9.19336 16 8.28711 16C7.57357 16 6.87826 15.9115 6.20117 15.7344C5.52409 15.5573 4.88346 15.3021 4.2793 14.9688C3.68034 14.6302 3.12565 14.2214 2.61523 13.7422C2.10482 13.2578 1.66211 12.7109 1.28711 12.1016V14H0.287109V10H4.28711V11H1.81836C2.12565 11.6094 2.50586 12.1615 2.95898 12.6562C3.41732 13.1458 3.92773 13.5651 4.49023 13.9141C5.05273 14.2578 5.6543 14.526 6.29492 14.7188C6.94076 14.9062 7.60482 15 8.28711 15ZM16.2871 2V6H12.2871V5H14.7559C14.4486 4.39062 14.0658 3.84115 13.6074 3.35156C13.1543 2.85677 12.6465 2.4375 12.084 2.09375C11.5215 1.74479 10.9173 1.47656 10.2715 1.28906C9.63086 1.09635 8.9694 1 8.28711 1C7.48503 1 6.71419 1.125 5.97461 1.375C5.24023 1.61979 4.56836 1.96875 3.95898 2.42188C3.35482 2.86979 2.82617 3.41146 2.37305 4.04688C1.92513 4.67708 1.58398 5.3776 1.34961 6.14844L0.396484 5.85156C0.656901 4.98698 1.04492 4.19531 1.56055 3.47656C2.07617 2.75781 2.68034 2.14062 3.37305 1.625C4.07096 1.10938 4.83659 0.710938 5.66992 0.429688C6.50846 0.143229 7.38086 0 8.28711 0C9.00065 0 9.69596 0.0885417 10.373 0.265625C11.0501 0.442708 11.6882 0.700521 12.2871 1.03906C12.8913 1.3724 13.4486 1.78125 13.959 2.26562C14.4694 2.74479 14.9121 3.28906 15.2871 3.89844V2H16.2871Z"
      fill="#0A70FF"
    />
  </svg>
);
