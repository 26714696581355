// 3rd party libraries
import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Components
import MaintenanceServiceDashboardContents from '../MaintenanceServiceDashboard/MaintenanceServiceDashboardContents';
import { Loader } from 'components';

import { FleetNavigationEntityType, useFleetNavigation } from 'providers';

// Types
import { NAV_ITEMS_TO_ID } from 'constants/nav';

// API
import { useGetMaintenanceEventsQuery } from 'api';

export default function MaintenanceServiceMachineDashboard(): ReactElement {
  const { machineId } = useParams<{ machineId: string }>();
  // const { data: machine, isLoading: machineIsLoading } = useGetMachineByIdQuery(machineId);
  const { data: allEvents, isLoading: eventsLoading } = useGetMaintenanceEventsQuery({
    machineIds: machineId ? [machineId] : [],
    limit: 1
  });

  const navContext = useFleetNavigation();

  useEffect(() => {
    machineId &&
      navContext.updateEntityIfNeeded({ type: FleetNavigationEntityType.MACHINE, id: machineId });
    navContext.updateNavIdIfNeeded(NAV_ITEMS_TO_ID.maintenance);
  }, [machineId, navContext]);

  return eventsLoading || !allEvents ? (
    <Loader />
  ) : (
    <MaintenanceServiceDashboardContents machineId={machineId} />
  );
}
