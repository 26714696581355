import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg.attrs(({ width }) => ({
  width: width || `100%`,
  height: width || `auto`,
  viewBox: '0 0 16 16',
  fill: 'none'
}))``;

export const IcoMinus = ({
  width,
  color,
  srLabel
}: {
  width?: string;
  color?: string;
  srLabel?: string;
}): JSX.Element => {
  return (
    <div>
      <Svg
        {...{ width, color }}
        className="icon--pencil icon"
        focusable="true"
        aria-label={srLabel || `edit`}
      >
        <path
          d="M6 8H10"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 2C12.8 2 14 3.2 14 8C14 12.8 12.8 14 8 14C3.2 14 2 12.8 2 8C2 3.2 3.2 2 8 2Z"
          stroke={color ? color : '#0076CC'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </div>
  );
};
