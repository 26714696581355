import React from 'react';
import { Typography } from 'components';
import { useTranslation } from 'react-i18next';
import Asterisk from './Asterisk ';
import { Dropdown } from './Dropdown';
import { AlertEnumTypes, TAlertData } from 'types/machine-health/alerts';

import { ValidationErrors } from '../CreateAlert';

import theme from 'themes';
import { ErrorBorderWrapper, InputWrapper, RequiredMessageWrapper } from './index.elements';
import { IcoError } from 'icons/IcoError';

interface ISlidingWindow {
  alertFrequencyUnits: AlertEnumTypes['alertFrequencyUnits'];
  alertData: TAlertData;
  onDropdownChange: (key: string, value: string | number | boolean, place?: string) => void;
  onBlurHandle: (key: string) => void;
  validationState: ValidationErrors;
  inputValidationsErrors: boolean;
}

export const SlidingWindow = ({
  alertFrequencyUnits,
  alertData,
  onDropdownChange,
  inputValidationsErrors
}: ISlidingWindow): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const onDropdownValuesChange = (val: string) => {
    onDropdownChange('slidingWindowUnits', val);
  };

  const onWindowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0) {
      onDropdownChange('slidingWindowValue', e.target.value);
    }
  };

  return (
    <div className="frequency_container">
      <div className="dropdown-container--label label">
        <Typography variant="inputlabel">
          <p className="label label-fields">
            {t('alert_sliding_window') as string} <Asterisk />
          </p>
        </Typography>
      </div>

      <InputWrapper className="inputHoverSec">
        <input
          type="number"
          name="slidingWindowValue"
          min={0}
          value={alertData.slidingWindowValue}
          onChange={onWindowChange}
          placeholder="Value"
          style={{
            border: inputValidationsErrors
              ? alertData?.slidingWindowValue === undefined || alertData.slidingWindowValue < 1
                ? `0.063rem solid ${theme.colors.text.error}`
                : '0.063rem solid #e5e9ed'
              : '0.063rem solid #e5e9ed',
            borderRadius: '0.375rem 0rem 0rem 0.438rem'
          }}
        />

        <ErrorBorderWrapper
          className="customSelectHover"
          style={{
            width: '8.2rem'
          }}
          error={inputValidationsErrors && alertData.slidingWindowUnits === undefined}
          hideLeftBorder={
            inputValidationsErrors
              ? alertData?.slidingWindowValue === undefined || alertData?.slidingWindowValue < 1
              : false
          }
        >
          <Dropdown
            vals={alertFrequencyUnits}
            onDropdownChange={onDropdownValuesChange}
            value={alertData.slidingWindowUnits}
          />
        </ErrorBorderWrapper>
      </InputWrapper>
      {inputValidationsErrors ? (
        alertData?.slidingWindowValue === undefined ||
        alertData.slidingWindowUnits === undefined ||
        (alertData.slidingWindowValue as number) < 1 ? (
          <RequiredMessageWrapper className="label">
            <IcoError />{' '}
            <span>
              {alertData?.slidingWindowValue === undefined ||
              (alertData?.slidingWindowValue as number) < 1
                ? Number(alertData?.slidingWindowValue as number) === 0
                  ? (alertData?.slidingWindowValue as unknown as string) === ''
                    ? t('please-add-sliding-window')
                    : t('sliding-window-cannot-be-0')
                  : t('please-add-sliding-window')
                : t('please-add-sliding-window-unit')}
            </span>
          </RequiredMessageWrapper>
        ) : null
      ) : null}
    </div>
  );
};
