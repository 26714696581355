// 3rd party libs
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { DateRangeProvider, WarningPrompt, Input } from 'components';
import { Button } from 'components/Button';
import Slider from './Slider';
import AlertSetup from '../Step3/AlertSetup';
import { AlertSimulation } from '../Step3/AlertSimulation';
import AlertLogic from './AlertLogic';
import { DropdownSubOptions } from '../Step3/DropdownSubOptions';
import { useTranslation } from 'react-i18next';
import { DropDownItem } from 'pages/ProteinMachine/MachineConfig/Common/Alerts/FormElementsTypes';
import { useLanguage, useTimeZone } from 'providers';
import Asterisk from '../Step3/Asterisk ';
import {
  Footer,
  FooterButtonContainer,
  FormContainer,
  FormItem,
  FormParent,
  FormSecondRowItem,
  FormSecondRowItemDiv,
  PageContainer,
  RequiredTextMessageWrapper,
  SecondFormContainer,
  StyledInputField,
  StyledTextarea
} from './index.elements';
import {
  useGetAlertByIdQuery,
  useGetAlertEnumsQuery,
  useGetAlertDestinationDropdownValuesQuery,
  useGetMachineTagListBasedOnIdQuery,
  useSaveAlertMutation,
  useUpdateAlertByIdMutation,
  useGetMachineConfiguratorDataQuery,
  useCreateMachineConfigurationDataMutation
} from 'api';
import { useTheme } from 'styled-components';
import { isEmpty } from 'lodash';
import { convertToSeconds, isOnlyAlphaNumeric } from 'helpers';
import {
  ALERT_STATE,
  AlertEnumTypes,
  TAlertData,
  TReminder,
  TTrigger,
  queryBuilderDesireRule
} from 'types/machine-health/alerts';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { LogicGroup2 } from 'components/AlertQueryBuilder';
import AlertHeader from './AlertHeader';
import { RequiredMessageWrapper } from '../Step3/index.elements';
import { IcoError } from 'icons/IcoError';
import { ToastMsg } from 'common/components/Toast/Toast';
import { MachineHealthSubTabs, WidgetType } from 'types/protein';
import { v4 as uuidv4 } from 'uuid';
import CustomLoader from 'components/CustomLoader';
import { LoadingWrapper } from '../AlertsTab/AlertsTable.element';

type PRIORITY = AlertEnumTypes['alertPriority'][number];
type SLIDING_WINDOW_UNITS = AlertEnumTypes['slidingWindowUnits'][number];
type ALERT_FREQUENCY_UNITS = AlertEnumTypes['alertFrequencyUnits'][number];
type REMINDER_UNITS = AlertEnumTypes['reminderUnits'][number];
type TRIGGER_TYPE = AlertEnumTypes['triggerType'][number];
type TRIGGER_UNITS = AlertEnumTypes['triggerUnits'][number];

export type ValidationErrors = {
  name?: string | null;
  message?: string | null;
  frequency?: string | null;
  slidingWindow?: string | null;
};

export type THandleType =
  | string
  | number
  | boolean
  | REMINDER_UNITS
  | ALERT_FREQUENCY_UNITS
  | SLIDING_WINDOW_UNITS;

type TParams = {
  alertId: string;
  machineId: string;
};

const CreateAlertPage = (): JSX.Element => {
  const { timeZone } = useTimeZone();
  const { t } = useTranslation(['mh']);
  const theme = useTheme();
  const history = useHistory();
  const params: TParams = useParams();
  const { machineId } = useFleetMachineAccountData();
  const MACHINE_ID = machineId;
  const { languageId } = useLanguage();
  const PRE_SELECTED_ALERT_DESTINATION = 'MH_OV_ISSUES';
  const [validationState, setValidationState] = useState<ValidationErrors>({
    name: null,
    message: null,
    frequency: null,
    slidingWindow: null
  });

  const [saveAlert] = useSaveAlertMutation();
  const [selectSubComponent, setSelectSubComponent] = useState('');
  const [alertDestination, setAlertDestination] = useState<DropDownItem[]>([]);
  const [isReminderEnabled, setIsReminderEnabled] = useState(false);
  const [isAlertSubmitting, setIsAlertSubmitting] = useState(false);
  const [inputValidationsErrors, setInputValidationsErrors] = useState(false);
  const {
    data: AlertDataById,
    isFetching: isAlertDataFetching,
    isError
  } = useGetAlertByIdQuery(params.alertId ? { alertId: params.alertId } : skipToken);

  const [updateAlertById] = useUpdateAlertByIdMutation();

  const [reminderValues, setReminderValues] = useState<TReminder>({
    value: undefined,
    units: undefined,
    stop_after: undefined,
    alertId: undefined,
    id: undefined
  });

  const [triggerValues, setTriggerValues] = useState<TTrigger>({
    type: 'MATCHES',
    value: undefined,
    units: undefined,
    alertId: undefined,
    id: undefined
  });

  const [alertData, setAlertData] = useState<TAlertData>({
    name: undefined,
    description: undefined,
    message: undefined,
    state: 'ENABLED',
    priority: 'MEDIUM',
    slidingWindowValue: undefined,
    slidingWindowUnits: undefined,
    needsAcknowledgment: false,
    frequencyValue: undefined,
    frequencyUnits: undefined,
    rootLogicGroup: undefined,
    machineId: undefined,
    trigger: triggerValues,
    id: undefined,
    widgetIds: []
  });

  const [queryData, setQueryData] = useState<LogicGroup2 | undefined>(undefined);
  const [alertIncrementId, setAlertIncrementId] = useState<number | undefined>(undefined);
  const [createMachineConfigurationData] = useCreateMachineConfigurationDataMutation();

  const { data: MasterTagList, isFetching: isMasterTagListFetching } =
    useGetMachineTagListBasedOnIdQuery(
      MACHINE_ID
        ? {
            id: MACHINE_ID
          }
        : skipToken
    );

  const { data: EnumsTypes, isFetching: isAlertTypeFetching } = useGetAlertEnumsQuery();

  const { data: overviewWidgetData } = useGetMachineConfiguratorDataQuery(
    machineId
      ? {
          machineId,
          labels: [MachineHealthSubTabs.CLESingleSession],
          showInactive: true
        }
      : skipToken
  );

  const { data: AlertDestinationList, isLoading: isLoadingAlertDestinationList } =
    useGetAlertDestinationDropdownValuesQuery(
      MACHINE_ID
        ? {
            machineId: `${MACHINE_ID}`,
            widgetTypes: ['alerts_widget', 'current_issues'],
            labels: [''],
            languageId: languageId
          }
        : skipToken
    );

  const MasterTagDropdownList = useMemo(() => {
    if (!isMasterTagListFetching) {
      if (MasterTagList && MasterTagList.length > 0) {
        return MasterTagList.map((e) => ({
          label: e.name,
          value: e.id,
          isFixed: true
        }));
      } else {
        return [
          { label: t('no_tag_template_found'), value: t('no_tag_template_found'), isFixed: true }
        ];
      }
    }

    return [];
  }, [MasterTagList, isMasterTagListFetching]);

  const AlertDestinationDropdownList = useMemo(() => {
    if (!isLoadingAlertDestinationList) {
      if (AlertDestinationList) {
        if (!params.alertId) {
          const OverviewTabData = AlertDestinationList?.find(
            (destination) => destination.widgetLabel === PRE_SELECTED_ALERT_DESTINATION
          );
          if (OverviewTabData) {
            setAlertData({ ...alertData, widgetIds: [OverviewTabData.value] });
            setAlertDestination([{ ...OverviewTabData, isFixed: true, isDisabled: true }]);
          } else {
            setAlertData({ ...alertData, widgetIds: [] });
            setAlertDestination([]);
          }
        }

        return AlertDestinationList.filter((destination) => {
          if (overviewWidgetData && overviewWidgetData[0]?.active === false) {
            return destination.widgetLabel !== MachineHealthSubTabs.CLESingleSessionISS;
          }
          return destination;
        }).map((alertDest) =>
          alertDest.widgetLabel === PRE_SELECTED_ALERT_DESTINATION
            ? {
                label: alertDest.label,
                value: alertDest.value,
                isFixed: true,
                isDisabled: true
              }
            : {
                label: alertDest.label,
                value: alertDest.value
              }
        );
      } else {
        return [];
      }
    }

    return [];
  }, [AlertDestinationList, isLoadingAlertDestinationList]);

  const AlertUnitTypes = useMemo(() => {
    if (!isAlertTypeFetching) {
      if (EnumsTypes) {
        return EnumsTypes;
      } else {
        return {} as AlertEnumTypes;
      }
    }

    return {} as AlertEnumTypes;
  }, [EnumsTypes, isAlertTypeFetching]);

  const path = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const dropdownLoading =
    (overviewWidgetData && overviewWidgetData[0]?.active === false) ||
    (overviewWidgetData && overviewWidgetData?.length === 0) ||
    (overviewWidgetData &&
      overviewWidgetData[0]?.members?.find(
        (item) => item.label === MachineHealthSubTabs.CLESingleSessionISS
      ))
      ? false
      : true;

  const onHandleChange = (key: string, value: THandleType) => {
    let priorityValue: PRIORITY;
    switch (key) {
      case 'priority':
        switch (value) {
          case 1:
            priorityValue = 'HIGH';
            break;
          case 2:
            priorityValue = 'MEDIUM';
            break;
          case 3:
            priorityValue = 'LOW';
            break;
          default:
            priorityValue = 'MEDIUM';
        }
        setAlertData({ ...alertData, [key]: priorityValue });
        break;
      case 'state':
        switch (value as boolean) {
          case true:
            setAlertData({ ...alertData, [key]: 'ENABLED' });
            break;
          default:
            setAlertData({ ...alertData, [key]: 'DISABLED' });
        }
        break;
      case 'description':
        if (value === '') {
          setAlertData({ ...alertData, [key]: undefined });
        } else {
          setAlertData({ ...alertData, [key]: value.toString() });
        }
        break;
      default:
        setAlertData({ ...alertData, [key]: value });
    }
  };

  const onReminderHandleChange = (key: string, value: string | number) => {
    switch (key) {
      case 'units':
        setReminderValues({ ...reminderValues, [key]: value as REMINDER_UNITS });
        break;
      default:
        if (value === '') {
          setReminderValues({ ...reminderValues, [key]: 1 });
        } else {
          setReminderValues({ ...reminderValues, [key]: Number(value) });
        }
    }
  };

  const onTriggerHandleChange = (key: string, value: string | number) => {
    switch (key) {
      case 'type':
        setTriggerValues({
          ...triggerValues,
          [key]: value as TRIGGER_TYPE,
          ['value']: undefined,
          ['units']: undefined
        });
        setAlertData({
          ...alertData,
          trigger: {
            ...triggerValues,
            [key]: value as TRIGGER_TYPE,
            ['value']: undefined,
            ['units']: undefined
          }
        });
        break;
      case 'units':
        setTriggerValues({ ...triggerValues, [key]: value as TRIGGER_UNITS });
        setAlertData({
          ...alertData,
          trigger: {
            ...triggerValues,
            [key]: value as TRIGGER_UNITS
          }
        });
        break;
      default:
        if (Number(value) >= 0) {
          setTriggerValues({ ...triggerValues, [key]: value });
          setAlertData({
            ...alertData,
            trigger: {
              ...triggerValues,
              [key]: value
            }
          });
        }
    }
  };

  const onBlurHandle = (key: string) => {
    const validationErrors: ValidationErrors = {};
    switch (key) {
      case 'name':
        if (isEmpty(alertData.name && (alertData.name.trim() as string))) {
          validationErrors.name = t('alert_name_is_required');
        } else if (
          typeof alertData.name !== undefined &&
          alertData.name &&
          !isOnlyAlphaNumeric(alertData.name)
        ) {
          validationErrors.name = t('alert_name_is_invalid');
        } else {
          validationErrors.name = null;
        }
        setValidationState((prev) => {
          const newValues = { ...prev, ...validationErrors };
          return newValues;
        });
        break;
      case 'message':
        if (isEmpty(alertData.message)) {
          validationErrors.message = t('alert_message_is_required');
        } else {
          validationErrors.message = null;
        }
        setValidationState((prev) => {
          const newValues = { ...prev, ...validationErrors };
          return newValues;
        });
        break;
      case 'frequency': {
        if (Number(alertData.frequencyValue) === 0 || !alertData.frequencyValue) {
          (alertData.frequencyValue as unknown as string) === '' || !alertData.frequencyValue
            ? (validationErrors.frequency = 'Frequency is Required')
            : (validationErrors.frequency = 'Frequency cannot be 0');
        } else {
          validationErrors.frequency = null;
        }
        setValidationState((prev) => {
          const newValues = { ...prev, ...validationErrors };
          return newValues;
        });
        break;
      }
      case 'slidingWindow':
        if (Number(alertData.slidingWindowValue) === 0 || !alertData.slidingWindowValue) {
          (alertData.slidingWindowValue as unknown as string) === '' ||
          !alertData.slidingWindowValue
            ? (validationErrors.slidingWindow = 'Sliding Window is Required')
            : (validationErrors.slidingWindow = 'Sliding Window cannot be 0');
        } else {
          validationErrors.slidingWindow = null;
        }
        setValidationState((prev) => {
          const newValues = { ...prev, ...validationErrors };
          return newValues;
        });
        break;
    }
  };

  const onAlertDestinationChange = (selectedValue: DropDownItem[]) => {
    setAlertDestination(selectedValue);
    const ids: string[] = selectedValue.map((dest) => `${dest.value}`);
    setAlertData({ ...alertData, widgetIds: ids });
  };

  function checkAnyValueShouldNotBeZero() {
    let status = false;

    if (
      alertData?.trigger?.value &&
      alertData?.trigger?.type === 'MATCHES' &&
      alertData.frequencyValue &&
      alertData.frequencyUnits &&
      alertData.slidingWindowValue &&
      alertData.slidingWindowUnits
    ) {
      const frequencyInSeconds = convertToSeconds(
        alertData.frequencyValue,
        alertData.frequencyUnits
      );

      const slidingWindowInSeconds = convertToSeconds(
        alertData.slidingWindowValue,
        alertData.slidingWindowUnits
      );

      if (frequencyInSeconds !== null && slidingWindowInSeconds !== null) {
        if (alertData.trigger.value * frequencyInSeconds > slidingWindowInSeconds) {
          status = true;
          ToastMsg({
            message: `The number of "matches * frequency" cannot exceed sliding window`,
            type: 'error',
            position: 'top-right'
          });
        }
      } else {
        // Handle the case where either conversion result is null
        console.error('Error converting units to seconds.');
      }
    }

    if (
      alertData?.trigger?.type === 'DURATION' &&
      alertData?.trigger?.value &&
      alertData?.trigger?.units &&
      alertData.slidingWindowValue &&
      alertData.slidingWindowUnits
    ) {
      const slidingWindowInSeconds = convertToSeconds(
        alertData.slidingWindowValue,
        alertData.slidingWindowUnits
      );

      const durationInSeconds = convertToSeconds(alertData.trigger.value, alertData.trigger.units);

      if (slidingWindowInSeconds !== null && durationInSeconds !== null) {
        if (durationInSeconds > slidingWindowInSeconds) {
          status = true;
          return ToastMsg({
            message: `The Trigger Duration cannot exceed sliding window.`,
            type: 'error',
            position: 'top-right'
          });
        }
      } else {
        // Handle the case where either conversion result is null
        console.error('Error converting units to seconds.');
      }
    }
    return status;
  }

  const goToAlertsView = async () => {
    if (fieldValidation()) {
      ToastMsg({
        message: 'Please fix all errors',
        type: 'error',
        position: 'top-right'
      });
      setInputValidationsErrors(true);
    } else {
      if (checkAnyValueShouldNotBeZero()) return;
      if (params.alertId) {
        setIsAlertSubmitting(true);
        try {
          await updateAlertById(alertData).unwrap();
          ToastMsg({
            message: 'Alert updated successfully',
            type: 'success',
            position: 'top-right'
          });
          setTimeout(() => {
            history.push(`/fleet/machine/${machineId}/alerts/manage-alerts`);
          }, 1000);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const ErrorMessage = error?.data
            ? error.data && error.data?.detail
            : 'Failed to Save an Alert!!';
          if (ErrorMessage) {
            ToastMsg({
              message: ErrorMessage,
              type: 'error',
              position: 'top-right'
            });
            console.error(error);
          } else {
            ToastMsg({
              message: 'Failed to Save an Alert!!',
              type: 'error',
              position: 'top-right'
            });
            console.error(error);
          }
        }
      } else {
        if (typeof machineId === 'undefined') {
          return ToastMsg({
            message: 'machineId is missing!!',
            type: 'warning',
            position: 'top-right'
          });
        }
        try {
          setIsAlertSubmitting(true);
          await saveAlert({ data: alertData, machineId: MACHINE_ID }).unwrap();
          ToastMsg({
            message: 'Alert added successfully',
            type: 'success',
            position: 'top-right'
          });
          setInputValidationsErrors(false);
          setTimeout(() => {
            history.push(`/fleet/machine/${machineId}/alerts/manage-alerts`);
          }, 1000);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          const ErrorMessage = error?.data
            ? error.data && error.data?.detail
            : 'Failed to Save an Alert!!';
          if (ErrorMessage) {
            ToastMsg({
              message: ErrorMessage,
              type: 'error',
              position: 'top-right'
            });
            console.error(error);
          } else {
            ToastMsg({
              message: 'Failed to Save an Alert!!',
              type: 'error',
              position: 'top-right'
            });
            console.error(error);
          }
          setInputValidationsErrors(false);
        }
      }
    }
  };

  const getAlertQueryData = (data: queryBuilderDesireRule) => {
    setAlertData({ ...alertData, rootLogicGroup: data });
  };

  // to check if Alert logic is valid
  const isInvalidAlertLogic = (logicGroup: queryBuilderDesireRule) => {
    if (logicGroup) {
      if (logicGroup?.logic_groups.length === 0) {
        if (logicGroup?.logic_statements.length !== 0) {
          for (const statement of logicGroup?.logic_statements) {
            if (
              statement.tag_name === '' ||
              statement.tag_name === '~' ||
              statement.comparison_operator === '=' ||
              statement.comparison_operator === '' ||
              statement.logic_statement_values[0]?.value === '' ||
              statement.logic_statement_values[1]?.value === '' ||
              (statement.comparison_operator === 'BETWEEN' &&
                statement.logic_statement_values.length !== 2) ||
              (statement.comparison_operator === 'BETWEEN' &&
                Number(statement?.logic_statement_values[1]?.value as number) >
                  Number(statement?.logic_statement_values[0]?.value as number)) ||
              (statement.comparison_operator === 'NOT_BETWEEN' &&
                Number(statement?.logic_statement_values[1]?.value as number) >
                  Number(statement?.logic_statement_values[0]?.value as number)) ||
              (statement.comparison_operator === 'NOT_BETWEEN' &&
                statement.logic_statement_values.length !== 2) ||
              (statement.logic_statement_values[0]?.tag_name === undefined &&
                statement.logic_statement_values[0]?.business_unit_id) ||
              (statement.logic_statement_values[1]?.tag_name === undefined &&
                statement.logic_statement_values[1]?.business_unit_id)
            ) {
              return true;
            }
          }
        } else {
          return true;
        }
      } else {
        // If there are nested logic groups, recursively check them
        for (const group of logicGroup?.logic_groups) {
          if (isInvalidAlertLogic(group)) {
            return true;
          }
        }
      }

      if (logicGroup.logic_statements) {
        for (const statement of logicGroup?.logic_statements) {
          if (
            statement.tag_name === '' ||
            statement.tag_name === '~' ||
            statement.comparison_operator === '=' ||
            statement.comparison_operator === '' ||
            statement.logic_statement_values[0]?.value === '' ||
            statement.logic_statement_values[1]?.value === '' ||
            (statement.comparison_operator === 'BETWEEN' &&
              statement.logic_statement_values.length !== 2) ||
            (statement.comparison_operator === 'BETWEEN' &&
              Number(statement?.logic_statement_values[1]?.value as number) >
                Number(statement?.logic_statement_values[0]?.value as number)) ||
            (statement.comparison_operator === 'NOT_BETWEEN' &&
              statement.logic_statement_values.length !== 2) ||
            (statement.comparison_operator === 'NOT_BETWEEN' &&
              Number(statement?.logic_statement_values[1]?.value as number) >
                Number(statement?.logic_statement_values[0]?.value as number)) ||
            (statement.logic_statement_values[0]?.tag_name === undefined &&
              statement.logic_statement_values[0]?.business_unit_id) ||
            (statement.logic_statement_values[1]?.tag_name === undefined &&
              statement.logic_statement_values[1]?.business_unit_id)
          ) {
            return true;
          }
        }
      } else {
        return true;
      }

      return false;
    }
  };

  const isAlertLogicInvalid: boolean = useMemo(() => {
    return isInvalidAlertLogic(
      alertData && (alertData.rootLogicGroup as queryBuilderDesireRule)
    ) as boolean;
  }, [alertData.rootLogicGroup]);

  const fieldValidation = (): boolean => {
    const require =
      isEmpty(alertData.name && (alertData.name.trim() as string)) ||
      (alertData.name && !isOnlyAlphaNumeric(alertData.name)) ||
      isEmpty(alertData.message) ||
      isEmpty(alertData.frequencyUnits) ||
      Number(alertData.slidingWindowValue) === 0 ||
      Number(alertData.frequencyValue) === 0 ||
      typeof alertData.frequencyValue === 'undefined' ||
      isEmpty(alertData.slidingWindowUnits) ||
      isAlertLogicInvalid;

    if (alertData.trigger?.type === 'MATCHES') {
      return (
        Number(alertData.trigger?.value) <= 0 || alertData.trigger?.value === undefined || require
      );
    }
    if (alertData.trigger?.type === 'DURATION') {
      return (
        Number(alertData.trigger?.value) <= 0 || alertData.trigger?.units === undefined || require
      );
    }

    if ('reminder' in alertData) {
      return (
        reminderValues.value === undefined ||
        reminderValues.units === undefined ||
        reminderValues.stop_after === undefined ||
        require
      );
    }
    return require;
  };

  const AlertDestinationFunc = useCallback(() => {
    if (AlertDestinationDropdownList.length !== 0 && AlertDataById) {
      const selectedValues = AlertDestinationDropdownList?.filter(({ value }) =>
        AlertDataById.widgetIds.includes(value)
      );
      selectedValues && setAlertDestination(selectedValues);
    }
  }, [AlertDestinationDropdownList, AlertDataById]);

  useMemo(() => {
    AlertDestinationFunc();
  }, [AlertDestinationDropdownList, AlertDataById]);

  const onHandleNeedAcknowledgment = (value: boolean) => {
    setAlertData({
      ...alertData,
      ['needsAcknowledgment']: value
    });
  };

  useEffect(() => {
    if (!isAlertDataFetching && !isError && params.alertId) {
      if (AlertDataById) {
        setAlertData({
          ...alertData,
          ['name']: AlertDataById.name,
          ['message']: AlertDataById.message,
          ['machineId']: AlertDataById.machineId as string,
          ['id']: AlertDataById.id as string,
          ['description']: AlertDataById.description ? AlertDataById.description : undefined,
          ['state']: AlertDataById.state as ALERT_STATE,
          ['priority']: AlertDataById.priority as PRIORITY,
          ['frequencyValue']: AlertDataById.frequencyValue as number,
          ['frequencyUnits']: AlertDataById.frequencyUnits as ALERT_FREQUENCY_UNITS,
          ['slidingWindowValue']: AlertDataById.slidingWindowValue as number,
          ['slidingWindowUnits']: AlertDataById.slidingWindowUnits as SLIDING_WINDOW_UNITS,
          ['widgetIds']: AlertDataById.widgetIds,
          ['needsAcknowledgment']: AlertDataById.needsAcknowledgment as boolean,
          ['trigger']: {
            ['type']: AlertDataById.trigger.type as TRIGGER_TYPE,
            ['value']: AlertDataById.trigger.value as number,
            ['units']: AlertDataById.trigger.units as TRIGGER_UNITS,
            ['alertId']: AlertDataById.trigger.alertId as string,
            ['id']: AlertDataById.trigger.id as string
          } as TTrigger
        });
        setAlertIncrementId(AlertDataById.incrementId as number);

        setTriggerValues({
          ...triggerValues,
          ['type']: AlertDataById.trigger.type as TRIGGER_TYPE,
          ['value']: AlertDataById.trigger.value as number,
          ['units']: AlertDataById.trigger.units as TRIGGER_UNITS,
          ['alertId']: AlertDataById.trigger.alertId as string,
          ['id']: AlertDataById.trigger.id as string
        });
        setQueryData(AlertDataById.rootLogicGroup as LogicGroup2);
        AlertDestinationFunc();
        if (AlertDataById.reminder !== null) {
          setIsReminderEnabled(true);
          setReminderValues({
            ...reminderValues,
            ['stop_after']: AlertDataById.reminder.stopAfter as number,
            ['value']: AlertDataById.reminder.value as number,
            ['units']: AlertDataById.reminder.units as REMINDER_UNITS,
            ['alertId']: AlertDataById.reminder.alertId as string,
            ['id']: AlertDataById.reminder.id as string
          });
          setAlertData({
            ...alertData,
            ['name']: AlertDataById.name,
            ['message']: AlertDataById.message,
            ['machineId']: AlertDataById.machineId as string,
            ['id']: AlertDataById.id as string,
            ['description']: AlertDataById.description ? AlertDataById.description : undefined,
            ['state']: AlertDataById.state as ALERT_STATE,
            ['priority']: AlertDataById.priority as PRIORITY,
            ['frequencyValue']: AlertDataById.frequencyValue as number,
            ['frequencyUnits']: AlertDataById.frequencyUnits as ALERT_FREQUENCY_UNITS,
            ['slidingWindowValue']: AlertDataById.slidingWindowValue as number,
            ['slidingWindowUnits']: AlertDataById.slidingWindowUnits as SLIDING_WINDOW_UNITS,
            ['widgetIds']: AlertDataById.widgetIds,
            ['needsAcknowledgment']: AlertDataById.needsAcknowledgment as boolean,
            ['reminder']: {
              ['stop_after']: AlertDataById.reminder.stopAfter as number,
              ['value']: AlertDataById.reminder.value as number,
              ['units']: AlertDataById.reminder.units as REMINDER_UNITS,
              ['alertId']: AlertDataById.reminder.alertId as string,
              ['id']: AlertDataById.reminder.id as string
            }
          });
          setAlertIncrementId(AlertDataById.incrementId as number);
        }
      }
    }
  }, [isAlertDataFetching, params.alertId]);

  useEffect(() => {
    if (isReminderEnabled) {
      setAlertData({ ...alertData, reminder: { ...reminderValues } });
    } else {
      setAlertData((prevAlertData) => {
        const updatedAlertData = { ...prevAlertData };
        delete updatedAlertData.reminder; // Remove the 'reminder' key
        return updatedAlertData;
      });
    }
  }, [isReminderEnabled, reminderValues.value, reminderValues.units, reminderValues.stop_after]);

  const createWidgetConfiguration = async (parent_id: string) => {
    if (machineId) {
      await createMachineConfigurationData({
        machineId: machineId,
        configuration: {
          id: uuidv4(),
          name: 'Issues Selected Session',
          names: {
            en: 'Issues Selected Session'
          },
          label: MachineHealthSubTabs.CLESingleSessionISS,
          widgetClass: 'widget',
          widgetType: WidgetType.Alerts,
          editable: false,
          deletable: false,
          active: true,
          toggleActive: true,
          reorder: false,
          parent_id,
          tags: []
        },
        languageId: languageId
      })
        .unwrap()
        .catch((error) => {
          console.error(error?.data?.detail);
        });
    }
  };

  useLayoutEffect(() => {
    if (
      overviewWidgetData &&
      overviewWidgetData.length > 0 &&
      overviewWidgetData[0]?.active === true
    ) {
      const targetItem = overviewWidgetData[0].members?.find(
        (item) => item.label === MachineHealthSubTabs.CLESingleSession
      );

      if (!targetItem) {
        // POST call to add widget data
        createWidgetConfiguration(overviewWidgetData[0].id);
      }
    }
  }, [overviewWidgetData]);

  if (isAlertDataFetching) {
    return (
      <LoadingWrapper>
        <CustomLoader size={'4rem'} thickness="0.3rem" />
      </LoadingWrapper>
    );
  }

  return (
    <PageContainer>
      <FormParent>
        <AlertHeader
          alertData={alertData}
          alertIncrementId={alertIncrementId}
          onHandleChange={onHandleChange}
          onHandleNeedAcknowledgment={onHandleNeedAcknowledgment}
        />
        <FormContainer>
          <FormItem>
            <Input
              label={t('alert_name2') as string}
              type="text"
              value={alertData.name}
              mandatory
              borderVariant={
                inputValidationsErrors
                  ? alertData.name == undefined || alertData?.name?.trim().length === 0
                    ? 'error'
                    : 'black'
                  : 'black'
              }
              placeholder={t('choose_name_for_alert') as string}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onHandleChange('name', e.target.value)
              }
              variant="white-dark"
              errorMessage={
                inputValidationsErrors
                  ? alertData.name == undefined || alertData?.name?.trim().length === 0
                    ? (t('alert_name_is_required') as string)
                    : !isOnlyAlphaNumeric(alertData?.name)
                    ? (t('alert_name_is_invalid') as string)
                    : undefined
                  : undefined
              }
            />
          </FormItem>
          <FormItem>
            <p
              className="label"
              style={{
                fontSize: '0.875rem',
                marginBottom: '0.4rem',
                fontWeight: '500',
                marginTop: '0'
              }}
            >
              {t('alert_description2') as string}
            </p>
            <StyledTextarea
              rows={5}
              value={alertData.description}
              style={{
                resize: 'none',
                height: '75px',
                padding: '12px'
              }}
              placeholder={t('provide_alert_description') as string}
              onChange={(e) => {
                onHandleChange('description', e.target.value);
              }}
            />
          </FormItem>
          {process.env.REACT_APP_ENABLE_CALCULATED_TAGS_TAB === 'true' ? (
            <FormItem>
              <p
                className="label"
                style={{
                  fontSize: '0.875rem',
                  marginBottom: '0.4rem',
                  fontWeight: '500',
                  marginTop: '0'
                }}
              >
                {t('tag_templates') as string}
              </p>
              {MasterTagDropdownList.length > 0 ? (
                <DropdownSubOptions
                  ariaLabel={t('choose_a_destination') as string}
                  options={MasterTagDropdownList}
                  value={MasterTagDropdownList}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  handleMultiSelect={() => {}}
                  placeholder={t('choose_a_destination') as string}
                  disabled
                />
              ) : (
                <CustomLoader size={'2rem'} thickness="0.2rem" />
              )}
            </FormItem>
          ) : null}
          {process.env.REACT_APP_ENABLE_ALERTS_TAB === 'true' ? (
            <FormItem>
              <p
                className="label"
                style={{
                  fontSize: '0.875rem',
                  marginBottom: '0.4rem',
                  fontWeight: '500',
                  marginTop: '0'
                }}
              >
                {t('alert_destination') as string}
              </p>
              {!dropdownLoading ? (
                <DropdownSubOptions
                  ariaLabel={t('choose_a_destination') as string}
                  options={AlertDestinationDropdownList}
                  handleMultiSelect={onAlertDestinationChange}
                  value={alertDestination}
                  placeholder={
                    AlertDestinationDropdownList?.length === 0
                      ? 'No Alert Destination Found'
                      : `${t('choose_a_destination')}`
                  }
                  disabled={
                    isLoadingAlertDestinationList || AlertDestinationDropdownList?.length === 0
                  }
                  hasError={alertDestination.length < 2}
                />
              ) : (
                <CustomLoader size={'2rem'} thickness="0.2rem" />
              )}
            </FormItem>
          ) : null}
        </FormContainer>
        <SecondFormContainer>
          <FormSecondRowItem>
            <p
              className="label"
              style={{
                fontSize: '0.875rem',
                marginBottom: '0.4rem',
                fontWeight: '500'
              }}
            >
              {t('alert_message') as string} <Asterisk />
            </p>
            <StyledTextarea
              rows={5}
              value={alertData.message}
              style={{
                resize: 'none',
                height: '75px',
                padding: '12px'
              }}
              error={
                inputValidationsErrors
                  ? alertData?.message === undefined || alertData.message.trim().length === 0
                    ? true
                    : false
                  : false
              }
              placeholder={t('text_to_display_in_ui') as string}
              onChange={(e) => onHandleChange('message', e.target.value)}
            />
            {inputValidationsErrors ? (
              alertData?.message === undefined || alertData.message.trim().length === 0 ? (
                <span className="label">
                  <RequiredMessageWrapper>
                    <IcoError /> <span>{t('alert_message_is_required')}</span>
                  </RequiredMessageWrapper>
                </span>
              ) : null
            ) : null}
          </FormSecondRowItem>
          <FormSecondRowItem>
            <p className="label label-fields">
              {t('alert_priority') as string} <Asterisk />
            </p>
            <FormSecondRowItemDiv>
              <label htmlFor="">{t('high') as string}</label>
              <label htmlFor="">{t('medium') as string}</label>
              <label htmlFor="">{t('low') as string}</label>
            </FormSecondRowItemDiv>
            <Slider
              min={1}
              max={3}
              defaultValue={alertData.priority}
              onChange={(e) => onHandleChange('priority', e)}
            />
          </FormSecondRowItem>
          <FormSecondRowItem />

          {process.env.REACT_APP_ENABLE_CALCULATED_TAGS_TAB === 'true' ? (
            <DateRangeProvider timeZone={timeZone} subtractDaysCount={14}>
              <FormSecondRowItem>
                <AlertSimulation />
              </FormSecondRowItem>
            </DateRangeProvider>
          ) : null}

          {alertDestination.map((obj) => obj.value).includes('predictive_maintenance') ? (
            <FormSecondRowItem>
              <p className="label">
                {t('select_sub_component_title')} <Asterisk />
              </p>
              <StyledInputField
                value={selectSubComponent}
                type="text"
                placeholder={t('select_sub_component_placeholder') as string}
                onChange={(e: { target: { value: string } }) => {
                  setSelectSubComponent(e.target.value);
                }}
              />
            </FormSecondRowItem>
          ) : null}
        </SecondFormContainer>

        <AlertLogic
          onQueryDataChange={getAlertQueryData}
          queryData={queryData}
          inputValidationsErrors={inputValidationsErrors}
        />

        <AlertSetup
          AlertUnitTypes={AlertUnitTypes}
          isAlertTypeFetching={isAlertTypeFetching}
          onBlurHandle={onBlurHandle}
          validationState={validationState}
          alertData={alertData}
          onHandleChange={onHandleChange}
          setIsReminderEnabled={setIsReminderEnabled}
          isReminderEnabled={isReminderEnabled}
          onReminderHandleChange={onReminderHandleChange}
          reminderValues={reminderValues}
          onTriggerHandleChange={onTriggerHandleChange}
          triggerValues={triggerValues}
          inputValidationsErrors={inputValidationsErrors}
        />

        <Footer>
          <FooterButtonContainer>
            <RequiredTextMessageWrapper>
              {t('All fields with a')} <span id="astrick-icon">*</span> {t('symbol are mandatory')}.
            </RequiredTextMessageWrapper>

            <Button
              borderRadius="3rem"
              bgColor={theme.colors.white}
              color={theme.colors.primaryBlue5}
              borderColor={theme.colors.primaryBlue5}
              onClick={() => history.goBack()}
              style={{
                width: '10rem'
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              borderRadius="3rem"
              variant="hover-blue"
              bgColor={theme.colors.primaryBlue5}
              color={theme.colors.lightGrey3}
              onClick={goToAlertsView}
              style={{
                width: '10rem'
              }}
            >
              {t('save')}
            </Button>
          </FooterButtonContainer>
        </Footer>
      </FormParent>
      <WarningPrompt
        helperText={t('Are you sure you wish to exit without saving changes')}
        isVisible={
          isAlertSubmitting === false && ('create-alert' === path || 'update-alert' === path)
        }
        title={t('unsaved_changes')}
      />
    </PageContainer>
  );
};

export default CreateAlertPage;
