import React from 'react';
import { ActiveIssuesWidget as Widget } from 'common/components/WidgetUi/ActiveIssuesWidget/ActiveIssuesWidget';
import { useGetMachineOverviewAlarmsQuery } from 'api';
import { useRouter } from 'common/hooks/useRouter';
import { useDateRange } from 'components';
import { processApiData } from 'common/helpers/processApiData';
import { format, parseISO } from 'date-fns';
import { removeDuplicatesByKeys } from 'common/helpers/dataFilters';

export const demoData = [
  {
    startTimestamp: '2023-12-11T11:16:25+00:00',
    code: '22322',
    description: 'AVD: Filter temperature too high'
  }
];

export const ActiveIssuesWidget = (): JSX.Element => {
  const { machineId } = useRouter('/fleet/page/machineId') as { machineId: string };

  const { isoDateRange, timeZone } = useDateRange();

  const { data, isLoading, error } = useGetMachineOverviewAlarmsQuery(
    {
      machineId,
      startDatetime: isoDateRange.startTime,
      machineType: 'aseptic'
    },
    {
      pollingInterval: 60000
    }
  );

  const processedData = data && processApiData(data, { startTimeKey: 'startTimestamp', timeZone });

  const tableData = processedData?.map((item) => ({
    text: item?.description as string,
    timestamp: format(parseISO(item?.startTimestamp as string), 'P, p'),
    id: item?.code as string,
    strong: item?.code as string
  }));

  const filtered = removeDuplicatesByKeys(['timestamp', 'id'], { data: tableData });

  const settings = {
    title: 'Alerts',
    data: filtered,
    isLoading,
    hasError: error ? 'error getting machineOverviewAlarms' : undefined,
    hasMessage: !isLoading && !error && data?.length === 0 ? 'No alerts' : undefined,
    styleType: 'v1'
  };

  return <Widget {...settings} />;
};
