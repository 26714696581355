import React, { useEffect, useMemo, useState } from 'react';
import { useGetUserManagementV2Query } from 'api';
import { PAGE_LENGTH } from 'constants/search';
import usePaginatedQueryOffset from 'hooks/usePaginatedQueryOffset';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Id } from 'types';
import { Group, UserManagementTableType } from 'types/user-management';
import { groupsColumnConfigs, transformKeysToTableColumn } from '../utils';
import NewBaseTable from '../../../../components/NewBaseTable/NewBaseTable';
import theme from 'themes';
import { Button, Pagination } from 'components';
import { GroupAddIcon } from 'icons/GroupAddIcon';
import { AddUserIcon } from 'icons/AddUserIcon';

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.lightGrey1};
  border: 0.0625rem solid ${theme.colors.lightGrey3};
  border-top: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  top: 2rem;
  margin-bottom: -5px;
  margin-top: -12px;
  button {
    padding: 12px;
  }
  border-top: 1px solid ${theme.colors.lightGrey3};
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.18);
  color: #323130;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const SearchContainer = styled.div`
  margin-top: 35px;
  margin-bottom: -10px;
`;
const defaultGroupSortState = {
  id: '',
  desc: true
};

interface Props {
  onClickButton: (tableType: UserManagementTableType, id?: Id) => void;
}

const GroupManagementTable = ({ onClickButton }: Props): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  const [groupId, setGroupId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { onPageChange, pageNumber } = usePaginatedQueryOffset();
  const [pageNumberState, setPageNumber] = useState(pageNumber);
  const { data: result, isFetching: isLoading } = useGetUserManagementV2Query({
    type: lowerCase(UserManagementTableType.GROUP),
    nameSearchQuery: searchValue.trim(),
    groupId,
    limit: PAGE_LENGTH.SMALL,
    offset: pageNumberState
  });

  // To set the page number to 0 and clear search when tab changes.
  useEffect(() => {
    setPageNumber(0);
    setGroupId('');
  }, []);

  useEffect(() => {
    setPageNumber(pageNumber);
  }, [pageNumber]);

  const tableData = useMemo(() => {
    if (!result) return [];
    else {
      const toSort = [...result?.items];
      return toSort.sort((a, b) => a.name.localeCompare(b.name));
    }
  }, [result]);

  const dataWithColKeys = transformKeysToTableColumn(
    tableData as Group[],
    UserManagementTableType.GROUP
  );
  const updateSearchValue = (updatedSearchValue?: string) => {
    setSearchValue(updatedSearchValue || '');
    // Make sure to reset the page count
    onPageChange(0);
  };

  return (
    <>
      <ButtonContainer>
        <Button
          onClick={() => {
            onClickButton(UserManagementTableType.GROUP);
          }}
          variant="text"
          icon={<GroupAddIcon />}
          size="normal"
        >
          &nbsp; Create Group
        </Button>
        <Button
          onClick={() => {
            onClickButton(UserManagementTableType.USER);
          }}
          variant="text"
          icon={<AddUserIcon />}
          size="normal"
        >
          &nbsp; Add User
        </Button>
      </ButtonContainer>

      <NewBaseTable
        newTableData={isLoading ? [] : dataWithColKeys}
        columnConfigs={groupsColumnConfigs(onClickButton, t)}
        tdMarginLeft="0.625rem"
        textAlign="left"
        sortState={defaultGroupSortState}
        isShowColumnOptions={true}
        isDataLoading={isLoading}
        isShowGlobalSearch={true}
        searchPlaceholder={t('mm_group_mgrm_table_search_group') as string}
        searchInputValue={updateSearchValue}
        customClassName="group-table"
      />

      {result && (
        <PaginationContainer>
          <Pagination
            onPageChange={onPageChange}
            itemsPerPage={PAGE_LENGTH.SMALL}
            numItems={result?.total}
            forcePage={pageNumberState}
            currentPage={pageNumber}
          />
        </PaginationContainer>
      )}
    </>
  );
};
export default GroupManagementTable;
