// 3rd party libraries
import React, { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from 'constants/authConfig';
import { useMsal } from '@azure/msal-react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import FileVerificationPage from 'pages/FileverificationPage';

const NavigateToB2C = () => {
  const { instance } = useMsal();
  const location = useLocation<{ from: Location }>();

  const searhString = location.state?.from?.search || '';
  const searchParams = new URLSearchParams(searhString);
  const docSharedId = searchParams.get('sharedId');

  function navigateToLogin() {
    const { from } = location.state || { from: { pathname: '/' } };
    loginRequest.redirectStartPage = from.pathname + from.search;
    instance.loginRedirect(loginRequest);
  }

  useEffect(() => {
    if (!docSharedId) {
      navigateToLogin();
    }
  }, []);

  if (docSharedId) {
    return <FileVerificationPage docSharedId={docSharedId} navigateToLogin={navigateToLogin} />;
  }
  return null;
};

export default function B2CLogin(): React.ReactElement {
  const location = useLocation<{ from: Location }>();
  const { pathname = '/', search = '' } = location.state?.from || {};
  return (
    <div>
      <AuthenticatedTemplate>
        <Redirect
          to={{
            pathname: pathname + search
          }}
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <NavigateToB2C />
      </UnauthenticatedTemplate>
    </div>
  );
}
