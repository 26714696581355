import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const ChangeoverStepCategoriesChartContainer = styled.div<StyledUiContainerProps>``;

export const ChangeoverStepCategoriesChartTooltip = styled.div`
  min-width: max-content;
  dispay: flex;
  flex-direction: column;
  gap: 1em;

  .pie-chart-tooltip__label {
    font-weight: 500;
  }

  .small-text {
    font-size: 0.9em;
  }
`;
