import { addTimeZoneToUTCTimeStamp, formatTimeStampWithTz } from 'components/StyledUi/js';
import { useIntensifierStrokeCountQuery } from 'hooks/useIntensifiersCount';
import { useMachineIssues } from 'hooks/useMachineAlarmsAlerts';
import { GetDataProps } from '../types';
import { format } from 'date-fns';

export const colorMappingDotsChartAvure = {
  1: '#118DFF',
  2: '#3A4BC6',
  3: '#E66C37',
  4: '#C83D95'
};

const SKIDS = {
  SKID1: 'Skid 1',
  SKID2: 'Skid 2',
  SKID3: 'Skid 3',
  SKID4: 'Skid 4'
};

export const INTENSIFIERS = [
  {
    lookupValue: 'Intensifier 1',
    displayValue: 'Intensifier L1',
    color: '#118DFF'
  },
  {
    lookupValue: 'Intensifier 2',
    displayValue: 'Intensifier L2',
    color: '#3A4BC6'
  },
  {
    lookupValue: 'Intensifier 3',
    displayValue: 'Intensifier R1',
    color: '#E66C37'
  },
  {
    lookupValue: 'Intensifier 4',
    displayValue: 'Intensifier R2',
    color: '#C83D95'
  }
];

export const MAXNUMSKIDS = 4;

const randomNumber = (min: number, max: number): number => {
  return Math.round(Math.random() * (max - min) + min);
};

export const generateDemoDataIntensifiers = (): Record<string, unknown>[] => {
  const totalNumRecords = 100;
  const numSkids = 4;
  const numIntens = 4;
  const name = 'intensifier_utilization_';

  const d = new Date('2023-09-11T00:00:00.000Z'); //start date
  const step = 10; //every 10 minutes

  const valueOutOfRangeIndex = 5;

  const isSkidSet = {
    1: true,
    2: false,
    3: false,
    4: false
  };

  const data = [];

  for (let i = 0; i < totalNumRecords; i++) {
    const object = {};
    for (let y = 1; y <= numSkids; y++) {
      for (let s = 1; s <= numIntens; s++) {
        const key = name + `${y}` + '_' + `${s}`;
        let value;
        if (isSkidSet[y]) {
          if (s == 1) {
            if (i % valueOutOfRangeIndex !== 0) {
              value = randomNumber(270, 290);
            } else {
              value = randomNumber(100, 250);
            }
          } else if (s == 2) {
            if (i % valueOutOfRangeIndex !== 0) {
              value = randomNumber(280, 300);
            } else {
              value = randomNumber(100, 300);
            }
          } else if (s == 3) {
            if (i % valueOutOfRangeIndex !== 0) {
              value = randomNumber(290, 310);
            } else {
              value = randomNumber(200, 400);
            }
          } else {
            if (i % valueOutOfRangeIndex !== 0) {
              value = randomNumber(300, 320);
            } else {
              value = randomNumber(250, 500);
            }
          }
        } else {
          value = 0;
        }
        object[key] = value;
      }
    }
    object.timestamp = new Date(d.setMinutes(d.getMinutes() + step)).toISOString();
    data.push(object);
  }
  return data;
};

export const formatDataBySkid = (
  data: Record<string, unknown>[],
  timezone: string
): { skids: Record<string, unknown>[] } => {
  const skids = [] as Record<string, unknown>[];

  // Iterate over the data and separate it into skids
  data.forEach((item) => {
    // skip failed batches
    if (!item.batchSuccessful) return;

    let currSkid = 1;
    while (currSkid <= MAXNUMSKIDS) {
      const key1 = `intensifierCount${currSkid}1`;
      const key2 = `intensifierCount${currSkid}2`;
      const key3 = `intensifierCount${currSkid}3`;
      const key4 = `intensifierCount${currSkid}4`;

      if (
        item[key1] !== undefined &&
        item[key2] !== undefined &&
        item[key3] !== undefined &&
        item[key4] !== undefined
      ) {
        if (!skids[currSkid]) {
          skids[currSkid] = [];
        }

        const machineTime = addTimeZoneToUTCTimeStamp(item.startTime, timezone);

        if (item[key1] !== 0 && item[key2] !== 0 && item[key3] !== 0 && item[key4] !== 0) {
          skids[currSkid].push({
            lotId: item.lotId as unknown as string,
            batchNumber: item.batchNumber as unknown as number,
            batchSuccessful: item.batchSuccessful as unknown as boolean,
            startTime: new Date(machineTime) as unknown as Date,
            [key1]: item[key1],
            [key2]: item[key2],
            [key3]: item[key3],
            [key4]: item[key4]
          });
        }
      }
      currSkid++;
    }
  });

  // We do not always have all 4 intensifiers, in that case, if we only have 1 intensifier, values will be coming through as 0
  // We need to check for 0
  const isZero = (value) => value === 0;
  const hasSkids = skids.map((skid) => skid.every((e) => isZero(e.value)));
  hasSkids.shift();

  //We never set item at 0 so we are removing first element of the array
  skids.shift();

  const filteredSkids = [];

  // Now, based on hasSkids we make out final array of skids
  hasSkids.filter((el, index) => {
    if (!el) {
      filteredSkids.push(skids[index]);
    }
  });

  return { skids: filteredSkids };
};

// This function takes API response and sorts it by SKId and then by Intensifier
export const parseAlarmData = (
  data: Record<string, unknown>[],
  timezone: string
): Record<string, unknown>[] => {
  const skidAlarms = [];
  const skidAlarmsByKeyTimestamp = [];

  data.map((item) => {
    for (const [key, value] of Object.entries(item)) {
      if (key === 'description' || key === 'detailedInstructions') {
        const hasSkid = Object.values(SKIDS).filter((item) => value.includes(item));

        if (!hasSkid || hasSkid.length === 0) continue;

        const skidId = hasSkid[0].split(' ')[1];

        if (!skidAlarms[skidId]) {
          skidAlarms[skidId] = {};
        }

        const intensifiersLookupValues = INTENSIFIERS.reduce((values, item) => {
          values.push(item.lookupValue);
          return values;
        }, []);

        const hasIntensifier = intensifiersLookupValues.filter((item) => value.includes(item));

        if (!hasSkid || !hasIntensifier) continue;

        if (!Object.prototype.hasOwnProperty.call(skidAlarms[skidId], hasIntensifier[0])) {
          skidAlarms[skidId][hasIntensifier[0]] = [];
        }

        if (!skidAlarmsByKeyTimestamp[skidId]) {
          skidAlarmsByKeyTimestamp[skidId] = [];
        }

        //Initial time is in UTC, we converting them to the machine time
        let machineTime = item.createdAt;
        if (timezone) machineTime = formatTimeStampWithTz(item.createdAt, timezone);

        skidAlarmsByKeyTimestamp[skidId].push({
          ...item,
          timestamp: new Date(item.createdAt),
          startTime: new Date(item.createdAt),
          time: machineTime
        });

        skidAlarms[skidId][hasIntensifier[0]].push({
          ...item,
          timestamp: new Date(machineTime),
          date: machineTime
        });
      }
    }
  });

  skidAlarms.shift();
  skidAlarmsByKeyTimestamp.shift();

  return { skidAlarms, skidAlarmsByKeyTimestamp };
};

export const getData = (): GetDataProps => {
  const { data: skidsData, isLoading, hasError } = useIntensifierStrokeCountQuery();

  const {
    alerts: alertData,
    isLoading: alarmsLoading,
    hasError: alertsHasError
  } = useMachineIssues();

  const loading = isLoading || alarmsLoading;

  if (loading) return { isLoading: true, allData: [], hasError: false };

  return {
    allData: { skidsData, alertData },
    isLoading: isLoading || alarmsLoading,
    hasError: hasError || alertsHasError
  };
};

export const formatXaxisValues = (date: Date, daterange: Date[]): string => {
  if (daterange) {
    const a = daterange[0];
    const b = daterange[1];

    if (Math.abs(a.getTime() - b.getTime()) > 24 * 60 * 60 * 1000) {
      //format as dates/months
      return format(date, 'MMM/dd');
    } else {
      //format as hours
      return format(date, 'h a'); //this will change based on where user is
    }
  } else {
    return format(date, 'MMM/dd');
  }
};
