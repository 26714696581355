import React from 'react';
import styled from 'styled-components';
import theme from 'themes';

type IconProps = {
  width?: number | undefined;
  color?: string | undefined;
  srLabel?: string | undefined;
};

type RibbonItem = {
  label: string;
  icon: ({ width, color, srLabel }: IconProps) => JSX.Element;
  onClick?: () => void;
  disable?: boolean;
};
type Props = {
  ribbonItems: RibbonItem[];
  padding?: string;
  border?: boolean;
};
const Container = styled.div<{ padding?: string; border?: boolean }>`
  display: flex;
  gap: 3rem;
  font-size: 1.1rem;
  padding: ${({ padding }) => padding};
  border-bottom: ${({ border, theme }) => (border ? `1px solid ${theme.colors.mediumGrey1}` : '')};
`;
const BtnText = styled.span<{ color?: string }>`
  color: ${({ color }) => color || 'inherit'};
`;

export const IconTextBtn = styled.button<{ gap?: string; disable?: boolean }>`
  all: unset;
  display: flex;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  gap: ${({ gap }) => gap};
  align-items: center;
`;

const RibbonComponent = ({ ribbonItems, padding, border }: Props): JSX.Element => {
  return (
    <Container padding={padding} border={border}>
      {ribbonItems?.map((ribbon) => {
        const { icon: Icon, disable } = ribbon || {};
        return (
          <IconTextBtn gap=".5rem" disable={disable} onClick={ribbon?.onClick}>
            {Icon && <Icon width={24} color={disable ? theme.colors.lightGrey9 : ''} />}
            <BtnText color={disable ? theme.colors.lightGrey9 : ''}>{ribbon?.label}</BtnText>
          </IconTextBtn>
        );
      })}
    </Container>
  );
};

export default RibbonComponent;
