import React from 'react';
import { DimensionsContainer } from 'components';
import { PressurizationTimeList } from './PressurizationTimeList';
import { PressurizationProps, RightSideProps } from './types';
import { ALERTSFILTER } from './types/constants';
import { DotsChartWrapper } from './chart/DotsChartWrapper';
import { LabelWrapper } from './chart/LabelWrapper';
import { SelectedItemsProvider } from '../../../hooks/useZoomHistoryhook';
import { ZoomContainer } from 'pages/FleetMachineDetail/MachineHealth/components/ZoomDotsChart/Zoom';

export const PressurizationDeltaChart = ({ data }: PressurizationProps): JSX.Element => {
  const { dataAlarms: dataList, dataAlarmsByFilterKey, chartData } = data;

  const rightsideSettings = {
    data: chartData,
    data2: dataList,
    tooltipData: dataAlarmsByFilterKey
  };

  return (
    <>
      <PressurizationTimeList
        data={dataAlarmsByFilterKey as unknown as Record<string, unknown>[]}
        headers={Object.values(ALERTSFILTER)}
        className="pressurization-list-alarms"
      />
      <DimensionsContainer
        Component={({ width: innerWidth }) => {
          return <RightSide width={innerWidth} {...rightsideSettings} />;
        }}
      />
    </>
  );
};

const RightSide = ({ data, data2, tooltipData, width }: RightSideProps): JSX.Element => {
  const chartSettings = {
    data,
    data2,
    tooltipData,
    width
  };

  return (
    <div style={{ position: 'absolute' }}>
      <LabelWrapper />
      <SelectedItemsProvider>
        <ChartWrapper {...chartSettings} />
      </SelectedItemsProvider>
    </div>
  );
};

const ChartWrapper = ({ data, data2, tooltipData, width }: RightSideProps): JSX.Element => {
  const dataSet2 = data2 as unknown as Record<string, unknown>[];

  return (
    <>
      <ZoomContainer />
      <DotsChartWrapper
        {...{
          data,
          data2: dataSet2,
          width,
          height: 350,
          tooltipData
        }}
      />
    </>
  );
};
