import React, { ReactElement, useMemo, useState } from 'react';
import {
  Card,
  CardContainer,
  Dashboard,
  DateHeader,
  DropdownContainer,
  EmptyData,
  FilterInfo,
  GridIconContainer,
  Heading,
  HistoryCardContainer,
  HistoryContainer,
  LoaderContainer,
  Results,
  ViewController
} from '../documentation.elements';
import { Button, Typography } from 'components';
import theme from 'themes';

import { useTranslation } from 'react-i18next';
import HistoryFile from './HistoryFile';
import { useClearDocSearchHistoryMutation, useGetSearchHistoryQuery } from 'api';
import CustomLoader from 'components/CustomLoader';
import { filterByTimeRange, getRelativeDateString, groupBy } from 'helpers';
import { IcoGridView } from 'icons/IcoGridView';
import { IcoRowsView } from 'icons/IcoRowsView';
import Dropdown from 'common/components/Dropdown';
import { ContentAlign, Flex } from 'common/components/common-styled';
import { OptionType } from 'components/EllypsisMenu';
import { TimeRange } from 'types/machine-management';
import { ToastMsg } from 'common/components/Toast/Toast';
import { IcoNoFiles } from 'icons/IcoNoFiles';
import { useHistory } from 'react-router-dom';
import { JBTRoutes } from 'constants/routes';
let totalResult = 0;

const SearchHistoryPage = (): ReactElement => {
  const { t } = useTranslation(['fpns']);
  const [isGridView, setIsGridView] = useState(false);
  const [timeRange, setTimeRange] = useState<TimeRange>('24_hours');
  const history = useHistory();

  const { data, isLoading } = useGetSearchHistoryQuery();
  const [deleteSearchHistory, { isLoading: clearHistoryLoading }] =
    useClearDocSearchHistoryMutation();

  const historyData = useMemo(() => {
    if (data) {
      const d = timeRange === 'all' ? data : filterByTimeRange(data, timeRange, 'updatedAt');
      totalResult = d.length;
      const sortedDate = [...d]?.sort((currFile, prevFile) => {
        if (!(currFile?.updatedAt || prevFile?.updatedAt)) {
          return 0;
        }
        return new Date(currFile?.updatedAt || '') > new Date(prevFile?.updatedAt || '') ? -1 : 1;
      });
      const historyDataObj = groupBy(sortedDate || []);
      return Object.values(historyDataObj);
    }
    return [];
  }, [data, timeRange, isGridView]);

  const handleClearHistory = async () => {
    if (clearHistoryLoading) return;
    // setActiveDocId(id);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resp = (await deleteSearchHistory()) as any;
      if (resp.error) {
        ToastMsg({
          heading: 'Error',
          message: resp?.error?.data?.detail,
          type: 'error',
          position: 'top-right'
        });
      } else {
        ToastMsg({
          heading: 'Successful',
          message: 'History deleted successfully.',
          type: 'success',
          position: 'top-right'
        });
        // setIsBookmark(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const options = [
    { label: t('last_24_hours'), value: '24_hours' },
    { label: t('last_3_days'), value: '3_days' },
    { label: t('last_7_days'), value: '7_days' },
    { label: t('last_2_weeks'), value: 'all' }
  ];
  const handleDateFilter = (selectedOption: OptionType) => {
    setTimeRange(selectedOption.value as TimeRange);
  };
  return (
    <div>
      <Heading>
        <Typography weight="semi-bold" size={'1.25rem'}>
          {t('search_history')}
        </Typography>
      </Heading>
      <FilterInfo>
        <Results>
          {t('Showings')}{' '}
          <span>
            {historyData?.length ? '01' : '00'}~{`${totalResult || '00'}`.padStart(2, '0')}
          </span>{' '}
          {t('of_all')} <span>{`${data?.length || '00'}`.padStart(2, '0')}</span> {t('history')}
        </Results>
        <DropdownContainer>
          <Dropdown handleChange={handleDateFilter} defaultValue={options[0]} options={options} />
        </DropdownContainer>
        <ViewController>
          <GridIconContainer onClick={() => setIsGridView(true)}>
            <IcoGridView width="22" />
          </GridIconContainer>
          <IcoRowsView
            width={22}
            height={22}
            cursor={'pointer'}
            role="button"
            onClick={() => setIsGridView(false)}
          />
        </ViewController>
      </FilterInfo>

      <Dashboard>
        <ContentAlign justifyContent="end">
          {clearHistoryLoading && (
            <CustomLoader size="1.2rem" padding="0 .5rem" thickness=".2rem" />
          )}
          {data?.length ? (
            <Typography
              style={{ cursor: 'pointer' }}
              onClick={handleClearHistory}
              color={theme.colors.primaryBlue5}
            >
              {t('clear_all_history')}
            </Typography>
          ) : null}
        </ContentAlign>
        <CardContainer flexDirection={'column'} reducedHeight="50vh">
          {isLoading ? (
            <LoaderContainer>
              <CustomLoader size="3rem" />
            </LoaderContainer>
          ) : historyData.length ? (
            historyData.map((item) => {
              return isGridView ? (
                <>
                  <DateHeader hideBorder padding="1rem 0 0 0">
                    {getRelativeDateString(item[0]?.updatedAt)}
                  </DateHeader>
                  <Flex>
                    <HistoryFile isGridView data={item} />
                  </Flex>
                </>
              ) : (
                <HistoryContainer>
                  <HistoryCardContainer key={item[0]?.id}>
                    <Card hoverDisable padding="1rem" width="auto" height="auto">
                      <DateHeader padding="0 0 .5rem">
                        {getRelativeDateString(item[0]?.updatedAt)}
                      </DateHeader>
                      <HistoryFile data={item} />
                    </Card>
                  </HistoryCardContainer>
                </HistoryContainer>
              );
            })
          ) : (
            <EmptyData>
              <IcoNoFiles className="emptyIcon" />
              <Typography weight="semi-bold">{t('empty_search_history')} </Typography>
              <Button
                borderRadius="3rem"
                variant="hover-blue"
                bgColor={theme.colors.primaryBlue5}
                width="fit-content"
                onClick={() => history.push(JBTRoutes.documentation)}
                className="backBtn"
              >
                {t('back_to_doc_center')}
              </Button>
            </EmptyData>
          )}
        </CardContainer>
      </Dashboard>
    </div>
  );
};

export default SearchHistoryPage;
