// 3rd party libs
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'themes';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { JBTRoutes } from 'constants/routes';
import { useArchiveMachineMutation, useGetOnboardingMachinesQuery } from 'api';
import { ModalSize } from 'types';

import {
  MachineProgressType,
  Tabs,
  MtlOption,
  OnboardingMachine,
  ArchiveResponse
} from 'types/machine-management';

import { Button, Loader, Modal, Typography } from 'components';
import MachineManagementTable from 'components/MachineManagementTable';
import { HeaderModalUploadCategory, ModalUploadCategory } from './ModalUploadCategory';
import { HeaderModalPhotoUpload, ModalPhotoUpload } from './ModalPhotoUpload';

import MasterTagList from './MasterTagList';
import { useHistory, useLocation } from 'react-router-dom';
import MachineManagementPopupcard from './MachineManagementPopup/MachineManagementPopupcard';
import { useTranslation } from 'react-i18next';
import MachineImageUploader from './MachineManagementPopup/MachineImageUploader';
import Collapse from 'rc-collapse';
import {
  MenuPanel,
  expandIcon
} from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';
import { TableFilterContainer } from 'pages/MaintenanceServiceDashboard/TopView';
import { IcoPlus } from 'icons/IconPlus';
import { IcoCart } from 'icons/IcoCart';
import { IcoPhoto } from 'icons/IcoPhoto';
import DropdownTab from 'common/components/DropdownTab';
import { Tab } from 'types/maintenance';
import { PopupModalContainer } from './NewMachine/Machine.elements';
import { ToastMsg } from 'common/components/Toast/Toast';
import UnArchiveModal from './UnArchiveModal';
import { IconTextBtn } from 'components/RibbonComponent';
import UploadMachinePdf from './UploadMachinePdf';
import UploadPartsBOM from './UploadPartsBOM';

//styling
const Root = styled.div`
  height: 17rem;
  width: 32rem;
  margin: auto;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.125rem solid;
  border-radius: 0.625rem;
  border-color: #e2e2e2;
`;

const PurchaseButtons = styled.div`
  display: flex;
  max-width: 21rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  * + * {
    margin-left: 1rem;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  position: fixed;
  right: 1rem;
  bottom: 3rem;
  z-index: 1;
  > button {
    border-radius: 50rem;
    width: 3rem;
    height: 3rem;
    > svg {
      margin: 0;
    }
  }
`;

const SubMenuButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button {
    margin-bottom: 1rem;
  }
`;

const TableContainer = styled.div`
  & .rc-collapse-item {
    position: relative;
  }
  & .rc-collapse-item-active {
    .rc-collapse-header {
      position: absolute;
      top: 0.8rem;
    }
  }
`;

const Container = styled.div`
  margin: 0 2rem;
`;

const HeadMenu = styled.header`
  display: flex;
  gap: 2.5rem;
`;
const Header = styled.div`
  padding: 0 2rem 1rem 2rem;
  font-weight: 600;
`;
const BtnContainer = styled.div`
  width: 50%;
  margin: 2rem 0 0 auto !important;
  & button {
    margin: 0 1rem;
  }
`;
interface ActiveMmRow {
  mtlId: string;
  position: { x: number; y: number };
  mtlOption?: MtlOption;
  activeMachine?: OnboardingMachine;
}

const MachineManagement = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation(['mh']);
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const tabParam = params.get('tab');

  const [currentTab, setCurrentTab] = useState(
    tabParam === '1' ? Tabs.MasterTagList : Tabs.Machines
  );
  const [showUploadCategoriesModal, setShowUploadCategoriesModal] = useState<boolean>(false);
  const [showPhotoUploadModal, setShowPhotoUploadModal] = useState<boolean>(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMachineImageUploader, setShowMachineImageUploader] = useState(false);
  const [menuMachineId, setMenuMachineId] = useState<string>('');
  const [activeMm, setActiveMm] = useState<ActiveMmRow>();
  const [clickPosition, setClickPosition] = useState<number>(0);
  const [isArchiveModal, setIsArchiveModal] = useState(false);
  const [uploadPdfModal, setUploadPdfModal] = useState(false);
  const [uploadPartsBOM, setUploadPartsBOM] = useState(false);
  const [showStatusManagementTableModal, setShowStatusManagementTableModal] = useState(false);

  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>([
    'progress',
    'done',
    'archived'
  ]);
  const [isUnArchiveModal, setIsUnarchiveModal] = useState<boolean>(false);

  const { data: onboardingMachines, isLoading: onboardingMachinesLoading } =
    useGetOnboardingMachinesQuery();
  const [archiveMachine] = useArchiveMachineMutation();

  const rows = useMemo(() => {
    if (onboardingMachines === undefined) {
      return [];
    }
    return onboardingMachines;
  }, [onboardingMachines]);

  const machineInProgress = rows.filter((machine) => {
    if (
      machine.isArchived !== true &&
      (machine.diagrams != MachineProgressType.DONE ||
        machine.maintenanceSchedule != MachineProgressType.DONE ||
        machine.provisionGateway != MachineProgressType.DONE ||
        machine.tagList != MachineProgressType.DONE)
    )
      return machine;
  });
  const machineOnboarded = rows.filter((machine) => {
    if (
      machine.diagrams === MachineProgressType.DONE &&
      machine.maintenanceSchedule === MachineProgressType.DONE &&
      machine.provisionGateway === MachineProgressType.DONE &&
      machine.tagList === MachineProgressType.DONE
    )
      return machine;
  });

  const machineArchived = rows.filter((machine) => machine.isArchived);

  const onModalClose = () => {
    setShowUploadCategoriesModal(!showUploadCategoriesModal);
  };

  const onModalPhotosClose = () => {
    setShowPhotoUploadModal(!showPhotoUploadModal);
  };

  const onRowActionsHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mtlId: string,
    record?: OnboardingMachine
  ) => {
    const itemBounds = event.currentTarget.getBoundingClientRect();
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setClickPosition(event.clientY);
    if (!mtlId) {
      setActiveMm(undefined);
    }
    setActiveMm({
      mtlId: mtlId,
      position: {
        x: itemBounds.x,
        y: itemBounds.y + itemBounds.height + winScroll
      },
      activeMachine: record
    });
  };
  const closePopups = () => {
    setActiveMm(undefined);
  };

  useEffect(() => {
    closePopups();
  }, [showSubMenu, showPhotoUploadModal, showUploadCategoriesModal]);
  const selectedActiveMenuItem = (item: string, machineId: string): void => {
    switch (item) {
      case 'machineImageUpload':
        closePopups();
        setMenuMachineId(machineId as string);
        setShowMachineImageUploader(true);
        break;
      case 'archive':
        closePopups();
        setIsArchiveModal(true);
        setMenuMachineId(machineId);
        break;
      case 'uploadMachinePdf':
        setUploadPdfModal(true);
        closePopups();
        setMenuMachineId(machineId);
        break;
      case 'uploadPartsBOM':
        setUploadPartsBOM(true);
        closePopups();
        setMenuMachineId(machineId);
        break;
      case 'statusManagementTable':
        closePopups();
        setShowStatusManagementTableModal(true);
        setMenuMachineId(machineId);
        break;
      default:
        break;
    }
  };

  const tabs = [
    {
      label: t('machines'),
      value: 'machines',
      selected: currentTab === Tabs.Machines
    },
    {
      label: t('tag_templates'),
      value: 'tag_templates',
      selected: currentTab === Tabs.MasterTagList
    }
  ];

  const handleTabClick = (subTab: Tab, mainTab: Tab) => {
    const activeTab = mainTab.value === 'tag_templates' ? Tabs.MasterTagList : Tabs.Machines;
    setCurrentTab(activeTab);
    history.push({
      search: `?tab=${mainTab.value === 'tag_templates' ? '1' : '0'}`
    });
  };
  const getTitle = (title: string) => {
    const count =
      title === 'progress'
        ? machineInProgress.length
        : title === 'archived'
          ? machineArchived.length
          : machineOnboarded.length;
    const text = {
      progress: t('in_progress'),
      done: t('complete'),
      archived: t('archive')
    }[title];
    return `${text} (${count})`;
  };

  const handleMachineArchive = async () => {
    const response = (await archiveMachine({
      machine_id: menuMachineId,
      machine_archive: false,
      status: {
        publish_status_tags: false,
        publish_status_gateway: false,
        publish_status_maintenance: false,
        publish_upload_diagram: false
      }
    })) as ArchiveResponse;
    if (response.error) {
      return ToastMsg({
        heading: 'Failed',
        message: 'Machine archived failed',
        type: 'error'
      });
    }
    ToastMsg({
      heading: 'Successful',
      message: 'Machine archived successfully'
    });
    setIsArchiveModal(false);
  };

  const handleClickAction = (event?: string, mId?: string) => {
    switch (event) {
      case 'unarchive':
        setIsUnarchiveModal(true);
        setMenuMachineId(mId || '');
    }
  };
  return (
    <>
      <Container>
        <DropdownTab tabs={tabs} onTabClick={handleTabClick} />
      </Container>
      {currentTab === Tabs.Machines && (
        <Container>
          <TableFilterContainer>
            <HeadMenu>
              <IconTextBtn
                onClick={() => {
                  window.location.assign(JBTRoutes.machineManagementNew);
                }}
              >
                <span style={{ marginTop: '.8rem' }}>
                  <IcoPlus width="24" />
                </span>{' '}
                {t('new_machine')}
              </IconTextBtn>

              <IconTextBtn
                gap=".5rem"
                onClick={() => {
                  setShowUploadCategoriesModal(true);
                }}
              >
                <IcoCart width="20" /> {t('shop_by_category')}
              </IconTextBtn>

              <IconTextBtn
                gap=".5rem"
                onClick={() => {
                  setShowPhotoUploadModal(true);
                }}
              >
                <IcoPhoto width="20" /> {t('photos')}
              </IconTextBtn>
            </HeadMenu>
          </TableFilterContainer>
          {onboardingMachinesLoading && <Loader size={40} />}
          {!onboardingMachinesLoading &&
            machineInProgress.length == 0 &&
            machineOnboarded.length == 0 && (
              <Root>
                <Typography color={theme.colors.darkGrey} variant="h3">
                  There are no connected machines yet.
                </Typography>
                <PurchaseButtons>
                  <Button
                    variant="primary"
                    disabled={false}
                    onClick={() => {
                      window.location.assign(JBTRoutes.machineManagementNew);
                    }}
                  >
                    Onboard New Machine +
                  </Button>
                </PurchaseButtons>
              </Root>
            )}
          {!onboardingMachinesLoading &&
            (machineInProgress.length != 0 ?? machineOnboarded.length != 0) && (
              <>
                <TableContainer>
                  <Collapse
                    activeKey={activePanelKeys}
                    onChange={(key: React.Key | React.Key[]) =>
                      setActivePanelKeys(Array.isArray(key) ? key : [key])
                    }
                    destroyInactivePanel={true}
                    expandIcon={expandIcon}
                  >
                    {['progress', 'done', 'archived'].map((title) => {
                      const header = <Header>{getTitle(title)}</Header>;
                      return (
                        <MenuPanel
                          showArrow={true}
                          collapsible="header"
                          key={title}
                          header={header}
                          mb={0}
                        >
                          {title === 'done' ? (
                            <MachineManagementTable
                              data={machineOnboarded}
                              isDataLoading={onboardingMachinesLoading}
                              headerBgColor={theme.colors.machineManagementColors.lightGreen}
                              onOpenActions={onRowActionsHandler}
                            />
                          ) : title === 'archived' ? (
                            <MachineManagementTable
                              data={machineArchived}
                              isDataLoading={onboardingMachinesLoading}
                              headerBgColor={theme.colors.machineManagementColors.lightOrange}
                              onOpenActions={onRowActionsHandler}
                              tableName={'archive'}
                              handleClickAction={handleClickAction}
                            />
                          ) : (
                            <MachineManagementTable
                              data={machineInProgress}
                              isDataLoading={onboardingMachinesLoading}
                              headerBgColor={theme.colors.machineManagementColors.lightOrange}
                              onOpenActions={onRowActionsHandler}
                            />
                          )}
                        </MenuPanel>
                      );
                    })}
                  </Collapse>
                </TableContainer>
                {activeMm && activeMm.mtlOption === undefined && (
                  <MachineManagementPopupcard
                    visible={activeMm && activeMm.mtlOption === undefined}
                    handleClose={closePopups}
                    posX={activeMm.position.x}
                    posY={activeMm.position.y}
                    clickPosition={clickPosition}
                    activeMenuSelection={selectedActiveMenuItem}
                    machineId={activeMm?.mtlId}
                    activeMachine={activeMm.activeMachine}
                    vh={vh}
                  />
                )}
                <MachineImageUploader
                  isOpen={showMachineImageUploader}
                  setShowMachineImageUploader={setShowMachineImageUploader}
                  machineId={menuMachineId}
                />
              </>
            )}
          <Modal
            visible={showUploadCategoriesModal}
            size={ModalSize.SMALL}
            onClose={onModalClose}
            title={HeaderModalUploadCategory}
          >
            <ModalUploadCategory setShowModal={setShowUploadCategoriesModal} />
          </Modal>
          <Modal
            visible={showPhotoUploadModal}
            size={ModalSize.SMALL}
            onClose={onModalPhotosClose}
            title={HeaderModalPhotoUpload}
          >
            <ModalPhotoUpload setShowModal={setShowPhotoUploadModal} />
          </Modal>
        </Container>
      )}
      {isUnArchiveModal && (
        <Modal
          visible={isUnArchiveModal}
          size={ModalSize.SMALL_AUTO_HEIGHT}
          title={'Undo machine from Archive?'}
          onClose={() => setIsUnarchiveModal(false)}
          widthOverride="40rem"
        >
          <UnArchiveModal
            handleClose={() => setIsUnarchiveModal(false)}
            machineId={menuMachineId}
          />
        </Modal>
      )}
      {currentTab === Tabs.MasterTagList && (
        <Container>
          <MasterTagList />
          <ButtonDiv>
            {showSubMenu && (
              <SubMenuButtonDiv>
                <Button
                  variant="primary"
                  disabled={false}
                  onClick={() => {
                    window.location.assign(JBTRoutes.machineMasterTagListDashBoard);
                  }}
                >
                  {t('create_new_template')}
                </Button>
              </SubMenuButtonDiv>
            )}
            <Button variant="primary" onClick={() => setShowSubMenu(!showSubMenu)}>
              {showSubMenu ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faPlus} />}
            </Button>
          </ButtonDiv>
        </Container>
      )}
      {isArchiveModal && (
        <Modal
          visible={isArchiveModal}
          onClose={() => setIsArchiveModal(false)}
          size={'small_auto_height'}
          widthOverride="50rem"
        >
          <PopupModalContainer>
            <>
              <h2>{t('is_archive_heading')}?</h2>
              <p>{t('is_archive_statement')}</p>
              <BtnContainer>
                <Button
                  borderRadius="3rem"
                  onClick={() => setIsArchiveModal(false)}
                  borderColor={theme.colors.primaryBlue5}
                  bgColor="transparent"
                  variant="link"
                >
                  Cancel
                </Button>
                <Button borderRadius="3rem" onClick={handleMachineArchive} variant="hover-blue">
                  Archive
                </Button>
              </BtnContainer>
            </>
          </PopupModalContainer>
        </Modal>
      )}

      {showStatusManagementTableModal && (
        <Modal
          visible={showStatusManagementTableModal}
          onClose={() => setShowStatusManagementTableModal(false)}
          size={'large'}
          widthOverride="90rem"
        >
          <PopupModalContainer>
            <>
              <h2>{t('Status Management Table')}</h2>
              <p>Table Render</p>
              <BtnContainer>
                <Button
                  borderRadius="3rem"
                  onClick={() => console.log('false')}
                  borderColor={theme.colors.primaryBlue5}
                  bgColor="transparent"
                  variant="link"
                  width="10rem"
                >
                  {t('Cancel')}
                </Button>
                <Button
                  borderRadius="3rem"
                  onClick={() => console.log('clicked')}
                  variant="hover-blue"
                  width="10rem"
                >
                  {t('Save')}
                </Button>
              </BtnContainer>
            </>
          </PopupModalContainer>
        </Modal>
      )}

      {uploadPdfModal && (
        <UploadMachinePdf
          machineId={menuMachineId}
          open={uploadPdfModal}
          onCloseFlyout={() => setUploadPdfModal(false)}
        />
      )}
      {uploadPartsBOM && (
        <UploadPartsBOM
          machineId={menuMachineId}
          open={uploadPartsBOM}
          onCloseFlyout={() => setUploadPartsBOM(false)}
        />
      )}
    </>
  );
};

export default MachineManagement;
