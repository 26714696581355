import React from 'react';

import { WidgetUi } from 'common/ui';
import { ProgressTilesWidgetContainer } from './ProgressTileWidget.elements';

import { ProcessedProductPercentageKPIProgressTile } from './ProcessedProductPercentageKpiProgressTile';
import { ThroughputPieceCountKPIProgressTile } from './ThroughputPieceCountKPIProgressTile';
import { YieldKPIProgressTile } from './YieldKPIProgressTile';
import { ThroughputRateKPIProgressTile } from './ThroughputRateKPIProgressTile';

export interface ProgresTileWidgetProps {
  /** set to true if you dont' want the wrapping */
  tilesOnly?: boolean;
}
export const ProgressTileWidget = (): JSX.Element => {
  const widgetSettings = {
    title: 'Quality & Performance',
    className: 'progress-tiles-widget',
    hasDateFooter: 'last 30 minutes'
  };

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <ProgressTilesWidgetContainer className="widget-ui__main">
          <YieldKPIProgressTile />
          <ThroughputRateKPIProgressTile />
          <ThroughputPieceCountKPIProgressTile />
          <ProcessedProductPercentageKPIProgressTile />
        </ProgressTilesWidgetContainer>
      }
    />
  );
};
