import React, { useEffect, useState } from 'react';
import { useGetIntensifiersStrokeCountQuery } from 'api';
import { useDateRange } from 'components';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { formatDataBySkid } from 'pages/FleetMachineDetail/MachineHealth/components/SkidStrokeCountsChart/utils/index';

export interface IntensifierStrokeCountProps {
  isLoading?: boolean;
  hasError?: string;
  hasMessage?: string;
  data?: Record<string, unknown>[];
}

const IntensifiersStrokeCountContext = createContext<IntensifierStrokeCountProps>({
  isLoading: true
});

export const useMachineAlarmsContext = (): IntensifierStrokeCountProps =>
  useContext(IntensifiersStrokeCountContext);

export const IntensifiersProvider = ({ children }: { children?: ReactNode }): JSX.Element => {
  const { data, isLoading, hasError } = useIntensifierStrokeCountQuery();

  const value = {
    data,
    isLoading,
    hasError
  };

  return (
    <IntensifiersStrokeCountContext.Provider value={value}>
      {children}
    </IntensifiersStrokeCountContext.Provider>
  );
};

export const useIntensifierStrokeCountQuery = (): IntensifierStrokeCountProps => {
  const { machineId, timeZone } = useFleetMachineAccountData();

  if (!machineId)
    return {
      data: undefined,
      isLoading: undefined,
      hasError: undefined
    };

  const { startTime: startDatetime, endTime: endDatetime } = useDateRange().utcTZConvertedISO;
  const { data, isLoading, error } = useGetIntensifiersStrokeCountQuery({
    machineId,
    startDatetime,
    endDatetime
  });

  const [skidsInfo, setSkidsInfo] = useState<{
    skids: Record<string, unknown>[];
    skidsByTimestampAsKey: Record<string, unknown>[];
  }>([]);

  useEffect(() => {
    // This transforms the data that we would be using for the chart
    // It returns 2 large arrays
    // Array #1 is an array of arrays - it would contain as many arrays as there are skids in the response
    // Array within array #1 is a skid, it contains an object with timestamp and intensifiers and its value

    // Array #2 is an array of arrays. It would have same data as array #1 but formatted differently. We need this for tooltip information
    const sortedArray = data?.slice().sort(function (x, y) {
      return (
        new Date(x.startTime as unknown as string).getTime() -
        new Date(y.startTime as unknown as string).getTime()
      );
    });

    const { skids, skidsByTimestampAsKey } = formatDataBySkid(
      sortedArray || [],
      timeZone || 'Europe/Amsterdam'
    );
    setSkidsInfo({
      skids,
      skidsByTimestampAsKey
    });
  }, [data]);

  const cachedData = useMemo(() => skidsInfo, [skidsInfo]);

  const hasError = error ? `error getting machine alarms` : undefined;

  return { data: { ...cachedData }, isLoading, hasError };
};
