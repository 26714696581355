import React from 'react';

export interface Props {
  color?: string;
}

export const Speedometericon = ({ color }: Props): JSX.Element => {
  color = color || '#FF0000';

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2645_47466)">
        <path
          d="M18.364 19.3642C19.6227 18.1055 20.4798 16.5018 20.8271 14.756C21.1743 13.0102 20.9961 11.2006 20.3149 9.55607C19.6337 7.91154 18.4802 6.50594 17.0001 5.51701C15.5201 4.52808 13.78 4.00024 12 4.00024C10.22 4.00024 8.47992 4.52808 6.99988 5.51701C5.51984 6.50594 4.36628 7.91154 3.68509 9.55607C3.0039 11.2006 2.82566 13.0102 3.17292 14.756C3.52018 16.5018 4.37733 18.1055 5.636 19.3642"
          stroke={`${color}`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 9L12 13"
          stroke={`${color}`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2645_47466">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
