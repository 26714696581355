import React, { ReactNode } from 'react';
import { CalendarButtonContainer } from './CalendarButton.elements';
import { StyledUiContainerProps } from 'components';
import { useTranslation } from 'react-i18next';
import { IcoCalendar } from 'icons/IcoCalendar';

export interface CalendarButtonProps extends StyledUiContainerProps {
  handleClick?: (x: Record<string, unknown>) => void;
  label?: ReactNode;
  iconOnly?: boolean;
}

interface Props extends CalendarButtonProps {
  children?: ReactNode;
}

export const CalendarButton = ({
  children,
  handleClick,
  label,
  className,
  iconOnly
}: Props): JSX.Element => {
  const { t } = useTranslation(['mh']);

  let labelToUse = label || children;

  if (iconOnly) {
    className += ' icon-only';
    labelToUse = undefined;
  }

  if (typeof labelToUse === 'string') labelToUse = t(labelToUse);

  const buttonSettings = {
    className,
    onClick: () => handleClick?.({ label })
  };

  return (
    <CalendarButtonContainer {...buttonSettings}>
      <IcoCalendar />
      {labelToUse}
    </CalendarButtonContainer>
  );
};
