import React, { useMemo } from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
//import { DatepickerBar } from 'common/pages/fleet/machine/proseal/MachineProduction/components/DatepickerBar/DatepickerBar';
import { useDataManagementProvider } from '../../provider/useDataManagement';
import { useTimeZone } from 'providers/timeZoneProvider';
//import { useMachineModesAPIProvider } from '../../provider/useMachineModesProvider';
import { useDateRangeV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { ResetIcon } from 'icons/resetbtn';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { DatepickerBarContainer } from 'common/pages/fleet/machine/proseal/MachineProduction/components/DatepickerBar/elements';
import { DisplayDateButtonWithDropdown } from 'components/StyledUi/DisplayDateRangeButton/DisplayDateButtonWithDropdown';

interface Props {
  version?: string;
}

export const DatepickerBarWrapper = ({ version }: Props): JSX.Element => {
  //Based on a routing version either v1 or v2 we get timezone value from different providers
  const providertoCall = version?.toLowerCase() === 'v1' ? useTimeZone : useMachineInfo;
  const { timeZone } = providertoCall();
  const { handleResetButton } = useDataManagementProvider();
  const showDaterange = true;

  const { dateRange, setDateRange } = useDateRangeV2();

  const handleResetBtn = handleResetButton;

  const timezone = timeZone || 'UTC';

  const resetButton = (
    <button className="reset-button" onClick={() => handleResetBtn?.()}>
      <ResetIcon /> Reset{' '}
    </button>
  );

  const timeZonedDateRange = {
    startTime: utcToZonedTime(dateRange.startTime, timezone),
    endTime: utcToZonedTime(dateRange.endTime, timezone)
  };

  const datepickerBarMemo = useMemo(() => {
    return (
      <>
        <p>Timeframe</p>
        <DisplayDateButtonWithDropdown
          {...{ dateRange: timeZonedDateRange, setDateRange, hasGoBackDateLimit: 30, timeZone }}
        />
      </>
    );
  }, [setDateRange]);

  return (
    <DatepickerBarContainer className="widget__time-bar">
      {resetButton}
      {showDaterange && datepickerBarMemo}
    </DatepickerBarContainer>
  );

  // const datepickerMemo = useMemo(() => {
  //   const settingsDaterange = {
  //     showDaterange: true,
  //     timeZone,
  //     handleReset: handleResetButton
  //   };

  //   return <DatepickerBar {...settingsDaterange} />;
  // }, [handleResetButton]);

  // return <>{datepickerMemo}</>;
};
