// 3rd party
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import styled, { useTheme } from 'styled-components';

// Components
import { PopUpCard } from 'components';

// Icons
import { IcoPencilEdit } from 'icons/IcoPencilEdit';
import { IcoDocumentDuplicate } from 'icons/IcoDocumentDuplicate';
import { IcoClock } from 'icons/IcoClock';
import { IcoTrash } from 'icons/IcoTrash';

// Types
import { MtlOption } from 'types/machine-management';

interface MtlOptionPopupCardProps {
  visible: boolean;
  mtlId: string;
  posX?: number;
  posY?: number;
  clickPosition?: number;
  handleClose: () => void;
  vh?: number;
  handleOption: (optionType: MtlOption, mtlId: string) => void;
  baseRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const InvisibleLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
`;

const MtlOptionsContainer = styled.div`
  height: auto;
  width: 100%;
`;

const MtlOptionItem = styled.div`
  width: 100%;
  height: 2.5rem;
  padding: 0.6875rem 0.875rem 0.6875rem 1rem;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey10};
    border-radius: 0.5rem;
  }
`;

const MtlOptionLabel = styled.div`
  flex-grow: 1;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${(props) => props.theme.colors.darkGrey};
`;

const MtlOptionIcon = styled.div`
  flex-grow: 0;
  width: 1rem;
`;

const InvisibleInteractionLayer = ({
  onClose,
  posY
}: {
  onClose: () => void;
  posY: number;
}): React.ReactElement => {
  return ReactDOM.createPortal(
    <InvisibleLayer style={{ height: posY + 100 }} onClick={() => onClose()} />,
    document.body
  );
};

const MtlOptionsModal = ({
  visible,
  mtlId,
  posX,
  posY,
  handleClose,
  handleOption,
  clickPosition,
  vh,
  baseRef
}: MtlOptionPopupCardProps): ReactElement => {
  const theme = useTheme();

  return (
    <>
      <InvisibleInteractionLayer onClose={handleClose} posY={posY ?? 0} />
      <PopUpCard
        show={visible}
        setShowFunction={handleClose}
        // Subtract 160 (i.e. almost the full 10rem width of this pop-up)
        positionX={posX ? posX - 140 : 0}
        positionY={
          posY && clickPosition && vh ? (clickPosition + 250 <= vh ? posY : posY - 188) : 0
        }
        widthRem={10}
        hideCloseButton={true}
        padChildren={false}
        baseRef={baseRef}
        borderColor={theme.colors.lightGrey10}
      >
        <MtlOptionsContainer>
          <MtlOptionItem
            role="button"
            onClick={() => {
              handleOption(MtlOption.Edit, mtlId);
            }}
          >
            <MtlOptionIcon>
              <IcoPencilEdit />
            </MtlOptionIcon>
            <MtlOptionLabel>Edit</MtlOptionLabel>
          </MtlOptionItem>
          <MtlOptionItem
            role="button"
            onClick={() => {
              handleOption(MtlOption.Duplicate, mtlId);
            }}
          >
            <MtlOptionIcon>
              <IcoDocumentDuplicate />
            </MtlOptionIcon>
            <MtlOptionLabel>Duplicate</MtlOptionLabel>
          </MtlOptionItem>
          <MtlOptionItem
            role="button"
            onClick={() => {
              handleOption(MtlOption.Versions, mtlId);
            }}
          >
            <MtlOptionIcon>
              <IcoClock />
            </MtlOptionIcon>
            <MtlOptionLabel>Version History</MtlOptionLabel>
          </MtlOptionItem>
          <MtlOptionItem
            role="button"
            onClick={() => {
              handleOption(MtlOption.Delete, mtlId);
            }}
          >
            <MtlOptionIcon>
              <IcoTrash width="25" />
            </MtlOptionIcon>
            <MtlOptionLabel>Delete</MtlOptionLabel>
          </MtlOptionItem>
        </MtlOptionsContainer>
      </PopUpCard>
    </>
  );
};

export default MtlOptionsModal;
