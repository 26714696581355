import styled, { css } from 'styled-components';
import { StyledUiContainerProps, styledTheme } from 'components';
import { themeColors } from 'themes';
import theme from 'common/theme';
export const baseClass = 'widget-ui';

export const basePad = '1.3em';
export const resFontSize = 'clamp(13.5px, 1.2vw, 16px)';

// overrides that simulate the old widget ui look
const styleTypeV1 = css`
  .${baseClass}__header {
    background-color: #f9fafb;
    padding: 0.5rem 0.65rem;

    h2 {
      font-size: 1.3em;
      font-weight: 500;
    }
  }
`;

// exporting this to re-use if we need to
export const WidgetUiHeaderStyles = css`
  font-weight: 500;
  margin: 0;
  font-size: 1.1em;
  flex-grow: 1;
  padding: 0;
  text-transform: capitalize;
  margin: 0;
  color: ${theme.colors.title};
  .sub-text {
    font-size: 0.8em;
    opacity: 0.8;
    text-transform: none;
  }
`;

export const WidgetUiMainStyles = css`
  border-width: 0 1px 1px 1px;
  border-style: solid;
  min-height: 100%;
  height: 100%;
  border-color: inherit;
  position: relative;
  padding-top: 0;
  overflow-y: auto;
  width: 100%;

  border-bottom-left-radius: ${styledTheme.radius.base};
  border-bottom-right-radius: ${styledTheme.radius.base};

  &.has-overflow {
    overflow: visible;
    max-width: 100%;
  }

  &.centered {
    align-items: center;
    justify-content: center;
  }

  &.override-rc-rable {
    .rc-table {
      overflow: visible;
      border-width: 1px 0 0 0;
    }

    .rc-table-content {
      max-height: 100%;
      overflow: visible;
    }
  }
`;

// exporting this to re-use if we need to
export const WidgetUiHeaderStylesMedium = css`
  margin: 0;
  flex-grow: 1;
  padding: 0;
  margin: 0;
`;

export const WidgetUiBorderStyles = css`
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.border.main};
  border-radius: ${theme.radius.base};
`;

export const WidgetUiContainerOnlyStyles = css`
  ${WidgetUiBorderStyles};
  padding: ${basePad};
`;

export const WidgetUiContainer = styled.div<StyledUiContainerProps>`
  display: grid;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  border-width: 0;
  position: relative;
  grid-template-columns: 1fr;

  width: ${({ width }) => width || `100%`};
  color: ${styledTheme.color.main};
  font-size: ${resFontSize};
  border-color: ${theme.colors.border.main};
  grid-template-rows: ${({ gridRows }) => gridRows || `auto 1fr`};
  grid-template-areas: ${({ gridAreas }) => gridAreas};

  .is-capitalized {
    text-transform: capitalize;
  }

  &.no-header .${baseClass}__main {
    border: solid 1px ${theme.colors.border.main};
    border-top-left-radius: ${styledTheme.radius.base};
    border-top-right-radius: ${styledTheme.radius.base};
    min-height: 100%;
  }

  &.has-sub-header {
    .${baseClass}__header {
      .padding-bottom: 0;
    }
  }

  .${baseClass}__header {
    display: flex;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    gap: 0.5em;
    align-items: center;
    border-color: inherit;

    .date-range-picker {
      z-index: 500;
    }

    padding: ${basePad} ${basePad} 0 ${basePad};

    h2 {
      ${WidgetUiHeaderStyles}
    }

    border-top-left-radius: ${styledTheme.radius.base};
    border-top-right-radius: ${styledTheme.radius.base};

    .icon-gear {
      height: 1.3em;
      width: 1.3em;
      path {
        stroke: ${styledTheme.color.secondary};
      }
    }

    .icon-calendar {
      height: 1.3em;
      width: 1.3em;
      path {
        stroke: ${styledTheme.color.secondary};
      }
    }
  }

  &.has-sub-header {
    .${baseClass}__header {
      padding-bottom: 0.4em;
    }
  }

  .no-overflow {
    overflow: hidden;
  }

  .${baseClass}__sub-header {
    max-width: 100%;
    width: 100%;
    position: relative;
    border-width: 0 1px;
    border-style: solid;
    border-color: inherit;
    padding: 0 ${basePad} ${basePad};
  }

  &.is-loading,
  &.has-message,
  &.has-error {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .widget-ui__main {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .${baseClass}__sub-header {
      display: none;
    }
  }

  &.scrollable {
    .${baseClass}__main {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
    }
  }

  .${baseClass}__main, .widget-ui-main {
    ${WidgetUiMainStyles};
  }

  .date-range-picker {
    z-index: 500;
  }

  &.has-footer {
    .${baseClass}__main {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    & > footer,
    .${baseClass}__footer {
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: inherit;
      border-bottom-left-radius: ${styledTheme.radius.base};
      border-bottom-right-radius: ${styledTheme.radius.base};
    }
  }

  .has-padding {
    padding: 0 ${basePad} ${basePad} ${basePad};
  }

  &.style-type--v1 {
    ${styleTypeV1}
  }

  &.color-coded-widget {
    .widget-ui-header {
      .color-coded-title--green {
        color: ${themeColors.green2};
      }

      .color-coded-title--red {
        color: ${themeColors.errorRed};
      }
    }
  }

  .has-tooltips:hover {
    overflow: visible;
  }
`;
