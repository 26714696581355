import styled from 'styled-components';

// Components
import { Button, Card } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Themes
import theme from 'themes';

export const Container = styled(Card)`
  display: flex;
  flex: 1;
`;

export const LeftSideContainer = styled.div`
  max-width: 20%;
  border-right: 1px solid #f2f3f4;
  margin: 1.5rem 0;
`;

export const TitleDiv = styled.div`
  margin: 0 2rem;
  display: flex;
  gap: 0.6rem;
  justify-content: center;
  align-items: center;
`;

export const TitleText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
`;

export const RightSideContainer = styled.div<{ height?: number | string }>`
  flex: 1;
  ${({ height }) =>
    !!height &&
    `
    height: ${height};
  `}
  min-height: 25rem;
  padding-left: 2rem;

  button {
    display: flex;
    align-items: center;
    font-size: 0.87rem;
    padding: 0.35rem;
    background-color: #f1f7ff;
    border-color: #0a70ff;
    border: 1px solid;
    border-radius: 0.3rem;
    cursor: pointer;
    border: 0;
    background: none;
    height: 30px;

    svg {
      margin: 0 5px;
      opacity: 1;
    }

    &:disabled svg {
      opacity: 0.3;
    }

    &.button.undo {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const NoContentText = styled.p`
  text-align: center;
  transform: translateY(120px);
`;

export const LabelComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.2rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${(tooltip: { color: string; bg: string }) =>
    tooltip ? tooltip.color : theme.colors.darkGrey} !important;
  div {
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
    transform: translateY(3px);
  }
`;

export const StyledMetadataIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

export const StyledMetadataInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '1.25rem',
  gap: '0.625rem',
  width: '16rem',
  height: '100%',
  background: '#f9fafb',
  backgroundColor: theme.colors.lightGrey1,
  borderRight: `0.0625rem solid ${theme.colors.lightGrey2}`,
  flex: 'none',
  order: 2,
  alignSelf: 'stretch',
  flexGrow: 0,
  zIndex: 2
}));

export const StyledMetadataButton = styled(Button)`
  cursor: pointer;
  display: table;
  margin: 0 auto;
  float: none;
  max-width: 6.25rem;
  margin-top: 6.25rem;
`;

export const StyledIconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 1.5rem 1rem !important;
`;

export const KPICellValueContainer = styled.div<{ bg: string }>`
  background-image: url(${(props) => props.bg});
  // background-repeat: no-repeat;
  background-position: center;

  text-align: center;
  // max-width: 50%;
  font-size: 1.1em;
  margin: 2rem;

  .cell-value__value {
    font-size: 1.25em;
    line-height: 0.95;
    font-weight: 500;
    color: #323130;
  }

  .cell-value__title {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0.8em;
    text-transform: capitalize;
  }

  .cell-value__label {
    margin-top: 0.85em;
    font-size: 0.85em;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 0.85;
    color: #666666;
  }
`;

export const ZoomContainer = styled.div`
  display: flex;
`;

export const VictoryChartContainer = styled.div`
  & div .zoom-container {
    display: none;
    background-color: red;
  }
`;

export const NewLegend = styled.div`
  display: flex;
  gap: 1rem;
  align-item: center;
  padding: 1.5rem;
`;

export const RightHeaderDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const RightSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
