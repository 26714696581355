import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import BookmarkPage from './BookmarkPage';
import CategoryPage from './CategoryPage';
import SearchHistoryPage from './SearchHistoryPage';
import SearchPage from './SearchPage';
import { useGetBookmarkDocumentQuery } from 'api';

type Params = {
  page: string;
};
type Props = {
  machineDescription?: string;
  machineDescLoading: boolean;
};
const DocumentationPages = ({ machineDescription, machineDescLoading }: Props): ReactElement => {
  const params: Params = useParams();
  const path = window.location.pathname;
  const { data: bookmarkedDocuments } = useGetBookmarkDocumentQuery();
  const totalBookmark = bookmarkedDocuments?.length || 0;

  if (params.page === 'search-history') {
    return <SearchHistoryPage />;
  } else if (params.page === 'bookmark') {
    return <BookmarkPage />;
  } else if (params.page === 'search') {
    return <SearchPage totalBookmark={totalBookmark} />;
  } else if (params.page === 'category' || path.includes('/category')) {
    return (
      <CategoryPage
        machineDescLoading={machineDescLoading}
        machineDescription={machineDescription}
        totalBookmark={totalBookmark}
      />
    );
  }

  return <div></div>;
};

export default DocumentationPages;
