import React from 'react';

export const Production = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5364_132264)">
        <path
          d="M5.6001 16.7604H22.4001V5.64551H5.6001V25.4053"
          stroke="#29A429"
          strokeWidth="0.840002"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_5364_132264)">
          <path
            d="M9.396 11.3001C9.396 11.8516 9.50463 12.3978 9.7157 12.9074C9.92677 13.4169 10.2361 13.8799 10.6261 14.2699C11.0162 14.66 11.4792 14.9693 11.9887 15.1804C12.4983 15.3915 13.0444 15.5001 13.596 15.5001C14.1475 15.5001 14.6937 15.3915 15.2033 15.1804C15.7128 14.9693 16.1758 14.66 16.5658 14.2699C16.9558 13.8799 17.2652 13.4169 17.4763 12.9074C17.6874 12.3978 17.796 11.8516 17.796 11.3001C17.796 10.7485 17.6874 10.2024 17.4763 9.69283C17.2652 9.18326 16.9558 8.72026 16.5658 8.33025C16.1758 7.94024 15.7128 7.63087 15.2033 7.4198C14.6937 7.20873 14.1475 7.1001 13.596 7.1001C13.0444 7.1001 12.4983 7.20873 11.9887 7.4198C11.4792 7.63087 11.0162 7.94024 10.6261 8.33025C10.2361 8.72026 9.92677 9.18326 9.7157 9.69283C9.50463 10.2024 9.396 10.7485 9.396 11.3001Z"
            stroke="#29A429"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.196 11.3L13.1294 12.2334L14.996 10.3667"
            stroke="#29A429"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5364_132264">
          <rect width="28" height="28.2283" fill="white" />
        </clipPath>
        <clipPath id="clip1_5364_132264">
          <rect width="11.2" height="11.2" fill="white" transform="translate(7.99609 5.7002)" />
        </clipPath>
      </defs>
    </svg>
  );
};
