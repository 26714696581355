import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { differenceInSeconds, parseISO, getHours, getMinutes, getSeconds, parse } from 'date-fns';

// This makes a ISO time stamp out of StartDate and StartTime
// example startDate: 2023-11-15, startTime: 01:50:42 will become 2023-11-15T01:50:42+00:00
// if you provide a timezone, it will add the offest to the timestamp but will convert the time.
// this function only compbines start and end time and adds the offset to the end of the timestamp
export const combineDateAndTimeToISOTimeStamp = (
  dateStamp: string,
  timeStamp: string,
  options?: {
    timeZone?: string;
    convertToZonedTime?: boolean;
  }
): string => {
  const newStamp = `${dateStamp}T${timeStamp}`;
  // add the UTC offset as default
  let offset = '+00:00';
  // make a utc timestamp
  const utcStamp = `${newStamp}${offset}`;
  // sometimes the time has already been zoned, so we jus want to add the offset to the end,
  // not convert it
  if (options?.timeZone) {
    // Format the converted date with the new time zone offset
    if (options?.convertToZonedTime)
      return convertUTCTimestamptoZonedTimestamp(utcStamp, options?.timeZone) as string;
    else offset = formatInTimeZone(new Date(), options.timeZone, 'XXX');
  }

  return `${newStamp}${offset}`;
};

// add offset text to timestamp
// example timestamp: 2023-11-15T01:50:42 will become 2023-11-15T01:50:42+00:00 or -05:00 depending on the provided timezone
export const addOffsetTextToTimestamp = (
  timeStamp: string,
  options?: {
    timeZone?: string;
    convertToZonedTime?: boolean;
  }
): string => {
  const { timeZone } = options || {};

  const offset = timeZone ? convertToZonedTimestamp(new Date(), timeZone)?.slice(-6) : '+00:00';

  return timeStamp + offset;
};

// takes a UTC timestamp and a timezone, applies the offset to the time and adds the offset to the timestamp then returns a new ISO timestamp with zoned time
// EXAMPLE --------------
// UTC timestamp: 2023-11-15T01:50:42+00:00
// Timezone: Pacific/Honolulu (which is -10 hours from utc)
// will return the string 2023-11-14T15:50:42-10:00
export const convertToZonedTimestamp = (
  timestamp: string | Date,
  timeZone?: string,
  format?: string
): string | undefined => {
  timeZone = timeZone || 'UTC';

  if (timestamp instanceof Date) timestamp = zonedTimeToUtc(timestamp, timeZone).toISOString();
  // set default format, zoned time with offset
  format = format || `yyyy-MM-dd'T'HH:mm:ssXXX`;
  // default format for chart axis date tick marks
  if (format === 'chart-axis') format = 'M/dd';

  // Format the converted date with the new time zone offset
  const formattedDate = formatInTimeZone(timestamp, timeZone, format);
  // return the new formated timestamp string
  return formattedDate;
};

// renaming above function to be more clear
export const convertUTCTimestamptoZonedTimestamp = convertToZonedTimestamp;

// get the number of seconds between a start and end time, provided in ISO string format
// and return a number value of the amount of seconds between the 2
export const getDurationISOTimeStamps = (start: string, end: string): number =>
  differenceInSeconds(parseISO(start), parseISO(end));

// get user locale based on browser
export const getUserLocale = (): string =>
  navigator.languages.length ? navigator.languages[0] : navigator.language;

export interface GetCUrrentZonedTimeReturnProps {
  string?: string;
  date: Date;
  utc: string;
  zoned: string;
}

export interface GenerateZonedStartTimeProps {
  subtractSeconds?: number;
  subtractDays?: number;
  subtractHours?: number;
  subtractMinutes?: number;
  subtractWeeks?: number;
  startOf?: 'day' | 'week' | 'month' | 'year';
  /** custom format, please us date-fns format string options , like 'YYYY/MM/DD or P, p  etc' */
  format?: string;
}

export const generateZonedTimeframe = (
  timeZone: string,
  {
    subtractDays,
    subtractSeconds,
    subtractHours,
    subtractMinutes,
    startOf
  }: GenerateZonedStartTimeProps
): {
  startTime: string;
  endTime: string;
} => {
  const start = generateZonedTimestamp(timeZone, {
    subtractDays,
    subtractSeconds,
    subtractHours,
    subtractMinutes,
    startOf
  }).utc;

  return {
    startTime: start,
    endTime: getCurrentZonedTime(timeZone).utc
  };
};

// returns a zone and utc string timestamp for start of day
export const getZonedStartOfDay = (
  timeZone?: string
): {
  zoned: string;
  utc: string;
  date: Date;
  formatted: string;
} => {
  timeZone = timeZone || 'UTC';

  // Get the current date and time
  const zonedTimestampArr = (convertToZonedTimestamp(new Date(), timeZone) as string).split('T');
  const dateStr = zonedTimestampArr[0];
  const offesetStr = zonedTimestampArr[1].slice(-6);

  const newTimestamp = `${dateStr}T00:00:00${offesetStr}`;
  const newUTCTimestamp = convertToZonedTimestamp(newTimestamp, 'UTC') as string;

  return {
    zoned: newTimestamp,
    utc: newUTCTimestamp,
    date: new Date(newTimestamp),
    formatted: convertToZonedTimestamp(newTimestamp, timeZone, 'P, p') as string
  };
};

// this will return a new timestamp with a iso string version of the zoned time and utc time
export const generateZonedTimestamp = (
  timeZone: string,
  {
    subtractSeconds,
    subtractDays,
    subtractHours,
    subtractMinutes,
    subtractWeeks,
    format,
    startOf
  }: GenerateZonedStartTimeProps
): {
  utc: string;
  zoned: string;
  date: Date;
  formatted: string;
} => {
  // Get the current date and time
  timeZone = timeZone || 'UTC';
  subtractSeconds = subtractSeconds || 0;
  // default format is 04/22/2024, 6:58 PM
  format = format || 'P, p';

  if (subtractMinutes) subtractSeconds = subtractMinutes * 60;
  if (subtractHours) subtractSeconds = subtractHours * 3600;
  if (subtractDays) subtractSeconds = subtractDays * 86400;
  if (subtractWeeks) subtractSeconds = subtractWeeks * 604800;

  // do this to convert to ms for adding and subtracting time frames
  subtractSeconds = subtractSeconds * 1000;
  // get a new numering timestamp from utc epoch
  const nowV2 = subtractDays
    ? getZonedStartOfDay(timeZone).date.getTime()
    : getCurrentZonedTime(timeZone).date.getTime();

  // subtract the amount of ms from current utc ms
  const subtractedUtcTime = utcToZonedTime(new Date(nowV2 - subtractSeconds), timeZone);

  const range =
    startOf === 'day'
      ? getZonedStartOfDay(timeZone)
      : {
          utc: convertToZonedTimestamp(subtractedUtcTime, 'UTC') as string as string,
          zoned: convertToZonedTimestamp(subtractedUtcTime, timeZone) as string,
          date: subtractedUtcTime,
          formatted: convertToZonedTimestamp(subtractedUtcTime, timeZone, format) as string
        };

  return range;
};

export const generateZonedStartTime = (
  props?: GenerateZonedStartTimeProps,
  timeZone?: string
): {
  utc: string;
  zoned: string;
  date: Date;
} => generateZonedTimestamp(timeZone || 'UTC', props || {});

export const getZonedStartTime = (
  timeZone?: string,
  options?: {
    subtractSeconds?: number;
    subtractDays?: number;
    startOfDay?: boolean;
  }
): {
  zoned: string;
  utc: string;
  date: Date;
} => generateZonedTimestamp(timeZone || 'UTC', options || {});

// gets the current time in a specific timeZone
// redirecting to newer funtion
export const getCurrentZonedTime = (timeZone?: string): GetCUrrentZonedTimeReturnProps => {
  if (typeof timeZone !== 'string') timeZone = timeZone || 'UTC';

  //if (options?.startOfDay) return getZonedStartOfDay(timeZone);

  // Get the current date and time
  const now = new Date();
  const seconds = getSeconds(now);
  const roundedSeconds = Math.ceil(seconds / 15) * 15;

  now.setSeconds(roundedSeconds);
  now.setMilliseconds(0);

  const utcZonedTime = zonedTimeToUtc(now, timeZone);
  const zonedTime = utcToZonedTime(utcZonedTime, timeZone);

  const formattedUTC = convertToZonedTimestamp(zonedTime.toISOString(), 'utc') as string;
  const formattedZoned = convertToZonedTimestamp(zonedTime.toISOString(), timeZone) as string;

  return {
    zoned: formattedZoned,
    string: formattedZoned,
    utc: formattedUTC,
    date: new Date(zonedTime)
  };
};

/** this loops through an array and retuns the latest string timestamp */
export const getLastTimestamp = (
  dateKey: string,
  options?: {
    data?: Record<string, unknown>[];
  }
): string | undefined =>
  !options?.data
    ? undefined
    : options.data.reduce((acc, item) => {
        const date = item?.[dateKey] as string;
        if (!date) return acc;
        if (acc === '') acc = date;
        if (acc < date) acc = date;
        return acc;
      }, '');

// this can be removed
export const getDateTime = (dateString: string): string[] => {
  const [date, times] = dateString.split('T');
  const [time] = times.split('+');
  return [date, time];
};

// converts an iso timestamp with the timezone offset
/** please use  convertToZonedTimestamp */
export const formatZonedTimestamp = (timestamp: string, tz: string, format?: string): string =>
  formatInTimeZone(parseISO(timestamp), tz, format || 'P, p');

export const convertDateToString = (
  date: Date,
  formatting?: string,
  options?: {
    // takes a date object with the local timezone attached to it,
    // converts the timestamp to UTC time
    convertToUTC?: boolean;
    timeZone?: string;
  }
): string => {
  formatting = formatting || 'api-call';

  const timeZone = options?.timeZone || 'UTC';

  const formats: Record<string, string> = {
    'chart-axis': 'MMM/d',
    'api-call': `yyyy-MM-dd'T'HH:mm:ssXXX`
  };

  const formatter = formats?.[formatting];
  const formatted = convertToZonedTimestamp(date, timeZone, formatter) as string;

  return options?.convertToUTC
    ? (convertToZonedTimestamp(formatted, 'utc') as string)
    : (formatted as string);
};

// converts seconds to 'hours as a number'
export const convertSecondsToHours = (seconds: number, round?: boolean): number => {
  const val = seconds / 3600;
  return round ? Math.ceil(val * 2) / 2 : val;
};

// converts 'hh:mm:ss' to seconds
export const convertTimeToSeconds = (time?: string): number => {
  if (!time) return -1;
  const parsedTime = parse(time, 'HH:mm:ss', new Date());
  return getHours(parsedTime) * 3600 + getMinutes(parsedTime) * 60 + getSeconds(parsedTime);
};

export const convertSecondsToTime = (
  seconds: unknown,
  formatType?: 'small' | 'hours' | 'long' | 'hr-min' | 'h:m:s' | 'h:m' | 'hrs' | string
): string => {
  if (typeof seconds === 'string') seconds = Number(seconds);
  if (typeof seconds !== 'number') return '';

  let hours: string | number = Math.floor(seconds / 3600);

  seconds -= hours * 3600;

  hours = String(hours);

  const minutes = Math.floor(seconds / 60);

  seconds -= minutes * 60;
  seconds = Math.floor(seconds);
  seconds = Number(seconds) < 1 ? 1 : seconds;

  const showMin = minutes < 10 ? `0${minutes}` : minutes;
  const showSec = Number(seconds) < 10 ? `0${seconds}` : seconds;

  let formatted = `${hours || '00'}:${showMin || '00'}:${showSec || '00'}`;

  // costom formatter, "hours:minutes:seconds" leave out the seconds to now show them and set to 0 if you don't want to use
  if (formatType?.includes(':') || formatType?.includes('#')) {
    const splitter = formatType.includes('#') ? '#' : ':';
    const [customH, customM, customS] = formatType.split(splitter);
    formatted = customH === '0' || !hours ? `` : `${hours}${customH}`;
    if (splitter === ':') formatted = `${formatted} `;
    if (customM !== '0' && minutes) formatted = `${formatted}${minutes}${customM}`;
    if (splitter === ':') formatted = `${formatted} `;
    if (customS && customS !== '0' && showSec) formatted = `${formatted}${seconds}${customS}`;
  }

  if (formatType === 'hrs') {
    formatted = `${hours || `0`} hrs`;
  }

  if (formatType === 'long' || formatType === 'hr-min') {
    formatted = `${hours || minutes || seconds}`;
    if (hours) formatted += ` hr`;
    if (minutes) formatted += ` ${showMin} min`;
  }

  if (formatType === 'small') {
    formatted = ``;
    if (hours) formatted += `${hours} hr`;
    if (showMin) formatted += ` ${showMin} min`;
  }

  return formatted;
};
