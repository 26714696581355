import { UseApiDataReturnProps } from 'sandbox/joey/useApiCall';
import { useFleetMachineAccountData } from '../../hooks/useFleetMachineAcountData';
import { useDateRange } from 'components';
import { useGetSkidUtilizationKpiQuery } from 'api';

export type UseSkidUtilizationKpiReturnProps = UseApiDataReturnProps;

export const useSkidUtilizationApi = (): UseApiDataReturnProps => {
  const { machineId } = useFleetMachineAccountData();

  const startTime = useDateRange().isoDateRange.startTime.split('T')[0];
  const endTime = useDateRange().isoDateRange.endTime.split('T')[0];

  if (!machineId) return { hasError: 'error: missing machine id' };
  if (!startTime) return { hasError: 'error: missing start time' };

  const { data, isLoading, error } = useGetSkidUtilizationKpiQuery({
    machineId,
    startTime,
    endTime
  });

  return {
    data: data as Record<string, unknown>[],
    isLoading,
    hasError: error ? 'error in api call' : undefined,
    hasMessage: data?.length === 0 ? 'No data in range' : undefined
  };
};
