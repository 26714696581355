import React from 'react';

import { FilterSelectedProvider, useDateRange, useFilterSelected } from 'components';
import { useGetPressurizeStateDataByIdQuery } from 'api';
import { useParams } from 'react-router-dom';
import { PieChartWidget, PieChartWidgetProps } from 'common/components';
import { convertSecondsToHours } from 'common/helpers/dateAndTimeHelpers';

// todo: add these to style guide
const demoColors: Record<string, string> = {
  Waiting: '#8A8886',
  'In cycle': '#0076CC',
  'Operator stop': '#E04854',
  'Warm-up': '#18A03C',
  Starved: '#E66C37',
  Washing: '#00A6FF',
  'Machine stop': '#750985',
  Blocked: '#FF1A1A',
  'PLC Start': '#009EB3'
};

const TooltipComponent = ({ label, value }: Record<string, unknown>): JSX.Element => {
  const val = convertSecondsToHours(value as number, true);
  const val2 = val < 1 ? '< 1' : val;

  return (
    <>
      <div className="pie-chart-tooltip__label">{label}</div>
      <div className="pie-chart-tooltip__value">
        {val2} <span className="small-text">Hours</span>
      </div>
    </>
  );
};

const MachineStates = ({
  data,
  isLoading,
  hasError,
  hasMessage
}: PieChartWidgetProps): JSX.Element => {
  const [selected, handle] = useFilterSelected();

  const handleClick = (slice: Record<string, unknown>) => {
    const isSelected = selected?.label?.includes(slice.label as string) ? true : false;
    return handle(isSelected ? 'toggle' : 'set', { label: slice.label as string });
  };

  // the function that helps determins if the slice is selected
  const checkIfSelected = (slice: Record<string, unknown>) => {
    const label = slice.label as string;
    const selectedVals = selected?.label || [];
    if (selectedVals.includes(label) || !selectedVals.length) return true;
    return false;
  };

  // set WidgetUi settings - this is the settings for the widget
  const widgetUiSettings = {
    title: 'machine states',
    className: 'machine-states-widget',
    hasDateFooter: true,
    hasButtons: {
      headerRight: ['calendar']
    }
  };

  const pieChartSettings = {
    type: 'doughnut',
    // passing it the inner part of the tooltip.
    // the container stiles will already be applied
    TooltipComponent
  };

  const loadCenterVal = data?.[0];

  const centerCellValues = {
    title: loadCenterVal?.label,
    value: `${convertSecondsToHours(loadCenterVal?.value as number, true)}`,
    label: 'hours'
  };

  if (selected) {
    data?.forEach((x) => {
      if (x.label === selected?.label[0]) {
        centerCellValues.title = x.label;
        centerCellValues.value = `${convertSecondsToHours(x.value as number, true)}`;
      }
    });
  }

  const pieChartWidgetSettings = {
    isLoading,
    hasError,
    hasMessage,
    data,
    widgetUiSettings,
    pieChartSettings,
    handleClick,
    checkIfSelected,
    selected,
    centerCellValues,
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;
        return <>{label}</>;
      }
    }
  };

  return <PieChartWidget {...pieChartWidgetSettings} />;
};

export const MachineStatesWidget = (): JSX.Element => {
  const { utcTZConvertedISO } = useDateRange();
  const { machineId } = useParams<{ machineId: string }>();

  const params = {
    startDatetime: utcTZConvertedISO.startTime,
    endDatetime: utcTZConvertedISO.endTime,
    machineId: machineId
  };

  const { data, ...api } = useGetPressurizeStateDataByIdQuery(params);

  const pieChartData = data?.map((item) => ({
    label: item.machineState,
    value: Number(item.totalTimeSeconds),
    color: demoColors?.[item.machineState as string] as string,
    ...item
  }));

  return (
    <FilterSelectedProvider>
      <MachineStates {...{ data: pieChartData, ...api }} />
    </FilterSelectedProvider>
  );
};
