import React from 'react';

export interface IconProps {
  color?: string;
  width?: number;
}

export const IcoClipboard = ({ color, width = 16 }: IconProps): JSX.Element => {
  color = color || '#323130';

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Clipboard list">
        <path
          id="Icon"
          d="M5.99967 3.33333H4.66634C3.92996 3.33333 3.33301 3.93029 3.33301 4.66667V12.6667C3.33301 13.403 3.92996 14 4.66634 14H11.333C12.0694 14 12.6663 13.403 12.6663 12.6667V4.66667C12.6663 3.93029 12.0694 3.33333 11.333 3.33333H9.99967M5.99967 3.33333C5.99967 4.06971 6.59663 4.66667 7.33301 4.66667H8.66634C9.40272 4.66667 9.99967 4.06971 9.99967 3.33333M5.99967 3.33333C5.99967 2.59695 6.59663 2 7.33301 2H8.66634C9.40272 2 9.99967 2.59695 9.99967 3.33333M7.99967 8H9.99967M7.99967 10.6667H9.99967M5.99967 8H6.00634M5.99967 10.6667H6.00634"
          stroke={color}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
