import styled from 'styled-components';
import theme from 'common/theme';
const pad = '1.3em';

export const ProgressTilesWidgetContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  height: max-content;
  padding: 0;

  .progress-bar-container {
    height: max-content;
  }

  .kpi-progress-tile {
    padding: ${pad} ${pad} 0 ${pad};
    border-top: solid 1px ${theme.colors.border.lightest};

    &:nth-child(odd) {
      border-right: solid 1px ${theme.colors.border.lightest};
    }
    &:nth-child(1),
    &:nth-child(2) {
      border-top: none;
    }
  }
`;
