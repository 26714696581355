import React from 'react';
import { intensifierColors } from '../../IntensifierPerformance/IntensifierPerformanceView';
import { DotsChartLabelGenerator } from 'pages/FleetMachineDetail/MachineHealth/components/PressurizationDelta/chart/DotsChartLabelGenerator';

export const LabelWrapper = (): JSX.Element => {
  const settings = [
    {
      name: 'Intensifier L1',
      keyName: 'intensifier_utilization_1_1' //key nam is used to look up color value
    },
    {
      name: 'Intensifier L2',
      keyName: 'intensifier_utilization_1_2' //key nam is used to look up color value
    },
    {
      name: 'Intensifier R1',
      keyName: 'intensifier_utilization_1_3' //key nam is used to look up color value
    },
    {
      name: 'Intensifier R2',
      keyName: 'intensifier_utilization_1_4' //key nam is used to look up color value
    }
  ] as Record<string, unknown>[];

  const generatorSettings = {
    settings,
    colorMap: intensifierColors,
    className: 'intensifier-stroke-count-labels'
  };

  return <DotsChartLabelGenerator {...generatorSettings} />;
};
