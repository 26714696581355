import React from 'react';

export const TableIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 6.66667H14M2 9.33333H14M8 6.66667V12M3.33333 12H12.6667C13.403 12 14 11.403 14 10.6667V5.33333C14 4.59695 13.403 4 12.6667 4H3.33333C2.59695 4 2 4.59695 2 5.33333V10.6667C2 11.403 2.59695 12 3.33333 12Z"
      stroke="#303E47"
    />
  </svg>
);
