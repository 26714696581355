import React, { FC, SVGProps } from 'react';

export const IcoTrashV2: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Trash">
        <path
          id="Icon"
          d="M12.6665 4.66667L12.0883 12.7617C12.0385 13.4594 11.4579 14 10.7583 14H5.24133C4.54181 14 3.96122 13.4594 3.91138 12.7617L3.33317 4.66667M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M9.99984 4.66667V2.66667C9.99984 2.29848 9.70136 2 9.33317 2H6.6665C6.29831 2 5.99984 2.29848 5.99984 2.66667V4.66667M2.6665 4.66667H13.3332"
          stroke="#B62C10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
