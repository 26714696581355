import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 5rem);
  .flower-pot {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .sign-in-btn {
    padding: 2rem 0;
    button {
      font-weight: 500;
    }
  }
`;
const PageContent = styled.div`
  margin: 7rem 0;
  .text-center {
    text-align: center;
  }
`;

export { PageContainer, PageContent };
