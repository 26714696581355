import React, { ReactElement, useMemo } from 'react';

import DocsCenter from './DocsCenter';
import { usePermissions } from 'hooks';
import { PermissionScopeName } from 'types/user-management';
import UnauthorizedPage from 'pages/Permission/AccessDenied/Unauthorized';
import { useHistory } from 'react-router-dom';
import { JBTRoutes } from 'constants/routes';

const DocumentationCenter = (): ReactElement => {
  const permission = usePermissions().scopes;
  const history = useHistory();
  const isDocAccessible = useMemo(() => {
    const docPermissionObj = permission.find(
      (item) => item.name === PermissionScopeName.DOCUMENTATION_CENTER
    );
    const checkDocAccessible = docPermissionObj?.read && docPermissionObj.write;
    return checkDocAccessible;
  }, []);

  const handleClickBtn = () => {
    history.push(JBTRoutes.dashboard);
  };
  return (
    <>
      {isDocAccessible ? (
        <DocsCenter />
      ) : (
        <UnauthorizedPage btn="go_to_dashboard" handleClick={handleClickBtn} />
      )}
    </>
  );
};

export default DocumentationCenter;
