import styled from 'styled-components';
import theme, { themeColors } from 'themes';

export const Container = styled.main`
  height: calc(100vh - 17rem);
  padding: 0 2rem;
  display: flex;
  gap: 1.5rem;
  .rc-collapse {
    padding-bottom: 0 !important;
  }
  .rc-collapse-item {
    margin-bottom: 0 !important;
  }
`;
export const Header = styled.h4<{ color: string }>`
  display: flex;
  gap: 1rem;
  color: ${({ color }) => color};
  align-items: center;
`;
export const DocsNav = styled.nav`
  min-width: 16rem;
  width: 30%;
  max-width: 25%;
  user-select: none;
  overflow: auto;
  padding-top: 1rem;
  border-right: 1px solid ${theme.colors.lightGrey3};
  .rc-collapse {
    padding: 0.3rem 0;
  }
  a {
    text-decoration: none;
    display: block;
  }
`;
export const LibraryMenu = styled.div`
  all: unset;
  padding: 0 !important;
`;
export const SearchContainer = styled.div`
  position: relative;
  .searchIcon {
    position: absolute;
    z-index: 9;
    top: 0.1rem;
  }
`;
export const SearchList = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding};
  position: absolute;
  background-color: ${theme.colors.white};
  top: 1.6rem;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 18rem;
  min-height: 14rem;
  z-index: 10;
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px ${theme.colors.lightGrey10} solid;
  max-height: 13rem;
  height: 13rem;
  overflow: auto;
  .emptyText {
    text-align: center;
    margin-top: 1rem;
  }
`;
export const SearchItem = styled.div`
  :hover {
    background-color: ${theme.colors.lightGrey10};
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
`;
export const SearchText = styled.div`
  overflow: hidden;
  padding: 0.5rem 0rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const Content = styled.section`
  padding: 1rem;
  flex: 1;
`;
export const Highlight = styled.span`
  background: ${themeColors.yellow};
`;
export const HighlightText = styled.div`
  overflow: hidden;
  width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
  flex: 1;
`;
export const Heading = styled.header`
  border-bottom: 2px solid ${theme.colors.lightGrey3};
  text-align: justify;
  margin-bottom: 1.5rem;
`;

export const MenuList = styled.ul<{ padding?: string }>`
  list-style: none;
  padding-left: 2rem;
  padding: ${({ padding }) => padding};
`;
export const MenuListItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  font-weight: 500;
  font-size: 0.9rem;
  gap: 0.5rem;
  color: ${({ active, theme }) => (active ? theme.colors.primaryBlue5 : 'inherit')};
  transition: all 0.1s ease-in-out;
  :hover {
    cursor: pointer;
    color: ${theme.colors.darkBlue2};
    font-size: 0.95rem;
  }
`;
export const Divider = styled.div`
  border: 1px solid red;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: end;
`;
export const DeleteIcon = styled.div`
  margin-top: 0.1rem;
  margin-right: 1rem;
`;

export const Results = styled.p<{ flex?: boolean }>`
  ${({ flex }) => {
    return flex
      ? `display: flex;
    align-items: center;
    gap: .5rem;
    `
      : '';
  }}
  font-weight: 500;
  color: #828282;
  span {
    color: ${theme.colors.black};
  }
  a {
    all: unset;
    cursor: pointer;
  }
`;
export const Dashboard = styled.div``;
export const CardContainer = styled.div<{ reducedHeight?: string; flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexDirection }) => (flexDirection ? 'no-wrap' : 'wrap')};
  gap: 1.25rem;
  overflow: auto;
  max-height: ${({ reducedHeight }) => `calc(100vh - ${reducedHeight || '35rem'})`};
  padding-bottom: 2rem;
`;
export const Card = styled.div<{
  gridRows?: string;
  padding?: string;
  width?: string;
  height?: string;
  hoverDisable?: boolean;
}>`
  padding: ${({ padding }) => padding || 0};
  display: grid;
  grid-template-rows: ${({ gridRows }) => gridRows || '1fr'};
  gap: 0.5rem;
  width: ${({ width }) => width || '15rem'};
  height: ${({ height }) => height || '10rem'};
  border: 2px solid ${theme.colors.lightGrey10};
  border-radius: 0.6rem;
  border-bottom: 0;
  box-sizing: border-box;
  box-shadow: 0rem 0.2rem 0.3rem 0.05rem rgba(0, 0, 0, 0.08);
  ${({ hoverDisable }) =>
    hoverDisable
      ? ''
      : `:hover {
    border: 1px solid ${theme.colors.primaryBlue5};
    cursor: pointer;
  }`}
`;

export const CardContent = styled.div<{ display?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  align-self: center;
  gap: 0.4rem;
  padding: 0 1rem;
  height: 100%;
  justify-content: center;
`;
export const FileName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    width: 12rem;
    text-align: center;
    white-space: pre;
}
`;
export const FilterInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const BtnContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  white-space: nowrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
  gap: 0.2rem;
  margin-right: 0.75rem;
  :hover {
    cursor: pointer;
  }
`;
export const CategoryName = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1.5rem;
`;
export const CollapseMenu = styled.div`
  border: 1px solid ${theme.colors.tableborder};
  margin: 1rem 0;
  border-radius: 0.6rem;
  padding: 1rem;
  .machine {
    margin-left: 2rem;
  }
  .rc-collapse-header {
    border-top: 1px solid #dadada;
    padding: 0.75rem 0.5rem;
    cursor: pointer;
  }
  .rc-collapse {
    max-height: 40vh;
    overflow: auto;
  }
  .rc-collapse-item-active .rc-collapse-header {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const CollapseHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  .machineIcon {
    margin-left: 1rem;
  }
`;

export const EmptyData = styled.div`
  text-align: center;
  margin-top: 3rem;
  .emptyIcon {
    margin-left: -3rem;
  }
  .backBtn {
    margin: 3rem auto;
  }
`;
export const DateHeader = styled.div<{ hideBorder?: boolean; padding?: string }>`
  border-bottom: ${({ hideBorder, theme }) =>
    hideBorder ? '' : `1px solid ${theme.colors.lightGrey3}`};
  padding: ${({ padding }) => padding || 0};
`;
export const FileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;
export const HistoryContainer = styled.div`
  flex: 1;
  margin-right: 1rem;
`;
export const HistoryCardContainer = styled.div`
  margin-bottom: 1.25rem;
`;
export const DropdownContainer = styled.div`
  margin-left: auto;
  margin-right: 1.5rem;
`;
export const ViewController = styled.div`
  display: flex;
  gap: 1rem;
`;

export const GridIconContainer = styled.button`
  all: unset;
  cursor: pointer;
`;
