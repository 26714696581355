import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { sub } from 'date-fns';
import { format, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

function padding(val?: number): string {
  if (!val) return '00';
  return val > 9 ? `${val}` : '0' + val;
}

export function getTzOffset(timezone: string): string {
  const machineZoneOffset = getTimezoneOffset(timezone) / 1000 / 60; //turn milliseconds to minutes
  const hours = Math.floor(Math.abs(machineZoneOffset) / 60);
  const minutes = Math.abs(machineZoneOffset) % 60;

  const offsetString =
    (machineZoneOffset < 0 ? '-' : '+') +
    (hours < 10 ? '0' : '') +
    hours +
    (minutes < 10 ? '0' : '') +
    minutes;

  return offsetString;
}

export function dateToString(
  startTime?: Date,
  endTime?: Date,
  subtractDaysCount?: number,
  subtractHoursCount?: number,
  hasSeconds?: boolean
): { startTime: string; endTime: string } {
  //This function takes in 2 Date parameters and returns UTC string for those dates

  let newEnd;
  if (!endTime) {
    newEnd = new Date();
  } else {
    newEnd = endTime;
  }

  let newStart;
  if (startTime) {
    newStart = startTime;
  } else {
    if (subtractDaysCount) newStart = sub(newEnd, { days: subtractDaysCount });
    if (subtractHoursCount) newStart = sub(newEnd, { hours: subtractHoursCount });
    if (subtractDaysCount === undefined && subtractHoursCount === undefined)
      newStart = sub(newEnd, { days: 7 });
  }

  // Build UTC date
  const dayEnd = newEnd.getUTCDate();
  const monthEnd = newEnd.getUTCMonth() + 1; // Return Value is 0 indexed
  const yearEnd = newEnd.getUTCFullYear();
  const hourEnd = newEnd.getUTCHours();
  const minutesEnd = newEnd.getUTCMinutes();
  const secondsEnd = newEnd.getUTCSeconds();

  const dayStart = newStart?.getUTCDate();
  const monthStart = newStart ? newStart.getUTCMonth() + 1 : undefined; // Return Value is 0 indexed
  const yearStart = newStart ? newStart?.getUTCFullYear() : undefined;
  const hourStart = newStart ? newStart?.getUTCHours() : undefined;
  const minutesStart = newStart ? newStart?.getUTCMinutes() : undefined;
  const secondsStart = newStart ? newStart?.getUTCSeconds() : undefined;

  let defStart;
  let defEnd;

  if (!hasSeconds) {
    defStart =
      yearStart +
      '-' +
      padding(monthStart) +
      '-' +
      padding(dayStart ?? 0) +
      'T' +
      padding(hourStart) +
      ':' +
      padding(minutesStart) +
      ':' +
      padding(secondsStart) +
      '.000Z'; //2020-09-30T00:00:00.000Z Z represents the UTC time zone.

    defEnd =
      yearEnd +
      '-' +
      padding(monthEnd) +
      '-' +
      padding(dayEnd) +
      'T' +
      padding(hourEnd) +
      ':' +
      padding(minutesEnd) +
      ':' +
      padding(secondsEnd) +
      '.000Z'; //2020-09-30T00:00:00.000Z Z represents the UTC time zone.
  } else {
    defStart =
      yearStart +
      '-' +
      padding(monthStart) +
      '-' +
      padding(dayStart) +
      'T' +
      padding(hourStart) +
      ':' +
      padding(minutesStart) +
      ':00.000Z'; //2020-09-30T00:00:00.000Z Z represents the UTC time zone.

    defEnd =
      yearEnd +
      '-' +
      padding(monthEnd) +
      '-' +
      padding(dayEnd) +
      'T' +
      padding(hourEnd) +
      ':' +
      padding(minutesEnd) +
      ':00.000Z'; //2020-09-30T00:00:00.000Z Z represents the UTC time zone.
  }

  return { startTime: defStart, endTime: defEnd };
}

export function rightnowUTCString(date?: Date): string {
  const now = date ? date : new Date();
  // Build UTC date
  const dayEnd = now.getUTCDate();
  const monthEnd = now.getUTCMonth() + 1; // Return Value is 0 indexed
  const yearEnd = now.getUTCFullYear();
  const hourEnd = now.getUTCHours();
  const minutesEnd = now.getUTCMinutes();
  const seconds = now.getUTCSeconds();

  const rightnowTimeString =
    yearEnd +
    '-' +
    padding(monthEnd) +
    '-' +
    padding(dayEnd) +
    'T' +
    padding(hourEnd) +
    ':' +
    padding(minutesEnd) +
    ':' +
    padding(seconds) +
    '.000Z'; //2020-09-30T00:00:00.000Z Z represents the UTC time zone.

  return rightnowTimeString;
}

const { startTime: defStart, endTime: defEnd } = dateToString();

export interface DateRangeContextProps {
  dateRange: { startTime: string; endTime: string };
  setDateRange: (range: { startTime: Date; endTime: Date }) => void;
  machineDateRange: { startMoment: string; endMoment: string };
  utcDateRange: { startTime: string; endTime: string };
  timeZone: string;
}

export interface DateRangeProviderProps {
  subtractDaysCount?: number;
  subtractHoursCount?: number;
  timeZone?: string;
  hasFutureDates?: boolean;
  hasGoBackDateLimit?: number;
  frequencyRefresh?: number;
  startTime?: string;
  endTime?: string;
}

interface Props extends DateRangeProviderProps {
  children?: ReactNode;
  timeZone?: string;
}

export const DateRangeContextV2 = createContext<DateRangeContextProps>({
  dateRange: {
    startTime: defStart,
    endTime: defEnd
  },
  setDateRange: (range: { startTime: Date; endTime: Date }) =>
    console.log('status not set' + range),
  machineDateRange: {
    startMoment: defStart,
    endMoment: defEnd
  },
  utcDateRange: {
    startTime: defStart,
    endTime: defEnd
  },
  timeZone: 'UTC'
});

export const useDateRangeV2 = (): DateRangeContextProps =>
  useContext<DateRangeContextProps>(DateRangeContextV2);

export const DateRangeProviderV2 = ({
  subtractHoursCount,
  subtractDaysCount,
  timeZone,
  children,
  startTime,
  endTime
}: Props): JSX.Element => {
  const machineTimeZone = timeZone || 'UTC'; // Fallback on UTC timezone, timezone is a MUST, but setting UTC
  const offsetString = getTzOffset(machineTimeZone);

  const { startTime: start, endTime: end } = dateToString(
    undefined,
    undefined,
    subtractDaysCount,
    subtractHoursCount
  );

  const [machineDateRange, setMachineDateRange] = useState<{
    startMoment: string;
    endMoment: string;
  }>({
    startMoment: start,
    endMoment: end
  });

  useEffect(() => {
    if (startTime && endTime) {
      setMachineDateRange({
        startMoment: startTime,
        endMoment: endTime
      });
    }
  }, [startTime, endTime]);

  const dateRange = {
    startTime: machineDateRange.startMoment,
    endTime: machineDateRange.endMoment
  };

  const utcDateRange = {
    startTime: machineDateRange.startMoment.split('.')[0] + '+00:00', //example format: 2024-04-29T13:46:12+00:00
    endTime: machineDateRange.endMoment.split('.')[0] + '+00:00'
  };

  const setDateRange = ({ startTime, endTime }: { startTime: Date; endTime: Date }) => {
    const machineTimeStart = new Date(format(startTime, 'yyyy-MM-dd HH:mm:ss') + offsetString); //machine timezone offset
    const machineTimeEnd = new Date(format(endTime, 'yyyy-MM-dd HH:mm:ss') + offsetString); //machine timezone offset
    const { startTime: start, endTime: end } = dateToString(machineTimeStart, machineTimeEnd);

    //set daterange as UTC string
    setMachineDateRange({
      startMoment: start,
      endMoment: end
    });
  };

  const now = utcToZonedTime(rightnowUTCString(), machineTimeZone);

  const contextValue = {
    dateRange,
    setDateRange,
    machineDateRange,
    utcDateRange,
    now,
    timeZone: machineTimeZone
  };

  return <DateRangeContextV2.Provider value={contextValue}>{children}</DateRangeContextV2.Provider>;
};
