import React from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { IcoClose2 } from 'icons/IcoClose';
import { FlyoutAndPopupsContainer } from './FlyoutsAndPopups.elements';
import { useTranslation } from 'react-i18next';

export interface FlyoutProps {
  Header?: JSX.Element;
  /** custom close component */
  CloseIcon?: JSX.Element;
  /** this renders in the middle area, can be sent as children too */
  Component?: FC<Record<string, unknown>>;
  Footer?: FC<Record<string, unknown>>;
  type?: 'flyout' | 'modal' | 'full-screen';
  /** appears at the top of the popup */
  title?: ReactNode;
  TitleIcons?: JSX.Element | JSX.Element[];
  /** is the flyout open or closed, this must be controlled externally */
  isOpen?: boolean;
  /** this is require as this is a externally handled */
  handleClose: () => void;
  children?: ReactNode;
}

export const Flyout = ({
  CloseIcon,
  Header,
  Component,
  type,
  title,
  Footer,
  isOpen,
  handleClose,
  children
}: FlyoutProps): JSX.Element => {
  const { t } = useTranslation(['mh']);

  if (typeof title === 'string') title = t(title.toLowerCase());

  // ref is used for useClickoutside
  const ref = useRef(null);
  // start the grid layout and build from there
  // we start with the close button/title row
  let gridRows = 'auto';
  // add header row if needded
  if (Header) gridRows += ' auto';
  // add main content row no matter what
  gridRows += ' 1fr';
  // add footer row if needed
  if (Footer) gridRows += ' auto';
  //default is flyout for now
  type = type || 'flyout';

  let className = 'popup-container';

  if (type) className += ` is-${type}`;
  if (isOpen) className += ' is-open';

  if (Header && Footer) className += ' has-header-footer';
  else {
    if (Header) className += ' has-header';
    if (Footer) className += ' has-footer';
  }

  useClickOutside(ref, () => (isOpen ? closeHandler() : null));

  useEffect(() => {
    if (isOpen) document.body.classList.add('popup-open');
    else document.body.classList.remove('popup-open');
  }, [isOpen, open]);

  const closeHandler = () => {
    document.body.classList.remove('popup-open');
    return handleClose();
  };

  const MainToUse = (): JSX.Element => (
    <>{isOpen && (Component ? <Component /> : children || <></>)}</>
  );

  return (
    <FlyoutAndPopupsContainer {...{ className, gridRows }}>
      {
        // the wrapper is the white are and contains the drop shadow
        <div className="popup-wrapper" ref={ref}>
          {
            // the title bar is the top row with the title and close button
            <div className="popup-title-bar">
              <div className="popup-title-bar__header">
                {typeof title === 'string' ? <h2>{title}</h2> : title}
              </div>
              <button className="btn-close" type="button" onClick={() => handleClose()}>
                {CloseIcon || <IcoClose2 />}
              </button>
            </div>
          }

          {
            // the header is the row below the title bar and is optional
            Header && <div className="popup-header">{Header}</div>
          }

          {
            // the middle scroll section of the popup of flyout1
            MainToUse && (
              <div className="popup-main">
                <MainToUse />
              </div>
            )
          }

          {
            // the fixed bottom of the popup or flyout
            Footer && <div className="popup-footer">{Footer}</div>
          }
        </div>
      }
    </FlyoutAndPopupsContainer>
  );
};
