import React from 'react';
import { MemoizedChart } from './WidgetWrapper';
import { formatChartData, getData, parseAlarmData } from './utils';
import { AlertData, AllDataProps, Combined, MiddleProps } from './types';
import { useDateRange } from 'components';

// Provided gets the data
export const ProvidedPage = (): JSX.Element => {
  const { timeZone } = useDateRange();

  const { allData, isLoading, hasError } = getData();

  if (isLoading) return <>Loading...</>;
  if (!allData) return <></>;

  const { pressurizationData, alertData } = allData as AllDataProps;

  const formattedData = formatChartData(pressurizationData || [], timeZone);

  // Format Alarm Data
  const { dataAlarms, dataAlarmsByFilterKey } = parseAlarmData(
    alertData as unknown as AlertData[],
    timeZone as unknown as string
  );

  // This calculates X domain given 2 pieces of data: Alarms AND Chart data
  const combined = [...formattedData, ...dataAlarms] as Combined[];
  combined.sort(
    (a, b) =>
      (new Date(a.timestamp as unknown as string) as unknown as number) -
      (new Date(b.timestamp as unknown as string) as unknown as number)
  );

  let chartDomainX: Date[] = [];
  if (combined.length)
    chartDomainX = [combined[0].timestamp, combined[combined.length - 1].timestamp];

  const data = { dataAlarms, dataAlarmsByFilterKey, chartDomainX, chartData: formattedData };

  return <Middle data={data} isLoading={isLoading} hasError={hasError} />;
};

// Middle grabs the data and passes it to memoized function
export const Middle = ({ data, isLoading, hasError }: MiddleProps): JSX.Element => {
  return <MemoizedChart data={data} isLoading={isLoading} hasError={hasError} />;
};
