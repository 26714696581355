import React, { useEffect, useRef, useState } from 'react';

import { WidgetUi } from 'common/ui';
import { default as NoIssuesBell } from './assets/no_issues_bell.svg';
import { useMachineIssues } from 'hooks/useMachineAlarmsAlerts';
import { filterGivenDataByKeyValue, fromatDataForIssuesTable } from './utils';
import { default as Bell } from './assets/bell.svg';
import { MachineActiveIssuesTextColor } from './MachineActiveIssues.elements';
import { Issues } from './Issues/Issues';
import styled from 'styled-components';
import { themeColors } from 'themes';

export const IssuesContainer = styled.div`
  &.widget-ui-main--no_data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 1rem;

    img {
      max-width: 125px;
      width: 100%;
    }

    p {
      font-size: 1rem;
      font-weight: 700;
      color: ${themeColors.mediumGrey4};
    }
  }
`;

interface WrapperProps {
  heading: string;
  noRecordTitle: string;
  gridArea?: string;
}

export interface PHConcern {
  widgetTitle: string;
  noRecordTitle: string;
}

const sortByDate = (arr) => {
  const sorter = (a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  };
  arr.sort(sorter);
};

export const MachineIssuesWrapperAvure = ({
  heading,
  noRecordTitle,
  gridArea
}: WrapperProps): JSX.Element => {
  const { alerts, isLoading, hasError } = useMachineIssues();

  //const { startTime, endTime } = useDateRange().utcTZConvertedISO;

  //const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  //const hours = duration.asHours();

  const [issuesData, setIssuesData] = useState<Record<string, unknown>[]>([]);

  const filter = {
    description: [
      'Rise in intensifier stroke count',
      'Pressurization time significantly higher than expected'
    ]
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [isLoading]);

  useEffect(() => {
    const alertsT = alerts as unknown as Record<string, unknown>[];
    const filteredData = filterGivenDataByKeyValue(alertsT, filter);
    const tableData = fromatDataForIssuesTable(filteredData, Bell);
    sortByDate(tableData);

    setIssuesData(tableData);
  }, [alerts]);

  const hasDataToDisplay = issuesData.length === 0 ? false : true;

  const title = (
    <>
      <span>{heading} </span>
      <MachineActiveIssuesTextColor
        className={`color-coded-title color-coded-title${hasDataToDisplay ? `--red` : `--green`}`}
      >
        {`(`}
        {issuesData.length}
        {` in total)`}
      </MachineActiveIssuesTextColor>
    </>
  );

  const widgetSettings = {
    gridArea: gridArea,
    className: 'color-coded-widget',
    styleType: 'v2',
    title,
    isLoading: isFirstRender.current ? (isLoading ? true : false) : false,
    hasError: hasError ? 'Error loading data' : undefined,
    Main: !hasDataToDisplay ? (
      <IssuesContainer className="widget-ui-main widget-ui-main--no_data">
        <img src={NoIssuesBell} title={noRecordTitle} />
        <p>{noRecordTitle}</p>
      </IssuesContainer>
    ) : (
      <Issues data={issuesData} />
    )
  };

  return <WidgetUi {...widgetSettings} />;
};
