import React, { useState, useEffect } from 'react';

import { StyledButtonV2 } from 'components/StyledUi/elements/StyledButton';
import { DateRangeProps } from '..';
import { DisplayCalendar } from './DisplayCalendar';
import { RangeSelectListPropsOptions } from '../DateRange/RangeSelectList';
import { TimeRangeInputs } from '../DateRange/TimeInput/TimeRangeInputs';
import {
  defaultRangeSelectListItems,
  defaultTimeRangeSelectListItems
} from '../DateRange/RangeSelectList/defaultRangeSelectListItems';
import {
  DateRangePickerContainer,
  dateRangePickerBaseClass as baseClass
} from '../DateRange/DateRangePicker/DateRangePicker.elements';
import { DisplayRangeSelectList } from './DisplayRangeSelectList';

export interface DateRangePickerSettingsProps {
  hideTimeInputs?: boolean;
  hideDateRangeSelect?: boolean;
  hideTimeRangeSelect?: boolean;
  hideCalendar?: boolean;
  hasFutureDates?: boolean;
}

export interface DateRangePickerProps extends DateRangePickerSettingsProps {
  dateRange: DateRangeProps;
  handleSubmit: (range: DateRangeProps) => void;
  handleCancel: () => void;
  className?: string;
  hasGoBackDateLimit?: number;
  dateRangeSelectListOptions?: RangeSelectListPropsOptions;
  hourRangeSelectListOptions?: RangeSelectListPropsOptions;
  timezone?: string;
}

export const DisplayDateRangePicker = ({
  dateRange,
  handleSubmit,
  handleCancel,
  className,
  hasGoBackDateLimit,
  dateRangeSelectListOptions,
  hourRangeSelectListOptions,
  hideCalendar,
  hideDateRangeSelect,
  hideTimeInputs,
  hideTimeRangeSelect,
  hasFutureDates,
  timezone
}: DateRangePickerProps): JSX.Element => {
  const open = true;

  const [newDateRange, setNewDateRange] = useState<DateRangeProps>();

  const dayRangeOptions = dateRangeSelectListOptions || defaultRangeSelectListItems;
  const hourRangeOptions = hourRangeSelectListOptions || defaultTimeRangeSelectListItems;

  useEffect(() => {
    setNewDateRange(dateRange);
  }, [dateRange]);

  if (!newDateRange) return <></>;

  const containerSettings = {
    className: className ? `${className} ${baseClass}` : baseClass,
    'data-hidden': !open ? 'true' : undefined
  };

  //Calendar specific date range so it would highlight only 1 day
  const calendarSettings = !hideCalendar && {
    dateRange: newDateRange,
    setDateRange: setNewDateRange,
    hasGoBackDateLimit,
    hasFutureDates,
    timezone
  };

  const dateRangeListSettings = !hideDateRangeSelect && {
    handleSelect: handleSubmit,
    options: dayRangeOptions,
    dateRange
  };

  const timeRangeSettings = !hideTimeRangeSelect && {
    dateRange: newDateRange,
    setDateRange: setNewDateRange
  };

  const timeRangeListSettings = !hideTimeInputs && {
    handleSelect: handleSubmit,
    options: hourRangeOptions,
    dateRange
  };

  let gridCols: string | undefined = undefined;
  let gridRows: string | undefined = undefined;
  let gridAreas: string | undefined = undefined;

  if (hideTimeInputs && hideDateRangeSelect && hideTimeRangeSelect) {
    gridCols = `1fr`;
    gridRows = `auto auto`;
    gridAreas = `"calendar" "btns"`;
  }

  return (
    <>
      <DateRangePickerContainer {...{ gridAreas, gridCols, gridRows, ...containerSettings }}>
        {calendarSettings && (
          <DisplayCalendar className={`${baseClass}__calendar`} {...calendarSettings} />
        )}

        {dateRangeListSettings && (
          <DisplayRangeSelectList
            className={`${baseClass}__date-range-select ${baseClass}__side-col`}
            {...dateRangeListSettings}
          />
        )}

        {timeRangeSettings && (
          <TimeRangeInputs className={`${baseClass}__time-range`} {...timeRangeSettings} />
        )}

        {timeRangeListSettings && (
          <DisplayRangeSelectList
            className={`${baseClass}__time-range-select ${baseClass}__side-col`}
            {...timeRangeListSettings}
          />
        )}

        <div className={`${baseClass}__btns`}>
          <StyledButtonV2 styleType="submit" onClick={() => handleSubmit(newDateRange)}>
            Submit
          </StyledButtonV2>
          <StyledButtonV2 styleType="cancel" onClick={() => handleCancel()}>
            Cancel
          </StyledButtonV2>
        </div>
      </DateRangePickerContainer>
    </>
  );
};
