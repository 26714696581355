import React, { useEffect } from 'react';
import { PageContainer, PageContent } from './fileVerification.elements';
import { Button, Typography } from 'components';
import { useTranslation } from 'react-i18next';
import IcoDropCup from 'icons/IcoDropCup.svg';
import IcoFlowerPot from 'icons/IcoFlowerPot.svg';
import theme from 'themes';
import { ContentAlign } from 'common/components/common-styled';
import { useHistory } from 'react-router-dom';
import { useVerifySharedLinkQuery } from 'api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { LoaderContainer } from 'components/CustomLoader/index.elements';
import CustomLoader from 'components/CustomLoader';

type Props = {
  navigateToLogin?: () => void;
  docSharedId?: string;
};
const FileVerificationPage = ({ navigateToLogin, docSharedId }: Props): JSX.Element | null => {
  const { data, isLoading } = useVerifySharedLinkQuery(docSharedId ? { docSharedId } : skipToken);
  const { t } = useTranslation(['fpns']);
  const history = useHistory();
  useEffect(() => {
    if (data?.detail) {
      navigateToLogin?.();
    }
  }, [isLoading]);

  if (data?.detail) return null;

  return (
    <PageContainer>
      {isLoading ? (
        <LoaderContainer padding="5rem">
          <CustomLoader />
        </LoaderContainer>
      ) : (
        <>
          <PageContent>
            <Typography
              className="text-center"
              weight="thin"
              size={'4rem'}
              color={theme.colors.primaryBlue5}
            >
              {t('requested_page_not_found')}
            </Typography>
            <Typography
              className="text-center"
              weight="semi-bold"
              size={'2rem'}
              color={theme.colors.primaryBlue5}
            >
              {t('invalid_link_msg')}
            </Typography>
            <ContentAlign className="sign-in-btn" justifyContent="center">
              <Button
                borderRadius="3rem"
                variant="hover-blue"
                bgColor={theme.colors.primaryBlue5}
                width="fit-content"
                onClick={() => history.push('/dashboard')}
                className="backBtn"
              >
                {t('sign_in_your_account')}
              </Button>
            </ContentAlign>
          </PageContent>

          <img className="flower-pot" src={IcoFlowerPot} />
          <img src={IcoDropCup} />
        </>
      )}
    </PageContainer>
  );
};

export default FileVerificationPage;
