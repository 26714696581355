import React from 'react';
import styled from 'styled-components';
import { ImportIcon } from 'icons/ImportIcon';
import { DeleteIcon } from 'icons/DeleteIcon';
import { AddUserIcon } from 'icons/AddUserIcon';
import { UserManagementTableType, UserTypes } from 'types/user-management';
import { TableAction, TableActionType } from 'pages/UserManagementTwo';
import { EditIcon } from 'icons/EditIcon';
import { useTranslation } from 'react-i18next';

interface ButtonTextProps {
  tColor?: string;
}

const TableButton = styled.button`
  all: unset;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #323130;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }
`;
const TableIcon = styled.div`
  margin-right: 0.2rem;
`;
const TableText = styled.div<ButtonTextProps>`
  color: ${(props) => (props.tColor ? props.tColor : 'black')};
`;
const ButtonContainer = styled.div`
  display: flex;
`;
const ImportButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.55rem;
  margin-top: -0.8rem;
  border: 1px solid black;
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-left: none;
  border-right: none;
`;

type ButtonAction = {
  icon?: JSX.Element;
  text: string;
  txtColor?: string;
  disabled?: boolean;
  action?: TableActionType;
  importModeBtn?: boolean;
};
const userTableButtons: ButtonAction[] = [
  {
    icon: <AddUserIcon />,
    text: 'add_user',
    txtColor: '',
    action: 'addUser'
  },
  {
    icon: <EditIcon />,
    disabled: true,
    text: 'edit',
    txtColor: '',
    action: 'edit'
  },
  { icon: <ImportIcon />, text: 'import', txtColor: '', action: 'import' },
  {
    icon: <DeleteIcon />,
    disabled: true,
    text: 'delete',
    txtColor: 'red',
    action: 'delete'
  }
];
const userTableImportButtons: ButtonAction[] = [
  {
    icon: <ImportIcon />,
    disabled: false,
    text: 'cancel',
    txtColor: 'black',
    action: 'exit'
  },
  {
    icon: <DeleteIcon />,
    disabled: true,
    text: 'delete',
    txtColor: 'red',
    action: 'delete'
  }
];
type ButtonWrapperProps = {
  handleFlyout: ({ flyoutType, action }: TableAction) => void;
  isItemSelected?: boolean;
  isImportMode?: boolean;
};
const UserTableButtonWrapper = ({
  handleFlyout,
  isItemSelected,
  isImportMode
}: ButtonWrapperProps): JSX.Element => {
  const { t } = useTranslation(['fpns']);
  return (
    <>
      {!isImportMode ? (
        <ButtonContainer>
          {userTableButtons.map((btn) => {
            return (
              <TableButton
                onClick={() =>
                  handleFlyout({
                    flyoutType: UserManagementTableType.USER,
                    selectedUser: [] as unknown as UserTypes,
                    action: btn.action
                  })
                }
                disabled={btn.disabled && !isItemSelected}
              >
                <TableIcon>{btn.icon}</TableIcon>
                <TableText tColor={btn.txtColor}>{t(btn.text)}</TableText>
              </TableButton>
            );
          })}
        </ButtonContainer>
      ) : (
        <ImportButtonContainer>
          {userTableImportButtons.map((btn) => {
            return (
              <TableButton
                onClick={() =>
                  handleFlyout({
                    flyoutType: UserManagementTableType.USER,
                    selectedUser: [] as unknown as UserTypes,
                    action: btn.action
                  })
                }
                disabled={btn.disabled && !isItemSelected}
              >
                <TableIcon>{btn.icon}</TableIcon>
                <TableText tColor={btn.txtColor}>{t(btn.text)}</TableText>
              </TableButton>
            );
          })}
        </ImportButtonContainer>
      )}
    </>
  );
};
export default UserTableButtonWrapper;
