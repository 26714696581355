import React, { KeyboardEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Content,
  DocsNav,
  Header,
  LibraryMenu,
  MenuList,
  MenuListItem
} from './documentation.elements';
import { PageHeader, Typography } from 'components';
import { useTranslation } from 'react-i18next';
import Collapse from 'rc-collapse';
import { MenuPanel } from 'pages/MaintenanceServiceDashboard/MaintenanceQueryTableByFrequency';

import theme from 'themes';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import {
  useCreateSearchHistoryMutation,
  useGetDocumentCategoryQuery,
  useGetPlantsQuery,
  useLazySearchDocumentQuery
} from 'api';
import { getDocIcons } from 'icons/docsIcons';
import DocumentationCenterIcon from 'icons/headerIcons/DocumentationCenterIcon.svg';
import CustomLoader from 'components/CustomLoader';
import AsyncSearch, { SearchResult } from 'components/SearchInput/AsyncSearch';
import { DocsCategory, Machine, Plant } from 'types';
import LibraryPage from './DocumentationPages/LibraryPage';
import DocumentationPages from './DocumentationPages/DocumentationPages';
import { JBTRoutes } from 'constants/routes';
import { CustomFile, FileType } from 'types/machine-management';
import FilePreviewModal from 'pages/MachineManagement/UploadMachinePdf/FilePreviewModal';

const DocsCenter = (): ReactElement => {
  const { t } = useTranslation(['fpns']);
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const searchQuery = new URLSearchParams(location.search);
  const machineId = searchQuery.get('machineId') || '';
  const categoryId = searchQuery.get('categoryId');
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [activePanelKeys, setActivePanelKeys] = useState<React.Key[]>(['Library']);
  const [activeMenu, setActiveMenu] = useState('library');
  const [selectedFilePreview, setSelectedFilePreview] = useState<CustomFile>();
  const [docSearchQuery, setDocSearchQuery] = useState<string | number>('');

  const { data: plants, isLoading: plantsLoading } = useGetPlantsQuery();
  const [searchDocument, { isLoading: isSearchLoading, isFetching: isSearchFetching }] =
    useLazySearchDocumentQuery();
  const [createSearchHistory] = useCreateSearchHistoryMutation();

  useEffect(() => {
    searchDocument({ query: String(docSearchQuery) }).then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resp = response as unknown as any;
      const docs = resp?.data?.documentSearchDetails
        ? resp.data.documentSearchDetails.map((doc: FileType) => ({
            label: doc.userFileName,
            value: doc.id
          }))
        : [];
      setSearchResult(docs);
    });
  }, [docSearchQuery]);

  const machineDesc = useMemo(() => {
    const machines: Machine[] | undefined = plantsLoading
      ? undefined
      : plants?.reduce((acc: Machine[], plant: Plant) => acc.concat(plant?.machines ?? []), []);
    const description = machines?.find((machine) => machine.id === machineId)?.description;
    return description;
  }, [machineId, plantsLoading]);

  const {
    data: DocsNavItems = [],
    isLoading,
    isFetching
  } = useGetDocumentCategoryQuery({ machineId: machineId });

  const handleActiveMenu = (page: string) => {
    setActiveMenu(page);
  };

  const activePage = useMemo(() => {
    return {
      search_history: theme.colors.primaryBlue5,
      library: theme.colors.primaryBlue5,
      bookmark: theme.colors.primaryBlue5,
      [activeMenu]: theme.colors.darkBlue2
    };
  }, [activeMenu]);

  const handleSearchItemClick = (selectedItem: SearchResult) => {
    // history.push(`${url}/search?id=${selectedItem.label}`);
    createSearchHistory({ document_id: selectedItem.value });
    setSelectedFilePreview({ id: selectedItem.value, name: selectedItem.label });
  };

  const handleCategoryClick = (category: DocsCategory) => {
    history.push(`${path}/category?categoryId=${category.id}&machineId=${machineId}`);
  };

  const handleRemoveMachine = () => history.push(JBTRoutes.documentation);

  const handleSearch = (query: string | number) => {
    setDocSearchQuery(query);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && docSearchQuery) {
      history.push(`${url}/search?query=${docSearchQuery}`);
    }
  };

  return (
    <>
      <PageHeader heading={t('documentation_center')} icon={DocumentationCenterIcon} />
      <Container>
        <DocsNav>
          <Typography weight="semi-bold">{t('getting_started')}</Typography>

          {/* Search */}
          <AsyncSearch
            handleClick={handleSearchItemClick}
            searchResult={searchResult}
            handleSearch={handleSearch}
            inputValue={docSearchQuery}
            isSearching={isSearchLoading || isSearchFetching}
            handleKeyPress={handleKeyPress}
          />

          {/* Collapsable Nav */}
          <LibraryMenu>
            <Collapse
              destroyInactivePanel={true}
              activeKey={activePanelKeys}
              onChange={(key: React.Key | React.Key[]) =>
                setActivePanelKeys(Array.isArray(key) ? key : [key])
              }
            >
              {['Library'].map((panel) => {
                return (
                  <MenuPanel
                    showArrow={true}
                    collapsible="disabled"
                    key={'Library'}
                    header={
                      <Header
                        onClick={() => {
                          history.push(JBTRoutes.documentation);
                          handleActiveMenu('library');
                        }}
                        color={activePage.library}
                        style={{ cursor: 'pointer' }}
                      >
                        {t('library')}{' '}
                        {isLoading && <CustomLoader thickness=".2rem" size="1.5rem" />}
                      </Header>
                    }
                    mb={0}
                    onClick={handleActiveMenu}
                  >
                    {activePanelKeys.includes(panel) && (
                      <MenuList padding="0">
                        {DocsNavItems.map((nav) => {
                          const Icon = getDocIcons(nav.name);
                          return (
                            <MenuListItem
                              active={categoryId === nav.id}
                              onClick={() => {
                                handleActiveMenu('library');
                                handleCategoryClick(nav);
                              }}
                              key={nav.id}
                            >
                              <Icon />
                              <div>{t(nav.name)}</div>
                            </MenuListItem>
                          );
                        })}
                      </MenuList>
                    )}
                  </MenuPanel>
                );
              })}
            </Collapse>
            <Link to={`${url}/search-history`} onClick={() => handleActiveMenu('search_history')}>
              <Typography color={activePage.search_history} weight="semi-bold">
                {t('search_history')}
              </Typography>
            </Link>
            <Link to={`${url}/bookmark`} onClick={() => handleActiveMenu('bookmark')}>
              <Typography color={activePage.bookmark} weight="semi-bold">
                {t('bookmark')}
              </Typography>
            </Link>
          </LibraryMenu>
        </DocsNav>

        <Content>
          <Switch>
            <Route exact path={path}>
              <LibraryPage
                handleRemove={handleRemoveMachine}
                handleClick={handleCategoryClick}
                machineId={machineId}
                isLoading={isLoading || isFetching}
                DocsNavItems={DocsNavItems}
                machineDescription={machineDesc}
                machineDescLoading={plantsLoading}
              />
            </Route>
            <Route path={`${path}/:page`}>
              <DocumentationPages
                machineDescLoading={plantsLoading}
                machineDescription={machineDesc}
              />
            </Route>
          </Switch>
        </Content>
      </Container>
      {selectedFilePreview && (
        <FilePreviewModal
          file={selectedFilePreview}
          setSelectedFilePreview={setSelectedFilePreview}
          type="fps"
        />
      )}
    </>
  );
};

export default DocsCenter;
