import { useAggregateDSIKPIsQuery } from 'api';
import { useLiveTimeFrame } from 'common/ui';

import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { MachineAccountInfoQueryParams } from 'types/protein';

/* sample return item {
  "name": "Last 1 Min",
  "value": 82,
  "unit": "%"
} */

/**
 * target value is based on last 30 minutes, to get % it's avg last 30 min / target * 100
 *
 * api path is `/v2/dsi/machine-health/<machine_id>/yield/aggregate`
 *
 *
 *
 * */

const GetData = (machineId: string, type: string) => {
  const { startTime } = useLiveTimeFrame(30000, {});

  const { data, isLoading, error, ...rest } = useAggregateDSIKPIsQuery({
    machineId,
    type,
    startTime
  });

  return { data, isLoading, error, ...rest };
};

export const useKPIApi = (
  type: string
): {
  groupedData?: Record<string, Record<string, unknown>[]>;
  data?: Record<string, unknown>[];
  kpiRows?: Record<string, unknown>[];
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  [key: string]: unknown;
} => {
  const { machineId } = useParams<MachineAccountInfoQueryParams>();

  const { data, isLoading, error } = GetData(machineId, type);

  // console.log(type, { timeFrame, data, ...rest });
  if (isLoading || error) return { isLoading, hasError: error ? true : false };

  if (!data) return { hasMessage: true };

  const avgLast30Mins = data?.find((item) => item.name === 'Average Last 30 mins');
  const target = data?.find((item) => item.name === 'target');

  const currentProgress = Number(avgLast30Mins?.value || 0);
  const targetProgress = Number(target?.value || 0);

  let progress = 0;

  if (targetProgress > 0 && currentProgress > 0)
    progress = Math.round((currentProgress / targetProgress) * 100);

  return { data, progress };
};
