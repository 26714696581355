import styled from 'styled-components';
import { StyledUiContainerProps } from 'components';

export const LineOverviewWidgetContainer = styled.div<StyledUiContainerProps>`
  .widget-ui {
    width: 100% !important;
  }

  .widget-ui__header {
    border-width: 1px 0px 0 0px;
    border-radius: 0;
    padding: 1.3em 0 0 0 !important;
  }

  .widget-ui__main {
    border-width: 0;
    padding: 0.5rem 0 !important;

    > .active-issues__row {
      border-bottom: 1px solid #c2c2c6 !important;
    }
  }

  .active-issues__row {
    padding-left: 0;
    padding-right: 0.2rem;
    padding-bottom: 0;
  }

  .active-issues__row--3 {
    display: flex;
    justify-content: right;

    p {
      color: #0076cc;
      cursor: pointer;
      font-size: 0.688rem;
      font-weight: 400;
      line-height: 1.125rem;
      margin: 0;
    }
  }
`;
