import styled from 'styled-components';
import theme from 'themes';

export const Nav = styled.div`
  display: inline-block;
  text-align: left;
  a {
    display: block;
    line-height: inherit;
    cursor: pointer;
    text-align: center;
  }
  ul {
    list-style: none;
    z-index: 12;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const NavMenu = styled.ul`
  line-height: 2.813rem;
  display: flex;
  align-items: start;
  font-size: 0.8125rem;
  .nav__menu-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    width: 10rem;
    span {
      &:hover {
        .nav__submenu {
          display: block;
        }
      }
    }
  }
  .icon {
    padding-left: 1rem;
  }
`;

export const NavSubMenu = styled.ul`
  flex-direction: column;
  list-style: none;
  position: absolute;
  width: max-content;
  top: 3rem;
  left: 0rem;
  background: #fff;
  padding: 0rem 1rem;
  box-shadow: 0rem 0.438rem 0.563rem 0rem #0000001a;
  border-radius: 0.313rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
`;

export const HeaderWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  height: 41px;
  border: 1px solid ${theme.colors.white};
  background-color: ${theme.colors.white};

  &:hover {
    background: ${theme.colors.lightGrey1};
    border-color: ${theme.colors.lightGrey1};
  }

  &:focus {
    background: ${theme.colors.lightGrey1};
    border-color: #0076cc;
    box-shadow: 0px 0px 4px 0px #0076cc30, 0px 0px 4px 0px #0076cc3d inset !important;
  }

  span:nth-child(2) {
    text-decoration: none;
    color: ${theme.colors.text.lightBlack};
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const LinkCell = styled.span`
  font-size: 14px;
`;

export const LinkColumn = styled.div`
  a {
    text-decoration: none;
    color: #303e47;
    display: flex;
    align-items: center;
    svg {
      padding-top: 7px;
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  padding-right: 8px;
  align-items: center;
`;
