// colors will be an object who's keys are the formatted string values of the group/category/state names as they appear in the interface.
/**
 *  example colors = {
 *    'production uptime': '#FF0000',
 *    idle: '#00FF00',
 *  }
 */

import styledTheme from 'common/theme';

const baseColors = {
  undefined: '#ffffff',
  'stopped by operator': '#00a6ff',
  'machine running': '#008200',
  'machine error': '#ffa500',
  'external error': styledTheme.colors.error200,
  'process error': '#b59410',
  offline: '#808080',
  'new undefined error': '#e63b1c',
  overfed: '#118DFF',
  'well fed': '#009EB3',
  underfed: '#C83D95',
  'very underfed': '#E66C37'
};
export const colors: Record<string, string> = {
  'Machine Running': '#008200',
  ...baseColors
};

export const chartColors = {
  red: '#B62C10'
};
