import React, { useState } from 'react';
import { StyledChartLegendPropsItem } from 'components/StyledUi/StyledChartsV2/ChartLegends';
import styled from 'styled-components';
import { styledTheme } from 'components';
import { useFleetMachineAccountData } from 'pages/FleetMachineDetail/hooks';
import { alarmsLegendsMapping } from 'pages/FleetMachineDetail/default_settings/avure_customer_default_settings';

interface ItemProps {
  color?: string;
  isSelected?: boolean;
}

const AlarmsLegendContainer = styled.div`
  &.alarms_legend {
    margin: 1rem 0;

    .alarms-table-legend {
      padding: 0;
      margin: 0;
    }

    li {
      margin-right: 1rem;
    }

    li:before {
      border-radius: 20px;
      width: 0.75em;
      height: 0.75em;
    }

    .alarms-table-legend {
      display: flex;
      flex-direction: row;
      list-style: none;

      button {
        cursor: pointer;
        position: relative;
        border: none;
        background: none;
        font-size: 0.75rem;
        font-weight: 700;
        padding-left: 0.35rem;
        text-transform: capitalize;
      }
    }
  }
`;

const LegendItem = styled.li<ItemProps>`
  position: relative;

  button {
    padding-left: 0.2rem;
  }

  &:before {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.3)};
    content: '';
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    background-color: ${({ color, isSelected }) => (!isSelected ? 'black' : color || 'black')};
    border-radius: ${styledTheme.radius.xs};
    transition: all 300ms;
  }
`;

interface AlarmsLegendProps {
  selected: { type: string[]; scope: string };
  handle: (type: string, value: string[]) => void;
}

export const AlarmsLegend = ({ selected, handle }: AlarmsLegendProps): JSX.Element => {
  const { machineType } = useFleetMachineAccountData();
  if (!machineType) return <></>;

  const [curSelected, setSelected] = useState<{ type: string[]; scope: string }>(selected);

  const handleSelect = (id?: string) => {
    if (!id) return;

    const newSel = selected?.type || [];

    //if add new to empty
    if (newSel.length === 0) {
      if (handle) handle('type', [id]);
      return setSelected({ ...curSelected, type: [id] });
    }

    if (newSel.length === 2) {
      if (!newSel?.includes(id)) {
        if (handle) handle('type', []);
        return setSelected({ ...curSelected, type: [] });
      }
    }

    //if click on already selected
    if (newSel?.includes(id)) {
      const filt = newSel.filter((item) => item !== id).filter((x) => x);
      if (handle) handle('type', filt);
      return setSelected({ ...curSelected, type: filt });
    }

    if (handle) handle('type', [...newSel, id]);
    return setSelected({ ...curSelected, type: [...newSel, id] });
  };

  const legendProps = {
    ...alarmsLegendsMapping[machineType],
    handle: handleSelect,
    selected: selected.type
  };

  // const legendProps = {
  //   items: [
  //     {
  //       label: 'A-Alarm (Critical)',
  //       id: 'Critical'
  //     },
  //     {
  //       label: 'B-Alarm (Product)',
  //       id: 'Product'
  //     },
  //     {
  //       label: 'C-Alarm (Warning)',
  //       id: 'Warning'
  //     }
  //   ],
  //   colors: {
  //     Product: 'rgba(0, 118, 204, 1)',
  //     Warning: 'rgba(226, 178, 8, 1)',
  //     Critical: 'rgba(182, 44, 16, 1)'
  //   },
  //   handle: handleSelect,
  //   selected: selected.type
  // };

  return (
    <AlarmsLegendContainer className="alarms_legend">
      <Legend {...legendProps} />
    </AlarmsLegendContainer>
  );
};

interface LegendProps {
  items: Record<string, unknown>[];
  selected: string[];
  colors: Record<string, unknown>;
  handle: (val: string) => void;
}

export const Legend = ({ items, selected, colors, handle }: LegendProps): JSX.Element => {
  if (!items) return <div className="error-wrapper">no items</div>;

  return (
    <ul className="alarms-table-legend">
      {items.map(({ label, id, handleClick, colorKey, color }: StyledChartLegendPropsItem) => {
        const isSelected = selected?.length == 0 ? true : selected?.includes(id);

        const handler = handleClick || handle;

        let itemSettings: Record<string, unknown> = {
          'data-muted': isSelected ? undefined : 'true',
          isSelected
        };

        const buttonSettings: Record<string, unknown> = {
          type: 'button',
          onClick: () => handler?.(id)
        };

        if (color) itemSettings = { ...itemSettings, color };
        else if (colors) itemSettings = { ...itemSettings, color: colors[colorKey || id] };

        return (
          <LegendItem key={label} {...itemSettings}>
            <button {...buttonSettings}>{label}</button>
          </LegendItem>
        );
      })}
    </ul>
  );
};
