// 3rd party libs
import React from 'react';
import theme from 'themes';

import { useAuth, useAuthB2C } from 'hooks';
import { Button, Typography } from 'components';
const b2cflag: boolean = process.env.REACT_APP_ENABLE_B2C == 'true';
import accessDeniedIcon from 'icons/accessDenied.svg';
import { useTranslation } from 'react-i18next';
import {
  AccessContentDiv,
  CardContainer,
  Container,
  ImgBox,
  InnerContent
} from './unauthorized.element';

const AccessDenied = (): JSX.Element => {
  const auth = useAuth();
  const authB2C = useAuthB2C();
  const { t } = useTranslation(['common']);

  const handleLogout = () => {
    b2cflag ? authB2C.signout() : auth.signout();
  };
  return (
    <>
      <Container>
        <CardContainer>
          <InnerContent>
            <ImgBox src={accessDeniedIcon} alt="Access-denied" />
            <AccessContentDiv>
              <Typography mb={'2rem'} size="4rem" weight="thin" color={theme.colors.primaryBlue5}>
                {t('access_denied')}
              </Typography>
              <Typography mb={'3rem'} size="2rem" weight="medium" color={theme.colors.primaryBlue5}>
                {t('access_denied_message')}
              </Typography>
              <Button
                variant="hover-blue"
                borderRadius="3rem"
                width="10rem"
                onClick={() => {
                  handleLogout();
                }}
              >
                Close
              </Button>
            </AccessContentDiv>
          </InnerContent>
        </CardContainer>
      </Container>
    </>
  );
};

export default AccessDenied;
