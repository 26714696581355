import React, { useState, useEffect, useRef, useMemo } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconSearch } from 'icons/IconSearch';
import { StyledTableInput } from 'components/machine-health/configurator/WidgetTable/Form';
import { ChangeEvent } from 'types';
import {
  DropdownContainer,
  IconWrapper,
  List,
  OptionItem,
  OptionItemContainer,
  PopupContainer,
  SearchBarWrapper,
  SelectedOption,
  StyledSearch
} from './DropdownOption.element';
import theme from 'themes';

interface CustomDropdownProps {
  options?: OptionType[];
  icon?: string;
  handleOptionSelect?: (option?: OptionType) => void;
  searchable?: boolean;
  searchInputValue?: (input?: string) => void;
  selectedOpt?: string | JSX.Element;
  label?: JSX.Element;
  iconColor?: string;
  containerPosition?: string;
  popupTop?: string;
  width?: string;
}

export interface OptionType {
  value: string;
  label: string;
  icon?: JSX.Element;
}

const DropdownOption: React.FC<CustomDropdownProps> = ({
  options,
  icon,
  handleOptionSelect,
  searchable,
  searchInputValue,
  selectedOpt,
  label,
  iconColor,
  popupTop,
  width,
  containerPosition
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [filterOption, setFilterOption] = useState(options);

  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown container

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchOption = (e: ChangeEvent) => {
    const value = e.target.value;
    setSearchInput(value);
    const filteredList = options?.filter(
      (option) =>
        option.label.toLowerCase().includes(value.toLowerCase()) ||
        option.value.toLowerCase().includes(value.toLowerCase())
    );
    setFilterOption(filteredList);
    searchInputValue?.(value);
  };

  useMemo(() => {
    setFilterOption(options);
  }, [options]);

  // Effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} containerPosition={containerPosition}>
      <div style={{ cursor: 'pointer' }} onClick={toggleDropdown}>
        {label}
      </div>
      {selectedOpt && <SelectedOption>{selectedOpt}</SelectedOption>}
      {icon ? (
        <img src={icon} onClick={toggleDropdown} />
      ) : isOpen ? (
        <FontAwesomeIcon
          icon={faChevronUp}
          style={{ color: iconColor || theme.colors.black, cursor: 'pointer' }}
          onClick={toggleDropdown}
        />
      ) : (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ color: iconColor || theme.colors.black, cursor: 'pointer' }}
          onClick={toggleDropdown}
        />
      )}
      {isOpen && (
        <PopupContainer width={width} top={popupTop || '1rem'}>
          {searchable && (
            <StyledSearch>
              <SearchBarWrapper>
                <IconWrapper>
                  <IconSearch width="15" />
                </IconWrapper>
                <StyledTableInput
                  type="text"
                  value={searchInput}
                  onChange={handleSearchOption}
                  placeholder={'Search'}
                  style={{
                    paddingLeft: '2rem'
                  }}
                  borderVariant={'none'}
                  bgColor="#F2F3F4"
                />
              </SearchBarWrapper>
            </StyledSearch>
          )}
          <List>
            {filterOption?.map((option) => (
              <OptionItemContainer>
                {option?.icon}
                <OptionItem
                  key={option.value}
                  onClick={() => {
                    setIsOpen(false);
                    handleOptionSelect && handleOptionSelect(option);
                  }}
                  padding="0"
                >
                  {option?.label}
                </OptionItem>
              </OptionItemContainer>
            ))}
          </List>
        </PopupContainer>
      )}
    </DropdownContainer>
  );
};

export default DropdownOption;
