import React from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import InputLabel from 'components/InputLabel';
import { TooltipWrapper } from 'components/StyledUi';
import { IcoRequiredInfo } from 'icons/IcoRequiredInfo';
import { themeColors } from 'themes';
import { useTranslation } from 'react-i18next';
import { IcoMultiple } from 'icons/IcoMultiple';
import { IcoMinus } from 'icons/IcoMinus';
import { IcoExpressionPlus } from 'icons/IcoExpressionPlus';
import { IcoDivide } from 'icons/IcoDivide';
import {
  ExpressionWrapper,
  HeadingWrapper,
  LabelWrapper,
  MentionWrapper,
  OperationIcons,
  OperationIconsWrapper,
  TagDiv,
  TagNameDiv,
  TagNameWrapper
} from './index.elements';
import { IcoBracketRightIcon } from 'icons/IcoBracketRightIcon';
import { IcoBracketLeftIcon } from 'icons/IcoBracketLeftIcon';

type TMentionTextArea = {
  expressionOptions: {
    id: string;
    display: string;
  }[];
  label: string;
  tagName: string;
  placeholder: string;
};

const MentionTextArea = ({
  expressionOptions,
  label,
  tagName,
  placeholder
}: TMentionTextArea): React.ReactElement => {
  const [value, setValue] = React.useState('');
  const { t } = useTranslation(['mh']);
  const filteringTagOnQuery = (query: string, callback: (data: SuggestionDataItem[]) => void) => {
    if (!query) return callback(expressionOptions ? expressionOptions : []);

    setTimeout(() => {
      const filteredUsers = expressionOptions.filter((user) =>
        user.display.toLowerCase().includes(query.toLowerCase())
      );
      callback(filteredUsers ? filteredUsers : []);
    }, 10);
  };
  return (
    <>
      <ExpressionWrapper>
        <HeadingWrapper>
          <LabelWrapper>
            <InputLabel mandatory>{`${t(label)}`}</InputLabel>
            <TooltipWrapper Tooltip={`${t(placeholder)}`}>
              <IcoRequiredInfo width="30" color={themeColors.primaryBlue5} />
            </TooltipWrapper>
          </LabelWrapper>
        </HeadingWrapper>
        <TagNameWrapper isDisabled={expressionOptions.length === 0}>
          <TagNameDiv>{tagName} </TagNameDiv>
          <OperationIconsWrapper>
            <OperationIcons onClick={() => alert('IcoExpressionPlus')}>
              <IcoExpressionPlus width="20" />
            </OperationIcons>
            <OperationIcons onClick={() => alert('IcoMinus')}>
              <IcoMinus width="20" />
            </OperationIcons>
            <OperationIcons onClick={() => alert('IcoMultiple')}>
              <IcoMultiple width="20" />
            </OperationIcons>
            <OperationIcons onClick={() => alert('IcoDivide')}>
              <IcoDivide width="20" />
            </OperationIcons>
            <OperationIcons onClick={() => alert('IcoBracketLeftIcon')}>
              <IcoBracketLeftIcon width="20" />
            </OperationIcons>
            <OperationIcons onClick={() => alert('IcoBracketRightIcon')}>
              <IcoBracketRightIcon width="20" />
            </OperationIcons>
          </OperationIconsWrapper>
        </TagNameWrapper>

        <MentionWrapper>
          <MentionsInput
            className="mentions"
            id="logicTextArea"
            value={value}
            onChange={(_event: { target: { value: string } }, _newValue: string) => {
              setValue(_newValue);
            }}
            disabled={expressionOptions.length === 0}
            placeholder={expressionOptions.length === 0 ? `No Tags found!!` : placeholder}
          >
            <Mention
              trigger={'@'}
              data={filteringTagOnQuery}
              markup="[__display__]"
              appendSpaceOnAdd
              renderSuggestion={(suggestion, _, highlightedDisplay) => {
                return (
                  <TagDiv>
                    <span>{highlightedDisplay}</span>
                    <b>({suggestion.id})</b>
                  </TagDiv>
                );
              }}
              displayTransform={(id: string, display: string) => {
                const TagId = expressionOptions.find((tag) => tag.display === display)?.id;
                return `${display} (${TagId})`;
              }}
            />
          </MentionsInput>
        </MentionWrapper>
      </ExpressionWrapper>
    </>
  );
};
export default MentionTextArea;
