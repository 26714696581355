import styled from 'styled-components';

export const LineCardContainerHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LineCardContainerParent = styled.div`
  padding: 1.5rem;
  padding-top: 0;
  border: 0.063rem solid #c8c8c8;
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
`;

export const LineContainerTitle = styled.p`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
  text-align: left;
`;
