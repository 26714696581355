import React from 'react';

export const Cleaning = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6349_196588)">
        <path
          d="M5.6001 16.7607H22.4001V5.64575H5.6001V25.4056"
          stroke="#0076CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_6349_196588)">
          <path
            d="M9.98779 9.31712C10.0662 9.32646 10.1465 9.33252 10.2277 9.33346C10.5963 9.33766 10.9459 9.25039 11.161 9.10012C11.3761 8.95079 11.7257 8.86352 12.0943 8.86679C12.463 8.86352 12.8125 8.95079 13.0277 9.10012C13.2428 9.25039 13.5923 9.33766 13.961 9.33346C14.3297 9.33766 14.6792 9.25039 14.8943 9.10012C15.1095 8.95079 15.459 8.86352 15.8277 8.86679C16.1963 8.86352 16.5459 8.95079 16.761 9.10012C16.9761 9.25039 17.3257 9.33766 17.6943 9.33346C17.7737 9.33346 17.8525 9.32692 17.9291 9.31759"
            stroke="#0076CC"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.76099 7.93335L10.5641 12.7536C10.6005 12.9715 10.7129 13.1695 10.8815 13.3123C11.0501 13.455 11.2639 13.5334 11.4849 13.5334H16.4371C16.6581 13.5334 16.8718 13.455 17.0404 13.3123C17.209 13.1695 17.3215 12.9715 17.3579 12.7536L18.161 7.93335"
            stroke="#0076CC"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6943 14.9333H17.2277"
            stroke="#0076CC"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6349_196588">
          <rect width="28" height="28.2283" fill="white" />
        </clipPath>
        <clipPath id="clip1_6349_196588">
          <rect width="11.2" height="11.2" fill="white" transform="translate(8.36108 5.6001)" />
        </clipPath>
      </defs>
    </svg>
  );
};
