import { addTimeZoneToUTCTimeStamp } from 'components/StyledUi/js';
import { useMachineIssues } from 'hooks/useMachineAlarmsAlerts';
import { usePressurizationDelta } from 'hooks/usePressurizationDeltaProvider';
import { AlertData, GetDataProps, PressureDeltaData } from '../types';
import { ALERTSFILTER } from '../types/constants';
import { format } from 'date-fns';

export const getData = (): GetDataProps => {
  const { data: pressurizationData, isLoading, hasError: isError } = usePressurizationDelta();
  const {
    alerts: alertData,
    isLoading: alarmsLoading,
    hasError: alertsHasError
  } = useMachineIssues();

  const alerts = alertData as unknown as AlertData[];

  const loading = isLoading || alarmsLoading;

  if (loading) return { isLoading: true, allData: {}, hasError: false };

  return {
    allData: { pressurizationData, alertData: alerts },
    isLoading: isLoading || alarmsLoading,
    hasError: isError || alertsHasError
  };
};

export const formatChartData = (
  data: PressureDeltaData[],
  timezone: string
): PressureDeltaData[] => {
  // Format Chart Data
  const processedChartData = processChartData(data, timezone);
  const filtered = filteroutFailedBatches(processedChartData);

  // Sort Chart Data
  const result = filtered.sort(
    (a, b) =>
      (new Date(a.startTime) as unknown as number) - (new Date(b.startTime) as unknown as number)
  ) as PressureDeltaData[];

  return result;
};

export const processChartData = (
  data: PressureDeltaData[],
  timezone: string
): PressureDeltaData[] => {
  return data.map((item) => {
    //Initial time is in UTC, we converting them to the machine time
    const machineTime = addTimeZoneToUTCTimeStamp(item.startTime, timezone);
    return { ...item, timestamp: new Date(machineTime) };
  });
};

const isFailed = (item: PressureDeltaData) => item.batchSuccessful;
export const filteroutFailedBatches = (data: PressureDeltaData[]): PressureDeltaData[] =>
  data.filter(isFailed);

// This function takes API response and sorts it by SKId and then by Intensifier
export const parseAlarmData = (
  data: AlertData[],
  timezone: string
): { dataAlarms: AlertData[]; dataAlarmsByFilterKey: Record<string, unknown> } => {
  const dataAlarms: AlertData[] = [];
  const dataAlarmsByFilterKey = {} as Record<string, unknown>;

  data.map((item) => {
    for (const [key, value] of Object.entries(item)) {
      if (key === 'description' || key === 'detailedInstructions') {
        const hasFilter = Object.values(ALERTSFILTER).filter((item) => value.includes(item));

        if (!hasFilter || hasFilter.length === 0) continue;

        if (!Object.prototype.hasOwnProperty.call(dataAlarmsByFilterKey, hasFilter)) {
          dataAlarmsByFilterKey[hasFilter] = [];
        }

        //Initial time is in UTC, we converting them to the machine time
        let machineTime = item.createdAt;
        //if (timezone) machineTime = formatTimeStampWithTz(item.createdAt, timezone);
        if (timezone) machineTime = format(new Date(item.createdAt), 'MMMM d, y - hh:mm:ss a');

        dataAlarmsByFilterKey[hasFilter].push({
          ...item,
          machineTime: machineTime,
          timestamp: new Date(item.createdAt)
        });

        dataAlarms.push({
          ...item,
          machineTime: machineTime,
          timestamp: new Date(item.createdAt)
        });
      }
    }
  });

  return { dataAlarms, dataAlarmsByFilterKey };
};
