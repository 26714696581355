import React, { ReactNode } from 'react';
import { IcoFilter } from 'icons/IcoFilter';
import { FilterButtonContainer } from './FilterButton.elements';
import { useTranslation } from 'react-i18next';

export interface FilterButtonProps {
  handleClick?: () => void;
  color?: string;
  children?: ReactNode;
  hideText?: boolean;
}

export const FilterButton = ({
  handleClick,
  color,
  children,
  hideText
}: FilterButtonProps): JSX.Element => {
  const { t } = useTranslation(['mh']);

  let label: ReactNode = hideText ? undefined : children || 'Sort by';
  let className = 'filter-button';

  if (typeof label === 'string') label = t(label);
  if (label) className += ' has-label';

  return (
    <FilterButtonContainer onClick={handleClick} {...{ className }}>
      {label && <span className="filter-button-label">{label}</span>}
      <span className="icon-holder">
        <IcoFilter color={color} />
      </span>
    </FilterButtonContainer>
  );
};
