import React from 'react';
import { BarProps } from './types';
import { BarContainer, DD, DL } from './elements';
import { secondsToHms } from './utils';
import { actualDurationColors } from '../../../../settings/colors';

export const Bar = ({ item }: BarProps): JSX.Element => {
  const { barTitle, percentage, icon, color, numberOcuurences, duration, extraData } = item;
  const extraValue = secondsToHms(extraData, true);
  const extraValueColor = {
    color:
      extraData && extraData > 0
        ? `${actualDurationColors.positiveValue}`
        : `${actualDurationColors.negativeValue}`
  };

  return (
    <BarContainer className="bar-indicator--bars" style={{ height: '50px' }}>
      <img src={`${icon}`} width="25px" height="25px" className="bar-indicator--icon" />
      <div className="bar-inner">
        <div className="bar-indicator-wrap">
          <p className="bar-indicator--name">
            {barTitle && barTitle}{' '}
            <small>({(duration && secondsToHms(duration, false)) || 0})</small>
          </p>
          <p className="bar-indicator--extra-data" style={extraValueColor}>
            {extraValue}
          </p>
        </div>
        <DL color={color || '#000000'}>
          {numberOcuurences && (
            <DD className={`percentage percentage-${percentage}`}>
              <span className="text"> {numberOcuurences && numberOcuurences} </span>
            </DD>
          )}
          <DD className={`percentage percentage-${percentage}`}></DD>
        </DL>
      </div>
    </BarContainer>
  );
};
