import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';
import { Loader, Typography } from 'components';
import FileItem from './FileItem';
import { CustomFile, FileType } from 'types/machine-management';
import {
  DropArea,
  FileContainer,
  InlineLink,
  LoaderContainer,
  ProcessingContainer,
  ProcessingLabel,
  VContainer
} from './File.element';
import CustomLoader from 'components/CustomLoader';
import FilePreviewModal from './FilePreviewModal';
import { useTranslation } from 'react-i18next';

type Style = {
  fileContainerHeight: string;
  isBorder?: boolean;
};
interface OnboardingDropAreaProps {
  acceptedTypes: Accept;
  files?: File[];
  onFileChange: (file?: File[]) => void;
  icon?: IconDefinition;
  isUploading?: boolean;
  handleFileDelete?: (file: File | FileType) => void;
  filesWithUrl?: FileType[];
  isLoading?: boolean;
  maxFileSize?: number;
  acceptedFileText?: string;
  isFileUploading?: boolean;
  styles?: Style;
  repoType?: 'fps' | 'dg' | 'on'; // this key define the BE repo service
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isFile<T>(obj: T) {
  return obj instanceof File;
}
export default function FileUploadArea({
  acceptedTypes,
  files,
  onFileChange,
  isUploading,
  handleFileDelete,
  filesWithUrl,
  isLoading,
  maxFileSize,
  repoType = 'on',
  acceptedFileText,
  isFileUploading,
  styles
}: OnboardingDropAreaProps): JSX.Element {
  const theme = useTheme();
  const style = {
    ...styles
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileChange(acceptedFiles);
  }, []);

  const [selectedFilePreview, setSelectedFilePreview] = useState<CustomFile>();
  const { t } = useTranslation(['fpns']);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    onDrop: onDrop,
    noClick: true,
    accept: acceptedTypes,
    maxFiles: 100,
    maxSize: maxFileSize
  });

  return (
    <VContainer>
      {isUploading ? (
        <ProcessingContainer>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
          <ProcessingLabel>Processing...</ProcessingLabel>
        </ProcessingContainer>
      ) : (
        <DropArea {...getRootProps()} isDragAccept={isDragAccept} theme={theme}>
          <input multiple {...getInputProps()} />
          <VContainer>
            <Typography mb={'.5rem'} color={theme.colors.mediumGrey3}>
              <InlineLink onClick={open}>Click </InlineLink> or drag file here
            </Typography>
            <Typography color={theme.colors.mediumGrey2} mb={0}>
              {acceptedFileText
                ? acceptedFileText
                : '.xls, .doc, .pdf, .jpg, .png. File size (100 MB per file max) & max upload 100 files.'}
            </Typography>
          </VContainer>
        </DropArea>
      )}
      {isLoading && (
        <LoaderContainer>
          <CustomLoader size="3rem" thickness=".3rem" />
        </LoaderContainer>
      )}
      {filesWithUrl?.length && !isLoading ? (
        <FileContainer hasBorder={style.isBorder} fileContainerheight={style.fileContainerHeight}>
          {isFileUploading && (
            <Typography className="progress-text" mb={0}>
              {t('upload_in_progress')}...
            </Typography>
          )}
          {files &&
            !isLoading &&
            files.map((file) => {
              return (
                <FileItem
                  key={file.lastModified}
                  handleFileDelete={() => handleFileDelete?.(file)}
                  file={file}
                />
              );
            })}
          {filesWithUrl &&
            !isLoading &&
            filesWithUrl?.map((file) => {
              return (
                <FileItem
                  progress={file?.progress || 0}
                  handleFileDelete={() => handleFileDelete?.(file)}
                  file={file.file}
                  handleFilePreview={() => setSelectedFilePreview(file.file)}
                />
              );
            })}

          {selectedFilePreview && (
            <FilePreviewModal
              file={selectedFilePreview}
              setSelectedFilePreview={setSelectedFilePreview}
              type={repoType}
            />
          )}
        </FileContainer>
      ) : null}
    </VContainer>
  );
}
