import React from 'react';
import { PieChartWidget } from 'common/components';

import { AveragePressurizationTimeChartTooltip } from './AveragePressurizationTimeWidget.elements';
import { FilterSelectedProvider, StyledUiContainerProps, useFilterSelected } from 'components';
import { useMachinePressurization } from 'pages/FleetMachineDetail/hooks';
import { useTranslation } from 'react-i18next';

const TooltipComponent = ({ label, value }: Record<string, unknown>): JSX.Element => {
  const { t } = useTranslation(['mh']);

  return (
    <AveragePressurizationTimeChartTooltip>
      <div className="pie-chart-tooltip__label">{t(String(label))}</div>
      <div className="pie-chart-tooltip__value">
        {Math.round(value as number)} <span className="small-text">{t('seconds')}</span>
      </div>
    </AveragePressurizationTimeChartTooltip>
  );
};

export const AveragePressurizationTimeWidget = (props: StyledUiContainerProps): JSX.Element => {
  return (
    <FilterSelectedProvider>
      <Provided {...props} />
    </FilterSelectedProvider>
  );
};

const chartColors = {
  'Average Excess Pressure Time': '#C83D95',
  'Average Target Time': '#0076CC'
};

const Provided = ({ gridArea }: StyledUiContainerProps): JSX.Element => {
  const api = useMachinePressurization();
  const { data, isLoading, hasMessage, hasError } = api;
  const [selected, handle] = useFilterSelected();

  const pieChartData = [
    {
      label: 'Average Excess Pressure Time',
      value: data?.avgDeltaPressurizeTime,
      color: chartColors['Average Excess Pressure Time']
    },
    {
      label: 'Average Target Time',
      value: data?.avgIdealPressurizeTime,
      color: chartColors['Average Target Time']
    }
  ];

  // chartData = chartData.slice().sort((a, b) => b.count - a.count);
  const handleClick = (slice: Record<string, unknown>) => {
    const isSelected = selected?.label?.includes(slice.label as string) ? true : false;
    return handle(isSelected ? 'toggle' : 'set', { label: slice.label as string });
  };

  // the function that helps determins if the slice is selected
  const checkIfSelected = (slice: Record<string, unknown>) => {
    const label = slice.label as string;
    const selectedVals = selected?.label || [];
    if (selectedVals.includes(label) || !selectedVals.length) return true;
    return false;
  };

  // the value of the preformatted middle text
  //const currentSelected = selected?.label[0] || 'Mainten';
  //const currentItem = undefined; //data?.find((x) => x.label === currentSelected);
  //console.log({ currentItem, currentSelected })
  // set WidgetUi settings - this is the settings for the widget
  const widgetUiSettings = {
    gridArea,
    isLoading,
    hasMessage,
    hasError,
    title: 'average pressurization time',
    className: 'average-pressurization-time-widget',
    hasDateFooter: true
  };

  const pieChartSettings = {
    type: 'doughnut',
    // passing it the inner part of the tooltip.
    // the container stiles will already be applied
    TooltipComponent
  };

  const centerCellValues = {
    label: `Avg Delta`,
    value: `${Math.round(data?.avgDeltaPressurizeTime as number)} sec`
  };

  if (selected) {
    const cur = selected?.label[0];

    pieChartData?.forEach((x) => {
      if (x.label === cur) {
        centerCellValues.label = x.label;
        centerCellValues.value = `${Math.round(x.value as number)} sec`;
      }
    });
  }

  const pieChartWidgetSettings = {
    widgetUiSettings,
    pieChartSettings,
    handleClick,
    checkIfSelected,
    selected,
    data: pieChartData,
    centerCellValues,
    disableLegend: true,
    bottomCellValues: [
      {
        label: `Average Actual Time`,
        value: `${Math.round(data?.avgPressurizeTime as number)} sec`
      },
      {
        label: `Average Target Time`,
        value: `${Math.round(data?.avgIdealPressurizeTime as number)} sec`
      }
    ],
    format: {
      legendItem: (props: Record<string, unknown>) => {
        const label = props?.label as string;

        return <>{label}</>;
      }
    }
  };
  return <PieChartWidget {...pieChartWidgetSettings} />;
};
