import React from 'react';

export const IcoFavourite = ({ color }: { color?: string }): JSX.Element => {
  color = color || '#ffffff';
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {' '}
      <g clipPath="url(#clip0_2970_14229)">
        {' '}
        <rect width="16" height="16" transform="translate(0 0.5)" fill="white" />{' '}
        <path
          d="M7.04895 3.42664C7.34833 2.50538 8.65167 2.50538 8.95104 3.42664L9.57175 5.33672C9.70563 5.74872 10.0896 6.02766 10.5228 6.02767L12.5312 6.02775C13.4998 6.02778 13.9026 7.26733 13.1189 7.83674L11.4942 9.01732C11.1437 9.27196 10.997 9.72329 11.1309 10.1353L11.7515 12.0454C12.0508 12.9667 10.9963 13.7328 10.2126 13.1634L8.58775 11.983C8.23728 11.7284 7.76272 11.7284 7.41225 11.983L5.78736 13.1634C5.00366 13.7328 3.94924 12.9667 4.24854 12.0454L4.8691 10.1353C5.00295 9.72329 4.8563 9.27196 4.50585 9.01732L2.88106 7.83674C2.0974 7.26733 2.50015 6.02778 3.46884 6.02775L5.47724 6.02767C5.91044 6.02766 6.29437 5.74872 6.42825 5.33672L7.04895 3.42664Z"
          fill={color}
          stroke="#0076CC"
        />{' '}
      </g>{' '}
      <defs>
        {' '}
        <clipPath id="clip0_2970_14229">
          {' '}
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />{' '}
        </clipPath>{' '}
      </defs>{' '}
    </svg>
  );
};
