import React, { useMemo } from 'react';
import { ViewContainer } from './View.elements';
import ChartsOverTimeContainer from './ChartsOverTimeContainer';
import { ProgressTileWidget } from '../Overview/ProgressTileWidget';
import { BeltSpeedWidget } from './BeltSpeedWidget/BeltSpeedWidget';
import { LoadingWidget } from './LoadingWidget/LoadingWidget';
import { ProductionValuesV1 } from './ProductValuesWidget/ProductValuesWidget';

export const ProductionView = (): JSX.Element => {
  const cachedProdTiles = useMemo(() => <ProductionValuesV1 />, [ProductionValuesV1]);

  return (
    <ViewContainer className="view-container view-container--overview">
      <div className="product-row">{cachedProdTiles}</div>
      <BeltSpeedWidget />
      <LoadingWidget />
      <ProgressTileWidget />

      <div className="charts-over-time-row">
        <ChartsOverTimeContainer />
      </div>
    </ViewContainer>
  );
};
