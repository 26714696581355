import React from 'react';
import { NewBaseTable } from 'components';
import { ColumnConfig, SortState } from 'components/NewBaseTable/NewBaseTable';
import { Row } from '@tanstack/react-table';
import { TableColumnConfigs } from 'types';
import { useChartsAndFiltersPageData } from '../../_hooks/useChartsAndFiltersPageData';
import { CSSProperties } from 'styled-components';

interface TableUiProps {
  columnsConfig: ColumnConfig[];
  sortState: SortState;
  getRowStyles?: (row: Row<TableColumnConfigs>) => CSSProperties;
}

export const TableUi = ({ columnsConfig, sortState, getRowStyles }: TableUiProps): JSX.Element => {
  const { data, isLoading } = useChartsAndFiltersPageData();
  const newTableData = data && !isLoading ? data : [];

  return (
    <NewBaseTable
      newTableData={newTableData}
      columnConfigs={columnsConfig}
      tdMarginLeft="0.625rem"
      textAlign="left"
      sortState={sortState}
      isShowColumnOptions={true}
      isDataLoading={isLoading}
      isShowGlobalSearch={true}
      showTableCoverRule={true}
      showTableHeaderRule={true}
      customClassName="alarmsTable"
      disableVirtualize={false}
      hideTotalFetchCount={false}
      maxTableHeight={35.7}
      getRowStyles={getRowStyles ? getRowStyles : undefined}
    />
  );
};
