import styled from 'styled-components';

export const SwaggerWrapper = styled.div`
  height: 72vh;
`;
export const ServiceSelectorWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: 24px;
  padding: 12px 10px 10px 0px;
`;
export const NoServiceSelectedWrapper = styled.div`
  height: 62vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
