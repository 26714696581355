// 3rd party
import React, { ReactElement, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

// Components
import { Button, Input, Modal, TextInputWithOptions, Typography } from 'components';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// Types
import { Bubble, ChangeEvent } from 'types';
import { Part } from 'types/parts';
import { EditedBubbleRecord } from 'types/machine-management';
import { MandatoryIndicator } from 'pages/UserManagementTwo/components/Group/AddEditGroup';
import { useTranslation } from 'react-i18next';

interface EditBubbleModalProps {
  visible: boolean;
  handleClose: () => void;
  handleSubmit: (bubble: Bubble, partSku: string, action?: string) => void;
  availableSkus?: Set<string>;
  selectedBubble?: Bubble;
  selectedPart?: Part;
  bubbleEdit?: EditedBubbleRecord;
}

const EditBubbleContainer = styled.div`
  padding: 1.25rem;
  height: auto;
  width: 100%;
`;

const EditBubbleHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
`;

const EditBubbleBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditBubbleMessageContainer = styled.div`
  width: 100%;

  & > input {
    margin-bottom: 1.25rem;
  }
`;

const EditBubbleMessageContent = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: 0.75rem 0;
`;

const BubbleInput = styled(Input)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.tableborder};
  border-radius: 0.35rem;
`;

const BubbleMessageActions = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

const BubbleMessageAction = styled.div<{ btnPadding?: string }>`
  width: 8.375rem;
  height: 2.5rem;
  button {
    padding ${({ btnPadding }) => btnPadding || 'auto'}
  }
`;

const EditContainer = styled.div`
  width: 100%;
  height: 3.25rem;
  padding: 0.5rem 1rem;
  border: 0.0625rem solid #d0d5dd;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
`;

const EditIcon = styled.div`
  margin-right: 0.875rem;
`;

const EditContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const EditLabel = styled.div`
  flex-grow: 1;
  margin-right: 0.875rem;
`;

const EditAction = styled.div`
  flex-shrink: 0;
`;
const Label = styled.div`
  display: flex;
`;

const EditBubbleModal = ({
  visible,
  handleClose,
  handleSubmit,
  availableSkus,
  selectedBubble,
  selectedPart,
  bubbleEdit
}: EditBubbleModalProps): ReactElement => {
  // Context helper variables
  const isBubblePreviouslyAddedByUser =
    selectedBubble && !selectedBubble.id.includes('edit-') ? false : true;

  const isNewBubble = isBubblePreviouslyAddedByUser
    ? true
    : selectedBubble && !selectedBubble.index;
  const theme = useTheme();
  const defaultRadius = isNewBubble
    ? selectedBubble?.avgRadius || 40
    : selectedBubble?.radius || 40;
  const { t } = useTranslation(['fpns']);
  const [bubbleLabel, setBubbleLabel] = useState<string>('');
  const [partSku, setPartSku] = useState<string>('');
  const [bubbleRadius, setBubbleRadius] = useState<number>(defaultRadius);

  const handleCloseIntercept = () => {
    setBubbleLabel('');
    setPartSku('');
    setBubbleRadius(defaultRadius);
    handleClose();
  };

  const handleSubmitIntercept = (action?: string) => {
    if (selectedBubble)
      handleSubmit(
        {
          ...selectedBubble,
          id: isBubblePreviouslyAddedByUser ? '' : selectedBubble.id,
          index: bubbleLabel,
          radius: bubbleRadius ? bubbleRadius : selectedBubble.radius
        },
        partSku,
        action
      );
    handleCloseIntercept();
  };

  useEffect(() => {
    if (selectedPart?.sku && !isBubblePreviouslyAddedByUser) {
      setPartSku(selectedPart?.sku);
    } else {
      setPartSku('');
    }
  }, [selectedPart]);

  useEffect(() => {
    if (selectedBubble && !isBubblePreviouslyAddedByUser) {
      setBubbleLabel(selectedBubble.index);
    } else {
      setBubbleLabel('');
    }
  }, [selectedBubble]);

  useEffect(() => {
    if (isNewBubble) {
      setBubbleRadius(selectedBubble?.avgRadius || 40);
    } else if (selectedBubble && selectedBubble.radius > 0) {
      setBubbleRadius(selectedBubble.radius);
    } else {
      setBubbleRadius(40);
    }
  }, [selectedBubble]);

  return (
    <Modal
      visible={visible}
      size="xsmall_auto_height"
      onClose={handleClose}
      widthOverride="25rem"
      allowContentScroll={false}
    >
      <EditBubbleContainer>
        <EditBubbleHeader>
          {isNewBubble ? t('add_missing_bubble') : t('edit_bubble')}
        </EditBubbleHeader>
        <EditBubbleBody>
          <EditBubbleMessageContainer>
            <EditBubbleMessageContent>
              {`${isNewBubble ? t('enter') : t('edit')} ${t('name_and_part_number')}${
                isNewBubble ? ` ${t('add_bubble_to_diagram')}` : ''
              }:`}
            </EditBubbleMessageContent>
            {bubbleEdit &&
              bubbleEdit.newIndex &&
              (isNewBubble || selectedBubble?.index !== bubbleEdit.newIndex) && (
                <>
                  <EditContainer>
                    <EditIcon>
                      <FontAwesomeIcon icon={faExclamationCircle} fontSize="1rem" />
                    </EditIcon>
                    <EditContent>
                      <EditLabel>
                        <EditBubbleMessageContent>
                          {t('previous_label')} <b>{bubbleEdit.newIndex}</b>
                        </EditBubbleMessageContent>
                      </EditLabel>
                      <EditAction>
                        <Button
                          variant="inline-link"
                          onClick={() => {
                            if (bubbleEdit.newIndex) setBubbleLabel(bubbleEdit.newIndex);
                          }}
                        >
                          {t('use_last_edit')}
                        </Button>
                      </EditAction>
                    </EditContent>
                  </EditContainer>
                </>
              )}

            <Label>
              <Typography mb={0} weight="semi-bold">
                {t('bubble_number')}
              </Typography>
              <MandatoryIndicator>*</MandatoryIndicator>
            </Label>
            <BubbleInput
              variant="white"
              placeholder="Bubble label"
              width="100%"
              value={bubbleLabel}
              onChange={(event: ChangeEvent) => {
                const updatedBubbleLabel = event.target.value as string;
                setBubbleLabel(updatedBubbleLabel);
              }}
            />
            {bubbleEdit &&
              bubbleEdit.newSku &&
              (isNewBubble || selectedPart?.sku !== bubbleEdit.newSku) && (
                <>
                  <EditContainer>
                    <EditIcon>
                      <FontAwesomeIcon icon={faExclamationCircle} fontSize="1rem" />
                    </EditIcon>
                    <EditContent>
                      <EditLabel>
                        <EditBubbleMessageContent>
                          {t('previous_part_number')} <b>{bubbleEdit.newSku}</b>
                        </EditBubbleMessageContent>
                      </EditLabel>
                      <EditAction>
                        <Button
                          variant="inline-link"
                          onClick={() => {
                            if (bubbleEdit.newSku) setPartSku(bubbleEdit.newSku);
                          }}
                        >
                          {t('use_last_edit')}
                        </Button>
                      </EditAction>
                    </EditContent>
                  </EditContainer>
                </>
              )}
            <Label>
              <Typography mb={0} weight="semi-bold">
                {t('sku_part_number')}
              </Typography>
              <MandatoryIndicator>*</MandatoryIndicator>
            </Label>
            <TextInputWithOptions
              elementId="edit-bubble-sku-input"
              value={partSku ? partSku : ''}
              onValueChange={(newValue) => {
                if (newValue) setPartSku(newValue);
                else setPartSku('');
              }}
              placeholder="Part number"
              options={availableSkus ? Array.from(availableSkus) : []}
            />

            {/* RADIUS INPUT */}
            <Label>
              <Typography mb={0} weight="semi-bold">
                {t('radius_of_bubble')}
              </Typography>
              <MandatoryIndicator>*</MandatoryIndicator>
            </Label>
            <BubbleInput
              variant="white"
              placeholder="Radius (e.g., 40)"
              width="100%"
              type="number"
              min="10"
              value={bubbleRadius}
              onChange={(event: ChangeEvent) => {
                const updatedRadius = parseFloat(event.target.value);
                setBubbleRadius(updatedRadius);
              }}
            />
          </EditBubbleMessageContainer>
          <BubbleMessageActions>
            <BubbleMessageAction btnPadding=".5rem">
              <Button
                variant="link"
                borderColor={theme.colors.primaryBlue5}
                borderRadius="3rem"
                onClick={handleCloseIntercept}
              >
                {t('cancel')}
              </Button>
            </BubbleMessageAction>
            {!isNewBubble && (
              <BubbleMessageAction btnPadding=".5rem">
                <Button
                  variant="secondary-danger"
                  borderColor={theme.colors.text.error}
                  borderRadius="3rem"
                  onClick={() => handleSubmitIntercept('delete')}
                  color={theme.colors.text.error}
                >
                  {t('delete')}
                </Button>
              </BubbleMessageAction>
            )}

            <BubbleMessageAction btnPadding=".5rem 1rem">
              <Button
                disabled={!bubbleLabel || !partSku || !bubbleRadius || bubbleRadius < 10}
                variant="hover-blue"
                borderRadius="3rem"
                bgColor={theme.colors.primaryBlue5}
                onClick={() => handleSubmitIntercept()}
              >
                {isNewBubble ? t('add_bubble') : t('update_bubble')}
              </Button>
            </BubbleMessageAction>
          </BubbleMessageActions>
        </EditBubbleBody>
      </EditBubbleContainer>
    </Modal>
  );
};

export default EditBubbleModal;
