import React from 'react';
import { useDateRange } from 'components/StyledUi';
import {
  MachineIssuesWrapperAvure,
  PHConcern
} from 'components/machine-health/MachineActiveIssues/indexV2';
import { MachineIssuesProvider } from 'hooks/useMachineAlarmsAlerts';
import { MachineType } from 'types/machine-health';
import { AlertStatus } from 'types/machine-health/alerts';
import { useFleetMachineAccountData } from '../hooks';

export const PotentialHealthConcerns = ({ widgetTitle, noRecordTitle }: PHConcern): JSX.Element => {
  const { machineType } = useFleetMachineAccountData();

  if (machineType === 'ASD') return <></>;

  const { startTime, endTime } = useDateRange().utcTZConvertedISO;

  const machineIssuesProviderSettings = {
    pollingInterval: 60000,
    machineType: MachineType.Avure,
    alertStatus: AlertStatus.NotComplete,
    isAlarms: false,
    isAlerts: true,
    isMachineAlerts: false,
    start_datetime: startTime,
    end_datetime: endTime
  };

  return (
    <MachineIssuesProvider {...machineIssuesProviderSettings}>
      <MachineIssuesWrapperAvure heading={widgetTitle} noRecordTitle={noRecordTitle} />
    </MachineIssuesProvider>
  );
};
