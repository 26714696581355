import React from 'react';
import { ActionWithRulesProps } from 'react-querybuilder';
import { DeleteRuleButton } from './index.elements';
import TrashIcon from '../icons/delete.svg';

interface CustomRemoveRuleBtnProps extends ActionWithRulesProps {
  getRulesLength: (data: number[]) => boolean;
}

const CustomRemoveRuleBtn = (props: CustomRemoveRuleBtnProps): JSX.Element => {
  return (
    <>
      {props.level !== 1 ? (
        !props.getRulesLength(props.path) ? (
          <DeleteRuleButton
            onClick={(e) => {
              return props.handleOnClick(e);
            }}
          >
            <img src={TrashIcon} alt="Icon" height={20} />
          </DeleteRuleButton>
        ) : null
      ) : (
        <DeleteRuleButton
          onClick={(e) => {
            return props.handleOnClick(e);
          }}
        >
          <img src={TrashIcon} alt="Icon" height={20} />
        </DeleteRuleButton>
      )}
    </>
  );
};

export default CustomRemoveRuleBtn;
