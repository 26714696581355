import styled from 'styled-components';
import theme from 'themes';

export const Link = styled.a`
  text-decoration: none;
  color: ${theme.colors.primaryBlue5};
  cursor: pointer;
  width: 20rem;
  display: flex;
  justify-content: space-between;
`;

export const IconButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
`;
