import React, { ReactNode } from 'react';

import { FilterButton, FilterButtonProps } from './FilterButton';
import { FilterButtonWithDropdownContainer } from './FilterButton.elements';
import { useTranslation } from 'react-i18next';

export interface FilterButtonWithDoropdownItemProps {
  label?: ReactNode;
  path?: string;
  color?: string;
  id?: string;
}

export interface FilterButtonWithDropdownProps extends FilterButtonProps {
  items: Record<string, FilterButtonWithDoropdownItemProps>;
  selected?: string;
  handleSelect: (props: FilterButtonWithDoropdownItemProps) => void;
}

export const FilterButtonWithDropdown = ({
  handleSelect,
  items,
  selected
}: FilterButtonWithDropdownProps): JSX.Element => {
  selected = selected || Object.keys(items)[0];

  const { t } = useTranslation(['mh']);

  if (!items) {
    throw new Error('FilterButtonWithDropdown: items are required');
  }

  if (!handleSelect) {
    throw new Error('FilterButtonWithDropdown: handleSelect is required');
  }

  const Item = ({ label, path, color, id }: FilterButtonWithDoropdownItemProps) => {
    if (!label) {
      throw new Error('FilterButtonWithDropdown: label is required');
    }

    if (typeof label === 'string') label = t(label.toLowerCase());

    // start the base class for the Item
    let className = 'dropdown__item is-capitalized';

    if (selected === id) className += ' is-selected';

    return (
      <button
        type="button"
        onClick={() => handleSelect?.({ id, path, color, label })}
        className={className}
      >
        {label}
      </button>
    );
  };

  return (
    <FilterButtonWithDropdownContainer>
      <FilterButton />
      {items && (
        <ul className="dropdown">
          {Object.entries(items).map(([id, item]) => (
            <li key={id}>
              <Item id={id} {...item} />
            </li>
          ))}
        </ul>
      )}
    </FilterButtonWithDropdownContainer>
  );
};
