import { generateZonedTimeframe } from 'common/helpers/dateAndTimeHelpersV2';
import { useEffect } from 'react';
import { useWidgetDateRange } from '../useWidgetDateRange';
export interface UseLiveTimeFrameProps {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  months?: number;
}

export const useLiveTimeFrame = (
  refreshInterval: number,
  { minutes, hours, days, seconds }: UseLiveTimeFrameProps,
  timeZone?: string
): {
  startTime: string;
  endTime: string;
  timeZone: string;
} => {
  refreshInterval = refreshInterval || 30000;

  const { setDateRange, utcDateRange, ...rest } = useWidgetDateRange();

  timeZone = timeZone || rest.timeZone;

  useEffect(() => {
    if (refreshInterval < 1) return;

    const updater = setInterval(() => {
      const newRange = generateZonedTimeframe(timeZone, {
        subtractHours: hours,
        subtractMinutes: minutes,
        subtractDays: days,
        subtractSeconds: seconds
      });

      if (newRange?.endTime === utcDateRange?.endTime) return;
      else return setDateRange?.(newRange);
    }, refreshInterval);
    return () => clearInterval(updater);
  }, [utcDateRange]);

  return { ...utcDateRange, timeZone };
};
