import React from 'react';
import { useTranslation } from 'react-i18next';
import { CellContext } from '@tanstack/react-table';
import { capitalizeFirst } from 'helpers';
import { BaseType } from 'types';
import { NewBaseTable } from 'components';
import { IcoTrash } from 'icons/IcoTrash';
import theme from 'themes';
import { NavLink } from 'react-router-dom';
import { TableWrapper } from '../TagCalculated/index.elements';

interface TableColumnConfigs extends BaseType {
  tag_name?: string;
  description?: string;
  tag?: string;
}
const TableData = [
  {
    id: 1,
    aggregatedTagName: 'Lorem Ipsum 1',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 2,
    aggregatedTagName: 'Lorem Ipsum 2',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 3,
    aggregatedTagName: 'Lorem Ipsum 3',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 4,
    aggregatedTagName: 'Lorem Ipsum 4',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 5,
    aggregatedTagName: 'Lorem Ipsum 5',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 6,
    aggregatedTagName: 'Lorem Ipsum 6',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 7,
    aggregatedTagName: 'Lorem Ipsum 7',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 8,
    aggregatedTagName: 'Lorem Ipsum 8',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 9,
    aggregatedTagName: 'Lorem Ipsum 9',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 10,
    aggregatedTagName: 'Lorem Ipsum 10',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 11,
    aggregatedTagName: 'Lorem Ipsum 11',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 12,
    aggregatedTagName: 'Lorem Ipsum 12',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 13,
    aggregatedTagName: 'Lorem Ipsum 13',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 14,
    aggregatedTagName: 'Lorem Ipsum 14',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 15,
    aggregatedTagName: 'Lorem Ipsum 15',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 16,
    aggregatedTagName: 'Lorem Ipsum 16',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 17,
    aggregatedTagName: 'Lorem Ipsum 17',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 18,
    aggregatedTagName: 'Lorem Ipsum 18',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 19,
    aggregatedTagName: 'Lorem Ipsum 19',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 20,
    aggregatedTagName: 'Lorem Ipsum 20',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 21,
    aggregatedTagName: 'Lorem Ipsum 21',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 22,
    aggregatedTagName: 'Lorem Ipsum 22',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 23,
    aggregatedTagName: 'Lorem Ipsum 23',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 24,
    aggregatedTagName: 'Lorem Ipsum 24',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 25,
    aggregatedTagName: 'Lorem Ipsum 25',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 26,
    aggregatedTagName: 'Lorem Ipsum 26',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 27,
    aggregatedTagName: 'Lorem Ipsum 27',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 28,
    aggregatedTagName: 'Lorem Ipsum 28',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 29,
    aggregatedTagName: 'Lorem Ipsum 29',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 30,
    aggregatedTagName: 'Lorem Ipsum 30',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 31,
    aggregatedTagName: 'Lorem Ipsum 31',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 32,
    aggregatedTagName: 'Lorem Ipsum 32',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 33,
    aggregatedTagName: 'Lorem Ipsum 33',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 34,
    aggregatedTagName: 'Lorem Ipsum 34',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 35,
    aggregatedTagName: 'Lorem Ipsum 35',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  },
  {
    id: 36,
    aggregatedTagName: 'Lorem Ipsum 36',
    description: 'Lorem Ipsum',
    tag: 'Lorem Ipsum',
    aggregateType: 'Lorem Ipsum',
    aggregateFunction: 'Lorem Ipsum'
  }
];
const TagsAggregateTable = (): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const columns = [
    {
      id: 'aggregatedTagName',
      header: t('aggregated_tag_name'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <NavLink to={`#`} style={{ color: theme.colors.primaryBlue5, textDecoration: 'none' }}>
            {cellValue.getValue()}
          </NavLink>
        );
      }
    },
    {
      id: 'description',
      header: t('description'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) =>
        capitalizeFirst(cellValue.getValue().toLocaleLowerCase())
    },
    {
      id: 'tag',
      header: t('tag'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'aggregateType',
      header: t('aggregate_type'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'aggregateFunction',
      header: t('aggregate_function'),
      isEnableSorting: true,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return cellValue.getValue();
      }
    },
    {
      id: 'action',
      header: 'action',
      isEnableSorting: false,
      isSelected: true,
      renderer: (cellValue: CellContext<TableColumnConfigs, string>) => {
        return (
          <div
            onClick={() => {
              alert(JSON.stringify(cellValue.row.original));
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IcoTrash width="28" />
          </div>
        );
      }
    }
  ];

  const defaultSortState = {
    id: 'aggregatedTagName',
    desc: true
  };

  return (
    <TableWrapper>
      <div>
        <NewBaseTable
          newTableData={TableData}
          columnConfigs={columns}
          sortState={defaultSortState}
          isShowColumnOptions
          isShowGlobalSearch
          maxTableHeight={26.5}
        />
      </div>
    </TableWrapper>
  );
};
export default TagsAggregateTable;
