import React, { Fragment } from 'react';

import { /*ProgressBar,*/ WidgetUi } from 'common/ui';

import machineImage from 'common/pages/fleetV2/settings/dsi/machine-image.jpg';
import { MachineOverviewWidgetContainer } from './MachineOverviewWidget.elements';
import { SplitListContainer } from 'common/ui/Lists';
import { useTranslation } from 'react-i18next';
import {
  useMachineOverviewWidget
  //useMachineOverviewWidgetCalibrationData
} from './hooks/useMachineOverviewWidget';
import { IcoGearWithBoxes } from 'icons/IcoGear';
import { IcoArrowThreeArrowsUp } from 'icons/IcoArrow';
import { useTimeZone } from 'providers';
//import { IcoFirstAidKit } from 'icons/IcoFirstAidKit';

/**
 * this is just for a custom widget template,
 * if you want a pie chart or bar chart one of the existing global charts, please choose one from common/components/WidgetUi
 */

const MachineImage = (): JSX.Element => (
  <img src={machineImage} alt="dsi" className="full machine-image" />
);

const SplitListGroup = ({
  items,
  leftKey,
  rightKey,
  isRecipe
}: {
  items?: Record<string, unknown>[];
  className?: string;
  leftKey?: string;
  rightKey?: string;
  isRecipe?: boolean;
}): JSX.Element => {
  if (isRecipe) {
    rightKey = rightKey || 'piece_counts';
    leftKey = leftKey || 'value';
  }

  const { t } = useTranslation(['mh']);

  return (
    <>
      {items?.map((item, index) => {
        let leftValue = item?.[leftKey as string] || item?.label;
        if (typeof leftValue === 'string') leftValue = t(leftValue);

        let rightValue = item?.[rightKey as string] || item?.value;
        if (isRecipe) rightValue = `${rightValue} pc/min`;

        return (
          <Fragment key={String(leftValue || index)}>
            <dt>{leftValue || 'n/a'}</dt>
            <dd>{rightValue || 'n/a'}</dd>
          </Fragment>
        );
      })}
    </>
  );
};

/*
const CalibrationProgressBar = (): JSX.Element => {
  //const data = useMachineOverviewWidgetCalibrationData();

  const progressBarSettings = {
    title: 'calibration health',
    progress: 95,
    format: {
      infoTooltip: () => 'calibration health tooltip',
      value: () => ''
    },
    bottomTickCount: 3
  };

  return <ProgressBar {...progressBarSettings} />;
};
*/

export const MachineOverviewWidget = (): JSX.Element => {
  const { timeZone } = useTimeZone();

  const isLoading = !timeZone ? true : false;

  const widgetSettings = {
    title: 'Machine Overview',
    className: 'machine-overview-widget',
    isLoading
  };

  if (isLoading) {
    return <WidgetUi {...widgetSettings}>{isLoading ? 'loading' : 'loaded'}</WidgetUi>;
  }

  return <Loaded />;
};

const Loaded = (): JSX.Element => {
  const { t } = useTranslation(['mh']);

  const {
    isLoading,
    hasMessage,
    hasError,
    currentProductName,
    //currentProductType,
    currentRecipes,
    currentTotalOutput
    //machineState
  } = useMachineOverviewWidget();

  const widgetSettings = {
    title: 'Machine Overview',
    className: 'machine-overview-widget',
    hasError,
    isLoading,
    hasMessage
  };

  const { weight, pieces } = (currentTotalOutput || {}) as Record<string, Record<string, unknown>>;

  return (
    <WidgetUi
      {...widgetSettings}
      Main={
        <MachineOverviewWidgetContainer className="widget-ui__main has-padding">
          <div className="image-col">
            <MachineImage />
          </div>
          <div className="widget-group widget-group-1">
            <div className="icon-area product-setup">
              <IcoGearWithBoxes />
            </div>
            <div className="split-list-scroll-area">
              <SplitListContainer>
                <dt className="group-header group-title">{t('product setup')}</dt>
                <dd className="group-header colored-label">{currentProductName || 'n/a'}</dd>
              </SplitListContainer>
              <SplitListContainer scrollable>
                <SplitListGroup isRecipe items={currentRecipes as Record<string, unknown>[]} />
              </SplitListContainer>
            </div>
          </div>
          <div className="widget-group widget-group-2">
            <div className="icon-area cumulative-finished-goods">
              <IcoArrowThreeArrowsUp />
            </div>
            <div>
              <div className="group-header">
                {t('cumulative finished goods')}&nbsp;
                <span className="header-label">({t('today')})</span>
              </div>
              <div className="grid">
                <div className="grid-cel spacer"></div>
                <div className="grid-cel title">{t('portions')}</div>
                <div className="grid-cel title">{t('nuggets')}</div>
                <div className="grid-cel title total">{t('totals')}</div>

                <div className="grid-cel label">
                  {t('weight')} ({weight?.unit})
                </div>
                <div className="grid-cel value">{weight?.filet?.toLocaleString()}</div>
                <div className="grid-cel value">{weight?.nugget?.toLocaleString()}</div>
                <div className="grid-cel value total">{weight?.total?.toLocaleString()}</div>

                <div className="grid-cel label">{t('pieces')}</div>
                <div className="grid-cel value">{pieces?.filet?.toLocaleString()}</div>
                <div className="grid-cel value">{pieces?.nugget?.toLocaleString()}</div>
                <div className="grid-cel value total">{pieces?.total?.toLocaleString()}</div>
              </div>
            </div>
          </div>
          {/*<div className="widget-group progress-bar-group">
            <div className="icon-area calibration-health">
              <IcoFirstAidKit />
            </div>
            <CalibrationProgressBar />
          </div>*/}
        </MachineOverviewWidgetContainer>
      }
    />
  );
};
