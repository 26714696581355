import styled from 'styled-components';
import theme from 'themes';

interface ImageOptionProps {
  marginLeft?: string;
  marginTop?: string;
}
type TableProps = {
  tdMarginLeft?: string;
  textAlign?: 'left' | 'center';
  padding?: string;
};
export const StyledTable = styled.table<TableProps>`
  width: -webkit-fill-available;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  overflow: auto; 
  border-collapse: collapse;
  thead {
    background: #fff;
    position: sticky;
    margin: 0;
    top: 0;
    z-index: 11;
    th {
      border-bottom: 0.0625rem solid #dadada;
      padding: 0.5rem 0.25rem;
      text-transform: capitalize;
      width: max-content;
    }
    .select-none,
    .disabled-sort {
      display: flex !important;
      justify-content: center;
      float: left;
      margin-left: 1rem;
    }
    .disabled-sort div {
      visibility: hidden;
    }
  }

  /* Apply alternate row coloring + optional borders */
  tbody {
    td {
      border-bottom: 0.0625rem solid #f3f2f1;
      padding: ${({ padding }) => padding || '0.5rem 0.625rem'} ;
      span {
        float: left;
        margin-left: ${({ tdMarginLeft }) => tdMarginLeft || '1.125rem'} ;
      }
    }

    tr:last-child {
      border-bottom: none;
      background-color: transparent;
      & th {
        border-bottom: none;
      }
    }
    tr td input:not([type="checkbox"]), select {
      min-width: 10rem;
  }
  table thead .disabled-sort {
    display: inline-block;
    width: 100%;
    overflow: auto;
  }
`;

export const StyledColumnRefContainer = styled.div`
  padding: 0.375rem 0.25rem;
`;
export const SearchIconWrapper = styled.div`
  position: relative;
  > svg {
    cursor: auto;
    position: absolute;
    left: 0px;
    top: 11px;
    z-index: 9;
    fill: #666666;
  }
`;
export const StyledNonDraggableColumnRefContainer = styled.div`
  padding: 0.375rem 0.25rem;
`;

export const StyledDragRefContainer = styled.div`
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
`;

export const StyledTFoot = styled.tfoot`
  color: gray;
  & th {
    font-weight: normal;
  }
`;

export const StyledAscDescContainer = styled.div`
  margin-left: 0.5rem;
  margin-top: -0.125rem;
  div: nth-child(2) {
    margin-top: -0.625rem;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-top: auto;
`;

export const ColumnOptionWrapper = styled.button`
  position: relative;
  color: ${theme.colors.text.lightBlack};
  all: unset;
  box-sizing: border-box;
  padding: 11px;
  display: flex;
  gap: 8px;
  border: 1px solid ${theme.colors.white};
  &:hover {
    background: ${theme.colors.background.background2};
    border-color: ${theme.colors.background.background2};
    outline: 0px;
  }
  &:focus {
    background: ${theme.colors.background.background2};
    outline: 0px;
    border: 1px solid ${theme.colors.primaryBlue5};
    box-shadow: 0px 0px 4px 0px #0076cc30, 0px 0px 4px 0px #0076cc3d inset !important;
  }
`;
export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: auto;

  & > input {
    padding-top: 10px;
    margin-bottom: 9px;
    font-size: 14px;
  }

  .column-option-icon {
    padding-right: 8px;
  }

  & .globalSearchInput {
    width: 14rem;
  }
  & .globalSearchInput::placeholder {
    color: #828285;
  }
`;
export const ColumnOption = styled.div<{ shadow?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  box-shadow: ${({ shadow }) => {
    return shadow ? '0px 1px 1px 0px #0000002E' : 'transparent';
  }};
`;
export const TableFilterOption = styled.div`
  width: 1rem;
  cursor: pointer;
`;
export const FilterSelectWrapper = styled.div`
  top: 1rem;
  display: flex;
  width: 10rem;
  z-index: 100;
`;
export const ImageOptionWrapper = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
`;
export const ImageOption = styled.img<ImageOptionProps>`
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
`;
export const OptionsModalContainer = styled.div`
  width: 100%;
  padding: 0.5rem 2rem;
`;

export const SelectionHeader = styled.div`
  margin-left: 1rem;
`;

export const OptionalColumnContainer = styled.div`
  width: 100%;
  padding: 0.625rem 0.75rem;
`;

export const OptionColumnCheckboxWrapper = styled.div`
  display: flex;
  justify-content: left;
  border: 0.125rem solid ${(props) => props.theme.colors.lightGrey6};
  padding: 0 1rem;
  border-radius: 0.313rem;
  margin-bottom: 0.625rem;
`;

export const OptionalColumnName = styled.label`
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
  text-transform: capitalize;
`;

export const SelectAllOptionalColumn = styled.div`
  display: flex;
  padding: 0.625rem 0.75rem;
`;

export const ModalButtonsContainer = styled.div`
  width: 100%;
  padding-right: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;

  button {
    margin: 1rem;
    width: auto;
  }
`;
export const TableCoverRule = styled.div`
  width: 100%;
  border-bottom: 0.063rem solid ${(props) => props.theme.colors.lightGrey3};
`;
export const TableHeaderRule = styled.div`
  width: 100%;
  border-bottom: 0.063rem solid ${(props) => props.theme.colors.lightGrey3};
  margin-top: -1rem;
  margin-bottom: 0.5rem;
`;
export const StyledRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
`;

export const TableHeadBtn = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
  align-items: center;
`;
