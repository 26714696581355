// 3rd party libs
import React from 'react';
import theme from 'themes';

import { Button, Typography } from 'components';
import accessDeniedIcon from 'icons/accessDenied.svg';
import { useTranslation } from 'react-i18next';
import {
  AccessContentDiv,
  CardContainer,
  Container,
  ImgBox,
  InnerContent
} from './unauthorized.element';

type Props = {
  btn?: string;
  handleClick?: () => void;
};
const UnauthorizedPage = ({ btn, handleClick }: Props): JSX.Element => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <Container>
        <CardContainer>
          <InnerContent>
            <AccessContentDiv padding={'3rem 0'}>
              <Typography mb={'2rem'} size="4rem" weight="thin" color={theme.colors.primaryBlue5}>
                {t('unauthorized')}
              </Typography>
              <Typography mb={'3rem'} size="2rem" weight="medium" color={theme.colors.primaryBlue5}>
                {t('access_denied_message')}
              </Typography>
              <Button
                variant="hover-blue"
                borderRadius="3rem"
                width="10rem"
                onClick={() => {
                  handleClick?.();
                }}
              >
                {t(btn || 'Close')}
              </Button>
            </AccessContentDiv>
            <ImgBox src={accessDeniedIcon} alt="Access-denied" />
          </InnerContent>
        </CardContainer>
      </Container>
    </>
  );
};

export default UnauthorizedPage;
