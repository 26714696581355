import { format } from 'date-fns';

export const formatXaxisValues = (date: Date, daterange: Date[]): string => {
  if (daterange) {
    const a = daterange[0];
    const b = daterange[1];

    if (Math.abs(a.getTime() - b.getTime()) > 24 * 60 * 60 * 1000) {
      //format as dates/months
      return format(date, 'MMM/dd');
    } else {
      //format as hours
      return format(date, 'h a'); //this will change based on where user is
    }
  } else {
    return format(date, 'MMM/dd');
  }
};
