import styled from 'styled-components';

export const SkidChartTooltipContainer = styled.div`
  display: flex;
  gap: 0.4em;
  flex-direction: column;
  max-width: max-content;
  .tooltip__date {
    font-weight: 500;
    width: 100%;
  }

  .tooltip-cycle {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: 0.4em;

    span {
      dispaly: flex;
      align-items: center;
      justify-content: center;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      padding: 0.07em;

      svg {
        width: 0.5em;
        height: auto;
      }
    }

    &.pass span {
      border-color: green;
      svg {
        width: 0.6em;
        transform: translate(0.1em, -0.25em);
      }
    }

    &.fail span {
      border-color: red;
      svg {
        transform: translate(0.1em, -0.15em);
        fill: red;
      }
    }
  }

  .tooltip__cycles {
    display: flex;
    gap: 0.4em;
    flex-direction: column;
    font-size: 0.85em;
  }

  .underlined {
    padding-bottom: 0.6em;
    margin-bottom: 0.2em;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0px 0px 1px 0px;
  }
`;

export const SkidChartTooltipItem = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 0.4em;

  &:before {
    content: '';
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: ${({ color }) => color || 'black'};
    flex-grow: 0;
  }
`;
