import React from 'react';
import TagsAggregateTable from './TagsAggregateTable';
import { MainSection } from '../TagCalculated/index.elements';

const TagAggregated = (): JSX.Element => {
  return (
    <>
      <div
        style={{
          margin: '0.5rem'
        }}
      >
        <MainSection>
          <TagsAggregateTable />
        </MainSection>
      </div>
    </>
  );
};

export default TagAggregated;
