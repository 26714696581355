import styled from 'styled-components';

export const ZoomContainer = styled.div`
  .overlay {
    overflow: visible;
  }

  .zoom-smallbrush {
    fill: #dae9ff;

    .overlay {
      fill: #dae9ff;
    }

    .selection {
      fill: #0a70ff;
      fill-opacity: 1;
    }
  }

  .zoom-bigbrush {
    // Brush styling
    .brush-selection {
      fill: #007bff; /* Fill color of the selection */
      fill-opacity: 0.2; /* Opacity of the fill */
      stroke: #007bff; /* Border color of the selection */
      stroke-width: 2; /* Border width of the selection */
      rx: 5px; /* Horizontal corner radius */
      ry: 5px; /* Vertical corner radius */
    }

    /* Add this to your CSS file or style tag */
    .handle-custom {
      fill: #007bff; /* Fill color of the handles */
      stroke: #007bff; /* Border color of the handles */
      stroke-width: 2; /* Border width of the handles */
      rx: 5px; /* Horizontal corner radius */
      ry: 5px; /* Vertical corner radius */
    }

    .handle-pill {
      fill: #007bff; /* Fill color of the handles */
      rx: 5px; /* Horizontal corner radius */
      ry: 5px; /* Vertical corner radius */
      color: #ffffff;
    }

    image {
      height: 100%;
      width: 100%;
    }
  }
`;
