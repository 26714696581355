import React from 'react';

export const NoProduct = (): JSX.Element => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10080_39829)">
        <path
          d="M5.60001 16.9605H22.4V5.84558H5.60001V25.6054"
          stroke="#DA7029"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1319 11.5447C10.1451 11.5205 10.1705 11.5055 10.1981 11.5055H17.7996C17.8283 11.5055 17.8546 11.5218 17.8672 11.5476L18.4698 12.7744C18.4944 12.8245 18.4579 12.883 18.4021 12.883H14.711C14.6822 12.883 14.656 12.8666 14.6433 12.8408L14.03 11.5889C14.0025 11.5326 13.9222 11.5326 13.8947 11.5889L13.2814 12.8408C13.2687 12.8666 13.2425 12.883 13.2137 12.883H9.5286C9.47137 12.883 9.43501 12.8217 9.46243 12.7715L10.1319 11.5447Z"
          stroke="#DA7029"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 13.3518L14 15.6671"
          stroke="#DA7029"
          strokeWidth="0.49"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8447 13.6396V15.3261C17.8447 15.4539 17.7289 15.5764 17.5229 15.6668C17.3169 15.7571 17.0375 15.8079 16.7462 15.8079H11.2539C10.9626 15.8079 10.6832 15.7571 10.4772 15.6668C10.2712 15.5764 10.1554 15.4539 10.1554 15.3261V13.6396"
          stroke="#DA7029"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="13.9749" cy="8.91281" r="1.81551" stroke="#DA7029" strokeWidth="0.49" />
        <path
          d="M13.2716 9.55054L14.613 8.43064"
          stroke="#DA7029"
          strokeWidth="0.383835"
          strokeLinecap="round"
        />
        <path
          d="M13.3128 8.43066L14.613 9.60087"
          stroke="#DA7029"
          strokeWidth="0.383835"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10080_39829">
          <rect width="28" height="28.2283" fill="white" transform="translate(0 0.199951)" />
        </clipPath>
      </defs>
    </svg>
  );
};
