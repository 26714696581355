import React, { useEffect, useRef, useState } from 'react';
import { DoctsChartContainer } from './DotsChart.elements';
import DotsChart from '../../../../../common/components/Charts/DotsChart/DotsChart';
import { INTENSIFIERS, colorMappingDotsChartAvure } from './utils';
import { styledTheme } from 'common/theme';
import { generateCheckmark } from 'icons/checkmark';
import { format } from 'date-fns';
import { useSelectedItems } from 'pages/FleetMachineDetail/hooks/useZoomHistoryhook';

const COLORMAP = {
  intensifierCount11: '#118DFF',
  intensifierCount12: '#3A4BC6',
  intensifierCount13: '#E66C37',
  intensifierCount14: '#C83D95'
};

interface Props {
  data: Record<string, unknown>[];
  data2: Record<string, unknown>[];
  height: number;
  width: number;
  index: number;
}

export const DotsChartWrapper = ({ data, data2, height, width, index }: Props): JSX.Element => {
  const chartArea = useRef<HTMLDivElement | null>(null);

  const [chart, setChart] = useState();
  const [chartWidth, setChartWidth] = useState(width);

  const { history, index: historyIndex, setState } = useSelectedItems();

  const updateHistory = {
    updateZoomHistory: setState
  };

  const errorMessage = (data: { time: string; detailedInstructions: string }): string => {
    const { time, detailedInstructions } = data;
    const hasIntensifierError = Object.values(INTENSIFIERS).filter((item) =>
      detailedInstructions.includes(item.lookupValue)
    );
    const intensifier = hasIntensifierError && hasIntensifierError[0];

    return `<div class='message--inner'>
            <div class='tooltip--header'>${time}</div>
            <div class='error--line-item'>
              <span class='color-indicator' style='background-color: ${intensifier.color}'></span>
              <span class='error--line-item--header'>${intensifier.displayValue}</span>
              <span class='tooltip--value'><p>${detailedInstructions}</p></span>
            </div>  
            </div>`;
  };

  const tooltipMessage = (data: {
    startTime: Date;
    batchNumber: string;
    lotId: string;
    batchSuccessful: boolean;
  }): string => {
    const { startTime, batchNumber, lotId, batchSuccessful } = data;

    let content = '';
    const currSkid = index + 1;

    const key1 = `intensifierCount${currSkid}1`;
    const key2 = `intensifierCount${currSkid}2`;
    const key3 = `intensifierCount${currSkid}3`;
    const key4 = `intensifierCount${currSkid}4`;

    const value1 = data[key1];
    const value2 = data[key2];
    const value3 = data[key3];
    const value4 = data[key4];

    // This console log is to make TS happy, we use this variable on the line 79 with eval funciton
    const isTShappy = false;
    if (isTShappy) console.log(value1, value2, value3, value4);

    let start = 1;

    while (start < 5) {
      const value = 'value' + start;
      content += `<div key=${key1 + value} 
        class='tooltip--line-item'><span class='color-indicator' style='background-color: ${
          colorMappingDotsChartAvure[start]
        }'></span>
        Intensifier ${start} - <span class='tooltip--value'>${eval(
        value
      )} stroke counts</span></div>`;
      start++;
    }

    const date = format(new Date(startTime), 'MMMM d, y');

    const checkmark = batchSuccessful
      ? generateCheckmark({ color: styledTheme.colors.status.success.base })
      : generateCheckmark({ color: styledTheme.colors.status.error.base });

    return `<div class="tooltip--inner">
              <div class='tooltip--header'><div>${checkmark}</div>${date}</div>
              <div class='tooltip--subheader'><small>LotID:</small> ${lotId} <small>Batchnumber:</small> ${batchNumber}</div>
              ${content}
            </div>`;
  };

  const settings = {
    data: data,
    dataTooltipMessage: tooltipMessage,
    data2: data2,
    height: height,
    width: width,
    axisSettings: {
      xAxisKey: 'startTime',
      yAxisKey: 'intensifierCount11',
      yAxisKey2: 'intensifierCount12',
      yAxisKey3: 'intensifierCount13',
      yAxisKey4: 'intensifierCount14',
      xAxisLabel: 'Time',
      yAxisLabel: 'Stroke Count',
      yAxisDomainPadding: 70, //this is not margin, padding, this is to add/subtrack to domain values for better looking dots on the graph,
      yDomainKeysToValuesToIgnore: ['batchNumber'] //key to ignore when we are calculating Y domain max and min values
    },
    margins: {
      top: 30,
      right: 70,
      bottom: 30,
      left: 85
    },
    chartClass: 'stroke-count-chart',
    dataColor1: COLORMAP['intensifierCount11'],
    dataColor2: COLORMAP['intensifierCount12'],
    dataColor3: COLORMAP['intensifierCount13'],
    dataColor4: COLORMAP['intensifierCount14'],
    verticalLineMessage: errorMessage,
    tooltipSettings: {
      width: 220,
      height: 134
    }
  };

  useEffect(() => {
    if (history === undefined || historyIndex === undefined) return;
    const domain = {
      chartDomainRangeX: history && history[historyIndex] && history[historyIndex][0],
      chartDomainRangeY: history && history[historyIndex] && history[historyIndex][1]
    };

    if (!chart) {
      chartArea.current &&
        setChart(new DotsChart(chartArea.current, { ...settings }, { ...updateHistory }));
    } else {
      chart.update(data, data2, { ...domain }, { ...updateHistory });
    }
  }, [chart, chartWidth]);

  useEffect(() => {
    if (history === undefined || historyIndex === undefined) return;
    const domain = {
      chartDomainRangeX: history && history[historyIndex] && history[historyIndex][0],
      chartDomainRangeY: history && history[historyIndex] && history[historyIndex][1]
    };

    chart && chart.updateOnUndo(domain);
  }, [historyIndex]);

  useEffect(() => {
    setChartWidth(width);
  }, [width]);

  useEffect(() => {
    chartArea.current &&
      setChart(new DotsChart(chartArea.current, { ...settings }, { ...updateHistory }));
  }, [chartWidth]);

  return (
    <DoctsChartContainer>
      <div className={`chart-area dots-chart-${index}`} ref={chartArea}></div>
    </DoctsChartContainer>
  );
};
