// 3rd party libs
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import styled from 'styled-components';
import theme from 'themes';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LeftChevron from 'components/HorizontalScrollButtons/LeftScrollButton';
import RightChevron from 'components/HorizontalScrollButtons/RightScrollButton';
import _ from 'lodash';

// Components
import { Breadcrumbs, Button, Indicator, Loader, PageHeader } from 'components';

import {
  useGetMaintenanceScheduleFileQuery,
  useGetOnboardingMachineByIdQuery,
  useSaveMaintenanceScheduleMutation,
  useUpdateMachineOnboardingStatusMutation,
  useUploadMaintenanceScheduleMutation,
  useValidateUploadMaintenanceScheduleMutation
} from 'api';

// Maintence Schedule Components
import MaintenceScheduleTable from 'components/MaintenanceScheduleTable';
import RemainingErrorsModal from './RemainingErrorsModal';

// Types
import {
  MaintenceScheduleImportRow,
  MaintenanceFrequencyType,
  MaintenanceCreator,
  MaintenceScheduleImportRowErrorData,
  MaintenanceScheduleFileRow
} from 'types/maintenance';

// Constants
import { JBTRoutes } from 'constants/routes';
import { skipToken } from '@reduxjs/toolkit/query';
import { isEqual } from 'lodash';
import { UpdateRowFn } from 'types/machine-management';
import { onlyLettersAndNumbers } from 'helpers';
import { ToastMsg } from 'common/components/Toast/Toast';

//styling
const ActionButton = styled(Button)<{ ml?: string }>`
  font-weight: 500;
  margin-left: ${({ ml }) => ml};
  position: relative;
`;
const LoaderContainer = styled.div`
  width: 10rem;
  position: absolute;
  background: ${({ theme }) => theme.colors.whiteLight};
  inset: 0;
`;
const ButtonDiv = styled.div`
  position: relative;
  bottom: 0%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  z-index: 1;
  gap: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.mediumGrey1,
  height: 1,
  width: '100%'
}));

const StyledIndicator = styled(Indicator)`
  display: relative;
  margin: 0 0 0 15px;
  padding: 10px;
`;

const TableContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 25px;
`;
const TableWrapper = styled.div`
  width: 94%;
  overflow-x: hidden;
  margin-left: 35px;
`;
interface IState {
  validatedRows: MaintenceScheduleImportRow[];
}

type MaintenanceScheduleAction =
  | { type: 'loading' }
  | { type: 'stopLoading' }
  | { type: 'showErrorModal'; maintenceScheduleImportRows: MaintenceScheduleImportRow[] }
  | { type: 'closeErrorModal' }
  | {
      type: 'updateImportRows';
      rowId: number;
      key: string;
      value: number | string | MaintenanceFrequencyType | MaintenanceCreator;
    }
  | { type: 'updateMaintenanceState'; maintenceScheduleImportRows: MaintenceScheduleImportRow[] }
  | { type: 'validatedRows'; maintenceScheduleImportRows: MaintenceScheduleImportRow[] }
  | {
      type: 'updateRowValidation';
      rowId: number;
      errorKey: string;
      errors: MaintenceScheduleImportRowErrorData[];
    };

interface MaintenanceScheduleState {
  isLoading: boolean;
  showRemainingErrorsModal: boolean;
  maintenceScheduleImportRows: MaintenceScheduleImportRow[];
}

const addRowId = (rows: MaintenceScheduleImportRow[]) =>
  rows?.map((row, index) => ({ ...row, rowId: index }));

const reducer = (state: MaintenanceScheduleState, action: MaintenanceScheduleAction) => {
  switch (action.type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'stopLoading':
      return { ...state, isLoading: false };
    case 'showErrorModal':
      return {
        ...state,
        showRemainingErrorsModal: true,
        isLoading: false,
        maintenceScheduleImportRows: action.maintenceScheduleImportRows
      };
    case 'closeErrorModal':
      return { ...state, showRemainingErrorsModal: false, isLoading: false };
    case 'updateImportRows':
      return {
        ...state,
        maintenceScheduleImportRows: state.maintenceScheduleImportRows?.map((row) =>
          row.rowId === action.rowId ? { ...row, [action.key]: action.value || undefined } : row
        )
      };
    case 'updateRowValidation':
      return {
        ...state,
        maintenceScheduleImportRows: state.maintenceScheduleImportRows?.map((row) =>
          row.rowId === action.rowId
            ? { ...row, [action.errorKey]: action.errors || undefined }
            : row
        )
      };
    case 'validatedRows':
      return {
        ...state,
        isLoading: false,
        maintenceScheduleImportRows: action.maintenceScheduleImportRows
      };
    default:
      return state;
  }
};

const MaintenanceSchedule = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const { machineId } = useParams<{ machineId: string }>();
  const [isUpdateRows, setIsUpdateRow] = useState(false);
  const { data: maintenanceFile, isLoading } = useGetMaintenanceScheduleFileQuery(
    machineId ? { machineId: machineId } : skipToken
  );
  const [validatedRows, { isLoading: isValidating }] =
    useValidateUploadMaintenanceScheduleMutation();

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    showRemainingErrorsModal: false,
    maintenceScheduleImportRows: addRowId(
      (location.state as IState)?.validatedRows || maintenanceFile || []
    )
  });

  const [errorRowsList, setErrorRowsList] = useState<MaintenceScheduleImportRow[]>([]);
  const [validRowsList, setValidRowsList] = useState<MaintenceScheduleImportRow[]>([]);

  const allRows: {
    errorRows: MaintenceScheduleImportRow[];
    validRows: MaintenceScheduleImportRow[];
  } = useMemo(() => {
    const maintenanceRows = addRowId(maintenanceFile || []);
    const errorRows: MaintenceScheduleImportRow[] | undefined = state.maintenceScheduleImportRows
      ?.length
      ? state.maintenceScheduleImportRows?.filter(
          (row: MaintenceScheduleImportRow) => row.errorMessages?.length
        )
      : maintenanceRows?.filter((row: MaintenceScheduleImportRow) => row.errorMessages?.length);

    const validRows: MaintenceScheduleImportRow[] | undefined = state.maintenceScheduleImportRows
      ?.length
      ? state.maintenceScheduleImportRows.filter(
          (row: MaintenceScheduleImportRow) => !row.errorMessages?.length
        )
      : maintenanceRows?.filter((row: MaintenceScheduleImportRow) => !row.errorMessages?.length);

    setErrorRowsList(errorRows || []);
    setValidRowsList(validRows || []);
    return { errorRows: errorRows || [], validRows: validRows || [] };
  }, [isLoading, isUpdateRows]);

  const { errorRows, validRows } = allRows;

  const [errorTabId, setErrorTabId] = useState<string>();
  const [validTabId, setValidTabId] = useState<string>();
  const [horizontalScrollRate, setHorizontalScrollRate] = useState<number>();

  const { t } = useTranslation(['fpns', 'common']);
  useEffect(() => {
    setErrorTabId('maintenanceErrorTab');
    setValidTabId('maintenanceValidTab');
    setHorizontalScrollRate(800);
  });
  const { data: machine } = useGetOnboardingMachineByIdQuery(
    machineId ? { machineId: machineId } : skipToken
  );

  const [uploadMaintenanceSchedule] = useUploadMaintenanceScheduleMutation();
  const [updateMachineOnboardingStatus] = useUpdateMachineOnboardingStatusMutation();
  const [saveMaintenanceSchedule, upload] = useSaveMaintenanceScheduleMutation();

  const updateMaintenceScheduleRow = useCallback(
    (rowId, key, value) => {
      if (validRowsList && validRowsList.length > 0) {
        const updatedRows = validRowsList.map((item) => {
          return item.rowId === rowId ? { ...item, [key]: value } : item;
        });
        setValidRowsList(updatedRows);
      }
    },
    [validRowsList]
  );
  const updateMaintenceScheduleRowValidation = useCallback((rowId, errorKey, errors) => {
    dispatch({ type: 'updateRowValidation', rowId, errorKey, errors });
  }, []);

  const updateErrorMessage = (
    rowId: number | undefined,
    key: string,
    value: string | string[] | number[] | number | boolean | null | JSX.Element,
    columnName: string | undefined
  ) => {
    let errorData;
    let isValueChanged: boolean | undefined = false;

    const updatedRows: MaintenceScheduleImportRow | undefined = errorRowsList.find((item) => {
      return item.rowId === rowId;
    });
    const errorRow: MaintenceScheduleImportRow | undefined = errorRows.find((item) => {
      return item.rowId === rowId;
    });

    if (columnName === 'skus' || columnName === 'quantities') {
      const data = errorRow?.[key];
      if (typeof value === 'string') {
        const inputValue =
          key === 'quantities' ? value.split(',').map((item) => +item) : value.split(',');
        isValueChanged = !isEqual(data, inputValue);
      } else {
        const dataStr = JSON.stringify(data);
        const valueStr = JSON.stringify(value);
        isValueChanged = !isEqual(dataStr, valueStr);
      }
    } else if (columnName === 'salesforce_machine_id') {
      isValueChanged =
        typeof value === 'string' && value.length === 18 && onlyLettersAndNumbers(value);
    } else {
      isValueChanged = errorRow?.[key] !== value && !!(errorRow?.[key] ?? value);
    }

    if (isValueChanged) {
      const updatedErrorMsg = updatedRows?.errorMessages?.filter(
        (item) => item.columnName !== columnName
      );
      errorData = updatedErrorMsg;
    } else {
      errorData = errorRow?.errorMessages;
    }
    return errorData;
  };

  const updateErrorRows: UpdateRowFn = (rowId, key, value, columnName) => {
    const updatedRows = errorRowsList.map((item) => {
      const errorMsg = updateErrorMessage(rowId, key, value, columnName);
      return item.rowId === rowId ? { ...item, [key]: value, errorMessages: errorMsg } : item;
    });
    setErrorRowsList(updatedRows);
  };

  const onContinue = useCallback(async () => {
    dispatch({ type: 'loading' });
    function toSnakeCase<MaintenceScheduleFileRow>(obj: MaintenceScheduleImportRow) {
      let d = {} as MaintenceScheduleFileRow;
      for (const key in obj) {
        d = { ...d, [_.snakeCase(key)]: obj[key] };
      }
      return d;
    }
    const bodyParams: MaintenanceScheduleFileRow[] = errorRowsList
      .concat(validRowsList)
      .map((item) => {
        const renamedObj = toSnakeCase<MaintenceScheduleImportRow>(item);
        const { skus, quantities } = renamedObj;
        const correctSkus = skus?.filter((element) => element !== '');
        const correctQuantity = quantities?.filter((element) => element !== '').map(Number);
        const payloadObj: MaintenanceScheduleFileRow = {
          ...renamedObj,
          skus: correctSkus?.length ? correctSkus : null,
          quantities: correctQuantity?.length ? correctQuantity : null
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { error_messages, ...rest } = payloadObj;
        return rest;
      });
    const validatedData = (await validatedRows({
      machineId,
      maintenceScheduleImportRows: bodyParams
    })) as { data: MaintenceScheduleImportRow[] };
    dispatch({
      type: 'validatedRows',
      maintenceScheduleImportRows: addRowId(validatedData.data) as MaintenceScheduleImportRow[]
    });
    setIsUpdateRow(!isUpdateRows);

    const isValidationSuccess = !validatedData.data?.filter((item) => item.errorMessages?.length)
      .length;
    if (isValidationSuccess) handleSaveClick();
    else {
      return ToastMsg({
        heading: 'Failed',
        message: 'Failed to submit the file',
        type: 'error'
      });
    }

    try {
      const results = await uploadMaintenanceSchedule({
        maintenceScheduleImportRows: validRowsList.concat(errorRowsList),
        manufacturerSchedule: true,
        machineId
      }).unwrap();

      // Check if call succeded based on return type of array
      if (typeof results[0] === 'string') {
        await updateMachineOnboardingStatus({
          machineId,
          maintenanceScheduleStatus: 'Done'
        }).unwrap();

        history.push(JBTRoutes.onboardingPage.replace(':machineId', machineId));
      } else {
        if (results.length > 0) {
          dispatch({
            type: 'showErrorModal',
            maintenceScheduleImportRows: addRowId(results as MaintenceScheduleImportRow[])
          });
        } else {
          dispatch({ type: 'stopLoading' });
        }
      }
    } catch (error) {
      toast.error(t('failed_to_upload_maintenance_schedule'));
      console.log(error);
      dispatch({ type: 'stopLoading' });
    }
  }, [validRowsList, errorRowsList]);

  const onCancel = () => {
    window.location.assign(JBTRoutes.onboardingPage.replace(':machineId', machineId));
  };

  const handleSaveClick = async () => {
    function toSnakeCase<MaintenceScheduleFileRow>(obj: MaintenceScheduleImportRow) {
      let d = {} as MaintenceScheduleFileRow;
      for (const key in obj) {
        d = { ...d, [_.snakeCase(key)]: obj[key] };
      }
      return d;
    }
    const bodyParams: MaintenanceScheduleFileRow[] = errorRowsList
      .concat(validRowsList)
      .map((item) => {
        const renamedObj = toSnakeCase<MaintenceScheduleImportRow>(item);
        const { skus, quantities } = renamedObj;
        const correctSkus = skus?.filter((element) => element !== '');
        const correctQuantity = quantities?.filter((element) => element !== '').map(Number);
        const payloadObj: MaintenanceScheduleFileRow = {
          ...renamedObj,
          skus: correctSkus?.length ? correctSkus : null,
          quantities: correctQuantity?.length ? correctQuantity : null
        };
        return payloadObj;
      });

    let params = bodyParams.map((item) => {
      const err = item?.error_messages?.map((er) => ({
        errors: er.errors,
        column_name: er.columnName
      }));
      return {
        ...item,
        quantities: item.quantities?.map((quantity) => +quantity),
        error_messages: err
      };
    });

    const validateParams = params.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { error_messages, ...rest } = item;
      return rest;
    });
    const validatedData = (await validatedRows({
      machineId,
      maintenceScheduleImportRows: validateParams
    })) as { data: MaintenceScheduleImportRow[]; error: Error };

    if (!validatedData.error) {
      const data: MaintenanceScheduleFileRow[] = validatedData.data.map((item) => {
        const snakeKey = toSnakeCase<MaintenceScheduleImportRow>(item);
        return snakeKey;
      });
      params = data.map((item) => {
        const err = item?.error_messages?.map((er) => ({
          errors: er.errors,
          column_name: er.columnName
        }));
        return {
          ...item,
          quantities: item.quantities?.map((quantity) => +quantity),
          error_messages: err
        };
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveMaintenanceSchedule(params).then((res: any) => {
      if (res.error) {
        ToastMsg({
          message: 'Something went wrong',
          theme: 'colored',
          type: 'warning'
        });
      } else history.push(`/onboarding/${machineId}`);
    });
  };
  const handleScrollLeftErrorTable = () => {
    if (errorTabId) {
      const eTab = document.querySelector(`div#${errorTabId} div div table`);
      if (eTab?.parentElement) {
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        eTab.parentElement.scrollLeft -= horizontalScrollRate!;
      }
    }
  };
  const handleScrollLeftValidTable = () => {
    if (validTabId) {
      const eTab = document.querySelector(`div#${validTabId} div div table`);
      if (eTab?.parentElement) {
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        eTab.parentElement.scrollLeft -= horizontalScrollRate!;
      }
    }
  };
  const handleScrollRightErrorTable = () => {
    if (errorTabId) {
      const eTab = document.querySelector(`div#${errorTabId} div div table`);
      if (eTab?.parentElement) {
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        eTab.parentElement.scrollLeft += horizontalScrollRate!;
      }
    }
  };
  const handleScrollRightValidTable = () => {
    if (validTabId) {
      const eTab = document.querySelector(`div#${validTabId} div div table`);
      if (eTab?.parentElement) {
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        eTab.parentElement.scrollLeft += horizontalScrollRate!;
      }
    }
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          { label: 'Machine Management', link: JBTRoutes.machineManagement },
          {
            label: machine?.description || 'Retrieving machine...',
            link: JBTRoutes.onboardingPage.replace(':machineId', machineId)
          },
          { label: 'Review Maintenance Schedule' }
        ]}
      />
      <PageHeader heading="Maintenance Schedule" />
      <Divider />
      <Content>
        {/* <DataRenderer isLoading={state.isLoading}> */}
        <StyledIndicator color={theme.colors.negativeRed}>
          {t('errors_count', { ns: 'common', item: errorRowsList.length })}
        </StyledIndicator>
        <TableContainer>
          <LeftChevron handleScroll={handleScrollLeftErrorTable} />
          <RightChevron handleScroll={handleScrollRightErrorTable} />
          <TableWrapper>
            <MaintenceScheduleTable
              data={errorRowsList}
              isDataLoading={state.isLoading || isLoading || isValidating}
              onRowUpdate={updateErrorRows}
              onValidation={updateMaintenceScheduleRowValidation}
              id={errorTabId}
            />
          </TableWrapper>
        </TableContainer>
        <StyledIndicator color={theme.colors.onTrackGreen}>
          {t('valid_count', { item: validRows.length })}
        </StyledIndicator>
        <TableContainer>
          <LeftChevron handleScroll={handleScrollLeftValidTable} />
          <RightChevron handleScroll={handleScrollRightValidTable} />
          <TableWrapper>
            <MaintenceScheduleTable
              data={validRowsList}
              isDataLoading={state.isLoading || isLoading || isValidating}
              onRowUpdate={updateMaintenceScheduleRow}
              onValidation={updateMaintenceScheduleRowValidation}
              id={validTabId}
            />
          </TableWrapper>
        </TableContainer>
        {/* </DataRenderer> */}
      </Content>
      <Divider />
      <ButtonDiv>
        <ActionButton width="10rem" variant="no-shadow" onClick={onCancel}>
          {t('cancel', { ns: 'common' })}
        </ActionButton>
        <ActionButton
          disabled={upload.isLoading}
          ml={'1rem'}
          width="10rem"
          variant="secondary-light"
          onClick={handleSaveClick}
        >
          {t('save_and_exit')}
          {upload.isLoading ||
            (isValidating && !state.isLoading && (
              <LoaderContainer>
                <Loader size={35} margin={0} />
              </LoaderContainer>
            ))}
        </ActionButton>
        <ActionButton
          disabled={!!errorRowsList.some((errorRow) => errorRow.errorMessages?.length)}
          width="10rem"
          bgColor={theme.colors.primaryBlue5}
          color={theme.colors.lightGrey3}
          onClick={onContinue}
          variant="hover-blue"
        >
          {t('submit', { ns: 'fpns' })}
          {state.isLoading && (
            <LoaderContainer>
              <Loader size={35} margin={0} />
            </LoaderContainer>
          )}
        </ActionButton>
      </ButtonDiv>
      <RemainingErrorsModal
        isVisible={state.showRemainingErrorsModal}
        onClose={() => dispatch({ type: 'closeErrorModal' })}
        errorCount={errorRows.length}
      />
    </Container>
  );
};

export default MaintenanceSchedule;
