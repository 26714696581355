import styled from 'styled-components';

export const DatepickerBarContainer = styled.div`
  .reset-button {
    display: block;
    border: 0;
    background: none;
    cursor: pointer;

    svg {
      top: 4px;
      right: 2px;
      position: relative;
    }
  }

  &.widget__time-bar {
    grid-area: time-bar;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 0;
    top: 3px;
    position: relative;
    justify-content: end;

    // .reset-button {
    //   position: relative;
    //   font-size: 0.73rem;

    //   @media (min-width: 1025px) {
    //     top: 5px;
    //     font-size: 0.9rem;
    //   }
    // }

    @media (min-width: 1025px) {
      top: 0px;
      position: relative;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: flex-end;
    }

    .date-col,
    .spacer-col {
      display: none;

      @media (min-width: 1025px) {
        display: block;
      }
    }

    p {
      margin: 0.65rem 0.5rem;

      @media and (min-width: 1100px) {
        margin: 0 0.5rem;
      }
    }

    .ui-date-button-w-dropdown {
      margin-left: 1rem;
    }
  }
`;
