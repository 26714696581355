export const DSDMOpitonalColumns = [
  'Table Name',
  'Machine Is Primary Key',
  'Machine Description',
  'Omniblue Tag Name'
];
export const KDMOptionalColumns = [
  'Machine Description',
  'Machine Scaled Data Type',
  'Machine Raw High',
  'Machine Raw Low',
  'Machine Scaled High',
  'Machine Scaled Low',
  'Unit of Measure'
];
