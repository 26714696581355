import styled from 'styled-components';
import theme from 'themes';

export const AlertsTableWrapper = styled.div`
  width: 100%;

  tr td {
    &:first-child {
      width: 10%;
    }

    &:nth-child(2) {
      width: 22%;
      word-break: break-all;
    }

    &:nth-child(3) {
      width: 12%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 12%;
    }

    &:nth-child(6) {
      width: 18%;
    }
  }

  .threshold-alerts-table {
    .customcss {
      padding: 0px;
      margin-top: 12px;
      padding-right: 12px;
      & > div:nth-child(1) {
        .nav__menu-item {
          & > button {
            cursor: pointer;
          }
          & > div {
            margin-top: -8px;
            padding-top: 3px;
            height: 40px;
            border-radius: 0px;
            &:hover {
              background: ${theme.colors.background.background2};
              border-color: ${theme.colors.background.background2};
            }
            &:focus {
              background: ${theme.colors.background.background2};
              border: 1px solid ${theme.colors.primaryBlue5};
              box-shadow: 0px 0px 4px 0px #0076cc30, 0px 0px 4px 0px #0076cc3d inset !important;
            }
          }
        }
      }
      & > div:nth-child(3) {
        margin-top: 0px;
      }
    }

    table {
      height: 100%;
      overflow: auto;
    }
    div:nth-child(2) {
      max-height: calc(100vh - 28rem) !important;
    }
  }
`;

export const LoaderWrapper = styled.div`
  div {
    margin: 0;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 21rem);
`;
