import { useGetMachineHealthByBuKpiQuery } from 'api';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { MachineAccountInfoQueryParams } from 'types/protein';
import { useLiveTimeFrame } from 'common/ui';
import { MachineHealthInterval, DSIKPIType } from 'types/dsi';

/* sample return item {
  "name": "Last 1 Min",
  "value": 82,
  "unit": "%"
} */

/**
 * target value is based on last 30 minutes, to get % it's avg last 30 min / target * 100
 *
 * api path is `/v2/dsi/machine-health/<machine_id>/yield/aggregate`
 *
 *
 *
 * */
const showLiveLogs = false;

export const useBeltSpeed = (): {
  isLoading?: boolean;
  hasError?: ReactNode;
  hasMessage?: ReactNode;
  data?: Record<string, unknown>[];
  beltSpeed?: number;
  [key: string]: unknown;
} => {
  const { machineId } = useParams<MachineAccountInfoQueryParams>();

  const { data, error, isLoading } = useGetMachineHealthByBuKpiQuery({
    machineId,
    kpiDataInterval: MachineHealthInterval.LastHour,
    widgetType: DSIKPIType.BeltSpeed,
    limit: 100000,
    includeHistoricData: true,
    businessUnit: 'dsi',

    totalValuesDesired: 2000
  });

  // this hook automaically refreshes this api call every 60 seconds
  const live = useLiveTimeFrame(60000, { minutes: 60 });

  if (showLiveLogs) console.log({ live });

  let beltSpeed = 0;

  const beltSpeeds = data?.[0]?.values;

  if (beltSpeeds && beltSpeeds.length) {
    console.log({ beltSpeeds });
    const sorted = [...beltSpeeds]?.sort((a, b) =>
      (b.timestamp || '').localeCompare(a.timestamp || '')
    );
    console.log({ sorted });
    beltSpeed = sorted?.[0]?.value || 0;
  }
  //const sortedBeltSpeeds = (beltSpeeds && beltSpeeds.length) && beltSpeeds?.sort((a, b) => a.timestamp.localeCompare(b.timestamp))

  return {
    isLoading: isLoading && true,
    hasError: error && true,
    beltSpeed
  };
};
