import * as d3 from 'd3';
import { styledTheme } from 'common/theme';
import backgroundImageUrl from './images/ChartBG.png';
import { renderToString } from 'react-dom/server';
import { secondsToHms } from 'common/pages/fleetV2/machine/aseptic/views/MachineHealth/components/ActualDurationWidget/utils';

const HANDLE = 8;

/* eslint-disable */
export class ZoomedBrush {
  constructor(
    element,
    dataSettings,
    { margins, rangeX, domainY, width, height, zoomedRangeX, updateZoomedRangeX, defaultBackground }
  ) {
    this.element = element;
    this.brush = null;
    this.customHandles = null;
    this.defaultSelection = null;

    this.width = width;
    this.height = height;

    this.domainY = domainY;
    this.updateZoomedRangeX = updateZoomedRangeX;

    const { top, right, bottom, left } = margins;

    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;

    const { data, xAxisKey, colorMapKey, colorMap, type } = dataSettings;

    this.xAxisKey = xAxisKey;
    this.colorMapKey = colorMapKey;
    this.colorMap = colorMap;
    this.type = type;
    this.defaultBackground = defaultBackground;

    this.usingCustomHandle = false;
    this.updateZoomedRangeFlag = false;

    //Clear previously binded events
    this.clearEvents(d3.select(this.element));

    // CREATE SVG
    // Delete svg if already exists
    d3.select(this.element).selectAll('svg').remove();
    // Create and style svg
    this.svg = this.createSVG(this.element, 'zoom-bigbrush');
    if (this.defaultBackground) this.createSVGbackground(this.svg);
    // Create separate svg for the smaller zoom brush
    this.svgSmall = this.createSVG(this.element, 'zoom-smallbrush', 10);

    // CREATE AND STYLE AXISES
    // Create group for X axis
    this.xAxisGroup = this.svg
      .append('g')
      .attr('transform', `translate(0,${this.height - this.bottom})`)
      .attr('class', 'x-axis-group');
    //if we have Y domain, create a group for it
    if (this.domainY)
      this.yAxisGroup = this.svg.append('g').attr('transform', `translate(${this.left}, 0)`);
    //Create X axis
    this.x = this.createXAxis(rangeX);
    //Attach and style X axis to svg
    this.createXGroup(this.x);
    this.y = undefined;
    if (this.domainY) {
      //Create Y axis
      this.y = this.createYaxis(this.domainY);
      //Attach and style Y axis to svg
      this.createYGroup(this.y);
    }

    // BACKGROUND SVG
    data?.length !== 0 && this.type === 'flags' && this.populateIcons(data);
    data?.length !== 0 && this.type === 'line' && this.populateLine(data);

    // CREATE BRUSHES
    // Create big brush
    this.brush = this.activateBrush();

    // Smaller brush for second svg
    this.brushSecond = this.activateBrush(8);

    // BRUSH SELECTION
    this.brushSelection = zoomedRangeX
      ? [this.x(zoomedRangeX[0]), this.x(zoomedRangeX[1])]
      : [this.x(rangeX[0]), this.x(rangeX[1])];

    // DRAW BRUSH
    // Main zoom brush
    this.drawMainBrush(this.svg, this.x);
    // Small Zoom brush
    this.drawSecondBrush(this.svgSmall, this.x);

    const xAxis = this.x;
    const customDragHandles = d3
      .drag()
      .on('start', () => {
        this.usingCustomHandle = true;
      })
      .on('drag', (event) => this.customHandleDraggingHandles(event, xAxis))
      .on('end', (event) => {
        this.usingCustomHandle = false;
        this.customHandleDragEnded(event, xAxis);
      });

    // Add drag behavior to the custom div
    const customDiv = d3.selectAll('.handle-pill');
    const brushMain = this.brush;
    const brushSecond = this.brushSecond;
    const allSvg = d3.select(this.element).selectAll('svg');
    //const width = this.width;

    const customPillDrag = d3
      .drag()
      .on('drag', (event) => {
        // Update the position of the custom div
        //customDiv.attr("x", `${event.x}px`);
        const brushG = d3.selectAll('.brush.main');
        const brushGsmall = d3.selectAll('.zoom-smallbrush .brush');
        const allSvg = d3.select(this.element).selectAll('svg');
        const svgWidth = parseInt(allSvg.attr('width'));

        //Checking so X doesn't go beyond svg boundaries
        let wCoord = undefined;
        let eCoord = undefined;

        //Checking so X doesn't go beyond svg boundaries
        const currWestHandleX = d3.brushSelection(brushG.node())[0];
        const currEastHandleX = d3.brushSelection(brushG.node())[1];

        const selectionWidth = currEastHandleX - currWestHandleX;
        let futureWestSelection = event.x - selectionWidth / 2;
        let futureEastSelection = event.x + selectionWidth / 2;

        if (currWestHandleX < 5) {
          wCoord = 5;
          eCoord = 5 + selectionWidth;
        } else if (svgWidth - 5 <= currEastHandleX) {
          eCoord = svgWidth - 5;
          wCoord = svgWidth - 5 - selectionWidth;
        } else {
          wCoord = currWestHandleX;
          eCoord = currEastHandleX;
        }

        //Checking so X doesn't go beyond svg boundaries
        if (futureWestSelection < 5) {
          futureWestSelection = 5;
          futureEastSelection = 5 + selectionWidth;
        } else if (svgWidth - 5 <= futureEastSelection) {
          futureEastSelection = svgWidth - 5;
          futureWestSelection = svgWidth - 5 - selectionWidth;
        }

        allSvg.selectAll('.handle-pill').attr('x', () => wCoord + ((eCoord - wCoord) / 2 - 40)); //-40 is a half of the width of a pill

        //Position handles
        allSvg.selectAll('.custom-west-handle').attr('x', wCoord - HANDLE / 2);
        allSvg.selectAll('.custom-east-handle').attr('x', eCoord - HANDLE / 2);

        //Position text
        allSvg.selectAll('.handle-pill-text').attr('x', () => {
          //we need to calculate duration, sp that we place it correctly on x axis
          const duration =
            Number(
              secondsToHms((xAxis.invert(eCoord).getTime() - xAxis.invert(wCoord).getTime()) / 1000)
                .length
            ) * 2.8;

          const position = (eCoord - wCoord) / 2 - duration;
          return wCoord + position;
        });

        const newSelection = [futureWestSelection, futureEastSelection];

        this.brushSelection = newSelection;

        brushG.call(brushMain.move, newSelection);
        brushGsmall.call(brushSecond.move, newSelection);
      })
      .on('end', (event) => {
        this.usingCustomHandle = false;
        this.customHandleDragEnded(event, xAxis);
      });

    customDiv.call(customPillDrag);

    //Attach custom drag to custom handles
    this.svg.selectAll('.brush .handle-custom').call(customDragHandles);
    //this.svg.selectAll('.handle-pill').call(customDragPillContainer)

    this.brush
      .on('brush', (e) => this.brushed(e))
      .on('end', (e) => this.customHandleDragEnded(e, xAxis));

    d3.selectAll('.zoom-bigbrush').selectAll('.brush').call(this.brush.move, this.brushSelection);

    d3.selectAll('.zoom-smallbrush')
      .selectAll('.brush')
      .call(this.brushSecond)
      .call(this.brushSecond.move, this.brushSelection);
  }

  brushed(event) {
    if (this.usingCustomHandle) return;
    if (event?.sourceEvent?.type !== 'brush' && event?.mode !== 'drag') return; // Ignore brush-by-brush

    const xAxis = this.x;
    const selection = event.selection;
    const selectionLeft = event.selection[0];
    const selectionRight = event.selection[1];

    const allSvg = d3.select(this.element).selectAll('svg');

    //Position handles
    allSvg.selectAll('.custom-west-handle').attr('x', selectionLeft - HANDLE / 2);
    allSvg.selectAll('.custom-east-handle').attr('x', selectionRight - HANDLE / 2);

    this.brushSelection = selection;

    this.brush &&
      d3
        .select(this.element)
        .selectAll('svg')
        .select('.brush')
        .call(this.brush.move, this.brushSelection);

    //Position blue pill
    allSvg
      .selectAll('.handle-pill')
      .attr(
        'x',
        () => this.brushSelection[0] + ((this.brushSelection[1] - this.brushSelection[0]) / 2 - 40)
      ); //-40 is a half of the width of a pill

    //Position text
    allSvg.selectAll('.handle-pill-text').attr('x', () => {
      //we need to calculate duration, sp that we place it correctly on x axis
      const duration =
        Number(
          secondsToHms(
            (xAxis.invert(selectionRight).getTime() - xAxis.invert(selectionLeft).getTime()) / 1000
          ).length
        ) * 2.8;
      const position = (this.brushSelection[1] - this.brushSelection[0]) / 2 - duration;
      return this.brushSelection[0] + position;
    });
  }

  applyBrushBehavior(xAxis) {
    const customDragHandles = d3
      .drag()
      .on('start', (event) => {
        this.usingCustomHandle = true;
      })
      .on('drag', (event) => this.customHandleDraggingHandles(event, xAxis))
      .on('end', (event) => {
        this.usingCustomHandle = false;
        this.customHandleDragEnded(event, xAxis);
      });

    this.svg.selectAll('.brush .handle-custom').call(customDragHandles);
  }

  activateBrush(height) {
    const extent = this.getExtentX(height);
    return d3.brushX().extent(extent);
  }

  createSVG(element, className, height) {
    return d3
      .select(element)
      .append('svg')
      .attr('width', this.width)
      .attr('height', height ?? this.height)
      .attr('class', `${className}`);
  }

  createSVGbackground(svg) {
    // Define the pattern
    const pattern = svg
      .append('defs')
      .append('pattern')
      .attr('id', 'backgroundPattern')
      .attr('width', '100%')
      .attr('height', '59')
      .attr('patternUnits', 'userSpaceOnUse');

    // Embed the PNG image as an <image> element within the pattern
    pattern
      .append('image')
      .attr('xlink:href', backgroundImageUrl) // Replace with the URL to your PNG image
      .attr('width', 1) // Set the width and height to 1 to cover the entire pattern area
      .attr('height', 1);

    svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '59')
      .attr('fill', 'url(#backgroundPattern)');
  }

  createXAxis(range) {
    // Create X axis
    return d3
      .scaleTime()
      .domain(range)
      .range([this.left, this.width - this.right]);
  }

  createYaxis(range) {
    // Create Y axis
    return d3
      .scaleLinear()
      .domain(range)
      .range([this.height - this.bottom, 0]);
  }

  createXGroup(x) {
    // Call X axis
    const xAxisCall = d3.axisBottom(x).ticks(10);

    this.styleXAxis(xAxisCall);
  }

  createYGroup(y) {
    // Call Y axis
    const yAxisCall = d3.axisLeft(y).ticks(2);
    this.yAxisGroup
      .call(yAxisCall)
      .attr('fill', styledTheme.colors.gray)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove())
      .call((g) => g.selectAll('.tick text').remove());
  }

  customHandleDraggingHandles(event, x) {
    if (event.type != 'drag' && !event?.subject?.type) return;

    let selection = this.brushSelection;
    const allSvg = d3.select(this.element).selectAll('svg');
    const svgWidth = parseInt(allSvg.attr('width'));

    //Checking so X doesn't go beyond svg boundaries
    let wCoordHandle = undefined;
    let eCoordHandle = undefined;

    let wSelectionBrush;
    let eSelectionBrush;
    const currWestHandleX = Number(allSvg.selectAll('.custom-west-handle').attr('x'));
    const currEastHandleX = Number(allSvg.selectAll('.custom-east-handle').attr('x'));

    if (event.x < 5) {
      if (event.subject.type == 'w') {
        wCoordHandle = 5 - HANDLE / 2;
        wSelectionBrush = 5;
      }
      if (event.subject.type == 'e') {
        if (event.x < currWestHandleX) {
          eCoordHandle = currWestHandleX + HANDLE;
          eSelectionBrush = currWestHandleX + HANDLE * 1.5;
        } else {
          eCoordHandle = 5 + HANDLE / 2;
          eSelectionBrush = 5 + HANDLE;
        }
      }
    } else if (svgWidth - 5 <= event.x) {
      if (event.subject.type == 'w') {
        if (event.x > currEastHandleX) {
          wCoordHandle = currEastHandleX - HANDLE;
          wSelectionBrush = currEastHandleX - HANDLE / 2;
        } else {
          wCoordHandle = svgWidth - 5 - HANDLE * 1.5;
          wSelectionBrush = svgWidth - 5 - HANDLE;
        }
      }
      if (event.subject.type == 'e') {
        eCoordHandle = svgWidth - 5 - HANDLE / 2;
        eSelectionBrush = svgWidth - 5; // 1 - is a width of a border selection, it centers the handle
      }
    } else {
      if (event.subject.type == 'w') {
        if (event.x >= currEastHandleX - HANDLE) {
          wCoordHandle = currEastHandleX - HANDLE;
          wSelectionBrush = currEastHandleX - HANDLE / 2;
        } else {
          wCoordHandle = event.x - HANDLE / 2;
          wSelectionBrush = event.x;
        }
      }
      if (event.subject.type == 'e') {
        if (currWestHandleX + HANDLE >= event.x) {
          eCoordHandle = currWestHandleX + HANDLE;
          eSelectionBrush = currWestHandleX + HANDLE * 1.5;
        } else {
          eCoordHandle = event.x - HANDLE / 2;
          eSelectionBrush = event.x;
        }
      }
    }

    //Update position of handles
    if (event.subject.type == 'w') {
      allSvg.selectAll('.custom-west-handle').attr('x', wCoordHandle);
      selection[0] = wSelectionBrush;
    }

    if (event.subject.type == 'e') {
      allSvg.selectAll('.custom-east-handle').attr('x', eCoordHandle);
      selection[1] = eSelectionBrush;
    }

    //Don't update if selection is undefined
    if (selection[0] === undefined || selection[1] === undefined) return;

    this.brushSelection = selection;

    this.brush &&
      d3
        .select(this.element)
        .selectAll('svg')
        .select('.brush')
        .call(this.brush.move, this.brushSelection);

    //Position pill container
    allSvg
      .selectAll('.handle-pill')
      .attr(
        'x',
        () => this.brushSelection[0] + ((this.brushSelection[1] - this.brushSelection[0]) / 2 - 40)
      ); //-40 is a half of the width of a pill

    //Position pill text
    allSvg
      .selectAll('.handle-pill-text')
      .attr('x', () => {
        const start = this.brushSelection[0];
        const end = this.brushSelection[1];
        //we need to calculate duration, sp that we place it correctly on x axis
        const duration =
          Number(
            secondsToHms((x.invert(end).getTime() - x.invert(start).getTime()) / 1000).length
          ) * 2.8;
        const position = (this.brushSelection[1] - this.brushSelection[0]) / 2 - duration;
        return this.brushSelection[0] + position;
      })
      .text(() => {
        const start = x.invert(this.brushSelection[0]);
        const end = x.invert(this.brushSelection[1]);
        const duration = secondsToHms((end.getTime() - start.getTime()) / 1000);
        return duration;
      });
  }

  customHandleDragEnded(event, x) {
    const range = [x.invert(this.brushSelection[0]), x.invert(this.brushSelection[1])];

    this.updateZoomedRangeX(range); //this updates provider with final zoomed range
  }

  drawMainBrush(svg, x) {
    const selection = this.getCurrentSelection();
    const start = x(selection[0]);
    const end = x(selection[1]);

    // Append the brush to the chart
    const brushGroup = svg
      .append('g')
      .attr('class', 'brush main')
      .call(this.brush)
      .call(this.brush.move, [start, end]);

    // Below prevents default brush actions and let us use only custom handle actions
    brushGroup.selectAll('.selection').attr('class', 'selection brush-selection');
    brushGroup.select('.overlay').style('pointer-events', 'none');
    brushGroup.selectAll('.handle').style('pointer-events', 'none');

    // Append a custom handle as a rectangle
    const customHandleHeight = 28; // Set your desired handle height
    brushGroup
      .selectAll('.handle-custom')
      .data([{ type: 'w' }, { type: 'e' }])
      .enter()
      .append('rect')
      .attr('class', 'handle-custom')
      .attr('width', 8) // Set the width of the handle
      .attr('y', 18)
      .attr('height', customHandleHeight) // Set the height of the custom handle
      .attr('cursor', 'ew-resize');

    //Assign an individual class to each brush handle
    brushGroup.selectAll('.handle-custom').each(function (d, i) {
      // Here you can use i or d to differentiate handles and assign classes
      if (i === 0) {
        // Assuming the first handle is the "west" handle
        d3.select(this).classed('custom-west-handle', true);
      } else if (i === 1) {
        // Assuming the second handle is the "east" handle
        d3.select(this).classed('custom-east-handle', true);
      }
    });

    //Append time counter pill to selection
    // #1
    svg
      .append('rect')
      .attr('class', 'handle-pill')
      .attr('height', 18)
      .attr('width', 80)
      .attr('y', -1)
      .attr('cursor', 'grab');

    //Text inside pill
    svg
      .append('text')
      .attr('class', 'handle-pill-text')
      .attr('font-size', 12)
      .attr('fill', '#ffffff')
      .attr('y', 12);

    //We need a copy of rect #1 that would lay on top of everything and be used for dragging
    svg
      .append('rect')
      .attr('class', 'handle-pill')
      .attr('height', 18)
      .attr('width', 80)
      .attr('y', -1)
      .attr('cursor', 'grab')
      .style('opacity', 0);

    this.positionMainBrushElements(svg, selection, x);
  }

  positionMainBrushElements(svg, selection, x) {
    const start = selection[0];
    const end = selection[1];

    svg.selectAll('.handle-custom').attr('x', (d, i) => (i === 0 ? start - 4 : end - 4));

    //Position pill container
    svg.selectAll('.handle-pill').attr('x', start + ((end - start) / 2 - 40));

    //Position pill text
    svg
      .selectAll('.handle-pill-text')
      .text(() => {
        const start = x.invert(selection[0]);
        const end = x.invert(selection[1]);
        const duration = secondsToHms((end.getTime() - start.getTime()) / 1000);
        return duration;
      })
      .attr('x', () => {
        const start = selection[0];
        const end = selection[1];
        const duration =
          Number(
            secondsToHms(
              (x.invert(selection[1]).getTime() - x.invert(selection[0]).getTime()) / 1000
            ).length
          ) * 2.8;
        const position = (end - start) / 2 - duration;
        return start + position;
      });
  }

  updateMainBrushSelection(svg, selection, x) {
    //Update Brush selection
    svg.selectAll('.brush').call(this.brush).call(this.brush.move, selection);
  }

  updateSmallBrushSelection(svg, selection) {
    svg.selectAll('.brush').call(this.brushSecond).call(this.brushSecond.move, selection);
  }

  drawSecondBrush(svg, x) {
    const selection = this.getCurrentSelection();
    const start = x(selection[0]);
    const end = x(selection[1]);

    // Append the brush to the chart
    const brushGroup = svg
      .append('g')
      .attr('class', 'brush')
      .call(this.brushSecond)
      .call(this.brushSecond.move, [start, end]);

    // Below prevents default brush actions and let us use only custom handle actions
    brushGroup
      .selectAll('.selection')
      .attr('class', 'selection brush-selection')
      .style('pointer-events', 'none');
    brushGroup.select('.overlay').style('pointer-events', 'none');
    brushGroup.selectAll('.handle').style('pointer-events', 'none');
  }

  getCurrentSelection() {
    return this.brushSelection;
  }

  getExtentX(height) {
    let extendBrush;
    !height
      ? (extendBrush = [
          [5, 7],
          [this.width - 5, this.height - this.bottom + 10]
        ])
      : (extendBrush = [
          [0, 0],
          [this.width - this.right, height]
        ]);
    return extendBrush;
  }

  populateIcons(data) {
    if (!data) return;
    // Add icons
    this.svg
      .append('g')
      .attr('class', 'group--label-container')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 12)
      .selectAll()
      .data(data)
      .join('foreignObject')
      .attr('x', (d) => this.x(d[this.xAxisKey]))
      .attr('y', '6')
      .attr('class', 'icon')
      .attr('width', 28)
      .attr('height', 28)
      .append('xhtml:div')
      .style('position', 'absolute')
      .html((d) => {
        const icon = renderToString(this.colorMap[d[this.colorMapKey]]);
        return `<div class="icon--inner">${icon}</div>`;
      });

    this.svg.selectAll('.group--label-container').lower();
  }

  populateLine(data) {
    if (!data) return;

    this.svg
      .selectAll('.background-line')
      .exit()
      //.transition().duration(750)
      .remove();

    const color = d3
      .scaleSequential(d3.interpolate('white', 'rgba(134, 134, 134, 0.8)'))
      .domain([0, 1]);
    const defs = this.svg.append('defs');

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'area-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', 50) // Starts at the bottom of the SVG
      .attr('x2', 0)
      .attr('y2', 0); // Ends at the top of the SVG

    // Set up the gradient stops based on your data or preferences
    gradient
      .append('stop')
      .attr('offset', '0%') // Bottom of the SVG
      .attr('stop-color', color(0)); // Color at the lowest data value

    gradient
      .append('stop')
      .attr('offset', '100%') // Top of the SVG
      .attr('stop-color', color(1)); // Color at the highest data value

    // Define an area shape generator.
    this.area = d3
      .area()
      .curve(d3.curveNatural)
      .x((d) => this.x(d['timestamp']))
      .y0(this.y(0))
      .y1((d) => this.y(d['ppm']));

    data.map((item, index) => {
      return this.svg
        .append('path')
        .datum(item)
        .attr('d', this.area)
        .attr('class', `background-line curved-line-${index}`)
        .attr('fill', 'url(#area-gradient)');
    });
    this.svg.selectAll('.background-line').lower();
  }

  rePopulateLine(data) {
    if (!data) return;

    //Update axis
    this.svg
      .select('.x-axis-group')
      //.transition().duration(750)
      .call(d3.axisBottom(this.x));
    //Style axis
    this.styleXAxis(this.x);

    const area = this.area;

    // Bind the new data to the areas
    const updatedAreas = this.svg.selectAll('.background-line').data(data);

    // Update the existing paths
    updatedAreas.attr('d', area);

    // Handle the enter selection
    updatedAreas
      .enter()
      .append('path')
      .attr('class', 'background-line')
      .attr('d', area)
      .attr('fill', 'url(#area-gradient)')
      .style('fill-opacity', 0)
      // .transition()
      // .duration(700)
      .style('fill-opacity', 1);

    // Handle the exit selection
    updatedAreas.exit().remove();

    //Change the order of svg elements and bring line lower
    this.svg.selectAll('.background-line').lower();
  }

  removeOldData() {
    if (!this.data) return;

    this.svg
      .selectAll('.group--label-container')
      .data(this.data)
      .exit()
      // .transition()
      // .duration(500)
      .attr('height', 0)
      .attr('y', this.height)
      .remove();
  }

  updateBrushSelection(selection: Date[] | undefined, type: string) {
    const invertedSelection = [this.x(selection[0]), this.x(selection[1])];
    this.brushSelection = invertedSelection;

    this.positionMainBrushElements(this.svg, this.brushSelection, this.x);
    this.updateMainBrushSelection(this.svg, this.brushSelection, this.x);
    this.updateSmallBrushSelection(this.svgSmall, this.brushSelection, this.x);
  }

  rePopulateIcons(data) {
    if (!data) return;

    const g = d3.selectAll('.group--label-container');

    const foreignObjects = g.selectAll('.icon').data(data, (d) => d.start_time);

    // EXIT phase: remove old elements not present in new data
    foreignObjects
      .exit()
      //.transition().duration(750)
      .attr('width', 0)
      .attr('height', 0)
      .remove();

    // UPDATE phase: update existing elements
    foreignObjects
      // .transition()
      // .duration(750)
      .attr('x', (d) => this.x(d[this.xAxisKey]))
      .attr('y', 6)
      .attr('width', 28)
      .attr('height', 28);

    // ENTER phase: create new elements
    const foreignObjectEnter = foreignObjects
      .enter()
      .append('foreignObject')
      .attr('class', 'icon')
      .attr('x', (d) => this.x(d[this.xAxisKey]))
      .attr('y', 6)
      .attr('width', 0)
      .attr('height', 0);

    foreignObjectEnter
      //.transition().duration(750)
      .attr('width', 28)
      .attr('height', 28);

    foreignObjectEnter
      .append('xhtml:div')
      .style('position', 'absolute')
      .style('width', '100%')
      .style('height', '100%')
      .html((d) => {
        const icon = renderToString(this.colorMap[d[this.colorMapKey]]);
        return `<div class="icon--inner">${icon}</div>`;
      });

    this.svg
      .select('.x-axis-group')
      //.transition().duration(750)
      .call(d3.axisBottom(this.x));
    this.styleXAxis(this.x);
  }

  styleXAxis(axis) {
    return this.xAxisGroup
      .call(axis)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('y1', 0)
          .attr('y2', 10)
          // .transition()
          // .duration(500)
          .attr('stroke-opacity', 0.1)
      )
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', styledTheme.colors.gray)
          .attr('transform', `translate(0, 10)`)
      )
      .call((g) => g.selectAll('.tick line:first-child').remove());
  }

  updateXAxis(domain) {
    if (domain) {
      this.x.domain(domain);
      //Update axis
      this.svg
        .select('.x-axis-group')
        //.transition().duration(750)
        .call(d3.axisBottom(this.x));
      this.styleXAxis(this.x);
    }
  }

  clearEvents(selection) {
    selection.on('brush', null);
    //Removes all events with the namespace "brush" from all child elements
    selection.selectAll('*').on('.brush', null);

    selection.on('drag', null);
    selection.selectAll('*').on('.drag', null);

    selection.selectAll('*').on('start', null);
    selection.selectAll('*').on('end', null);
  }
}
/* eslint-enable */
