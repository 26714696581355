import React from 'react';

import { PressurizationPerformanceTable } from '../../components';
import { PotentialHealthConcerns } from '../../components/PotentialHealthConcernsWidget';
import { DeltaPressurizationTimeKPIWidget, AveragePressurizationTimeWidget } from './components';
import { PressurizationPerformanceViewContainer } from './PressurizedPerformanceView.elements';
import { PressurizationDeltaWidget } from '../components/PressurizationDelta/PressurizationDeltaWidget';
import { useTranslation } from 'react-i18next';

export const PressurizationPerformanceView = (): JSX.Element => {
  const { t } = useTranslation(['mh']);
  return (
    <PressurizationPerformanceViewContainer>
      <div className="pressurization-performance-view__top-row">
        <AveragePressurizationTimeWidget />
        <DeltaPressurizationTimeKPIWidget />
        {process.env.REACT_APP_ENABLE_PASCAL_HEALTH_ISSUES_WIDGET === 'true' && (
          <PotentialHealthConcerns
            widgetTitle={t('potential_health_concerns') as string}
            noRecordTitle={'No heath issues'}
          />
        )}
      </div>
      <PressurizationDeltaWidget />
      <PressurizationPerformanceTable />
    </PressurizationPerformanceViewContainer>
  );
};
