import styled from 'styled-components';

export const KPIProgressTileContainer = styled.div`
  display: grid;
  gap: 0.5em;
  font-size: 1em;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  min-height: 10em;

  .kpi-area {
    .split-list {
      font-size: 0.9em;
      dd {
        font-size: 1.1em;
      }
    }
  }
`;
