// 3rd Party Libraries
import React, { ReactElement, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { tanStackTableActions as tanStackActions } from 'actions/tanStackTable';
import { useDispatch } from 'react-redux';

// Components
import { Button, Modal, NewBaseTable, PageHeader } from 'components';
import { DataRenderer } from 'components/machine-health';

// API
import {
  useGetFavoriteMachinesQuery,
  useGetMachineStatusByIdsQuery,
  useGetMachinesQuery,
  useGetPlantByIdQuery
} from 'api';

// Custom hooks
import { useQueryParams } from 'hooks';
import { default as theme, themeColors } from 'themes';

// Icons
import DashboardIcon from 'icons/headerIcons/DashboardIcon.svg';

// Utils
import { newGenerateColumnConfigs } from 'components/site/SiteTable/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ModalSize } from 'types';
import { PopupModalContainer } from 'pages/MachineManagement/NewMachine/Machine.elements';
import { ButtonContainer } from 'components/Button';
import { IconButton, NoDataContainer, ParentCardContainer } from './index.elements';
import FavoriteCard from './FavoriteCard';
import AddFavorite from '../../icons/AddFavorite.png';
import { IcoCarouselView } from 'icons/IcoCarouselView';
import { IcoGridView } from 'icons/IcoGridView';
import MultiCardCarousel from 'common/components/MultiCardCarousel/MultiCardCarousel';
import { tanStackTableMultiFilterOption } from 'reducers/tanStackTable';
import { mappedStatuses } from 'components/MachineStatus/utils/BUsMachineStatusMapping';
import { AdvanceFilterOptionType } from 'common/components/AdvanceFilterPopUp';

/* Styling */
const MachineDetailContainer = styled.div``;

const MachinesContainer = styled.div`
  background: white;
  padding: 1.875rem 3.125rem;

  span {
    display: flex;
    gap: 0.2rem;
  }

  .machine_status {
    font-weight: 500;
  }

  .machine_status.connected {
    color: ${themeColors.green2};
  }

  .machine_status.disconnected {
    color: ${theme.colors.text.error};
  }

  .icon {
    padding: 0 0.2rem;
    width: 1rem;
    height: 1rem;
  }

  .customcss {
    padding-top: 1rem;
  }

  .offline-text {
    width: 9rem;
    text-align: center;
  }

  .fleet-dashboard-table {
    table {
      overflow: auto;
    }
    thead {
      position: sticky;
    }
  }
`;

const MachinesTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 0 1rem 0;
`;

const MachinesTitle = styled.h3`
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
  text-align: left;
`;

/* End styling */

const FleetDashboard = (): ReactElement => {
  const query = useQueryParams();
  const { data: getFavoriteMachines, isLoading } = useGetFavoriteMachinesQuery();
  const [popupOpen, setPopupOpen] = useState(false);
  const [isCarousel, setIsCarousel] = useState(true);
  const plantId = query.get('plantId') || undefined;
  const { data: userMachines, isSuccess: successUserMachines } = useGetMachinesQuery(
    plantId ? { plantIds: [plantId] } : {}
  );

  const { t } = useTranslation(['mh']);
  const machineIds = userMachines?.map((machine) => machine.id) || [];
  const { data: accountData } = useGetPlantByIdQuery(plantId ? plantId : skipToken);
  const {
    data: machineStatuses,
    isLoading: statusesLoading,
    error: errorStatuses
  } = useGetMachineStatusByIdsQuery(
    { machineIds: machineIds || [], is_description: true },
    { skip: !successUserMachines }
  );

  const multiCardCarouselItems =
    getFavoriteMachines &&
    getFavoriteMachines.map((ele, index) => {
      return (
        <DataRenderer key={ele.id} isLoading={isLoading}>
          <FavoriteCard
            key={index}
            machineId={ele.id}
            machineName={ele.description}
            customerName={ele.name}
          />
        </DataRenderer>
      );
    });

  const [filterOption, setFilterOption] = useState<AdvanceFilterOptionType[]>([]);

  const globalTableData =
    machineStatuses &&
    machineStatuses.map((ele) => {
      return {
        id: ele.id,
        customerName: ele.plantName,
        machines: ele.description,
        connectivity: mappedStatuses?.[ele?.connectionStatus as string]
      };
    });

  const filteredTableData = useMemo(() => {
    if (!machineStatuses) return [];

    // Transform machineStatuses to the desired format
    const transformedData = machineStatuses.map((ele) => ({
      id: ele.id,
      customerName: ele.plantName,
      machines: ele.description,
      connectivity: mappedStatuses?.[ele?.connectionStatus as string]
    }));

    // If filterOption is empty, return all data
    if (filterOption.length === 0) {
      return transformedData;
    }

    // Apply filters based on filterOption
    return transformedData.filter((data) => {
      return filterOption.some((filter) => {
        // Convert filter name to a key in data object
        const key = filter.name.toLowerCase();
        return filter.value === '' || data[key] === filter.value;
      });
    });
  }, [machineStatuses, mappedStatuses, filterOption]);

  const displayData = filterOption?.length === 0 ? globalTableData : filteredTableData;

  const dispatch = useDispatch();
  const advanceFilterOptions: tanStackTableMultiFilterOption[] = [
    {
      category: 'Connectivity',
      filters: [
        { value: 'Connected', label: 'Online' },
        { value: 'Offline', label: 'Offline' }
      ]
    }
  ];

  dispatch(tanStackActions.addFilterOptions(advanceFilterOptions));

  const updateTableOnFilterChange = (val: AdvanceFilterOptionType[]): void => {
    setFilterOption(val);
  };

  return (
    <>
      <PageHeader
        mb="1rem"
        heading={plantId ? '' : t('dashboard')}
        icon={DashboardIcon}
        breadcrumbs={
          plantId
            ? [
                {
                  label: accountData ? accountData.name : t('retrieving_customer')
                },
                {
                  label: accountData ? accountData.siteName : t('retrieving_plant')
                }
              ]
            : []
        }
        // TODO: Resolve after Alpha release
        // message="Urgent maintenance required for some machines"
        messageColor={theme.colors.negativeRed}
      />
      <MachineDetailContainer>
        <MachinesContainer>
          <MachinesTitleContainer>
            <MachinesTitle>{t('machines')}</MachinesTitle>
            <div>
              <IconButton isSelected={isCarousel} onClick={() => setIsCarousel(true)}>
                <IcoCarouselView color={themeColors.primaryBlue5} />
              </IconButton>
              <IconButton isSelected={!isCarousel} onClick={() => setIsCarousel(!isCarousel)}>
                <IcoGridView color={themeColors.primaryBlue5} />
              </IconButton>
            </div>
          </MachinesTitleContainer>
          <Modal
            visible={popupOpen}
            onClose={() => {
              setPopupOpen(false);
            }}
            bgOpacity="78%"
            size={ModalSize.SMALL_AUTO_HEIGHT}
            widthOverride="40rem"
          >
            <PopupModalContainer className="popup-model-container">
              <>
                <h2>{'Replace a favorite machine'}</h2>
                <p>
                  {
                    'You are only allowed to mark as favorite up to 12 Machines, Please erase one of your favorites.'
                  }
                </p>
                <ButtonContainer>
                  <Button
                    width="10rem"
                    borderRadius="3rem"
                    borderColor={theme.colors.primaryBlue5}
                    bgColor="transparent"
                    variant="link"
                    onClick={() => {
                      setPopupOpen(false);
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    width="10rem"
                    borderRadius="3rem"
                    variant="hover-blue"
                    onClick={() => {
                      setPopupOpen(false);
                    }}
                  >
                    {'Continue'}
                  </Button>
                </ButtonContainer>
              </>
            </PopupModalContainer>
          </Modal>

          {isCarousel ? (
            <MultiCardCarousel items={multiCardCarouselItems} />
          ) : (
            <ParentCardContainer>
              {getFavoriteMachines?.map((ele) => {
                return (
                  <DataRenderer isLoading={isLoading}>
                    <FavoriteCard
                      machineId={ele.id}
                      machineName={ele.description}
                      customerName={ele.name}
                    />
                  </DataRenderer>
                );
              })}
            </ParentCardContainer>
          )}

          {getFavoriteMachines?.length === 0 ? (
            <NoDataContainer>
              <img src={AddFavorite} alt="No data" />
              <p>
                {'You can select up to '}
                <span style={{ display: 'inline' }}>{'4 Machines'}</span>
                {' as your favorites.'}
              </p>
            </NoDataContainer>
          ) : (
            ''
          )}
          <DataRenderer
            isLoading={statusesLoading}
            error={errorStatuses && (t('failed_to_load_data', { ns: 'common' }) as string)}
          >
            <NewBaseTable
              columnConfigs={newGenerateColumnConfigs({ setPopupOpen: setPopupOpen })}
              sortState={{ id: 'customerName', desc: false }}
              newTableData={displayData || []}
              tdMarginLeft="0.5rem"
              isShowColumnOptions
              isShowGlobalSearch
              textAlign="left"
              customClassName="fleet-dashboard-table"
              shadow
              enableAdvanceFilter={true}
              handleAdvanceFilterRequest={updateTableOnFilterChange}
            />
          </DataRenderer>
        </MachinesContainer>
      </MachineDetailContainer>
    </>
  );
};

export default FleetDashboard;
