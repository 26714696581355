import NewBaseTable from 'components/NewBaseTable/NewBaseTable';
import { TFunction } from 'i18next';
import { TableAction } from 'pages/UserManagementTwo';
import React from 'react';
import { PaginatedResults } from 'types';
import { Group, User, UserTypes } from 'types/user-management';
import { usersColumnConfigs } from '../utils';
import { Pagination } from 'components';
import { PaginationContainer } from '.';

interface props {
  isLoading: boolean;
  dataWithKeys: (User | Group)[];
  handleTableAction: ({ flyoutType, flyoutItemId, selectedUser }: TableAction) => void;
  t: TFunction<'fpns'[], undefined>;
  handleClickRow?: (action?: string, data?: UserTypes) => void;
  selectedTableItems?: UserTypes[];
  defaultUserSortState: { id: string; desc: boolean };
  searchInputValue: (inputValue?: string, searchType?: string) => void;
  handleGroupSearch: (val?: string) => void;
  result: PaginatedResults<Group> | undefined;
  onPageChange: (page: number) => void;
  pageLenght: number;
  pageNumberState: number;
  pageNumber: number;
  resetControlCheck?: boolean;
  setResetControlCheck?: (val: boolean) => void;
}
export const DisplayExistingUsers = ({
  isLoading,
  dataWithKeys,
  handleTableAction,
  t,
  handleClickRow,
  selectedTableItems,
  defaultUserSortState,
  searchInputValue,
  handleGroupSearch,
  result,
  onPageChange,
  pageLenght,
  pageNumberState,
  pageNumber,
  resetControlCheck,
  setResetControlCheck
}: props): JSX.Element => {
  return (
    <>
      <NewBaseTable
        newTableData={isLoading ? [] : dataWithKeys}
        columnConfigs={usersColumnConfigs(
          handleTableAction,
          t,
          handleClickRow,
          selectedTableItems,
          false,
          resetControlCheck,
          setResetControlCheck
        )}
        tdMarginLeft="0.625rem"
        textAlign="left"
        sortState={defaultUserSortState}
        enableFilter={true}
        isShowColumnOptions={true}
        // handleFilterRequest={updateTableOnFilterChange}
        isDataLoading={isLoading}
        isShowGlobalSearch={true}
        showTableCoverRule={true}
        showTableHeaderRule={true}
        searchPlaceholder={t('mm_user_mgrm_table_search_user') as string}
        searchInputValue={searchInputValue}
        handleFilterRequest={handleGroupSearch}
        customClassName="userTable"
      />
      {result && (
        <PaginationContainer>
          <Pagination
            onPageChange={onPageChange}
            itemsPerPage={pageLenght}
            numItems={result?.total}
            forcePage={pageNumberState}
            currentPage={pageNumber}
          />
        </PaginationContainer>
      )}
    </>
  );
};
