import Cleaning from '../../../../../../../../../../icons/Cleaning.svg';
import Sterilization from '../../../../../../../../../../icons/Sterilization.svg';
import Idle from '../../../../../../../../../../icons/Idle.svg';
import Maintenance from '../../../../../../../../../../icons/Maint.svg';
import WaitTime from '../../../../../../../../../../icons/Wait_time.svg';
import Production from '../../../../../../../../../../icons/Production.svg';
import NoProduct from '../../../../../../../../../../icons/no-product.svg';

export const ICONMAP = {
  Idle: Idle,
  Maintenance: Maintenance,
  Mainteance: Maintenance,
  Production: Production,
  Cleaning: Cleaning,
  Sterilisation: Sterilization,
  Sterilization: Sterilization,
  Waittime: WaitTime,
  'Wait time': WaitTime,
  'No Product': NoProduct,
  Running: Production,
  'Not Running': Idle,
  Offline: WaitTime
} as Record<string, unknown>;

export const secondsToHms = (d?: number, showSign?: boolean): string => {
  if (d === undefined) return '';

  const isPositive = d > 0 ? '+' : '-';
  d = Math.abs(d);
  const day = Math.floor(d / 3600 / 24);
  const h = Math.floor((d / 3600) % 24);
  const m = Math.floor((d % 3600) / 60);
  //const s = Math.floor((d % 3600) % 60);

  const dDisplay = day > 0 ? day + (day == 1 ? 'd ' : 'd ') : '';
  const hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
  const mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';

  const isShownPositive = dDisplay || hDisplay || mDisplay ? isPositive : '';
  //const sDisplay = s > 0 ? s + (s == 1 ? 'sec' : 'sec') : '';
  return showSign
    ? isShownPositive + dDisplay + hDisplay + mDisplay
    : dDisplay + hDisplay + mDisplay;
};
