import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useMachineInfo } from 'common/pages/fleetV2/providers';
import { utcToZonedTime } from 'date-fns-tz';
import { cloneDeep } from 'lodash';
import { useMachineModesAPIProvider } from './useMachineModesProvider';
import { useDateRangeV2 } from 'components/StyledUi/DateRange/hooks/useDateRangeV2';
import { addMinutes } from 'date-fns';
import { MachineStateItem } from '../types';
import { useTimeZone } from 'providers/timeZoneProvider';
import { isDateNow } from 'common/pages/fleetV2/machine/proseal/views/MachineProduction/components/LiveGraphWidgetV2/utils';

const DataManagementContext = createContext({
  handleResetButton: () => console.log('handleResetButton not set'),
  handelZoomInDaterangeUpdate: (dateRange: Date[]) =>
    console.log('handelZoomInDaterangeUpdate not set', dateRange)
});

interface DataManagementProps {
  data?: MachineStateItem[];
  chartDateRange?: Date[];
  zoomDaterange?: Date[];
  filteredData?: MachineStateItem[];
  handleResetButton: () => void;
  handelZoomInDaterangeUpdate: (dateRange: Date[]) => void;
  resetTrack?: number;
  end_time?: Date;
  timezone?: string;
}

export const useDataManagementProvider = (): DataManagementProps =>
  useContext(DataManagementContext);

interface Props {
  children?: ReactNode | ReactNode[];
  version?: string;
}

export const ZOOMED_RANGE = 72; //in hours  168 - 7 days, 72 - 3days
export const ZOOMED_RANGE_MINNUTES = ZOOMED_RANGE * 60;
export const ZOOMED_RANGE_MILLISECONDS = ZOOMED_RANGE * 60 * 60 * 1000;

// This provider handles data updates and zoom resizing
export const DataManagement = ({ children, version }: Props): JSX.Element => {
  const { data, end_time } = useMachineModesAPIProvider();

  const [zoomDaterange, setZoomDaterange] = useState<Date[] | undefined>(undefined);
  const [chartDateRange, setChartDateRange] = useState<Date[] | undefined>(undefined);
  const [filteredData, setFilteredData] = useState(data);
  const [resetTrack, setResetTrack] = useState<number>(0);
  const isZoomRangeCurrent = useRef<boolean>(true);

  //Based on a routing version eithetr v1 or v2 we get timezone value from different providers
  const providertoCall = version?.toLowerCase() === 'v1' ? useTimeZone : useMachineInfo;
  const { timeZone } = providertoCall();

  const timezone = timeZone || 'UTC';

  const { startMoment: startTime, endMoment: endTime } = useDateRangeV2().machineDateRange;

  // When date range changes - update zoom area.
  // If date range is smaller than default zoom area, make zoom area same as date range
  useEffect(() => {
    const endDate = utcToZonedTime(new Date(endTime), timezone);
    const originalStartDate = utcToZonedTime(new Date(startTime), timezone);
    setZoomDaterange([originalStartDate, endDate]);

    //is end date NOW?
    const now = utcToZonedTime(
      new Date().toISOString(),
      timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const zoomEnd = utcToZonedTime(endTime, timezone);
    const difference = Math.abs(now.valueOf() - zoomEnd.getTime()) > 5 * 60 * 1000;
    if (difference) isZoomRangeCurrent.current = false;

    setChartDateRange([utcToZonedTime(startTime, timezone), utcToZonedTime(endTime, timezone)]);

    //set original data
    const dat = data;
    dat && setFilteredData(dat);
  }, [startTime, endTime]);

  //When data changes, check if zoom range end date is currrent, and if it is, update zoom range state
  useEffect(() => {
    if (!end_time) return;
    //if end date of zoomed window is now
    if (isZoomRangeCurrent.current && zoomDaterange && !data) {
      const lastItemTime = utcToZonedTime(end_time, timezone);
      const startDate = utcToZonedTime(
        new Date(new Date(end_time).getTime() - ZOOMED_RANGE_MILLISECONDS),
        timezone
      );
      setZoomDaterange([startDate, lastItemTime]);
    }

    if (!zoomDaterange) return;

    const start = zoomDaterange[0];
    const end = zoomDaterange[1];
    const copyData = cloneDeep(data);
    const filteredEvents = copyData?.reduce((acc: MachineStatesExtended[], item) => {
      //if item start date is less than zoom start and if item end date more than zoom start
      if (item.start_time <= start && item.end_time >= start && item.end_time <= end) {
        const duration = item.end_time.getTime() - start.getTime();
        item.start_time = start;
        item.duration = duration;
        acc.push(item);

        //if item end date is more than zoom end and if item start date is less than zoom end
      } else if (item.end_time >= end && item.start_time <= end && item.start_time >= start) {
        const duration = end.getTime() - item.start_time.getTime();
        item.end_time = end;
        item.duration = duration;
        acc.push(item);

        //if item start date is more less than zoom start and if item end date is more than zoom end
      } else if (item.start_time <= start && item.end_time >= end) {
        const duration = end.getTime() - start.getTime();
        item.start_time = start;
        item.end_time = end;
        item.duration = duration;
        acc.push(item);
      } else if (item.start_time >= start && item.end_time <= end) {
        //do nothing, keep original start and end date
        acc.push(item);
      }

      return acc;
    }, []);
    setFilteredData(filteredEvents);
  }, [data]);

  useEffect(() => {
    if (!end_time) return;

    //check if zoomed in area in the past
    if (isZoomRangeCurrent.current) {
      const endDate = utcToZonedTime(new Date(end_time), timezone);
      const zoomStart = utcToZonedTime(
        new Date(new Date(end_time).getTime() - ZOOMED_RANGE_MILLISECONDS),
        timezone
      );
      const originalZoomStart = (zoomDaterange && zoomDaterange[0]) || zoomStart;
      setZoomDaterange([originalZoomStart, endDate]);
    }

    setChartDateRange([
      addMinutes(utcToZonedTime(startTime, timezone), 1),
      utcToZonedTime(end_time, timezone)
    ]);
  }, [end_time]);

  //If zoom range changed - filter tha data that is in that range
  // Chack zoom end date if it si NOW or not
  useEffect(() => {
    if (!zoomDaterange) return;

    //update filtered data if zoomed area changes
    const start = zoomDaterange[0];
    const end = zoomDaterange[1];
    const copyData = cloneDeep(data);
    const filteredEvents = copyData?.reduce((acc: MachineStatesExtended[], item) => {
      //if item start date is less than zoom start and if item end date more than zoom start
      if (item.start_time <= start && item.end_time >= start && item.end_time <= end) {
        const duration = item.end_time.getTime() - start.getTime();
        item.start_time = start;
        item.duration = duration;
        acc.push(item);

        //if item end date is more than zoom end and if item start date is less than zoom end
      } else if (item.end_time >= end && item.start_time <= end && item.start_time >= start) {
        const duration = end.getTime() - item.start_time.getTime();
        item.end_time = end;
        item.duration = duration;
        acc.push(item);

        //if item start date is more less than zoom start and if item end date is more than zoom end
      } else if (item.start_time <= start && item.end_time >= end) {
        const duration = end.getTime() - start.getTime();
        item.start_time = start;
        item.end_time = end;
        item.duration = duration;
        acc.push(item);
      } else if (item.start_time >= start && item.end_time <= end) {
        //do nothing, keep original start and end date
        acc.push(item);
      }

      return acc;
    }, []);
    setFilteredData(filteredEvents);

    //Check if zoomed area end is NOW
    const now = utcToZonedTime(new Date().toISOString(), timezone);
    const zoomEnd = utcToZonedTime(end, timezone);
    const difference = Math.abs(now.valueOf() - zoomEnd.getTime()) > 5 * 60 * 1000;
    difference ? (isZoomRangeCurrent.current = false) : (isZoomRangeCurrent.current = true);
  }, [zoomDaterange]);

  const handleResetButton = () => {
    if (!end_time) return;
    //Which end date do we pass - end_time or endTime? It depends if we are looking at live data or not
    //Check if endTime from date range is current date and time
    const isCurrent = isDateNow(endTime, timezone); //is end date now?
    const endDate = isCurrent
      ? utcToZonedTime(new Date(end_time), timezone)
      : utcToZonedTime(endTime, timezone);
    const originalStartDate = utcToZonedTime(new Date(startTime), timezone);

    setZoomDaterange([originalStartDate, endDate]);
    const dat = data;
    setFilteredData(dat);
    setResetTrack((c) => c + 1);
  };

  const handelZoomInDaterangeUpdate = useCallback((daterange: Date[]): void => {
    const start = daterange[0];
    const end = daterange[1];

    //check if zoom end date is in the past
    const now = utcToZonedTime(new Date().toISOString(), timezone);
    const zoomEnd = utcToZonedTime(end, timezone);
    const difference = Math.abs(now.valueOf() - zoomEnd.getTime()) > 5 * 60 * 1000; //5min is a grace period
    difference ? (isZoomRangeCurrent.current = false) : (isZoomRangeCurrent.current = true);

    const copyData = cloneDeep(data);

    const filteredEvents = copyData?.reduce((acc: MachineStatesExtended[], item) => {
      //if item start date is less than zoom start and if item end date more than zoom start
      if (item.start_time <= start && item.end_time >= start && item.end_time <= end) {
        const duration = item.end_time.getTime() - start.getTime();
        item.start_time = start;
        item.duration = duration;
        acc.push(item);

        //if item end date is more than zoom end and if item start date is less than zoom end
      } else if (item.end_time >= end && item.start_time <= end && item.start_time >= start) {
        const duration = end.getTime() - item.start_time.getTime();
        item.end_time = end;
        item.duration = duration;
        acc.push(item);

        //if item start date is more less than zoom start and if item end date is more than zoom end
      } else if (item.start_time <= start && item.end_time >= end) {
        const duration = end.getTime() - start.getTime();
        item.start_time = start;
        item.end_time = end;
        item.duration = duration;
        acc.push(item);
      } else if (item.start_time >= start && item.end_time <= end) {
        //do nothing, keep original start and end date
        acc.push(item);
      }

      return acc;
    }, []);

    setFilteredData(filteredEvents);
    setZoomDaterange([start, end]);
  }, []);

  const values = {
    data,
    chartDateRange,
    zoomDaterange,
    filteredData,
    handleResetButton,
    handelZoomInDaterangeUpdate,
    resetTrack,
    end_time,
    timezone
  };

  return <DataManagementContext.Provider value={values}>{children}</DataManagementContext.Provider>;
};
