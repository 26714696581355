/** a function to remove duplicate items from an array of objects if all keys match */
export const removeDuplicates = (options?: {
  data?: Record<string, unknown>[];
}): Record<string, unknown>[] | undefined => {
  const { data } = options || {};
  return data?.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);
};

/** a function to remove duplicates of items that have the same values for certain keys */
export const removeDuplicatesByKeys = (
  // keys to check for duplicates
  keys: string[],
  options?: {
    data?: Record<string, unknown>[];
  }
): Record<string, unknown>[] | undefined => {
  const { data } = options || {};
  return data?.filter(
    (item, index, self) =>
      self.findIndex((t) => keys.every((key) => t[key] === item[key])) === index
  );
};
