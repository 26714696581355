import styled from 'styled-components';
import theme, { themeColors } from 'themes';

export const ParentCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1.5rem 0rem;
  justify-content: space-around;

  @media (max-width: 1100px) {
    justify-content: space-evenly;
  }

  .favorite-container {
    min-width: 18.75rem;
  }
`;

export const NoDataContainer = styled.div`
  text-align: center;
  padding: 0.875rem;
  padding-bottom: 1rem;

  p {
    font-weight: 400;
    font-size: 0.875rem;
    margin: 0.75rem;
  }

  span {
    font-weight: 700;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // min-width: 12rem;

  .machine_status {
    font-weight: 500;
  }
  .machine_status.connected {
    color: ${themeColors.green2};
  }
  .machine_status.disconnected {
    color: ${theme.colors.text.error};
  }

  .state {
    font-weight: 500;
    padding: 0 0.45rem;
  }

  .idle {
    color: #000000;
  }

  .state.no.product,
  .state.stop.by.alarm {
    color: #ba4e00;
  }

  .state.running {
    color: #008200;
  }

  .unknown {
    color: #ba4e00;
  }

  @media (max-width: 992px) {
    flex-basis: calc(50% - 0.8rem);
  }

  flex: none;
  width: 24%;
  padding: 1rem;
  box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.1), 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  margin: 0.5rem 0;
`;

export const CloseButton = styled.button`
  background: inherit;
  border: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${theme.colors.primaryBlue5};
  margin: 0.5rem 0;
  cursor: pointer;
  // width: 20rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const CardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.25rem;
`;

export const IconButton = styled.button<{ isSelected: boolean }>`
  cursor: pointer;
  border: none;
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  background: ${({ isSelected }) => (isSelected ? '#F2F3F4' : 'unset')};
`;
